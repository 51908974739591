// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../common/assets/Lato-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../common/assets/Lato-Bold.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../common/assets/Lato-Black.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Lato;src:local(Lato-Regular),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:normal;font-style:normal;font-stretch:normal}@font-face{font-family:Lato;src:local(Lato-Bold),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:bold;font-style:normal;font-stretch:normal}@font-face{font-family:Lato;src:local(Lato-Black),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:900;font-style:normal;font-stretch:normal}*[data-v-7422a0a8],*[data-v-7422a0a8]::before,*[data-v-7422a0a8]::after{box-sizing:border-box}*[data-v-7422a0a8]:focus{outline:none}html[data-v-7422a0a8]{position:relative;font-family:\"Lato\",\"Trebuchet MS\",sans-serif;font-weight:normal;font-style:normal;font-stretch:normal;font-size:14px;line-height:1.5;-webkit-text-size-adjust:100%;min-height:100%}span[data-v-7422a0a8]{font-weight:normal}[tabindex=\"-1\"][data-v-7422a0a8]:focus{outline:0 !important}.content[data-v-7422a0a8]{margin:24px}.content button[data-v-7422a0a8]{width:100%}.content .item[data-v-7422a0a8]{justify-content:space-between;display:flex;flex-direction:row}.content .item[data-v-7422a0a8] :last-child{text-align:right;font-weight:bold}", "",{"version":3,"sources":["webpack://./../common/styles/fonts.scss","webpack://./../common/styles/base.scss","webpack://./src/AccountConfirmation.vue","webpack://./../common/styles/layout.scss"],"names":[],"mappings":"AAkBC,WACG,gBAAA,CACA,kFAAA,CAEA,kBAAA,CACA,iBAAA,CACA,mBAAA,CAGF,WACE,gBAAA,CACA,+EAAA,CAEA,gBAAA,CACA,iBAAA,CACA,mBAAA,CAGF,WACE,gBAAA,CACA,gFAAA,CAEA,eAAA,CACA,iBAAA,CACA,mBAAA,CCvCJ,wEAGI,qBAAA,CAGJ,yBACI,YAAA,CAGJ,sBACI,iBAAA,CACA,4CAAA,CACA,kBAAA,CACA,iBAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA,CACA,6BAAA,CACA,eAAA,CAGJ,sBACI,kBAAA,CAkBJ,uCACI,oBAAA,CC1CJ,0BACI,WCsBQ,CDpBR,iCAEI,UAAA,CAGJ,gCACI,6BAAA,CACA,YAAA,CACA,kBAAA,CAEA,4CACI,gBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
