import ProjectConfig from '../../project-config.json';

const USE_DEV_WHEN_LOCAL = false;

export default class Environment {

    // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
    static get config() {
        return ProjectConfig[this.projectId];
    }

    static get projectId() {

        // Check if we are running on pre-configured Firebase servers
        // let firebase = this.firebaseConfig();
        // if(firebase) { // Check if we are running server-side
        //     return firebase.projectId;
        // }
        if(this.isProduction()) {
            return 'ihunter-d5eab';
        }
        if (this.isDevelopment()
            || (this.isLocal() && USE_DEV_WHEN_LOCAL)) {
            return 'ihunterdev';
        }
        if(this.isLocal()) {
            return 'localhost';
        }
        return 'localhost';
    }

    static firebaseConfig() {
        if(!process)
        return process && process?.env?.FIREBASE_CONFIG && JSON.parse(process.env.FIREBASE_CONFIG);
    }

    static isLocal() {
        return window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');
    }

    static isDevelopment() {
        return window.location.hostname.includes('dev');
    }

    static isProduction() {
        return !this.isDevelopment() && !this.isLocal();
    }
}