/**!
    @name    : ihunter-common
    @author  : Ⓒ 2024 Inside Outside Studios Inc. All rights reserved.
    @user    : erik
    @version : 1.2.0
    @date    : 4/11/2024
    @time    : 2:06:00 PM Mountain Standard Time
*/
const Ope = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, Dpe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, Ape = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, Mpe = {
  localhost: Ope,
  hybrid: Dpe,
  ihunterdev: Ape,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, xpe = !1;
class Rv {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return Mpe[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && xpe ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  static firebaseConfig() {
    var e;
    if (!process)
      return process && ((e = process == null ? void 0 : process.env) == null ? void 0 : e.FIREBASE_CONFIG) && JSON.parse(process.env.FIREBASE_CONFIG);
  }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class bE extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const fi = "application/json", Lpe = "application/octet-stream";
class Upe {
  constructor() {
  }
  get apiServer() {
    return Rv.config.functionURL;
  }
  get tileServer() {
    return Rv.config.tileURL;
  }
  get webServer() {
    return Rv.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let i = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || fi,
        Accept: r || fi
      }
    };
    return this.request(e, i);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: fi
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let o = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || fi,
        Accept: s || fi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, o);
  }
  async patch(e, n, r, s) {
    let o = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || fi,
        Accept: s || fi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, o);
  }
  async post(e, n, r, s) {
    let o = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || fi,
        Accept: s || fi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, o);
  }
  async delete(e, n, r) {
    let i = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || fi,
        Accept: r || fi
      }
    };
    return this.request(e, i);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new bE(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(fi)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new bE(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(fi) ? r(await i.json()) : o && o.startsWith(Lpe) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new bE(500, i.message, i))) : s(new bE(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class RIt {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var NS = { exports: {} };
function Fpe(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
NS.exports = Fpe;
const Q = (NS.exports == null ? {} : NS.exports).default || NS.exports, eu = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, V6 = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class wo {
  static TileServer() {
    return Rv.config.tileURL;
  }
  static WebServer() {
    return Rv.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return eu.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return Q(eu.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return Q(eu.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return Q(eu.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + Q(V6.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), Q(eu.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), Q(eu.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + Q(V6.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return Q(eu.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return Q(eu.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const Ws = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, rA = {
  REGIONS: "/product/regions/{region}"
}, Bs = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, z6 = {
  PRODUCT: "/product/skus/{product}"
}, G6 = {
  PRODUCT: "/product/prices/{product}"
}, Ype = {
  PROMOTIONS: "/store/promotions"
};
class kIt {
  static AllRegions() {
    return sA(Q(rA.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return rA.REGIONS;
  }
  static Regions(e) {
    return Q(rA.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Bs.REGION;
  }
  static Region(e) {
    return Q(Bs.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return Ws.REGION;
  }
  static RegionMetadata(e) {
    return Q(Ws.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Bs.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return Q(Bs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return Ws.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return Q(Ws.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Bs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return Q(Bs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return Ws.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return Q(Ws.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Bs.RESOURCE;
  }
  static Resource(e, n) {
    return Q(Bs.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return Ws.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return Q(Ws.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Bs.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return Q(Bs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return Ws.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return Q(Ws.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Bs.MAP;
  }
  static Map(e, n, r, s) {
    return Q(Bs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return Ws.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return Q(Ws.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Bs.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return Q(Bs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return Ws.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return Q(Ws.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return sA(Q(z6.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return Q(z6.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return sA(Q(G6.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return Q(G6.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return Ype.PROMOTIONS;
  }
}
const sA = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class Wpe {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const $6 = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, K6 = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, J6 = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, Q6 = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, iA = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, Z6 = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, Bpe = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
class cc {
  static RegionPropertyMap() {
    return $6.METADATA;
  }
  static SubscriptionPropertyMap() {
    return K6.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return J6.METADATA;
  }
  static ResourcePropertyMap() {
    return Q6.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return iA.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return iA.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return Z6.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties($6.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(K6.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(J6.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(Q6.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(iA.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(Z6.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(Bpe.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
}
var PS = { exports: {} };
function qpe(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
PS.exports = qpe;
const oA = (PS.exports == null ? {} : PS.exports).default || PS.exports;
var OS = { exports: {} };
async function Hpe(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
OS.exports = Hpe;
const jpe = (OS.exports == null ? {} : OS.exports).default || OS.exports;
Promise.allMap = jpe;
class Vpe {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, cc.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, cc.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, cc.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, cc.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, cc.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[cc.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, cc.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = oA(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = oA(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[cc.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, cc.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = oA(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class NIt extends Wpe {
  constructor(e) {
    super(e), this.http = new Upe(), this.builder = new Vpe(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(wo.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(wo.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(wo.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(wo.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(wo.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(wo.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(wo.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + wo.CountyMetadata(e)), await this.http.getJson(wo.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + wo.CountyProductMetadata(e, n)), await this.http.getJson(wo.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const Gg = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class PIt {
  static Apple(e) {
    return Q(Gg.APPLE, { uid: e });
  }
  static Android(e) {
    return Q(Gg.ANDROID, { uid: e });
  }
  static Web(e) {
    return Q(Gg.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return Q(Gg.WEB, { uid: e }) + Q(Gg.SKU, { region: n, sku: r });
  }
}
const Yr = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, $g = {
  CUSTOMER: `/${Yr.USERS}/{uid}/${Yr.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${Yr.USERS}/{uid}/${Yr.SUBSCRIPTIONS}`,
  PRODUCT: `/${Yr.PRODUCTS}/{product}`,
  PRICE: `/${Yr.PRODUCTS}/{product}/${Yr.PRICES}/{price}`
};
class OIt {
  static Customer(e, n) {
    return Q($g.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return Q($g.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return $g.CUSTOMER;
  }
  static PriceRoute() {
    return $g.PRICE;
  }
  static ProductRoute() {
    return $g.PRODUCT;
  }
  static Users() {
    return Yr.USERS;
  }
  static Customers() {
    return Yr.CUSTOMERS;
  }
  static Subscriptions() {
    return Yr.SUBSCRIPTIONS;
  }
  static Schedules() {
    return Yr.SCHEDULES;
  }
  static Products() {
    return Yr.PRODUCTS;
  }
  static Prices() {
    return Yr.PRICES;
  }
  static Promotions() {
    return Yr.PROMOTIONS;
  }
  static Payments() {
    return Yr.PAYMENTS;
  }
  static Coupons() {
    return Yr.COUPONS;
  }
  static Invoices() {
    return Yr.INVOICES;
  }
}
const zh = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class Kg {
  static Preferences(e) {
    return Q(zh.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return Q(zh.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return zh.OPT_IN;
  }
  static Status(e) {
    return Q(zh.STATUS, { uid: e });
  }
  static StatusRoute() {
    return zh.STATUS;
  }
  static Email(e) {
    return Q(zh.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return zh.EMAIL;
  }
}
const X6 = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class DIt {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, X6.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * X6.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const Gh = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, aA = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, e4 = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, cA = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, t4 = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, Jg = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, $h = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, n4 = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class C7 {
  static OutgoingContactRequestProcessedRoute() {
    return Gh.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return Q(Gh.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return Q(Gh.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return Gh.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return Q(Gh.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return Gh.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return Q(Gh.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return aA.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return Q(aA.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return Q(aA.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return Q(e4.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return e4.DATA;
  }
  static Chat(e) {
    return Q(cA.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var l, h;
    const r = Date.now();
    let s = e.length > 2, i = {}, o = {}, a = {};
    for (let u of e)
      i[u] = !0, o[u] = ((l = n == null ? void 0 : n.lastviewed) == null ? void 0 : l[u]) || r, a[u] = ((h = n == null ? void 0 : n.lastcleared) == null ? void 0 : h[u]) || r;
    let c = {
      participants: i,
      isgroupchat: s,
      lastviewed: o,
      lastcleared: a
    };
    return s && (c.chatname = cA.GROUP_NAME), c;
  }
  static ChatParticipants(e) {
    return Q(cA.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return Q(t4.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return t4.DATA;
  }
  static MessageRoute() {
    return Jg.ROUTE;
  }
  static Message(e, n) {
    const r = C7.MessageKey(n);
    return Q(Jg.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: $h.NOTIFY_ALL,
      type: Jg.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return Q(Jg.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return Jg.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return Q($h.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return Q($h.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === $h.NOTIFY_ALL ? $h.TITLE_FROM_NOTIFY_ALL : Q($h.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return Q(n4.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return $h.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return n4.UNKNOWN_NAME;
  }
}
const T7 = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
};
const R = function(t, e) {
  if (!t)
    throw $m(e);
}, $m = function(t) {
  return new Error("Firebase Database (" + T7.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
};
const b7 = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, zpe = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, lY = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(b7(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : zpe(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], a = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const l = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const u = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || a == null || l == null || u == null)
        throw new Gpe();
      const d = i << 2 | a >> 4;
      if (r.push(d), l !== 64) {
        const f = a << 4 & 240 | l >> 2;
        if (r.push(f), u !== 64) {
          const p = l << 6 & 192 | u;
          r.push(p);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class Gpe extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const E7 = function(t) {
  const e = b7(t);
  return lY.encodeByteArray(e, !0);
}, _I = function(t) {
  return E7(t).replace(/\./g, "");
}, mI = function(t) {
  try {
    return lY.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function $pe(t) {
  return S7(void 0, t);
}
function S7(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !Kpe(n) || (t[n] = S7(t[n], e[n]));
  return t;
}
function Kpe(t) {
  return t !== "__proto__";
}
function Jpe() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const Qpe = () => Jpe().__FIREBASE_DEFAULTS__, Zpe = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, Xpe = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && mI(t[1]);
  return e && JSON.parse(e);
}, HN = () => {
  try {
    return Qpe() || Zpe() || Xpe();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, e_e = (t) => {
  var e, n;
  return (n = (e = HN()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, I7 = (t) => {
  const e = e_e(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, t_e = () => {
  var t;
  return (t = HN()) === null || t === void 0 ? void 0 : t.config;
}, n_e = (t) => {
  var e;
  return (e = HN()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
class AT {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function R7(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    _I(JSON.stringify(n)),
    _I(JSON.stringify(o)),
    ""
  ].join(".");
}
function Oa() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function uY() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(Oa());
}
function r_e() {
  var t;
  const e = (t = HN()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function s_e() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function k7() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function N7() {
  return T7.NODE_ADMIN === !0;
}
function i_e() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function o_e() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const a_e = "FirebaseError";
class Nl extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = a_e, Object.setPrototypeOf(this, Nl.prototype), Error.captureStackTrace && Error.captureStackTrace(this, MT.prototype.create);
  }
}
class MT {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? c_e(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new Nl(s, a, r);
  }
}
function c_e(t, e) {
  return t.replace(l_e, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const l_e = /\{\$([^}]+)}/g;
function kw(t) {
  return JSON.parse(t);
}
function dr(t) {
  return JSON.stringify(t);
}
const P7 = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = kw(mI(i[0]) || ""), n = kw(mI(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, u_e = function(t) {
  const e = P7(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, h_e = function(t) {
  const e = P7(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function Ha(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function im(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function r4(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function gI(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function Nx(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (s4(i) && s4(o)) {
      if (!Nx(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function s4(t) {
  return t !== null && typeof t == "object";
}
function hY(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function pv(t) {
  const e = {};
  return t.replace(/^\?/, "").split("&").forEach((r) => {
    if (r) {
      const [s, i] = r.split("=");
      e[decodeURIComponent(s)] = decodeURIComponent(i);
    }
  }), e;
}
function _v(t) {
  const e = t.indexOf("?");
  if (!e)
    return "";
  const n = t.indexOf("#", e);
  return t.substring(e, n > 0 ? n : void 0);
}
class d_e {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function f_e(t, e) {
  const n = new p_e(t, e);
  return n.subscribe.bind(n);
}
class p_e {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    __e(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = lA), s.error === void 0 && (s.error = lA), s.complete === void 0 && (s.complete = lA);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function __e(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function lA() {
}
function jN(t, e) {
  return `${t} failed: ${e} argument `;
}
const m_e = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, R(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, VN = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function os(t) {
  return t && t._delegate ? t._delegate : t;
}
class vh {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const vd = "[DEFAULT]";
class g_e {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new AT();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (v_e(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: vd });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = vd) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = vd) {
    return this.instances.has(e);
  }
  getOptions(e = vd) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: y_e(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = vd) {
    return this.component ? this.component.multipleInstances ? e : vd : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function y_e(t) {
  return t === vd ? void 0 : t;
}
function v_e(t) {
  return t.instantiationMode === "EAGER";
}
class w_e {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new g_e(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var St;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(St || (St = {}));
const C_e = {
  debug: St.DEBUG,
  verbose: St.VERBOSE,
  info: St.INFO,
  warn: St.WARN,
  error: St.ERROR,
  silent: St.SILENT
}, T_e = St.INFO, b_e = {
  [St.DEBUG]: "log",
  [St.VERBOSE]: "log",
  [St.INFO]: "info",
  [St.WARN]: "warn",
  [St.ERROR]: "error"
}, E_e = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = b_e[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class dY {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = T_e, this._logHandler = E_e, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in St))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? C_e[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, St.DEBUG, ...e), this._logHandler(this, St.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, St.VERBOSE, ...e), this._logHandler(this, St.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, St.INFO, ...e), this._logHandler(this, St.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, St.WARN, ...e), this._logHandler(this, St.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, St.ERROR, ...e), this._logHandler(this, St.ERROR, ...e);
  }
}
const S_e = (t, e) => e.some((n) => t instanceof n);
let i4, o4;
function I_e() {
  return i4 || (i4 = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function R_e() {
  return o4 || (o4 = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const O7 = /* @__PURE__ */ new WeakMap(), Px = /* @__PURE__ */ new WeakMap(), D7 = /* @__PURE__ */ new WeakMap(), uA = /* @__PURE__ */ new WeakMap(), fY = /* @__PURE__ */ new WeakMap();
function k_e(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(Yu(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && O7.set(n, t);
  }).catch(() => {
  }), fY.set(e, t), e;
}
function N_e(t) {
  if (Px.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  Px.set(t, e);
}
let Ox = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return Px.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || D7.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return Yu(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function P_e(t) {
  Ox = t(Ox);
}
function O_e(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(hA(this), e, ...n);
    return D7.set(r, e.sort ? e.sort() : [e]), Yu(r);
  } : R_e().includes(t) ? function(...e) {
    return t.apply(hA(this), e), Yu(O7.get(this));
  } : function(...e) {
    return Yu(t.apply(hA(this), e));
  };
}
function D_e(t) {
  return typeof t == "function" ? O_e(t) : (t instanceof IDBTransaction && N_e(t), S_e(t, I_e()) ? new Proxy(t, Ox) : t);
}
function Yu(t) {
  if (t instanceof IDBRequest)
    return k_e(t);
  if (uA.has(t))
    return uA.get(t);
  const e = D_e(t);
  return e !== t && (uA.set(t, e), fY.set(e, t)), e;
}
const hA = (t) => fY.get(t);
function A_e(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = Yu(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(Yu(o.result), c.oldVersion, c.newVersion, Yu(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const M_e = ["get", "getKey", "getAll", "getAllKeys", "count"], x_e = ["put", "add", "delete", "clear"], dA = /* @__PURE__ */ new Map();
function a4(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (dA.get(e))
    return dA.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = x_e.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || M_e.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return dA.set(e, i), i;
}
P_e((t) => ({
  ...t,
  get: (e, n, r) => a4(e, n) || t.get(e, n, r),
  has: (e, n) => !!a4(e, n) || t.has(e, n)
}));
class L_e {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((n) => {
      if (U_e(n)) {
        const r = n.getImmediate();
        return `${r.library}/${r.version}`;
      } else
        return null;
    }).filter((n) => n).join(" ");
  }
}
function U_e(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const Dx = "@firebase/app", c4 = "0.9.8";
const vf = new dY("@firebase/app"), F_e = "@firebase/app-compat", Y_e = "@firebase/analytics-compat", W_e = "@firebase/analytics", B_e = "@firebase/app-check-compat", q_e = "@firebase/app-check", H_e = "@firebase/auth", j_e = "@firebase/auth-compat", V_e = "@firebase/database", z_e = "@firebase/database-compat", G_e = "@firebase/functions", $_e = "@firebase/functions-compat", K_e = "@firebase/installations", J_e = "@firebase/installations-compat", Q_e = "@firebase/messaging", Z_e = "@firebase/messaging-compat", X_e = "@firebase/performance", eme = "@firebase/performance-compat", tme = "@firebase/remote-config", nme = "@firebase/remote-config-compat", rme = "@firebase/storage", sme = "@firebase/storage-compat", ime = "@firebase/firestore", ome = "@firebase/firestore-compat", ame = "firebase", cme = "9.20.0";
const Ax = "[DEFAULT]", lme = {
  [Dx]: "fire-core",
  [F_e]: "fire-core-compat",
  [W_e]: "fire-analytics",
  [Y_e]: "fire-analytics-compat",
  [q_e]: "fire-app-check",
  [B_e]: "fire-app-check-compat",
  [H_e]: "fire-auth",
  [j_e]: "fire-auth-compat",
  [V_e]: "fire-rtdb",
  [z_e]: "fire-rtdb-compat",
  [G_e]: "fire-fn",
  [$_e]: "fire-fn-compat",
  [K_e]: "fire-iid",
  [J_e]: "fire-iid-compat",
  [Q_e]: "fire-fcm",
  [Z_e]: "fire-fcm-compat",
  [X_e]: "fire-perf",
  [eme]: "fire-perf-compat",
  [tme]: "fire-rc",
  [nme]: "fire-rc-compat",
  [rme]: "fire-gcs",
  [sme]: "fire-gcs-compat",
  [ime]: "fire-fst",
  [ome]: "fire-fst-compat",
  "fire-js": "fire-js",
  [ame]: "fire-js-all"
};
const yI = /* @__PURE__ */ new Map(), Mx = /* @__PURE__ */ new Map();
function ume(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    vf.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function wf(t) {
  const e = t.name;
  if (Mx.has(e))
    return vf.debug(`There were multiple attempts to register component ${e}.`), !1;
  Mx.set(e, t);
  for (const n of yI.values())
    ume(n, t);
  return !0;
}
function A7(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const hme = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, Wu = new MT("app", "Firebase", hme);
class dme {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new vh(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw Wu.create("app-deleted", { appName: this._name });
  }
}
const xT = cme;
function fme(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: Ax, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw Wu.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = t_e()), !n)
    throw Wu.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = yI.get(s);
  if (i) {
    if (Nx(n, i.options) && Nx(r, i.config))
      return i;
    throw Wu.create("duplicate-app", { appName: s });
  }
  const o = new w_e(s);
  for (const c of Mx.values())
    o.addComponent(c);
  const a = new dme(n, r, o);
  return yI.set(s, a), a;
}
function M7(t = Ax) {
  const e = yI.get(t);
  if (!e && t === Ax)
    return fme();
  if (!e)
    throw Wu.create("no-app", { appName: t });
  return e;
}
function zc(t, e, n) {
  var r;
  let s = (r = lme[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), vf.warn(a.join(" "));
    return;
  }
  wf(new vh(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const pme = "firebase-heartbeat-database", _me = 1, Nw = "firebase-heartbeat-store";
let fA = null;
function x7() {
  return fA || (fA = A_e(pme, _me, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(Nw);
      }
    }
  }).catch((t) => {
    throw Wu.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), fA;
}
async function mme(t) {
  try {
    return (await x7()).transaction(Nw).objectStore(Nw).get(L7(t));
  } catch (e) {
    if (e instanceof Nl)
      vf.warn(e.message);
    else {
      const n = Wu.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      vf.warn(n.message);
    }
  }
}
async function l4(t, e) {
  try {
    const r = (await x7()).transaction(Nw, "readwrite");
    return await r.objectStore(Nw).put(e, L7(t)), r.done;
  } catch (n) {
    if (n instanceof Nl)
      vf.warn(n.message);
    else {
      const r = Wu.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      vf.warn(r.message);
    }
  }
}
function L7(t) {
  return `${t.name}!${t.options.appId}`;
}
const gme = 1024, yme = 30 * 24 * 60 * 60 * 1e3;
class vme {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new Cme(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const n = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), r = u4();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === r || this._heartbeatsCache.heartbeats.some((s) => s.date === r)))
      return this._heartbeatsCache.heartbeats.push({ date: r, agent: n }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((s) => {
        const i = new Date(s.date).valueOf();
        return Date.now() - i <= yme;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = u4(), { heartbeatsToSend: n, unsentEntries: r } = wme(this._heartbeatsCache.heartbeats), s = _I(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function u4() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function wme(t, e = gme) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), h4(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), h4(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class Cme {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return i_e() ? o_e().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await mme(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const s = await this.read();
      return l4(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : s.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const s = await this.read();
      return l4(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : s.lastSentHeartbeatDate,
        heartbeats: [
          ...s.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function h4(t) {
  return _I(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function Tme(t) {
  wf(new vh(
    "platform-logger",
    (e) => new L_e(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), wf(new vh(
    "heartbeat",
    (e) => new vme(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), zc(Dx, c4, t), zc(Dx, c4, "esm2017"), zc("fire-js", "");
}
Tme("");
const d4 = "@firebase/database", f4 = "0.14.4";
let U7 = "";
function bme(t) {
  U7 = t;
}
class Eme {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), dr(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : kw(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class Sme {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return Ha(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const F7 = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new Eme(e);
    }
  } catch {
  }
  return new Sme();
}, Ad = F7("localStorage"), Ime = F7("sessionStorage");
const __ = new dY("@firebase/database"), Rme = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), Y7 = function(t) {
  const e = m_e(t), n = new d_e();
  n.update(e);
  const r = n.digest();
  return lY.encodeByteArray(r);
}, LT = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += LT.apply(null, r) : typeof r == "object" ? e += dr(r) : e += r, e += " ";
  }
  return e;
};
let kv = null, p4 = !0;
const kme = function(t, e) {
  R(!e, "Can't turn on custom loggers persistently."), __.logLevel = St.VERBOSE, kv = __.log.bind(__);
}, zr = function(...t) {
  if (p4 === !0 && (p4 = !1, kv === null && Ime.get("logging_enabled") === !0 && kme()), kv) {
    const e = LT.apply(null, t);
    kv(e);
  }
}, UT = function(t) {
  return function(...e) {
    zr(t, ...e);
  };
}, xx = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + LT(...t);
  __.error(e);
}, ml = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${LT(...t)}`;
  throw __.error(e), new Error(e);
}, As = function(...t) {
  const e = "FIREBASE WARNING: " + LT(...t);
  __.warn(e);
}, Nme = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && As("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, pY = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, Pme = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, om = "[MIN_NAME]", Cf = "[MAX_NAME]", op = function(t, e) {
  if (t === e)
    return 0;
  if (t === om || e === Cf)
    return -1;
  if (e === om || t === Cf)
    return 1;
  {
    const n = _4(t), r = _4(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, Ome = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, Qg = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + dr(e));
}, _Y = function(t) {
  if (typeof t != "object" || t === null)
    return dr(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += dr(e[r]), n += ":", n += _Y(t[e[r]]);
  return n += "}", n;
}, W7 = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function Xr(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const B7 = function(t) {
  R(!pY(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, Dme = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, Ame = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function Mme(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const xme = new RegExp("^-?(0*)\\d{1,10}$"), Lme = -2147483648, Ume = 2147483647, _4 = function(t) {
  if (xme.test(t)) {
    const e = Number(t);
    if (e >= Lme && e <= Ume)
      return e;
  }
  return null;
}, Km = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw As("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, Fme = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, Nv = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class Yme {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    As(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class Wme {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? (zr("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', As(e);
  }
}
class DS {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
DS.OWNER = "owner";
const mY = "5", q7 = "v", H7 = "s", j7 = "r", V7 = "f", z7 = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, G7 = "ls", $7 = "p", Lx = "ac", K7 = "websocket", J7 = "long_polling";
class Q7 {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = Ad.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && Ad.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function Bme(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function Z7(t, e, n) {
  R(typeof e == "string", "typeof type must == string"), R(typeof n == "object", "typeof params must == object");
  let r;
  if (e === K7)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === J7)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  Bme(t) && (n.ns = t.namespace);
  const s = [];
  return Xr(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class qme {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    Ha(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return $pe(this.counters_);
  }
}
const pA = {}, _A = {};
function gY(t) {
  const e = t.toString();
  return pA[e] || (pA[e] = new qme()), pA[e];
}
function Hme(t, e) {
  const n = t.toString();
  return _A[n] || (_A[n] = e()), _A[n];
}
class jme {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && Km(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const m4 = "start", Vme = "close", zme = "pLPCommand", Gme = "pRTLPCB", X7 = "id", eJ = "pw", tJ = "ser", $me = "cb", Kme = "seg", Jme = "ts", Qme = "d", Zme = "dframe", nJ = 1870, rJ = 30, Xme = nJ - rJ, ege = 25e3, tge = 3e4;
class r_ {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = UT(e), this.stats_ = gY(n), this.urlFn = (c) => (this.appCheckToken && (c[Lx] = this.appCheckToken), Z7(n, J7, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new jme(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(tge)), Pme(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new yY((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === m4)
            this.id = a, this.password = c;
          else if (o === Vme)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[m4] = "t", r[tJ] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[$me] = this.scriptTagHolder.uniqueCallbackIdentifier), r[q7] = mY, this.transportSessionId && (r[H7] = this.transportSessionId), this.lastSessionId && (r[G7] = this.lastSessionId), this.applicationId && (r[$7] = this.applicationId), this.appCheckToken && (r[Lx] = this.appCheckToken), typeof location < "u" && location.hostname && z7.test(location.hostname) && (r[j7] = V7);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    r_.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    r_.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return r_.forceAllow_ ? !0 : !r_.forceDisallow_ && typeof document < "u" && document.createElement != null && !Dme() && !Ame();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = dr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = E7(n), s = W7(r, Xme);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[Zme] = "t", r[X7] = e, r[eJ] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = dr(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
}
class yY {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = Rme(), window[zme + this.uniqueCallbackIdentifier] = e, window[Gme + this.uniqueCallbackIdentifier] = n, this.myIFrame = yY.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        zr("frame writing exception"), a.stack && zr(a.stack), zr(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || zr("No IE domain setting required");
      } catch {
        const r = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + r + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[X7] = this.myID, e[eJ] = this.myPW, e[tJ] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + rJ + r.length <= nJ; ) {
        const o = this.pendingSegs.shift();
        r = r + "&" + Kme + s + "=" + o.seg + "&" + Jme + s + "=" + o.ts + "&" + Qme + s + "=" + o.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(ege)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          zr("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const nge = 16384, rge = 45e3;
let vI = null;
typeof MozWebSocket < "u" ? vI = MozWebSocket : typeof WebSocket < "u" && (vI = WebSocket);
class Co {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = UT(this.connId), this.stats_ = gY(n), this.connURL = Co.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[q7] = mY, typeof location < "u" && location.hostname && z7.test(location.hostname) && (o[j7] = V7), n && (o[H7] = n), r && (o[G7] = r), s && (o[Lx] = s), i && (o[$7] = i), Z7(e, K7, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, Ad.set("previous_websocket_failure", !0);
    try {
      let r;
      N7(), this.mySock = new vI(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    Co.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && vI !== null && !Co.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return Ad.isInMemoryStorage || Ad.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    Ad.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = kw(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (R(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = dr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = W7(n, nge);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(rge));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
Co.responsesRequiredToBeHealthy = 2;
Co.healthyTimeout = 3e4;
class Pw {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [r_, Co];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = Co && Co.isAvailable();
    let r = n && !Co.previouslyFailed();
    if (e.webSocketOnly && (n || As("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [Co];
    else {
      const s = this.transports_ = [];
      for (const i of Pw.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      Pw.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
Pw.globalTransportInitialized_ = !1;
const sge = 6e4, ige = 5e3, oge = 10 * 1024, age = 100 * 1024, mA = "t", g4 = "d", cge = "s", y4 = "r", lge = "e", v4 = "o", w4 = "a", C4 = "n", T4 = "p", uge = "h";
class hge {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = UT("c:" + this.id + ":"), this.transportManager_ = new Pw(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = Nv(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > age ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > oge ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (mA in e) {
      const n = e[mA];
      n === w4 ? this.upgradeIfSecondaryHealthy_() : n === y4 ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === v4 && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = Qg("t", e), r = Qg("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: T4, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: w4, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: C4, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = Qg("t", e), r = Qg("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = Qg(mA, e);
    if (g4 in e) {
      const r = e[g4];
      if (n === uge) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === C4) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === cge ? this.onConnectionShutdown_(r) : n === y4 ? this.onReset_(r) : n === lge ? xx("Server Error: " + r) : n === v4 ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : xx("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), mY !== r && As("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), Nv(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(sge));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : Nv(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(ige));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: T4, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (Ad.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class sJ {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class iJ {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, R(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    R(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class wI extends iJ {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !uY() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new wI();
  }
  getInitialEvent(e) {
    return R(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const b4 = 32, E4 = 768;
class Ot {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function ot() {
  return new Ot("");
}
function Ce(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function wh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function Zt(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new Ot(t.pieces_, e);
}
function vY(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function dge(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function Ow(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function oJ(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new Ot(e, 0);
}
function Sn(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof Ot)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new Ot(n, 0);
}
function xe(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function Is(t, e) {
  const n = Ce(t), r = Ce(e);
  if (n === null)
    return e;
  if (n === r)
    return Is(Zt(t), Zt(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function fge(t, e) {
  const n = Ow(t, 0), r = Ow(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = op(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function wY(t, e) {
  if (wh(t) !== wh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function Vi(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (wh(t) > wh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class pge {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = Ow(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += VN(this.parts_[r]);
    aJ(this);
  }
}
function _ge(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += VN(e), aJ(t);
}
function mge(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= VN(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function aJ(t) {
  if (t.byteLength_ > E4)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + E4 + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > b4)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + b4 + ") or object contains a cycle " + wd(t));
}
function wd(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class CY extends iJ {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new CY();
  }
  getInitialEvent(e) {
    return R(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const Zg = 1e3, gge = 60 * 5 * 1e3, S4 = 30 * 1e3, yge = 1.3, vge = 3e4, wge = "server_kill", I4 = 3;
class Gc extends sJ {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = Gc.nextPersistentConnectionId_++, this.log_ = UT("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = Zg, this.maxReconnectDelay_ = gge, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !N7())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    CY.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && wI.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(dr(i)), R(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new AT(), s = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (o) => {
        const a = o.d;
        o.s === "ok" ? n.resolve(a) : n.reject(a);
      }
    };
    this.outstandingGets_.push(s), this.outstandingGetCount_++;
    const i = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(i), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), R(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), R(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      Gc.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && Ha(e, "w")) {
      const r = im(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        As(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || h_e(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = S4);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = u_e(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), R(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const i = r.d;
            this.log_("reportStats", "Error sending stats: " + i);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + dr(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : xx("Unrecognized action received from server: " + dr(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    R(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = Zg, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = Zg, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > vge && (this.reconnectDelay_ = Zg), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * yge);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + Gc.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        R(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? zr("getToken() completed but was canceled") : (zr("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new hge(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            As(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(wge);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && As(u), c());
      }
    }
  }
  interrupt(e) {
    zr("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    zr("Resuming connection for reason: " + e), delete this.interruptReasons_[e], r4(this.interruptReasons_) && (this.reconnectDelay_ = Zg, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => _Y(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new Ot(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    zr("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= I4 && (this.reconnectDelay_ = S4, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    zr("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= I4 && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + U7.replace(/\./g, "-")] = 1, uY() ? e["framework.cordova"] = 1 : k7() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = wI.getInstance().currentlyOnline();
    return r4(this.interruptReasons_) && e;
  }
}
Gc.nextPersistentConnectionId_ = 0;
Gc.nextConnectionId_ = 0;
class ke {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new ke(e, n);
  }
}
class zN {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new ke(om, e), s = new ke(om, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return ke.MIN;
  }
}
let EE;
class cJ extends zN {
  static get __EMPTY_NODE() {
    return EE;
  }
  static set __EMPTY_NODE(e) {
    EE = e;
  }
  compare(e, n) {
    return op(e.name, n.name);
  }
  isDefinedOn(e) {
    throw $m("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return ke.MIN;
  }
  maxPost() {
    return new ke(Cf, EE);
  }
  makePost(e, n) {
    return R(typeof e == "string", "KeyIndex indexValue must always be a string."), new ke(e, EE);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const m_ = new cJ();
class SE {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class Dr {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? Dr.RED, this.left = s ?? oi.EMPTY_NODE, this.right = i ?? oi.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new Dr(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return oi.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return oi.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, Dr.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, Dr.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
Dr.RED = !0;
Dr.BLACK = !1;
class Cge {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new Dr(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class oi {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = oi.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new oi(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, Dr.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new oi(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, Dr.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new SE(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new SE(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new SE(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new SE(this.root_, null, this.comparator_, !0, e);
  }
}
oi.EMPTY_NODE = new Cge();
function Tge(t, e) {
  return op(t.name, e.name);
}
function TY(t, e) {
  return op(t, e);
}
let Ux;
function bge(t) {
  Ux = t;
}
const lJ = function(t) {
  return typeof t == "number" ? "number:" + B7(t) : "string:" + t;
}, uJ = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    R(typeof e == "string" || typeof e == "number" || typeof e == "object" && Ha(e, ".sv"), "Priority must be a string or number.");
  } else
    R(t === Ux || t.isEmpty(), "priority of unexpected type.");
  R(t === Ux || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let R4;
class Sr {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = Sr.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, R(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), uJ(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    R4 = e;
  }
  static get __childrenNodeConstructor() {
    return R4;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new Sr(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : Sr.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return xe(e) ? this : Ce(e) === ".priority" ? this.priorityNode_ : Sr.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : Sr.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Ce(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (R(r !== ".priority" || wh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, Sr.__childrenNodeConstructor.EMPTY_NODE.updateChild(Zt(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + lJ(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += B7(this.value_) : e += this.value_, this.lazyHash_ = Y7(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === Sr.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof Sr.__childrenNodeConstructor ? -1 : (R(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = Sr.VALUE_TYPE_ORDER.indexOf(n), i = Sr.VALUE_TYPE_ORDER.indexOf(r);
    return R(s >= 0, "Unknown leaf type: " + n), R(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
Sr.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let hJ, dJ;
function Ege(t) {
  hJ = t;
}
function Sge(t) {
  dJ = t;
}
class Ige extends zN {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? op(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return ke.MIN;
  }
  maxPost() {
    return new ke(Cf, new Sr("[PRIORITY-POST]", dJ));
  }
  makePost(e, n) {
    const r = hJ(e);
    return new ke(n, new Sr("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const Dn = new Ige();
const Rge = Math.log(2);
class kge {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / Rge, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const CI = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new Dr(d, u.node, Dr.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new Dr(d, u.node, Dr.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new Dr(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, Dr.BLACK) : (d(m, Dr.BLACK), d(m, Dr.RED));
    }
    return h;
  }, o = new kge(t.length), a = i(o);
  return new oi(r || e, a);
};
let gA;
const Dp = {};
class Cc {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return R(Dp && Dn, "ChildrenNode.ts has not been loaded"), gA = gA || new Cc({ ".priority": Dp }, { ".priority": Dn }), gA;
  }
  get(e) {
    const n = im(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof oi ? n : null;
  }
  hasIndex(e) {
    return Ha(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    R(e !== m_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(ke.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = CI(r, e.getCompare()) : a = Dp;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new Cc(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = gI(this.indexes_, (s, i) => {
      const o = im(this.indexSet_, i);
      if (R(o, "Missing index implementation for " + i), s === Dp)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(ke.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), CI(a, o.getCompare());
        } else
          return Dp;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new ke(e.name, a))), c.insert(e, e.node);
      }
    });
    return new Cc(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = gI(this.indexes_, (s) => {
      if (s === Dp)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new ke(e.name, i)) : s;
      }
    });
    return new Cc(r, this.indexSet_);
  }
}
let Xg;
class ie {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && uJ(this.priorityNode_), this.children_.isEmpty() && R(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return Xg || (Xg = new ie(new oi(TY), null, Cc.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || Xg;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new ie(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? Xg : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = Ce(e);
    return n === null ? this : this.getImmediateChild(n).getChild(Zt(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (R(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new ke(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? Xg : this.priorityNode_;
      return new ie(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Ce(e);
    if (r === null)
      return n;
    {
      R(Ce(e) !== ".priority" || wh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(Zt(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(Dn, (o, a) => {
      n[o] = a.val(e), r++, i && ie.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + lJ(this.getPriority().val()) + ":"), this.forEachChild(Dn, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : Y7(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new ke(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new ke(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new ke(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, ke.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, ke.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === FT ? -1 : 0;
  }
  withIndex(e) {
    if (e === m_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new ie(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === m_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(Dn), s = n.getIterator(Dn);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === m_ ? null : this.indexMap_.get(e.toString());
  }
}
ie.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class Nge extends ie {
  constructor() {
    super(new oi(TY), ie.EMPTY_NODE, Cc.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return ie.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const FT = new Nge();
Object.defineProperties(ke, {
  MIN: {
    value: new ke(om, ie.EMPTY_NODE)
  },
  MAX: {
    value: new ke(Cf, FT)
  }
});
cJ.__EMPTY_NODE = ie.EMPTY_NODE;
Sr.__childrenNodeConstructor = ie;
bge(FT);
Sge(FT);
const Pge = !0;
function ir(t, e = null) {
  if (t === null)
    return ie.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), R(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new Sr(n, ir(e));
  }
  if (!(t instanceof Array) && Pge) {
    const n = [];
    let r = !1;
    if (Xr(t, (o, a) => {
      if (o.substring(0, 1) !== ".") {
        const c = ir(a);
        c.isEmpty() || (r = r || !c.getPriority().isEmpty(), n.push(new ke(o, c)));
      }
    }), n.length === 0)
      return ie.EMPTY_NODE;
    const i = CI(n, Tge, (o) => o.name, TY);
    if (r) {
      const o = CI(n, Dn.getCompare());
      return new ie(i, ir(e), new Cc({ ".priority": o }, { ".priority": Dn }));
    } else
      return new ie(i, ir(e), Cc.Default);
  } else {
    let n = ie.EMPTY_NODE;
    return Xr(t, (r, s) => {
      if (Ha(t, r) && r.substring(0, 1) !== ".") {
        const i = ir(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(ir(e));
  }
}
Ege(ir);
class Oge extends zN {
  constructor(e) {
    super(), this.indexPath_ = e, R(!xe(e) && Ce(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? op(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = ir(e), s = ie.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new ke(n, s);
  }
  maxPost() {
    const e = ie.EMPTY_NODE.updateChild(this.indexPath_, FT);
    return new ke(Cf, e);
  }
  toString() {
    return Ow(this.indexPath_, 0).join("/");
  }
}
class Dge extends zN {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? op(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return ke.MIN;
  }
  maxPost() {
    return ke.MAX;
  }
  makePost(e, n) {
    const r = ir(e);
    return new ke(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const Age = new Dge();
function fJ(t) {
  return { type: "value", snapshotNode: t };
}
function am(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function Dw(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function Aw(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function Mge(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class bY {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    R(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(Dw(n, a)) : R(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(am(n, r)) : o.trackChildChange(Aw(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(Dn, (s, i) => {
      n.hasChild(s) || r.trackChildChange(Dw(s, i));
    }), n.isLeafNode() || n.forEachChild(Dn, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(Aw(s, i, o));
      } else
        r.trackChildChange(am(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? ie.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class Mw {
  constructor(e) {
    this.indexedFilter_ = new bY(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = Mw.getStartPost_(e), this.endPost_ = Mw.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new ke(n, r)) || (r = ie.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = ie.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(ie.EMPTY_NODE);
    const i = this;
    return n.forEachChild(Dn, (o, a) => {
      i.matches(new ke(o, a)) || (s = s.updateImmediateChild(o, ie.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class xge {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new Mw(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new ke(n, r)) || (r = ie.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = ie.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = ie.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(ie.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, ie.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    R(a.numChildren() === this.limit_, "");
    const c = new ke(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(Aw(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(Dw(n, u));
        const _ = a.updateImmediateChild(n, ie.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(am(d.name, d.node)), _.updateImmediateChild(d.name, d.node)) : _;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(Dw(l.name, l.node)), i.trackChildChange(am(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, ie.EMPTY_NODE)) : e;
  }
}
class EY {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = Dn;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return R(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return R(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : om;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return R(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return R(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : Cf;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return R(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === Dn;
  }
  copy() {
    const e = new EY();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function Lge(t) {
  return t.loadsAllData() ? new bY(t.getIndex()) : t.hasLimit() ? new xge(t) : new Mw(t);
}
function k4(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === Dn ? n = "$priority" : t.index_ === Age ? n = "$value" : t.index_ === m_ ? n = "$key" : (R(t.index_ instanceof Oge, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = dr(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = dr(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + dr(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = dr(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + dr(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function N4(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== Dn && (e.i = t.index_.toString()), e;
}
class TI extends sJ {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = UT("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (R(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = TI.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = k4(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), im(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = TI.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = k4(e._queryParams), r = e._path.toString(), s = new AT();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + hY(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = kw(a.responseText);
            } catch {
              As("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && As("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class Uge {
  constructor() {
    this.rootNode_ = ie.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function bI() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function pJ(t, e, n) {
  if (xe(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = Ce(e);
    t.children.has(r) || t.children.set(r, bI());
    const s = t.children.get(r);
    e = Zt(e), pJ(s, e, n);
  }
}
function Fx(t, e, n) {
  t.value !== null ? n(e, t.value) : Fge(t, (r, s) => {
    const i = new Ot(e.toString() + "/" + r);
    Fx(s, i, n);
  });
}
function Fge(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class Yge {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && Xr(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const P4 = 10 * 1e3, Wge = 30 * 1e3, Bge = 5 * 60 * 1e3;
class qge {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new Yge(e);
    const r = P4 + (Wge - P4) * Math.random();
    Nv(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    Xr(e, (s, i) => {
      i > 0 && Ha(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), Nv(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * Bge));
  }
}
var ko;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(ko || (ko = {}));
function SY() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function IY() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function RY(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class EI {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = ko.ACK_USER_WRITE, this.source = SY();
  }
  operationForChild(e) {
    if (xe(this.path)) {
      if (this.affectedTree.value != null)
        return R(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new Ot(e));
        return new EI(ot(), n, this.revert);
      }
    } else return R(Ce(this.path) === e, "operationForChild called for unrelated child."), new EI(Zt(this.path), this.affectedTree, this.revert);
  }
}
class xw {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = ko.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return xe(this.path) ? new xw(this.source, ot()) : new xw(this.source, Zt(this.path));
  }
}
class Tf {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = ko.OVERWRITE;
  }
  operationForChild(e) {
    return xe(this.path) ? new Tf(this.source, ot(), this.snap.getImmediateChild(e)) : new Tf(this.source, Zt(this.path), this.snap);
  }
}
class cm {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = ko.MERGE;
  }
  operationForChild(e) {
    if (xe(this.path)) {
      const n = this.children.subtree(new Ot(e));
      return n.isEmpty() ? null : n.value ? new Tf(this.source, ot(), n.value) : new cm(this.source, ot(), n);
    } else
      return R(Ce(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new cm(this.source, Zt(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class Ch {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (xe(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = Ce(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class Hge {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function jge(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(Mge(o.childName, o.snapshotNode));
  }), ey(t, s, "child_removed", e, r, n), ey(t, s, "child_added", e, r, n), ey(t, s, "child_moved", i, r, n), ey(t, s, "child_changed", e, r, n), ey(t, s, "value", e, r, n), s;
}
function ey(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => zge(t, a, c)), o.forEach((a) => {
    const c = Vge(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function Vge(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function zge(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw $m("Should only compare child_ events.");
  const r = new ke(e.childName, e.snapshotNode), s = new ke(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function GN(t, e) {
  return { eventCache: t, serverCache: e };
}
function Pv(t, e, n, r) {
  return GN(new Ch(e, n, r), t.serverCache);
}
function _J(t, e, n, r) {
  return GN(t.eventCache, new Ch(e, n, r));
}
function SI(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function bf(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let yA;
const Gge = () => (yA || (yA = new oi(Ome)), yA);
class jt {
  constructor(e, n = Gge()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new jt(null);
    return Xr(e, (r, s) => {
      n = n.set(new Ot(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: ot(), value: this.value };
    if (xe(e))
      return null;
    {
      const r = Ce(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(Zt(e), n);
        return i != null ? { path: Sn(new Ot(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (xe(e))
      return this;
    {
      const n = Ce(e), r = this.children.get(n);
      return r !== null ? r.subtree(Zt(e)) : new jt(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (xe(e))
      return new jt(n, this.children);
    {
      const r = Ce(e), i = (this.children.get(r) || new jt(null)).set(Zt(e), n), o = this.children.insert(r, i);
      return new jt(this.value, o);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (xe(e))
      return this.children.isEmpty() ? new jt(null) : new jt(null, this.children);
    {
      const n = Ce(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(Zt(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new jt(null) : new jt(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (xe(e))
      return this.value;
    {
      const n = Ce(e), r = this.children.get(n);
      return r ? r.get(Zt(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (xe(e))
      return n;
    {
      const r = Ce(e), i = (this.children.get(r) || new jt(null)).setTree(Zt(e), n);
      let o;
      return i.isEmpty() ? o = this.children.remove(r) : o = this.children.insert(r, i), new jt(this.value, o);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(ot(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(Sn(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, ot(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (xe(e))
      return null;
    {
      const i = Ce(e), o = this.children.get(i);
      return o ? o.findOnPath_(Zt(e), Sn(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, ot(), n);
  }
  foreachOnPath_(e, n, r) {
    if (xe(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = Ce(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(Zt(e), Sn(n, s), r) : new jt(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(ot(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(Sn(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class Bo {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new Bo(new jt(null));
  }
}
function Ov(t, e, n) {
  if (xe(e))
    return new Bo(new jt(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = Is(s, e);
      return i = i.updateChild(o, n), new Bo(t.writeTree_.set(s, i));
    } else {
      const s = new jt(n), i = t.writeTree_.setTree(e, s);
      return new Bo(i);
    }
  }
}
function Yx(t, e, n) {
  let r = t;
  return Xr(n, (s, i) => {
    r = Ov(r, Sn(e, s), i);
  }), r;
}
function O4(t, e) {
  if (xe(e))
    return Bo.empty();
  {
    const n = t.writeTree_.setTree(e, new jt(null));
    return new Bo(n);
  }
}
function Wx(t, e) {
  return ap(t, e) != null;
}
function ap(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(Is(n.path, e)) : null;
}
function D4(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(Dn, (r, s) => {
    e.push(new ke(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new ke(r, s.value));
  }), e;
}
function Bu(t, e) {
  if (xe(e))
    return t;
  {
    const n = ap(t, e);
    return n != null ? new Bo(new jt(n)) : new Bo(t.writeTree_.subtree(e));
  }
}
function Bx(t) {
  return t.writeTree_.isEmpty();
}
function lm(t, e) {
  return mJ(ot(), t.writeTree_, e);
}
function mJ(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (R(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = mJ(Sn(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(Sn(t, ".priority"), r)), n;
  }
}
function $N(t, e) {
  return wJ(e, t);
}
function $ge(t, e, n, r, s) {
  R(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = Ov(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function Kge(t, e, n, r) {
  R(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = Yx(t.visibleWrites, e, n), t.lastWriteId = r;
}
function Jge(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function Qge(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  R(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && Zge(a, r.path) ? s = !1 : Vi(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return Xge(t), !0;
    if (r.snap)
      t.visibleWrites = O4(t.visibleWrites, r.path);
    else {
      const a = r.children;
      Xr(a, (c) => {
        t.visibleWrites = O4(t.visibleWrites, Sn(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function Zge(t, e) {
  if (t.snap)
    return Vi(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && Vi(Sn(t.path, n), e))
      return !0;
  return !1;
}
function Xge(t) {
  t.visibleWrites = gJ(t.allWrites, eye, ot()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function eye(t) {
  return t.visible;
}
function gJ(t, e, n) {
  let r = Bo.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        Vi(n, o) ? (a = Is(n, o), r = Ov(r, a, i.snap)) : Vi(o, n) && (a = Is(o, n), r = Ov(r, ot(), i.snap.getChild(a)));
      else if (i.children) {
        if (Vi(n, o))
          a = Is(n, o), r = Yx(r, a, i.children);
        else if (Vi(o, n))
          if (a = Is(o, n), xe(a))
            r = Yx(r, ot(), i.children);
          else {
            const c = im(i.children, Ce(a));
            if (c) {
              const l = c.getChild(Zt(a));
              r = Ov(r, ot(), l);
            }
          }
      } else
        throw $m("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function yJ(t, e, n, r, s) {
  if (!r && !s) {
    const i = ap(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = Bu(t.visibleWrites, e);
      if (Bx(o))
        return n;
      if (n == null && !Wx(o, ot()))
        return null;
      {
        const a = n || ie.EMPTY_NODE;
        return lm(o, a);
      }
    }
  } else {
    const i = Bu(t.visibleWrites, e);
    if (!s && Bx(i))
      return n;
    if (!s && n == null && !Wx(i, ot()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && (Vi(l.path, e) || Vi(e, l.path));
      }, a = gJ(t.allWrites, o, e), c = n || ie.EMPTY_NODE;
      return lm(a, c);
    }
  }
}
function tye(t, e, n) {
  let r = ie.EMPTY_NODE;
  const s = ap(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(Dn, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = Bu(t.visibleWrites, e);
    return n.forEachChild(Dn, (o, a) => {
      const c = lm(Bu(i, new Ot(o)), a);
      r = r.updateImmediateChild(o, c);
    }), D4(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = Bu(t.visibleWrites, e);
    return D4(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function nye(t, e, n, r, s) {
  R(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = Sn(e, n);
  if (Wx(t.visibleWrites, i))
    return null;
  {
    const o = Bu(t.visibleWrites, i);
    return Bx(o) ? s.getChild(n) : lm(o, s.getChild(n));
  }
}
function rye(t, e, n, r) {
  const s = Sn(e, n), i = ap(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = Bu(t.visibleWrites, s);
    return lm(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function sye(t, e) {
  return ap(t.visibleWrites, e);
}
function iye(t, e, n, r, s, i, o) {
  let a;
  const c = Bu(t.visibleWrites, e), l = ap(c, ot());
  if (l != null)
    a = l;
  else if (n != null)
    a = lm(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function oye() {
  return {
    visibleWrites: Bo.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function II(t, e, n, r) {
  return yJ(t.writeTree, t.treePath, e, n, r);
}
function kY(t, e) {
  return tye(t.writeTree, t.treePath, e);
}
function A4(t, e, n, r) {
  return nye(t.writeTree, t.treePath, e, n, r);
}
function RI(t, e) {
  return sye(t.writeTree, Sn(t.treePath, e));
}
function aye(t, e, n, r, s, i) {
  return iye(t.writeTree, t.treePath, e, n, r, s, i);
}
function NY(t, e, n) {
  return rye(t.writeTree, t.treePath, e, n);
}
function vJ(t, e) {
  return wJ(Sn(t.treePath, e), t.writeTree);
}
function wJ(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class cye {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    R(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), R(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, Aw(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, Dw(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, am(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, Aw(r, e.snapshotNode, s.oldSnap));
      else
        throw $m("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class lye {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const CJ = new lye();
class PY {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new Ch(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return NY(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : bf(this.viewCache_), i = aye(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function uye(t) {
  return { filter: t };
}
function hye(t, e) {
  R(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), R(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function dye(t, e, n, r, s) {
  const i = new cye();
  let o, a;
  if (n.type === ko.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = qx(t, e, l.path, l.snap, r, s, i) : (R(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !xe(l.path), o = kI(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === ko.MERGE) {
    const l = n;
    l.source.fromUser ? o = pye(t, e, l.path, l.children, r, s, i) : (R(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = Hx(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === ko.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = gye(t, e, l.path, r, s, i) : o = _ye(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === ko.LISTEN_COMPLETE)
    o = mye(t, e, n.path, r, i);
  else
    throw $m("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return fye(e, o, c), { viewCache: o, changes: c };
}
function fye(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = SI(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(fJ(SI(e)));
  }
}
function TJ(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (RI(r, n) != null)
    return e;
  {
    let a, c;
    if (xe(n))
      if (R(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = bf(e), h = l instanceof ie ? l : ie.EMPTY_NODE, u = kY(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = II(r, bf(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = Ce(n);
      if (l === ".priority") {
        R(wh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = A4(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = Zt(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = A4(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = NY(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return Pv(e, a, o.isFullyInitialized() || xe(n), t.filter.filtersNodes());
  }
}
function kI(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (xe(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = Ce(n);
    if (!c.isCompleteForPath(n) && wh(n) > 1)
      return e;
    const p = Zt(n), m = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), m) : l = h.updateChild(c.getNode(), f, m, p, CJ, null);
  }
  const u = _J(e, l, c.isFullyInitialized() || xe(n), h.filtersNodes()), d = new PY(s, u, i);
  return TJ(t, u, n, s, d, a);
}
function qx(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new PY(s, e, i);
  if (xe(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = Pv(e, l, !0, t.filter.filtersNodes());
  else {
    const u = Ce(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = Pv(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = Zt(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (xe(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? vY(d) === ".priority" && _.getChild(oJ(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = ie.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = Pv(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function M4(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function pye(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = Sn(n, c);
    M4(e, Ce(h)) && (a = qx(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = Sn(n, c);
    M4(e, Ce(h)) || (a = qx(t, a, h, l, s, i, o));
  }), a;
}
function x4(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function Hx(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  xe(n) ? l = r : l = new jt(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = x4(t, f, d);
      c = kI(t, c, new Ot(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = x4(t, p, d);
      c = kI(t, c, new Ot(u), _, s, i, o, a);
    }
  }), c;
}
function _ye(t, e, n, r, s, i, o) {
  if (RI(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (xe(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return kI(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (xe(n)) {
      let l = new jt(null);
      return c.getNode().forEachChild(m_, (h, u) => {
        l = l.set(new Ot(h), u);
      }), Hx(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new jt(null);
    return r.foreach((h, u) => {
      const d = Sn(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), Hx(t, e, n, l, s, i, a, o);
  }
}
function mye(t, e, n, r, s) {
  const i = e.serverCache, o = _J(e, i.getNode(), i.isFullyInitialized() || xe(n), i.isFiltered());
  return TJ(t, o, n, r, CJ, s);
}
function gye(t, e, n, r, s, i) {
  let o;
  if (RI(r, n) != null)
    return e;
  {
    const a = new PY(r, e, s), c = e.eventCache.getNode();
    let l;
    if (xe(n) || Ce(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = II(r, bf(e));
      else {
        const u = e.serverCache.getNode();
        R(u instanceof ie, "serverChildren would be complete if leaf node"), h = kY(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = Ce(n);
      let u = NY(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, Zt(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, ie.EMPTY_NODE, Zt(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = II(r, bf(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || RI(r, ot()) != null, Pv(e, l, o, t.filter.filtersNodes());
  }
}
class yye {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new bY(r.getIndex()), i = Lge(r);
    this.processor_ = uye(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(ie.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(ie.EMPTY_NODE, a.getNode(), null), h = new Ch(c, o.isFullyInitialized(), s.filtersNodes()), u = new Ch(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = GN(u, h), this.eventGenerator_ = new Hge(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function vye(t) {
  return t.viewCache_.serverCache.getNode();
}
function wye(t) {
  return SI(t.viewCache_);
}
function Cye(t, e) {
  const n = bf(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !xe(e) && !n.getImmediateChild(Ce(e)).isEmpty()) ? n.getChild(e) : null;
}
function L4(t) {
  return t.eventRegistrations_.length === 0;
}
function Tye(t, e) {
  t.eventRegistrations_.push(e);
}
function U4(t, e, n) {
  const r = [];
  if (n) {
    R(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function F4(t, e, n, r) {
  e.type === ko.MERGE && e.source.queryId !== null && (R(bf(t.viewCache_), "We should always have a full cache before handling merges"), R(SI(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = dye(t.processor_, s, e, n, r);
  return hye(t.processor_, i.viewCache), R(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, bJ(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function bye(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(Dn, (i, o) => {
    r.push(am(i, o));
  }), n.isFullyInitialized() && r.push(fJ(n.getNode())), bJ(t, r, n.getNode(), e);
}
function bJ(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return jge(t.eventGenerator_, e, n, s);
}
let NI;
class EJ {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function Eye(t) {
  R(!NI, "__referenceConstructor has already been defined"), NI = t;
}
function Sye() {
  return R(NI, "Reference.ts has not been loaded"), NI;
}
function Iye(t) {
  return t.views.size === 0;
}
function OY(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return R(i != null, "SyncTree gave us an op for an invalid query."), F4(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat(F4(o, e, n, r));
    return i;
  }
}
function SJ(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = II(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof ie ? (a = kY(n, r), c = !1) : (a = ie.EMPTY_NODE, c = !1);
    const l = GN(new Ch(a, c, !1), new Ch(r, s, !1));
    return new yye(e, l);
  }
  return o;
}
function Rye(t, e, n, r, s, i) {
  const o = SJ(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), Tye(o, n), bye(o, n);
}
function kye(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = Th(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(U4(l, n, r)), L4(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(U4(c, n, r)), L4(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !Th(t) && i.push(new (Sye())(e._repo, e._path)), { removed: i, events: o };
}
function IJ(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function qu(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || Cye(r, e);
  return n;
}
function RJ(t, e) {
  if (e._queryParams.loadsAllData())
    return KN(t);
  {
    const r = e._queryIdentifier;
    return t.views.get(r);
  }
}
function kJ(t, e) {
  return RJ(t, e) != null;
}
function Th(t) {
  return KN(t) != null;
}
function KN(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let PI;
function Nye(t) {
  R(!PI, "__referenceConstructor has already been defined"), PI = t;
}
function Pye() {
  return R(PI, "Reference.ts has not been loaded"), PI;
}
let Oye = 1;
class Y4 {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new jt(null), this.pendingWriteTree_ = oye(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function NJ(t, e, n, r, s) {
  return $ge(t.pendingWriteTree_, e, n, r, s), s ? Jm(t, new Tf(SY(), e, n)) : [];
}
function Dye(t, e, n, r) {
  Kge(t.pendingWriteTree_, e, n, r);
  const s = jt.fromObject(n);
  return Jm(t, new cm(SY(), e, s));
}
function wu(t, e, n = !1) {
  const r = Jge(t.pendingWriteTree_, e);
  if (Qge(t.pendingWriteTree_, e)) {
    let i = new jt(null);
    return r.snap != null ? i = i.set(ot(), !0) : Xr(r.children, (o) => {
      i = i.set(new Ot(o), !0);
    }), Jm(t, new EI(r.path, i, n));
  } else
    return [];
}
function YT(t, e, n) {
  return Jm(t, new Tf(IY(), e, n));
}
function Aye(t, e, n) {
  const r = jt.fromObject(n);
  return Jm(t, new cm(IY(), e, r));
}
function Mye(t, e) {
  return Jm(t, new xw(IY(), e));
}
function xye(t, e, n) {
  const r = AY(t, n);
  if (r) {
    const s = MY(r), i = s.path, o = s.queryId, a = Is(i, e), c = new xw(RY(o), a);
    return xY(t, i, c);
  } else
    return [];
}
function OI(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || kJ(o, e))) {
    const c = kye(o, e, n, r);
    Iye(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => Th(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = Fye(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = AJ(t, _);
            t.listenProvider_.startListening(Dv(m), Lw(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(Dv(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(JN(d));
        t.listenProvider_.stopListening(Dv(d), f);
      }));
    }
    Yye(t, l);
  }
  return a;
}
function PJ(t, e, n, r) {
  const s = AY(t, r);
  if (s != null) {
    const i = MY(s), o = i.path, a = i.queryId, c = Is(o, e), l = new Tf(RY(a), c, n);
    return xY(t, o, l);
  } else
    return [];
}
function Lye(t, e, n, r) {
  const s = AY(t, r);
  if (s) {
    const i = MY(s), o = i.path, a = i.queryId, c = Is(o, e), l = jt.fromObject(n), h = new cm(RY(a), c, l);
    return xY(t, o, h);
  } else
    return [];
}
function jx(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = Is(d, s);
    i = i || qu(f, p), o = o || Th(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || Th(a), i = i || qu(a, ot())) : (a = new EJ(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = ie.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((f, p) => {
    const _ = qu(p, ot());
    _ && (i = i.updateImmediateChild(f, _));
  }));
  const l = kJ(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = JN(e);
    R(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = Wye();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = $N(t.pendingWriteTree_, s);
  let u = Rye(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = RJ(a, e);
    u = u.concat(Bye(t, e, d));
  }
  return u;
}
function DY(t, e, n) {
  const s = t.pendingWriteTree_, i = t.syncPointTree_.findOnPath(e, (o, a) => {
    const c = Is(o, e), l = qu(a, c);
    if (l)
      return l;
  });
  return yJ(s, e, i, n, !0);
}
function Uye(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = Is(l, n);
    r = r || qu(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || qu(s, ot()) : (s = new EJ(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new Ch(r, !0, !1) : null, a = $N(t.pendingWriteTree_, e._path), c = SJ(s, e, a, i ? o.getNode() : ie.EMPTY_NODE, i);
  return wye(c);
}
function Jm(t, e) {
  return OJ(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    $N(t.pendingWriteTree_, ot())
  );
}
function OJ(t, e, n, r) {
  if (xe(t.path))
    return DJ(t, e, n, r);
  {
    const s = e.get(ot());
    n == null && s != null && (n = qu(s, ot()));
    let i = [];
    const o = Ce(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = vJ(r, o);
      i = i.concat(OJ(a, c, l, h));
    }
    return s && (i = i.concat(OY(s, t, r, n))), i;
  }
}
function DJ(t, e, n, r) {
  const s = e.get(ot());
  n == null && s != null && (n = qu(s, ot()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = vJ(r, o), h = t.operationForChild(o);
    h && (i = i.concat(DJ(h, a, c, l)));
  }), s && (i = i.concat(OY(s, t, r, n))), i;
}
function AJ(t, e) {
  const n = e.query, r = Lw(t, n);
  return {
    hashFn: () => (vye(e) || ie.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? xye(t, n._path, r) : Mye(t, n._path);
      {
        const i = Mme(s, n);
        return OI(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function Lw(t, e) {
  const n = JN(e);
  return t.queryToTagMap.get(n);
}
function JN(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function AY(t, e) {
  return t.tagToQueryMap.get(e);
}
function MY(t) {
  const e = t.indexOf("$");
  return R(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new Ot(t.substr(0, e))
  };
}
function xY(t, e, n) {
  const r = t.syncPointTree_.get(e);
  R(r, "Missing sync point for query tag that we're tracking");
  const s = $N(t.pendingWriteTree_, e);
  return OY(r, n, s, null);
}
function Fye(t) {
  return t.fold((e, n, r) => {
    if (n && Th(n))
      return [KN(n)];
    {
      let s = [];
      return n && (s = IJ(n)), Xr(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function Dv(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (Pye())(t._repo, t._path) : t;
}
function Yye(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = JN(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function Wye() {
  return Oye++;
}
function Bye(t, e, n) {
  const r = e._path, s = Lw(t, e), i = AJ(t, n), o = t.listenProvider_.startListening(Dv(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    R(!Th(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!xe(l) && h && Th(h))
        return [KN(h).query];
      {
        let d = [];
        return h && (d = d.concat(IJ(h).map((f) => f.query))), Xr(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(Dv(h), Lw(t, h));
    }
  }
  return o;
}
class LY {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new LY(n);
  }
  node() {
    return this.node_;
  }
}
class UY {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = Sn(this.path_, e);
    return new UY(this.syncTree_, n);
  }
  node() {
    return DY(this.syncTree_, this.path_);
  }
}
const qye = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, W4 = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (R(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return Hye(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return jye(t[".sv"], e);
  R(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, Hye = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      R(!1, "Unexpected server value: " + t);
  }
}, jye = function(t, e, n) {
  t.hasOwnProperty("increment") || R(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && R(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (R(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const o = s.getValue();
  return typeof o != "number" ? r : o + r;
}, MJ = function(t, e, n, r) {
  return FY(e, new UY(n, t), r);
}, xJ = function(t, e, n) {
  return FY(t, new LY(e), n);
};
function FY(t, e, n) {
  const r = t.getPriority().val(), s = W4(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = W4(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new Sr(a, ir(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new Sr(s))), o.forEachChild(Dn, (a, c) => {
      const l = FY(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class YY {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function WY(t, e) {
  let n = e instanceof Ot ? e : new Ot(e), r = t, s = Ce(n);
  for (; s !== null; ) {
    const i = im(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new YY(s, r, i), n = Zt(n), s = Ce(n);
  }
  return r;
}
function Qm(t) {
  return t.node.value;
}
function LJ(t, e) {
  t.node.value = e, Vx(t);
}
function UJ(t) {
  return t.node.childCount > 0;
}
function Vye(t) {
  return Qm(t) === void 0 && !UJ(t);
}
function QN(t, e) {
  Xr(t.node.children, (n, r) => {
    e(new YY(n, t, r));
  });
}
function FJ(t, e, n, r) {
  n && !r && e(t), QN(t, (s) => {
    FJ(s, e, !0, r);
  }), n && r && e(t);
}
function zye(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function WT(t) {
  return new Ot(t.parent === null ? t.name : WT(t.parent) + "/" + t.name);
}
function Vx(t) {
  t.parent !== null && Gye(t.parent, t.name, t);
}
function Gye(t, e, n) {
  const r = Vye(n), s = Ha(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, Vx(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, Vx(t));
}
const $ye = /[\[\].#$\/\u0000-\u001F\u007F]/, Kye = /[\[\].#$\u0000-\u001F\u007F]/, vA = 10 * 1024 * 1024, BY = function(t) {
  return typeof t == "string" && t.length !== 0 && !$ye.test(t);
}, YJ = function(t) {
  return typeof t == "string" && t.length !== 0 && !Kye.test(t);
}, Jye = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), YJ(t);
}, Qye = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !pY(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Ha(t, ".sv");
}, Zye = function(t, e, n, r) {
  ZN(jN(t, "value"), e, n);
}, ZN = function(t, e, n) {
  const r = n instanceof Ot ? new pge(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + wd(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + wd(r) + " with contents = " + e.toString());
  if (pY(e))
    throw new Error(t + "contains " + e.toString() + " " + wd(r));
  if (typeof e == "string" && e.length > vA / 3 && VN(e) > vA)
    throw new Error(t + "contains a string greater than " + vA + " utf8 bytes " + wd(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (Xr(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !BY(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + wd(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      _ge(r, o), ZN(t, a, r), mge(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + wd(r) + " in addition to actual children.");
  }
}, Xye = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = Ow(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1)) {
        if (!BY(i[o]))
          throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      }
  }
  e.sort(fge);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && Vi(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, eve = function(t, e, n, r) {
  const s = jN(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  Xr(e, (o, a) => {
    const c = new Ot(o);
    if (ZN(s, a, Sn(n, c)), vY(c) === ".priority" && !Qye(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), Xye(s, i);
}, WJ = function(t, e, n, r) {
  if (!YJ(n))
    throw new Error(jN(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, tve = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), WJ(t, e, n);
}, nve = function(t, e) {
  if (Ce(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, rve = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !BY(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !Jye(n))
    throw new Error(jN(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class sve {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function XN(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !wY(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function BJ(t, e, n) {
  XN(t, n), qJ(t, (r) => wY(r, e));
}
function Zi(t, e, n) {
  XN(t, n), qJ(t, (r) => Vi(r, e) || Vi(e, r));
}
function qJ(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (ive(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function ive(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      kv && zr("event: " + n.toString()), Km(r);
    }
  }
}
const ove = "repo_interrupt", ave = 25;
class cve {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new sve(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = bI(), this.transactionQueueTree_ = new YY(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function lve(t, e, n) {
  if (t.stats_ = gY(t.repoInfo_), t.forceRestClient_ || Fme())
    t.server_ = new TI(t.repoInfo_, (r, s, i, o) => {
      B4(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => q4(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        dr(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new Gc(t.repoInfo_, e, (r, s, i, o) => {
      B4(t, r, s, i, o);
    }, (r) => {
      q4(t, r);
    }, (r) => {
      hve(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = Hme(t.repoInfo_, () => new qge(t.stats_, t.server_)), t.infoData_ = new Uge(), t.infoSyncTree_ = new Y4({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = YT(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), qY(t, "connected", !1), t.serverSyncTree_ = new Y4({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      Zi(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function uve(t) {
  const n = t.infoData_.getNode(new Ot(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + n;
}
function eP(t) {
  return qye({
    timestamp: uve(t)
  });
}
function B4(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new Ot(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = gI(n, (l) => ir(l));
      o = Lye(t.serverSyncTree_, i, c, s);
    } else {
      const c = ir(n);
      o = PJ(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = gI(n, (l) => ir(l));
    o = Aye(t.serverSyncTree_, i, c);
  } else {
    const c = ir(n);
    o = YT(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = um(t, i)), Zi(t.eventQueue_, a, o);
}
function q4(t, e) {
  qY(t, "connected", e), e === !1 && _ve(t);
}
function hve(t, e) {
  Xr(e, (n, r) => {
    qY(t, n, r);
  });
}
function qY(t, e, n) {
  const r = new Ot("/.info/" + e), s = ir(n);
  t.infoData_.updateSnapshot(r, s);
  const i = YT(t.infoSyncTree_, r, s);
  Zi(t.eventQueue_, r, i);
}
function HY(t) {
  return t.nextWriteId_++;
}
function dve(t, e, n) {
  const r = Uye(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = ir(s).withIndex(e._queryParams.getIndex());
    jx(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = YT(t.serverSyncTree_, e._path, i);
    else {
      const a = Lw(t.serverSyncTree_, e);
      o = PJ(t.serverSyncTree_, e._path, i, a);
    }
    return Zi(t.eventQueue_, e._path, o), OI(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (BT(t, "get for query " + dr(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function fve(t, e, n, r, s) {
  BT(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = eP(t), o = ir(n, r), a = DY(t.serverSyncTree_, e), c = xJ(o, a, i), l = HY(t), h = NJ(t.serverSyncTree_, e, c, l, !0);
  XN(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || As("set at " + e + " failed: " + d);
    const _ = wu(t.serverSyncTree_, l, !p);
    Zi(t.eventQueue_, e, _), zx(t, s, d, f);
  });
  const u = VY(t, e);
  um(t, u), Zi(t.eventQueue_, u, []);
}
function pve(t, e, n, r) {
  BT(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = eP(t), o = {};
  if (Xr(n, (a, c) => {
    s = !1, o[a] = MJ(Sn(e, a), ir(c), t.serverSyncTree_, i);
  }), s)
    zr("update() called with empty data.  Don't do anything."), zx(t, r, "ok", void 0);
  else {
    const a = HY(t), c = Dye(t.serverSyncTree_, e, o, a);
    XN(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || As("update at " + e + " failed: " + l);
      const d = wu(t.serverSyncTree_, a, !u), f = d.length > 0 ? um(t, e) : e;
      Zi(t.eventQueue_, f, d), zx(t, r, l, h);
    }), Xr(n, (l) => {
      const h = VY(t, Sn(e, l));
      um(t, h);
    }), Zi(t.eventQueue_, e, []);
  }
}
function _ve(t) {
  BT(t, "onDisconnectEvents");
  const e = eP(t), n = bI();
  Fx(t.onDisconnect_, ot(), (s, i) => {
    const o = MJ(s, i, t.serverSyncTree_, e);
    pJ(n, s, o);
  });
  let r = [];
  Fx(n, ot(), (s, i) => {
    r = r.concat(YT(t.serverSyncTree_, s, i));
    const o = VY(t, s);
    um(t, o);
  }), t.onDisconnect_ = bI(), Zi(t.eventQueue_, ot(), r);
}
function mve(t, e, n) {
  let r;
  Ce(e._path) === ".info" ? r = jx(t.infoSyncTree_, e, n) : r = jx(t.serverSyncTree_, e, n), BJ(t.eventQueue_, e._path, r);
}
function H4(t, e, n) {
  let r;
  Ce(e._path) === ".info" ? r = OI(t.infoSyncTree_, e, n) : r = OI(t.serverSyncTree_, e, n), BJ(t.eventQueue_, e._path, r);
}
function gve(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt(ove);
}
function BT(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), zr(n, ...e);
}
function zx(t, e, n, r) {
  e && Km(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function HJ(t, e, n) {
  return DY(t.serverSyncTree_, e, n) || ie.EMPTY_NODE;
}
function jY(t, e = t.transactionQueueTree_) {
  if (e || tP(t, e), Qm(e)) {
    const n = VJ(t, e);
    R(n.length > 0, "Sending zero length transaction queue"), n.every(
      (s) => s.status === 0
      /* TransactionStatus.RUN */
    ) && yve(t, WT(e), n);
  } else UJ(e) && QN(e, (n) => {
    jY(t, n);
  });
}
function yve(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = HJ(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    R(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = Is(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    BT(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(wu(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      tP(t, WY(t.transactionQueueTree_, e)), jY(t, t.transactionQueueTree_), Zi(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        Km(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        As("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      um(t, e);
    }
  }, o);
}
function um(t, e) {
  const n = jJ(t, e), r = WT(n), s = VJ(t, n);
  return vve(t, s, r), r;
}
function vve(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const o = e.filter((a) => a.status === 0).map((a) => a.currentWriteId);
  for (let a = 0; a < e.length; a++) {
    const c = e[a], l = Is(n, c.path);
    let h = !1, u;
    if (R(l !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), c.status === 4)
      h = !0, u = c.abortReason, s = s.concat(wu(t.serverSyncTree_, c.currentWriteId, !0));
    else if (c.status === 0)
      if (c.retryCount >= ave)
        h = !0, u = "maxretry", s = s.concat(wu(t.serverSyncTree_, c.currentWriteId, !0));
      else {
        const d = HJ(t, c.path, o);
        c.currentInputSnapshot = d;
        const f = e[a].update(d.val());
        if (f !== void 0) {
          ZN("transaction failed: Data returned ", f, c.path);
          let p = ir(f);
          typeof f == "object" && f != null && Ha(f, ".priority") || (p = p.updatePriority(d.getPriority()));
          const m = c.currentWriteId, v = eP(t), y = xJ(p, d, v);
          c.currentOutputSnapshotRaw = p, c.currentOutputSnapshotResolved = y, c.currentWriteId = HY(t), o.splice(o.indexOf(m), 1), s = s.concat(NJ(t.serverSyncTree_, c.path, y, c.currentWriteId, c.applyLocally)), s = s.concat(wu(t.serverSyncTree_, m, !0));
        } else
          h = !0, u = "nodata", s = s.concat(wu(t.serverSyncTree_, c.currentWriteId, !0));
      }
    Zi(t.eventQueue_, n, s), s = [], h && (e[a].status = 2, function(d) {
      setTimeout(d, Math.floor(0));
    }(e[a].unwatcher), e[a].onComplete && (u === "nodata" ? r.push(() => e[a].onComplete(null, !1, e[a].currentInputSnapshot)) : r.push(() => e[a].onComplete(new Error(u), !1, null))));
  }
  tP(t, t.transactionQueueTree_);
  for (let a = 0; a < r.length; a++)
    Km(r[a]);
  jY(t, t.transactionQueueTree_);
}
function jJ(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = Ce(e); n !== null && Qm(r) === void 0; )
    r = WY(r, n), e = Zt(e), n = Ce(e);
  return r;
}
function VJ(t, e) {
  const n = [];
  return zJ(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function zJ(t, e, n) {
  const r = Qm(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  QN(e, (s) => {
    zJ(t, s, n);
  });
}
function tP(t, e) {
  const n = Qm(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, LJ(e, n.length > 0 ? n : void 0);
  }
  QN(e, (r) => {
    tP(t, r);
  });
}
function VY(t, e) {
  const n = WT(jJ(t, e)), r = WY(t.transactionQueueTree_, e);
  return zye(r, (s) => {
    wA(t, s);
  }), wA(t, r), FJ(r, (s) => {
    wA(t, s);
  }), n;
}
function wA(t, e) {
  const n = Qm(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (R(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (R(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(wu(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? LJ(e, void 0) : n.length = i + 1, Zi(t.eventQueue_, WT(e), s);
    for (let o = 0; o < r.length; o++)
      Km(r[o]);
  }
}
function wve(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function Cve(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : As(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const j4 = function(t, e) {
  const n = Tve(t), r = n.namespace;
  n.domain === "firebase.com" && ml(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && ml("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || Nme();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new Q7(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new Ot(n.pathString)
  };
}, Tve = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = wve(t.substring(h, u)));
    const d = Cve(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class bve {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + dr(this.snapshot.exportVal());
  }
}
class Eve {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class GJ {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return R(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class zY {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return xe(this._path) ? null : vY(this._path);
  }
  get ref() {
    return new Pl(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = N4(this._queryParams), n = _Y(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return N4(this._queryParams);
  }
  isEqual(e) {
    if (e = os(e), !(e instanceof zY))
      return !1;
    const n = this._repo === e._repo, r = wY(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + dge(this._path);
  }
}
class Pl extends zY {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new EY(), !1);
  }
  get parent() {
    const e = oJ(this._path);
    return e === null ? null : new Pl(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class Uw {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new Ot(e), r = Gx(this.ref, e);
    return new Uw(this._node.getChild(n), r, Dn);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (r, s) => e(new Uw(s, Gx(this.ref, r), Dn)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new Ot(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function IE(t, e) {
  return t = os(t), t._checkNotDeleted("ref"), e !== void 0 ? Gx(t._root, e) : t._root;
}
function Gx(t, e) {
  return t = os(t), Ce(t._path) === null ? tve("child", "path", e) : WJ("child", "path", e), new Pl(t._repo, Sn(t._path, e));
}
function Sve(t, e) {
  t = os(t), nve("set", t._path), Zye("set", e, t._path);
  const n = new AT();
  return fve(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function Ive(t, e) {
  eve("update", e, t._path);
  const n = new AT();
  return pve(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function Rve(t) {
  t = os(t);
  const e = new GJ(() => {
  }), n = new nP(e);
  return dve(t._repo, t, n).then((r) => new Uw(r, new Pl(t._repo, t._path), t._queryParams.getIndex()));
}
class nP {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new bve("value", this, new Uw(e.snapshotNode, new Pl(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new Eve(this, e, n) : null;
  }
  matches(e) {
    return e instanceof nP ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function kve(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      H4(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new GJ(n, i || void 0), a = new nP(o);
  return mve(t._repo, t, a), () => H4(t._repo, t, a);
}
function Nve(t, e, n, r) {
  return kve(t, "value", e, n, r);
}
function Pve(t, ...e) {
  let n = os(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
Eye(Pl);
Nye(Pl);
const Ove = "FIREBASE_DATABASE_EMULATOR_HOST", $x = {};
let Dve = !1;
function Ave(t, e, n, r) {
  t.repoInfo_ = new Q7(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function Mve(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || ml("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), zr("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = j4(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[Ove]), c ? (i = `http://${c}?ns=${a.namespace}`, o = j4(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new Wme(t.name, t.options, e);
  rve("Invalid Firebase Database URL", o), xe(o.path) || ml("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = Lve(a, t, l, new Yme(t.name, n));
  return new Uve(h, t);
}
function xve(t, e) {
  const n = $x[e];
  (!n || n[t.key] !== t) && ml(`Database ${e}(${t.repoInfo_}) has already been deleted.`), gve(t), delete n[t.key];
}
function Lve(t, e, n, r) {
  let s = $x[e.name];
  s || (s = {}, $x[e.name] = s);
  let i = s[t.toURLString()];
  return i && ml("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new cve(t, Dve, n, r), s[t.toURLString()] = i, i;
}
class Uve {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (lve(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Pl(this._repo, ot())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (xve(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && ml("Cannot call " + e + " on a deleted database.");
  }
}
function Fve(t = M7(), e) {
  const n = A7(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = I7("database");
    r && Yve(n, ...r);
  }
  return n;
}
function Yve(t, e, n, r = {}) {
  t = os(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && ml("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && ml('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new DS(DS.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : R7(r.mockUserToken, t.app.options.projectId);
    i = new DS(o);
  }
  Ave(s, e, n, i);
}
function Wve(t) {
  bme(xT), wf(new vh(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return Mve(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), zc(d4, f4, t), zc(d4, f4, "esm2017");
}
Gc.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
Gc.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
Wve();
const $J = "firebasestorage.googleapis.com", KJ = "storageBucket", Bve = 2 * 60 * 1e3, qve = 10 * 60 * 1e3;
class zn extends Nl {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(CA(e), `Firebase Storage: ${n} (${CA(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, zn.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return CA(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var Yn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(Yn || (Yn = {}));
function CA(t) {
  return "storage/" + t;
}
function GY() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new zn(Yn.UNKNOWN, t);
}
function Hve(t) {
  return new zn(Yn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function jve(t) {
  return new zn(Yn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function Vve() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new zn(Yn.UNAUTHENTICATED, t);
}
function zve() {
  return new zn(Yn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function Gve(t) {
  return new zn(Yn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function $ve() {
  return new zn(Yn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function Kve() {
  return new zn(Yn.CANCELED, "User canceled the upload/download.");
}
function Jve(t) {
  return new zn(Yn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function Qve(t) {
  return new zn(Yn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function Zve() {
  return new zn(Yn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + KJ + "' property when initializing the app?");
}
function Xve() {
  return new zn(Yn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function ewe() {
  return new zn(Yn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function twe(t) {
  return new zn(Yn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function Kx(t) {
  return new zn(Yn.INVALID_ARGUMENT, t);
}
function JJ() {
  return new zn(Yn.APP_DELETED, "The Firebase app was deleted.");
}
function nwe(t) {
  return new zn(Yn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function Av(t, e) {
  return new zn(Yn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function ty(t) {
  throw new zn(Yn.INTERNAL_ERROR, "Internal error: " + t);
}
class wi {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = wi.makeFromUrl(e, n);
    } catch {
      return new wi(e, "");
    }
    if (r.path === "")
      return r;
    throw Qve(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(w) {
      w.path.charAt(w.path.length - 1) === "/" && (w.path_ = w.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(w) {
      w.path_ = decodeURIComponent(w.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === $J ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), g = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let w = 0; w < g.length; w++) {
      const de = g[w], we = de.regex.exec(e);
      if (we) {
        const fe = we[de.indices.bucket];
        let ys = we[de.indices.path];
        ys || (ys = ""), r = new wi(fe, ys), de.postModify(r);
        break;
      }
    }
    if (r == null)
      throw Jve(e);
    return r;
  }
}
class rwe {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function swe(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let g;
    a === 1 ? (a = 2, g = 0) : g = (r + Math.random()) * 1e3, u(g);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function iwe(t) {
  t(!1);
}
function owe(t) {
  return t !== void 0;
}
function awe(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function $Y(t) {
  return typeof t == "string" || t instanceof String;
}
function V4(t) {
  return KY() && t instanceof Blob;
}
function KY() {
  return typeof Blob < "u" && !r_e();
}
function z4(t, e, n, r) {
  if (r < e)
    throw Kx(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw Kx(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function rP(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function QJ(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var Zd;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(Zd || (Zd = {}));
function cwe(t, e) {
  const n = t >= 500 && t < 600, s = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, i = e.indexOf(t) !== -1;
  return n || s || i;
}
class lwe {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new RE(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === Zd.NO_ERROR, c = i.getStatus();
        if (!a || cwe(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === Zd.ABORT;
          r(!1, new RE(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new RE(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          owe(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = GY();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? JJ() : Kve();
        o(c);
      } else {
        const c = $ve();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new RE(!1, null, !0)) : this.backoffId_ = swe(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && iwe(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class RE {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function uwe(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function hwe(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function dwe(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function fwe(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function pwe(t, e, n, r, s, i, o = !0) {
  const a = QJ(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return dwe(l, e), uwe(l, n), hwe(l, i), fwe(l, r), new lwe(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function _we() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function mwe(...t) {
  const e = _we();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (KY())
      return new Blob(t);
    throw new zn(Yn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function gwe(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function ywe(t) {
  if (typeof atob > "u")
    throw twe("base-64");
  return atob(t);
}
const Ea = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class TA {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function vwe(t, e) {
  switch (t) {
    case Ea.RAW:
      return new TA(ZJ(e));
    case Ea.BASE64:
    case Ea.BASE64URL:
      return new TA(XJ(t, e));
    case Ea.DATA_URL:
      return new TA(Cwe(e), Twe(e));
  }
  throw GY();
}
function ZJ(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const i = r, o = t.charCodeAt(++n);
        r = 65536 | (i & 1023) << 10 | o & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function wwe(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw Av(Ea.DATA_URL, "Malformed data URL.");
  }
  return ZJ(e);
}
function XJ(t, e) {
  switch (t) {
    case Ea.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw Av(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case Ea.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw Av(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = ywe(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : Av(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class eQ {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw Av(Ea.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = bwe(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function Cwe(t) {
  const e = new eQ(t);
  return e.base64 ? XJ(Ea.BASE64, e.rest) : wwe(e.rest);
}
function Twe(t) {
  return new eQ(t).contentType;
}
function bwe(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class fu {
  constructor(e, n) {
    let r = 0, s = "";
    V4(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (V4(this.data_)) {
      const r = this.data_, s = gwe(r, e, n);
      return s === null ? null : new fu(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new fu(r, !0);
    }
  }
  static getBlob(...e) {
    if (KY()) {
      const n = e.map((r) => r instanceof fu ? r.data_ : r);
      return new fu(mwe.apply(null, n));
    } else {
      const n = e.map((o) => $Y(o) ? vwe(Ea.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new fu(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function tQ(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return awe(e) ? e : null;
}
function Ewe(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function Swe(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function nQ(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function Iwe(t, e) {
  return e;
}
class vs {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || Iwe;
  }
}
let kE = null;
function Rwe(t) {
  return !$Y(t) || t.length < 2 ? t : nQ(t);
}
function rQ() {
  if (kE)
    return kE;
  const t = [];
  t.push(new vs("bucket")), t.push(new vs("generation")), t.push(new vs("metageneration")), t.push(new vs("name", "fullPath", !0));
  function e(i, o) {
    return Rwe(o);
  }
  const n = new vs("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new vs("size");
  return s.xform = r, t.push(s), t.push(new vs("timeCreated")), t.push(new vs("updated")), t.push(new vs("md5Hash", null, !0)), t.push(new vs("cacheControl", null, !0)), t.push(new vs("contentDisposition", null, !0)), t.push(new vs("contentEncoding", null, !0)), t.push(new vs("contentLanguage", null, !0)), t.push(new vs("contentType", null, !0)), t.push(new vs("metadata", "customMetadata", !0)), kE = t, kE;
}
function kwe(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new wi(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function Nwe(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return kwe(r, t), r;
}
function sQ(t, e, n) {
  const r = tQ(e);
  return r === null ? null : Nwe(t, r, n);
}
function Pwe(t, e, n, r) {
  const s = tQ(e);
  if (s === null || !$Y(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((l) => {
    const h = t.bucket, u = t.fullPath, d = "/b/" + o(h) + "/o/" + o(u), f = rP(d, n, r), p = QJ({
      alt: "media",
      token: l
    });
    return f + p;
  })[0];
}
function Owe(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class JY {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function iQ(t) {
  if (!t)
    throw GY();
}
function Dwe(t, e) {
  function n(r, s) {
    const i = sQ(t, s, e);
    return iQ(i !== null), i;
  }
  return n;
}
function Awe(t, e) {
  function n(r, s) {
    const i = sQ(t, s, e);
    return iQ(i !== null), Pwe(i, s, t.host, t._protocol);
  }
  return n;
}
function oQ(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? /* This exact message string is the only consistent part of the */ /* server's error response that identifies it as an App Check error. */ n.getErrorText().includes("Firebase App Check token is invalid") ? s = zve() : s = Vve() : n.getStatus() === 402 ? s = jve(t.bucket) : n.getStatus() === 403 ? s = Gve(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function aQ(t) {
  const e = oQ(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = Hve(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function Mwe(t, e, n) {
  const r = e.fullServerUrl(), s = rP(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new JY(s, i, Awe(t, n), o);
  return a.errorHandler = aQ(e), a;
}
function xwe(t, e) {
  const n = e.fullServerUrl(), r = rP(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new JY(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = aQ(e), a;
}
function Lwe(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function Uwe(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = Lwe(null, e)), r;
}
function Fwe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = Uwe(e, r, s), h = Owe(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = fu.getBlob(u, r, d);
  if (f === null)
    throw Xve();
  const p = { name: l.fullPath }, _ = rP(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new JY(_, m, Dwe(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = oQ(e), y;
}
class Ywe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = Zd.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = Zd.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = Zd.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw ty("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw ty("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw ty("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw ty("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw ty("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class Wwe extends Ywe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function QY() {
  return new Wwe();
}
class Ef {
  constructor(e, n) {
    this._service = e, n instanceof wi ? this._location = n : this._location = wi.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new Ef(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new wi(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return nQ(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = Ewe(this._location.path);
    if (e === null)
      return null;
    const n = new wi(this._location.bucket, e);
    return new Ef(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw nwe(e);
  }
}
function Bwe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = Fwe(t.storage, t._location, rQ(), new fu(e, !0), n);
  return t.storage.makeRequestWithTokens(r, QY).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function qwe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = Mwe(t.storage, t._location, rQ());
  return t.storage.makeRequestWithTokens(e, QY).then((n) => {
    if (n === null)
      throw ewe();
    return n;
  });
}
function Hwe(t) {
  t._throwIfRoot("deleteObject");
  const e = xwe(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, QY);
}
function jwe(t, e) {
  const n = Swe(t._location.path, e), r = new wi(t._location.bucket, n);
  return new Ef(t.storage, r);
}
function Vwe(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function zwe(t, e) {
  return new Ef(t, e);
}
function cQ(t, e) {
  if (t instanceof ZY) {
    const n = t;
    if (n._bucket == null)
      throw Zve();
    const r = new Ef(n, n._bucket);
    return e != null ? cQ(r, e) : r;
  } else
    return e !== void 0 ? jwe(t, e) : t;
}
function Gwe(t, e) {
  if (e && Vwe(e)) {
    if (t instanceof ZY)
      return zwe(t, e);
    throw Kx("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return cQ(t, e);
}
function G4(t, e) {
  const n = e == null ? void 0 : e[KJ];
  return n == null ? null : wi.makeFromBucketSpec(n, t);
}
function $we(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : R7(s, t.app.options.projectId));
}
class ZY {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = $J, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = Bve, this._maxUploadRetryTime = qve, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = wi.makeFromBucketSpec(s, this._host) : this._bucket = G4(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = wi.makeFromBucketSpec(this._url, e) : this._bucket = G4(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    z4(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    z4(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new Ef(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new rwe(JJ());
    {
      const o = pwe(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const $4 = "@firebase/storage", K4 = "0.11.2";
const lQ = "storage";
function Kwe(t, e, n) {
  return t = os(t), Bwe(t, e, n);
}
function Jwe(t) {
  return t = os(t), qwe(t);
}
function Qwe(t) {
  return t = os(t), Hwe(t);
}
function bA(t, e) {
  return t = os(t), Gwe(t, e);
}
function Zwe(t = M7(), e) {
  t = os(t);
  const r = A7(t, lQ).getImmediate({
    identifier: e
  }), s = I7("storage");
  return s && Xwe(r, ...s), r;
}
function Xwe(t, e, n, r = {}) {
  $we(t, e, n, r);
}
function eCe(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new ZY(n, r, s, e, xT);
}
function tCe() {
  wf(new vh(
    lQ,
    eCe,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), zc($4, K4, ""), zc($4, K4, "esm2017");
}
tCe();
class uQ {
  constructor() {
    this.db = Fve(), this.storage = Zwe();
  }
  async get(e) {
    let n = await Rve(IE(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await Ive(IE(this.db, e), n);
  }
  async set(e, n) {
    return await Sve(IE(this.db, e), n);
  }
  async watch(e, n, r) {
    return Nve(Pve(IE(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await Kwe(bA(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await Qwe(bA(this.storage, e));
  }
  async objectUrl(e) {
    return await Jwe(bA(this.storage, e));
  }
}
class AIt extends uQ {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch(Kg.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set(Kg.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set(Kg.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get(Kg.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get(Kg.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
const nCe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, rCe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, sCe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, iCe = {
  localhost: nCe,
  hybrid: rCe,
  ihunterdev: sCe,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, oCe = !1;
class g_ {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return iCe[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && oCe ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  static firebaseConfig() {
    var e;
    if (!process)
      return process && ((e = process == null ? void 0 : process.env) == null ? void 0 : e.FIREBASE_CONFIG) && JSON.parse(process.env.FIREBASE_CONFIG);
  }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class NE extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const pi = "application/json", aCe = "application/octet-stream";
class hQ {
  constructor() {
  }
  get apiServer() {
    return g_.config.functionURL;
  }
  get tileServer() {
    return g_.config.tileURL;
  }
  get webServer() {
    return g_.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let s = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || pi,
        Accept: r || pi
      }
    };
    return this.request(e, s);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: pi
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let i = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || pi,
        Accept: s || pi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async patch(e, n, r, s) {
    let i = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || pi,
        Accept: s || pi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async post(e, n, r, s) {
    let i = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || pi,
        Accept: s || pi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async delete(e, n, r) {
    let s = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || pi,
        Accept: r || pi
      }
    };
    return this.request(e, s);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new NE(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(pi)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new NE(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(pi) ? r(await i.json()) : o && o.startsWith(aCe) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new NE(500, i.message, i))) : s(new NE(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class cCe {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var AS = { exports: {} };
function lCe(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
AS.exports = lCe;
const z = (AS.exports == null ? {} : AS.exports).default || AS.exports, tu = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, J4 = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class Ai {
  static TileServer() {
    return g_.config.tileURL;
  }
  static WebServer() {
    return g_.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return tu.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return z(tu.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return z(tu.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return z(tu.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + z(J4.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), z(tu.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), z(tu.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + z(J4.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return z(tu.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return z(tu.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const qs = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, EA = {
  REGIONS: "/product/regions/{region}"
}, Hs = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, Q4 = {
  PRODUCT: "/product/skus/{product}"
}, Z4 = {
  PRODUCT: "/product/prices/{product}"
}, uCe = {
  PROMOTIONS: "/store/promotions"
};
class hCe {
  static AllRegions() {
    return SA(z(EA.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return EA.REGIONS;
  }
  static Regions(e) {
    return z(EA.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Hs.REGION;
  }
  static Region(e) {
    return z(Hs.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return qs.REGION;
  }
  static RegionMetadata(e) {
    return z(qs.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Hs.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return z(Hs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return qs.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return z(qs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Hs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return z(Hs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return qs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return z(qs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Hs.RESOURCE;
  }
  static Resource(e, n) {
    return z(Hs.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return qs.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return z(qs.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Hs.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return z(Hs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return qs.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return z(qs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Hs.MAP;
  }
  static Map(e, n, r, s) {
    return z(Hs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return qs.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return z(qs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Hs.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return z(Hs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return qs.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return z(qs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return SA(z(Q4.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return z(Q4.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return SA(z(Z4.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return z(Z4.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return uCe.PROMOTIONS;
  }
}
const SA = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class dCe {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const X4 = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, e3 = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, t3 = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, n3 = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, IA = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, r3 = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, fCe = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
class da {
  static RegionPropertyMap() {
    return X4.METADATA;
  }
  static SubscriptionPropertyMap() {
    return e3.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return t3.METADATA;
  }
  static ResourcePropertyMap() {
    return n3.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return IA.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return IA.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return r3.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties(X4.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(e3.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(t3.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(n3.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(IA.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(r3.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(fCe.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
}
var MS = { exports: {} };
function pCe(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
MS.exports = pCe;
const xS = (MS.exports == null ? {} : MS.exports).default || MS.exports;
var LS = { exports: {} };
async function _Ce(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
LS.exports = _Ce;
const Jx = (LS.exports == null ? {} : LS.exports).default || LS.exports;
Promise.allMap = Jx;
class mCe {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, da.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, da.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, da.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, da.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, da.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[da.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, da.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = xS(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = xS(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[da.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, da.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = xS(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class gCe extends dCe {
  constructor(e) {
    super(e), this.http = new hQ(), this.builder = new mCe(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(Ai.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(Ai.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(Ai.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Ai.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(Ai.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Ai.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(Ai.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + Ai.CountyMetadata(e)), await this.http.getJson(Ai.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + Ai.CountyProductMetadata(e, n)), await this.http.getJson(Ai.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const ny = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class yCe {
  static Apple(e) {
    return z(ny.APPLE, { uid: e });
  }
  static Android(e) {
    return z(ny.ANDROID, { uid: e });
  }
  static Web(e) {
    return z(ny.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return z(ny.WEB, { uid: e }) + z(ny.SKU, { region: n, sku: r });
  }
}
const Wr = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, ry = {
  CUSTOMER: `/${Wr.USERS}/{uid}/${Wr.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${Wr.USERS}/{uid}/${Wr.SUBSCRIPTIONS}`,
  PRODUCT: `/${Wr.PRODUCTS}/{product}`,
  PRICE: `/${Wr.PRODUCTS}/{product}/${Wr.PRICES}/{price}`
};
class vCe {
  static Customer(e, n) {
    return z(ry.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return z(ry.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return ry.CUSTOMER;
  }
  static PriceRoute() {
    return ry.PRICE;
  }
  static ProductRoute() {
    return ry.PRODUCT;
  }
  static Users() {
    return Wr.USERS;
  }
  static Customers() {
    return Wr.CUSTOMERS;
  }
  static Subscriptions() {
    return Wr.SUBSCRIPTIONS;
  }
  static Schedules() {
    return Wr.SCHEDULES;
  }
  static Products() {
    return Wr.PRODUCTS;
  }
  static Prices() {
    return Wr.PRICES;
  }
  static Promotions() {
    return Wr.PROMOTIONS;
  }
  static Payments() {
    return Wr.PAYMENTS;
  }
  static Coupons() {
    return Wr.COUPONS;
  }
  static Invoices() {
    return Wr.INVOICES;
  }
}
const Kh = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class Vp {
  static Preferences(e) {
    return z(Kh.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return z(Kh.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return Kh.OPT_IN;
  }
  static Status(e) {
    return z(Kh.STATUS, { uid: e });
  }
  static StatusRoute() {
    return Kh.STATUS;
  }
  static Email(e) {
    return z(Kh.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return Kh.EMAIL;
  }
}
const s3 = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class wCe {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, s3.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * s3.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const Jh = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, RA = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, i3 = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, kA = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, o3 = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, sy = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, Qh = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, a3 = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class XY {
  static OutgoingContactRequestProcessedRoute() {
    return Jh.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return z(Jh.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return z(Jh.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return Jh.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return z(Jh.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return Jh.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return z(Jh.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return RA.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return z(RA.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return z(RA.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return z(i3.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return i3.DATA;
  }
  static Chat(e) {
    return z(kA.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var r, s;
    const i = Date.now();
    let o = e.length > 2, a = {}, c = {}, l = {};
    for (let u of e)
      a[u] = !0, c[u] = ((r = n == null ? void 0 : n.lastviewed) == null ? void 0 : r[u]) || i, l[u] = ((s = n == null ? void 0 : n.lastcleared) == null ? void 0 : s[u]) || i;
    let h = {
      participants: a,
      isgroupchat: o,
      lastviewed: c,
      lastcleared: l
    };
    return o && (h.chatname = kA.GROUP_NAME), h;
  }
  static ChatParticipants(e) {
    return z(kA.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return z(o3.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return o3.DATA;
  }
  static MessageRoute() {
    return sy.ROUTE;
  }
  static Message(e, n) {
    const r = XY.MessageKey(n);
    return z(sy.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: Qh.NOTIFY_ALL,
      type: sy.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return z(sy.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return sy.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return z(Qh.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return z(Qh.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === Qh.NOTIFY_ALL ? Qh.TITLE_FROM_NOTIFY_ALL : z(Qh.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return z(a3.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return Qh.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return a3.UNKNOWN_NAME;
  }
}
const dQ = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
}, k = function(t, e) {
  if (!t)
    throw Zm(e);
}, Zm = function(t) {
  return new Error("Firebase Database (" + dQ.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
}, fQ = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, CCe = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, e2 = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(fQ(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : CCe(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], o = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const a = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const c = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || o == null || a == null || c == null)
        throw new TCe();
      const l = i << 2 | o >> 4;
      if (r.push(l), a !== 64) {
        const h = o << 4 & 240 | a >> 2;
        if (r.push(h), c !== 64) {
          const u = a << 6 & 192 | c;
          r.push(u);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class TCe extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const pQ = function(t) {
  const e = fQ(t);
  return e2.encodeByteArray(e, !0);
}, DI = function(t) {
  return pQ(t).replace(/\./g, "");
}, AI = function(t) {
  try {
    return e2.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function bCe(t) {
  return _Q(void 0, t);
}
function _Q(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !ECe(n) || (t[n] = _Q(t[n], e[n]));
  return t;
}
function ECe(t) {
  return t !== "__proto__";
}
function SCe() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const ICe = () => SCe().__FIREBASE_DEFAULTS__, RCe = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, kCe = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && AI(t[1]);
  return e && JSON.parse(e);
}, sP = () => {
  try {
    return ICe() || RCe() || kCe();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, NCe = (t) => {
  var e, n;
  return (n = (e = sP()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, mQ = (t) => {
  const e = NCe(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, PCe = () => {
  var t;
  return (t = sP()) === null || t === void 0 ? void 0 : t.config;
}, OCe = (t) => {
  var e;
  return (e = sP()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
class qT {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function gQ(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    DI(JSON.stringify(n)),
    DI(JSON.stringify(o)),
    ""
  ].join(".");
}
function Da() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function t2() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(Da());
}
function DCe() {
  var t;
  const e = (t = sP()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function ACe() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function yQ() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function vQ() {
  return dQ.NODE_ADMIN === !0;
}
function MCe() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function xCe() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const LCe = "FirebaseError";
class Ol extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = LCe, Object.setPrototypeOf(this, Ol.prototype), Error.captureStackTrace && Error.captureStackTrace(this, HT.prototype.create);
  }
}
class HT {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? UCe(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new Ol(s, a, r);
  }
}
function UCe(t, e) {
  return t.replace(FCe, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const FCe = /\{\$([^}]+)}/g;
function Fw(t) {
  return JSON.parse(t);
}
function fr(t) {
  return JSON.stringify(t);
}
const wQ = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = Fw(AI(i[0]) || ""), n = Fw(AI(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, YCe = function(t) {
  const e = wQ(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, WCe = function(t) {
  const e = wQ(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function ja(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function hm(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function c3(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function MI(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function Qx(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (l3(i) && l3(o)) {
      if (!Qx(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function l3(t) {
  return t !== null && typeof t == "object";
}
function n2(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function mv(t) {
  const e = {};
  return t.replace(/^\?/, "").split("&").forEach((n) => {
    if (n) {
      const [r, s] = n.split("=");
      e[decodeURIComponent(r)] = decodeURIComponent(s);
    }
  }), e;
}
function gv(t) {
  const e = t.indexOf("?");
  if (!e)
    return "";
  const n = t.indexOf("#", e);
  return t.substring(e, n > 0 ? n : void 0);
}
class BCe {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function qCe(t, e) {
  const n = new HCe(t, e);
  return n.subscribe.bind(n);
}
class HCe {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    jCe(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = NA), s.error === void 0 && (s.error = NA), s.complete === void 0 && (s.complete = NA);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function jCe(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function NA() {
}
function iP(t, e) {
  return `${t} failed: ${e} argument `;
}
const VCe = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, k(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, oP = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function as(t) {
  return t && t._delegate ? t._delegate : t;
}
class bh {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const Cd = "[DEFAULT]";
class zCe {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new qT();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if ($Ce(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: Cd });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = Cd) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = Cd) {
    return this.instances.has(e);
  }
  getOptions(e = Cd) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: GCe(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = Cd) {
    return this.component ? this.component.multipleInstances ? e : Cd : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function GCe(t) {
  return t === Cd ? void 0 : t;
}
function $Ce(t) {
  return t.instantiationMode === "EAGER";
}
class KCe {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new zCe(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var It;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(It || (It = {}));
const JCe = {
  debug: It.DEBUG,
  verbose: It.VERBOSE,
  info: It.INFO,
  warn: It.WARN,
  error: It.ERROR,
  silent: It.SILENT
}, QCe = It.INFO, ZCe = {
  [It.DEBUG]: "log",
  [It.VERBOSE]: "log",
  [It.INFO]: "info",
  [It.WARN]: "warn",
  [It.ERROR]: "error"
}, XCe = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = ZCe[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class r2 {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = QCe, this._logHandler = XCe, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in It))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? JCe[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, It.DEBUG, ...e), this._logHandler(this, It.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, It.VERBOSE, ...e), this._logHandler(this, It.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, It.INFO, ...e), this._logHandler(this, It.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, It.WARN, ...e), this._logHandler(this, It.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, It.ERROR, ...e), this._logHandler(this, It.ERROR, ...e);
  }
}
const eTe = (t, e) => e.some((n) => t instanceof n);
let u3, h3;
function tTe() {
  return u3 || (u3 = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function nTe() {
  return h3 || (h3 = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const CQ = /* @__PURE__ */ new WeakMap(), Zx = /* @__PURE__ */ new WeakMap(), TQ = /* @__PURE__ */ new WeakMap(), PA = /* @__PURE__ */ new WeakMap(), s2 = /* @__PURE__ */ new WeakMap();
function rTe(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(Hu(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && CQ.set(n, t);
  }).catch(() => {
  }), s2.set(e, t), e;
}
function sTe(t) {
  if (Zx.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  Zx.set(t, e);
}
let Xx = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return Zx.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || TQ.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return Hu(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function iTe(t) {
  Xx = t(Xx);
}
function oTe(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(OA(this), e, ...n);
    return TQ.set(r, e.sort ? e.sort() : [e]), Hu(r);
  } : nTe().includes(t) ? function(...e) {
    return t.apply(OA(this), e), Hu(CQ.get(this));
  } : function(...e) {
    return Hu(t.apply(OA(this), e));
  };
}
function aTe(t) {
  return typeof t == "function" ? oTe(t) : (t instanceof IDBTransaction && sTe(t), eTe(t, tTe()) ? new Proxy(t, Xx) : t);
}
function Hu(t) {
  if (t instanceof IDBRequest)
    return rTe(t);
  if (PA.has(t))
    return PA.get(t);
  const e = aTe(t);
  return e !== t && (PA.set(t, e), s2.set(e, t)), e;
}
const OA = (t) => s2.get(t);
function cTe(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = Hu(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(Hu(o.result), c.oldVersion, c.newVersion, Hu(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const lTe = ["get", "getKey", "getAll", "getAllKeys", "count"], uTe = ["put", "add", "delete", "clear"], DA = /* @__PURE__ */ new Map();
function d3(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (DA.get(e))
    return DA.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = uTe.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || lTe.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return DA.set(e, i), i;
}
iTe((t) => ({
  ...t,
  get: (e, n, r) => d3(e, n) || t.get(e, n, r),
  has: (e, n) => !!d3(e, n) || t.has(e, n)
}));
class hTe {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((e) => {
      if (dTe(e)) {
        const n = e.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((e) => e).join(" ");
  }
}
function dTe(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const eL = "@firebase/app", f3 = "0.9.8", Sf = new r2("@firebase/app"), fTe = "@firebase/app-compat", pTe = "@firebase/analytics-compat", _Te = "@firebase/analytics", mTe = "@firebase/app-check-compat", gTe = "@firebase/app-check", yTe = "@firebase/auth", vTe = "@firebase/auth-compat", wTe = "@firebase/database", CTe = "@firebase/database-compat", TTe = "@firebase/functions", bTe = "@firebase/functions-compat", ETe = "@firebase/installations", STe = "@firebase/installations-compat", ITe = "@firebase/messaging", RTe = "@firebase/messaging-compat", kTe = "@firebase/performance", NTe = "@firebase/performance-compat", PTe = "@firebase/remote-config", OTe = "@firebase/remote-config-compat", DTe = "@firebase/storage", ATe = "@firebase/storage-compat", MTe = "@firebase/firestore", xTe = "@firebase/firestore-compat", LTe = "firebase", UTe = "9.20.0", tL = "[DEFAULT]", FTe = {
  [eL]: "fire-core",
  [fTe]: "fire-core-compat",
  [_Te]: "fire-analytics",
  [pTe]: "fire-analytics-compat",
  [gTe]: "fire-app-check",
  [mTe]: "fire-app-check-compat",
  [yTe]: "fire-auth",
  [vTe]: "fire-auth-compat",
  [wTe]: "fire-rtdb",
  [CTe]: "fire-rtdb-compat",
  [TTe]: "fire-fn",
  [bTe]: "fire-fn-compat",
  [ETe]: "fire-iid",
  [STe]: "fire-iid-compat",
  [ITe]: "fire-fcm",
  [RTe]: "fire-fcm-compat",
  [kTe]: "fire-perf",
  [NTe]: "fire-perf-compat",
  [PTe]: "fire-rc",
  [OTe]: "fire-rc-compat",
  [DTe]: "fire-gcs",
  [ATe]: "fire-gcs-compat",
  [MTe]: "fire-fst",
  [xTe]: "fire-fst-compat",
  "fire-js": "fire-js",
  [LTe]: "fire-js-all"
}, xI = /* @__PURE__ */ new Map(), nL = /* @__PURE__ */ new Map();
function YTe(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    Sf.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function If(t) {
  const e = t.name;
  if (nL.has(e))
    return Sf.debug(`There were multiple attempts to register component ${e}.`), !1;
  nL.set(e, t);
  for (const n of xI.values())
    YTe(n, t);
  return !0;
}
function bQ(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const WTe = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, ju = new HT("app", "Firebase", WTe);
class BTe {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new bh(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw ju.create("app-deleted", { appName: this._name });
  }
}
const jT = UTe;
function qTe(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: tL, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw ju.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = PCe()), !n)
    throw ju.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = xI.get(s);
  if (i) {
    if (Qx(n, i.options) && Qx(r, i.config))
      return i;
    throw ju.create("duplicate-app", { appName: s });
  }
  const o = new KCe(s);
  for (const c of nL.values())
    o.addComponent(c);
  const a = new BTe(n, r, o);
  return xI.set(s, a), a;
}
function EQ(t = tL) {
  const e = xI.get(t);
  if (!e && t === tL)
    return qTe();
  if (!e)
    throw ju.create("no-app", { appName: t });
  return e;
}
function $c(t, e, n) {
  var r;
  let s = (r = FTe[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), Sf.warn(a.join(" "));
    return;
  }
  If(new bh(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const HTe = "firebase-heartbeat-database", jTe = 1, Yw = "firebase-heartbeat-store";
let AA = null;
function SQ() {
  return AA || (AA = cTe(HTe, jTe, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(Yw);
      }
    }
  }).catch((t) => {
    throw ju.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), AA;
}
async function VTe(t) {
  try {
    return (await SQ()).transaction(Yw).objectStore(Yw).get(IQ(t));
  } catch (e) {
    if (e instanceof Ol)
      Sf.warn(e.message);
    else {
      const n = ju.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      Sf.warn(n.message);
    }
  }
}
async function p3(t, e) {
  try {
    const n = (await SQ()).transaction(Yw, "readwrite");
    return await n.objectStore(Yw).put(e, IQ(t)), n.done;
  } catch (n) {
    if (n instanceof Ol)
      Sf.warn(n.message);
    else {
      const r = ju.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      Sf.warn(r.message);
    }
  }
}
function IQ(t) {
  return `${t.name}!${t.options.appId}`;
}
const zTe = 1024, GTe = 30 * 24 * 60 * 60 * 1e3;
class $Te {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new JTe(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const e = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), n = _3();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === n || this._heartbeatsCache.heartbeats.some((r) => r.date === n)))
      return this._heartbeatsCache.heartbeats.push({ date: n, agent: e }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const s = new Date(r.date).valueOf();
        return Date.now() - s <= GTe;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = _3(), { heartbeatsToSend: n, unsentEntries: r } = KTe(this._heartbeatsCache.heartbeats), s = DI(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function _3() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function KTe(t, e = zTe) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), m3(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), m3(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class JTe {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return MCe() ? xCe().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await VTe(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return p3(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return p3(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function m3(t) {
  return DI(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function QTe(t) {
  If(new bh(
    "platform-logger",
    (e) => new hTe(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), If(new bh(
    "heartbeat",
    (e) => new $Te(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), $c(eL, f3, t), $c(eL, f3, "esm2017"), $c("fire-js", "");
}
QTe("");
const g3 = "@firebase/database", y3 = "0.14.4";
let RQ = "";
function ZTe(t) {
  RQ = t;
}
class XTe {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), fr(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : Fw(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class ebe {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return ja(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const kQ = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new XTe(e);
    }
  } catch {
  }
  return new ebe();
}, Md = kQ("localStorage"), tbe = kQ("sessionStorage"), y_ = new r2("@firebase/database"), nbe = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), NQ = function(t) {
  const e = VCe(t), n = new BCe();
  n.update(e);
  const r = n.digest();
  return e2.encodeByteArray(r);
}, VT = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += VT.apply(null, r) : typeof r == "object" ? e += fr(r) : e += r, e += " ";
  }
  return e;
};
let Mv = null, v3 = !0;
const rbe = function(t, e) {
  k(!e, "Can't turn on custom loggers persistently."), y_.logLevel = It.VERBOSE, Mv = y_.log.bind(y_);
}, Gr = function(...t) {
  if (v3 === !0 && (v3 = !1, Mv === null && tbe.get("logging_enabled") === !0 && rbe()), Mv) {
    const e = VT.apply(null, t);
    Mv(e);
  }
}, zT = function(t) {
  return function(...e) {
    Gr(t, ...e);
  };
}, rL = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + VT(...t);
  y_.error(e);
}, gl = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${VT(...t)}`;
  throw y_.error(e), new Error(e);
}, Ms = function(...t) {
  const e = "FIREBASE WARNING: " + VT(...t);
  y_.warn(e);
}, sbe = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && Ms("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, i2 = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, ibe = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, dm = "[MIN_NAME]", Rf = "[MAX_NAME]", cp = function(t, e) {
  if (t === e)
    return 0;
  if (t === dm || e === Rf)
    return -1;
  if (e === dm || t === Rf)
    return 1;
  {
    const n = w3(t), r = w3(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, obe = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, iy = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + fr(e));
}, o2 = function(t) {
  if (typeof t != "object" || t === null)
    return fr(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += fr(e[r]), n += ":", n += o2(t[e[r]]);
  return n += "}", n;
}, PQ = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function es(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const OQ = function(t) {
  k(!i2(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, abe = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, cbe = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function lbe(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const ube = new RegExp("^-?(0*)\\d{1,10}$"), hbe = -2147483648, dbe = 2147483647, w3 = function(t) {
  if (ube.test(t)) {
    const e = Number(t);
    if (e >= hbe && e <= dbe)
      return e;
  }
  return null;
}, Xm = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw Ms("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, fbe = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, xv = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class pbe {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    Ms(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class _be {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? (Gr("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', Ms(e);
  }
}
class US {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
US.OWNER = "owner";
const a2 = "5", DQ = "v", AQ = "s", MQ = "r", xQ = "f", LQ = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, UQ = "ls", FQ = "p", sL = "ac", YQ = "websocket", WQ = "long_polling";
class BQ {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = Md.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && Md.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function mbe(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function qQ(t, e, n) {
  k(typeof e == "string", "typeof type must == string"), k(typeof n == "object", "typeof params must == object");
  let r;
  if (e === YQ)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === WQ)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  mbe(t) && (n.ns = t.namespace);
  const s = [];
  return es(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class gbe {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    ja(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return bCe(this.counters_);
  }
}
const MA = {}, xA = {};
function c2(t) {
  const e = t.toString();
  return MA[e] || (MA[e] = new gbe()), MA[e];
}
function ybe(t, e) {
  const n = t.toString();
  return xA[n] || (xA[n] = e()), xA[n];
}
class vbe {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && Xm(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const C3 = "start", wbe = "close", Cbe = "pLPCommand", Tbe = "pRTLPCB", HQ = "id", jQ = "pw", VQ = "ser", bbe = "cb", Ebe = "seg", Sbe = "ts", Ibe = "d", Rbe = "dframe", zQ = 1870, GQ = 30, kbe = zQ - GQ, Nbe = 25e3, Pbe = 3e4;
class s_ {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = zT(e), this.stats_ = c2(n), this.urlFn = (c) => (this.appCheckToken && (c[sL] = this.appCheckToken), qQ(n, WQ, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new vbe(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(Pbe)), ibe(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new l2((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === C3)
            this.id = a, this.password = c;
          else if (o === wbe)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[C3] = "t", r[VQ] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[bbe] = this.scriptTagHolder.uniqueCallbackIdentifier), r[DQ] = a2, this.transportSessionId && (r[AQ] = this.transportSessionId), this.lastSessionId && (r[UQ] = this.lastSessionId), this.applicationId && (r[FQ] = this.applicationId), this.appCheckToken && (r[sL] = this.appCheckToken), typeof location < "u" && location.hostname && LQ.test(location.hostname) && (r[MQ] = xQ);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    s_.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    s_.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return s_.forceAllow_ ? !0 : !s_.forceDisallow_ && typeof document < "u" && document.createElement != null && !abe() && !cbe();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = fr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = pQ(n), s = PQ(r, kbe);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[Rbe] = "t", r[HQ] = e, r[jQ] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = fr(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
}
class l2 {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = nbe(), window[Cbe + this.uniqueCallbackIdentifier] = e, window[Tbe + this.uniqueCallbackIdentifier] = n, this.myIFrame = l2.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        Gr("frame writing exception"), a.stack && Gr(a.stack), Gr(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || Gr("No IE domain setting required");
      } catch {
        const n = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + n + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[HQ] = this.myID, e[jQ] = this.myPW, e[VQ] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + GQ + r.length <= zQ; ) {
        const i = this.pendingSegs.shift();
        r = r + "&" + Ebe + s + "=" + i.seg + "&" + Sbe + s + "=" + i.ts + "&" + Ibe + s + "=" + i.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(Nbe)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          Gr("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const Obe = 16384, Dbe = 45e3;
let LI = null;
typeof MozWebSocket < "u" ? LI = MozWebSocket : typeof WebSocket < "u" && (LI = WebSocket);
class To {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = zT(this.connId), this.stats_ = c2(n), this.connURL = To.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[DQ] = a2, typeof location < "u" && location.hostname && LQ.test(location.hostname) && (o[MQ] = xQ), n && (o[AQ] = n), r && (o[UQ] = r), s && (o[sL] = s), i && (o[FQ] = i), qQ(e, YQ, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, Md.set("previous_websocket_failure", !0);
    try {
      let r;
      vQ(), this.mySock = new LI(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    To.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && LI !== null && !To.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return Md.isInMemoryStorage || Md.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    Md.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = Fw(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (k(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = fr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = PQ(n, Obe);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(Dbe));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
To.responsesRequiredToBeHealthy = 2;
To.healthyTimeout = 3e4;
class Ww {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [s_, To];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = To && To.isAvailable();
    let r = n && !To.previouslyFailed();
    if (e.webSocketOnly && (n || Ms("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [To];
    else {
      const s = this.transports_ = [];
      for (const i of Ww.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      Ww.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
Ww.globalTransportInitialized_ = !1;
const Abe = 6e4, Mbe = 5e3, xbe = 10 * 1024, Lbe = 100 * 1024, LA = "t", T3 = "d", Ube = "s", b3 = "r", Fbe = "e", E3 = "o", S3 = "a", I3 = "n", R3 = "p", Ybe = "h";
class Wbe {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = zT("c:" + this.id + ":"), this.transportManager_ = new Ww(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = xv(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > Lbe ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > xbe ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (LA in e) {
      const n = e[LA];
      n === S3 ? this.upgradeIfSecondaryHealthy_() : n === b3 ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === E3 && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = iy("t", e), r = iy("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: R3, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: S3, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: I3, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = iy("t", e), r = iy("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = iy(LA, e);
    if (T3 in e) {
      const r = e[T3];
      if (n === Ybe) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === I3) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === Ube ? this.onConnectionShutdown_(r) : n === b3 ? this.onReset_(r) : n === Fbe ? rL("Server Error: " + r) : n === E3 ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : rL("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), a2 !== r && Ms("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), xv(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(Abe));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : xv(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(Mbe));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: R3, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (Md.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class $Q {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class KQ {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, k(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    k(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class UI extends KQ {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !t2() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new UI();
  }
  getInitialEvent(e) {
    return k(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const k3 = 32, N3 = 768;
class Dt {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function at() {
  return new Dt("");
}
function Te(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function Eh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function Xt(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new Dt(t.pieces_, e);
}
function u2(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function Bbe(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function Bw(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function JQ(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new Dt(e, 0);
}
function In(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof Dt)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new Dt(n, 0);
}
function Le(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function Rs(t, e) {
  const n = Te(t), r = Te(e);
  if (n === null)
    return e;
  if (n === r)
    return Rs(Xt(t), Xt(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function qbe(t, e) {
  const n = Bw(t, 0), r = Bw(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = cp(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function h2(t, e) {
  if (Eh(t) !== Eh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function zi(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (Eh(t) > Eh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class Hbe {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = Bw(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += oP(this.parts_[r]);
    QQ(this);
  }
}
function jbe(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += oP(e), QQ(t);
}
function Vbe(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= oP(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function QQ(t) {
  if (t.byteLength_ > N3)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + N3 + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > k3)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + k3 + ") or object contains a cycle " + Td(t));
}
function Td(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class d2 extends KQ {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new d2();
  }
  getInitialEvent(e) {
    return k(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const oy = 1e3, zbe = 60 * 5 * 1e3, P3 = 30 * 1e3, Gbe = 1.3, $be = 3e4, Kbe = "server_kill", O3 = 3;
class Kc extends $Q {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = Kc.nextPersistentConnectionId_++, this.log_ = zT("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = oy, this.maxReconnectDelay_ = zbe, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !vQ())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    d2.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && UI.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(fr(i)), k(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new qT(), r = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (i) => {
        const o = i.d;
        i.s === "ok" ? n.resolve(o) : n.reject(o);
      }
    };
    this.outstandingGets_.push(r), this.outstandingGetCount_++;
    const s = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(s), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), k(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), k(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      Kc.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && ja(e, "w")) {
      const r = hm(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        Ms(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || WCe(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = P3);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = YCe(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), k(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const s = r.d;
            this.log_("reportStats", "Error sending stats: " + s);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + fr(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : rL("Unrecognized action received from server: " + fr(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    k(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = oy, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = oy, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > $be && (this.reconnectDelay_ = oy), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * Gbe);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + Kc.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        k(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? Gr("getToken() completed but was canceled") : (Gr("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new Wbe(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            Ms(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(Kbe);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && Ms(u), c());
      }
    }
  }
  interrupt(e) {
    Gr("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    Gr("Resuming connection for reason: " + e), delete this.interruptReasons_[e], c3(this.interruptReasons_) && (this.reconnectDelay_ = oy, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => o2(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new Dt(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    Gr("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= O3 && (this.reconnectDelay_ = P3, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    Gr("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= O3 && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + RQ.replace(/\./g, "-")] = 1, t2() ? e["framework.cordova"] = 1 : yQ() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = UI.getInstance().currentlyOnline();
    return c3(this.interruptReasons_) && e;
  }
}
Kc.nextPersistentConnectionId_ = 0;
Kc.nextConnectionId_ = 0;
class Ne {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new Ne(e, n);
  }
}
class aP {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new Ne(dm, e), s = new Ne(dm, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return Ne.MIN;
  }
}
let PE;
class ZQ extends aP {
  static get __EMPTY_NODE() {
    return PE;
  }
  static set __EMPTY_NODE(e) {
    PE = e;
  }
  compare(e, n) {
    return cp(e.name, n.name);
  }
  isDefinedOn(e) {
    throw Zm("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return Ne.MIN;
  }
  maxPost() {
    return new Ne(Rf, PE);
  }
  makePost(e, n) {
    return k(typeof e == "string", "KeyIndex indexValue must always be a string."), new Ne(e, PE);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const v_ = new ZQ();
class OE {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class Ar {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? Ar.RED, this.left = s ?? ai.EMPTY_NODE, this.right = i ?? ai.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new Ar(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return ai.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return ai.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, Ar.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, Ar.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
Ar.RED = !0;
Ar.BLACK = !1;
class Jbe {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new Ar(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class ai {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = ai.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new ai(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, Ar.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new ai(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, Ar.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new OE(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new OE(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new OE(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new OE(this.root_, null, this.comparator_, !0, e);
  }
}
ai.EMPTY_NODE = new Jbe();
function Qbe(t, e) {
  return cp(t.name, e.name);
}
function f2(t, e) {
  return cp(t, e);
}
let iL;
function Zbe(t) {
  iL = t;
}
const XQ = function(t) {
  return typeof t == "number" ? "number:" + OQ(t) : "string:" + t;
}, eZ = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    k(typeof e == "string" || typeof e == "number" || typeof e == "object" && ja(e, ".sv"), "Priority must be a string or number.");
  } else
    k(t === iL || t.isEmpty(), "priority of unexpected type.");
  k(t === iL || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let D3;
class Ir {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = Ir.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, k(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), eZ(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    D3 = e;
  }
  static get __childrenNodeConstructor() {
    return D3;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new Ir(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : Ir.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return Le(e) ? this : Te(e) === ".priority" ? this.priorityNode_ : Ir.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : Ir.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Te(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (k(r !== ".priority" || Eh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, Ir.__childrenNodeConstructor.EMPTY_NODE.updateChild(Xt(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + XQ(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += OQ(this.value_) : e += this.value_, this.lazyHash_ = NQ(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === Ir.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof Ir.__childrenNodeConstructor ? -1 : (k(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = Ir.VALUE_TYPE_ORDER.indexOf(n), i = Ir.VALUE_TYPE_ORDER.indexOf(r);
    return k(s >= 0, "Unknown leaf type: " + n), k(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
Ir.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let tZ, nZ;
function Xbe(t) {
  tZ = t;
}
function eEe(t) {
  nZ = t;
}
class tEe extends aP {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? cp(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return Ne.MIN;
  }
  maxPost() {
    return new Ne(Rf, new Ir("[PRIORITY-POST]", nZ));
  }
  makePost(e, n) {
    const r = tZ(e);
    return new Ne(n, new Ir("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const An = new tEe(), nEe = Math.log(2);
class rEe {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / nEe, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const FI = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new Ar(d, u.node, Ar.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new Ar(d, u.node, Ar.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new Ar(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, Ar.BLACK) : (d(m, Ar.BLACK), d(m, Ar.RED));
    }
    return h;
  }, o = new rEe(t.length), a = i(o);
  return new ai(r || e, a);
};
let UA;
const Ap = {};
class Tc {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return k(Ap && An, "ChildrenNode.ts has not been loaded"), UA = UA || new Tc({ ".priority": Ap }, { ".priority": An }), UA;
  }
  get(e) {
    const n = hm(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof ai ? n : null;
  }
  hasIndex(e) {
    return ja(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    k(e !== v_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(Ne.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = FI(r, e.getCompare()) : a = Ap;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new Tc(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = MI(this.indexes_, (s, i) => {
      const o = hm(this.indexSet_, i);
      if (k(o, "Missing index implementation for " + i), s === Ap)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(Ne.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), FI(a, o.getCompare());
        } else
          return Ap;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new Ne(e.name, a))), c.insert(e, e.node);
      }
    });
    return new Tc(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = MI(this.indexes_, (s) => {
      if (s === Ap)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new Ne(e.name, i)) : s;
      }
    });
    return new Tc(r, this.indexSet_);
  }
}
let ay;
class oe {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && eZ(this.priorityNode_), this.children_.isEmpty() && k(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return ay || (ay = new oe(new ai(f2), null, Tc.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || ay;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new oe(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? ay : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = Te(e);
    return n === null ? this : this.getImmediateChild(n).getChild(Xt(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (k(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new Ne(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? ay : this.priorityNode_;
      return new oe(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Te(e);
    if (r === null)
      return n;
    {
      k(Te(e) !== ".priority" || Eh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(Xt(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(An, (o, a) => {
      n[o] = a.val(e), r++, i && oe.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + XQ(this.getPriority().val()) + ":"), this.forEachChild(An, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : NQ(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new Ne(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new Ne(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new Ne(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, Ne.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, Ne.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === GT ? -1 : 0;
  }
  withIndex(e) {
    if (e === v_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new oe(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === v_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(An), s = n.getIterator(An);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === v_ ? null : this.indexMap_.get(e.toString());
  }
}
oe.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class sEe extends oe {
  constructor() {
    super(new ai(f2), oe.EMPTY_NODE, Tc.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return oe.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const GT = new sEe();
Object.defineProperties(Ne, {
  MIN: {
    value: new Ne(dm, oe.EMPTY_NODE)
  },
  MAX: {
    value: new Ne(Rf, GT)
  }
});
ZQ.__EMPTY_NODE = oe.EMPTY_NODE;
Ir.__childrenNodeConstructor = oe;
Zbe(GT);
eEe(GT);
const iEe = !0;
function or(t, e = null) {
  if (t === null)
    return oe.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), k(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new Ir(n, or(e));
  }
  if (!(t instanceof Array) && iEe) {
    const n = [];
    let r = !1;
    if (es(t, (i, o) => {
      if (i.substring(0, 1) !== ".") {
        const a = or(o);
        a.isEmpty() || (r = r || !a.getPriority().isEmpty(), n.push(new Ne(i, a)));
      }
    }), n.length === 0)
      return oe.EMPTY_NODE;
    const s = FI(n, Qbe, (i) => i.name, f2);
    if (r) {
      const i = FI(n, An.getCompare());
      return new oe(s, or(e), new Tc({ ".priority": i }, { ".priority": An }));
    } else
      return new oe(s, or(e), Tc.Default);
  } else {
    let n = oe.EMPTY_NODE;
    return es(t, (r, s) => {
      if (ja(t, r) && r.substring(0, 1) !== ".") {
        const i = or(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(or(e));
  }
}
Xbe(or);
class oEe extends aP {
  constructor(e) {
    super(), this.indexPath_ = e, k(!Le(e) && Te(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? cp(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = or(e), s = oe.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new Ne(n, s);
  }
  maxPost() {
    const e = oe.EMPTY_NODE.updateChild(this.indexPath_, GT);
    return new Ne(Rf, e);
  }
  toString() {
    return Bw(this.indexPath_, 0).join("/");
  }
}
class aEe extends aP {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? cp(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return Ne.MIN;
  }
  maxPost() {
    return Ne.MAX;
  }
  makePost(e, n) {
    const r = or(e);
    return new Ne(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const cEe = new aEe();
function rZ(t) {
  return { type: "value", snapshotNode: t };
}
function fm(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function qw(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function Hw(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function lEe(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class p2 {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    k(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(qw(n, a)) : k(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(fm(n, r)) : o.trackChildChange(Hw(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(An, (s, i) => {
      n.hasChild(s) || r.trackChildChange(qw(s, i));
    }), n.isLeafNode() || n.forEachChild(An, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(Hw(s, i, o));
      } else
        r.trackChildChange(fm(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? oe.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class jw {
  constructor(e) {
    this.indexedFilter_ = new p2(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = jw.getStartPost_(e), this.endPost_ = jw.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new Ne(n, r)) || (r = oe.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = oe.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(oe.EMPTY_NODE);
    const i = this;
    return n.forEachChild(An, (o, a) => {
      i.matches(new Ne(o, a)) || (s = s.updateImmediateChild(o, oe.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class uEe {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new jw(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new Ne(n, r)) || (r = oe.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = oe.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = oe.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(oe.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, oe.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    k(a.numChildren() === this.limit_, "");
    const c = new Ne(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(Hw(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(qw(n, u));
        const p = a.updateImmediateChild(n, oe.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(fm(d.name, d.node)), p.updateImmediateChild(d.name, d.node)) : p;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(qw(l.name, l.node)), i.trackChildChange(fm(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, oe.EMPTY_NODE)) : e;
  }
}
class _2 {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = An;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return k(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return k(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : dm;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return k(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return k(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : Rf;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return k(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === An;
  }
  copy() {
    const e = new _2();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function hEe(t) {
  return t.loadsAllData() ? new p2(t.getIndex()) : t.hasLimit() ? new uEe(t) : new jw(t);
}
function A3(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === An ? n = "$priority" : t.index_ === cEe ? n = "$value" : t.index_ === v_ ? n = "$key" : (k(t.index_ instanceof oEe, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = fr(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = fr(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + fr(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = fr(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + fr(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function M3(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== An && (e.i = t.index_.toString()), e;
}
class YI extends $Q {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = zT("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (k(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = YI.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = A3(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), hm(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = YI.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = A3(e._queryParams), r = e._path.toString(), s = new qT();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + n2(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = Fw(a.responseText);
            } catch {
              Ms("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && Ms("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class dEe {
  constructor() {
    this.rootNode_ = oe.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function WI() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function sZ(t, e, n) {
  if (Le(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = Te(e);
    t.children.has(r) || t.children.set(r, WI());
    const s = t.children.get(r);
    e = Xt(e), sZ(s, e, n);
  }
}
function oL(t, e, n) {
  t.value !== null ? n(e, t.value) : fEe(t, (r, s) => {
    const i = new Dt(e.toString() + "/" + r);
    oL(s, i, n);
  });
}
function fEe(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class pEe {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && es(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const x3 = 10 * 1e3, _Ee = 30 * 1e3, mEe = 5 * 60 * 1e3;
class gEe {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new pEe(e);
    const r = x3 + (_Ee - x3) * Math.random();
    xv(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    es(e, (s, i) => {
      i > 0 && ja(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), xv(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * mEe));
  }
}
var No;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(No || (No = {}));
function m2() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function g2() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function y2(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class BI {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = No.ACK_USER_WRITE, this.source = m2();
  }
  operationForChild(e) {
    if (Le(this.path)) {
      if (this.affectedTree.value != null)
        return k(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new Dt(e));
        return new BI(at(), n, this.revert);
      }
    } else return k(Te(this.path) === e, "operationForChild called for unrelated child."), new BI(Xt(this.path), this.affectedTree, this.revert);
  }
}
class Vw {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = No.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return Le(this.path) ? new Vw(this.source, at()) : new Vw(this.source, Xt(this.path));
  }
}
class kf {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = No.OVERWRITE;
  }
  operationForChild(e) {
    return Le(this.path) ? new kf(this.source, at(), this.snap.getImmediateChild(e)) : new kf(this.source, Xt(this.path), this.snap);
  }
}
class pm {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = No.MERGE;
  }
  operationForChild(e) {
    if (Le(this.path)) {
      const n = this.children.subtree(new Dt(e));
      return n.isEmpty() ? null : n.value ? new kf(this.source, at(), n.value) : new pm(this.source, at(), n);
    } else
      return k(Te(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new pm(this.source, Xt(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class Sh {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (Le(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = Te(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class yEe {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function vEe(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(lEe(o.childName, o.snapshotNode));
  }), cy(t, s, "child_removed", e, r, n), cy(t, s, "child_added", e, r, n), cy(t, s, "child_moved", i, r, n), cy(t, s, "child_changed", e, r, n), cy(t, s, "value", e, r, n), s;
}
function cy(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => CEe(t, a, c)), o.forEach((a) => {
    const c = wEe(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function wEe(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function CEe(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw Zm("Should only compare child_ events.");
  const r = new Ne(e.childName, e.snapshotNode), s = new Ne(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function cP(t, e) {
  return { eventCache: t, serverCache: e };
}
function Lv(t, e, n, r) {
  return cP(new Sh(e, n, r), t.serverCache);
}
function iZ(t, e, n, r) {
  return cP(t.eventCache, new Sh(e, n, r));
}
function qI(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function Nf(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let FA;
const TEe = () => (FA || (FA = new ai(obe)), FA);
class Vt {
  constructor(e, n = TEe()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new Vt(null);
    return es(e, (r, s) => {
      n = n.set(new Dt(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: at(), value: this.value };
    if (Le(e))
      return null;
    {
      const r = Te(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(Xt(e), n);
        return i != null ? { path: In(new Dt(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (Le(e))
      return this;
    {
      const n = Te(e), r = this.children.get(n);
      return r !== null ? r.subtree(Xt(e)) : new Vt(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (Le(e))
      return new Vt(n, this.children);
    {
      const r = Te(e), s = (this.children.get(r) || new Vt(null)).set(Xt(e), n), i = this.children.insert(r, s);
      return new Vt(this.value, i);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (Le(e))
      return this.children.isEmpty() ? new Vt(null) : new Vt(null, this.children);
    {
      const n = Te(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(Xt(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new Vt(null) : new Vt(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (Le(e))
      return this.value;
    {
      const n = Te(e), r = this.children.get(n);
      return r ? r.get(Xt(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (Le(e))
      return n;
    {
      const r = Te(e), s = (this.children.get(r) || new Vt(null)).setTree(Xt(e), n);
      let i;
      return s.isEmpty() ? i = this.children.remove(r) : i = this.children.insert(r, s), new Vt(this.value, i);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(at(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(In(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, at(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (Le(e))
      return null;
    {
      const i = Te(e), o = this.children.get(i);
      return o ? o.findOnPath_(Xt(e), In(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, at(), n);
  }
  foreachOnPath_(e, n, r) {
    if (Le(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = Te(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(Xt(e), In(n, s), r) : new Vt(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(at(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(In(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class qo {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new qo(new Vt(null));
  }
}
function Uv(t, e, n) {
  if (Le(e))
    return new qo(new Vt(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = Rs(s, e);
      return i = i.updateChild(o, n), new qo(t.writeTree_.set(s, i));
    } else {
      const s = new Vt(n), i = t.writeTree_.setTree(e, s);
      return new qo(i);
    }
  }
}
function aL(t, e, n) {
  let r = t;
  return es(n, (s, i) => {
    r = Uv(r, In(e, s), i);
  }), r;
}
function L3(t, e) {
  if (Le(e))
    return qo.empty();
  {
    const n = t.writeTree_.setTree(e, new Vt(null));
    return new qo(n);
  }
}
function cL(t, e) {
  return lp(t, e) != null;
}
function lp(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(Rs(n.path, e)) : null;
}
function U3(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(An, (r, s) => {
    e.push(new Ne(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new Ne(r, s.value));
  }), e;
}
function Vu(t, e) {
  if (Le(e))
    return t;
  {
    const n = lp(t, e);
    return n != null ? new qo(new Vt(n)) : new qo(t.writeTree_.subtree(e));
  }
}
function lL(t) {
  return t.writeTree_.isEmpty();
}
function _m(t, e) {
  return oZ(at(), t.writeTree_, e);
}
function oZ(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (k(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = oZ(In(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(In(t, ".priority"), r)), n;
  }
}
function lP(t, e) {
  return uZ(e, t);
}
function bEe(t, e, n, r, s) {
  k(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = Uv(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function EEe(t, e, n, r) {
  k(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = aL(t.visibleWrites, e, n), t.lastWriteId = r;
}
function SEe(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function IEe(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  k(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && REe(a, r.path) ? s = !1 : zi(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return kEe(t), !0;
    if (r.snap)
      t.visibleWrites = L3(t.visibleWrites, r.path);
    else {
      const a = r.children;
      es(a, (c) => {
        t.visibleWrites = L3(t.visibleWrites, In(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function REe(t, e) {
  if (t.snap)
    return zi(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && zi(In(t.path, n), e))
      return !0;
  return !1;
}
function kEe(t) {
  t.visibleWrites = aZ(t.allWrites, NEe, at()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function NEe(t) {
  return t.visible;
}
function aZ(t, e, n) {
  let r = qo.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        zi(n, o) ? (a = Rs(n, o), r = Uv(r, a, i.snap)) : zi(o, n) && (a = Rs(o, n), r = Uv(r, at(), i.snap.getChild(a)));
      else if (i.children) {
        if (zi(n, o))
          a = Rs(n, o), r = aL(r, a, i.children);
        else if (zi(o, n))
          if (a = Rs(o, n), Le(a))
            r = aL(r, at(), i.children);
          else {
            const c = hm(i.children, Te(a));
            if (c) {
              const l = c.getChild(Xt(a));
              r = Uv(r, at(), l);
            }
          }
      } else
        throw Zm("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function cZ(t, e, n, r, s) {
  if (!r && !s) {
    const i = lp(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = Vu(t.visibleWrites, e);
      if (lL(o))
        return n;
      if (n == null && !cL(o, at()))
        return null;
      {
        const a = n || oe.EMPTY_NODE;
        return _m(o, a);
      }
    }
  } else {
    const i = Vu(t.visibleWrites, e);
    if (!s && lL(i))
      return n;
    if (!s && n == null && !cL(i, at()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && (zi(l.path, e) || zi(e, l.path));
      }, a = aZ(t.allWrites, o, e), c = n || oe.EMPTY_NODE;
      return _m(a, c);
    }
  }
}
function PEe(t, e, n) {
  let r = oe.EMPTY_NODE;
  const s = lp(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(An, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = Vu(t.visibleWrites, e);
    return n.forEachChild(An, (o, a) => {
      const c = _m(Vu(i, new Dt(o)), a);
      r = r.updateImmediateChild(o, c);
    }), U3(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = Vu(t.visibleWrites, e);
    return U3(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function OEe(t, e, n, r, s) {
  k(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = In(e, n);
  if (cL(t.visibleWrites, i))
    return null;
  {
    const o = Vu(t.visibleWrites, i);
    return lL(o) ? s.getChild(n) : _m(o, s.getChild(n));
  }
}
function DEe(t, e, n, r) {
  const s = In(e, n), i = lp(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = Vu(t.visibleWrites, s);
    return _m(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function AEe(t, e) {
  return lp(t.visibleWrites, e);
}
function MEe(t, e, n, r, s, i, o) {
  let a;
  const c = Vu(t.visibleWrites, e), l = lp(c, at());
  if (l != null)
    a = l;
  else if (n != null)
    a = _m(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function xEe() {
  return {
    visibleWrites: qo.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function HI(t, e, n, r) {
  return cZ(t.writeTree, t.treePath, e, n, r);
}
function v2(t, e) {
  return PEe(t.writeTree, t.treePath, e);
}
function F3(t, e, n, r) {
  return OEe(t.writeTree, t.treePath, e, n, r);
}
function jI(t, e) {
  return AEe(t.writeTree, In(t.treePath, e));
}
function LEe(t, e, n, r, s, i) {
  return MEe(t.writeTree, t.treePath, e, n, r, s, i);
}
function w2(t, e, n) {
  return DEe(t.writeTree, t.treePath, e, n);
}
function lZ(t, e) {
  return uZ(In(t.treePath, e), t.writeTree);
}
function uZ(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class UEe {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    k(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), k(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, Hw(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, qw(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, fm(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, Hw(r, e.snapshotNode, s.oldSnap));
      else
        throw Zm("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class FEe {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const hZ = new FEe();
class C2 {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new Sh(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return w2(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : Nf(this.viewCache_), i = LEe(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function YEe(t) {
  return { filter: t };
}
function WEe(t, e) {
  k(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), k(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function BEe(t, e, n, r, s) {
  const i = new UEe();
  let o, a;
  if (n.type === No.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = uL(t, e, l.path, l.snap, r, s, i) : (k(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !Le(l.path), o = VI(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === No.MERGE) {
    const l = n;
    l.source.fromUser ? o = HEe(t, e, l.path, l.children, r, s, i) : (k(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = hL(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === No.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = zEe(t, e, l.path, r, s, i) : o = jEe(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === No.LISTEN_COMPLETE)
    o = VEe(t, e, n.path, r, i);
  else
    throw Zm("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return qEe(e, o, c), { viewCache: o, changes: c };
}
function qEe(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = qI(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(rZ(qI(e)));
  }
}
function dZ(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (jI(r, n) != null)
    return e;
  {
    let a, c;
    if (Le(n))
      if (k(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = Nf(e), h = l instanceof oe ? l : oe.EMPTY_NODE, u = v2(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = HI(r, Nf(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = Te(n);
      if (l === ".priority") {
        k(Eh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = F3(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = Xt(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = F3(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = w2(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return Lv(e, a, o.isFullyInitialized() || Le(n), t.filter.filtersNodes());
  }
}
function VI(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (Le(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = Te(n);
    if (!c.isCompleteForPath(n) && Eh(n) > 1)
      return e;
    const p = Xt(n), _ = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), _) : l = h.updateChild(c.getNode(), f, _, p, hZ, null);
  }
  const u = iZ(e, l, c.isFullyInitialized() || Le(n), h.filtersNodes()), d = new C2(s, u, i);
  return dZ(t, u, n, s, d, a);
}
function uL(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new C2(s, e, i);
  if (Le(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = Lv(e, l, !0, t.filter.filtersNodes());
  else {
    const u = Te(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = Lv(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = Xt(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (Le(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? u2(d) === ".priority" && _.getChild(JQ(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = oe.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = Lv(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function Y3(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function HEe(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = In(n, c);
    Y3(e, Te(h)) && (a = uL(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = In(n, c);
    Y3(e, Te(h)) || (a = uL(t, a, h, l, s, i, o));
  }), a;
}
function W3(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function hL(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  Le(n) ? l = r : l = new Vt(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = W3(t, f, d);
      c = VI(t, c, new Dt(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = W3(t, p, d);
      c = VI(t, c, new Dt(u), _, s, i, o, a);
    }
  }), c;
}
function jEe(t, e, n, r, s, i, o) {
  if (jI(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (Le(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return VI(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (Le(n)) {
      let l = new Vt(null);
      return c.getNode().forEachChild(v_, (h, u) => {
        l = l.set(new Dt(h), u);
      }), hL(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new Vt(null);
    return r.foreach((h, u) => {
      const d = In(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), hL(t, e, n, l, s, i, a, o);
  }
}
function VEe(t, e, n, r, s) {
  const i = e.serverCache, o = iZ(e, i.getNode(), i.isFullyInitialized() || Le(n), i.isFiltered());
  return dZ(t, o, n, r, hZ, s);
}
function zEe(t, e, n, r, s, i) {
  let o;
  if (jI(r, n) != null)
    return e;
  {
    const a = new C2(r, e, s), c = e.eventCache.getNode();
    let l;
    if (Le(n) || Te(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = HI(r, Nf(e));
      else {
        const u = e.serverCache.getNode();
        k(u instanceof oe, "serverChildren would be complete if leaf node"), h = v2(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = Te(n);
      let u = w2(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, Xt(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, oe.EMPTY_NODE, Xt(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = HI(r, Nf(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || jI(r, at()) != null, Lv(e, l, o, t.filter.filtersNodes());
  }
}
class GEe {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new p2(r.getIndex()), i = hEe(r);
    this.processor_ = YEe(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(oe.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(oe.EMPTY_NODE, a.getNode(), null), h = new Sh(c, o.isFullyInitialized(), s.filtersNodes()), u = new Sh(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = cP(u, h), this.eventGenerator_ = new yEe(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function $Ee(t) {
  return t.viewCache_.serverCache.getNode();
}
function KEe(t) {
  return qI(t.viewCache_);
}
function JEe(t, e) {
  const n = Nf(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !Le(e) && !n.getImmediateChild(Te(e)).isEmpty()) ? n.getChild(e) : null;
}
function B3(t) {
  return t.eventRegistrations_.length === 0;
}
function QEe(t, e) {
  t.eventRegistrations_.push(e);
}
function q3(t, e, n) {
  const r = [];
  if (n) {
    k(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function H3(t, e, n, r) {
  e.type === No.MERGE && e.source.queryId !== null && (k(Nf(t.viewCache_), "We should always have a full cache before handling merges"), k(qI(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = BEe(t.processor_, s, e, n, r);
  return WEe(t.processor_, i.viewCache), k(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, fZ(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function ZEe(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(An, (s, i) => {
    r.push(fm(s, i));
  }), n.isFullyInitialized() && r.push(rZ(n.getNode())), fZ(t, r, n.getNode(), e);
}
function fZ(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return vEe(t.eventGenerator_, e, n, s);
}
let zI;
class pZ {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function XEe(t) {
  k(!zI, "__referenceConstructor has already been defined"), zI = t;
}
function eSe() {
  return k(zI, "Reference.ts has not been loaded"), zI;
}
function tSe(t) {
  return t.views.size === 0;
}
function T2(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return k(i != null, "SyncTree gave us an op for an invalid query."), H3(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat(H3(o, e, n, r));
    return i;
  }
}
function _Z(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = HI(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof oe ? (a = v2(n, r), c = !1) : (a = oe.EMPTY_NODE, c = !1);
    const l = cP(new Sh(a, c, !1), new Sh(r, s, !1));
    return new GEe(e, l);
  }
  return o;
}
function nSe(t, e, n, r, s, i) {
  const o = _Z(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), QEe(o, n), ZEe(o, n);
}
function rSe(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = Ih(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(q3(l, n, r)), B3(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(q3(c, n, r)), B3(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !Ih(t) && i.push(new (eSe())(e._repo, e._path)), { removed: i, events: o };
}
function mZ(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function zu(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || JEe(r, e);
  return n;
}
function gZ(t, e) {
  if (e._queryParams.loadsAllData())
    return uP(t);
  {
    const n = e._queryIdentifier;
    return t.views.get(n);
  }
}
function yZ(t, e) {
  return gZ(t, e) != null;
}
function Ih(t) {
  return uP(t) != null;
}
function uP(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let GI;
function sSe(t) {
  k(!GI, "__referenceConstructor has already been defined"), GI = t;
}
function iSe() {
  return k(GI, "Reference.ts has not been loaded"), GI;
}
let oSe = 1;
class j3 {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new Vt(null), this.pendingWriteTree_ = xEe(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function vZ(t, e, n, r, s) {
  return bEe(t.pendingWriteTree_, e, n, r, s), s ? eg(t, new kf(m2(), e, n)) : [];
}
function aSe(t, e, n, r) {
  EEe(t.pendingWriteTree_, e, n, r);
  const s = Vt.fromObject(n);
  return eg(t, new pm(m2(), e, s));
}
function Cu(t, e, n = !1) {
  const r = SEe(t.pendingWriteTree_, e);
  if (IEe(t.pendingWriteTree_, e)) {
    let s = new Vt(null);
    return r.snap != null ? s = s.set(at(), !0) : es(r.children, (i) => {
      s = s.set(new Dt(i), !0);
    }), eg(t, new BI(r.path, s, n));
  } else
    return [];
}
function $T(t, e, n) {
  return eg(t, new kf(g2(), e, n));
}
function cSe(t, e, n) {
  const r = Vt.fromObject(n);
  return eg(t, new pm(g2(), e, r));
}
function lSe(t, e) {
  return eg(t, new Vw(g2(), e));
}
function uSe(t, e, n) {
  const r = E2(t, n);
  if (r) {
    const s = S2(r), i = s.path, o = s.queryId, a = Rs(i, e), c = new Vw(y2(o), a);
    return I2(t, i, c);
  } else
    return [];
}
function $I(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || yZ(o, e))) {
    const c = rSe(o, e, n, r);
    tSe(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => Ih(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = fSe(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = bZ(t, _);
            t.listenProvider_.startListening(Fv(m), zw(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(Fv(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(hP(d));
        t.listenProvider_.stopListening(Fv(d), f);
      }));
    }
    pSe(t, l);
  }
  return a;
}
function wZ(t, e, n, r) {
  const s = E2(t, r);
  if (s != null) {
    const i = S2(s), o = i.path, a = i.queryId, c = Rs(o, e), l = new kf(y2(a), c, n);
    return I2(t, o, l);
  } else
    return [];
}
function hSe(t, e, n, r) {
  const s = E2(t, r);
  if (s) {
    const i = S2(s), o = i.path, a = i.queryId, c = Rs(o, e), l = Vt.fromObject(n), h = new pm(y2(a), c, l);
    return I2(t, o, h);
  } else
    return [];
}
function dL(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = Rs(d, s);
    i = i || zu(f, p), o = o || Ih(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || Ih(a), i = i || zu(a, at())) : (a = new pZ(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = oe.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((d, f) => {
    const p = zu(f, at());
    p && (i = i.updateImmediateChild(d, p));
  }));
  const l = yZ(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = hP(e);
    k(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = _Se();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = lP(t.pendingWriteTree_, s);
  let u = nSe(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = gZ(a, e);
    u = u.concat(mSe(t, e, d));
  }
  return u;
}
function b2(t, e, n) {
  const r = t.pendingWriteTree_, s = t.syncPointTree_.findOnPath(e, (i, o) => {
    const a = Rs(i, e), c = zu(o, a);
    if (c)
      return c;
  });
  return cZ(r, e, s, n, !0);
}
function dSe(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = Rs(l, n);
    r = r || zu(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || zu(s, at()) : (s = new pZ(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new Sh(r, !0, !1) : null, a = lP(t.pendingWriteTree_, e._path), c = _Z(s, e, a, i ? o.getNode() : oe.EMPTY_NODE, i);
  return KEe(c);
}
function eg(t, e) {
  return CZ(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    lP(t.pendingWriteTree_, at())
  );
}
function CZ(t, e, n, r) {
  if (Le(t.path))
    return TZ(t, e, n, r);
  {
    const s = e.get(at());
    n == null && s != null && (n = zu(s, at()));
    let i = [];
    const o = Te(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = lZ(r, o);
      i = i.concat(CZ(a, c, l, h));
    }
    return s && (i = i.concat(T2(s, t, r, n))), i;
  }
}
function TZ(t, e, n, r) {
  const s = e.get(at());
  n == null && s != null && (n = zu(s, at()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = lZ(r, o), h = t.operationForChild(o);
    h && (i = i.concat(TZ(h, a, c, l)));
  }), s && (i = i.concat(T2(s, t, r, n))), i;
}
function bZ(t, e) {
  const n = e.query, r = zw(t, n);
  return {
    hashFn: () => ($Ee(e) || oe.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? uSe(t, n._path, r) : lSe(t, n._path);
      {
        const i = lbe(s, n);
        return $I(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function zw(t, e) {
  const n = hP(e);
  return t.queryToTagMap.get(n);
}
function hP(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function E2(t, e) {
  return t.tagToQueryMap.get(e);
}
function S2(t) {
  const e = t.indexOf("$");
  return k(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new Dt(t.substr(0, e))
  };
}
function I2(t, e, n) {
  const r = t.syncPointTree_.get(e);
  k(r, "Missing sync point for query tag that we're tracking");
  const s = lP(t.pendingWriteTree_, e);
  return T2(r, n, s, null);
}
function fSe(t) {
  return t.fold((e, n, r) => {
    if (n && Ih(n))
      return [uP(n)];
    {
      let s = [];
      return n && (s = mZ(n)), es(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function Fv(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (iSe())(t._repo, t._path) : t;
}
function pSe(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = hP(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function _Se() {
  return oSe++;
}
function mSe(t, e, n) {
  const r = e._path, s = zw(t, e), i = bZ(t, n), o = t.listenProvider_.startListening(Fv(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    k(!Ih(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!Le(l) && h && Ih(h))
        return [uP(h).query];
      {
        let d = [];
        return h && (d = d.concat(mZ(h).map((f) => f.query))), es(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(Fv(h), zw(t, h));
    }
  }
  return o;
}
class R2 {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new R2(n);
  }
  node() {
    return this.node_;
  }
}
class k2 {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = In(this.path_, e);
    return new k2(this.syncTree_, n);
  }
  node() {
    return b2(this.syncTree_, this.path_);
  }
}
const gSe = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, V3 = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (k(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return ySe(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return vSe(t[".sv"], e);
  k(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, ySe = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      k(!1, "Unexpected server value: " + t);
  }
}, vSe = function(t, e, n) {
  t.hasOwnProperty("increment") || k(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && k(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (k(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const i = s.getValue();
  return typeof i != "number" ? r : i + r;
}, EZ = function(t, e, n, r) {
  return N2(e, new k2(n, t), r);
}, SZ = function(t, e, n) {
  return N2(t, new R2(e), n);
};
function N2(t, e, n) {
  const r = t.getPriority().val(), s = V3(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = V3(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new Ir(a, or(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new Ir(s))), o.forEachChild(An, (a, c) => {
      const l = N2(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class P2 {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function O2(t, e) {
  let n = e instanceof Dt ? e : new Dt(e), r = t, s = Te(n);
  for (; s !== null; ) {
    const i = hm(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new P2(s, r, i), n = Xt(n), s = Te(n);
  }
  return r;
}
function tg(t) {
  return t.node.value;
}
function IZ(t, e) {
  t.node.value = e, fL(t);
}
function RZ(t) {
  return t.node.childCount > 0;
}
function wSe(t) {
  return tg(t) === void 0 && !RZ(t);
}
function dP(t, e) {
  es(t.node.children, (n, r) => {
    e(new P2(n, t, r));
  });
}
function kZ(t, e, n, r) {
  n && !r && e(t), dP(t, (s) => {
    kZ(s, e, !0, r);
  }), n && r && e(t);
}
function CSe(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function KT(t) {
  return new Dt(t.parent === null ? t.name : KT(t.parent) + "/" + t.name);
}
function fL(t) {
  t.parent !== null && TSe(t.parent, t.name, t);
}
function TSe(t, e, n) {
  const r = wSe(n), s = ja(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, fL(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, fL(t));
}
const bSe = /[\[\].#$\/\u0000-\u001F\u007F]/, ESe = /[\[\].#$\u0000-\u001F\u007F]/, YA = 10 * 1024 * 1024, D2 = function(t) {
  return typeof t == "string" && t.length !== 0 && !bSe.test(t);
}, NZ = function(t) {
  return typeof t == "string" && t.length !== 0 && !ESe.test(t);
}, SSe = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), NZ(t);
}, ISe = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !i2(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ja(t, ".sv");
}, RSe = function(t, e, n, r) {
  fP(iP(t, "value"), e, n);
}, fP = function(t, e, n) {
  const r = n instanceof Dt ? new Hbe(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + Td(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + Td(r) + " with contents = " + e.toString());
  if (i2(e))
    throw new Error(t + "contains " + e.toString() + " " + Td(r));
  if (typeof e == "string" && e.length > YA / 3 && oP(e) > YA)
    throw new Error(t + "contains a string greater than " + YA + " utf8 bytes " + Td(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (es(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !D2(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + Td(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      jbe(r, o), fP(t, a, r), Vbe(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + Td(r) + " in addition to actual children.");
  }
}, kSe = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = Bw(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1) && !D2(i[o]))
        throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
  }
  e.sort(qbe);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && zi(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, NSe = function(t, e, n, r) {
  const s = iP(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  es(e, (o, a) => {
    const c = new Dt(o);
    if (fP(s, a, In(n, c)), u2(c) === ".priority" && !ISe(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), kSe(s, i);
}, PZ = function(t, e, n, r) {
  if (!NZ(n))
    throw new Error(iP(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, PSe = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), PZ(t, e, n);
}, OSe = function(t, e) {
  if (Te(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, DSe = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !D2(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !SSe(n))
    throw new Error(iP(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class ASe {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function pP(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !h2(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function OZ(t, e, n) {
  pP(t, n), DZ(t, (r) => h2(r, e));
}
function Xi(t, e, n) {
  pP(t, n), DZ(t, (r) => zi(r, e) || zi(e, r));
}
function DZ(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (MSe(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function MSe(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      Mv && Gr("event: " + n.toString()), Xm(r);
    }
  }
}
const xSe = "repo_interrupt", LSe = 25;
class USe {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new ASe(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = WI(), this.transactionQueueTree_ = new P2(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function FSe(t, e, n) {
  if (t.stats_ = c2(t.repoInfo_), t.forceRestClient_ || fbe())
    t.server_ = new YI(t.repoInfo_, (r, s, i, o) => {
      z3(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => G3(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        fr(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new Kc(t.repoInfo_, e, (r, s, i, o) => {
      z3(t, r, s, i, o);
    }, (r) => {
      G3(t, r);
    }, (r) => {
      WSe(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = ybe(t.repoInfo_, () => new gEe(t.stats_, t.server_)), t.infoData_ = new dEe(), t.infoSyncTree_ = new j3({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = $T(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), A2(t, "connected", !1), t.serverSyncTree_ = new j3({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      Xi(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function YSe(t) {
  const e = t.infoData_.getNode(new Dt(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + e;
}
function _P(t) {
  return gSe({
    timestamp: YSe(t)
  });
}
function z3(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new Dt(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = MI(n, (l) => or(l));
      o = hSe(t.serverSyncTree_, i, c, s);
    } else {
      const c = or(n);
      o = wZ(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = MI(n, (l) => or(l));
    o = cSe(t.serverSyncTree_, i, c);
  } else {
    const c = or(n);
    o = $T(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = mm(t, i)), Xi(t.eventQueue_, a, o);
}
function G3(t, e) {
  A2(t, "connected", e), e === !1 && jSe(t);
}
function WSe(t, e) {
  es(e, (n, r) => {
    A2(t, n, r);
  });
}
function A2(t, e, n) {
  const r = new Dt("/.info/" + e), s = or(n);
  t.infoData_.updateSnapshot(r, s);
  const i = $T(t.infoSyncTree_, r, s);
  Xi(t.eventQueue_, r, i);
}
function M2(t) {
  return t.nextWriteId_++;
}
function BSe(t, e, n) {
  const r = dSe(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = or(s).withIndex(e._queryParams.getIndex());
    dL(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = $T(t.serverSyncTree_, e._path, i);
    else {
      const a = zw(t.serverSyncTree_, e);
      o = wZ(t.serverSyncTree_, e._path, i, a);
    }
    return Xi(t.eventQueue_, e._path, o), $I(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (JT(t, "get for query " + fr(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function qSe(t, e, n, r, s) {
  JT(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = _P(t), o = or(n, r), a = b2(t.serverSyncTree_, e), c = SZ(o, a, i), l = M2(t), h = vZ(t.serverSyncTree_, e, c, l, !0);
  pP(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || Ms("set at " + e + " failed: " + d);
    const _ = Cu(t.serverSyncTree_, l, !p);
    Xi(t.eventQueue_, e, _), pL(t, s, d, f);
  });
  const u = L2(t, e);
  mm(t, u), Xi(t.eventQueue_, u, []);
}
function HSe(t, e, n, r) {
  JT(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = _P(t), o = {};
  if (es(n, (a, c) => {
    s = !1, o[a] = EZ(In(e, a), or(c), t.serverSyncTree_, i);
  }), s)
    Gr("update() called with empty data.  Don't do anything."), pL(t, r, "ok", void 0);
  else {
    const a = M2(t), c = aSe(t.serverSyncTree_, e, o, a);
    pP(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || Ms("update at " + e + " failed: " + l);
      const d = Cu(t.serverSyncTree_, a, !u), f = d.length > 0 ? mm(t, e) : e;
      Xi(t.eventQueue_, f, d), pL(t, r, l, h);
    }), es(n, (l) => {
      const h = L2(t, In(e, l));
      mm(t, h);
    }), Xi(t.eventQueue_, e, []);
  }
}
function jSe(t) {
  JT(t, "onDisconnectEvents");
  const e = _P(t), n = WI();
  oL(t.onDisconnect_, at(), (s, i) => {
    const o = EZ(s, i, t.serverSyncTree_, e);
    sZ(n, s, o);
  });
  let r = [];
  oL(n, at(), (s, i) => {
    r = r.concat($T(t.serverSyncTree_, s, i));
    const o = L2(t, s);
    mm(t, o);
  }), t.onDisconnect_ = WI(), Xi(t.eventQueue_, at(), r);
}
function VSe(t, e, n) {
  let r;
  Te(e._path) === ".info" ? r = dL(t.infoSyncTree_, e, n) : r = dL(t.serverSyncTree_, e, n), OZ(t.eventQueue_, e._path, r);
}
function $3(t, e, n) {
  let r;
  Te(e._path) === ".info" ? r = $I(t.infoSyncTree_, e, n) : r = $I(t.serverSyncTree_, e, n), OZ(t.eventQueue_, e._path, r);
}
function zSe(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt(xSe);
}
function JT(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), Gr(n, ...e);
}
function pL(t, e, n, r) {
  e && Xm(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function AZ(t, e, n) {
  return b2(t.serverSyncTree_, e, n) || oe.EMPTY_NODE;
}
function x2(t, e = t.transactionQueueTree_) {
  if (e || mP(t, e), tg(e)) {
    const n = xZ(t, e);
    k(n.length > 0, "Sending zero length transaction queue"), n.every(
      (r) => r.status === 0
      /* TransactionStatus.RUN */
    ) && GSe(t, KT(e), n);
  } else RZ(e) && dP(e, (n) => {
    x2(t, n);
  });
}
function GSe(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = AZ(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    k(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = Rs(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    JT(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(Cu(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      mP(t, O2(t.transactionQueueTree_, e)), x2(t, t.transactionQueueTree_), Xi(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        Xm(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        Ms("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      mm(t, e);
    }
  }, o);
}
function mm(t, e) {
  const n = MZ(t, e), r = KT(n), s = xZ(t, n);
  return $Se(t, s, r), r;
}
function $Se(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const i = e.filter((o) => o.status === 0).map((o) => o.currentWriteId);
  for (let o = 0; o < e.length; o++) {
    const a = e[o], c = Rs(n, a.path);
    let l = !1, h;
    if (k(c !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), a.status === 4)
      l = !0, h = a.abortReason, s = s.concat(Cu(t.serverSyncTree_, a.currentWriteId, !0));
    else if (a.status === 0)
      if (a.retryCount >= LSe)
        l = !0, h = "maxretry", s = s.concat(Cu(t.serverSyncTree_, a.currentWriteId, !0));
      else {
        const u = AZ(t, a.path, i);
        a.currentInputSnapshot = u;
        const d = e[o].update(u.val());
        if (d !== void 0) {
          fP("transaction failed: Data returned ", d, a.path);
          let f = or(d);
          typeof d == "object" && d != null && ja(d, ".priority") || (f = f.updatePriority(u.getPriority()));
          const p = a.currentWriteId, _ = _P(t), m = SZ(f, u, _);
          a.currentOutputSnapshotRaw = f, a.currentOutputSnapshotResolved = m, a.currentWriteId = M2(t), i.splice(i.indexOf(p), 1), s = s.concat(vZ(t.serverSyncTree_, a.path, m, a.currentWriteId, a.applyLocally)), s = s.concat(Cu(t.serverSyncTree_, p, !0));
        } else
          l = !0, h = "nodata", s = s.concat(Cu(t.serverSyncTree_, a.currentWriteId, !0));
      }
    Xi(t.eventQueue_, n, s), s = [], l && (e[o].status = 2, function(u) {
      setTimeout(u, Math.floor(0));
    }(e[o].unwatcher), e[o].onComplete && (h === "nodata" ? r.push(() => e[o].onComplete(null, !1, e[o].currentInputSnapshot)) : r.push(() => e[o].onComplete(new Error(h), !1, null))));
  }
  mP(t, t.transactionQueueTree_);
  for (let o = 0; o < r.length; o++)
    Xm(r[o]);
  x2(t, t.transactionQueueTree_);
}
function MZ(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = Te(e); n !== null && tg(r) === void 0; )
    r = O2(r, n), e = Xt(e), n = Te(e);
  return r;
}
function xZ(t, e) {
  const n = [];
  return LZ(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function LZ(t, e, n) {
  const r = tg(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  dP(e, (s) => {
    LZ(t, s, n);
  });
}
function mP(t, e) {
  const n = tg(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, IZ(e, n.length > 0 ? n : void 0);
  }
  dP(e, (r) => {
    mP(t, r);
  });
}
function L2(t, e) {
  const n = KT(MZ(t, e)), r = O2(t.transactionQueueTree_, e);
  return CSe(r, (s) => {
    WA(t, s);
  }), WA(t, r), kZ(r, (s) => {
    WA(t, s);
  }), n;
}
function WA(t, e) {
  const n = tg(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (k(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (k(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(Cu(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? IZ(e, void 0) : n.length = i + 1, Xi(t.eventQueue_, KT(e), s);
    for (let o = 0; o < r.length; o++)
      Xm(r[o]);
  }
}
function KSe(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function JSe(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : Ms(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const K3 = function(t, e) {
  const n = QSe(t), r = n.namespace;
  n.domain === "firebase.com" && gl(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && gl("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || sbe();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new BQ(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new Dt(n.pathString)
  };
}, QSe = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = KSe(t.substring(h, u)));
    const d = JSe(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class ZSe {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + fr(this.snapshot.exportVal());
  }
}
class XSe {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class UZ {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return k(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class U2 {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return Le(this._path) ? null : u2(this._path);
  }
  get ref() {
    return new Dl(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = M3(this._queryParams), n = o2(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return M3(this._queryParams);
  }
  isEqual(e) {
    if (e = as(e), !(e instanceof U2))
      return !1;
    const n = this._repo === e._repo, r = h2(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + Bbe(this._path);
  }
}
class Dl extends U2 {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new _2(), !1);
  }
  get parent() {
    const e = JQ(this._path);
    return e === null ? null : new Dl(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class Gw {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new Dt(e), r = _L(this.ref, e);
    return new Gw(this._node.getChild(n), r, An);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (n, r) => e(new Gw(r, _L(this.ref, n), An)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new Dt(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function DE(t, e) {
  return t = as(t), t._checkNotDeleted("ref"), e !== void 0 ? _L(t._root, e) : t._root;
}
function _L(t, e) {
  return t = as(t), Te(t._path) === null ? PSe("child", "path", e) : PZ("child", "path", e), new Dl(t._repo, In(t._path, e));
}
function e0e(t, e) {
  t = as(t), OSe("set", t._path), RSe("set", e, t._path);
  const n = new qT();
  return qSe(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function t0e(t, e) {
  NSe("update", e, t._path);
  const n = new qT();
  return HSe(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function n0e(t) {
  t = as(t);
  const e = new UZ(() => {
  }), n = new gP(e);
  return BSe(t._repo, t, n).then((r) => new Gw(r, new Dl(t._repo, t._path), t._queryParams.getIndex()));
}
class gP {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new ZSe("value", this, new Gw(e.snapshotNode, new Dl(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new XSe(this, e, n) : null;
  }
  matches(e) {
    return e instanceof gP ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function r0e(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      $3(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new UZ(n, i || void 0), a = new gP(o);
  return VSe(t._repo, t, a), () => $3(t._repo, t, a);
}
function s0e(t, e, n, r) {
  return r0e(t, "value", e, n, r);
}
function i0e(t, ...e) {
  let n = as(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
XEe(Dl);
sSe(Dl);
const o0e = "FIREBASE_DATABASE_EMULATOR_HOST", mL = {};
let a0e = !1;
function c0e(t, e, n, r) {
  t.repoInfo_ = new BQ(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function l0e(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || gl("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), Gr("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = K3(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[o0e]), c ? (i = `http://${c}?ns=${a.namespace}`, o = K3(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new _be(t.name, t.options, e);
  DSe("Invalid Firebase Database URL", o), Le(o.path) || gl("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = h0e(a, t, l, new pbe(t.name, n));
  return new d0e(h, t);
}
function u0e(t, e) {
  const n = mL[e];
  (!n || n[t.key] !== t) && gl(`Database ${e}(${t.repoInfo_}) has already been deleted.`), zSe(t), delete n[t.key];
}
function h0e(t, e, n, r) {
  let s = mL[e.name];
  s || (s = {}, mL[e.name] = s);
  let i = s[t.toURLString()];
  return i && gl("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new USe(t, a0e, n, r), s[t.toURLString()] = i, i;
}
class d0e {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (FSe(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Dl(this._repo, at())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (u0e(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && gl("Cannot call " + e + " on a deleted database.");
  }
}
function f0e(t = EQ(), e) {
  const n = bQ(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = mQ("database");
    r && p0e(n, ...r);
  }
  return n;
}
function p0e(t, e, n, r = {}) {
  t = as(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && gl("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && gl('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new US(US.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : gQ(r.mockUserToken, t.app.options.projectId);
    i = new US(o);
  }
  c0e(s, e, n, i);
}
function _0e(t) {
  ZTe(jT), If(new bh(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return l0e(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), $c(g3, y3, t), $c(g3, y3, "esm2017");
}
Kc.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
Kc.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
_0e();
const FZ = "firebasestorage.googleapis.com", YZ = "storageBucket", m0e = 2 * 60 * 1e3, g0e = 10 * 60 * 1e3;
class Gn extends Ol {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(BA(e), `Firebase Storage: ${n} (${BA(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, Gn.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return BA(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var Wn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(Wn || (Wn = {}));
function BA(t) {
  return "storage/" + t;
}
function F2() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new Gn(Wn.UNKNOWN, t);
}
function y0e(t) {
  return new Gn(Wn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function v0e(t) {
  return new Gn(Wn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function w0e() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new Gn(Wn.UNAUTHENTICATED, t);
}
function C0e() {
  return new Gn(Wn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function T0e(t) {
  return new Gn(Wn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function b0e() {
  return new Gn(Wn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function E0e() {
  return new Gn(Wn.CANCELED, "User canceled the upload/download.");
}
function S0e(t) {
  return new Gn(Wn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function I0e(t) {
  return new Gn(Wn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function R0e() {
  return new Gn(Wn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + YZ + "' property when initializing the app?");
}
function k0e() {
  return new Gn(Wn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function N0e() {
  return new Gn(Wn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function P0e(t) {
  return new Gn(Wn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function gL(t) {
  return new Gn(Wn.INVALID_ARGUMENT, t);
}
function WZ() {
  return new Gn(Wn.APP_DELETED, "The Firebase app was deleted.");
}
function O0e(t) {
  return new Gn(Wn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function Yv(t, e) {
  return new Gn(Wn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function ly(t) {
  throw new Gn(Wn.INTERNAL_ERROR, "Internal error: " + t);
}
class Ci {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = Ci.makeFromUrl(e, n);
    } catch {
      return new Ci(e, "");
    }
    if (r.path === "")
      return r;
    throw I0e(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(g) {
      g.path.charAt(g.path.length - 1) === "/" && (g.path_ = g.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(g) {
      g.path_ = decodeURIComponent(g.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === FZ ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), y = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let g = 0; g < y.length; g++) {
      const w = y[g], de = w.regex.exec(e);
      if (de) {
        const we = de[w.indices.bucket];
        let fe = de[w.indices.path];
        fe || (fe = ""), r = new Ci(we, fe), w.postModify(r);
        break;
      }
    }
    if (r == null)
      throw S0e(e);
    return r;
  }
}
class D0e {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function A0e(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let y;
    a === 1 ? (a = 2, y = 0) : y = (r + Math.random()) * 1e3, u(y);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function M0e(t) {
  t(!1);
}
function x0e(t) {
  return t !== void 0;
}
function L0e(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function Y2(t) {
  return typeof t == "string" || t instanceof String;
}
function J3(t) {
  return W2() && t instanceof Blob;
}
function W2() {
  return typeof Blob < "u" && !DCe();
}
function Q3(t, e, n, r) {
  if (r < e)
    throw gL(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw gL(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function yP(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function BZ(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var Xd;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(Xd || (Xd = {}));
function U0e(t, e) {
  const n = t >= 500 && t < 600, r = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, s = e.indexOf(t) !== -1;
  return n || r || s;
}
class F0e {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new AE(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === Xd.NO_ERROR, c = i.getStatus();
        if (!a || U0e(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === Xd.ABORT;
          r(!1, new AE(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new AE(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          x0e(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = F2();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? WZ() : E0e();
        o(c);
      } else {
        const c = b0e();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new AE(!1, null, !0)) : this.backoffId_ = A0e(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && M0e(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class AE {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function Y0e(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function W0e(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function B0e(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function q0e(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function H0e(t, e, n, r, s, i, o = !0) {
  const a = BZ(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return B0e(l, e), Y0e(l, n), W0e(l, i), q0e(l, r), new F0e(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function j0e() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function V0e(...t) {
  const e = j0e();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (W2())
      return new Blob(t);
    throw new Gn(Wn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function z0e(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function G0e(t) {
  if (typeof atob > "u")
    throw P0e("base-64");
  return atob(t);
}
const Sa = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class qA {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function $0e(t, e) {
  switch (t) {
    case Sa.RAW:
      return new qA(qZ(e));
    case Sa.BASE64:
    case Sa.BASE64URL:
      return new qA(HZ(t, e));
    case Sa.DATA_URL:
      return new qA(J0e(e), Q0e(e));
  }
  throw F2();
}
function qZ(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const s = r, i = t.charCodeAt(++n);
        r = 65536 | (s & 1023) << 10 | i & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function K0e(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw Yv(Sa.DATA_URL, "Malformed data URL.");
  }
  return qZ(e);
}
function HZ(t, e) {
  switch (t) {
    case Sa.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw Yv(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case Sa.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw Yv(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = G0e(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : Yv(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class jZ {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw Yv(Sa.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = Z0e(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function J0e(t) {
  const e = new jZ(t);
  return e.base64 ? HZ(Sa.BASE64, e.rest) : K0e(e.rest);
}
function Q0e(t) {
  return new jZ(t).contentType;
}
function Z0e(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class pu {
  constructor(e, n) {
    let r = 0, s = "";
    J3(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (J3(this.data_)) {
      const r = this.data_, s = z0e(r, e, n);
      return s === null ? null : new pu(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new pu(r, !0);
    }
  }
  static getBlob(...e) {
    if (W2()) {
      const n = e.map((r) => r instanceof pu ? r.data_ : r);
      return new pu(V0e.apply(null, n));
    } else {
      const n = e.map((o) => Y2(o) ? $0e(Sa.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new pu(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function VZ(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return L0e(e) ? e : null;
}
function X0e(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function eIe(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function zZ(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function tIe(t, e) {
  return e;
}
class ws {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || tIe;
  }
}
let ME = null;
function nIe(t) {
  return !Y2(t) || t.length < 2 ? t : zZ(t);
}
function GZ() {
  if (ME)
    return ME;
  const t = [];
  t.push(new ws("bucket")), t.push(new ws("generation")), t.push(new ws("metageneration")), t.push(new ws("name", "fullPath", !0));
  function e(i, o) {
    return nIe(o);
  }
  const n = new ws("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new ws("size");
  return s.xform = r, t.push(s), t.push(new ws("timeCreated")), t.push(new ws("updated")), t.push(new ws("md5Hash", null, !0)), t.push(new ws("cacheControl", null, !0)), t.push(new ws("contentDisposition", null, !0)), t.push(new ws("contentEncoding", null, !0)), t.push(new ws("contentLanguage", null, !0)), t.push(new ws("contentType", null, !0)), t.push(new ws("metadata", "customMetadata", !0)), ME = t, ME;
}
function rIe(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new Ci(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function sIe(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return rIe(r, t), r;
}
function $Z(t, e, n) {
  const r = VZ(e);
  return r === null ? null : sIe(t, r, n);
}
function iIe(t, e, n, r) {
  const s = VZ(e);
  if (s === null || !Y2(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((a) => {
    const c = t.bucket, l = t.fullPath, h = "/b/" + o(c) + "/o/" + o(l), u = yP(h, n, r), d = BZ({
      alt: "media",
      token: a
    });
    return u + d;
  })[0];
}
function oIe(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class B2 {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function KZ(t) {
  if (!t)
    throw F2();
}
function aIe(t, e) {
  function n(r, s) {
    const i = $Z(t, s, e);
    return KZ(i !== null), i;
  }
  return n;
}
function cIe(t, e) {
  function n(r, s) {
    const i = $Z(t, s, e);
    return KZ(i !== null), iIe(i, s, t.host, t._protocol);
  }
  return n;
}
function JZ(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? (
      /* This exact message string is the only consistent part of the */
      /* server's error response that identifies it as an App Check error. */
      n.getErrorText().includes("Firebase App Check token is invalid") ? s = C0e() : s = w0e()
    ) : n.getStatus() === 402 ? s = v0e(t.bucket) : n.getStatus() === 403 ? s = T0e(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function QZ(t) {
  const e = JZ(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = y0e(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function lIe(t, e, n) {
  const r = e.fullServerUrl(), s = yP(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new B2(s, i, cIe(t, n), o);
  return a.errorHandler = QZ(e), a;
}
function uIe(t, e) {
  const n = e.fullServerUrl(), r = yP(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new B2(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = QZ(e), a;
}
function hIe(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function dIe(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = hIe(null, e)), r;
}
function fIe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = dIe(e, r, s), h = oIe(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = pu.getBlob(u, r, d);
  if (f === null)
    throw k0e();
  const p = { name: l.fullPath }, _ = yP(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new B2(_, m, aIe(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = JZ(e), y;
}
class pIe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = Xd.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = Xd.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = Xd.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw ly("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw ly("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw ly("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw ly("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw ly("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class _Ie extends pIe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function q2() {
  return new _Ie();
}
class Pf {
  constructor(e, n) {
    this._service = e, n instanceof Ci ? this._location = n : this._location = Ci.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new Pf(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new Ci(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return zZ(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = X0e(this._location.path);
    if (e === null)
      return null;
    const n = new Ci(this._location.bucket, e);
    return new Pf(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw O0e(e);
  }
}
function mIe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = fIe(t.storage, t._location, GZ(), new pu(e, !0), n);
  return t.storage.makeRequestWithTokens(r, q2).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function gIe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = lIe(t.storage, t._location, GZ());
  return t.storage.makeRequestWithTokens(e, q2).then((n) => {
    if (n === null)
      throw N0e();
    return n;
  });
}
function yIe(t) {
  t._throwIfRoot("deleteObject");
  const e = uIe(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, q2);
}
function vIe(t, e) {
  const n = eIe(t._location.path, e), r = new Ci(t._location.bucket, n);
  return new Pf(t.storage, r);
}
function wIe(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function CIe(t, e) {
  return new Pf(t, e);
}
function ZZ(t, e) {
  if (t instanceof H2) {
    const n = t;
    if (n._bucket == null)
      throw R0e();
    const r = new Pf(n, n._bucket);
    return e != null ? ZZ(r, e) : r;
  } else
    return e !== void 0 ? vIe(t, e) : t;
}
function TIe(t, e) {
  if (e && wIe(e)) {
    if (t instanceof H2)
      return CIe(t, e);
    throw gL("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return ZZ(t, e);
}
function Z3(t, e) {
  const n = e == null ? void 0 : e[YZ];
  return n == null ? null : Ci.makeFromBucketSpec(n, t);
}
function bIe(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : gQ(s, t.app.options.projectId));
}
class H2 {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = FZ, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = m0e, this._maxUploadRetryTime = g0e, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = Ci.makeFromBucketSpec(s, this._host) : this._bucket = Z3(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = Ci.makeFromBucketSpec(this._url, e) : this._bucket = Z3(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    Q3(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    Q3(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new Pf(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new D0e(WZ());
    {
      const o = H0e(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const X3 = "@firebase/storage", ez = "0.11.2", XZ = "storage";
function EIe(t, e, n) {
  return t = as(t), mIe(t, e, n);
}
function SIe(t) {
  return t = as(t), gIe(t);
}
function IIe(t) {
  return t = as(t), yIe(t);
}
function HA(t, e) {
  return t = as(t), TIe(t, e);
}
function RIe(t = EQ(), e) {
  t = as(t);
  const n = bQ(t, XZ).getImmediate({
    identifier: e
  }), r = mQ("storage");
  return r && kIe(n, ...r), n;
}
function kIe(t, e, n, r = {}) {
  bIe(t, e, n, r);
}
function NIe(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new H2(n, r, s, e, jT);
}
function PIe() {
  If(new bh(
    XZ,
    NIe,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), $c(X3, ez, ""), $c(X3, ez, "esm2017");
}
PIe();
class eX {
  constructor() {
    this.db = f0e(), this.storage = RIe();
  }
  async get(e) {
    let n = await n0e(DE(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await t0e(DE(this.db, e), n);
  }
  async set(e, n) {
    return await e0e(DE(this.db, e), n);
  }
  async watch(e, n, r) {
    return s0e(i0e(DE(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await EIe(HA(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await IIe(HA(this.storage, e));
  }
  async objectUrl(e) {
    return await SIe(HA(this.storage, e));
  }
}
class OIe extends eX {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch(Vp.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set(Vp.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set(Vp.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get(Vp.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get(Vp.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
const DIe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, AIe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, MIe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, xIe = {
  localhost: DIe,
  hybrid: AIe,
  ihunterdev: MIe,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, LIe = !1;
class w_ {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return xIe[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && LIe ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  static firebaseConfig() {
    var e;
    if (!process)
      return process && ((e = process == null ? void 0 : process.env) == null ? void 0 : e.FIREBASE_CONFIG) && JSON.parse(process.env.FIREBASE_CONFIG);
  }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class xE extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const _i = "application/json", UIe = "application/octet-stream";
class tX {
  constructor() {
  }
  get apiServer() {
    return w_.config.functionURL;
  }
  get tileServer() {
    return w_.config.tileURL;
  }
  get webServer() {
    return w_.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let s = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || _i,
        Accept: r || _i
      }
    };
    return this.request(e, s);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: _i
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let i = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || _i,
        Accept: s || _i
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async patch(e, n, r, s) {
    let i = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || _i,
        Accept: s || _i
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async post(e, n, r, s) {
    let i = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || _i,
        Accept: s || _i
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async delete(e, n, r) {
    let s = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || _i,
        Accept: r || _i
      }
    };
    return this.request(e, s);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new xE(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(_i)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new xE(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(_i) ? r(await i.json()) : o && o.startsWith(UIe) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new xE(500, i.message, i))) : s(new xE(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class FIe {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var FS = { exports: {} };
function YIe(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
FS.exports = YIe;
const G = (FS.exports == null ? {} : FS.exports).default || FS.exports, nu = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, tz = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class Mi {
  static TileServer() {
    return w_.config.tileURL;
  }
  static WebServer() {
    return w_.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return nu.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return G(nu.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return G(nu.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return G(nu.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + G(tz.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), G(nu.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), G(nu.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + G(tz.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return G(nu.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return G(nu.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const js = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, jA = {
  REGIONS: "/product/regions/{region}"
}, Vs = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, nz = {
  PRODUCT: "/product/skus/{product}"
}, rz = {
  PRODUCT: "/product/prices/{product}"
}, WIe = {
  PROMOTIONS: "/store/promotions"
};
class BIe {
  static AllRegions() {
    return VA(G(jA.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return jA.REGIONS;
  }
  static Regions(e) {
    return G(jA.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Vs.REGION;
  }
  static Region(e) {
    return G(Vs.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return js.REGION;
  }
  static RegionMetadata(e) {
    return G(js.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Vs.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return G(Vs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return js.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return G(js.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Vs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return G(Vs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return js.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return G(js.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Vs.RESOURCE;
  }
  static Resource(e, n) {
    return G(Vs.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return js.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return G(js.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Vs.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return G(Vs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return js.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return G(js.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Vs.MAP;
  }
  static Map(e, n, r, s) {
    return G(Vs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return js.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return G(js.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Vs.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return G(Vs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return js.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return G(js.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return VA(G(nz.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return G(nz.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return VA(G(rz.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return G(rz.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return WIe.PROMOTIONS;
  }
}
const VA = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class qIe {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const sz = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, iz = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, oz = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, az = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, zA = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, cz = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, HIe = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
let fa = class {
  static RegionPropertyMap() {
    return sz.METADATA;
  }
  static SubscriptionPropertyMap() {
    return iz.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return oz.METADATA;
  }
  static ResourcePropertyMap() {
    return az.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return zA.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return zA.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return cz.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties(sz.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(iz.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(oz.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(az.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(zA.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(cz.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(HIe.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
};
var YS = { exports: {} };
function jIe(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
YS.exports = jIe;
const WS = (YS.exports == null ? {} : YS.exports).default || YS.exports;
var BS = { exports: {} };
async function VIe(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
BS.exports = VIe;
const yL = (BS.exports == null ? {} : BS.exports).default || BS.exports;
Promise.allMap = yL;
class zIe {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, fa.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, fa.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, fa.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, fa.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, fa.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[fa.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, fa.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = WS(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = WS(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[fa.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, fa.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = WS(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class GIe extends qIe {
  constructor(e) {
    super(e), this.http = new tX(), this.builder = new zIe(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(Mi.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(Mi.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(Mi.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Mi.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(Mi.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Mi.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(Mi.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + Mi.CountyMetadata(e)), await this.http.getJson(Mi.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + Mi.CountyProductMetadata(e, n)), await this.http.getJson(Mi.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const uy = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class $Ie {
  static Apple(e) {
    return G(uy.APPLE, { uid: e });
  }
  static Android(e) {
    return G(uy.ANDROID, { uid: e });
  }
  static Web(e) {
    return G(uy.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return G(uy.WEB, { uid: e }) + G(uy.SKU, { region: n, sku: r });
  }
}
const Br = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, hy = {
  CUSTOMER: `/${Br.USERS}/{uid}/${Br.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${Br.USERS}/{uid}/${Br.SUBSCRIPTIONS}`,
  PRODUCT: `/${Br.PRODUCTS}/{product}`,
  PRICE: `/${Br.PRODUCTS}/{product}/${Br.PRICES}/{price}`
};
class KIe {
  static Customer(e, n) {
    return G(hy.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return G(hy.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return hy.CUSTOMER;
  }
  static PriceRoute() {
    return hy.PRICE;
  }
  static ProductRoute() {
    return hy.PRODUCT;
  }
  static Users() {
    return Br.USERS;
  }
  static Customers() {
    return Br.CUSTOMERS;
  }
  static Subscriptions() {
    return Br.SUBSCRIPTIONS;
  }
  static Schedules() {
    return Br.SCHEDULES;
  }
  static Products() {
    return Br.PRODUCTS;
  }
  static Prices() {
    return Br.PRICES;
  }
  static Promotions() {
    return Br.PROMOTIONS;
  }
  static Payments() {
    return Br.PAYMENTS;
  }
  static Coupons() {
    return Br.COUPONS;
  }
  static Invoices() {
    return Br.INVOICES;
  }
}
const Zh = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class zp {
  static Preferences(e) {
    return G(Zh.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return G(Zh.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return Zh.OPT_IN;
  }
  static Status(e) {
    return G(Zh.STATUS, { uid: e });
  }
  static StatusRoute() {
    return Zh.STATUS;
  }
  static Email(e) {
    return G(Zh.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return Zh.EMAIL;
  }
}
const lz = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class JIe {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, lz.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * lz.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const Xh = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, GA = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, uz = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, $A = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, hz = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, dy = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, ed = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, dz = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class j2 {
  static OutgoingContactRequestProcessedRoute() {
    return Xh.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return G(Xh.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return G(Xh.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return Xh.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return G(Xh.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return Xh.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return G(Xh.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return GA.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return G(GA.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return G(GA.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return G(uz.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return uz.DATA;
  }
  static Chat(e) {
    return G($A.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var r, s;
    const i = Date.now();
    let o = e.length > 2, a = {}, c = {}, l = {};
    for (let u of e)
      a[u] = !0, c[u] = ((r = n == null ? void 0 : n.lastviewed) == null ? void 0 : r[u]) || i, l[u] = ((s = n == null ? void 0 : n.lastcleared) == null ? void 0 : s[u]) || i;
    let h = {
      participants: a,
      isgroupchat: o,
      lastviewed: c,
      lastcleared: l
    };
    return o && (h.chatname = $A.GROUP_NAME), h;
  }
  static ChatParticipants(e) {
    return G($A.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return G(hz.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return hz.DATA;
  }
  static MessageRoute() {
    return dy.ROUTE;
  }
  static Message(e, n) {
    const r = j2.MessageKey(n);
    return G(dy.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: ed.NOTIFY_ALL,
      type: dy.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return G(dy.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return dy.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return G(ed.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return G(ed.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === ed.NOTIFY_ALL ? ed.TITLE_FROM_NOTIFY_ALL : G(ed.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return G(dz.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return ed.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return dz.UNKNOWN_NAME;
  }
}
const nX = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
}, N = function(t, e) {
  if (!t)
    throw ng(e);
}, ng = function(t) {
  return new Error("Firebase Database (" + nX.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
}, rX = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, QIe = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, V2 = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(rX(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : QIe(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], o = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const a = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const c = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || o == null || a == null || c == null)
        throw new ZIe();
      const l = i << 2 | o >> 4;
      if (r.push(l), a !== 64) {
        const h = o << 4 & 240 | a >> 2;
        if (r.push(h), c !== 64) {
          const u = a << 6 & 192 | c;
          r.push(u);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class ZIe extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const sX = function(t) {
  const e = rX(t);
  return V2.encodeByteArray(e, !0);
}, KI = function(t) {
  return sX(t).replace(/\./g, "");
}, JI = function(t) {
  try {
    return V2.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function XIe(t) {
  return iX(void 0, t);
}
function iX(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !eRe(n) || (t[n] = iX(t[n], e[n]));
  return t;
}
function eRe(t) {
  return t !== "__proto__";
}
function tRe() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const nRe = () => tRe().__FIREBASE_DEFAULTS__, rRe = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, sRe = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && JI(t[1]);
  return e && JSON.parse(e);
}, vP = () => {
  try {
    return nRe() || rRe() || sRe();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, iRe = (t) => {
  var e, n;
  return (n = (e = vP()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, oX = (t) => {
  const e = iRe(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, oRe = () => {
  var t;
  return (t = vP()) === null || t === void 0 ? void 0 : t.config;
}, aRe = (t) => {
  var e;
  return (e = vP()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
class QT {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function aX(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    KI(JSON.stringify(n)),
    KI(JSON.stringify(o)),
    ""
  ].join(".");
}
function Aa() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function z2() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(Aa());
}
function cRe() {
  var t;
  const e = (t = vP()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function lRe() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function cX() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function lX() {
  return nX.NODE_ADMIN === !0;
}
function uRe() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function hRe() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const dRe = "FirebaseError";
class Al extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = dRe, Object.setPrototypeOf(this, Al.prototype), Error.captureStackTrace && Error.captureStackTrace(this, ZT.prototype.create);
  }
}
class ZT {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? fRe(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new Al(s, a, r);
  }
}
function fRe(t, e) {
  return t.replace(pRe, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const pRe = /\{\$([^}]+)}/g;
function $w(t) {
  return JSON.parse(t);
}
function pr(t) {
  return JSON.stringify(t);
}
const uX = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = $w(JI(i[0]) || ""), n = $w(JI(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, _Re = function(t) {
  const e = uX(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, mRe = function(t) {
  const e = uX(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function Va(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function gm(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function fz(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function QI(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function vL(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (pz(i) && pz(o)) {
      if (!vL(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function pz(t) {
  return t !== null && typeof t == "object";
}
function G2(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function yv(t) {
  const e = {};
  return t.replace(/^\?/, "").split("&").forEach((n) => {
    if (n) {
      const [r, s] = n.split("=");
      e[decodeURIComponent(r)] = decodeURIComponent(s);
    }
  }), e;
}
function vv(t) {
  const e = t.indexOf("?");
  if (!e)
    return "";
  const n = t.indexOf("#", e);
  return t.substring(e, n > 0 ? n : void 0);
}
class gRe {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function yRe(t, e) {
  const n = new vRe(t, e);
  return n.subscribe.bind(n);
}
class vRe {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    wRe(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = KA), s.error === void 0 && (s.error = KA), s.complete === void 0 && (s.complete = KA);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function wRe(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function KA() {
}
function wP(t, e) {
  return `${t} failed: ${e} argument `;
}
const CRe = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, N(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, CP = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function cs(t) {
  return t && t._delegate ? t._delegate : t;
}
class Rh {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const bd = "[DEFAULT]";
class TRe {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new QT();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (ERe(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: bd });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = bd) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = bd) {
    return this.instances.has(e);
  }
  getOptions(e = bd) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: bRe(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = bd) {
    return this.component ? this.component.multipleInstances ? e : bd : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function bRe(t) {
  return t === bd ? void 0 : t;
}
function ERe(t) {
  return t.instantiationMode === "EAGER";
}
class SRe {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new TRe(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var Rt;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(Rt || (Rt = {}));
const IRe = {
  debug: Rt.DEBUG,
  verbose: Rt.VERBOSE,
  info: Rt.INFO,
  warn: Rt.WARN,
  error: Rt.ERROR,
  silent: Rt.SILENT
}, RRe = Rt.INFO, kRe = {
  [Rt.DEBUG]: "log",
  [Rt.VERBOSE]: "log",
  [Rt.INFO]: "info",
  [Rt.WARN]: "warn",
  [Rt.ERROR]: "error"
}, NRe = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = kRe[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class $2 {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = RRe, this._logHandler = NRe, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in Rt))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? IRe[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, Rt.DEBUG, ...e), this._logHandler(this, Rt.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, Rt.VERBOSE, ...e), this._logHandler(this, Rt.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, Rt.INFO, ...e), this._logHandler(this, Rt.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, Rt.WARN, ...e), this._logHandler(this, Rt.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, Rt.ERROR, ...e), this._logHandler(this, Rt.ERROR, ...e);
  }
}
const PRe = (t, e) => e.some((n) => t instanceof n);
let _z, mz;
function ORe() {
  return _z || (_z = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function DRe() {
  return mz || (mz = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const hX = /* @__PURE__ */ new WeakMap(), wL = /* @__PURE__ */ new WeakMap(), dX = /* @__PURE__ */ new WeakMap(), JA = /* @__PURE__ */ new WeakMap(), K2 = /* @__PURE__ */ new WeakMap();
function ARe(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(Gu(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && hX.set(n, t);
  }).catch(() => {
  }), K2.set(e, t), e;
}
function MRe(t) {
  if (wL.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  wL.set(t, e);
}
let CL = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return wL.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || dX.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return Gu(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function xRe(t) {
  CL = t(CL);
}
function LRe(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(QA(this), e, ...n);
    return dX.set(r, e.sort ? e.sort() : [e]), Gu(r);
  } : DRe().includes(t) ? function(...e) {
    return t.apply(QA(this), e), Gu(hX.get(this));
  } : function(...e) {
    return Gu(t.apply(QA(this), e));
  };
}
function URe(t) {
  return typeof t == "function" ? LRe(t) : (t instanceof IDBTransaction && MRe(t), PRe(t, ORe()) ? new Proxy(t, CL) : t);
}
function Gu(t) {
  if (t instanceof IDBRequest)
    return ARe(t);
  if (JA.has(t))
    return JA.get(t);
  const e = URe(t);
  return e !== t && (JA.set(t, e), K2.set(e, t)), e;
}
const QA = (t) => K2.get(t);
function FRe(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = Gu(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(Gu(o.result), c.oldVersion, c.newVersion, Gu(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const YRe = ["get", "getKey", "getAll", "getAllKeys", "count"], WRe = ["put", "add", "delete", "clear"], ZA = /* @__PURE__ */ new Map();
function gz(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (ZA.get(e))
    return ZA.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = WRe.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || YRe.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return ZA.set(e, i), i;
}
xRe((t) => ({
  ...t,
  get: (e, n, r) => gz(e, n) || t.get(e, n, r),
  has: (e, n) => !!gz(e, n) || t.has(e, n)
}));
class BRe {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((e) => {
      if (qRe(e)) {
        const n = e.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((e) => e).join(" ");
  }
}
function qRe(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const TL = "@firebase/app", yz = "0.9.8", Of = new $2("@firebase/app"), HRe = "@firebase/app-compat", jRe = "@firebase/analytics-compat", VRe = "@firebase/analytics", zRe = "@firebase/app-check-compat", GRe = "@firebase/app-check", $Re = "@firebase/auth", KRe = "@firebase/auth-compat", JRe = "@firebase/database", QRe = "@firebase/database-compat", ZRe = "@firebase/functions", XRe = "@firebase/functions-compat", eke = "@firebase/installations", tke = "@firebase/installations-compat", nke = "@firebase/messaging", rke = "@firebase/messaging-compat", ske = "@firebase/performance", ike = "@firebase/performance-compat", oke = "@firebase/remote-config", ake = "@firebase/remote-config-compat", cke = "@firebase/storage", lke = "@firebase/storage-compat", uke = "@firebase/firestore", hke = "@firebase/firestore-compat", dke = "firebase", fke = "9.20.0", bL = "[DEFAULT]", pke = {
  [TL]: "fire-core",
  [HRe]: "fire-core-compat",
  [VRe]: "fire-analytics",
  [jRe]: "fire-analytics-compat",
  [GRe]: "fire-app-check",
  [zRe]: "fire-app-check-compat",
  [$Re]: "fire-auth",
  [KRe]: "fire-auth-compat",
  [JRe]: "fire-rtdb",
  [QRe]: "fire-rtdb-compat",
  [ZRe]: "fire-fn",
  [XRe]: "fire-fn-compat",
  [eke]: "fire-iid",
  [tke]: "fire-iid-compat",
  [nke]: "fire-fcm",
  [rke]: "fire-fcm-compat",
  [ske]: "fire-perf",
  [ike]: "fire-perf-compat",
  [oke]: "fire-rc",
  [ake]: "fire-rc-compat",
  [cke]: "fire-gcs",
  [lke]: "fire-gcs-compat",
  [uke]: "fire-fst",
  [hke]: "fire-fst-compat",
  "fire-js": "fire-js",
  [dke]: "fire-js-all"
}, ZI = /* @__PURE__ */ new Map(), EL = /* @__PURE__ */ new Map();
function _ke(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    Of.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function Df(t) {
  const e = t.name;
  if (EL.has(e))
    return Of.debug(`There were multiple attempts to register component ${e}.`), !1;
  EL.set(e, t);
  for (const n of ZI.values())
    _ke(n, t);
  return !0;
}
function fX(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const mke = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, $u = new ZT("app", "Firebase", mke);
class gke {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new Rh(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw $u.create("app-deleted", { appName: this._name });
  }
}
const XT = fke;
function yke(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: bL, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw $u.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = oRe()), !n)
    throw $u.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = ZI.get(s);
  if (i) {
    if (vL(n, i.options) && vL(r, i.config))
      return i;
    throw $u.create("duplicate-app", { appName: s });
  }
  const o = new SRe(s);
  for (const c of EL.values())
    o.addComponent(c);
  const a = new gke(n, r, o);
  return ZI.set(s, a), a;
}
function pX(t = bL) {
  const e = ZI.get(t);
  if (!e && t === bL)
    return yke();
  if (!e)
    throw $u.create("no-app", { appName: t });
  return e;
}
function Jc(t, e, n) {
  var r;
  let s = (r = pke[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), Of.warn(a.join(" "));
    return;
  }
  Df(new Rh(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const vke = "firebase-heartbeat-database", wke = 1, Kw = "firebase-heartbeat-store";
let XA = null;
function _X() {
  return XA || (XA = FRe(vke, wke, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(Kw);
      }
    }
  }).catch((t) => {
    throw $u.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), XA;
}
async function Cke(t) {
  try {
    return (await _X()).transaction(Kw).objectStore(Kw).get(mX(t));
  } catch (e) {
    if (e instanceof Al)
      Of.warn(e.message);
    else {
      const n = $u.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      Of.warn(n.message);
    }
  }
}
async function vz(t, e) {
  try {
    const n = (await _X()).transaction(Kw, "readwrite");
    return await n.objectStore(Kw).put(e, mX(t)), n.done;
  } catch (n) {
    if (n instanceof Al)
      Of.warn(n.message);
    else {
      const r = $u.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      Of.warn(r.message);
    }
  }
}
function mX(t) {
  return `${t.name}!${t.options.appId}`;
}
const Tke = 1024, bke = 30 * 24 * 60 * 60 * 1e3;
class Eke {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new Ike(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const e = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), n = wz();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === n || this._heartbeatsCache.heartbeats.some((r) => r.date === n)))
      return this._heartbeatsCache.heartbeats.push({ date: n, agent: e }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const s = new Date(r.date).valueOf();
        return Date.now() - s <= bke;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = wz(), { heartbeatsToSend: n, unsentEntries: r } = Ske(this._heartbeatsCache.heartbeats), s = KI(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function wz() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function Ske(t, e = Tke) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), Cz(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), Cz(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class Ike {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return uRe() ? hRe().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await Cke(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return vz(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return vz(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function Cz(t) {
  return KI(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function Rke(t) {
  Df(new Rh(
    "platform-logger",
    (e) => new BRe(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Df(new Rh(
    "heartbeat",
    (e) => new Eke(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Jc(TL, yz, t), Jc(TL, yz, "esm2017"), Jc("fire-js", "");
}
Rke("");
const Tz = "@firebase/database", bz = "0.14.4";
let gX = "";
function kke(t) {
  gX = t;
}
class Nke {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), pr(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : $w(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class Pke {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return Va(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const yX = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new Nke(e);
    }
  } catch {
  }
  return new Pke();
}, xd = yX("localStorage"), Oke = yX("sessionStorage"), C_ = new $2("@firebase/database"), Dke = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), vX = function(t) {
  const e = CRe(t), n = new gRe();
  n.update(e);
  const r = n.digest();
  return V2.encodeByteArray(r);
}, eb = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += eb.apply(null, r) : typeof r == "object" ? e += pr(r) : e += r, e += " ";
  }
  return e;
};
let Wv = null, Ez = !0;
const Ake = function(t, e) {
  N(!e, "Can't turn on custom loggers persistently."), C_.logLevel = Rt.VERBOSE, Wv = C_.log.bind(C_);
}, $r = function(...t) {
  if (Ez === !0 && (Ez = !1, Wv === null && Oke.get("logging_enabled") === !0 && Ake()), Wv) {
    const e = eb.apply(null, t);
    Wv(e);
  }
}, tb = function(t) {
  return function(...e) {
    $r(t, ...e);
  };
}, SL = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + eb(...t);
  C_.error(e);
}, yl = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${eb(...t)}`;
  throw C_.error(e), new Error(e);
}, xs = function(...t) {
  const e = "FIREBASE WARNING: " + eb(...t);
  C_.warn(e);
}, Mke = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && xs("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, J2 = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, xke = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, ym = "[MIN_NAME]", Af = "[MAX_NAME]", up = function(t, e) {
  if (t === e)
    return 0;
  if (t === ym || e === Af)
    return -1;
  if (e === ym || t === Af)
    return 1;
  {
    const n = Sz(t), r = Sz(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, Lke = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, fy = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + pr(e));
}, Q2 = function(t) {
  if (typeof t != "object" || t === null)
    return pr(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += pr(e[r]), n += ":", n += Q2(t[e[r]]);
  return n += "}", n;
}, wX = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function ts(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const CX = function(t) {
  N(!J2(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, Uke = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, Fke = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function Yke(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const Wke = new RegExp("^-?(0*)\\d{1,10}$"), Bke = -2147483648, qke = 2147483647, Sz = function(t) {
  if (Wke.test(t)) {
    const e = Number(t);
    if (e >= Bke && e <= qke)
      return e;
  }
  return null;
}, rg = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw xs("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, Hke = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, Bv = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class jke {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    xs(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class Vke {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? ($r("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', xs(e);
  }
}
class qS {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
qS.OWNER = "owner";
const Z2 = "5", TX = "v", bX = "s", EX = "r", SX = "f", IX = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, RX = "ls", kX = "p", IL = "ac", NX = "websocket", PX = "long_polling";
class OX {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = xd.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && xd.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function zke(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function DX(t, e, n) {
  N(typeof e == "string", "typeof type must == string"), N(typeof n == "object", "typeof params must == object");
  let r;
  if (e === NX)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === PX)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  zke(t) && (n.ns = t.namespace);
  const s = [];
  return ts(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class Gke {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    Va(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return XIe(this.counters_);
  }
}
const eM = {}, tM = {};
function X2(t) {
  const e = t.toString();
  return eM[e] || (eM[e] = new Gke()), eM[e];
}
function $ke(t, e) {
  const n = t.toString();
  return tM[n] || (tM[n] = e()), tM[n];
}
class Kke {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && rg(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const Iz = "start", Jke = "close", Qke = "pLPCommand", Zke = "pRTLPCB", AX = "id", MX = "pw", xX = "ser", Xke = "cb", eNe = "seg", tNe = "ts", nNe = "d", rNe = "dframe", LX = 1870, UX = 30, sNe = LX - UX, iNe = 25e3, oNe = 3e4;
class i_ {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = tb(e), this.stats_ = X2(n), this.urlFn = (c) => (this.appCheckToken && (c[IL] = this.appCheckToken), DX(n, PX, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new Kke(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(oNe)), xke(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new eW((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === Iz)
            this.id = a, this.password = c;
          else if (o === Jke)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[Iz] = "t", r[xX] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[Xke] = this.scriptTagHolder.uniqueCallbackIdentifier), r[TX] = Z2, this.transportSessionId && (r[bX] = this.transportSessionId), this.lastSessionId && (r[RX] = this.lastSessionId), this.applicationId && (r[kX] = this.applicationId), this.appCheckToken && (r[IL] = this.appCheckToken), typeof location < "u" && location.hostname && IX.test(location.hostname) && (r[EX] = SX);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    i_.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    i_.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return i_.forceAllow_ ? !0 : !i_.forceDisallow_ && typeof document < "u" && document.createElement != null && !Uke() && !Fke();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = pr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = sX(n), s = wX(r, sNe);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[rNe] = "t", r[AX] = e, r[MX] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = pr(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
}
class eW {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = Dke(), window[Qke + this.uniqueCallbackIdentifier] = e, window[Zke + this.uniqueCallbackIdentifier] = n, this.myIFrame = eW.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        $r("frame writing exception"), a.stack && $r(a.stack), $r(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || $r("No IE domain setting required");
      } catch {
        const n = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + n + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[AX] = this.myID, e[MX] = this.myPW, e[xX] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + UX + r.length <= LX; ) {
        const i = this.pendingSegs.shift();
        r = r + "&" + eNe + s + "=" + i.seg + "&" + tNe + s + "=" + i.ts + "&" + nNe + s + "=" + i.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(iNe)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          $r("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const aNe = 16384, cNe = 45e3;
let XI = null;
typeof MozWebSocket < "u" ? XI = MozWebSocket : typeof WebSocket < "u" && (XI = WebSocket);
class bo {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = tb(this.connId), this.stats_ = X2(n), this.connURL = bo.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[TX] = Z2, typeof location < "u" && location.hostname && IX.test(location.hostname) && (o[EX] = SX), n && (o[bX] = n), r && (o[RX] = r), s && (o[IL] = s), i && (o[kX] = i), DX(e, NX, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, xd.set("previous_websocket_failure", !0);
    try {
      let r;
      lX(), this.mySock = new XI(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    bo.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && XI !== null && !bo.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return xd.isInMemoryStorage || xd.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    xd.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = $w(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (N(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = pr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = wX(n, aNe);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(cNe));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
bo.responsesRequiredToBeHealthy = 2;
bo.healthyTimeout = 3e4;
class Jw {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [i_, bo];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = bo && bo.isAvailable();
    let r = n && !bo.previouslyFailed();
    if (e.webSocketOnly && (n || xs("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [bo];
    else {
      const s = this.transports_ = [];
      for (const i of Jw.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      Jw.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
Jw.globalTransportInitialized_ = !1;
const lNe = 6e4, uNe = 5e3, hNe = 10 * 1024, dNe = 100 * 1024, nM = "t", Rz = "d", fNe = "s", kz = "r", pNe = "e", Nz = "o", Pz = "a", Oz = "n", Dz = "p", _Ne = "h";
class mNe {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = tb("c:" + this.id + ":"), this.transportManager_ = new Jw(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = Bv(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > dNe ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > hNe ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (nM in e) {
      const n = e[nM];
      n === Pz ? this.upgradeIfSecondaryHealthy_() : n === kz ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === Nz && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = fy("t", e), r = fy("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: Dz, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: Pz, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: Oz, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = fy("t", e), r = fy("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = fy(nM, e);
    if (Rz in e) {
      const r = e[Rz];
      if (n === _Ne) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === Oz) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === fNe ? this.onConnectionShutdown_(r) : n === kz ? this.onReset_(r) : n === pNe ? SL("Server Error: " + r) : n === Nz ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : SL("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), Z2 !== r && xs("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), Bv(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(lNe));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : Bv(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(uNe));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: Dz, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (xd.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class FX {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class YX {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, N(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    N(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class eR extends YX {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !z2() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new eR();
  }
  getInitialEvent(e) {
    return N(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const Az = 32, Mz = 768;
class At {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function ct() {
  return new At("");
}
function be(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function kh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function en(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new At(t.pieces_, e);
}
function tW(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function gNe(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function Qw(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function WX(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new At(e, 0);
}
function Rn(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof At)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new At(n, 0);
}
function Ue(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function ks(t, e) {
  const n = be(t), r = be(e);
  if (n === null)
    return e;
  if (n === r)
    return ks(en(t), en(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function yNe(t, e) {
  const n = Qw(t, 0), r = Qw(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = up(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function nW(t, e) {
  if (kh(t) !== kh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function Gi(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (kh(t) > kh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class vNe {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = Qw(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += CP(this.parts_[r]);
    BX(this);
  }
}
function wNe(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += CP(e), BX(t);
}
function CNe(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= CP(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function BX(t) {
  if (t.byteLength_ > Mz)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + Mz + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > Az)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + Az + ") or object contains a cycle " + Ed(t));
}
function Ed(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class rW extends YX {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new rW();
  }
  getInitialEvent(e) {
    return N(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const py = 1e3, TNe = 60 * 5 * 1e3, xz = 30 * 1e3, bNe = 1.3, ENe = 3e4, SNe = "server_kill", Lz = 3;
class Qc extends FX {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = Qc.nextPersistentConnectionId_++, this.log_ = tb("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = py, this.maxReconnectDelay_ = TNe, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !lX())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    rW.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && eR.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(pr(i)), N(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new QT(), r = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (i) => {
        const o = i.d;
        i.s === "ok" ? n.resolve(o) : n.reject(o);
      }
    };
    this.outstandingGets_.push(r), this.outstandingGetCount_++;
    const s = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(s), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), N(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), N(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      Qc.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && Va(e, "w")) {
      const r = gm(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        xs(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || mRe(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = xz);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = _Re(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), N(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const s = r.d;
            this.log_("reportStats", "Error sending stats: " + s);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + pr(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : SL("Unrecognized action received from server: " + pr(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    N(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = py, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = py, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > ENe && (this.reconnectDelay_ = py), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * bNe);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + Qc.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        N(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? $r("getToken() completed but was canceled") : ($r("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new mNe(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            xs(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(SNe);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && xs(u), c());
      }
    }
  }
  interrupt(e) {
    $r("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    $r("Resuming connection for reason: " + e), delete this.interruptReasons_[e], fz(this.interruptReasons_) && (this.reconnectDelay_ = py, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => Q2(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new At(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    $r("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= Lz && (this.reconnectDelay_ = xz, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    $r("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= Lz && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + gX.replace(/\./g, "-")] = 1, z2() ? e["framework.cordova"] = 1 : cX() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = eR.getInstance().currentlyOnline();
    return fz(this.interruptReasons_) && e;
  }
}
Qc.nextPersistentConnectionId_ = 0;
Qc.nextConnectionId_ = 0;
class Pe {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new Pe(e, n);
  }
}
class TP {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new Pe(ym, e), s = new Pe(ym, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return Pe.MIN;
  }
}
let LE;
class qX extends TP {
  static get __EMPTY_NODE() {
    return LE;
  }
  static set __EMPTY_NODE(e) {
    LE = e;
  }
  compare(e, n) {
    return up(e.name, n.name);
  }
  isDefinedOn(e) {
    throw ng("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return Pe.MIN;
  }
  maxPost() {
    return new Pe(Af, LE);
  }
  makePost(e, n) {
    return N(typeof e == "string", "KeyIndex indexValue must always be a string."), new Pe(e, LE);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const T_ = new qX();
class UE {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class Mr {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? Mr.RED, this.left = s ?? ci.EMPTY_NODE, this.right = i ?? ci.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new Mr(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return ci.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return ci.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, Mr.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, Mr.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
Mr.RED = !0;
Mr.BLACK = !1;
class INe {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new Mr(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class ci {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = ci.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new ci(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, Mr.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new ci(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, Mr.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new UE(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new UE(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new UE(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new UE(this.root_, null, this.comparator_, !0, e);
  }
}
ci.EMPTY_NODE = new INe();
function RNe(t, e) {
  return up(t.name, e.name);
}
function sW(t, e) {
  return up(t, e);
}
let RL;
function kNe(t) {
  RL = t;
}
const HX = function(t) {
  return typeof t == "number" ? "number:" + CX(t) : "string:" + t;
}, jX = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    N(typeof e == "string" || typeof e == "number" || typeof e == "object" && Va(e, ".sv"), "Priority must be a string or number.");
  } else
    N(t === RL || t.isEmpty(), "priority of unexpected type.");
  N(t === RL || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let Uz;
class Rr {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = Rr.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, N(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), jX(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    Uz = e;
  }
  static get __childrenNodeConstructor() {
    return Uz;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new Rr(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : Rr.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return Ue(e) ? this : be(e) === ".priority" ? this.priorityNode_ : Rr.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : Rr.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = be(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (N(r !== ".priority" || kh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, Rr.__childrenNodeConstructor.EMPTY_NODE.updateChild(en(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + HX(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += CX(this.value_) : e += this.value_, this.lazyHash_ = vX(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === Rr.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof Rr.__childrenNodeConstructor ? -1 : (N(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = Rr.VALUE_TYPE_ORDER.indexOf(n), i = Rr.VALUE_TYPE_ORDER.indexOf(r);
    return N(s >= 0, "Unknown leaf type: " + n), N(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
Rr.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let VX, zX;
function NNe(t) {
  VX = t;
}
function PNe(t) {
  zX = t;
}
class ONe extends TP {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? up(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return Pe.MIN;
  }
  maxPost() {
    return new Pe(Af, new Rr("[PRIORITY-POST]", zX));
  }
  makePost(e, n) {
    const r = VX(e);
    return new Pe(n, new Rr("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const Mn = new ONe(), DNe = Math.log(2);
class ANe {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / DNe, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const tR = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new Mr(d, u.node, Mr.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new Mr(d, u.node, Mr.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new Mr(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, Mr.BLACK) : (d(m, Mr.BLACK), d(m, Mr.RED));
    }
    return h;
  }, o = new ANe(t.length), a = i(o);
  return new ci(r || e, a);
};
let rM;
const Mp = {};
class bc {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return N(Mp && Mn, "ChildrenNode.ts has not been loaded"), rM = rM || new bc({ ".priority": Mp }, { ".priority": Mn }), rM;
  }
  get(e) {
    const n = gm(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof ci ? n : null;
  }
  hasIndex(e) {
    return Va(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    N(e !== T_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(Pe.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = tR(r, e.getCompare()) : a = Mp;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new bc(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = QI(this.indexes_, (s, i) => {
      const o = gm(this.indexSet_, i);
      if (N(o, "Missing index implementation for " + i), s === Mp)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(Pe.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), tR(a, o.getCompare());
        } else
          return Mp;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new Pe(e.name, a))), c.insert(e, e.node);
      }
    });
    return new bc(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = QI(this.indexes_, (s) => {
      if (s === Mp)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new Pe(e.name, i)) : s;
      }
    });
    return new bc(r, this.indexSet_);
  }
}
let _y;
class ae {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && jX(this.priorityNode_), this.children_.isEmpty() && N(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return _y || (_y = new ae(new ci(sW), null, bc.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || _y;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new ae(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? _y : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = be(e);
    return n === null ? this : this.getImmediateChild(n).getChild(en(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (N(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new Pe(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? _y : this.priorityNode_;
      return new ae(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = be(e);
    if (r === null)
      return n;
    {
      N(be(e) !== ".priority" || kh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(en(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(Mn, (o, a) => {
      n[o] = a.val(e), r++, i && ae.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + HX(this.getPriority().val()) + ":"), this.forEachChild(Mn, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : vX(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new Pe(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new Pe(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new Pe(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, Pe.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, Pe.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === nb ? -1 : 0;
  }
  withIndex(e) {
    if (e === T_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new ae(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === T_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(Mn), s = n.getIterator(Mn);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === T_ ? null : this.indexMap_.get(e.toString());
  }
}
ae.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class MNe extends ae {
  constructor() {
    super(new ci(sW), ae.EMPTY_NODE, bc.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return ae.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const nb = new MNe();
Object.defineProperties(Pe, {
  MIN: {
    value: new Pe(ym, ae.EMPTY_NODE)
  },
  MAX: {
    value: new Pe(Af, nb)
  }
});
qX.__EMPTY_NODE = ae.EMPTY_NODE;
Rr.__childrenNodeConstructor = ae;
kNe(nb);
PNe(nb);
const xNe = !0;
function ar(t, e = null) {
  if (t === null)
    return ae.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), N(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new Rr(n, ar(e));
  }
  if (!(t instanceof Array) && xNe) {
    const n = [];
    let r = !1;
    if (ts(t, (i, o) => {
      if (i.substring(0, 1) !== ".") {
        const a = ar(o);
        a.isEmpty() || (r = r || !a.getPriority().isEmpty(), n.push(new Pe(i, a)));
      }
    }), n.length === 0)
      return ae.EMPTY_NODE;
    const s = tR(n, RNe, (i) => i.name, sW);
    if (r) {
      const i = tR(n, Mn.getCompare());
      return new ae(s, ar(e), new bc({ ".priority": i }, { ".priority": Mn }));
    } else
      return new ae(s, ar(e), bc.Default);
  } else {
    let n = ae.EMPTY_NODE;
    return ts(t, (r, s) => {
      if (Va(t, r) && r.substring(0, 1) !== ".") {
        const i = ar(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(ar(e));
  }
}
NNe(ar);
class LNe extends TP {
  constructor(e) {
    super(), this.indexPath_ = e, N(!Ue(e) && be(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? up(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = ar(e), s = ae.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new Pe(n, s);
  }
  maxPost() {
    const e = ae.EMPTY_NODE.updateChild(this.indexPath_, nb);
    return new Pe(Af, e);
  }
  toString() {
    return Qw(this.indexPath_, 0).join("/");
  }
}
class UNe extends TP {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? up(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return Pe.MIN;
  }
  maxPost() {
    return Pe.MAX;
  }
  makePost(e, n) {
    const r = ar(e);
    return new Pe(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const FNe = new UNe();
function GX(t) {
  return { type: "value", snapshotNode: t };
}
function vm(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function Zw(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function Xw(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function YNe(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class iW {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    N(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(Zw(n, a)) : N(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(vm(n, r)) : o.trackChildChange(Xw(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(Mn, (s, i) => {
      n.hasChild(s) || r.trackChildChange(Zw(s, i));
    }), n.isLeafNode() || n.forEachChild(Mn, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(Xw(s, i, o));
      } else
        r.trackChildChange(vm(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? ae.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class eC {
  constructor(e) {
    this.indexedFilter_ = new iW(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = eC.getStartPost_(e), this.endPost_ = eC.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new Pe(n, r)) || (r = ae.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = ae.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(ae.EMPTY_NODE);
    const i = this;
    return n.forEachChild(Mn, (o, a) => {
      i.matches(new Pe(o, a)) || (s = s.updateImmediateChild(o, ae.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class WNe {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new eC(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new Pe(n, r)) || (r = ae.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = ae.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = ae.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(ae.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, ae.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    N(a.numChildren() === this.limit_, "");
    const c = new Pe(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(Xw(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(Zw(n, u));
        const p = a.updateImmediateChild(n, ae.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(vm(d.name, d.node)), p.updateImmediateChild(d.name, d.node)) : p;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(Zw(l.name, l.node)), i.trackChildChange(vm(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, ae.EMPTY_NODE)) : e;
  }
}
class oW {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = Mn;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return N(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return N(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : ym;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return N(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return N(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : Af;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return N(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === Mn;
  }
  copy() {
    const e = new oW();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function BNe(t) {
  return t.loadsAllData() ? new iW(t.getIndex()) : t.hasLimit() ? new WNe(t) : new eC(t);
}
function Fz(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === Mn ? n = "$priority" : t.index_ === FNe ? n = "$value" : t.index_ === T_ ? n = "$key" : (N(t.index_ instanceof LNe, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = pr(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = pr(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + pr(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = pr(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + pr(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function Yz(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== Mn && (e.i = t.index_.toString()), e;
}
class nR extends FX {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = tb("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (N(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = nR.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = Fz(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), gm(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = nR.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = Fz(e._queryParams), r = e._path.toString(), s = new QT();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + G2(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = $w(a.responseText);
            } catch {
              xs("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && xs("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class qNe {
  constructor() {
    this.rootNode_ = ae.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function rR() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function $X(t, e, n) {
  if (Ue(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = be(e);
    t.children.has(r) || t.children.set(r, rR());
    const s = t.children.get(r);
    e = en(e), $X(s, e, n);
  }
}
function kL(t, e, n) {
  t.value !== null ? n(e, t.value) : HNe(t, (r, s) => {
    const i = new At(e.toString() + "/" + r);
    kL(s, i, n);
  });
}
function HNe(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class jNe {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && ts(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const Wz = 10 * 1e3, VNe = 30 * 1e3, zNe = 5 * 60 * 1e3;
class GNe {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new jNe(e);
    const r = Wz + (VNe - Wz) * Math.random();
    Bv(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    ts(e, (s, i) => {
      i > 0 && Va(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), Bv(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * zNe));
  }
}
var Po;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(Po || (Po = {}));
function aW() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function cW() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function lW(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class sR {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = Po.ACK_USER_WRITE, this.source = aW();
  }
  operationForChild(e) {
    if (Ue(this.path)) {
      if (this.affectedTree.value != null)
        return N(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new At(e));
        return new sR(ct(), n, this.revert);
      }
    } else return N(be(this.path) === e, "operationForChild called for unrelated child."), new sR(en(this.path), this.affectedTree, this.revert);
  }
}
class tC {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = Po.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return Ue(this.path) ? new tC(this.source, ct()) : new tC(this.source, en(this.path));
  }
}
class Mf {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = Po.OVERWRITE;
  }
  operationForChild(e) {
    return Ue(this.path) ? new Mf(this.source, ct(), this.snap.getImmediateChild(e)) : new Mf(this.source, en(this.path), this.snap);
  }
}
class wm {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = Po.MERGE;
  }
  operationForChild(e) {
    if (Ue(this.path)) {
      const n = this.children.subtree(new At(e));
      return n.isEmpty() ? null : n.value ? new Mf(this.source, ct(), n.value) : new wm(this.source, ct(), n);
    } else
      return N(be(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new wm(this.source, en(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class Nh {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (Ue(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = be(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class $Ne {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function KNe(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(YNe(o.childName, o.snapshotNode));
  }), my(t, s, "child_removed", e, r, n), my(t, s, "child_added", e, r, n), my(t, s, "child_moved", i, r, n), my(t, s, "child_changed", e, r, n), my(t, s, "value", e, r, n), s;
}
function my(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => QNe(t, a, c)), o.forEach((a) => {
    const c = JNe(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function JNe(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function QNe(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw ng("Should only compare child_ events.");
  const r = new Pe(e.childName, e.snapshotNode), s = new Pe(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function bP(t, e) {
  return { eventCache: t, serverCache: e };
}
function qv(t, e, n, r) {
  return bP(new Nh(e, n, r), t.serverCache);
}
function KX(t, e, n, r) {
  return bP(t.eventCache, new Nh(e, n, r));
}
function iR(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function xf(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let sM;
const ZNe = () => (sM || (sM = new ci(Lke)), sM);
class zt {
  constructor(e, n = ZNe()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new zt(null);
    return ts(e, (r, s) => {
      n = n.set(new At(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: ct(), value: this.value };
    if (Ue(e))
      return null;
    {
      const r = be(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(en(e), n);
        return i != null ? { path: Rn(new At(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (Ue(e))
      return this;
    {
      const n = be(e), r = this.children.get(n);
      return r !== null ? r.subtree(en(e)) : new zt(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (Ue(e))
      return new zt(n, this.children);
    {
      const r = be(e), s = (this.children.get(r) || new zt(null)).set(en(e), n), i = this.children.insert(r, s);
      return new zt(this.value, i);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (Ue(e))
      return this.children.isEmpty() ? new zt(null) : new zt(null, this.children);
    {
      const n = be(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(en(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new zt(null) : new zt(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (Ue(e))
      return this.value;
    {
      const n = be(e), r = this.children.get(n);
      return r ? r.get(en(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (Ue(e))
      return n;
    {
      const r = be(e), s = (this.children.get(r) || new zt(null)).setTree(en(e), n);
      let i;
      return s.isEmpty() ? i = this.children.remove(r) : i = this.children.insert(r, s), new zt(this.value, i);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(ct(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(Rn(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, ct(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (Ue(e))
      return null;
    {
      const i = be(e), o = this.children.get(i);
      return o ? o.findOnPath_(en(e), Rn(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, ct(), n);
  }
  foreachOnPath_(e, n, r) {
    if (Ue(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = be(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(en(e), Rn(n, s), r) : new zt(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(ct(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(Rn(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class Ho {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new Ho(new zt(null));
  }
}
function Hv(t, e, n) {
  if (Ue(e))
    return new Ho(new zt(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = ks(s, e);
      return i = i.updateChild(o, n), new Ho(t.writeTree_.set(s, i));
    } else {
      const s = new zt(n), i = t.writeTree_.setTree(e, s);
      return new Ho(i);
    }
  }
}
function NL(t, e, n) {
  let r = t;
  return ts(n, (s, i) => {
    r = Hv(r, Rn(e, s), i);
  }), r;
}
function Bz(t, e) {
  if (Ue(e))
    return Ho.empty();
  {
    const n = t.writeTree_.setTree(e, new zt(null));
    return new Ho(n);
  }
}
function PL(t, e) {
  return hp(t, e) != null;
}
function hp(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(ks(n.path, e)) : null;
}
function qz(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(Mn, (r, s) => {
    e.push(new Pe(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new Pe(r, s.value));
  }), e;
}
function Ku(t, e) {
  if (Ue(e))
    return t;
  {
    const n = hp(t, e);
    return n != null ? new Ho(new zt(n)) : new Ho(t.writeTree_.subtree(e));
  }
}
function OL(t) {
  return t.writeTree_.isEmpty();
}
function Cm(t, e) {
  return JX(ct(), t.writeTree_, e);
}
function JX(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (N(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = JX(Rn(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(Rn(t, ".priority"), r)), n;
  }
}
function EP(t, e) {
  return eee(e, t);
}
function XNe(t, e, n, r, s) {
  N(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = Hv(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function ePe(t, e, n, r) {
  N(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = NL(t.visibleWrites, e, n), t.lastWriteId = r;
}
function tPe(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function nPe(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  N(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && rPe(a, r.path) ? s = !1 : Gi(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return sPe(t), !0;
    if (r.snap)
      t.visibleWrites = Bz(t.visibleWrites, r.path);
    else {
      const a = r.children;
      ts(a, (c) => {
        t.visibleWrites = Bz(t.visibleWrites, Rn(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function rPe(t, e) {
  if (t.snap)
    return Gi(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && Gi(Rn(t.path, n), e))
      return !0;
  return !1;
}
function sPe(t) {
  t.visibleWrites = QX(t.allWrites, iPe, ct()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function iPe(t) {
  return t.visible;
}
function QX(t, e, n) {
  let r = Ho.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        Gi(n, o) ? (a = ks(n, o), r = Hv(r, a, i.snap)) : Gi(o, n) && (a = ks(o, n), r = Hv(r, ct(), i.snap.getChild(a)));
      else if (i.children) {
        if (Gi(n, o))
          a = ks(n, o), r = NL(r, a, i.children);
        else if (Gi(o, n))
          if (a = ks(o, n), Ue(a))
            r = NL(r, ct(), i.children);
          else {
            const c = gm(i.children, be(a));
            if (c) {
              const l = c.getChild(en(a));
              r = Hv(r, ct(), l);
            }
          }
      } else
        throw ng("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function ZX(t, e, n, r, s) {
  if (!r && !s) {
    const i = hp(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = Ku(t.visibleWrites, e);
      if (OL(o))
        return n;
      if (n == null && !PL(o, ct()))
        return null;
      {
        const a = n || ae.EMPTY_NODE;
        return Cm(o, a);
      }
    }
  } else {
    const i = Ku(t.visibleWrites, e);
    if (!s && OL(i))
      return n;
    if (!s && n == null && !PL(i, ct()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && (Gi(l.path, e) || Gi(e, l.path));
      }, a = QX(t.allWrites, o, e), c = n || ae.EMPTY_NODE;
      return Cm(a, c);
    }
  }
}
function oPe(t, e, n) {
  let r = ae.EMPTY_NODE;
  const s = hp(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(Mn, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = Ku(t.visibleWrites, e);
    return n.forEachChild(Mn, (o, a) => {
      const c = Cm(Ku(i, new At(o)), a);
      r = r.updateImmediateChild(o, c);
    }), qz(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = Ku(t.visibleWrites, e);
    return qz(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function aPe(t, e, n, r, s) {
  N(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = Rn(e, n);
  if (PL(t.visibleWrites, i))
    return null;
  {
    const o = Ku(t.visibleWrites, i);
    return OL(o) ? s.getChild(n) : Cm(o, s.getChild(n));
  }
}
function cPe(t, e, n, r) {
  const s = Rn(e, n), i = hp(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = Ku(t.visibleWrites, s);
    return Cm(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function lPe(t, e) {
  return hp(t.visibleWrites, e);
}
function uPe(t, e, n, r, s, i, o) {
  let a;
  const c = Ku(t.visibleWrites, e), l = hp(c, ct());
  if (l != null)
    a = l;
  else if (n != null)
    a = Cm(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function hPe() {
  return {
    visibleWrites: Ho.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function oR(t, e, n, r) {
  return ZX(t.writeTree, t.treePath, e, n, r);
}
function uW(t, e) {
  return oPe(t.writeTree, t.treePath, e);
}
function Hz(t, e, n, r) {
  return aPe(t.writeTree, t.treePath, e, n, r);
}
function aR(t, e) {
  return lPe(t.writeTree, Rn(t.treePath, e));
}
function dPe(t, e, n, r, s, i) {
  return uPe(t.writeTree, t.treePath, e, n, r, s, i);
}
function hW(t, e, n) {
  return cPe(t.writeTree, t.treePath, e, n);
}
function XX(t, e) {
  return eee(Rn(t.treePath, e), t.writeTree);
}
function eee(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class fPe {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    N(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), N(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, Xw(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, Zw(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, vm(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, Xw(r, e.snapshotNode, s.oldSnap));
      else
        throw ng("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class pPe {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const tee = new pPe();
class dW {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new Nh(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return hW(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : xf(this.viewCache_), i = dPe(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function _Pe(t) {
  return { filter: t };
}
function mPe(t, e) {
  N(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), N(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function gPe(t, e, n, r, s) {
  const i = new fPe();
  let o, a;
  if (n.type === Po.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = DL(t, e, l.path, l.snap, r, s, i) : (N(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !Ue(l.path), o = cR(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === Po.MERGE) {
    const l = n;
    l.source.fromUser ? o = vPe(t, e, l.path, l.children, r, s, i) : (N(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = AL(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === Po.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = TPe(t, e, l.path, r, s, i) : o = wPe(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === Po.LISTEN_COMPLETE)
    o = CPe(t, e, n.path, r, i);
  else
    throw ng("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return yPe(e, o, c), { viewCache: o, changes: c };
}
function yPe(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = iR(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(GX(iR(e)));
  }
}
function nee(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (aR(r, n) != null)
    return e;
  {
    let a, c;
    if (Ue(n))
      if (N(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = xf(e), h = l instanceof ae ? l : ae.EMPTY_NODE, u = uW(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = oR(r, xf(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = be(n);
      if (l === ".priority") {
        N(kh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = Hz(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = en(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = Hz(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = hW(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return qv(e, a, o.isFullyInitialized() || Ue(n), t.filter.filtersNodes());
  }
}
function cR(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (Ue(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = be(n);
    if (!c.isCompleteForPath(n) && kh(n) > 1)
      return e;
    const p = en(n), _ = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), _) : l = h.updateChild(c.getNode(), f, _, p, tee, null);
  }
  const u = KX(e, l, c.isFullyInitialized() || Ue(n), h.filtersNodes()), d = new dW(s, u, i);
  return nee(t, u, n, s, d, a);
}
function DL(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new dW(s, e, i);
  if (Ue(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = qv(e, l, !0, t.filter.filtersNodes());
  else {
    const u = be(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = qv(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = en(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (Ue(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? tW(d) === ".priority" && _.getChild(WX(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = ae.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = qv(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function jz(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function vPe(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = Rn(n, c);
    jz(e, be(h)) && (a = DL(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = Rn(n, c);
    jz(e, be(h)) || (a = DL(t, a, h, l, s, i, o));
  }), a;
}
function Vz(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function AL(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  Ue(n) ? l = r : l = new zt(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = Vz(t, f, d);
      c = cR(t, c, new At(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = Vz(t, p, d);
      c = cR(t, c, new At(u), _, s, i, o, a);
    }
  }), c;
}
function wPe(t, e, n, r, s, i, o) {
  if (aR(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (Ue(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return cR(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (Ue(n)) {
      let l = new zt(null);
      return c.getNode().forEachChild(T_, (h, u) => {
        l = l.set(new At(h), u);
      }), AL(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new zt(null);
    return r.foreach((h, u) => {
      const d = Rn(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), AL(t, e, n, l, s, i, a, o);
  }
}
function CPe(t, e, n, r, s) {
  const i = e.serverCache, o = KX(e, i.getNode(), i.isFullyInitialized() || Ue(n), i.isFiltered());
  return nee(t, o, n, r, tee, s);
}
function TPe(t, e, n, r, s, i) {
  let o;
  if (aR(r, n) != null)
    return e;
  {
    const a = new dW(r, e, s), c = e.eventCache.getNode();
    let l;
    if (Ue(n) || be(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = oR(r, xf(e));
      else {
        const u = e.serverCache.getNode();
        N(u instanceof ae, "serverChildren would be complete if leaf node"), h = uW(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = be(n);
      let u = hW(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, en(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, ae.EMPTY_NODE, en(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = oR(r, xf(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || aR(r, ct()) != null, qv(e, l, o, t.filter.filtersNodes());
  }
}
class bPe {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new iW(r.getIndex()), i = BNe(r);
    this.processor_ = _Pe(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(ae.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(ae.EMPTY_NODE, a.getNode(), null), h = new Nh(c, o.isFullyInitialized(), s.filtersNodes()), u = new Nh(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = bP(u, h), this.eventGenerator_ = new $Ne(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function EPe(t) {
  return t.viewCache_.serverCache.getNode();
}
function SPe(t) {
  return iR(t.viewCache_);
}
function IPe(t, e) {
  const n = xf(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !Ue(e) && !n.getImmediateChild(be(e)).isEmpty()) ? n.getChild(e) : null;
}
function zz(t) {
  return t.eventRegistrations_.length === 0;
}
function RPe(t, e) {
  t.eventRegistrations_.push(e);
}
function Gz(t, e, n) {
  const r = [];
  if (n) {
    N(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function $z(t, e, n, r) {
  e.type === Po.MERGE && e.source.queryId !== null && (N(xf(t.viewCache_), "We should always have a full cache before handling merges"), N(iR(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = gPe(t.processor_, s, e, n, r);
  return mPe(t.processor_, i.viewCache), N(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, ree(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function kPe(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(Mn, (s, i) => {
    r.push(vm(s, i));
  }), n.isFullyInitialized() && r.push(GX(n.getNode())), ree(t, r, n.getNode(), e);
}
function ree(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return KNe(t.eventGenerator_, e, n, s);
}
let lR;
class see {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function NPe(t) {
  N(!lR, "__referenceConstructor has already been defined"), lR = t;
}
function PPe() {
  return N(lR, "Reference.ts has not been loaded"), lR;
}
function OPe(t) {
  return t.views.size === 0;
}
function fW(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return N(i != null, "SyncTree gave us an op for an invalid query."), $z(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat($z(o, e, n, r));
    return i;
  }
}
function iee(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = oR(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof ae ? (a = uW(n, r), c = !1) : (a = ae.EMPTY_NODE, c = !1);
    const l = bP(new Nh(a, c, !1), new Nh(r, s, !1));
    return new bPe(e, l);
  }
  return o;
}
function DPe(t, e, n, r, s, i) {
  const o = iee(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), RPe(o, n), kPe(o, n);
}
function APe(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = Ph(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(Gz(l, n, r)), zz(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(Gz(c, n, r)), zz(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !Ph(t) && i.push(new (PPe())(e._repo, e._path)), { removed: i, events: o };
}
function oee(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function Ju(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || IPe(r, e);
  return n;
}
function aee(t, e) {
  if (e._queryParams.loadsAllData())
    return SP(t);
  {
    const n = e._queryIdentifier;
    return t.views.get(n);
  }
}
function cee(t, e) {
  return aee(t, e) != null;
}
function Ph(t) {
  return SP(t) != null;
}
function SP(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let uR;
function MPe(t) {
  N(!uR, "__referenceConstructor has already been defined"), uR = t;
}
function xPe() {
  return N(uR, "Reference.ts has not been loaded"), uR;
}
let LPe = 1;
class Kz {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new zt(null), this.pendingWriteTree_ = hPe(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function lee(t, e, n, r, s) {
  return XNe(t.pendingWriteTree_, e, n, r, s), s ? sg(t, new Mf(aW(), e, n)) : [];
}
function UPe(t, e, n, r) {
  ePe(t.pendingWriteTree_, e, n, r);
  const s = zt.fromObject(n);
  return sg(t, new wm(aW(), e, s));
}
function Tu(t, e, n = !1) {
  const r = tPe(t.pendingWriteTree_, e);
  if (nPe(t.pendingWriteTree_, e)) {
    let s = new zt(null);
    return r.snap != null ? s = s.set(ct(), !0) : ts(r.children, (i) => {
      s = s.set(new At(i), !0);
    }), sg(t, new sR(r.path, s, n));
  } else
    return [];
}
function rb(t, e, n) {
  return sg(t, new Mf(cW(), e, n));
}
function FPe(t, e, n) {
  const r = zt.fromObject(n);
  return sg(t, new wm(cW(), e, r));
}
function YPe(t, e) {
  return sg(t, new tC(cW(), e));
}
function WPe(t, e, n) {
  const r = _W(t, n);
  if (r) {
    const s = mW(r), i = s.path, o = s.queryId, a = ks(i, e), c = new tC(lW(o), a);
    return gW(t, i, c);
  } else
    return [];
}
function hR(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || cee(o, e))) {
    const c = APe(o, e, n, r);
    OPe(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => Ph(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = HPe(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = fee(t, _);
            t.listenProvider_.startListening(jv(m), nC(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(jv(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(IP(d));
        t.listenProvider_.stopListening(jv(d), f);
      }));
    }
    jPe(t, l);
  }
  return a;
}
function uee(t, e, n, r) {
  const s = _W(t, r);
  if (s != null) {
    const i = mW(s), o = i.path, a = i.queryId, c = ks(o, e), l = new Mf(lW(a), c, n);
    return gW(t, o, l);
  } else
    return [];
}
function BPe(t, e, n, r) {
  const s = _W(t, r);
  if (s) {
    const i = mW(s), o = i.path, a = i.queryId, c = ks(o, e), l = zt.fromObject(n), h = new wm(lW(a), c, l);
    return gW(t, o, h);
  } else
    return [];
}
function ML(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = ks(d, s);
    i = i || Ju(f, p), o = o || Ph(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || Ph(a), i = i || Ju(a, ct())) : (a = new see(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = ae.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((d, f) => {
    const p = Ju(f, ct());
    p && (i = i.updateImmediateChild(d, p));
  }));
  const l = cee(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = IP(e);
    N(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = VPe();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = EP(t.pendingWriteTree_, s);
  let u = DPe(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = aee(a, e);
    u = u.concat(zPe(t, e, d));
  }
  return u;
}
function pW(t, e, n) {
  const r = t.pendingWriteTree_, s = t.syncPointTree_.findOnPath(e, (i, o) => {
    const a = ks(i, e), c = Ju(o, a);
    if (c)
      return c;
  });
  return ZX(r, e, s, n, !0);
}
function qPe(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = ks(l, n);
    r = r || Ju(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || Ju(s, ct()) : (s = new see(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new Nh(r, !0, !1) : null, a = EP(t.pendingWriteTree_, e._path), c = iee(s, e, a, i ? o.getNode() : ae.EMPTY_NODE, i);
  return SPe(c);
}
function sg(t, e) {
  return hee(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    EP(t.pendingWriteTree_, ct())
  );
}
function hee(t, e, n, r) {
  if (Ue(t.path))
    return dee(t, e, n, r);
  {
    const s = e.get(ct());
    n == null && s != null && (n = Ju(s, ct()));
    let i = [];
    const o = be(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = XX(r, o);
      i = i.concat(hee(a, c, l, h));
    }
    return s && (i = i.concat(fW(s, t, r, n))), i;
  }
}
function dee(t, e, n, r) {
  const s = e.get(ct());
  n == null && s != null && (n = Ju(s, ct()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = XX(r, o), h = t.operationForChild(o);
    h && (i = i.concat(dee(h, a, c, l)));
  }), s && (i = i.concat(fW(s, t, r, n))), i;
}
function fee(t, e) {
  const n = e.query, r = nC(t, n);
  return {
    hashFn: () => (EPe(e) || ae.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? WPe(t, n._path, r) : YPe(t, n._path);
      {
        const i = Yke(s, n);
        return hR(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function nC(t, e) {
  const n = IP(e);
  return t.queryToTagMap.get(n);
}
function IP(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function _W(t, e) {
  return t.tagToQueryMap.get(e);
}
function mW(t) {
  const e = t.indexOf("$");
  return N(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new At(t.substr(0, e))
  };
}
function gW(t, e, n) {
  const r = t.syncPointTree_.get(e);
  N(r, "Missing sync point for query tag that we're tracking");
  const s = EP(t.pendingWriteTree_, e);
  return fW(r, n, s, null);
}
function HPe(t) {
  return t.fold((e, n, r) => {
    if (n && Ph(n))
      return [SP(n)];
    {
      let s = [];
      return n && (s = oee(n)), ts(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function jv(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (xPe())(t._repo, t._path) : t;
}
function jPe(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = IP(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function VPe() {
  return LPe++;
}
function zPe(t, e, n) {
  const r = e._path, s = nC(t, e), i = fee(t, n), o = t.listenProvider_.startListening(jv(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    N(!Ph(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!Ue(l) && h && Ph(h))
        return [SP(h).query];
      {
        let d = [];
        return h && (d = d.concat(oee(h).map((f) => f.query))), ts(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(jv(h), nC(t, h));
    }
  }
  return o;
}
class yW {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new yW(n);
  }
  node() {
    return this.node_;
  }
}
class vW {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = Rn(this.path_, e);
    return new vW(this.syncTree_, n);
  }
  node() {
    return pW(this.syncTree_, this.path_);
  }
}
const GPe = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, Jz = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (N(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return $Pe(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return KPe(t[".sv"], e);
  N(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, $Pe = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      N(!1, "Unexpected server value: " + t);
  }
}, KPe = function(t, e, n) {
  t.hasOwnProperty("increment") || N(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && N(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (N(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const i = s.getValue();
  return typeof i != "number" ? r : i + r;
}, pee = function(t, e, n, r) {
  return wW(e, new vW(n, t), r);
}, _ee = function(t, e, n) {
  return wW(t, new yW(e), n);
};
function wW(t, e, n) {
  const r = t.getPriority().val(), s = Jz(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = Jz(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new Rr(a, ar(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new Rr(s))), o.forEachChild(Mn, (a, c) => {
      const l = wW(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class CW {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function TW(t, e) {
  let n = e instanceof At ? e : new At(e), r = t, s = be(n);
  for (; s !== null; ) {
    const i = gm(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new CW(s, r, i), n = en(n), s = be(n);
  }
  return r;
}
function ig(t) {
  return t.node.value;
}
function mee(t, e) {
  t.node.value = e, xL(t);
}
function gee(t) {
  return t.node.childCount > 0;
}
function JPe(t) {
  return ig(t) === void 0 && !gee(t);
}
function RP(t, e) {
  ts(t.node.children, (n, r) => {
    e(new CW(n, t, r));
  });
}
function yee(t, e, n, r) {
  n && !r && e(t), RP(t, (s) => {
    yee(s, e, !0, r);
  }), n && r && e(t);
}
function QPe(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function sb(t) {
  return new At(t.parent === null ? t.name : sb(t.parent) + "/" + t.name);
}
function xL(t) {
  t.parent !== null && ZPe(t.parent, t.name, t);
}
function ZPe(t, e, n) {
  const r = JPe(n), s = Va(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, xL(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, xL(t));
}
const XPe = /[\[\].#$\/\u0000-\u001F\u007F]/, eOe = /[\[\].#$\u0000-\u001F\u007F]/, iM = 10 * 1024 * 1024, bW = function(t) {
  return typeof t == "string" && t.length !== 0 && !XPe.test(t);
}, vee = function(t) {
  return typeof t == "string" && t.length !== 0 && !eOe.test(t);
}, tOe = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), vee(t);
}, nOe = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !J2(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Va(t, ".sv");
}, rOe = function(t, e, n, r) {
  kP(wP(t, "value"), e, n);
}, kP = function(t, e, n) {
  const r = n instanceof At ? new vNe(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + Ed(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + Ed(r) + " with contents = " + e.toString());
  if (J2(e))
    throw new Error(t + "contains " + e.toString() + " " + Ed(r));
  if (typeof e == "string" && e.length > iM / 3 && CP(e) > iM)
    throw new Error(t + "contains a string greater than " + iM + " utf8 bytes " + Ed(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (ts(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !bW(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + Ed(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      wNe(r, o), kP(t, a, r), CNe(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + Ed(r) + " in addition to actual children.");
  }
}, sOe = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = Qw(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1) && !bW(i[o]))
        throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
  }
  e.sort(yNe);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && Gi(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, iOe = function(t, e, n, r) {
  const s = wP(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  ts(e, (o, a) => {
    const c = new At(o);
    if (kP(s, a, Rn(n, c)), tW(c) === ".priority" && !nOe(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), sOe(s, i);
}, wee = function(t, e, n, r) {
  if (!vee(n))
    throw new Error(wP(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, oOe = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), wee(t, e, n);
}, aOe = function(t, e) {
  if (be(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, cOe = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !bW(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !tOe(n))
    throw new Error(wP(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class lOe {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function NP(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !nW(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function Cee(t, e, n) {
  NP(t, n), Tee(t, (r) => nW(r, e));
}
function eo(t, e, n) {
  NP(t, n), Tee(t, (r) => Gi(r, e) || Gi(e, r));
}
function Tee(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (uOe(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function uOe(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      Wv && $r("event: " + n.toString()), rg(r);
    }
  }
}
const hOe = "repo_interrupt", dOe = 25;
class fOe {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new lOe(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = rR(), this.transactionQueueTree_ = new CW(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function pOe(t, e, n) {
  if (t.stats_ = X2(t.repoInfo_), t.forceRestClient_ || Hke())
    t.server_ = new nR(t.repoInfo_, (r, s, i, o) => {
      Qz(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => Zz(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        pr(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new Qc(t.repoInfo_, e, (r, s, i, o) => {
      Qz(t, r, s, i, o);
    }, (r) => {
      Zz(t, r);
    }, (r) => {
      mOe(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = $ke(t.repoInfo_, () => new GNe(t.stats_, t.server_)), t.infoData_ = new qNe(), t.infoSyncTree_ = new Kz({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = rb(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), EW(t, "connected", !1), t.serverSyncTree_ = new Kz({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      eo(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function _Oe(t) {
  const e = t.infoData_.getNode(new At(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + e;
}
function PP(t) {
  return GPe({
    timestamp: _Oe(t)
  });
}
function Qz(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new At(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = QI(n, (l) => ar(l));
      o = BPe(t.serverSyncTree_, i, c, s);
    } else {
      const c = ar(n);
      o = uee(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = QI(n, (l) => ar(l));
    o = FPe(t.serverSyncTree_, i, c);
  } else {
    const c = ar(n);
    o = rb(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = Tm(t, i)), eo(t.eventQueue_, a, o);
}
function Zz(t, e) {
  EW(t, "connected", e), e === !1 && wOe(t);
}
function mOe(t, e) {
  ts(e, (n, r) => {
    EW(t, n, r);
  });
}
function EW(t, e, n) {
  const r = new At("/.info/" + e), s = ar(n);
  t.infoData_.updateSnapshot(r, s);
  const i = rb(t.infoSyncTree_, r, s);
  eo(t.eventQueue_, r, i);
}
function SW(t) {
  return t.nextWriteId_++;
}
function gOe(t, e, n) {
  const r = qPe(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = ar(s).withIndex(e._queryParams.getIndex());
    ML(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = rb(t.serverSyncTree_, e._path, i);
    else {
      const a = nC(t.serverSyncTree_, e);
      o = uee(t.serverSyncTree_, e._path, i, a);
    }
    return eo(t.eventQueue_, e._path, o), hR(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (ib(t, "get for query " + pr(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function yOe(t, e, n, r, s) {
  ib(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = PP(t), o = ar(n, r), a = pW(t.serverSyncTree_, e), c = _ee(o, a, i), l = SW(t), h = lee(t.serverSyncTree_, e, c, l, !0);
  NP(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || xs("set at " + e + " failed: " + d);
    const _ = Tu(t.serverSyncTree_, l, !p);
    eo(t.eventQueue_, e, _), LL(t, s, d, f);
  });
  const u = RW(t, e);
  Tm(t, u), eo(t.eventQueue_, u, []);
}
function vOe(t, e, n, r) {
  ib(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = PP(t), o = {};
  if (ts(n, (a, c) => {
    s = !1, o[a] = pee(Rn(e, a), ar(c), t.serverSyncTree_, i);
  }), s)
    $r("update() called with empty data.  Don't do anything."), LL(t, r, "ok", void 0);
  else {
    const a = SW(t), c = UPe(t.serverSyncTree_, e, o, a);
    NP(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || xs("update at " + e + " failed: " + l);
      const d = Tu(t.serverSyncTree_, a, !u), f = d.length > 0 ? Tm(t, e) : e;
      eo(t.eventQueue_, f, d), LL(t, r, l, h);
    }), ts(n, (l) => {
      const h = RW(t, Rn(e, l));
      Tm(t, h);
    }), eo(t.eventQueue_, e, []);
  }
}
function wOe(t) {
  ib(t, "onDisconnectEvents");
  const e = PP(t), n = rR();
  kL(t.onDisconnect_, ct(), (s, i) => {
    const o = pee(s, i, t.serverSyncTree_, e);
    $X(n, s, o);
  });
  let r = [];
  kL(n, ct(), (s, i) => {
    r = r.concat(rb(t.serverSyncTree_, s, i));
    const o = RW(t, s);
    Tm(t, o);
  }), t.onDisconnect_ = rR(), eo(t.eventQueue_, ct(), r);
}
function COe(t, e, n) {
  let r;
  be(e._path) === ".info" ? r = ML(t.infoSyncTree_, e, n) : r = ML(t.serverSyncTree_, e, n), Cee(t.eventQueue_, e._path, r);
}
function Xz(t, e, n) {
  let r;
  be(e._path) === ".info" ? r = hR(t.infoSyncTree_, e, n) : r = hR(t.serverSyncTree_, e, n), Cee(t.eventQueue_, e._path, r);
}
function TOe(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt(hOe);
}
function ib(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), $r(n, ...e);
}
function LL(t, e, n, r) {
  e && rg(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function bee(t, e, n) {
  return pW(t.serverSyncTree_, e, n) || ae.EMPTY_NODE;
}
function IW(t, e = t.transactionQueueTree_) {
  if (e || OP(t, e), ig(e)) {
    const n = See(t, e);
    N(n.length > 0, "Sending zero length transaction queue"), n.every(
      (r) => r.status === 0
      /* TransactionStatus.RUN */
    ) && bOe(t, sb(e), n);
  } else gee(e) && RP(e, (n) => {
    IW(t, n);
  });
}
function bOe(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = bee(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    N(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = ks(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    ib(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(Tu(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      OP(t, TW(t.transactionQueueTree_, e)), IW(t, t.transactionQueueTree_), eo(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        rg(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        xs("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      Tm(t, e);
    }
  }, o);
}
function Tm(t, e) {
  const n = Eee(t, e), r = sb(n), s = See(t, n);
  return EOe(t, s, r), r;
}
function EOe(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const i = e.filter((o) => o.status === 0).map((o) => o.currentWriteId);
  for (let o = 0; o < e.length; o++) {
    const a = e[o], c = ks(n, a.path);
    let l = !1, h;
    if (N(c !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), a.status === 4)
      l = !0, h = a.abortReason, s = s.concat(Tu(t.serverSyncTree_, a.currentWriteId, !0));
    else if (a.status === 0)
      if (a.retryCount >= dOe)
        l = !0, h = "maxretry", s = s.concat(Tu(t.serverSyncTree_, a.currentWriteId, !0));
      else {
        const u = bee(t, a.path, i);
        a.currentInputSnapshot = u;
        const d = e[o].update(u.val());
        if (d !== void 0) {
          kP("transaction failed: Data returned ", d, a.path);
          let f = ar(d);
          typeof d == "object" && d != null && Va(d, ".priority") || (f = f.updatePriority(u.getPriority()));
          const p = a.currentWriteId, _ = PP(t), m = _ee(f, u, _);
          a.currentOutputSnapshotRaw = f, a.currentOutputSnapshotResolved = m, a.currentWriteId = SW(t), i.splice(i.indexOf(p), 1), s = s.concat(lee(t.serverSyncTree_, a.path, m, a.currentWriteId, a.applyLocally)), s = s.concat(Tu(t.serverSyncTree_, p, !0));
        } else
          l = !0, h = "nodata", s = s.concat(Tu(t.serverSyncTree_, a.currentWriteId, !0));
      }
    eo(t.eventQueue_, n, s), s = [], l && (e[o].status = 2, function(u) {
      setTimeout(u, Math.floor(0));
    }(e[o].unwatcher), e[o].onComplete && (h === "nodata" ? r.push(() => e[o].onComplete(null, !1, e[o].currentInputSnapshot)) : r.push(() => e[o].onComplete(new Error(h), !1, null))));
  }
  OP(t, t.transactionQueueTree_);
  for (let o = 0; o < r.length; o++)
    rg(r[o]);
  IW(t, t.transactionQueueTree_);
}
function Eee(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = be(e); n !== null && ig(r) === void 0; )
    r = TW(r, n), e = en(e), n = be(e);
  return r;
}
function See(t, e) {
  const n = [];
  return Iee(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function Iee(t, e, n) {
  const r = ig(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  RP(e, (s) => {
    Iee(t, s, n);
  });
}
function OP(t, e) {
  const n = ig(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, mee(e, n.length > 0 ? n : void 0);
  }
  RP(e, (r) => {
    OP(t, r);
  });
}
function RW(t, e) {
  const n = sb(Eee(t, e)), r = TW(t.transactionQueueTree_, e);
  return QPe(r, (s) => {
    oM(t, s);
  }), oM(t, r), yee(r, (s) => {
    oM(t, s);
  }), n;
}
function oM(t, e) {
  const n = ig(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (N(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (N(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(Tu(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? mee(e, void 0) : n.length = i + 1, eo(t.eventQueue_, sb(e), s);
    for (let o = 0; o < r.length; o++)
      rg(r[o]);
  }
}
function SOe(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function IOe(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : xs(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const eG = function(t, e) {
  const n = ROe(t), r = n.namespace;
  n.domain === "firebase.com" && yl(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && yl("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || Mke();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new OX(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new At(n.pathString)
  };
}, ROe = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = SOe(t.substring(h, u)));
    const d = IOe(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class kOe {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + pr(this.snapshot.exportVal());
  }
}
class NOe {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class Ree {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return N(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class kW {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return Ue(this._path) ? null : tW(this._path);
  }
  get ref() {
    return new Ml(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = Yz(this._queryParams), n = Q2(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return Yz(this._queryParams);
  }
  isEqual(e) {
    if (e = cs(e), !(e instanceof kW))
      return !1;
    const n = this._repo === e._repo, r = nW(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + gNe(this._path);
  }
}
class Ml extends kW {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new oW(), !1);
  }
  get parent() {
    const e = WX(this._path);
    return e === null ? null : new Ml(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class rC {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new At(e), r = UL(this.ref, e);
    return new rC(this._node.getChild(n), r, Mn);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (n, r) => e(new rC(r, UL(this.ref, n), Mn)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new At(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function FE(t, e) {
  return t = cs(t), t._checkNotDeleted("ref"), e !== void 0 ? UL(t._root, e) : t._root;
}
function UL(t, e) {
  return t = cs(t), be(t._path) === null ? oOe("child", "path", e) : wee("child", "path", e), new Ml(t._repo, Rn(t._path, e));
}
function POe(t, e) {
  t = cs(t), aOe("set", t._path), rOe("set", e, t._path);
  const n = new QT();
  return yOe(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function OOe(t, e) {
  iOe("update", e, t._path);
  const n = new QT();
  return vOe(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function DOe(t) {
  t = cs(t);
  const e = new Ree(() => {
  }), n = new DP(e);
  return gOe(t._repo, t, n).then((r) => new rC(r, new Ml(t._repo, t._path), t._queryParams.getIndex()));
}
class DP {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new kOe("value", this, new rC(e.snapshotNode, new Ml(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new NOe(this, e, n) : null;
  }
  matches(e) {
    return e instanceof DP ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function AOe(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      Xz(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new Ree(n, i || void 0), a = new DP(o);
  return COe(t._repo, t, a), () => Xz(t._repo, t, a);
}
function MOe(t, e, n, r) {
  return AOe(t, "value", e, n, r);
}
function xOe(t, ...e) {
  let n = cs(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
NPe(Ml);
MPe(Ml);
const LOe = "FIREBASE_DATABASE_EMULATOR_HOST", FL = {};
let UOe = !1;
function FOe(t, e, n, r) {
  t.repoInfo_ = new OX(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function YOe(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || yl("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), $r("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = eG(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[LOe]), c ? (i = `http://${c}?ns=${a.namespace}`, o = eG(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new Vke(t.name, t.options, e);
  cOe("Invalid Firebase Database URL", o), Ue(o.path) || yl("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = BOe(a, t, l, new jke(t.name, n));
  return new qOe(h, t);
}
function WOe(t, e) {
  const n = FL[e];
  (!n || n[t.key] !== t) && yl(`Database ${e}(${t.repoInfo_}) has already been deleted.`), TOe(t), delete n[t.key];
}
function BOe(t, e, n, r) {
  let s = FL[e.name];
  s || (s = {}, FL[e.name] = s);
  let i = s[t.toURLString()];
  return i && yl("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new fOe(t, UOe, n, r), s[t.toURLString()] = i, i;
}
class qOe {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (pOe(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Ml(this._repo, ct())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (WOe(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && yl("Cannot call " + e + " on a deleted database.");
  }
}
function HOe(t = pX(), e) {
  const n = fX(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = oX("database");
    r && jOe(n, ...r);
  }
  return n;
}
function jOe(t, e, n, r = {}) {
  t = cs(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && yl("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && yl('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new qS(qS.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : aX(r.mockUserToken, t.app.options.projectId);
    i = new qS(o);
  }
  FOe(s, e, n, i);
}
function VOe(t) {
  kke(XT), Df(new Rh(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return YOe(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), Jc(Tz, bz, t), Jc(Tz, bz, "esm2017");
}
Qc.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
Qc.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
VOe();
const kee = "firebasestorage.googleapis.com", Nee = "storageBucket", zOe = 2 * 60 * 1e3, GOe = 10 * 60 * 1e3;
class $n extends Al {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(aM(e), `Firebase Storage: ${n} (${aM(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, $n.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return aM(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var Bn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(Bn || (Bn = {}));
function aM(t) {
  return "storage/" + t;
}
function NW() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new $n(Bn.UNKNOWN, t);
}
function $Oe(t) {
  return new $n(Bn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function KOe(t) {
  return new $n(Bn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function JOe() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new $n(Bn.UNAUTHENTICATED, t);
}
function QOe() {
  return new $n(Bn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function ZOe(t) {
  return new $n(Bn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function XOe() {
  return new $n(Bn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function eDe() {
  return new $n(Bn.CANCELED, "User canceled the upload/download.");
}
function tDe(t) {
  return new $n(Bn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function nDe(t) {
  return new $n(Bn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function rDe() {
  return new $n(Bn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + Nee + "' property when initializing the app?");
}
function sDe() {
  return new $n(Bn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function iDe() {
  return new $n(Bn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function oDe(t) {
  return new $n(Bn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function YL(t) {
  return new $n(Bn.INVALID_ARGUMENT, t);
}
function Pee() {
  return new $n(Bn.APP_DELETED, "The Firebase app was deleted.");
}
function aDe(t) {
  return new $n(Bn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function Vv(t, e) {
  return new $n(Bn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function gy(t) {
  throw new $n(Bn.INTERNAL_ERROR, "Internal error: " + t);
}
class Ti {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = Ti.makeFromUrl(e, n);
    } catch {
      return new Ti(e, "");
    }
    if (r.path === "")
      return r;
    throw nDe(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(g) {
      g.path.charAt(g.path.length - 1) === "/" && (g.path_ = g.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(g) {
      g.path_ = decodeURIComponent(g.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === kee ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), y = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let g = 0; g < y.length; g++) {
      const w = y[g], de = w.regex.exec(e);
      if (de) {
        const we = de[w.indices.bucket];
        let fe = de[w.indices.path];
        fe || (fe = ""), r = new Ti(we, fe), w.postModify(r);
        break;
      }
    }
    if (r == null)
      throw tDe(e);
    return r;
  }
}
class cDe {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function lDe(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let y;
    a === 1 ? (a = 2, y = 0) : y = (r + Math.random()) * 1e3, u(y);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function uDe(t) {
  t(!1);
}
function hDe(t) {
  return t !== void 0;
}
function dDe(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function PW(t) {
  return typeof t == "string" || t instanceof String;
}
function tG(t) {
  return OW() && t instanceof Blob;
}
function OW() {
  return typeof Blob < "u" && !cRe();
}
function nG(t, e, n, r) {
  if (r < e)
    throw YL(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw YL(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function AP(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function Oee(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var ef;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(ef || (ef = {}));
function fDe(t, e) {
  const n = t >= 500 && t < 600, r = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, s = e.indexOf(t) !== -1;
  return n || r || s;
}
class pDe {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new YE(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === ef.NO_ERROR, c = i.getStatus();
        if (!a || fDe(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === ef.ABORT;
          r(!1, new YE(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new YE(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          hDe(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = NW();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? Pee() : eDe();
        o(c);
      } else {
        const c = XOe();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new YE(!1, null, !0)) : this.backoffId_ = lDe(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && uDe(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class YE {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function _De(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function mDe(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function gDe(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function yDe(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function vDe(t, e, n, r, s, i, o = !0) {
  const a = Oee(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return gDe(l, e), _De(l, n), mDe(l, i), yDe(l, r), new pDe(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function wDe() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function CDe(...t) {
  const e = wDe();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (OW())
      return new Blob(t);
    throw new $n(Bn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function TDe(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function bDe(t) {
  if (typeof atob > "u")
    throw oDe("base-64");
  return atob(t);
}
const Ia = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class cM {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function EDe(t, e) {
  switch (t) {
    case Ia.RAW:
      return new cM(Dee(e));
    case Ia.BASE64:
    case Ia.BASE64URL:
      return new cM(Aee(t, e));
    case Ia.DATA_URL:
      return new cM(IDe(e), RDe(e));
  }
  throw NW();
}
function Dee(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const s = r, i = t.charCodeAt(++n);
        r = 65536 | (s & 1023) << 10 | i & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function SDe(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw Vv(Ia.DATA_URL, "Malformed data URL.");
  }
  return Dee(e);
}
function Aee(t, e) {
  switch (t) {
    case Ia.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw Vv(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case Ia.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw Vv(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = bDe(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : Vv(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class Mee {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw Vv(Ia.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = kDe(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function IDe(t) {
  const e = new Mee(t);
  return e.base64 ? Aee(Ia.BASE64, e.rest) : SDe(e.rest);
}
function RDe(t) {
  return new Mee(t).contentType;
}
function kDe(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class _u {
  constructor(e, n) {
    let r = 0, s = "";
    tG(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (tG(this.data_)) {
      const r = this.data_, s = TDe(r, e, n);
      return s === null ? null : new _u(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new _u(r, !0);
    }
  }
  static getBlob(...e) {
    if (OW()) {
      const n = e.map((r) => r instanceof _u ? r.data_ : r);
      return new _u(CDe.apply(null, n));
    } else {
      const n = e.map((o) => PW(o) ? EDe(Ia.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new _u(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function xee(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return dDe(e) ? e : null;
}
function NDe(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function PDe(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function Lee(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function ODe(t, e) {
  return e;
}
class Cs {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || ODe;
  }
}
let WE = null;
function DDe(t) {
  return !PW(t) || t.length < 2 ? t : Lee(t);
}
function Uee() {
  if (WE)
    return WE;
  const t = [];
  t.push(new Cs("bucket")), t.push(new Cs("generation")), t.push(new Cs("metageneration")), t.push(new Cs("name", "fullPath", !0));
  function e(i, o) {
    return DDe(o);
  }
  const n = new Cs("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new Cs("size");
  return s.xform = r, t.push(s), t.push(new Cs("timeCreated")), t.push(new Cs("updated")), t.push(new Cs("md5Hash", null, !0)), t.push(new Cs("cacheControl", null, !0)), t.push(new Cs("contentDisposition", null, !0)), t.push(new Cs("contentEncoding", null, !0)), t.push(new Cs("contentLanguage", null, !0)), t.push(new Cs("contentType", null, !0)), t.push(new Cs("metadata", "customMetadata", !0)), WE = t, WE;
}
function ADe(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new Ti(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function MDe(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return ADe(r, t), r;
}
function Fee(t, e, n) {
  const r = xee(e);
  return r === null ? null : MDe(t, r, n);
}
function xDe(t, e, n, r) {
  const s = xee(e);
  if (s === null || !PW(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((a) => {
    const c = t.bucket, l = t.fullPath, h = "/b/" + o(c) + "/o/" + o(l), u = AP(h, n, r), d = Oee({
      alt: "media",
      token: a
    });
    return u + d;
  })[0];
}
function LDe(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class DW {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function Yee(t) {
  if (!t)
    throw NW();
}
function UDe(t, e) {
  function n(r, s) {
    const i = Fee(t, s, e);
    return Yee(i !== null), i;
  }
  return n;
}
function FDe(t, e) {
  function n(r, s) {
    const i = Fee(t, s, e);
    return Yee(i !== null), xDe(i, s, t.host, t._protocol);
  }
  return n;
}
function Wee(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? (
      /* This exact message string is the only consistent part of the */
      /* server's error response that identifies it as an App Check error. */
      n.getErrorText().includes("Firebase App Check token is invalid") ? s = QOe() : s = JOe()
    ) : n.getStatus() === 402 ? s = KOe(t.bucket) : n.getStatus() === 403 ? s = ZOe(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function Bee(t) {
  const e = Wee(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = $Oe(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function YDe(t, e, n) {
  const r = e.fullServerUrl(), s = AP(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new DW(s, i, FDe(t, n), o);
  return a.errorHandler = Bee(e), a;
}
function WDe(t, e) {
  const n = e.fullServerUrl(), r = AP(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new DW(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = Bee(e), a;
}
function BDe(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function qDe(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = BDe(null, e)), r;
}
function HDe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = qDe(e, r, s), h = LDe(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = _u.getBlob(u, r, d);
  if (f === null)
    throw sDe();
  const p = { name: l.fullPath }, _ = AP(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new DW(_, m, UDe(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = Wee(e), y;
}
class jDe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = ef.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = ef.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = ef.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw gy("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw gy("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw gy("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw gy("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw gy("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class VDe extends jDe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function AW() {
  return new VDe();
}
class Lf {
  constructor(e, n) {
    this._service = e, n instanceof Ti ? this._location = n : this._location = Ti.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new Lf(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new Ti(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return Lee(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = NDe(this._location.path);
    if (e === null)
      return null;
    const n = new Ti(this._location.bucket, e);
    return new Lf(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw aDe(e);
  }
}
function zDe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = HDe(t.storage, t._location, Uee(), new _u(e, !0), n);
  return t.storage.makeRequestWithTokens(r, AW).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function GDe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = YDe(t.storage, t._location, Uee());
  return t.storage.makeRequestWithTokens(e, AW).then((n) => {
    if (n === null)
      throw iDe();
    return n;
  });
}
function $De(t) {
  t._throwIfRoot("deleteObject");
  const e = WDe(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, AW);
}
function KDe(t, e) {
  const n = PDe(t._location.path, e), r = new Ti(t._location.bucket, n);
  return new Lf(t.storage, r);
}
function JDe(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function QDe(t, e) {
  return new Lf(t, e);
}
function qee(t, e) {
  if (t instanceof MW) {
    const n = t;
    if (n._bucket == null)
      throw rDe();
    const r = new Lf(n, n._bucket);
    return e != null ? qee(r, e) : r;
  } else
    return e !== void 0 ? KDe(t, e) : t;
}
function ZDe(t, e) {
  if (e && JDe(e)) {
    if (t instanceof MW)
      return QDe(t, e);
    throw YL("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return qee(t, e);
}
function rG(t, e) {
  const n = e == null ? void 0 : e[Nee];
  return n == null ? null : Ti.makeFromBucketSpec(n, t);
}
function XDe(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : aX(s, t.app.options.projectId));
}
class MW {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = kee, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = zOe, this._maxUploadRetryTime = GOe, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = Ti.makeFromBucketSpec(s, this._host) : this._bucket = rG(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = Ti.makeFromBucketSpec(this._url, e) : this._bucket = rG(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    nG(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    nG(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new Lf(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new cDe(Pee());
    {
      const o = vDe(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const sG = "@firebase/storage", iG = "0.11.2", Hee = "storage";
function eAe(t, e, n) {
  return t = cs(t), zDe(t, e, n);
}
function tAe(t) {
  return t = cs(t), GDe(t);
}
function nAe(t) {
  return t = cs(t), $De(t);
}
function lM(t, e) {
  return t = cs(t), ZDe(t, e);
}
function rAe(t = pX(), e) {
  t = cs(t);
  const n = fX(t, Hee).getImmediate({
    identifier: e
  }), r = oX("storage");
  return r && sAe(n, ...r), n;
}
function sAe(t, e, n, r = {}) {
  XDe(t, e, n, r);
}
function iAe(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new MW(n, r, s, e, XT);
}
function oAe() {
  Df(new Rh(
    Hee,
    iAe,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), Jc(sG, iG, ""), Jc(sG, iG, "esm2017");
}
oAe();
class jee {
  constructor() {
    this.db = HOe(), this.storage = rAe();
  }
  async get(e) {
    let n = await DOe(FE(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await OOe(FE(this.db, e), n);
  }
  async set(e, n) {
    return await POe(FE(this.db, e), n);
  }
  async watch(e, n, r) {
    return MOe(xOe(FE(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await eAe(lM(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await nAe(lM(this.storage, e));
  }
  async objectUrl(e) {
    return await tAe(lM(this.storage, e));
  }
}
class aAe extends jee {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch(zp.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set(zp.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set(zp.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get(zp.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get(zp.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
const cAe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, lAe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, uAe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, hAe = {
  localhost: cAe,
  hybrid: lAe,
  ihunterdev: uAe,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, dAe = !1;
class b_ {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return hAe[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && dAe ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  static firebaseConfig() {
    var e;
    if (!process)
      return process && ((e = process == null ? void 0 : process.env) == null ? void 0 : e.FIREBASE_CONFIG) && JSON.parse(process.env.FIREBASE_CONFIG);
  }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class BE extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const mi = "application/json", fAe = "application/octet-stream";
class Vee {
  constructor() {
  }
  get apiServer() {
    return b_.config.functionURL;
  }
  get tileServer() {
    return b_.config.tileURL;
  }
  get webServer() {
    return b_.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let s = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || mi,
        Accept: r || mi
      }
    };
    return this.request(e, s);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: mi
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let i = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || mi,
        Accept: s || mi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async patch(e, n, r, s) {
    let i = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || mi,
        Accept: s || mi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async post(e, n, r, s) {
    let i = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || mi,
        Accept: s || mi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async delete(e, n, r) {
    let s = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || mi,
        Accept: r || mi
      }
    };
    return this.request(e, s);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new BE(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(mi)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new BE(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(mi) ? r(await i.json()) : o && o.startsWith(fAe) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new BE(500, i.message, i))) : s(new BE(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class pAe {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var HS = { exports: {} };
function _Ae(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
HS.exports = _Ae;
const $ = (HS.exports == null ? {} : HS.exports).default || HS.exports, ru = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, oG = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class xi {
  static TileServer() {
    return b_.config.tileURL;
  }
  static WebServer() {
    return b_.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return ru.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return $(ru.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return $(ru.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return $(ru.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + $(oG.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), $(ru.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), $(ru.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + $(oG.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return $(ru.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return $(ru.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const zs = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, uM = {
  REGIONS: "/product/regions/{region}"
}, Gs = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, aG = {
  PRODUCT: "/product/skus/{product}"
}, cG = {
  PRODUCT: "/product/prices/{product}"
}, mAe = {
  PROMOTIONS: "/store/promotions"
};
class gAe {
  static AllRegions() {
    return hM($(uM.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return uM.REGIONS;
  }
  static Regions(e) {
    return $(uM.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Gs.REGION;
  }
  static Region(e) {
    return $(Gs.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return zs.REGION;
  }
  static RegionMetadata(e) {
    return $(zs.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Gs.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return $(Gs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return zs.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return $(zs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Gs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return $(Gs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return zs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return $(zs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Gs.RESOURCE;
  }
  static Resource(e, n) {
    return $(Gs.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return zs.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return $(zs.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Gs.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return $(Gs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return zs.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return $(zs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Gs.MAP;
  }
  static Map(e, n, r, s) {
    return $(Gs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return zs.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return $(zs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Gs.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return $(Gs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return zs.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return $(zs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return hM($(aG.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return $(aG.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return hM($(cG.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return $(cG.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return mAe.PROMOTIONS;
  }
}
const hM = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class yAe {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const lG = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, uG = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, hG = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, dG = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, dM = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, fG = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, vAe = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
class pa {
  static RegionPropertyMap() {
    return lG.METADATA;
  }
  static SubscriptionPropertyMap() {
    return uG.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return hG.METADATA;
  }
  static ResourcePropertyMap() {
    return dG.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return dM.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return dM.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return fG.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties(lG.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(uG.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(hG.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(dG.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(dM.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(fG.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(vAe.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
}
var jS = { exports: {} };
function wAe(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
jS.exports = wAe;
const VS = (jS.exports == null ? {} : jS.exports).default || jS.exports;
var zS = { exports: {} };
async function CAe(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
zS.exports = CAe;
const WL = (zS.exports == null ? {} : zS.exports).default || zS.exports;
Promise.allMap = WL;
class TAe {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, pa.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, pa.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, pa.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, pa.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, pa.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[pa.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, pa.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = VS(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = VS(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[pa.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, pa.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = VS(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class bAe extends yAe {
  constructor(e) {
    super(e), this.http = new Vee(), this.builder = new TAe(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(xi.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(xi.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(xi.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(xi.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(xi.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(xi.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(xi.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + xi.CountyMetadata(e)), await this.http.getJson(xi.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + xi.CountyProductMetadata(e, n)), await this.http.getJson(xi.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const yy = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class EAe {
  static Apple(e) {
    return $(yy.APPLE, { uid: e });
  }
  static Android(e) {
    return $(yy.ANDROID, { uid: e });
  }
  static Web(e) {
    return $(yy.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return $(yy.WEB, { uid: e }) + $(yy.SKU, { region: n, sku: r });
  }
}
const qr = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, vy = {
  CUSTOMER: `/${qr.USERS}/{uid}/${qr.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${qr.USERS}/{uid}/${qr.SUBSCRIPTIONS}`,
  PRODUCT: `/${qr.PRODUCTS}/{product}`,
  PRICE: `/${qr.PRODUCTS}/{product}/${qr.PRICES}/{price}`
};
class SAe {
  static Customer(e, n) {
    return $(vy.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return $(vy.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return vy.CUSTOMER;
  }
  static PriceRoute() {
    return vy.PRICE;
  }
  static ProductRoute() {
    return vy.PRODUCT;
  }
  static Users() {
    return qr.USERS;
  }
  static Customers() {
    return qr.CUSTOMERS;
  }
  static Subscriptions() {
    return qr.SUBSCRIPTIONS;
  }
  static Schedules() {
    return qr.SCHEDULES;
  }
  static Products() {
    return qr.PRODUCTS;
  }
  static Prices() {
    return qr.PRICES;
  }
  static Promotions() {
    return qr.PROMOTIONS;
  }
  static Payments() {
    return qr.PAYMENTS;
  }
  static Coupons() {
    return qr.COUPONS;
  }
  static Invoices() {
    return qr.INVOICES;
  }
}
const td = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class Gp {
  static Preferences(e) {
    return $(td.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return $(td.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return td.OPT_IN;
  }
  static Status(e) {
    return $(td.STATUS, { uid: e });
  }
  static StatusRoute() {
    return td.STATUS;
  }
  static Email(e) {
    return $(td.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return td.EMAIL;
  }
}
const pG = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class IAe {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, pG.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * pG.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const nd = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, fM = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, _G = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, pM = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, mG = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, wy = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, rd = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, gG = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class xW {
  static OutgoingContactRequestProcessedRoute() {
    return nd.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return $(nd.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return $(nd.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return nd.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return $(nd.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return nd.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return $(nd.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return fM.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return $(fM.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return $(fM.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return $(_G.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return _G.DATA;
  }
  static Chat(e) {
    return $(pM.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var r, s;
    const i = Date.now();
    let o = e.length > 2, a = {}, c = {}, l = {};
    for (let u of e)
      a[u] = !0, c[u] = ((r = n == null ? void 0 : n.lastviewed) == null ? void 0 : r[u]) || i, l[u] = ((s = n == null ? void 0 : n.lastcleared) == null ? void 0 : s[u]) || i;
    let h = {
      participants: a,
      isgroupchat: o,
      lastviewed: c,
      lastcleared: l
    };
    return o && (h.chatname = pM.GROUP_NAME), h;
  }
  static ChatParticipants(e) {
    return $(pM.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return $(mG.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return mG.DATA;
  }
  static MessageRoute() {
    return wy.ROUTE;
  }
  static Message(e, n) {
    const r = xW.MessageKey(n);
    return $(wy.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: rd.NOTIFY_ALL,
      type: wy.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return $(wy.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return wy.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return $(rd.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return $(rd.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === rd.NOTIFY_ALL ? rd.TITLE_FROM_NOTIFY_ALL : $(rd.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return $(gG.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return rd.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return gG.UNKNOWN_NAME;
  }
}
const zee = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
}, P = function(t, e) {
  if (!t)
    throw og(e);
}, og = function(t) {
  return new Error("Firebase Database (" + zee.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
}, Gee = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, RAe = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, LW = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(Gee(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : RAe(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], o = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const a = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const c = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || o == null || a == null || c == null)
        throw new kAe();
      const l = i << 2 | o >> 4;
      if (r.push(l), a !== 64) {
        const h = o << 4 & 240 | a >> 2;
        if (r.push(h), c !== 64) {
          const u = a << 6 & 192 | c;
          r.push(u);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class kAe extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const $ee = function(t) {
  const e = Gee(t);
  return LW.encodeByteArray(e, !0);
}, dR = function(t) {
  return $ee(t).replace(/\./g, "");
}, fR = function(t) {
  try {
    return LW.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function NAe(t) {
  return Kee(void 0, t);
}
function Kee(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !PAe(n) || (t[n] = Kee(t[n], e[n]));
  return t;
}
function PAe(t) {
  return t !== "__proto__";
}
function OAe() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const DAe = () => OAe().__FIREBASE_DEFAULTS__, AAe = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, MAe = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && fR(t[1]);
  return e && JSON.parse(e);
}, MP = () => {
  try {
    return DAe() || AAe() || MAe();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, xAe = (t) => {
  var e, n;
  return (n = (e = MP()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, Jee = (t) => {
  const e = xAe(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, LAe = () => {
  var t;
  return (t = MP()) === null || t === void 0 ? void 0 : t.config;
}, UAe = (t) => {
  var e;
  return (e = MP()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
class ob {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function Qee(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    dR(JSON.stringify(n)),
    dR(JSON.stringify(o)),
    ""
  ].join(".");
}
function Ma() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function UW() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(Ma());
}
function FAe() {
  var t;
  const e = (t = MP()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function YAe() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function Zee() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function Xee() {
  return zee.NODE_ADMIN === !0;
}
function WAe() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function BAe() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const qAe = "FirebaseError";
class xl extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = qAe, Object.setPrototypeOf(this, xl.prototype), Error.captureStackTrace && Error.captureStackTrace(this, ab.prototype.create);
  }
}
class ab {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? HAe(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new xl(s, a, r);
  }
}
function HAe(t, e) {
  return t.replace(jAe, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const jAe = /\{\$([^}]+)}/g;
function sC(t) {
  return JSON.parse(t);
}
function _r(t) {
  return JSON.stringify(t);
}
const ete = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = sC(fR(i[0]) || ""), n = sC(fR(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, VAe = function(t) {
  const e = ete(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, zAe = function(t) {
  const e = ete(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function za(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function bm(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function yG(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function pR(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function BL(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (vG(i) && vG(o)) {
      if (!BL(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function vG(t) {
  return t !== null && typeof t == "object";
}
function FW(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function wv(t) {
  const e = {};
  return t.replace(/^\?/, "").split("&").forEach((n) => {
    if (n) {
      const [r, s] = n.split("=");
      e[decodeURIComponent(r)] = decodeURIComponent(s);
    }
  }), e;
}
function Cv(t) {
  const e = t.indexOf("?");
  if (!e)
    return "";
  const n = t.indexOf("#", e);
  return t.substring(e, n > 0 ? n : void 0);
}
class GAe {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function $Ae(t, e) {
  const n = new KAe(t, e);
  return n.subscribe.bind(n);
}
class KAe {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    JAe(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = _M), s.error === void 0 && (s.error = _M), s.complete === void 0 && (s.complete = _M);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function JAe(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function _M() {
}
function xP(t, e) {
  return `${t} failed: ${e} argument `;
}
const QAe = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, P(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, LP = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function ls(t) {
  return t && t._delegate ? t._delegate : t;
}
class Oh {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const Sd = "[DEFAULT]";
class ZAe {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new ob();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (eMe(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: Sd });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = Sd) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = Sd) {
    return this.instances.has(e);
  }
  getOptions(e = Sd) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: XAe(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = Sd) {
    return this.component ? this.component.multipleInstances ? e : Sd : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function XAe(t) {
  return t === Sd ? void 0 : t;
}
function eMe(t) {
  return t.instantiationMode === "EAGER";
}
class tMe {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new ZAe(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var kt;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(kt || (kt = {}));
const nMe = {
  debug: kt.DEBUG,
  verbose: kt.VERBOSE,
  info: kt.INFO,
  warn: kt.WARN,
  error: kt.ERROR,
  silent: kt.SILENT
}, rMe = kt.INFO, sMe = {
  [kt.DEBUG]: "log",
  [kt.VERBOSE]: "log",
  [kt.INFO]: "info",
  [kt.WARN]: "warn",
  [kt.ERROR]: "error"
}, iMe = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = sMe[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class YW {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = rMe, this._logHandler = iMe, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in kt))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? nMe[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, kt.DEBUG, ...e), this._logHandler(this, kt.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, kt.VERBOSE, ...e), this._logHandler(this, kt.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, kt.INFO, ...e), this._logHandler(this, kt.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, kt.WARN, ...e), this._logHandler(this, kt.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, kt.ERROR, ...e), this._logHandler(this, kt.ERROR, ...e);
  }
}
const oMe = (t, e) => e.some((n) => t instanceof n);
let wG, CG;
function aMe() {
  return wG || (wG = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function cMe() {
  return CG || (CG = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const tte = /* @__PURE__ */ new WeakMap(), qL = /* @__PURE__ */ new WeakMap(), nte = /* @__PURE__ */ new WeakMap(), mM = /* @__PURE__ */ new WeakMap(), WW = /* @__PURE__ */ new WeakMap();
function lMe(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(Qu(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && tte.set(n, t);
  }).catch(() => {
  }), WW.set(e, t), e;
}
function uMe(t) {
  if (qL.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  qL.set(t, e);
}
let HL = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return qL.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || nte.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return Qu(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function hMe(t) {
  HL = t(HL);
}
function dMe(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(gM(this), e, ...n);
    return nte.set(r, e.sort ? e.sort() : [e]), Qu(r);
  } : cMe().includes(t) ? function(...e) {
    return t.apply(gM(this), e), Qu(tte.get(this));
  } : function(...e) {
    return Qu(t.apply(gM(this), e));
  };
}
function fMe(t) {
  return typeof t == "function" ? dMe(t) : (t instanceof IDBTransaction && uMe(t), oMe(t, aMe()) ? new Proxy(t, HL) : t);
}
function Qu(t) {
  if (t instanceof IDBRequest)
    return lMe(t);
  if (mM.has(t))
    return mM.get(t);
  const e = fMe(t);
  return e !== t && (mM.set(t, e), WW.set(e, t)), e;
}
const gM = (t) => WW.get(t);
function pMe(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = Qu(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(Qu(o.result), c.oldVersion, c.newVersion, Qu(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const _Me = ["get", "getKey", "getAll", "getAllKeys", "count"], mMe = ["put", "add", "delete", "clear"], yM = /* @__PURE__ */ new Map();
function TG(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (yM.get(e))
    return yM.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = mMe.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || _Me.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return yM.set(e, i), i;
}
hMe((t) => ({
  ...t,
  get: (e, n, r) => TG(e, n) || t.get(e, n, r),
  has: (e, n) => !!TG(e, n) || t.has(e, n)
}));
class gMe {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((e) => {
      if (yMe(e)) {
        const n = e.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((e) => e).join(" ");
  }
}
function yMe(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const jL = "@firebase/app", bG = "0.9.8", Uf = new YW("@firebase/app"), vMe = "@firebase/app-compat", wMe = "@firebase/analytics-compat", CMe = "@firebase/analytics", TMe = "@firebase/app-check-compat", bMe = "@firebase/app-check", EMe = "@firebase/auth", SMe = "@firebase/auth-compat", IMe = "@firebase/database", RMe = "@firebase/database-compat", kMe = "@firebase/functions", NMe = "@firebase/functions-compat", PMe = "@firebase/installations", OMe = "@firebase/installations-compat", DMe = "@firebase/messaging", AMe = "@firebase/messaging-compat", MMe = "@firebase/performance", xMe = "@firebase/performance-compat", LMe = "@firebase/remote-config", UMe = "@firebase/remote-config-compat", FMe = "@firebase/storage", YMe = "@firebase/storage-compat", WMe = "@firebase/firestore", BMe = "@firebase/firestore-compat", qMe = "firebase", HMe = "9.20.0", VL = "[DEFAULT]", jMe = {
  [jL]: "fire-core",
  [vMe]: "fire-core-compat",
  [CMe]: "fire-analytics",
  [wMe]: "fire-analytics-compat",
  [bMe]: "fire-app-check",
  [TMe]: "fire-app-check-compat",
  [EMe]: "fire-auth",
  [SMe]: "fire-auth-compat",
  [IMe]: "fire-rtdb",
  [RMe]: "fire-rtdb-compat",
  [kMe]: "fire-fn",
  [NMe]: "fire-fn-compat",
  [PMe]: "fire-iid",
  [OMe]: "fire-iid-compat",
  [DMe]: "fire-fcm",
  [AMe]: "fire-fcm-compat",
  [MMe]: "fire-perf",
  [xMe]: "fire-perf-compat",
  [LMe]: "fire-rc",
  [UMe]: "fire-rc-compat",
  [FMe]: "fire-gcs",
  [YMe]: "fire-gcs-compat",
  [WMe]: "fire-fst",
  [BMe]: "fire-fst-compat",
  "fire-js": "fire-js",
  [qMe]: "fire-js-all"
}, _R = /* @__PURE__ */ new Map(), zL = /* @__PURE__ */ new Map();
function VMe(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    Uf.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function Ff(t) {
  const e = t.name;
  if (zL.has(e))
    return Uf.debug(`There were multiple attempts to register component ${e}.`), !1;
  zL.set(e, t);
  for (const n of _R.values())
    VMe(n, t);
  return !0;
}
function rte(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const zMe = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, Zu = new ab("app", "Firebase", zMe);
class GMe {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new Oh(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw Zu.create("app-deleted", { appName: this._name });
  }
}
const cb = HMe;
function $Me(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: VL, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw Zu.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = LAe()), !n)
    throw Zu.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = _R.get(s);
  if (i) {
    if (BL(n, i.options) && BL(r, i.config))
      return i;
    throw Zu.create("duplicate-app", { appName: s });
  }
  const o = new tMe(s);
  for (const c of zL.values())
    o.addComponent(c);
  const a = new GMe(n, r, o);
  return _R.set(s, a), a;
}
function ste(t = VL) {
  const e = _R.get(t);
  if (!e && t === VL)
    return $Me();
  if (!e)
    throw Zu.create("no-app", { appName: t });
  return e;
}
function Zc(t, e, n) {
  var r;
  let s = (r = jMe[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), Uf.warn(a.join(" "));
    return;
  }
  Ff(new Oh(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const KMe = "firebase-heartbeat-database", JMe = 1, iC = "firebase-heartbeat-store";
let vM = null;
function ite() {
  return vM || (vM = pMe(KMe, JMe, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(iC);
      }
    }
  }).catch((t) => {
    throw Zu.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), vM;
}
async function QMe(t) {
  try {
    return (await ite()).transaction(iC).objectStore(iC).get(ote(t));
  } catch (e) {
    if (e instanceof xl)
      Uf.warn(e.message);
    else {
      const n = Zu.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      Uf.warn(n.message);
    }
  }
}
async function EG(t, e) {
  try {
    const n = (await ite()).transaction(iC, "readwrite");
    return await n.objectStore(iC).put(e, ote(t)), n.done;
  } catch (n) {
    if (n instanceof xl)
      Uf.warn(n.message);
    else {
      const r = Zu.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      Uf.warn(r.message);
    }
  }
}
function ote(t) {
  return `${t.name}!${t.options.appId}`;
}
const ZMe = 1024, XMe = 30 * 24 * 60 * 60 * 1e3;
class e1e {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new n1e(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const e = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), n = SG();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === n || this._heartbeatsCache.heartbeats.some((r) => r.date === n)))
      return this._heartbeatsCache.heartbeats.push({ date: n, agent: e }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const s = new Date(r.date).valueOf();
        return Date.now() - s <= XMe;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = SG(), { heartbeatsToSend: n, unsentEntries: r } = t1e(this._heartbeatsCache.heartbeats), s = dR(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function SG() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function t1e(t, e = ZMe) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), IG(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), IG(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class n1e {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return WAe() ? BAe().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await QMe(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return EG(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return EG(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function IG(t) {
  return dR(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function r1e(t) {
  Ff(new Oh(
    "platform-logger",
    (e) => new gMe(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Ff(new Oh(
    "heartbeat",
    (e) => new e1e(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Zc(jL, bG, t), Zc(jL, bG, "esm2017"), Zc("fire-js", "");
}
r1e("");
const RG = "@firebase/database", kG = "0.14.4";
let ate = "";
function s1e(t) {
  ate = t;
}
class i1e {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), _r(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : sC(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class o1e {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return za(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const cte = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new i1e(e);
    }
  } catch {
  }
  return new o1e();
}, Ld = cte("localStorage"), a1e = cte("sessionStorage"), E_ = new YW("@firebase/database"), c1e = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), lte = function(t) {
  const e = QAe(t), n = new GAe();
  n.update(e);
  const r = n.digest();
  return LW.encodeByteArray(r);
}, lb = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += lb.apply(null, r) : typeof r == "object" ? e += _r(r) : e += r, e += " ";
  }
  return e;
};
let zv = null, NG = !0;
const l1e = function(t, e) {
  P(!e, "Can't turn on custom loggers persistently."), E_.logLevel = kt.VERBOSE, zv = E_.log.bind(E_);
}, Kr = function(...t) {
  if (NG === !0 && (NG = !1, zv === null && a1e.get("logging_enabled") === !0 && l1e()), zv) {
    const e = lb.apply(null, t);
    zv(e);
  }
}, ub = function(t) {
  return function(...e) {
    Kr(t, ...e);
  };
}, GL = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + lb(...t);
  E_.error(e);
}, vl = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${lb(...t)}`;
  throw E_.error(e), new Error(e);
}, Ls = function(...t) {
  const e = "FIREBASE WARNING: " + lb(...t);
  E_.warn(e);
}, u1e = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && Ls("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, BW = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, h1e = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, Em = "[MIN_NAME]", Yf = "[MAX_NAME]", dp = function(t, e) {
  if (t === e)
    return 0;
  if (t === Em || e === Yf)
    return -1;
  if (e === Em || t === Yf)
    return 1;
  {
    const n = PG(t), r = PG(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, d1e = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, Cy = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + _r(e));
}, qW = function(t) {
  if (typeof t != "object" || t === null)
    return _r(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += _r(e[r]), n += ":", n += qW(t[e[r]]);
  return n += "}", n;
}, ute = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function ns(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const hte = function(t) {
  P(!BW(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, f1e = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, p1e = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function _1e(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const m1e = new RegExp("^-?(0*)\\d{1,10}$"), g1e = -2147483648, y1e = 2147483647, PG = function(t) {
  if (m1e.test(t)) {
    const e = Number(t);
    if (e >= g1e && e <= y1e)
      return e;
  }
  return null;
}, ag = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw Ls("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, v1e = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, Gv = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class w1e {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    Ls(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class C1e {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? (Kr("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', Ls(e);
  }
}
class GS {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
GS.OWNER = "owner";
const HW = "5", dte = "v", fte = "s", pte = "r", _te = "f", mte = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, gte = "ls", yte = "p", $L = "ac", vte = "websocket", wte = "long_polling";
class Cte {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = Ld.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && Ld.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function T1e(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function Tte(t, e, n) {
  P(typeof e == "string", "typeof type must == string"), P(typeof n == "object", "typeof params must == object");
  let r;
  if (e === vte)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === wte)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  T1e(t) && (n.ns = t.namespace);
  const s = [];
  return ns(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class b1e {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    za(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return NAe(this.counters_);
  }
}
const wM = {}, CM = {};
function jW(t) {
  const e = t.toString();
  return wM[e] || (wM[e] = new b1e()), wM[e];
}
function E1e(t, e) {
  const n = t.toString();
  return CM[n] || (CM[n] = e()), CM[n];
}
class S1e {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && ag(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const OG = "start", I1e = "close", R1e = "pLPCommand", k1e = "pRTLPCB", bte = "id", Ete = "pw", Ste = "ser", N1e = "cb", P1e = "seg", O1e = "ts", D1e = "d", A1e = "dframe", Ite = 1870, Rte = 30, M1e = Ite - Rte, x1e = 25e3, L1e = 3e4;
class o_ {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = ub(e), this.stats_ = jW(n), this.urlFn = (c) => (this.appCheckToken && (c[$L] = this.appCheckToken), Tte(n, wte, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new S1e(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(L1e)), h1e(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new VW((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === OG)
            this.id = a, this.password = c;
          else if (o === I1e)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[OG] = "t", r[Ste] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[N1e] = this.scriptTagHolder.uniqueCallbackIdentifier), r[dte] = HW, this.transportSessionId && (r[fte] = this.transportSessionId), this.lastSessionId && (r[gte] = this.lastSessionId), this.applicationId && (r[yte] = this.applicationId), this.appCheckToken && (r[$L] = this.appCheckToken), typeof location < "u" && location.hostname && mte.test(location.hostname) && (r[pte] = _te);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    o_.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    o_.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return o_.forceAllow_ ? !0 : !o_.forceDisallow_ && typeof document < "u" && document.createElement != null && !f1e() && !p1e();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = _r(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = $ee(n), s = ute(r, M1e);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[A1e] = "t", r[bte] = e, r[Ete] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = _r(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
}
class VW {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = c1e(), window[R1e + this.uniqueCallbackIdentifier] = e, window[k1e + this.uniqueCallbackIdentifier] = n, this.myIFrame = VW.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        Kr("frame writing exception"), a.stack && Kr(a.stack), Kr(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || Kr("No IE domain setting required");
      } catch {
        const n = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + n + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[bte] = this.myID, e[Ete] = this.myPW, e[Ste] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + Rte + r.length <= Ite; ) {
        const i = this.pendingSegs.shift();
        r = r + "&" + P1e + s + "=" + i.seg + "&" + O1e + s + "=" + i.ts + "&" + D1e + s + "=" + i.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(x1e)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          Kr("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const U1e = 16384, F1e = 45e3;
let mR = null;
typeof MozWebSocket < "u" ? mR = MozWebSocket : typeof WebSocket < "u" && (mR = WebSocket);
class Eo {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = ub(this.connId), this.stats_ = jW(n), this.connURL = Eo.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[dte] = HW, typeof location < "u" && location.hostname && mte.test(location.hostname) && (o[pte] = _te), n && (o[fte] = n), r && (o[gte] = r), s && (o[$L] = s), i && (o[yte] = i), Tte(e, vte, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, Ld.set("previous_websocket_failure", !0);
    try {
      let r;
      Xee(), this.mySock = new mR(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    Eo.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && mR !== null && !Eo.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return Ld.isInMemoryStorage || Ld.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    Ld.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = sC(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (P(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = _r(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = ute(n, U1e);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(F1e));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
Eo.responsesRequiredToBeHealthy = 2;
Eo.healthyTimeout = 3e4;
class oC {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [o_, Eo];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = Eo && Eo.isAvailable();
    let r = n && !Eo.previouslyFailed();
    if (e.webSocketOnly && (n || Ls("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [Eo];
    else {
      const s = this.transports_ = [];
      for (const i of oC.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      oC.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
oC.globalTransportInitialized_ = !1;
const Y1e = 6e4, W1e = 5e3, B1e = 10 * 1024, q1e = 100 * 1024, TM = "t", DG = "d", H1e = "s", AG = "r", j1e = "e", MG = "o", xG = "a", LG = "n", UG = "p", V1e = "h";
class z1e {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = ub("c:" + this.id + ":"), this.transportManager_ = new oC(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = Gv(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > q1e ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > B1e ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (TM in e) {
      const n = e[TM];
      n === xG ? this.upgradeIfSecondaryHealthy_() : n === AG ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === MG && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = Cy("t", e), r = Cy("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: UG, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: xG, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: LG, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = Cy("t", e), r = Cy("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = Cy(TM, e);
    if (DG in e) {
      const r = e[DG];
      if (n === V1e) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === LG) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === H1e ? this.onConnectionShutdown_(r) : n === AG ? this.onReset_(r) : n === j1e ? GL("Server Error: " + r) : n === MG ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : GL("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), HW !== r && Ls("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), Gv(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(Y1e));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : Gv(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(W1e));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: UG, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (Ld.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class kte {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class Nte {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, P(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    P(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class gR extends Nte {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !UW() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new gR();
  }
  getInitialEvent(e) {
    return P(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const FG = 32, YG = 768;
class Mt {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function lt() {
  return new Mt("");
}
function Ee(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function Dh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function tn(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new Mt(t.pieces_, e);
}
function zW(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function G1e(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function aC(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function Pte(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new Mt(e, 0);
}
function kn(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof Mt)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new Mt(n, 0);
}
function Fe(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function Ns(t, e) {
  const n = Ee(t), r = Ee(e);
  if (n === null)
    return e;
  if (n === r)
    return Ns(tn(t), tn(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function $1e(t, e) {
  const n = aC(t, 0), r = aC(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = dp(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function GW(t, e) {
  if (Dh(t) !== Dh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function $i(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (Dh(t) > Dh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class K1e {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = aC(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += LP(this.parts_[r]);
    Ote(this);
  }
}
function J1e(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += LP(e), Ote(t);
}
function Q1e(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= LP(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function Ote(t) {
  if (t.byteLength_ > YG)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + YG + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > FG)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + FG + ") or object contains a cycle " + Id(t));
}
function Id(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class $W extends Nte {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new $W();
  }
  getInitialEvent(e) {
    return P(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const Ty = 1e3, Z1e = 60 * 5 * 1e3, WG = 30 * 1e3, X1e = 1.3, exe = 3e4, txe = "server_kill", BG = 3;
let hb = class $S extends kte {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = $S.nextPersistentConnectionId_++, this.log_ = ub("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = Ty, this.maxReconnectDelay_ = Z1e, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !Xee())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    $W.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && gR.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(_r(i)), P(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new ob(), r = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (i) => {
        const o = i.d;
        i.s === "ok" ? n.resolve(o) : n.reject(o);
      }
    };
    this.outstandingGets_.push(r), this.outstandingGetCount_++;
    const s = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(s), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), P(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), P(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      $S.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && za(e, "w")) {
      const r = bm(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        Ls(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || zAe(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = WG);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = VAe(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), P(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const s = r.d;
            this.log_("reportStats", "Error sending stats: " + s);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + _r(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : GL("Unrecognized action received from server: " + _r(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    P(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = Ty, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = Ty, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > exe && (this.reconnectDelay_ = Ty), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * X1e);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + $S.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        P(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? Kr("getToken() completed but was canceled") : (Kr("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new z1e(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            Ls(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(txe);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && Ls(u), c());
      }
    }
  }
  interrupt(e) {
    Kr("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    Kr("Resuming connection for reason: " + e), delete this.interruptReasons_[e], yG(this.interruptReasons_) && (this.reconnectDelay_ = Ty, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => qW(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new Mt(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    Kr("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= BG && (this.reconnectDelay_ = WG, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    Kr("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= BG && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + ate.replace(/\./g, "-")] = 1, UW() ? e["framework.cordova"] = 1 : Zee() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = gR.getInstance().currentlyOnline();
    return yG(this.interruptReasons_) && e;
  }
};
hb.nextPersistentConnectionId_ = 0;
hb.nextConnectionId_ = 0;
class Oe {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new Oe(e, n);
  }
}
class UP {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new Oe(Em, e), s = new Oe(Em, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return Oe.MIN;
  }
}
let qE;
class Dte extends UP {
  static get __EMPTY_NODE() {
    return qE;
  }
  static set __EMPTY_NODE(e) {
    qE = e;
  }
  compare(e, n) {
    return dp(e.name, n.name);
  }
  isDefinedOn(e) {
    throw og("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return Oe.MIN;
  }
  maxPost() {
    return new Oe(Yf, qE);
  }
  makePost(e, n) {
    return P(typeof e == "string", "KeyIndex indexValue must always be a string."), new Oe(e, qE);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const S_ = new Dte();
class HE {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class xr {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? xr.RED, this.left = s ?? li.EMPTY_NODE, this.right = i ?? li.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new xr(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return li.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return li.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, xr.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, xr.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
xr.RED = !0;
xr.BLACK = !1;
class nxe {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new xr(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class li {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = li.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new li(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, xr.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new li(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, xr.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new HE(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new HE(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new HE(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new HE(this.root_, null, this.comparator_, !0, e);
  }
}
li.EMPTY_NODE = new nxe();
function rxe(t, e) {
  return dp(t.name, e.name);
}
function KW(t, e) {
  return dp(t, e);
}
let KL;
function sxe(t) {
  KL = t;
}
const Ate = function(t) {
  return typeof t == "number" ? "number:" + hte(t) : "string:" + t;
}, Mte = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    P(typeof e == "string" || typeof e == "number" || typeof e == "object" && za(e, ".sv"), "Priority must be a string or number.");
  } else
    P(t === KL || t.isEmpty(), "priority of unexpected type.");
  P(t === KL || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let qG;
class kr {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = kr.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, P(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), Mte(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    qG = e;
  }
  static get __childrenNodeConstructor() {
    return qG;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new kr(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : kr.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return Fe(e) ? this : Ee(e) === ".priority" ? this.priorityNode_ : kr.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : kr.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Ee(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (P(r !== ".priority" || Dh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, kr.__childrenNodeConstructor.EMPTY_NODE.updateChild(tn(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + Ate(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += hte(this.value_) : e += this.value_, this.lazyHash_ = lte(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === kr.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof kr.__childrenNodeConstructor ? -1 : (P(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = kr.VALUE_TYPE_ORDER.indexOf(n), i = kr.VALUE_TYPE_ORDER.indexOf(r);
    return P(s >= 0, "Unknown leaf type: " + n), P(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
kr.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let xte, Lte;
function ixe(t) {
  xte = t;
}
function oxe(t) {
  Lte = t;
}
class axe extends UP {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? dp(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return Oe.MIN;
  }
  maxPost() {
    return new Oe(Yf, new kr("[PRIORITY-POST]", Lte));
  }
  makePost(e, n) {
    const r = xte(e);
    return new Oe(n, new kr("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const xn = new axe(), cxe = Math.log(2);
class lxe {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / cxe, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const yR = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new xr(d, u.node, xr.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new xr(d, u.node, xr.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new xr(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, xr.BLACK) : (d(m, xr.BLACK), d(m, xr.RED));
    }
    return h;
  }, o = new lxe(t.length), a = i(o);
  return new li(r || e, a);
};
let bM;
const xp = {};
class Ec {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return P(xp && xn, "ChildrenNode.ts has not been loaded"), bM = bM || new Ec({ ".priority": xp }, { ".priority": xn }), bM;
  }
  get(e) {
    const n = bm(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof li ? n : null;
  }
  hasIndex(e) {
    return za(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    P(e !== S_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(Oe.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = yR(r, e.getCompare()) : a = xp;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new Ec(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = pR(this.indexes_, (s, i) => {
      const o = bm(this.indexSet_, i);
      if (P(o, "Missing index implementation for " + i), s === xp)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(Oe.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), yR(a, o.getCompare());
        } else
          return xp;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new Oe(e.name, a))), c.insert(e, e.node);
      }
    });
    return new Ec(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = pR(this.indexes_, (s) => {
      if (s === xp)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new Oe(e.name, i)) : s;
      }
    });
    return new Ec(r, this.indexSet_);
  }
}
let by;
class ce {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && Mte(this.priorityNode_), this.children_.isEmpty() && P(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return by || (by = new ce(new li(KW), null, Ec.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || by;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new ce(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? by : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = Ee(e);
    return n === null ? this : this.getImmediateChild(n).getChild(tn(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (P(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new Oe(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? by : this.priorityNode_;
      return new ce(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Ee(e);
    if (r === null)
      return n;
    {
      P(Ee(e) !== ".priority" || Dh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(tn(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(xn, (o, a) => {
      n[o] = a.val(e), r++, i && ce.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + Ate(this.getPriority().val()) + ":"), this.forEachChild(xn, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : lte(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new Oe(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new Oe(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new Oe(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, Oe.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, Oe.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === db ? -1 : 0;
  }
  withIndex(e) {
    if (e === S_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new ce(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === S_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(xn), s = n.getIterator(xn);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === S_ ? null : this.indexMap_.get(e.toString());
  }
}
ce.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class uxe extends ce {
  constructor() {
    super(new li(KW), ce.EMPTY_NODE, Ec.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return ce.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const db = new uxe();
Object.defineProperties(Oe, {
  MIN: {
    value: new Oe(Em, ce.EMPTY_NODE)
  },
  MAX: {
    value: new Oe(Yf, db)
  }
});
Dte.__EMPTY_NODE = ce.EMPTY_NODE;
kr.__childrenNodeConstructor = ce;
sxe(db);
oxe(db);
const hxe = !0;
function cr(t, e = null) {
  if (t === null)
    return ce.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), P(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new kr(n, cr(e));
  }
  if (!(t instanceof Array) && hxe) {
    const n = [];
    let r = !1;
    if (ns(t, (i, o) => {
      if (i.substring(0, 1) !== ".") {
        const a = cr(o);
        a.isEmpty() || (r = r || !a.getPriority().isEmpty(), n.push(new Oe(i, a)));
      }
    }), n.length === 0)
      return ce.EMPTY_NODE;
    const s = yR(n, rxe, (i) => i.name, KW);
    if (r) {
      const i = yR(n, xn.getCompare());
      return new ce(s, cr(e), new Ec({ ".priority": i }, { ".priority": xn }));
    } else
      return new ce(s, cr(e), Ec.Default);
  } else {
    let n = ce.EMPTY_NODE;
    return ns(t, (r, s) => {
      if (za(t, r) && r.substring(0, 1) !== ".") {
        const i = cr(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(cr(e));
  }
}
ixe(cr);
class dxe extends UP {
  constructor(e) {
    super(), this.indexPath_ = e, P(!Fe(e) && Ee(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? dp(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = cr(e), s = ce.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new Oe(n, s);
  }
  maxPost() {
    const e = ce.EMPTY_NODE.updateChild(this.indexPath_, db);
    return new Oe(Yf, e);
  }
  toString() {
    return aC(this.indexPath_, 0).join("/");
  }
}
class fxe extends UP {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? dp(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return Oe.MIN;
  }
  maxPost() {
    return Oe.MAX;
  }
  makePost(e, n) {
    const r = cr(e);
    return new Oe(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const pxe = new fxe();
function Ute(t) {
  return { type: "value", snapshotNode: t };
}
function Sm(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function cC(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function lC(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function _xe(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class JW {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    P(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(cC(n, a)) : P(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(Sm(n, r)) : o.trackChildChange(lC(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(xn, (s, i) => {
      n.hasChild(s) || r.trackChildChange(cC(s, i));
    }), n.isLeafNode() || n.forEachChild(xn, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(lC(s, i, o));
      } else
        r.trackChildChange(Sm(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? ce.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class uC {
  constructor(e) {
    this.indexedFilter_ = new JW(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = uC.getStartPost_(e), this.endPost_ = uC.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new Oe(n, r)) || (r = ce.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = ce.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(ce.EMPTY_NODE);
    const i = this;
    return n.forEachChild(xn, (o, a) => {
      i.matches(new Oe(o, a)) || (s = s.updateImmediateChild(o, ce.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class mxe {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new uC(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new Oe(n, r)) || (r = ce.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = ce.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = ce.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(ce.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, ce.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    P(a.numChildren() === this.limit_, "");
    const c = new Oe(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(lC(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(cC(n, u));
        const p = a.updateImmediateChild(n, ce.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(Sm(d.name, d.node)), p.updateImmediateChild(d.name, d.node)) : p;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(cC(l.name, l.node)), i.trackChildChange(Sm(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, ce.EMPTY_NODE)) : e;
  }
}
class QW {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = xn;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return P(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return P(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : Em;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return P(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return P(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : Yf;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return P(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === xn;
  }
  copy() {
    const e = new QW();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function gxe(t) {
  return t.loadsAllData() ? new JW(t.getIndex()) : t.hasLimit() ? new mxe(t) : new uC(t);
}
function HG(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === xn ? n = "$priority" : t.index_ === pxe ? n = "$value" : t.index_ === S_ ? n = "$key" : (P(t.index_ instanceof dxe, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = _r(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = _r(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + _r(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = _r(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + _r(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function jG(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== xn && (e.i = t.index_.toString()), e;
}
class vR extends kte {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = ub("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (P(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = vR.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = HG(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), bm(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = vR.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = HG(e._queryParams), r = e._path.toString(), s = new ob();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + FW(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = sC(a.responseText);
            } catch {
              Ls("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && Ls("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class yxe {
  constructor() {
    this.rootNode_ = ce.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function wR() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function Fte(t, e, n) {
  if (Fe(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = Ee(e);
    t.children.has(r) || t.children.set(r, wR());
    const s = t.children.get(r);
    e = tn(e), Fte(s, e, n);
  }
}
function JL(t, e, n) {
  t.value !== null ? n(e, t.value) : vxe(t, (r, s) => {
    const i = new Mt(e.toString() + "/" + r);
    JL(s, i, n);
  });
}
function vxe(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class wxe {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && ns(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const VG = 10 * 1e3, Cxe = 30 * 1e3, Txe = 5 * 60 * 1e3;
class bxe {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new wxe(e);
    const r = VG + (Cxe - VG) * Math.random();
    Gv(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    ns(e, (s, i) => {
      i > 0 && za(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), Gv(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * Txe));
  }
}
var Oo;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(Oo || (Oo = {}));
function ZW() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function XW() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function eB(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class CR {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = Oo.ACK_USER_WRITE, this.source = ZW();
  }
  operationForChild(e) {
    if (Fe(this.path)) {
      if (this.affectedTree.value != null)
        return P(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new Mt(e));
        return new CR(lt(), n, this.revert);
      }
    } else return P(Ee(this.path) === e, "operationForChild called for unrelated child."), new CR(tn(this.path), this.affectedTree, this.revert);
  }
}
class hC {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = Oo.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return Fe(this.path) ? new hC(this.source, lt()) : new hC(this.source, tn(this.path));
  }
}
class Wf {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = Oo.OVERWRITE;
  }
  operationForChild(e) {
    return Fe(this.path) ? new Wf(this.source, lt(), this.snap.getImmediateChild(e)) : new Wf(this.source, tn(this.path), this.snap);
  }
}
class Im {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = Oo.MERGE;
  }
  operationForChild(e) {
    if (Fe(this.path)) {
      const n = this.children.subtree(new Mt(e));
      return n.isEmpty() ? null : n.value ? new Wf(this.source, lt(), n.value) : new Im(this.source, lt(), n);
    } else
      return P(Ee(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new Im(this.source, tn(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class Ah {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (Fe(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = Ee(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class Exe {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function Sxe(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(_xe(o.childName, o.snapshotNode));
  }), Ey(t, s, "child_removed", e, r, n), Ey(t, s, "child_added", e, r, n), Ey(t, s, "child_moved", i, r, n), Ey(t, s, "child_changed", e, r, n), Ey(t, s, "value", e, r, n), s;
}
function Ey(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => Rxe(t, a, c)), o.forEach((a) => {
    const c = Ixe(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function Ixe(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function Rxe(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw og("Should only compare child_ events.");
  const r = new Oe(e.childName, e.snapshotNode), s = new Oe(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function FP(t, e) {
  return { eventCache: t, serverCache: e };
}
function $v(t, e, n, r) {
  return FP(new Ah(e, n, r), t.serverCache);
}
function Yte(t, e, n, r) {
  return FP(t.eventCache, new Ah(e, n, r));
}
function TR(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function Bf(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let EM;
const kxe = () => (EM || (EM = new li(d1e)), EM);
class Gt {
  constructor(e, n = kxe()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new Gt(null);
    return ns(e, (r, s) => {
      n = n.set(new Mt(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: lt(), value: this.value };
    if (Fe(e))
      return null;
    {
      const r = Ee(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(tn(e), n);
        return i != null ? { path: kn(new Mt(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (Fe(e))
      return this;
    {
      const n = Ee(e), r = this.children.get(n);
      return r !== null ? r.subtree(tn(e)) : new Gt(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (Fe(e))
      return new Gt(n, this.children);
    {
      const r = Ee(e), s = (this.children.get(r) || new Gt(null)).set(tn(e), n), i = this.children.insert(r, s);
      return new Gt(this.value, i);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (Fe(e))
      return this.children.isEmpty() ? new Gt(null) : new Gt(null, this.children);
    {
      const n = Ee(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(tn(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new Gt(null) : new Gt(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (Fe(e))
      return this.value;
    {
      const n = Ee(e), r = this.children.get(n);
      return r ? r.get(tn(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (Fe(e))
      return n;
    {
      const r = Ee(e), s = (this.children.get(r) || new Gt(null)).setTree(tn(e), n);
      let i;
      return s.isEmpty() ? i = this.children.remove(r) : i = this.children.insert(r, s), new Gt(this.value, i);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(lt(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(kn(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, lt(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (Fe(e))
      return null;
    {
      const i = Ee(e), o = this.children.get(i);
      return o ? o.findOnPath_(tn(e), kn(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, lt(), n);
  }
  foreachOnPath_(e, n, r) {
    if (Fe(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = Ee(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(tn(e), kn(n, s), r) : new Gt(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(lt(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(kn(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class jo {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new jo(new Gt(null));
  }
}
function Kv(t, e, n) {
  if (Fe(e))
    return new jo(new Gt(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = Ns(s, e);
      return i = i.updateChild(o, n), new jo(t.writeTree_.set(s, i));
    } else {
      const s = new Gt(n), i = t.writeTree_.setTree(e, s);
      return new jo(i);
    }
  }
}
function QL(t, e, n) {
  let r = t;
  return ns(n, (s, i) => {
    r = Kv(r, kn(e, s), i);
  }), r;
}
function zG(t, e) {
  if (Fe(e))
    return jo.empty();
  {
    const n = t.writeTree_.setTree(e, new Gt(null));
    return new jo(n);
  }
}
function ZL(t, e) {
  return fp(t, e) != null;
}
function fp(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(Ns(n.path, e)) : null;
}
function GG(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(xn, (r, s) => {
    e.push(new Oe(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new Oe(r, s.value));
  }), e;
}
function Xu(t, e) {
  if (Fe(e))
    return t;
  {
    const n = fp(t, e);
    return n != null ? new jo(new Gt(n)) : new jo(t.writeTree_.subtree(e));
  }
}
function XL(t) {
  return t.writeTree_.isEmpty();
}
function Rm(t, e) {
  return Wte(lt(), t.writeTree_, e);
}
function Wte(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (P(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = Wte(kn(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(kn(t, ".priority"), r)), n;
  }
}
function YP(t, e) {
  return jte(e, t);
}
function Nxe(t, e, n, r, s) {
  P(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = Kv(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function Pxe(t, e, n, r) {
  P(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = QL(t.visibleWrites, e, n), t.lastWriteId = r;
}
function Oxe(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function Dxe(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  P(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && Axe(a, r.path) ? s = !1 : $i(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return Mxe(t), !0;
    if (r.snap)
      t.visibleWrites = zG(t.visibleWrites, r.path);
    else {
      const a = r.children;
      ns(a, (c) => {
        t.visibleWrites = zG(t.visibleWrites, kn(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function Axe(t, e) {
  if (t.snap)
    return $i(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && $i(kn(t.path, n), e))
      return !0;
  return !1;
}
function Mxe(t) {
  t.visibleWrites = Bte(t.allWrites, xxe, lt()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function xxe(t) {
  return t.visible;
}
function Bte(t, e, n) {
  let r = jo.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        $i(n, o) ? (a = Ns(n, o), r = Kv(r, a, i.snap)) : $i(o, n) && (a = Ns(o, n), r = Kv(r, lt(), i.snap.getChild(a)));
      else if (i.children) {
        if ($i(n, o))
          a = Ns(n, o), r = QL(r, a, i.children);
        else if ($i(o, n))
          if (a = Ns(o, n), Fe(a))
            r = QL(r, lt(), i.children);
          else {
            const c = bm(i.children, Ee(a));
            if (c) {
              const l = c.getChild(tn(a));
              r = Kv(r, lt(), l);
            }
          }
      } else
        throw og("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function qte(t, e, n, r, s) {
  if (!r && !s) {
    const i = fp(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = Xu(t.visibleWrites, e);
      if (XL(o))
        return n;
      if (n == null && !ZL(o, lt()))
        return null;
      {
        const a = n || ce.EMPTY_NODE;
        return Rm(o, a);
      }
    }
  } else {
    const i = Xu(t.visibleWrites, e);
    if (!s && XL(i))
      return n;
    if (!s && n == null && !ZL(i, lt()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && ($i(l.path, e) || $i(e, l.path));
      }, a = Bte(t.allWrites, o, e), c = n || ce.EMPTY_NODE;
      return Rm(a, c);
    }
  }
}
function Lxe(t, e, n) {
  let r = ce.EMPTY_NODE;
  const s = fp(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(xn, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = Xu(t.visibleWrites, e);
    return n.forEachChild(xn, (o, a) => {
      const c = Rm(Xu(i, new Mt(o)), a);
      r = r.updateImmediateChild(o, c);
    }), GG(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = Xu(t.visibleWrites, e);
    return GG(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function Uxe(t, e, n, r, s) {
  P(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = kn(e, n);
  if (ZL(t.visibleWrites, i))
    return null;
  {
    const o = Xu(t.visibleWrites, i);
    return XL(o) ? s.getChild(n) : Rm(o, s.getChild(n));
  }
}
function Fxe(t, e, n, r) {
  const s = kn(e, n), i = fp(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = Xu(t.visibleWrites, s);
    return Rm(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function Yxe(t, e) {
  return fp(t.visibleWrites, e);
}
function Wxe(t, e, n, r, s, i, o) {
  let a;
  const c = Xu(t.visibleWrites, e), l = fp(c, lt());
  if (l != null)
    a = l;
  else if (n != null)
    a = Rm(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function Bxe() {
  return {
    visibleWrites: jo.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function bR(t, e, n, r) {
  return qte(t.writeTree, t.treePath, e, n, r);
}
function tB(t, e) {
  return Lxe(t.writeTree, t.treePath, e);
}
function $G(t, e, n, r) {
  return Uxe(t.writeTree, t.treePath, e, n, r);
}
function ER(t, e) {
  return Yxe(t.writeTree, kn(t.treePath, e));
}
function qxe(t, e, n, r, s, i) {
  return Wxe(t.writeTree, t.treePath, e, n, r, s, i);
}
function nB(t, e, n) {
  return Fxe(t.writeTree, t.treePath, e, n);
}
function Hte(t, e) {
  return jte(kn(t.treePath, e), t.writeTree);
}
function jte(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class Hxe {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    P(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), P(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, lC(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, cC(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, Sm(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, lC(r, e.snapshotNode, s.oldSnap));
      else
        throw og("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class jxe {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const Vte = new jxe();
class rB {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new Ah(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return nB(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : Bf(this.viewCache_), i = qxe(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function Vxe(t) {
  return { filter: t };
}
function zxe(t, e) {
  P(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), P(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function Gxe(t, e, n, r, s) {
  const i = new Hxe();
  let o, a;
  if (n.type === Oo.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = eU(t, e, l.path, l.snap, r, s, i) : (P(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !Fe(l.path), o = SR(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === Oo.MERGE) {
    const l = n;
    l.source.fromUser ? o = Kxe(t, e, l.path, l.children, r, s, i) : (P(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = tU(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === Oo.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = Zxe(t, e, l.path, r, s, i) : o = Jxe(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === Oo.LISTEN_COMPLETE)
    o = Qxe(t, e, n.path, r, i);
  else
    throw og("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return $xe(e, o, c), { viewCache: o, changes: c };
}
function $xe(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = TR(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(Ute(TR(e)));
  }
}
function zte(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (ER(r, n) != null)
    return e;
  {
    let a, c;
    if (Fe(n))
      if (P(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = Bf(e), h = l instanceof ce ? l : ce.EMPTY_NODE, u = tB(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = bR(r, Bf(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = Ee(n);
      if (l === ".priority") {
        P(Dh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = $G(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = tn(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = $G(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = nB(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return $v(e, a, o.isFullyInitialized() || Fe(n), t.filter.filtersNodes());
  }
}
function SR(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (Fe(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = Ee(n);
    if (!c.isCompleteForPath(n) && Dh(n) > 1)
      return e;
    const p = tn(n), _ = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), _) : l = h.updateChild(c.getNode(), f, _, p, Vte, null);
  }
  const u = Yte(e, l, c.isFullyInitialized() || Fe(n), h.filtersNodes()), d = new rB(s, u, i);
  return zte(t, u, n, s, d, a);
}
function eU(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new rB(s, e, i);
  if (Fe(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = $v(e, l, !0, t.filter.filtersNodes());
  else {
    const u = Ee(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = $v(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = tn(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (Fe(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? zW(d) === ".priority" && _.getChild(Pte(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = ce.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = $v(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function KG(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function Kxe(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = kn(n, c);
    KG(e, Ee(h)) && (a = eU(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = kn(n, c);
    KG(e, Ee(h)) || (a = eU(t, a, h, l, s, i, o));
  }), a;
}
function JG(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function tU(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  Fe(n) ? l = r : l = new Gt(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = JG(t, f, d);
      c = SR(t, c, new Mt(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = JG(t, p, d);
      c = SR(t, c, new Mt(u), _, s, i, o, a);
    }
  }), c;
}
function Jxe(t, e, n, r, s, i, o) {
  if (ER(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (Fe(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return SR(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (Fe(n)) {
      let l = new Gt(null);
      return c.getNode().forEachChild(S_, (h, u) => {
        l = l.set(new Mt(h), u);
      }), tU(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new Gt(null);
    return r.foreach((h, u) => {
      const d = kn(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), tU(t, e, n, l, s, i, a, o);
  }
}
function Qxe(t, e, n, r, s) {
  const i = e.serverCache, o = Yte(e, i.getNode(), i.isFullyInitialized() || Fe(n), i.isFiltered());
  return zte(t, o, n, r, Vte, s);
}
function Zxe(t, e, n, r, s, i) {
  let o;
  if (ER(r, n) != null)
    return e;
  {
    const a = new rB(r, e, s), c = e.eventCache.getNode();
    let l;
    if (Fe(n) || Ee(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = bR(r, Bf(e));
      else {
        const u = e.serverCache.getNode();
        P(u instanceof ce, "serverChildren would be complete if leaf node"), h = tB(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = Ee(n);
      let u = nB(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, tn(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, ce.EMPTY_NODE, tn(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = bR(r, Bf(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || ER(r, lt()) != null, $v(e, l, o, t.filter.filtersNodes());
  }
}
class Xxe {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new JW(r.getIndex()), i = gxe(r);
    this.processor_ = Vxe(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(ce.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(ce.EMPTY_NODE, a.getNode(), null), h = new Ah(c, o.isFullyInitialized(), s.filtersNodes()), u = new Ah(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = FP(u, h), this.eventGenerator_ = new Exe(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function eLe(t) {
  return t.viewCache_.serverCache.getNode();
}
function tLe(t) {
  return TR(t.viewCache_);
}
function nLe(t, e) {
  const n = Bf(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !Fe(e) && !n.getImmediateChild(Ee(e)).isEmpty()) ? n.getChild(e) : null;
}
function QG(t) {
  return t.eventRegistrations_.length === 0;
}
function rLe(t, e) {
  t.eventRegistrations_.push(e);
}
function ZG(t, e, n) {
  const r = [];
  if (n) {
    P(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function XG(t, e, n, r) {
  e.type === Oo.MERGE && e.source.queryId !== null && (P(Bf(t.viewCache_), "We should always have a full cache before handling merges"), P(TR(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = Gxe(t.processor_, s, e, n, r);
  return zxe(t.processor_, i.viewCache), P(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, Gte(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function sLe(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(xn, (s, i) => {
    r.push(Sm(s, i));
  }), n.isFullyInitialized() && r.push(Ute(n.getNode())), Gte(t, r, n.getNode(), e);
}
function Gte(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return Sxe(t.eventGenerator_, e, n, s);
}
let IR;
class $te {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function iLe(t) {
  P(!IR, "__referenceConstructor has already been defined"), IR = t;
}
function oLe() {
  return P(IR, "Reference.ts has not been loaded"), IR;
}
function aLe(t) {
  return t.views.size === 0;
}
function sB(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return P(i != null, "SyncTree gave us an op for an invalid query."), XG(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat(XG(o, e, n, r));
    return i;
  }
}
function Kte(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = bR(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof ce ? (a = tB(n, r), c = !1) : (a = ce.EMPTY_NODE, c = !1);
    const l = FP(new Ah(a, c, !1), new Ah(r, s, !1));
    return new Xxe(e, l);
  }
  return o;
}
function cLe(t, e, n, r, s, i) {
  const o = Kte(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), rLe(o, n), sLe(o, n);
}
function lLe(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = Mh(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(ZG(l, n, r)), QG(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(ZG(c, n, r)), QG(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !Mh(t) && i.push(new (oLe())(e._repo, e._path)), { removed: i, events: o };
}
function Jte(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function eh(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || nLe(r, e);
  return n;
}
function Qte(t, e) {
  if (e._queryParams.loadsAllData())
    return WP(t);
  {
    const n = e._queryIdentifier;
    return t.views.get(n);
  }
}
function Zte(t, e) {
  return Qte(t, e) != null;
}
function Mh(t) {
  return WP(t) != null;
}
function WP(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let RR;
function uLe(t) {
  P(!RR, "__referenceConstructor has already been defined"), RR = t;
}
function hLe() {
  return P(RR, "Reference.ts has not been loaded"), RR;
}
let dLe = 1;
class e$ {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new Gt(null), this.pendingWriteTree_ = Bxe(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function Xte(t, e, n, r, s) {
  return Nxe(t.pendingWriteTree_, e, n, r, s), s ? cg(t, new Wf(ZW(), e, n)) : [];
}
function fLe(t, e, n, r) {
  Pxe(t.pendingWriteTree_, e, n, r);
  const s = Gt.fromObject(n);
  return cg(t, new Im(ZW(), e, s));
}
function bu(t, e, n = !1) {
  const r = Oxe(t.pendingWriteTree_, e);
  if (Dxe(t.pendingWriteTree_, e)) {
    let s = new Gt(null);
    return r.snap != null ? s = s.set(lt(), !0) : ns(r.children, (i) => {
      s = s.set(new Mt(i), !0);
    }), cg(t, new CR(r.path, s, n));
  } else
    return [];
}
function fb(t, e, n) {
  return cg(t, new Wf(XW(), e, n));
}
function pLe(t, e, n) {
  const r = Gt.fromObject(n);
  return cg(t, new Im(XW(), e, r));
}
function _Le(t, e) {
  return cg(t, new hC(XW(), e));
}
function mLe(t, e, n) {
  const r = oB(t, n);
  if (r) {
    const s = aB(r), i = s.path, o = s.queryId, a = Ns(i, e), c = new hC(eB(o), a);
    return cB(t, i, c);
  } else
    return [];
}
function kR(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || Zte(o, e))) {
    const c = lLe(o, e, n, r);
    aLe(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => Mh(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = vLe(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = rne(t, _);
            t.listenProvider_.startListening(Jv(m), dC(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(Jv(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(BP(d));
        t.listenProvider_.stopListening(Jv(d), f);
      }));
    }
    wLe(t, l);
  }
  return a;
}
function ene(t, e, n, r) {
  const s = oB(t, r);
  if (s != null) {
    const i = aB(s), o = i.path, a = i.queryId, c = Ns(o, e), l = new Wf(eB(a), c, n);
    return cB(t, o, l);
  } else
    return [];
}
function gLe(t, e, n, r) {
  const s = oB(t, r);
  if (s) {
    const i = aB(s), o = i.path, a = i.queryId, c = Ns(o, e), l = Gt.fromObject(n), h = new Im(eB(a), c, l);
    return cB(t, o, h);
  } else
    return [];
}
function nU(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = Ns(d, s);
    i = i || eh(f, p), o = o || Mh(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || Mh(a), i = i || eh(a, lt())) : (a = new $te(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = ce.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((d, f) => {
    const p = eh(f, lt());
    p && (i = i.updateImmediateChild(d, p));
  }));
  const l = Zte(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = BP(e);
    P(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = CLe();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = YP(t.pendingWriteTree_, s);
  let u = cLe(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = Qte(a, e);
    u = u.concat(TLe(t, e, d));
  }
  return u;
}
function iB(t, e, n) {
  const r = t.pendingWriteTree_, s = t.syncPointTree_.findOnPath(e, (i, o) => {
    const a = Ns(i, e), c = eh(o, a);
    if (c)
      return c;
  });
  return qte(r, e, s, n, !0);
}
function yLe(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = Ns(l, n);
    r = r || eh(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || eh(s, lt()) : (s = new $te(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new Ah(r, !0, !1) : null, a = YP(t.pendingWriteTree_, e._path), c = Kte(s, e, a, i ? o.getNode() : ce.EMPTY_NODE, i);
  return tLe(c);
}
function cg(t, e) {
  return tne(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    YP(t.pendingWriteTree_, lt())
  );
}
function tne(t, e, n, r) {
  if (Fe(t.path))
    return nne(t, e, n, r);
  {
    const s = e.get(lt());
    n == null && s != null && (n = eh(s, lt()));
    let i = [];
    const o = Ee(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = Hte(r, o);
      i = i.concat(tne(a, c, l, h));
    }
    return s && (i = i.concat(sB(s, t, r, n))), i;
  }
}
function nne(t, e, n, r) {
  const s = e.get(lt());
  n == null && s != null && (n = eh(s, lt()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = Hte(r, o), h = t.operationForChild(o);
    h && (i = i.concat(nne(h, a, c, l)));
  }), s && (i = i.concat(sB(s, t, r, n))), i;
}
function rne(t, e) {
  const n = e.query, r = dC(t, n);
  return {
    hashFn: () => (eLe(e) || ce.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? mLe(t, n._path, r) : _Le(t, n._path);
      {
        const i = _1e(s, n);
        return kR(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function dC(t, e) {
  const n = BP(e);
  return t.queryToTagMap.get(n);
}
function BP(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function oB(t, e) {
  return t.tagToQueryMap.get(e);
}
function aB(t) {
  const e = t.indexOf("$");
  return P(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new Mt(t.substr(0, e))
  };
}
function cB(t, e, n) {
  const r = t.syncPointTree_.get(e);
  P(r, "Missing sync point for query tag that we're tracking");
  const s = YP(t.pendingWriteTree_, e);
  return sB(r, n, s, null);
}
function vLe(t) {
  return t.fold((e, n, r) => {
    if (n && Mh(n))
      return [WP(n)];
    {
      let s = [];
      return n && (s = Jte(n)), ns(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function Jv(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (hLe())(t._repo, t._path) : t;
}
function wLe(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = BP(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function CLe() {
  return dLe++;
}
function TLe(t, e, n) {
  const r = e._path, s = dC(t, e), i = rne(t, n), o = t.listenProvider_.startListening(Jv(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    P(!Mh(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!Fe(l) && h && Mh(h))
        return [WP(h).query];
      {
        let d = [];
        return h && (d = d.concat(Jte(h).map((f) => f.query))), ns(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(Jv(h), dC(t, h));
    }
  }
  return o;
}
class lB {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new lB(n);
  }
  node() {
    return this.node_;
  }
}
class uB {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = kn(this.path_, e);
    return new uB(this.syncTree_, n);
  }
  node() {
    return iB(this.syncTree_, this.path_);
  }
}
const bLe = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, t$ = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (P(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return ELe(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return SLe(t[".sv"], e);
  P(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, ELe = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      P(!1, "Unexpected server value: " + t);
  }
}, SLe = function(t, e, n) {
  t.hasOwnProperty("increment") || P(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && P(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (P(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const i = s.getValue();
  return typeof i != "number" ? r : i + r;
}, sne = function(t, e, n, r) {
  return hB(e, new uB(n, t), r);
}, ine = function(t, e, n) {
  return hB(t, new lB(e), n);
};
function hB(t, e, n) {
  const r = t.getPriority().val(), s = t$(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = t$(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new kr(a, cr(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new kr(s))), o.forEachChild(xn, (a, c) => {
      const l = hB(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class dB {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function fB(t, e) {
  let n = e instanceof Mt ? e : new Mt(e), r = t, s = Ee(n);
  for (; s !== null; ) {
    const i = bm(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new dB(s, r, i), n = tn(n), s = Ee(n);
  }
  return r;
}
function lg(t) {
  return t.node.value;
}
function one(t, e) {
  t.node.value = e, rU(t);
}
function ane(t) {
  return t.node.childCount > 0;
}
function ILe(t) {
  return lg(t) === void 0 && !ane(t);
}
function qP(t, e) {
  ns(t.node.children, (n, r) => {
    e(new dB(n, t, r));
  });
}
function cne(t, e, n, r) {
  n && !r && e(t), qP(t, (s) => {
    cne(s, e, !0, r);
  }), n && r && e(t);
}
function RLe(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function pb(t) {
  return new Mt(t.parent === null ? t.name : pb(t.parent) + "/" + t.name);
}
function rU(t) {
  t.parent !== null && kLe(t.parent, t.name, t);
}
function kLe(t, e, n) {
  const r = ILe(n), s = za(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, rU(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, rU(t));
}
const NLe = /[\[\].#$\/\u0000-\u001F\u007F]/, PLe = /[\[\].#$\u0000-\u001F\u007F]/, SM = 10 * 1024 * 1024, pB = function(t) {
  return typeof t == "string" && t.length !== 0 && !NLe.test(t);
}, lne = function(t) {
  return typeof t == "string" && t.length !== 0 && !PLe.test(t);
}, OLe = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), lne(t);
}, DLe = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !BW(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  za(t, ".sv");
}, ALe = function(t, e, n, r) {
  HP(xP(t, "value"), e, n);
}, HP = function(t, e, n) {
  const r = n instanceof Mt ? new K1e(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + Id(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + Id(r) + " with contents = " + e.toString());
  if (BW(e))
    throw new Error(t + "contains " + e.toString() + " " + Id(r));
  if (typeof e == "string" && e.length > SM / 3 && LP(e) > SM)
    throw new Error(t + "contains a string greater than " + SM + " utf8 bytes " + Id(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (ns(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !pB(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + Id(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      J1e(r, o), HP(t, a, r), Q1e(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + Id(r) + " in addition to actual children.");
  }
}, MLe = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = aC(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1) && !pB(i[o]))
        throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
  }
  e.sort($1e);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && $i(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, xLe = function(t, e, n, r) {
  const s = xP(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  ns(e, (o, a) => {
    const c = new Mt(o);
    if (HP(s, a, kn(n, c)), zW(c) === ".priority" && !DLe(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), MLe(s, i);
}, une = function(t, e, n, r) {
  if (!lne(n))
    throw new Error(xP(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, LLe = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), une(t, e, n);
}, ULe = function(t, e) {
  if (Ee(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, FLe = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !pB(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !OLe(n))
    throw new Error(xP(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class YLe {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function jP(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !GW(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function hne(t, e, n) {
  jP(t, n), dne(t, (r) => GW(r, e));
}
function to(t, e, n) {
  jP(t, n), dne(t, (r) => $i(r, e) || $i(e, r));
}
function dne(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (WLe(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function WLe(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      zv && Kr("event: " + n.toString()), ag(r);
    }
  }
}
const BLe = "repo_interrupt", qLe = 25;
class HLe {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new YLe(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = wR(), this.transactionQueueTree_ = new dB(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function jLe(t, e, n) {
  if (t.stats_ = jW(t.repoInfo_), t.forceRestClient_ || v1e())
    t.server_ = new vR(t.repoInfo_, (r, s, i, o) => {
      n$(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => r$(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        _r(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new hb(t.repoInfo_, e, (r, s, i, o) => {
      n$(t, r, s, i, o);
    }, (r) => {
      r$(t, r);
    }, (r) => {
      zLe(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = E1e(t.repoInfo_, () => new bxe(t.stats_, t.server_)), t.infoData_ = new yxe(), t.infoSyncTree_ = new e$({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = fb(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), _B(t, "connected", !1), t.serverSyncTree_ = new e$({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      to(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function VLe(t) {
  const e = t.infoData_.getNode(new Mt(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + e;
}
function VP(t) {
  return bLe({
    timestamp: VLe(t)
  });
}
function n$(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new Mt(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = pR(n, (l) => cr(l));
      o = gLe(t.serverSyncTree_, i, c, s);
    } else {
      const c = cr(n);
      o = ene(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = pR(n, (l) => cr(l));
    o = pLe(t.serverSyncTree_, i, c);
  } else {
    const c = cr(n);
    o = fb(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = km(t, i)), to(t.eventQueue_, a, o);
}
function r$(t, e) {
  _B(t, "connected", e), e === !1 && JLe(t);
}
function zLe(t, e) {
  ns(e, (n, r) => {
    _B(t, n, r);
  });
}
function _B(t, e, n) {
  const r = new Mt("/.info/" + e), s = cr(n);
  t.infoData_.updateSnapshot(r, s);
  const i = fb(t.infoSyncTree_, r, s);
  to(t.eventQueue_, r, i);
}
function mB(t) {
  return t.nextWriteId_++;
}
function GLe(t, e, n) {
  const r = yLe(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = cr(s).withIndex(e._queryParams.getIndex());
    nU(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = fb(t.serverSyncTree_, e._path, i);
    else {
      const a = dC(t.serverSyncTree_, e);
      o = ene(t.serverSyncTree_, e._path, i, a);
    }
    return to(t.eventQueue_, e._path, o), kR(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (_b(t, "get for query " + _r(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function $Le(t, e, n, r, s) {
  _b(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = VP(t), o = cr(n, r), a = iB(t.serverSyncTree_, e), c = ine(o, a, i), l = mB(t), h = Xte(t.serverSyncTree_, e, c, l, !0);
  jP(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || Ls("set at " + e + " failed: " + d);
    const _ = bu(t.serverSyncTree_, l, !p);
    to(t.eventQueue_, e, _), sU(t, s, d, f);
  });
  const u = yB(t, e);
  km(t, u), to(t.eventQueue_, u, []);
}
function KLe(t, e, n, r) {
  _b(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = VP(t), o = {};
  if (ns(n, (a, c) => {
    s = !1, o[a] = sne(kn(e, a), cr(c), t.serverSyncTree_, i);
  }), s)
    Kr("update() called with empty data.  Don't do anything."), sU(t, r, "ok", void 0);
  else {
    const a = mB(t), c = fLe(t.serverSyncTree_, e, o, a);
    jP(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || Ls("update at " + e + " failed: " + l);
      const d = bu(t.serverSyncTree_, a, !u), f = d.length > 0 ? km(t, e) : e;
      to(t.eventQueue_, f, d), sU(t, r, l, h);
    }), ns(n, (l) => {
      const h = yB(t, kn(e, l));
      km(t, h);
    }), to(t.eventQueue_, e, []);
  }
}
function JLe(t) {
  _b(t, "onDisconnectEvents");
  const e = VP(t), n = wR();
  JL(t.onDisconnect_, lt(), (s, i) => {
    const o = sne(s, i, t.serverSyncTree_, e);
    Fte(n, s, o);
  });
  let r = [];
  JL(n, lt(), (s, i) => {
    r = r.concat(fb(t.serverSyncTree_, s, i));
    const o = yB(t, s);
    km(t, o);
  }), t.onDisconnect_ = wR(), to(t.eventQueue_, lt(), r);
}
function QLe(t, e, n) {
  let r;
  Ee(e._path) === ".info" ? r = nU(t.infoSyncTree_, e, n) : r = nU(t.serverSyncTree_, e, n), hne(t.eventQueue_, e._path, r);
}
function s$(t, e, n) {
  let r;
  Ee(e._path) === ".info" ? r = kR(t.infoSyncTree_, e, n) : r = kR(t.serverSyncTree_, e, n), hne(t.eventQueue_, e._path, r);
}
function ZLe(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt(BLe);
}
function _b(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), Kr(n, ...e);
}
function sU(t, e, n, r) {
  e && ag(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function fne(t, e, n) {
  return iB(t.serverSyncTree_, e, n) || ce.EMPTY_NODE;
}
function gB(t, e = t.transactionQueueTree_) {
  if (e || zP(t, e), lg(e)) {
    const n = _ne(t, e);
    P(n.length > 0, "Sending zero length transaction queue"), n.every(
      (r) => r.status === 0
      /* TransactionStatus.RUN */
    ) && XLe(t, pb(e), n);
  } else ane(e) && qP(e, (n) => {
    gB(t, n);
  });
}
function XLe(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = fne(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    P(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = Ns(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    _b(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(bu(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      zP(t, fB(t.transactionQueueTree_, e)), gB(t, t.transactionQueueTree_), to(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        ag(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        Ls("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      km(t, e);
    }
  }, o);
}
function km(t, e) {
  const n = pne(t, e), r = pb(n), s = _ne(t, n);
  return eUe(t, s, r), r;
}
function eUe(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const i = e.filter((o) => o.status === 0).map((o) => o.currentWriteId);
  for (let o = 0; o < e.length; o++) {
    const a = e[o], c = Ns(n, a.path);
    let l = !1, h;
    if (P(c !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), a.status === 4)
      l = !0, h = a.abortReason, s = s.concat(bu(t.serverSyncTree_, a.currentWriteId, !0));
    else if (a.status === 0)
      if (a.retryCount >= qLe)
        l = !0, h = "maxretry", s = s.concat(bu(t.serverSyncTree_, a.currentWriteId, !0));
      else {
        const u = fne(t, a.path, i);
        a.currentInputSnapshot = u;
        const d = e[o].update(u.val());
        if (d !== void 0) {
          HP("transaction failed: Data returned ", d, a.path);
          let f = cr(d);
          typeof d == "object" && d != null && za(d, ".priority") || (f = f.updatePriority(u.getPriority()));
          const p = a.currentWriteId, _ = VP(t), m = ine(f, u, _);
          a.currentOutputSnapshotRaw = f, a.currentOutputSnapshotResolved = m, a.currentWriteId = mB(t), i.splice(i.indexOf(p), 1), s = s.concat(Xte(t.serverSyncTree_, a.path, m, a.currentWriteId, a.applyLocally)), s = s.concat(bu(t.serverSyncTree_, p, !0));
        } else
          l = !0, h = "nodata", s = s.concat(bu(t.serverSyncTree_, a.currentWriteId, !0));
      }
    to(t.eventQueue_, n, s), s = [], l && (e[o].status = 2, function(u) {
      setTimeout(u, Math.floor(0));
    }(e[o].unwatcher), e[o].onComplete && (h === "nodata" ? r.push(() => e[o].onComplete(null, !1, e[o].currentInputSnapshot)) : r.push(() => e[o].onComplete(new Error(h), !1, null))));
  }
  zP(t, t.transactionQueueTree_);
  for (let o = 0; o < r.length; o++)
    ag(r[o]);
  gB(t, t.transactionQueueTree_);
}
function pne(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = Ee(e); n !== null && lg(r) === void 0; )
    r = fB(r, n), e = tn(e), n = Ee(e);
  return r;
}
function _ne(t, e) {
  const n = [];
  return mne(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function mne(t, e, n) {
  const r = lg(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  qP(e, (s) => {
    mne(t, s, n);
  });
}
function zP(t, e) {
  const n = lg(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, one(e, n.length > 0 ? n : void 0);
  }
  qP(e, (r) => {
    zP(t, r);
  });
}
function yB(t, e) {
  const n = pb(pne(t, e)), r = fB(t.transactionQueueTree_, e);
  return RLe(r, (s) => {
    IM(t, s);
  }), IM(t, r), cne(r, (s) => {
    IM(t, s);
  }), n;
}
function IM(t, e) {
  const n = lg(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (P(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (P(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(bu(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? one(e, void 0) : n.length = i + 1, to(t.eventQueue_, pb(e), s);
    for (let o = 0; o < r.length; o++)
      ag(r[o]);
  }
}
function tUe(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function nUe(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : Ls(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const i$ = function(t, e) {
  const n = rUe(t), r = n.namespace;
  n.domain === "firebase.com" && vl(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && vl("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || u1e();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new Cte(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new Mt(n.pathString)
  };
}, rUe = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = tUe(t.substring(h, u)));
    const d = nUe(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class sUe {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + _r(this.snapshot.exportVal());
  }
}
class iUe {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class gne {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return P(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class vB {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return Fe(this._path) ? null : zW(this._path);
  }
  get ref() {
    return new Ll(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = jG(this._queryParams), n = qW(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return jG(this._queryParams);
  }
  isEqual(e) {
    if (e = ls(e), !(e instanceof vB))
      return !1;
    const n = this._repo === e._repo, r = GW(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + G1e(this._path);
  }
}
class Ll extends vB {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new QW(), !1);
  }
  get parent() {
    const e = Pte(this._path);
    return e === null ? null : new Ll(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class fC {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new Mt(e), r = iU(this.ref, e);
    return new fC(this._node.getChild(n), r, xn);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (n, r) => e(new fC(r, iU(this.ref, n), xn)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new Mt(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function jE(t, e) {
  return t = ls(t), t._checkNotDeleted("ref"), e !== void 0 ? iU(t._root, e) : t._root;
}
function iU(t, e) {
  return t = ls(t), Ee(t._path) === null ? LLe("child", "path", e) : une("child", "path", e), new Ll(t._repo, kn(t._path, e));
}
function oUe(t, e) {
  t = ls(t), ULe("set", t._path), ALe("set", e, t._path);
  const n = new ob();
  return $Le(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function aUe(t, e) {
  xLe("update", e, t._path);
  const n = new ob();
  return KLe(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function cUe(t) {
  t = ls(t);
  const e = new gne(() => {
  }), n = new GP(e);
  return GLe(t._repo, t, n).then((r) => new fC(r, new Ll(t._repo, t._path), t._queryParams.getIndex()));
}
class GP {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new sUe("value", this, new fC(e.snapshotNode, new Ll(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new iUe(this, e, n) : null;
  }
  matches(e) {
    return e instanceof GP ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function lUe(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      s$(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new gne(n, i || void 0), a = new GP(o);
  return QLe(t._repo, t, a), () => s$(t._repo, t, a);
}
function uUe(t, e, n, r) {
  return lUe(t, "value", e, n, r);
}
function hUe(t, ...e) {
  let n = ls(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
iLe(Ll);
uLe(Ll);
const dUe = "FIREBASE_DATABASE_EMULATOR_HOST", oU = {};
let fUe = !1;
function pUe(t, e, n, r) {
  t.repoInfo_ = new Cte(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function _Ue(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || vl("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), Kr("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = i$(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[dUe]), c ? (i = `http://${c}?ns=${a.namespace}`, o = i$(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new C1e(t.name, t.options, e);
  FLe("Invalid Firebase Database URL", o), Fe(o.path) || vl("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = gUe(a, t, l, new w1e(t.name, n));
  return new yUe(h, t);
}
function mUe(t, e) {
  const n = oU[e];
  (!n || n[t.key] !== t) && vl(`Database ${e}(${t.repoInfo_}) has already been deleted.`), ZLe(t), delete n[t.key];
}
function gUe(t, e, n, r) {
  let s = oU[e.name];
  s || (s = {}, oU[e.name] = s);
  let i = s[t.toURLString()];
  return i && vl("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new HLe(t, fUe, n, r), s[t.toURLString()] = i, i;
}
class yUe {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (jLe(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Ll(this._repo, lt())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (mUe(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && vl("Cannot call " + e + " on a deleted database.");
  }
}
function vUe(t = ste(), e) {
  const n = rte(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = Jee("database");
    r && wUe(n, ...r);
  }
  return n;
}
function wUe(t, e, n, r = {}) {
  t = ls(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && vl("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && vl('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new GS(GS.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : Qee(r.mockUserToken, t.app.options.projectId);
    i = new GS(o);
  }
  pUe(s, e, n, i);
}
function CUe(t) {
  s1e(cb), Ff(new Oh(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return _Ue(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), Zc(RG, kG, t), Zc(RG, kG, "esm2017");
}
hb.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
hb.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
CUe();
const yne = "firebasestorage.googleapis.com", vne = "storageBucket", TUe = 2 * 60 * 1e3, bUe = 10 * 60 * 1e3;
class Kn extends xl {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(RM(e), `Firebase Storage: ${n} (${RM(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, Kn.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return RM(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var qn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(qn || (qn = {}));
function RM(t) {
  return "storage/" + t;
}
function wB() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new Kn(qn.UNKNOWN, t);
}
function EUe(t) {
  return new Kn(qn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function SUe(t) {
  return new Kn(qn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function IUe() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new Kn(qn.UNAUTHENTICATED, t);
}
function RUe() {
  return new Kn(qn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function kUe(t) {
  return new Kn(qn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function NUe() {
  return new Kn(qn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function PUe() {
  return new Kn(qn.CANCELED, "User canceled the upload/download.");
}
function OUe(t) {
  return new Kn(qn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function DUe(t) {
  return new Kn(qn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function AUe() {
  return new Kn(qn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + vne + "' property when initializing the app?");
}
function MUe() {
  return new Kn(qn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function xUe() {
  return new Kn(qn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function LUe(t) {
  return new Kn(qn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function aU(t) {
  return new Kn(qn.INVALID_ARGUMENT, t);
}
function wne() {
  return new Kn(qn.APP_DELETED, "The Firebase app was deleted.");
}
function UUe(t) {
  return new Kn(qn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function Qv(t, e) {
  return new Kn(qn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function Sy(t) {
  throw new Kn(qn.INTERNAL_ERROR, "Internal error: " + t);
}
class bi {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = bi.makeFromUrl(e, n);
    } catch {
      return new bi(e, "");
    }
    if (r.path === "")
      return r;
    throw DUe(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(g) {
      g.path.charAt(g.path.length - 1) === "/" && (g.path_ = g.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(g) {
      g.path_ = decodeURIComponent(g.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === yne ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), y = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let g = 0; g < y.length; g++) {
      const w = y[g], de = w.regex.exec(e);
      if (de) {
        const we = de[w.indices.bucket];
        let fe = de[w.indices.path];
        fe || (fe = ""), r = new bi(we, fe), w.postModify(r);
        break;
      }
    }
    if (r == null)
      throw OUe(e);
    return r;
  }
}
class FUe {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function YUe(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let y;
    a === 1 ? (a = 2, y = 0) : y = (r + Math.random()) * 1e3, u(y);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function WUe(t) {
  t(!1);
}
function BUe(t) {
  return t !== void 0;
}
function qUe(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function CB(t) {
  return typeof t == "string" || t instanceof String;
}
function o$(t) {
  return TB() && t instanceof Blob;
}
function TB() {
  return typeof Blob < "u" && !FAe();
}
function a$(t, e, n, r) {
  if (r < e)
    throw aU(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw aU(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function $P(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function Cne(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var tf;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(tf || (tf = {}));
function HUe(t, e) {
  const n = t >= 500 && t < 600, r = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, s = e.indexOf(t) !== -1;
  return n || r || s;
}
class jUe {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new VE(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === tf.NO_ERROR, c = i.getStatus();
        if (!a || HUe(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === tf.ABORT;
          r(!1, new VE(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new VE(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          BUe(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = wB();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? wne() : PUe();
        o(c);
      } else {
        const c = NUe();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new VE(!1, null, !0)) : this.backoffId_ = YUe(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && WUe(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class VE {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function VUe(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function zUe(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function GUe(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function $Ue(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function KUe(t, e, n, r, s, i, o = !0) {
  const a = Cne(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return GUe(l, e), VUe(l, n), zUe(l, i), $Ue(l, r), new jUe(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function JUe() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function QUe(...t) {
  const e = JUe();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (TB())
      return new Blob(t);
    throw new Kn(qn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function ZUe(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function XUe(t) {
  if (typeof atob > "u")
    throw LUe("base-64");
  return atob(t);
}
const Ra = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class kM {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function eFe(t, e) {
  switch (t) {
    case Ra.RAW:
      return new kM(Tne(e));
    case Ra.BASE64:
    case Ra.BASE64URL:
      return new kM(bne(t, e));
    case Ra.DATA_URL:
      return new kM(nFe(e), rFe(e));
  }
  throw wB();
}
function Tne(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const s = r, i = t.charCodeAt(++n);
        r = 65536 | (s & 1023) << 10 | i & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function tFe(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw Qv(Ra.DATA_URL, "Malformed data URL.");
  }
  return Tne(e);
}
function bne(t, e) {
  switch (t) {
    case Ra.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw Qv(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case Ra.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw Qv(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = XUe(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : Qv(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class Ene {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw Qv(Ra.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = sFe(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function nFe(t) {
  const e = new Ene(t);
  return e.base64 ? bne(Ra.BASE64, e.rest) : tFe(e.rest);
}
function rFe(t) {
  return new Ene(t).contentType;
}
function sFe(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class mu {
  constructor(e, n) {
    let r = 0, s = "";
    o$(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (o$(this.data_)) {
      const r = this.data_, s = ZUe(r, e, n);
      return s === null ? null : new mu(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new mu(r, !0);
    }
  }
  static getBlob(...e) {
    if (TB()) {
      const n = e.map((r) => r instanceof mu ? r.data_ : r);
      return new mu(QUe.apply(null, n));
    } else {
      const n = e.map((o) => CB(o) ? eFe(Ra.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new mu(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function Sne(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return qUe(e) ? e : null;
}
function iFe(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function oFe(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function Ine(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function aFe(t, e) {
  return e;
}
class Ts {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || aFe;
  }
}
let zE = null;
function cFe(t) {
  return !CB(t) || t.length < 2 ? t : Ine(t);
}
function Rne() {
  if (zE)
    return zE;
  const t = [];
  t.push(new Ts("bucket")), t.push(new Ts("generation")), t.push(new Ts("metageneration")), t.push(new Ts("name", "fullPath", !0));
  function e(i, o) {
    return cFe(o);
  }
  const n = new Ts("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new Ts("size");
  return s.xform = r, t.push(s), t.push(new Ts("timeCreated")), t.push(new Ts("updated")), t.push(new Ts("md5Hash", null, !0)), t.push(new Ts("cacheControl", null, !0)), t.push(new Ts("contentDisposition", null, !0)), t.push(new Ts("contentEncoding", null, !0)), t.push(new Ts("contentLanguage", null, !0)), t.push(new Ts("contentType", null, !0)), t.push(new Ts("metadata", "customMetadata", !0)), zE = t, zE;
}
function lFe(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new bi(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function uFe(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return lFe(r, t), r;
}
function kne(t, e, n) {
  const r = Sne(e);
  return r === null ? null : uFe(t, r, n);
}
function hFe(t, e, n, r) {
  const s = Sne(e);
  if (s === null || !CB(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((a) => {
    const c = t.bucket, l = t.fullPath, h = "/b/" + o(c) + "/o/" + o(l), u = $P(h, n, r), d = Cne({
      alt: "media",
      token: a
    });
    return u + d;
  })[0];
}
function dFe(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class bB {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function Nne(t) {
  if (!t)
    throw wB();
}
function fFe(t, e) {
  function n(r, s) {
    const i = kne(t, s, e);
    return Nne(i !== null), i;
  }
  return n;
}
function pFe(t, e) {
  function n(r, s) {
    const i = kne(t, s, e);
    return Nne(i !== null), hFe(i, s, t.host, t._protocol);
  }
  return n;
}
function Pne(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? (
      /* This exact message string is the only consistent part of the */
      /* server's error response that identifies it as an App Check error. */
      n.getErrorText().includes("Firebase App Check token is invalid") ? s = RUe() : s = IUe()
    ) : n.getStatus() === 402 ? s = SUe(t.bucket) : n.getStatus() === 403 ? s = kUe(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function One(t) {
  const e = Pne(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = EUe(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function _Fe(t, e, n) {
  const r = e.fullServerUrl(), s = $P(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new bB(s, i, pFe(t, n), o);
  return a.errorHandler = One(e), a;
}
function mFe(t, e) {
  const n = e.fullServerUrl(), r = $P(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new bB(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = One(e), a;
}
function gFe(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function yFe(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = gFe(null, e)), r;
}
function vFe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = yFe(e, r, s), h = dFe(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = mu.getBlob(u, r, d);
  if (f === null)
    throw MUe();
  const p = { name: l.fullPath }, _ = $P(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new bB(_, m, fFe(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = Pne(e), y;
}
class wFe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = tf.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = tf.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = tf.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw Sy("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw Sy("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw Sy("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw Sy("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw Sy("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class CFe extends wFe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function EB() {
  return new CFe();
}
class qf {
  constructor(e, n) {
    this._service = e, n instanceof bi ? this._location = n : this._location = bi.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new qf(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new bi(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return Ine(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = iFe(this._location.path);
    if (e === null)
      return null;
    const n = new bi(this._location.bucket, e);
    return new qf(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw UUe(e);
  }
}
function TFe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = vFe(t.storage, t._location, Rne(), new mu(e, !0), n);
  return t.storage.makeRequestWithTokens(r, EB).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function bFe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = _Fe(t.storage, t._location, Rne());
  return t.storage.makeRequestWithTokens(e, EB).then((n) => {
    if (n === null)
      throw xUe();
    return n;
  });
}
function EFe(t) {
  t._throwIfRoot("deleteObject");
  const e = mFe(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, EB);
}
function SFe(t, e) {
  const n = oFe(t._location.path, e), r = new bi(t._location.bucket, n);
  return new qf(t.storage, r);
}
function IFe(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function RFe(t, e) {
  return new qf(t, e);
}
function Dne(t, e) {
  if (t instanceof SB) {
    const n = t;
    if (n._bucket == null)
      throw AUe();
    const r = new qf(n, n._bucket);
    return e != null ? Dne(r, e) : r;
  } else
    return e !== void 0 ? SFe(t, e) : t;
}
function kFe(t, e) {
  if (e && IFe(e)) {
    if (t instanceof SB)
      return RFe(t, e);
    throw aU("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return Dne(t, e);
}
function c$(t, e) {
  const n = e == null ? void 0 : e[vne];
  return n == null ? null : bi.makeFromBucketSpec(n, t);
}
function NFe(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : Qee(s, t.app.options.projectId));
}
class SB {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = yne, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = TUe, this._maxUploadRetryTime = bUe, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = bi.makeFromBucketSpec(s, this._host) : this._bucket = c$(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = bi.makeFromBucketSpec(this._url, e) : this._bucket = c$(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    a$(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    a$(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new qf(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new FUe(wne());
    {
      const o = KUe(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const l$ = "@firebase/storage", u$ = "0.11.2", Ane = "storage";
function PFe(t, e, n) {
  return t = ls(t), TFe(t, e, n);
}
function OFe(t) {
  return t = ls(t), bFe(t);
}
function DFe(t) {
  return t = ls(t), EFe(t);
}
function NM(t, e) {
  return t = ls(t), kFe(t, e);
}
function AFe(t = ste(), e) {
  t = ls(t);
  const n = rte(t, Ane).getImmediate({
    identifier: e
  }), r = Jee("storage");
  return r && MFe(n, ...r), n;
}
function MFe(t, e, n, r = {}) {
  NFe(t, e, n, r);
}
function xFe(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new SB(n, r, s, e, cb);
}
function LFe() {
  Ff(new Oh(
    Ane,
    xFe,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), Zc(l$, u$, ""), Zc(l$, u$, "esm2017");
}
LFe();
class Mne {
  constructor() {
    this.db = vUe(), this.storage = AFe();
  }
  async get(e) {
    let n = await cUe(jE(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await aUe(jE(this.db, e), n);
  }
  async set(e, n) {
    return await oUe(jE(this.db, e), n);
  }
  async watch(e, n, r) {
    return uUe(hUe(jE(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await PFe(NM(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await DFe(NM(this.storage, e));
  }
  async objectUrl(e) {
    return await OFe(NM(this.storage, e));
  }
}
class UFe extends Mne {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch(Gp.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set(Gp.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set(Gp.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get(Gp.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get(Gp.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
const FFe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, YFe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, WFe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, BFe = {
  localhost: FFe,
  hybrid: YFe,
  ihunterdev: WFe,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, qFe = !1;
class I_ {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return BFe[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && qFe ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  static firebaseConfig() {
    var e;
    if (!process)
      return process && ((e = process == null ? void 0 : process.env) == null ? void 0 : e.FIREBASE_CONFIG) && JSON.parse(process.env.FIREBASE_CONFIG);
  }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class GE extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const gi = "application/json", HFe = "application/octet-stream";
class xne {
  constructor() {
  }
  get apiServer() {
    return I_.config.functionURL;
  }
  get tileServer() {
    return I_.config.tileURL;
  }
  get webServer() {
    return I_.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let s = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || gi,
        Accept: r || gi
      }
    };
    return this.request(e, s);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: gi
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let i = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || gi,
        Accept: s || gi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async patch(e, n, r, s) {
    let i = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || gi,
        Accept: s || gi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async post(e, n, r, s) {
    let i = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || gi,
        Accept: s || gi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async delete(e, n, r) {
    let s = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || gi,
        Accept: r || gi
      }
    };
    return this.request(e, s);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new GE(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(gi)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new GE(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(gi) ? r(await i.json()) : o && o.startsWith(HFe) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new GE(500, i.message, i))) : s(new GE(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class jFe {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var KS = { exports: {} };
function VFe(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
KS.exports = VFe;
const K = (KS.exports == null ? {} : KS.exports).default || KS.exports, su = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, h$ = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class Li {
  static TileServer() {
    return I_.config.tileURL;
  }
  static WebServer() {
    return I_.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return su.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return K(su.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return K(su.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return K(su.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + K(h$.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), K(su.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), K(su.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + K(h$.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return K(su.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return K(su.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const $s = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, PM = {
  REGIONS: "/product/regions/{region}"
}, Ks = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, d$ = {
  PRODUCT: "/product/skus/{product}"
}, f$ = {
  PRODUCT: "/product/prices/{product}"
}, zFe = {
  PROMOTIONS: "/store/promotions"
};
class GFe {
  static AllRegions() {
    return OM(K(PM.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return PM.REGIONS;
  }
  static Regions(e) {
    return K(PM.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Ks.REGION;
  }
  static Region(e) {
    return K(Ks.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return $s.REGION;
  }
  static RegionMetadata(e) {
    return K($s.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Ks.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return K(Ks.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return $s.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return K($s.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Ks.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return K(Ks.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return $s.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return K($s.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Ks.RESOURCE;
  }
  static Resource(e, n) {
    return K(Ks.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return $s.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return K($s.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Ks.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return K(Ks.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return $s.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return K($s.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Ks.MAP;
  }
  static Map(e, n, r, s) {
    return K(Ks.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return $s.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return K($s.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Ks.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return K(Ks.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return $s.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return K($s.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return OM(K(d$.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return K(d$.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return OM(K(f$.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return K(f$.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return zFe.PROMOTIONS;
  }
}
const OM = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class $Fe {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const p$ = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, _$ = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, m$ = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, g$ = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, DM = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, y$ = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, KFe = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
class _a {
  static RegionPropertyMap() {
    return p$.METADATA;
  }
  static SubscriptionPropertyMap() {
    return _$.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return m$.METADATA;
  }
  static ResourcePropertyMap() {
    return g$.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return DM.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return DM.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return y$.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties(p$.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(_$.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(m$.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(g$.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(DM.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(y$.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(KFe.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
}
var JS = { exports: {} };
function JFe(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
JS.exports = JFe;
const QS = (JS.exports == null ? {} : JS.exports).default || JS.exports;
var ZS = { exports: {} };
async function QFe(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
ZS.exports = QFe;
const cU = (ZS.exports == null ? {} : ZS.exports).default || ZS.exports;
Promise.allMap = cU;
class ZFe {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, _a.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, _a.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, _a.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, _a.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, _a.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[_a.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, _a.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = QS(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = QS(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[_a.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, _a.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = QS(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class XFe extends $Fe {
  constructor(e) {
    super(e), this.http = new xne(), this.builder = new ZFe(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(Li.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(Li.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(Li.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Li.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(Li.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Li.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(Li.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + Li.CountyMetadata(e)), await this.http.getJson(Li.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + Li.CountyProductMetadata(e, n)), await this.http.getJson(Li.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const Iy = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class eYe {
  static Apple(e) {
    return K(Iy.APPLE, { uid: e });
  }
  static Android(e) {
    return K(Iy.ANDROID, { uid: e });
  }
  static Web(e) {
    return K(Iy.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return K(Iy.WEB, { uid: e }) + K(Iy.SKU, { region: n, sku: r });
  }
}
const Hr = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, Ry = {
  CUSTOMER: `/${Hr.USERS}/{uid}/${Hr.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${Hr.USERS}/{uid}/${Hr.SUBSCRIPTIONS}`,
  PRODUCT: `/${Hr.PRODUCTS}/{product}`,
  PRICE: `/${Hr.PRODUCTS}/{product}/${Hr.PRICES}/{price}`
};
class tYe {
  static Customer(e, n) {
    return K(Ry.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return K(Ry.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return Ry.CUSTOMER;
  }
  static PriceRoute() {
    return Ry.PRICE;
  }
  static ProductRoute() {
    return Ry.PRODUCT;
  }
  static Users() {
    return Hr.USERS;
  }
  static Customers() {
    return Hr.CUSTOMERS;
  }
  static Subscriptions() {
    return Hr.SUBSCRIPTIONS;
  }
  static Schedules() {
    return Hr.SCHEDULES;
  }
  static Products() {
    return Hr.PRODUCTS;
  }
  static Prices() {
    return Hr.PRICES;
  }
  static Promotions() {
    return Hr.PROMOTIONS;
  }
  static Payments() {
    return Hr.PAYMENTS;
  }
  static Coupons() {
    return Hr.COUPONS;
  }
  static Invoices() {
    return Hr.INVOICES;
  }
}
const sd = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class $p {
  static Preferences(e) {
    return K(sd.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return K(sd.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return sd.OPT_IN;
  }
  static Status(e) {
    return K(sd.STATUS, { uid: e });
  }
  static StatusRoute() {
    return sd.STATUS;
  }
  static Email(e) {
    return K(sd.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return sd.EMAIL;
  }
}
const v$ = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class nYe {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, v$.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * v$.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const id = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, AM = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, w$ = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, MM = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, C$ = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, ky = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, od = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, T$ = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class IB {
  static OutgoingContactRequestProcessedRoute() {
    return id.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return K(id.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return K(id.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return id.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return K(id.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return id.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return K(id.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return AM.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return K(AM.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return K(AM.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return K(w$.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return w$.DATA;
  }
  static Chat(e) {
    return K(MM.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var r, s;
    const i = Date.now();
    let o = e.length > 2, a = {}, c = {}, l = {};
    for (let u of e)
      a[u] = !0, c[u] = ((r = n == null ? void 0 : n.lastviewed) == null ? void 0 : r[u]) || i, l[u] = ((s = n == null ? void 0 : n.lastcleared) == null ? void 0 : s[u]) || i;
    let h = {
      participants: a,
      isgroupchat: o,
      lastviewed: c,
      lastcleared: l
    };
    return o && (h.chatname = MM.GROUP_NAME), h;
  }
  static ChatParticipants(e) {
    return K(MM.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return K(C$.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return C$.DATA;
  }
  static MessageRoute() {
    return ky.ROUTE;
  }
  static Message(e, n) {
    const r = IB.MessageKey(n);
    return K(ky.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: od.NOTIFY_ALL,
      type: ky.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return K(ky.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return ky.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return K(od.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return K(od.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === od.NOTIFY_ALL ? od.TITLE_FROM_NOTIFY_ALL : K(od.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return K(T$.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return od.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return T$.UNKNOWN_NAME;
  }
}
const Lne = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
}, O = function(t, e) {
  if (!t)
    throw ug(e);
}, ug = function(t) {
  return new Error("Firebase Database (" + Lne.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
}, Une = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, rYe = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, RB = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(Une(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : rYe(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], o = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const a = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const c = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || o == null || a == null || c == null)
        throw new sYe();
      const l = i << 2 | o >> 4;
      if (r.push(l), a !== 64) {
        const h = o << 4 & 240 | a >> 2;
        if (r.push(h), c !== 64) {
          const u = a << 6 & 192 | c;
          r.push(u);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class sYe extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const Fne = function(t) {
  const e = Une(t);
  return RB.encodeByteArray(e, !0);
}, NR = function(t) {
  return Fne(t).replace(/\./g, "");
}, PR = function(t) {
  try {
    return RB.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function iYe(t) {
  return Yne(void 0, t);
}
function Yne(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !oYe(n) || (t[n] = Yne(t[n], e[n]));
  return t;
}
function oYe(t) {
  return t !== "__proto__";
}
function aYe() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const cYe = () => aYe().__FIREBASE_DEFAULTS__, lYe = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, uYe = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && PR(t[1]);
  return e && JSON.parse(e);
}, KP = () => {
  try {
    return cYe() || lYe() || uYe();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, hYe = (t) => {
  var e, n;
  return (n = (e = KP()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, Wne = (t) => {
  const e = hYe(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, dYe = () => {
  var t;
  return (t = KP()) === null || t === void 0 ? void 0 : t.config;
}, fYe = (t) => {
  var e;
  return (e = KP()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
class mb {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function Bne(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    NR(JSON.stringify(n)),
    NR(JSON.stringify(o)),
    ""
  ].join(".");
}
function xa() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function kB() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(xa());
}
function pYe() {
  var t;
  const e = (t = KP()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function _Ye() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function qne() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function Hne() {
  return Lne.NODE_ADMIN === !0;
}
function mYe() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function gYe() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const yYe = "FirebaseError";
class Ul extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = yYe, Object.setPrototypeOf(this, Ul.prototype), Error.captureStackTrace && Error.captureStackTrace(this, gb.prototype.create);
  }
}
class gb {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? vYe(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new Ul(s, a, r);
  }
}
function vYe(t, e) {
  return t.replace(wYe, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const wYe = /\{\$([^}]+)}/g;
function pC(t) {
  return JSON.parse(t);
}
function mr(t) {
  return JSON.stringify(t);
}
const jne = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = pC(PR(i[0]) || ""), n = pC(PR(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, CYe = function(t) {
  const e = jne(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, TYe = function(t) {
  const e = jne(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function Ga(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function Nm(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function b$(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function OR(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function lU(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (E$(i) && E$(o)) {
      if (!lU(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function E$(t) {
  return t !== null && typeof t == "object";
}
function NB(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function Tv(t) {
  const e = {};
  return t.replace(/^\?/, "").split("&").forEach((n) => {
    if (n) {
      const [r, s] = n.split("=");
      e[decodeURIComponent(r)] = decodeURIComponent(s);
    }
  }), e;
}
function bv(t) {
  const e = t.indexOf("?");
  if (!e)
    return "";
  const n = t.indexOf("#", e);
  return t.substring(e, n > 0 ? n : void 0);
}
class bYe {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function EYe(t, e) {
  const n = new SYe(t, e);
  return n.subscribe.bind(n);
}
class SYe {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    IYe(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = xM), s.error === void 0 && (s.error = xM), s.complete === void 0 && (s.complete = xM);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function IYe(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function xM() {
}
function JP(t, e) {
  return `${t} failed: ${e} argument `;
}
const RYe = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, O(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, QP = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function us(t) {
  return t && t._delegate ? t._delegate : t;
}
class xh {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const Rd = "[DEFAULT]";
class kYe {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new mb();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (PYe(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: Rd });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = Rd) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = Rd) {
    return this.instances.has(e);
  }
  getOptions(e = Rd) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: NYe(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = Rd) {
    return this.component ? this.component.multipleInstances ? e : Rd : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function NYe(t) {
  return t === Rd ? void 0 : t;
}
function PYe(t) {
  return t.instantiationMode === "EAGER";
}
class OYe {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new kYe(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var Nt;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(Nt || (Nt = {}));
const DYe = {
  debug: Nt.DEBUG,
  verbose: Nt.VERBOSE,
  info: Nt.INFO,
  warn: Nt.WARN,
  error: Nt.ERROR,
  silent: Nt.SILENT
}, AYe = Nt.INFO, MYe = {
  [Nt.DEBUG]: "log",
  [Nt.VERBOSE]: "log",
  [Nt.INFO]: "info",
  [Nt.WARN]: "warn",
  [Nt.ERROR]: "error"
}, xYe = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = MYe[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class PB {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = AYe, this._logHandler = xYe, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in Nt))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? DYe[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, Nt.DEBUG, ...e), this._logHandler(this, Nt.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, Nt.VERBOSE, ...e), this._logHandler(this, Nt.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, Nt.INFO, ...e), this._logHandler(this, Nt.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, Nt.WARN, ...e), this._logHandler(this, Nt.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, Nt.ERROR, ...e), this._logHandler(this, Nt.ERROR, ...e);
  }
}
const LYe = (t, e) => e.some((n) => t instanceof n);
let S$, I$;
function UYe() {
  return S$ || (S$ = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function FYe() {
  return I$ || (I$ = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const Vne = /* @__PURE__ */ new WeakMap(), uU = /* @__PURE__ */ new WeakMap(), zne = /* @__PURE__ */ new WeakMap(), LM = /* @__PURE__ */ new WeakMap(), OB = /* @__PURE__ */ new WeakMap();
function YYe(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(th(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && Vne.set(n, t);
  }).catch(() => {
  }), OB.set(e, t), e;
}
function WYe(t) {
  if (uU.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  uU.set(t, e);
}
let hU = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return uU.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || zne.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return th(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function BYe(t) {
  hU = t(hU);
}
function qYe(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(UM(this), e, ...n);
    return zne.set(r, e.sort ? e.sort() : [e]), th(r);
  } : FYe().includes(t) ? function(...e) {
    return t.apply(UM(this), e), th(Vne.get(this));
  } : function(...e) {
    return th(t.apply(UM(this), e));
  };
}
function HYe(t) {
  return typeof t == "function" ? qYe(t) : (t instanceof IDBTransaction && WYe(t), LYe(t, UYe()) ? new Proxy(t, hU) : t);
}
function th(t) {
  if (t instanceof IDBRequest)
    return YYe(t);
  if (LM.has(t))
    return LM.get(t);
  const e = HYe(t);
  return e !== t && (LM.set(t, e), OB.set(e, t)), e;
}
const UM = (t) => OB.get(t);
function jYe(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = th(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(th(o.result), c.oldVersion, c.newVersion, th(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const VYe = ["get", "getKey", "getAll", "getAllKeys", "count"], zYe = ["put", "add", "delete", "clear"], FM = /* @__PURE__ */ new Map();
function R$(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (FM.get(e))
    return FM.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = zYe.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || VYe.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return FM.set(e, i), i;
}
BYe((t) => ({
  ...t,
  get: (e, n, r) => R$(e, n) || t.get(e, n, r),
  has: (e, n) => !!R$(e, n) || t.has(e, n)
}));
class GYe {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((e) => {
      if ($Ye(e)) {
        const n = e.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((e) => e).join(" ");
  }
}
function $Ye(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const dU = "@firebase/app", k$ = "0.9.8", Hf = new PB("@firebase/app"), KYe = "@firebase/app-compat", JYe = "@firebase/analytics-compat", QYe = "@firebase/analytics", ZYe = "@firebase/app-check-compat", XYe = "@firebase/app-check", e2e = "@firebase/auth", t2e = "@firebase/auth-compat", n2e = "@firebase/database", r2e = "@firebase/database-compat", s2e = "@firebase/functions", i2e = "@firebase/functions-compat", o2e = "@firebase/installations", a2e = "@firebase/installations-compat", c2e = "@firebase/messaging", l2e = "@firebase/messaging-compat", u2e = "@firebase/performance", h2e = "@firebase/performance-compat", d2e = "@firebase/remote-config", f2e = "@firebase/remote-config-compat", p2e = "@firebase/storage", _2e = "@firebase/storage-compat", m2e = "@firebase/firestore", g2e = "@firebase/firestore-compat", y2e = "firebase", v2e = "9.20.0", fU = "[DEFAULT]", w2e = {
  [dU]: "fire-core",
  [KYe]: "fire-core-compat",
  [QYe]: "fire-analytics",
  [JYe]: "fire-analytics-compat",
  [XYe]: "fire-app-check",
  [ZYe]: "fire-app-check-compat",
  [e2e]: "fire-auth",
  [t2e]: "fire-auth-compat",
  [n2e]: "fire-rtdb",
  [r2e]: "fire-rtdb-compat",
  [s2e]: "fire-fn",
  [i2e]: "fire-fn-compat",
  [o2e]: "fire-iid",
  [a2e]: "fire-iid-compat",
  [c2e]: "fire-fcm",
  [l2e]: "fire-fcm-compat",
  [u2e]: "fire-perf",
  [h2e]: "fire-perf-compat",
  [d2e]: "fire-rc",
  [f2e]: "fire-rc-compat",
  [p2e]: "fire-gcs",
  [_2e]: "fire-gcs-compat",
  [m2e]: "fire-fst",
  [g2e]: "fire-fst-compat",
  "fire-js": "fire-js",
  [y2e]: "fire-js-all"
}, DR = /* @__PURE__ */ new Map(), pU = /* @__PURE__ */ new Map();
function C2e(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    Hf.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function jf(t) {
  const e = t.name;
  if (pU.has(e))
    return Hf.debug(`There were multiple attempts to register component ${e}.`), !1;
  pU.set(e, t);
  for (const n of DR.values())
    C2e(n, t);
  return !0;
}
function Gne(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const T2e = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, nh = new gb("app", "Firebase", T2e);
class b2e {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new xh(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw nh.create("app-deleted", { appName: this._name });
  }
}
const yb = v2e;
function E2e(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: fU, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw nh.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = dYe()), !n)
    throw nh.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = DR.get(s);
  if (i) {
    if (lU(n, i.options) && lU(r, i.config))
      return i;
    throw nh.create("duplicate-app", { appName: s });
  }
  const o = new OYe(s);
  for (const c of pU.values())
    o.addComponent(c);
  const a = new b2e(n, r, o);
  return DR.set(s, a), a;
}
function $ne(t = fU) {
  const e = DR.get(t);
  if (!e && t === fU)
    return E2e();
  if (!e)
    throw nh.create("no-app", { appName: t });
  return e;
}
function Xc(t, e, n) {
  var r;
  let s = (r = w2e[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), Hf.warn(a.join(" "));
    return;
  }
  jf(new xh(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const S2e = "firebase-heartbeat-database", I2e = 1, _C = "firebase-heartbeat-store";
let YM = null;
function Kne() {
  return YM || (YM = jYe(S2e, I2e, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(_C);
      }
    }
  }).catch((t) => {
    throw nh.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), YM;
}
async function R2e(t) {
  try {
    return (await Kne()).transaction(_C).objectStore(_C).get(Jne(t));
  } catch (e) {
    if (e instanceof Ul)
      Hf.warn(e.message);
    else {
      const n = nh.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      Hf.warn(n.message);
    }
  }
}
async function N$(t, e) {
  try {
    const n = (await Kne()).transaction(_C, "readwrite");
    return await n.objectStore(_C).put(e, Jne(t)), n.done;
  } catch (n) {
    if (n instanceof Ul)
      Hf.warn(n.message);
    else {
      const r = nh.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      Hf.warn(r.message);
    }
  }
}
function Jne(t) {
  return `${t.name}!${t.options.appId}`;
}
const k2e = 1024, N2e = 30 * 24 * 60 * 60 * 1e3;
class P2e {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new D2e(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const e = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), n = P$();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === n || this._heartbeatsCache.heartbeats.some((r) => r.date === n)))
      return this._heartbeatsCache.heartbeats.push({ date: n, agent: e }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const s = new Date(r.date).valueOf();
        return Date.now() - s <= N2e;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = P$(), { heartbeatsToSend: n, unsentEntries: r } = O2e(this._heartbeatsCache.heartbeats), s = NR(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function P$() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function O2e(t, e = k2e) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), O$(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), O$(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class D2e {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return mYe() ? gYe().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await R2e(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return N$(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return N$(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function O$(t) {
  return NR(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function A2e(t) {
  jf(new xh(
    "platform-logger",
    (e) => new GYe(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), jf(new xh(
    "heartbeat",
    (e) => new P2e(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Xc(dU, k$, t), Xc(dU, k$, "esm2017"), Xc("fire-js", "");
}
A2e("");
const D$ = "@firebase/database", A$ = "0.14.4";
let Qne = "";
function M2e(t) {
  Qne = t;
}
class x2e {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), mr(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : pC(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class L2e {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return Ga(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const Zne = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new x2e(e);
    }
  } catch {
  }
  return new L2e();
}, Ud = Zne("localStorage"), U2e = Zne("sessionStorage"), R_ = new PB("@firebase/database"), F2e = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), Xne = function(t) {
  const e = RYe(t), n = new bYe();
  n.update(e);
  const r = n.digest();
  return RB.encodeByteArray(r);
}, vb = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += vb.apply(null, r) : typeof r == "object" ? e += mr(r) : e += r, e += " ";
  }
  return e;
};
let Zv = null, M$ = !0;
const Y2e = function(t, e) {
  O(!e, "Can't turn on custom loggers persistently."), R_.logLevel = Nt.VERBOSE, Zv = R_.log.bind(R_);
}, Jr = function(...t) {
  if (M$ === !0 && (M$ = !1, Zv === null && U2e.get("logging_enabled") === !0 && Y2e()), Zv) {
    const e = vb.apply(null, t);
    Zv(e);
  }
}, wb = function(t) {
  return function(...e) {
    Jr(t, ...e);
  };
}, _U = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + vb(...t);
  R_.error(e);
}, wl = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${vb(...t)}`;
  throw R_.error(e), new Error(e);
}, Us = function(...t) {
  const e = "FIREBASE WARNING: " + vb(...t);
  R_.warn(e);
}, W2e = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && Us("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, DB = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, B2e = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, Pm = "[MIN_NAME]", Vf = "[MAX_NAME]", pp = function(t, e) {
  if (t === e)
    return 0;
  if (t === Pm || e === Vf)
    return -1;
  if (e === Pm || t === Vf)
    return 1;
  {
    const n = x$(t), r = x$(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, q2e = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, Ny = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + mr(e));
}, AB = function(t) {
  if (typeof t != "object" || t === null)
    return mr(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += mr(e[r]), n += ":", n += AB(t[e[r]]);
  return n += "}", n;
}, ere = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function rs(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const tre = function(t) {
  O(!DB(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, H2e = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, j2e = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function V2e(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const z2e = new RegExp("^-?(0*)\\d{1,10}$"), G2e = -2147483648, $2e = 2147483647, x$ = function(t) {
  if (z2e.test(t)) {
    const e = Number(t);
    if (e >= G2e && e <= $2e)
      return e;
  }
  return null;
}, hg = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw Us("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, K2e = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, Xv = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class J2e {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    Us(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class Q2e {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? (Jr("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', Us(e);
  }
}
class XS {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
XS.OWNER = "owner";
const MB = "5", nre = "v", rre = "s", sre = "r", ire = "f", ore = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, are = "ls", cre = "p", mU = "ac", lre = "websocket", ure = "long_polling";
class hre {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = Ud.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && Ud.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function Z2e(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function dre(t, e, n) {
  O(typeof e == "string", "typeof type must == string"), O(typeof n == "object", "typeof params must == object");
  let r;
  if (e === lre)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === ure)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  Z2e(t) && (n.ns = t.namespace);
  const s = [];
  return rs(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class X2e {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    Ga(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return iYe(this.counters_);
  }
}
const WM = {}, BM = {};
function xB(t) {
  const e = t.toString();
  return WM[e] || (WM[e] = new X2e()), WM[e];
}
function eWe(t, e) {
  const n = t.toString();
  return BM[n] || (BM[n] = e()), BM[n];
}
class tWe {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && hg(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const L$ = "start", nWe = "close", rWe = "pLPCommand", sWe = "pRTLPCB", fre = "id", pre = "pw", _re = "ser", iWe = "cb", oWe = "seg", aWe = "ts", cWe = "d", lWe = "dframe", mre = 1870, gre = 30, uWe = mre - gre, hWe = 25e3, dWe = 3e4;
class a_ {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = wb(e), this.stats_ = xB(n), this.urlFn = (c) => (this.appCheckToken && (c[mU] = this.appCheckToken), dre(n, ure, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new tWe(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(dWe)), B2e(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new LB((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === L$)
            this.id = a, this.password = c;
          else if (o === nWe)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[L$] = "t", r[_re] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[iWe] = this.scriptTagHolder.uniqueCallbackIdentifier), r[nre] = MB, this.transportSessionId && (r[rre] = this.transportSessionId), this.lastSessionId && (r[are] = this.lastSessionId), this.applicationId && (r[cre] = this.applicationId), this.appCheckToken && (r[mU] = this.appCheckToken), typeof location < "u" && location.hostname && ore.test(location.hostname) && (r[sre] = ire);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    a_.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    a_.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return a_.forceAllow_ ? !0 : !a_.forceDisallow_ && typeof document < "u" && document.createElement != null && !H2e() && !j2e();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = mr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = Fne(n), s = ere(r, uWe);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[lWe] = "t", r[fre] = e, r[pre] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = mr(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
}
class LB {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = F2e(), window[rWe + this.uniqueCallbackIdentifier] = e, window[sWe + this.uniqueCallbackIdentifier] = n, this.myIFrame = LB.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        Jr("frame writing exception"), a.stack && Jr(a.stack), Jr(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || Jr("No IE domain setting required");
      } catch {
        const n = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + n + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[fre] = this.myID, e[pre] = this.myPW, e[_re] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + gre + r.length <= mre; ) {
        const i = this.pendingSegs.shift();
        r = r + "&" + oWe + s + "=" + i.seg + "&" + aWe + s + "=" + i.ts + "&" + cWe + s + "=" + i.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(hWe)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          Jr("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const fWe = 16384, pWe = 45e3;
let AR = null;
typeof MozWebSocket < "u" ? AR = MozWebSocket : typeof WebSocket < "u" && (AR = WebSocket);
class So {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = wb(this.connId), this.stats_ = xB(n), this.connURL = So.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[nre] = MB, typeof location < "u" && location.hostname && ore.test(location.hostname) && (o[sre] = ire), n && (o[rre] = n), r && (o[are] = r), s && (o[mU] = s), i && (o[cre] = i), dre(e, lre, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, Ud.set("previous_websocket_failure", !0);
    try {
      let r;
      Hne(), this.mySock = new AR(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    So.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && AR !== null && !So.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return Ud.isInMemoryStorage || Ud.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    Ud.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = pC(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (O(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = mr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = ere(n, fWe);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(pWe));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
So.responsesRequiredToBeHealthy = 2;
So.healthyTimeout = 3e4;
class mC {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [a_, So];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = So && So.isAvailable();
    let r = n && !So.previouslyFailed();
    if (e.webSocketOnly && (n || Us("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [So];
    else {
      const s = this.transports_ = [];
      for (const i of mC.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      mC.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
mC.globalTransportInitialized_ = !1;
const _We = 6e4, mWe = 5e3, gWe = 10 * 1024, yWe = 100 * 1024, qM = "t", U$ = "d", vWe = "s", F$ = "r", wWe = "e", Y$ = "o", W$ = "a", B$ = "n", q$ = "p", CWe = "h";
class TWe {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = wb("c:" + this.id + ":"), this.transportManager_ = new mC(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = Xv(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > yWe ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > gWe ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (qM in e) {
      const n = e[qM];
      n === W$ ? this.upgradeIfSecondaryHealthy_() : n === F$ ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === Y$ && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = Ny("t", e), r = Ny("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: q$, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: W$, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: B$, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = Ny("t", e), r = Ny("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = Ny(qM, e);
    if (U$ in e) {
      const r = e[U$];
      if (n === CWe) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === B$) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === vWe ? this.onConnectionShutdown_(r) : n === F$ ? this.onReset_(r) : n === wWe ? _U("Server Error: " + r) : n === Y$ ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : _U("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), MB !== r && Us("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), Xv(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(_We));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : Xv(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(mWe));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: q$, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (Ud.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class yre {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class vre {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, O(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    O(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class MR extends vre {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !kB() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new MR();
  }
  getInitialEvent(e) {
    return O(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const H$ = 32, j$ = 768;
class xt {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function ut() {
  return new xt("");
}
function Se(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function Lh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function nn(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new xt(t.pieces_, e);
}
function UB(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function bWe(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function gC(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function wre(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new xt(e, 0);
}
function Nn(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof xt)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new xt(n, 0);
}
function Ye(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function Ps(t, e) {
  const n = Se(t), r = Se(e);
  if (n === null)
    return e;
  if (n === r)
    return Ps(nn(t), nn(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function EWe(t, e) {
  const n = gC(t, 0), r = gC(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = pp(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function FB(t, e) {
  if (Lh(t) !== Lh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function Ki(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (Lh(t) > Lh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class SWe {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = gC(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += QP(this.parts_[r]);
    Cre(this);
  }
}
function IWe(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += QP(e), Cre(t);
}
function RWe(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= QP(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function Cre(t) {
  if (t.byteLength_ > j$)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + j$ + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > H$)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + H$ + ") or object contains a cycle " + kd(t));
}
function kd(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class YB extends vre {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new YB();
  }
  getInitialEvent(e) {
    return O(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const Py = 1e3, kWe = 60 * 5 * 1e3, V$ = 30 * 1e3, NWe = 1.3, PWe = 3e4, OWe = "server_kill", z$ = 3;
class el extends yre {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = el.nextPersistentConnectionId_++, this.log_ = wb("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = Py, this.maxReconnectDelay_ = kWe, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !Hne())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    YB.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && MR.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(mr(i)), O(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new mb(), r = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (i) => {
        const o = i.d;
        i.s === "ok" ? n.resolve(o) : n.reject(o);
      }
    };
    this.outstandingGets_.push(r), this.outstandingGetCount_++;
    const s = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(s), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), O(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), O(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      el.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && Ga(e, "w")) {
      const r = Nm(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        Us(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || TYe(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = V$);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = CYe(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), O(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const s = r.d;
            this.log_("reportStats", "Error sending stats: " + s);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + mr(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : _U("Unrecognized action received from server: " + mr(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    O(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = Py, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = Py, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > PWe && (this.reconnectDelay_ = Py), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * NWe);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + el.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        O(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? Jr("getToken() completed but was canceled") : (Jr("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new TWe(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            Us(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(OWe);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && Us(u), c());
      }
    }
  }
  interrupt(e) {
    Jr("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    Jr("Resuming connection for reason: " + e), delete this.interruptReasons_[e], b$(this.interruptReasons_) && (this.reconnectDelay_ = Py, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => AB(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new xt(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    Jr("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= z$ && (this.reconnectDelay_ = V$, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    Jr("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= z$ && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + Qne.replace(/\./g, "-")] = 1, kB() ? e["framework.cordova"] = 1 : qne() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = MR.getInstance().currentlyOnline();
    return b$(this.interruptReasons_) && e;
  }
}
el.nextPersistentConnectionId_ = 0;
el.nextConnectionId_ = 0;
class De {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new De(e, n);
  }
}
class ZP {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new De(Pm, e), s = new De(Pm, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return De.MIN;
  }
}
let $E;
class Tre extends ZP {
  static get __EMPTY_NODE() {
    return $E;
  }
  static set __EMPTY_NODE(e) {
    $E = e;
  }
  compare(e, n) {
    return pp(e.name, n.name);
  }
  isDefinedOn(e) {
    throw ug("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return De.MIN;
  }
  maxPost() {
    return new De(Vf, $E);
  }
  makePost(e, n) {
    return O(typeof e == "string", "KeyIndex indexValue must always be a string."), new De(e, $E);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const k_ = new Tre();
class KE {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class Lr {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? Lr.RED, this.left = s ?? ui.EMPTY_NODE, this.right = i ?? ui.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new Lr(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return ui.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return ui.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, Lr.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, Lr.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
Lr.RED = !0;
Lr.BLACK = !1;
class DWe {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new Lr(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class ui {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = ui.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new ui(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, Lr.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new ui(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, Lr.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new KE(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new KE(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new KE(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new KE(this.root_, null, this.comparator_, !0, e);
  }
}
ui.EMPTY_NODE = new DWe();
function AWe(t, e) {
  return pp(t.name, e.name);
}
function WB(t, e) {
  return pp(t, e);
}
let gU;
function MWe(t) {
  gU = t;
}
const bre = function(t) {
  return typeof t == "number" ? "number:" + tre(t) : "string:" + t;
}, Ere = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    O(typeof e == "string" || typeof e == "number" || typeof e == "object" && Ga(e, ".sv"), "Priority must be a string or number.");
  } else
    O(t === gU || t.isEmpty(), "priority of unexpected type.");
  O(t === gU || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let G$;
class Nr {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = Nr.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, O(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), Ere(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    G$ = e;
  }
  static get __childrenNodeConstructor() {
    return G$;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new Nr(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : Nr.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return Ye(e) ? this : Se(e) === ".priority" ? this.priorityNode_ : Nr.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : Nr.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Se(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (O(r !== ".priority" || Lh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, Nr.__childrenNodeConstructor.EMPTY_NODE.updateChild(nn(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + bre(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += tre(this.value_) : e += this.value_, this.lazyHash_ = Xne(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === Nr.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof Nr.__childrenNodeConstructor ? -1 : (O(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = Nr.VALUE_TYPE_ORDER.indexOf(n), i = Nr.VALUE_TYPE_ORDER.indexOf(r);
    return O(s >= 0, "Unknown leaf type: " + n), O(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
Nr.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let Sre, Ire;
function xWe(t) {
  Sre = t;
}
function LWe(t) {
  Ire = t;
}
class UWe extends ZP {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? pp(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return De.MIN;
  }
  maxPost() {
    return new De(Vf, new Nr("[PRIORITY-POST]", Ire));
  }
  makePost(e, n) {
    const r = Sre(e);
    return new De(n, new Nr("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const Ln = new UWe(), FWe = Math.log(2);
class YWe {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / FWe, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const xR = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new Lr(d, u.node, Lr.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new Lr(d, u.node, Lr.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new Lr(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, Lr.BLACK) : (d(m, Lr.BLACK), d(m, Lr.RED));
    }
    return h;
  }, o = new YWe(t.length), a = i(o);
  return new ui(r || e, a);
};
let HM;
const Lp = {};
class Sc {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return O(Lp && Ln, "ChildrenNode.ts has not been loaded"), HM = HM || new Sc({ ".priority": Lp }, { ".priority": Ln }), HM;
  }
  get(e) {
    const n = Nm(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof ui ? n : null;
  }
  hasIndex(e) {
    return Ga(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    O(e !== k_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(De.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = xR(r, e.getCompare()) : a = Lp;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new Sc(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = OR(this.indexes_, (s, i) => {
      const o = Nm(this.indexSet_, i);
      if (O(o, "Missing index implementation for " + i), s === Lp)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(De.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), xR(a, o.getCompare());
        } else
          return Lp;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new De(e.name, a))), c.insert(e, e.node);
      }
    });
    return new Sc(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = OR(this.indexes_, (s) => {
      if (s === Lp)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new De(e.name, i)) : s;
      }
    });
    return new Sc(r, this.indexSet_);
  }
}
let Oy;
class le {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && Ere(this.priorityNode_), this.children_.isEmpty() && O(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return Oy || (Oy = new le(new ui(WB), null, Sc.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || Oy;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new le(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? Oy : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = Se(e);
    return n === null ? this : this.getImmediateChild(n).getChild(nn(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (O(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new De(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? Oy : this.priorityNode_;
      return new le(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Se(e);
    if (r === null)
      return n;
    {
      O(Se(e) !== ".priority" || Lh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(nn(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(Ln, (o, a) => {
      n[o] = a.val(e), r++, i && le.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + bre(this.getPriority().val()) + ":"), this.forEachChild(Ln, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : Xne(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new De(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new De(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new De(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, De.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, De.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === Cb ? -1 : 0;
  }
  withIndex(e) {
    if (e === k_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new le(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === k_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(Ln), s = n.getIterator(Ln);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === k_ ? null : this.indexMap_.get(e.toString());
  }
}
le.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class WWe extends le {
  constructor() {
    super(new ui(WB), le.EMPTY_NODE, Sc.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return le.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const Cb = new WWe();
Object.defineProperties(De, {
  MIN: {
    value: new De(Pm, le.EMPTY_NODE)
  },
  MAX: {
    value: new De(Vf, Cb)
  }
});
Tre.__EMPTY_NODE = le.EMPTY_NODE;
Nr.__childrenNodeConstructor = le;
MWe(Cb);
LWe(Cb);
const BWe = !0;
function lr(t, e = null) {
  if (t === null)
    return le.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), O(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new Nr(n, lr(e));
  }
  if (!(t instanceof Array) && BWe) {
    const n = [];
    let r = !1;
    if (rs(t, (i, o) => {
      if (i.substring(0, 1) !== ".") {
        const a = lr(o);
        a.isEmpty() || (r = r || !a.getPriority().isEmpty(), n.push(new De(i, a)));
      }
    }), n.length === 0)
      return le.EMPTY_NODE;
    const s = xR(n, AWe, (i) => i.name, WB);
    if (r) {
      const i = xR(n, Ln.getCompare());
      return new le(s, lr(e), new Sc({ ".priority": i }, { ".priority": Ln }));
    } else
      return new le(s, lr(e), Sc.Default);
  } else {
    let n = le.EMPTY_NODE;
    return rs(t, (r, s) => {
      if (Ga(t, r) && r.substring(0, 1) !== ".") {
        const i = lr(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(lr(e));
  }
}
xWe(lr);
class qWe extends ZP {
  constructor(e) {
    super(), this.indexPath_ = e, O(!Ye(e) && Se(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? pp(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = lr(e), s = le.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new De(n, s);
  }
  maxPost() {
    const e = le.EMPTY_NODE.updateChild(this.indexPath_, Cb);
    return new De(Vf, e);
  }
  toString() {
    return gC(this.indexPath_, 0).join("/");
  }
}
class HWe extends ZP {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? pp(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return De.MIN;
  }
  maxPost() {
    return De.MAX;
  }
  makePost(e, n) {
    const r = lr(e);
    return new De(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const jWe = new HWe();
function Rre(t) {
  return { type: "value", snapshotNode: t };
}
function Om(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function yC(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function vC(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function VWe(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class BB {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    O(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(yC(n, a)) : O(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(Om(n, r)) : o.trackChildChange(vC(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(Ln, (s, i) => {
      n.hasChild(s) || r.trackChildChange(yC(s, i));
    }), n.isLeafNode() || n.forEachChild(Ln, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(vC(s, i, o));
      } else
        r.trackChildChange(Om(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? le.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class wC {
  constructor(e) {
    this.indexedFilter_ = new BB(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = wC.getStartPost_(e), this.endPost_ = wC.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new De(n, r)) || (r = le.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = le.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(le.EMPTY_NODE);
    const i = this;
    return n.forEachChild(Ln, (o, a) => {
      i.matches(new De(o, a)) || (s = s.updateImmediateChild(o, le.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class zWe {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new wC(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new De(n, r)) || (r = le.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = le.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = le.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(le.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, le.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    O(a.numChildren() === this.limit_, "");
    const c = new De(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(vC(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(yC(n, u));
        const p = a.updateImmediateChild(n, le.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(Om(d.name, d.node)), p.updateImmediateChild(d.name, d.node)) : p;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(yC(l.name, l.node)), i.trackChildChange(Om(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, le.EMPTY_NODE)) : e;
  }
}
class qB {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = Ln;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return O(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return O(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : Pm;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return O(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return O(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : Vf;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return O(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === Ln;
  }
  copy() {
    const e = new qB();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function GWe(t) {
  return t.loadsAllData() ? new BB(t.getIndex()) : t.hasLimit() ? new zWe(t) : new wC(t);
}
function $$(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === Ln ? n = "$priority" : t.index_ === jWe ? n = "$value" : t.index_ === k_ ? n = "$key" : (O(t.index_ instanceof qWe, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = mr(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = mr(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + mr(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = mr(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + mr(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function K$(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== Ln && (e.i = t.index_.toString()), e;
}
class LR extends yre {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = wb("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (O(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = LR.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = $$(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), Nm(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = LR.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = $$(e._queryParams), r = e._path.toString(), s = new mb();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + NB(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = pC(a.responseText);
            } catch {
              Us("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && Us("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class $We {
  constructor() {
    this.rootNode_ = le.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function UR() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function kre(t, e, n) {
  if (Ye(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = Se(e);
    t.children.has(r) || t.children.set(r, UR());
    const s = t.children.get(r);
    e = nn(e), kre(s, e, n);
  }
}
function yU(t, e, n) {
  t.value !== null ? n(e, t.value) : KWe(t, (r, s) => {
    const i = new xt(e.toString() + "/" + r);
    yU(s, i, n);
  });
}
function KWe(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class JWe {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && rs(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const J$ = 10 * 1e3, QWe = 30 * 1e3, ZWe = 5 * 60 * 1e3;
class XWe {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new JWe(e);
    const r = J$ + (QWe - J$) * Math.random();
    Xv(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    rs(e, (s, i) => {
      i > 0 && Ga(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), Xv(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * ZWe));
  }
}
var Do;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(Do || (Do = {}));
function HB() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function jB() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function VB(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class FR {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = Do.ACK_USER_WRITE, this.source = HB();
  }
  operationForChild(e) {
    if (Ye(this.path)) {
      if (this.affectedTree.value != null)
        return O(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new xt(e));
        return new FR(ut(), n, this.revert);
      }
    } else return O(Se(this.path) === e, "operationForChild called for unrelated child."), new FR(nn(this.path), this.affectedTree, this.revert);
  }
}
class CC {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = Do.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return Ye(this.path) ? new CC(this.source, ut()) : new CC(this.source, nn(this.path));
  }
}
class zf {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = Do.OVERWRITE;
  }
  operationForChild(e) {
    return Ye(this.path) ? new zf(this.source, ut(), this.snap.getImmediateChild(e)) : new zf(this.source, nn(this.path), this.snap);
  }
}
class Dm {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = Do.MERGE;
  }
  operationForChild(e) {
    if (Ye(this.path)) {
      const n = this.children.subtree(new xt(e));
      return n.isEmpty() ? null : n.value ? new zf(this.source, ut(), n.value) : new Dm(this.source, ut(), n);
    } else
      return O(Se(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new Dm(this.source, nn(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class Uh {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (Ye(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = Se(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class eBe {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function tBe(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(VWe(o.childName, o.snapshotNode));
  }), Dy(t, s, "child_removed", e, r, n), Dy(t, s, "child_added", e, r, n), Dy(t, s, "child_moved", i, r, n), Dy(t, s, "child_changed", e, r, n), Dy(t, s, "value", e, r, n), s;
}
function Dy(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => rBe(t, a, c)), o.forEach((a) => {
    const c = nBe(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function nBe(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function rBe(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw ug("Should only compare child_ events.");
  const r = new De(e.childName, e.snapshotNode), s = new De(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function XP(t, e) {
  return { eventCache: t, serverCache: e };
}
function ew(t, e, n, r) {
  return XP(new Uh(e, n, r), t.serverCache);
}
function Nre(t, e, n, r) {
  return XP(t.eventCache, new Uh(e, n, r));
}
function YR(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function Gf(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let jM;
const sBe = () => (jM || (jM = new ui(q2e)), jM);
class $t {
  constructor(e, n = sBe()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new $t(null);
    return rs(e, (r, s) => {
      n = n.set(new xt(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: ut(), value: this.value };
    if (Ye(e))
      return null;
    {
      const r = Se(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(nn(e), n);
        return i != null ? { path: Nn(new xt(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (Ye(e))
      return this;
    {
      const n = Se(e), r = this.children.get(n);
      return r !== null ? r.subtree(nn(e)) : new $t(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (Ye(e))
      return new $t(n, this.children);
    {
      const r = Se(e), s = (this.children.get(r) || new $t(null)).set(nn(e), n), i = this.children.insert(r, s);
      return new $t(this.value, i);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (Ye(e))
      return this.children.isEmpty() ? new $t(null) : new $t(null, this.children);
    {
      const n = Se(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(nn(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new $t(null) : new $t(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (Ye(e))
      return this.value;
    {
      const n = Se(e), r = this.children.get(n);
      return r ? r.get(nn(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (Ye(e))
      return n;
    {
      const r = Se(e), s = (this.children.get(r) || new $t(null)).setTree(nn(e), n);
      let i;
      return s.isEmpty() ? i = this.children.remove(r) : i = this.children.insert(r, s), new $t(this.value, i);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(ut(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(Nn(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, ut(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (Ye(e))
      return null;
    {
      const i = Se(e), o = this.children.get(i);
      return o ? o.findOnPath_(nn(e), Nn(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, ut(), n);
  }
  foreachOnPath_(e, n, r) {
    if (Ye(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = Se(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(nn(e), Nn(n, s), r) : new $t(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(ut(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(Nn(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class Vo {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new Vo(new $t(null));
  }
}
function tw(t, e, n) {
  if (Ye(e))
    return new Vo(new $t(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = Ps(s, e);
      return i = i.updateChild(o, n), new Vo(t.writeTree_.set(s, i));
    } else {
      const s = new $t(n), i = t.writeTree_.setTree(e, s);
      return new Vo(i);
    }
  }
}
function vU(t, e, n) {
  let r = t;
  return rs(n, (s, i) => {
    r = tw(r, Nn(e, s), i);
  }), r;
}
function Q$(t, e) {
  if (Ye(e))
    return Vo.empty();
  {
    const n = t.writeTree_.setTree(e, new $t(null));
    return new Vo(n);
  }
}
function wU(t, e) {
  return _p(t, e) != null;
}
function _p(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(Ps(n.path, e)) : null;
}
function Z$(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(Ln, (r, s) => {
    e.push(new De(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new De(r, s.value));
  }), e;
}
function rh(t, e) {
  if (Ye(e))
    return t;
  {
    const n = _p(t, e);
    return n != null ? new Vo(new $t(n)) : new Vo(t.writeTree_.subtree(e));
  }
}
function CU(t) {
  return t.writeTree_.isEmpty();
}
function Am(t, e) {
  return Pre(ut(), t.writeTree_, e);
}
function Pre(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (O(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = Pre(Nn(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(Nn(t, ".priority"), r)), n;
  }
}
function eO(t, e) {
  return Mre(e, t);
}
function iBe(t, e, n, r, s) {
  O(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = tw(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function oBe(t, e, n, r) {
  O(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = vU(t.visibleWrites, e, n), t.lastWriteId = r;
}
function aBe(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function cBe(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  O(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && lBe(a, r.path) ? s = !1 : Ki(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return uBe(t), !0;
    if (r.snap)
      t.visibleWrites = Q$(t.visibleWrites, r.path);
    else {
      const a = r.children;
      rs(a, (c) => {
        t.visibleWrites = Q$(t.visibleWrites, Nn(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function lBe(t, e) {
  if (t.snap)
    return Ki(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && Ki(Nn(t.path, n), e))
      return !0;
  return !1;
}
function uBe(t) {
  t.visibleWrites = Ore(t.allWrites, hBe, ut()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function hBe(t) {
  return t.visible;
}
function Ore(t, e, n) {
  let r = Vo.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        Ki(n, o) ? (a = Ps(n, o), r = tw(r, a, i.snap)) : Ki(o, n) && (a = Ps(o, n), r = tw(r, ut(), i.snap.getChild(a)));
      else if (i.children) {
        if (Ki(n, o))
          a = Ps(n, o), r = vU(r, a, i.children);
        else if (Ki(o, n))
          if (a = Ps(o, n), Ye(a))
            r = vU(r, ut(), i.children);
          else {
            const c = Nm(i.children, Se(a));
            if (c) {
              const l = c.getChild(nn(a));
              r = tw(r, ut(), l);
            }
          }
      } else
        throw ug("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function Dre(t, e, n, r, s) {
  if (!r && !s) {
    const i = _p(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = rh(t.visibleWrites, e);
      if (CU(o))
        return n;
      if (n == null && !wU(o, ut()))
        return null;
      {
        const a = n || le.EMPTY_NODE;
        return Am(o, a);
      }
    }
  } else {
    const i = rh(t.visibleWrites, e);
    if (!s && CU(i))
      return n;
    if (!s && n == null && !wU(i, ut()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && (Ki(l.path, e) || Ki(e, l.path));
      }, a = Ore(t.allWrites, o, e), c = n || le.EMPTY_NODE;
      return Am(a, c);
    }
  }
}
function dBe(t, e, n) {
  let r = le.EMPTY_NODE;
  const s = _p(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(Ln, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = rh(t.visibleWrites, e);
    return n.forEachChild(Ln, (o, a) => {
      const c = Am(rh(i, new xt(o)), a);
      r = r.updateImmediateChild(o, c);
    }), Z$(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = rh(t.visibleWrites, e);
    return Z$(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function fBe(t, e, n, r, s) {
  O(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = Nn(e, n);
  if (wU(t.visibleWrites, i))
    return null;
  {
    const o = rh(t.visibleWrites, i);
    return CU(o) ? s.getChild(n) : Am(o, s.getChild(n));
  }
}
function pBe(t, e, n, r) {
  const s = Nn(e, n), i = _p(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = rh(t.visibleWrites, s);
    return Am(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function _Be(t, e) {
  return _p(t.visibleWrites, e);
}
function mBe(t, e, n, r, s, i, o) {
  let a;
  const c = rh(t.visibleWrites, e), l = _p(c, ut());
  if (l != null)
    a = l;
  else if (n != null)
    a = Am(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function gBe() {
  return {
    visibleWrites: Vo.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function WR(t, e, n, r) {
  return Dre(t.writeTree, t.treePath, e, n, r);
}
function zB(t, e) {
  return dBe(t.writeTree, t.treePath, e);
}
function X$(t, e, n, r) {
  return fBe(t.writeTree, t.treePath, e, n, r);
}
function BR(t, e) {
  return _Be(t.writeTree, Nn(t.treePath, e));
}
function yBe(t, e, n, r, s, i) {
  return mBe(t.writeTree, t.treePath, e, n, r, s, i);
}
function GB(t, e, n) {
  return pBe(t.writeTree, t.treePath, e, n);
}
function Are(t, e) {
  return Mre(Nn(t.treePath, e), t.writeTree);
}
function Mre(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class vBe {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    O(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), O(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, vC(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, yC(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, Om(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, vC(r, e.snapshotNode, s.oldSnap));
      else
        throw ug("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class wBe {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const xre = new wBe();
class $B {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new Uh(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return GB(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : Gf(this.viewCache_), i = yBe(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function CBe(t) {
  return { filter: t };
}
function TBe(t, e) {
  O(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), O(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function bBe(t, e, n, r, s) {
  const i = new vBe();
  let o, a;
  if (n.type === Do.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = TU(t, e, l.path, l.snap, r, s, i) : (O(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !Ye(l.path), o = qR(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === Do.MERGE) {
    const l = n;
    l.source.fromUser ? o = SBe(t, e, l.path, l.children, r, s, i) : (O(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = bU(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === Do.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = kBe(t, e, l.path, r, s, i) : o = IBe(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === Do.LISTEN_COMPLETE)
    o = RBe(t, e, n.path, r, i);
  else
    throw ug("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return EBe(e, o, c), { viewCache: o, changes: c };
}
function EBe(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = YR(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(Rre(YR(e)));
  }
}
function Lre(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (BR(r, n) != null)
    return e;
  {
    let a, c;
    if (Ye(n))
      if (O(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = Gf(e), h = l instanceof le ? l : le.EMPTY_NODE, u = zB(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = WR(r, Gf(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = Se(n);
      if (l === ".priority") {
        O(Lh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = X$(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = nn(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = X$(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = GB(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return ew(e, a, o.isFullyInitialized() || Ye(n), t.filter.filtersNodes());
  }
}
function qR(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (Ye(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = Se(n);
    if (!c.isCompleteForPath(n) && Lh(n) > 1)
      return e;
    const p = nn(n), _ = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), _) : l = h.updateChild(c.getNode(), f, _, p, xre, null);
  }
  const u = Nre(e, l, c.isFullyInitialized() || Ye(n), h.filtersNodes()), d = new $B(s, u, i);
  return Lre(t, u, n, s, d, a);
}
function TU(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new $B(s, e, i);
  if (Ye(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = ew(e, l, !0, t.filter.filtersNodes());
  else {
    const u = Se(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = ew(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = nn(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (Ye(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? UB(d) === ".priority" && _.getChild(wre(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = le.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = ew(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function eK(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function SBe(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = Nn(n, c);
    eK(e, Se(h)) && (a = TU(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = Nn(n, c);
    eK(e, Se(h)) || (a = TU(t, a, h, l, s, i, o));
  }), a;
}
function tK(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function bU(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  Ye(n) ? l = r : l = new $t(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = tK(t, f, d);
      c = qR(t, c, new xt(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = tK(t, p, d);
      c = qR(t, c, new xt(u), _, s, i, o, a);
    }
  }), c;
}
function IBe(t, e, n, r, s, i, o) {
  if (BR(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (Ye(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return qR(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (Ye(n)) {
      let l = new $t(null);
      return c.getNode().forEachChild(k_, (h, u) => {
        l = l.set(new xt(h), u);
      }), bU(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new $t(null);
    return r.foreach((h, u) => {
      const d = Nn(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), bU(t, e, n, l, s, i, a, o);
  }
}
function RBe(t, e, n, r, s) {
  const i = e.serverCache, o = Nre(e, i.getNode(), i.isFullyInitialized() || Ye(n), i.isFiltered());
  return Lre(t, o, n, r, xre, s);
}
function kBe(t, e, n, r, s, i) {
  let o;
  if (BR(r, n) != null)
    return e;
  {
    const a = new $B(r, e, s), c = e.eventCache.getNode();
    let l;
    if (Ye(n) || Se(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = WR(r, Gf(e));
      else {
        const u = e.serverCache.getNode();
        O(u instanceof le, "serverChildren would be complete if leaf node"), h = zB(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = Se(n);
      let u = GB(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, nn(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, le.EMPTY_NODE, nn(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = WR(r, Gf(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || BR(r, ut()) != null, ew(e, l, o, t.filter.filtersNodes());
  }
}
class NBe {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new BB(r.getIndex()), i = GWe(r);
    this.processor_ = CBe(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(le.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(le.EMPTY_NODE, a.getNode(), null), h = new Uh(c, o.isFullyInitialized(), s.filtersNodes()), u = new Uh(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = XP(u, h), this.eventGenerator_ = new eBe(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function PBe(t) {
  return t.viewCache_.serverCache.getNode();
}
function OBe(t) {
  return YR(t.viewCache_);
}
function DBe(t, e) {
  const n = Gf(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !Ye(e) && !n.getImmediateChild(Se(e)).isEmpty()) ? n.getChild(e) : null;
}
function nK(t) {
  return t.eventRegistrations_.length === 0;
}
function ABe(t, e) {
  t.eventRegistrations_.push(e);
}
function rK(t, e, n) {
  const r = [];
  if (n) {
    O(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function sK(t, e, n, r) {
  e.type === Do.MERGE && e.source.queryId !== null && (O(Gf(t.viewCache_), "We should always have a full cache before handling merges"), O(YR(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = bBe(t.processor_, s, e, n, r);
  return TBe(t.processor_, i.viewCache), O(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, Ure(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function MBe(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(Ln, (s, i) => {
    r.push(Om(s, i));
  }), n.isFullyInitialized() && r.push(Rre(n.getNode())), Ure(t, r, n.getNode(), e);
}
function Ure(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return tBe(t.eventGenerator_, e, n, s);
}
let HR;
class Fre {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function xBe(t) {
  O(!HR, "__referenceConstructor has already been defined"), HR = t;
}
function LBe() {
  return O(HR, "Reference.ts has not been loaded"), HR;
}
function UBe(t) {
  return t.views.size === 0;
}
function KB(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return O(i != null, "SyncTree gave us an op for an invalid query."), sK(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat(sK(o, e, n, r));
    return i;
  }
}
function Yre(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = WR(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof le ? (a = zB(n, r), c = !1) : (a = le.EMPTY_NODE, c = !1);
    const l = XP(new Uh(a, c, !1), new Uh(r, s, !1));
    return new NBe(e, l);
  }
  return o;
}
function FBe(t, e, n, r, s, i) {
  const o = Yre(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), ABe(o, n), MBe(o, n);
}
function YBe(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = Fh(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(rK(l, n, r)), nK(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(rK(c, n, r)), nK(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !Fh(t) && i.push(new (LBe())(e._repo, e._path)), { removed: i, events: o };
}
function Wre(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function sh(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || DBe(r, e);
  return n;
}
function Bre(t, e) {
  if (e._queryParams.loadsAllData())
    return tO(t);
  {
    const n = e._queryIdentifier;
    return t.views.get(n);
  }
}
function qre(t, e) {
  return Bre(t, e) != null;
}
function Fh(t) {
  return tO(t) != null;
}
function tO(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let jR;
function WBe(t) {
  O(!jR, "__referenceConstructor has already been defined"), jR = t;
}
function BBe() {
  return O(jR, "Reference.ts has not been loaded"), jR;
}
let qBe = 1;
class iK {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new $t(null), this.pendingWriteTree_ = gBe(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function Hre(t, e, n, r, s) {
  return iBe(t.pendingWriteTree_, e, n, r, s), s ? dg(t, new zf(HB(), e, n)) : [];
}
function HBe(t, e, n, r) {
  oBe(t.pendingWriteTree_, e, n, r);
  const s = $t.fromObject(n);
  return dg(t, new Dm(HB(), e, s));
}
function Eu(t, e, n = !1) {
  const r = aBe(t.pendingWriteTree_, e);
  if (cBe(t.pendingWriteTree_, e)) {
    let s = new $t(null);
    return r.snap != null ? s = s.set(ut(), !0) : rs(r.children, (i) => {
      s = s.set(new xt(i), !0);
    }), dg(t, new FR(r.path, s, n));
  } else
    return [];
}
function Tb(t, e, n) {
  return dg(t, new zf(jB(), e, n));
}
function jBe(t, e, n) {
  const r = $t.fromObject(n);
  return dg(t, new Dm(jB(), e, r));
}
function VBe(t, e) {
  return dg(t, new CC(jB(), e));
}
function zBe(t, e, n) {
  const r = QB(t, n);
  if (r) {
    const s = ZB(r), i = s.path, o = s.queryId, a = Ps(i, e), c = new CC(VB(o), a);
    return XB(t, i, c);
  } else
    return [];
}
function VR(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || qre(o, e))) {
    const c = YBe(o, e, n, r);
    UBe(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => Fh(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = KBe(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = Gre(t, _);
            t.listenProvider_.startListening(nw(m), TC(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(nw(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(nO(d));
        t.listenProvider_.stopListening(nw(d), f);
      }));
    }
    JBe(t, l);
  }
  return a;
}
function jre(t, e, n, r) {
  const s = QB(t, r);
  if (s != null) {
    const i = ZB(s), o = i.path, a = i.queryId, c = Ps(o, e), l = new zf(VB(a), c, n);
    return XB(t, o, l);
  } else
    return [];
}
function GBe(t, e, n, r) {
  const s = QB(t, r);
  if (s) {
    const i = ZB(s), o = i.path, a = i.queryId, c = Ps(o, e), l = $t.fromObject(n), h = new Dm(VB(a), c, l);
    return XB(t, o, h);
  } else
    return [];
}
function EU(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = Ps(d, s);
    i = i || sh(f, p), o = o || Fh(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || Fh(a), i = i || sh(a, ut())) : (a = new Fre(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = le.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((d, f) => {
    const p = sh(f, ut());
    p && (i = i.updateImmediateChild(d, p));
  }));
  const l = qre(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = nO(e);
    O(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = QBe();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = eO(t.pendingWriteTree_, s);
  let u = FBe(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = Bre(a, e);
    u = u.concat(ZBe(t, e, d));
  }
  return u;
}
function JB(t, e, n) {
  const r = t.pendingWriteTree_, s = t.syncPointTree_.findOnPath(e, (i, o) => {
    const a = Ps(i, e), c = sh(o, a);
    if (c)
      return c;
  });
  return Dre(r, e, s, n, !0);
}
function $Be(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = Ps(l, n);
    r = r || sh(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || sh(s, ut()) : (s = new Fre(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new Uh(r, !0, !1) : null, a = eO(t.pendingWriteTree_, e._path), c = Yre(s, e, a, i ? o.getNode() : le.EMPTY_NODE, i);
  return OBe(c);
}
function dg(t, e) {
  return Vre(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    eO(t.pendingWriteTree_, ut())
  );
}
function Vre(t, e, n, r) {
  if (Ye(t.path))
    return zre(t, e, n, r);
  {
    const s = e.get(ut());
    n == null && s != null && (n = sh(s, ut()));
    let i = [];
    const o = Se(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = Are(r, o);
      i = i.concat(Vre(a, c, l, h));
    }
    return s && (i = i.concat(KB(s, t, r, n))), i;
  }
}
function zre(t, e, n, r) {
  const s = e.get(ut());
  n == null && s != null && (n = sh(s, ut()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = Are(r, o), h = t.operationForChild(o);
    h && (i = i.concat(zre(h, a, c, l)));
  }), s && (i = i.concat(KB(s, t, r, n))), i;
}
function Gre(t, e) {
  const n = e.query, r = TC(t, n);
  return {
    hashFn: () => (PBe(e) || le.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? zBe(t, n._path, r) : VBe(t, n._path);
      {
        const i = V2e(s, n);
        return VR(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function TC(t, e) {
  const n = nO(e);
  return t.queryToTagMap.get(n);
}
function nO(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function QB(t, e) {
  return t.tagToQueryMap.get(e);
}
function ZB(t) {
  const e = t.indexOf("$");
  return O(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new xt(t.substr(0, e))
  };
}
function XB(t, e, n) {
  const r = t.syncPointTree_.get(e);
  O(r, "Missing sync point for query tag that we're tracking");
  const s = eO(t.pendingWriteTree_, e);
  return KB(r, n, s, null);
}
function KBe(t) {
  return t.fold((e, n, r) => {
    if (n && Fh(n))
      return [tO(n)];
    {
      let s = [];
      return n && (s = Wre(n)), rs(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function nw(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (BBe())(t._repo, t._path) : t;
}
function JBe(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = nO(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function QBe() {
  return qBe++;
}
function ZBe(t, e, n) {
  const r = e._path, s = TC(t, e), i = Gre(t, n), o = t.listenProvider_.startListening(nw(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    O(!Fh(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!Ye(l) && h && Fh(h))
        return [tO(h).query];
      {
        let d = [];
        return h && (d = d.concat(Wre(h).map((f) => f.query))), rs(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(nw(h), TC(t, h));
    }
  }
  return o;
}
class eq {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new eq(n);
  }
  node() {
    return this.node_;
  }
}
class tq {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = Nn(this.path_, e);
    return new tq(this.syncTree_, n);
  }
  node() {
    return JB(this.syncTree_, this.path_);
  }
}
const XBe = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, oK = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (O(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return eqe(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return tqe(t[".sv"], e);
  O(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, eqe = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      O(!1, "Unexpected server value: " + t);
  }
}, tqe = function(t, e, n) {
  t.hasOwnProperty("increment") || O(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && O(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (O(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const i = s.getValue();
  return typeof i != "number" ? r : i + r;
}, $re = function(t, e, n, r) {
  return nq(e, new tq(n, t), r);
}, Kre = function(t, e, n) {
  return nq(t, new eq(e), n);
};
function nq(t, e, n) {
  const r = t.getPriority().val(), s = oK(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = oK(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new Nr(a, lr(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new Nr(s))), o.forEachChild(Ln, (a, c) => {
      const l = nq(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class rq {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function sq(t, e) {
  let n = e instanceof xt ? e : new xt(e), r = t, s = Se(n);
  for (; s !== null; ) {
    const i = Nm(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new rq(s, r, i), n = nn(n), s = Se(n);
  }
  return r;
}
function fg(t) {
  return t.node.value;
}
function Jre(t, e) {
  t.node.value = e, SU(t);
}
function Qre(t) {
  return t.node.childCount > 0;
}
function nqe(t) {
  return fg(t) === void 0 && !Qre(t);
}
function rO(t, e) {
  rs(t.node.children, (n, r) => {
    e(new rq(n, t, r));
  });
}
function Zre(t, e, n, r) {
  n && !r && e(t), rO(t, (s) => {
    Zre(s, e, !0, r);
  }), n && r && e(t);
}
function rqe(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function bb(t) {
  return new xt(t.parent === null ? t.name : bb(t.parent) + "/" + t.name);
}
function SU(t) {
  t.parent !== null && sqe(t.parent, t.name, t);
}
function sqe(t, e, n) {
  const r = nqe(n), s = Ga(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, SU(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, SU(t));
}
const iqe = /[\[\].#$\/\u0000-\u001F\u007F]/, oqe = /[\[\].#$\u0000-\u001F\u007F]/, VM = 10 * 1024 * 1024, iq = function(t) {
  return typeof t == "string" && t.length !== 0 && !iqe.test(t);
}, Xre = function(t) {
  return typeof t == "string" && t.length !== 0 && !oqe.test(t);
}, aqe = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), Xre(t);
}, cqe = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !DB(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Ga(t, ".sv");
}, lqe = function(t, e, n, r) {
  sO(JP(t, "value"), e, n);
}, sO = function(t, e, n) {
  const r = n instanceof xt ? new SWe(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + kd(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + kd(r) + " with contents = " + e.toString());
  if (DB(e))
    throw new Error(t + "contains " + e.toString() + " " + kd(r));
  if (typeof e == "string" && e.length > VM / 3 && QP(e) > VM)
    throw new Error(t + "contains a string greater than " + VM + " utf8 bytes " + kd(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (rs(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !iq(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + kd(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      IWe(r, o), sO(t, a, r), RWe(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + kd(r) + " in addition to actual children.");
  }
}, uqe = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = gC(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1) && !iq(i[o]))
        throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
  }
  e.sort(EWe);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && Ki(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, hqe = function(t, e, n, r) {
  const s = JP(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  rs(e, (o, a) => {
    const c = new xt(o);
    if (sO(s, a, Nn(n, c)), UB(c) === ".priority" && !cqe(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), uqe(s, i);
}, ese = function(t, e, n, r) {
  if (!Xre(n))
    throw new Error(JP(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, dqe = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), ese(t, e, n);
}, fqe = function(t, e) {
  if (Se(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, pqe = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !iq(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !aqe(n))
    throw new Error(JP(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class _qe {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function iO(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !FB(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function tse(t, e, n) {
  iO(t, n), nse(t, (r) => FB(r, e));
}
function no(t, e, n) {
  iO(t, n), nse(t, (r) => Ki(r, e) || Ki(e, r));
}
function nse(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (mqe(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function mqe(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      Zv && Jr("event: " + n.toString()), hg(r);
    }
  }
}
const gqe = "repo_interrupt", yqe = 25;
class vqe {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new _qe(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = UR(), this.transactionQueueTree_ = new rq(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function wqe(t, e, n) {
  if (t.stats_ = xB(t.repoInfo_), t.forceRestClient_ || K2e())
    t.server_ = new LR(t.repoInfo_, (r, s, i, o) => {
      aK(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => cK(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        mr(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new el(t.repoInfo_, e, (r, s, i, o) => {
      aK(t, r, s, i, o);
    }, (r) => {
      cK(t, r);
    }, (r) => {
      Tqe(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = eWe(t.repoInfo_, () => new XWe(t.stats_, t.server_)), t.infoData_ = new $We(), t.infoSyncTree_ = new iK({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = Tb(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), oq(t, "connected", !1), t.serverSyncTree_ = new iK({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      no(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function Cqe(t) {
  const e = t.infoData_.getNode(new xt(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + e;
}
function oO(t) {
  return XBe({
    timestamp: Cqe(t)
  });
}
function aK(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new xt(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = OR(n, (l) => lr(l));
      o = GBe(t.serverSyncTree_, i, c, s);
    } else {
      const c = lr(n);
      o = jre(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = OR(n, (l) => lr(l));
    o = jBe(t.serverSyncTree_, i, c);
  } else {
    const c = lr(n);
    o = Tb(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = Mm(t, i)), no(t.eventQueue_, a, o);
}
function cK(t, e) {
  oq(t, "connected", e), e === !1 && Iqe(t);
}
function Tqe(t, e) {
  rs(e, (n, r) => {
    oq(t, n, r);
  });
}
function oq(t, e, n) {
  const r = new xt("/.info/" + e), s = lr(n);
  t.infoData_.updateSnapshot(r, s);
  const i = Tb(t.infoSyncTree_, r, s);
  no(t.eventQueue_, r, i);
}
function aq(t) {
  return t.nextWriteId_++;
}
function bqe(t, e, n) {
  const r = $Be(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = lr(s).withIndex(e._queryParams.getIndex());
    EU(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = Tb(t.serverSyncTree_, e._path, i);
    else {
      const a = TC(t.serverSyncTree_, e);
      o = jre(t.serverSyncTree_, e._path, i, a);
    }
    return no(t.eventQueue_, e._path, o), VR(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (Eb(t, "get for query " + mr(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function Eqe(t, e, n, r, s) {
  Eb(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = oO(t), o = lr(n, r), a = JB(t.serverSyncTree_, e), c = Kre(o, a, i), l = aq(t), h = Hre(t.serverSyncTree_, e, c, l, !0);
  iO(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || Us("set at " + e + " failed: " + d);
    const _ = Eu(t.serverSyncTree_, l, !p);
    no(t.eventQueue_, e, _), IU(t, s, d, f);
  });
  const u = lq(t, e);
  Mm(t, u), no(t.eventQueue_, u, []);
}
function Sqe(t, e, n, r) {
  Eb(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = oO(t), o = {};
  if (rs(n, (a, c) => {
    s = !1, o[a] = $re(Nn(e, a), lr(c), t.serverSyncTree_, i);
  }), s)
    Jr("update() called with empty data.  Don't do anything."), IU(t, r, "ok", void 0);
  else {
    const a = aq(t), c = HBe(t.serverSyncTree_, e, o, a);
    iO(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || Us("update at " + e + " failed: " + l);
      const d = Eu(t.serverSyncTree_, a, !u), f = d.length > 0 ? Mm(t, e) : e;
      no(t.eventQueue_, f, d), IU(t, r, l, h);
    }), rs(n, (l) => {
      const h = lq(t, Nn(e, l));
      Mm(t, h);
    }), no(t.eventQueue_, e, []);
  }
}
function Iqe(t) {
  Eb(t, "onDisconnectEvents");
  const e = oO(t), n = UR();
  yU(t.onDisconnect_, ut(), (s, i) => {
    const o = $re(s, i, t.serverSyncTree_, e);
    kre(n, s, o);
  });
  let r = [];
  yU(n, ut(), (s, i) => {
    r = r.concat(Tb(t.serverSyncTree_, s, i));
    const o = lq(t, s);
    Mm(t, o);
  }), t.onDisconnect_ = UR(), no(t.eventQueue_, ut(), r);
}
function Rqe(t, e, n) {
  let r;
  Se(e._path) === ".info" ? r = EU(t.infoSyncTree_, e, n) : r = EU(t.serverSyncTree_, e, n), tse(t.eventQueue_, e._path, r);
}
function lK(t, e, n) {
  let r;
  Se(e._path) === ".info" ? r = VR(t.infoSyncTree_, e, n) : r = VR(t.serverSyncTree_, e, n), tse(t.eventQueue_, e._path, r);
}
function kqe(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt(gqe);
}
function Eb(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), Jr(n, ...e);
}
function IU(t, e, n, r) {
  e && hg(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function rse(t, e, n) {
  return JB(t.serverSyncTree_, e, n) || le.EMPTY_NODE;
}
function cq(t, e = t.transactionQueueTree_) {
  if (e || aO(t, e), fg(e)) {
    const n = ise(t, e);
    O(n.length > 0, "Sending zero length transaction queue"), n.every(
      (r) => r.status === 0
      /* TransactionStatus.RUN */
    ) && Nqe(t, bb(e), n);
  } else Qre(e) && rO(e, (n) => {
    cq(t, n);
  });
}
function Nqe(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = rse(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    O(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = Ps(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    Eb(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(Eu(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      aO(t, sq(t.transactionQueueTree_, e)), cq(t, t.transactionQueueTree_), no(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        hg(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        Us("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      Mm(t, e);
    }
  }, o);
}
function Mm(t, e) {
  const n = sse(t, e), r = bb(n), s = ise(t, n);
  return Pqe(t, s, r), r;
}
function Pqe(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const i = e.filter((o) => o.status === 0).map((o) => o.currentWriteId);
  for (let o = 0; o < e.length; o++) {
    const a = e[o], c = Ps(n, a.path);
    let l = !1, h;
    if (O(c !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), a.status === 4)
      l = !0, h = a.abortReason, s = s.concat(Eu(t.serverSyncTree_, a.currentWriteId, !0));
    else if (a.status === 0)
      if (a.retryCount >= yqe)
        l = !0, h = "maxretry", s = s.concat(Eu(t.serverSyncTree_, a.currentWriteId, !0));
      else {
        const u = rse(t, a.path, i);
        a.currentInputSnapshot = u;
        const d = e[o].update(u.val());
        if (d !== void 0) {
          sO("transaction failed: Data returned ", d, a.path);
          let f = lr(d);
          typeof d == "object" && d != null && Ga(d, ".priority") || (f = f.updatePriority(u.getPriority()));
          const p = a.currentWriteId, _ = oO(t), m = Kre(f, u, _);
          a.currentOutputSnapshotRaw = f, a.currentOutputSnapshotResolved = m, a.currentWriteId = aq(t), i.splice(i.indexOf(p), 1), s = s.concat(Hre(t.serverSyncTree_, a.path, m, a.currentWriteId, a.applyLocally)), s = s.concat(Eu(t.serverSyncTree_, p, !0));
        } else
          l = !0, h = "nodata", s = s.concat(Eu(t.serverSyncTree_, a.currentWriteId, !0));
      }
    no(t.eventQueue_, n, s), s = [], l && (e[o].status = 2, function(u) {
      setTimeout(u, Math.floor(0));
    }(e[o].unwatcher), e[o].onComplete && (h === "nodata" ? r.push(() => e[o].onComplete(null, !1, e[o].currentInputSnapshot)) : r.push(() => e[o].onComplete(new Error(h), !1, null))));
  }
  aO(t, t.transactionQueueTree_);
  for (let o = 0; o < r.length; o++)
    hg(r[o]);
  cq(t, t.transactionQueueTree_);
}
function sse(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = Se(e); n !== null && fg(r) === void 0; )
    r = sq(r, n), e = nn(e), n = Se(e);
  return r;
}
function ise(t, e) {
  const n = [];
  return ose(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function ose(t, e, n) {
  const r = fg(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  rO(e, (s) => {
    ose(t, s, n);
  });
}
function aO(t, e) {
  const n = fg(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, Jre(e, n.length > 0 ? n : void 0);
  }
  rO(e, (r) => {
    aO(t, r);
  });
}
function lq(t, e) {
  const n = bb(sse(t, e)), r = sq(t.transactionQueueTree_, e);
  return rqe(r, (s) => {
    zM(t, s);
  }), zM(t, r), Zre(r, (s) => {
    zM(t, s);
  }), n;
}
function zM(t, e) {
  const n = fg(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (O(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (O(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(Eu(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? Jre(e, void 0) : n.length = i + 1, no(t.eventQueue_, bb(e), s);
    for (let o = 0; o < r.length; o++)
      hg(r[o]);
  }
}
function Oqe(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function Dqe(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : Us(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const uK = function(t, e) {
  const n = Aqe(t), r = n.namespace;
  n.domain === "firebase.com" && wl(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && wl("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || W2e();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new hre(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new xt(n.pathString)
  };
}, Aqe = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = Oqe(t.substring(h, u)));
    const d = Dqe(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class Mqe {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + mr(this.snapshot.exportVal());
  }
}
class xqe {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class ase {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return O(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class uq {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return Ye(this._path) ? null : UB(this._path);
  }
  get ref() {
    return new Fl(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = K$(this._queryParams), n = AB(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return K$(this._queryParams);
  }
  isEqual(e) {
    if (e = us(e), !(e instanceof uq))
      return !1;
    const n = this._repo === e._repo, r = FB(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + bWe(this._path);
  }
}
class Fl extends uq {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new qB(), !1);
  }
  get parent() {
    const e = wre(this._path);
    return e === null ? null : new Fl(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class bC {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new xt(e), r = RU(this.ref, e);
    return new bC(this._node.getChild(n), r, Ln);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (n, r) => e(new bC(r, RU(this.ref, n), Ln)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new xt(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function JE(t, e) {
  return t = us(t), t._checkNotDeleted("ref"), e !== void 0 ? RU(t._root, e) : t._root;
}
function RU(t, e) {
  return t = us(t), Se(t._path) === null ? dqe("child", "path", e) : ese("child", "path", e), new Fl(t._repo, Nn(t._path, e));
}
function Lqe(t, e) {
  t = us(t), fqe("set", t._path), lqe("set", e, t._path);
  const n = new mb();
  return Eqe(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function Uqe(t, e) {
  hqe("update", e, t._path);
  const n = new mb();
  return Sqe(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function Fqe(t) {
  t = us(t);
  const e = new ase(() => {
  }), n = new cO(e);
  return bqe(t._repo, t, n).then((r) => new bC(r, new Fl(t._repo, t._path), t._queryParams.getIndex()));
}
class cO {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new Mqe("value", this, new bC(e.snapshotNode, new Fl(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new xqe(this, e, n) : null;
  }
  matches(e) {
    return e instanceof cO ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function Yqe(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      lK(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new ase(n, i || void 0), a = new cO(o);
  return Rqe(t._repo, t, a), () => lK(t._repo, t, a);
}
function Wqe(t, e, n, r) {
  return Yqe(t, "value", e, n, r);
}
function Bqe(t, ...e) {
  let n = us(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
xBe(Fl);
WBe(Fl);
const qqe = "FIREBASE_DATABASE_EMULATOR_HOST", kU = {};
let Hqe = !1;
function jqe(t, e, n, r) {
  t.repoInfo_ = new hre(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function Vqe(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || wl("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), Jr("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = uK(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[qqe]), c ? (i = `http://${c}?ns=${a.namespace}`, o = uK(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new Q2e(t.name, t.options, e);
  pqe("Invalid Firebase Database URL", o), Ye(o.path) || wl("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = Gqe(a, t, l, new J2e(t.name, n));
  return new $qe(h, t);
}
function zqe(t, e) {
  const n = kU[e];
  (!n || n[t.key] !== t) && wl(`Database ${e}(${t.repoInfo_}) has already been deleted.`), kqe(t), delete n[t.key];
}
function Gqe(t, e, n, r) {
  let s = kU[e.name];
  s || (s = {}, kU[e.name] = s);
  let i = s[t.toURLString()];
  return i && wl("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new vqe(t, Hqe, n, r), s[t.toURLString()] = i, i;
}
class $qe {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (wqe(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Fl(this._repo, ut())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (zqe(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && wl("Cannot call " + e + " on a deleted database.");
  }
}
function Kqe(t = $ne(), e) {
  const n = Gne(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = Wne("database");
    r && Jqe(n, ...r);
  }
  return n;
}
function Jqe(t, e, n, r = {}) {
  t = us(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && wl("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && wl('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new XS(XS.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : Bne(r.mockUserToken, t.app.options.projectId);
    i = new XS(o);
  }
  jqe(s, e, n, i);
}
function Qqe(t) {
  M2e(yb), jf(new xh(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return Vqe(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), Xc(D$, A$, t), Xc(D$, A$, "esm2017");
}
el.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
el.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
Qqe();
const cse = "firebasestorage.googleapis.com", lse = "storageBucket", Zqe = 2 * 60 * 1e3, Xqe = 10 * 60 * 1e3;
class Jn extends Ul {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(GM(e), `Firebase Storage: ${n} (${GM(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, Jn.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return GM(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var Hn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(Hn || (Hn = {}));
function GM(t) {
  return "storage/" + t;
}
function hq() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new Jn(Hn.UNKNOWN, t);
}
function eHe(t) {
  return new Jn(Hn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function tHe(t) {
  return new Jn(Hn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function nHe() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new Jn(Hn.UNAUTHENTICATED, t);
}
function rHe() {
  return new Jn(Hn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function sHe(t) {
  return new Jn(Hn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function iHe() {
  return new Jn(Hn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function oHe() {
  return new Jn(Hn.CANCELED, "User canceled the upload/download.");
}
function aHe(t) {
  return new Jn(Hn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function cHe(t) {
  return new Jn(Hn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function lHe() {
  return new Jn(Hn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + lse + "' property when initializing the app?");
}
function uHe() {
  return new Jn(Hn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function hHe() {
  return new Jn(Hn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function dHe(t) {
  return new Jn(Hn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function NU(t) {
  return new Jn(Hn.INVALID_ARGUMENT, t);
}
function use() {
  return new Jn(Hn.APP_DELETED, "The Firebase app was deleted.");
}
function fHe(t) {
  return new Jn(Hn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function rw(t, e) {
  return new Jn(Hn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function Ay(t) {
  throw new Jn(Hn.INTERNAL_ERROR, "Internal error: " + t);
}
class Ei {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = Ei.makeFromUrl(e, n);
    } catch {
      return new Ei(e, "");
    }
    if (r.path === "")
      return r;
    throw cHe(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(g) {
      g.path.charAt(g.path.length - 1) === "/" && (g.path_ = g.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(g) {
      g.path_ = decodeURIComponent(g.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === cse ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), y = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let g = 0; g < y.length; g++) {
      const w = y[g], de = w.regex.exec(e);
      if (de) {
        const we = de[w.indices.bucket];
        let fe = de[w.indices.path];
        fe || (fe = ""), r = new Ei(we, fe), w.postModify(r);
        break;
      }
    }
    if (r == null)
      throw aHe(e);
    return r;
  }
}
class pHe {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function _He(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let y;
    a === 1 ? (a = 2, y = 0) : y = (r + Math.random()) * 1e3, u(y);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function mHe(t) {
  t(!1);
}
function gHe(t) {
  return t !== void 0;
}
function yHe(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function dq(t) {
  return typeof t == "string" || t instanceof String;
}
function hK(t) {
  return fq() && t instanceof Blob;
}
function fq() {
  return typeof Blob < "u" && !pYe();
}
function dK(t, e, n, r) {
  if (r < e)
    throw NU(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw NU(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function lO(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function hse(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var nf;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(nf || (nf = {}));
function vHe(t, e) {
  const n = t >= 500 && t < 600, r = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, s = e.indexOf(t) !== -1;
  return n || r || s;
}
class wHe {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new QE(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === nf.NO_ERROR, c = i.getStatus();
        if (!a || vHe(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === nf.ABORT;
          r(!1, new QE(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new QE(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          gHe(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = hq();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? use() : oHe();
        o(c);
      } else {
        const c = iHe();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new QE(!1, null, !0)) : this.backoffId_ = _He(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && mHe(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class QE {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function CHe(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function THe(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function bHe(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function EHe(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function SHe(t, e, n, r, s, i, o = !0) {
  const a = hse(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return bHe(l, e), CHe(l, n), THe(l, i), EHe(l, r), new wHe(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function IHe() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function RHe(...t) {
  const e = IHe();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (fq())
      return new Blob(t);
    throw new Jn(Hn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function kHe(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function NHe(t) {
  if (typeof atob > "u")
    throw dHe("base-64");
  return atob(t);
}
const ka = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class $M {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function PHe(t, e) {
  switch (t) {
    case ka.RAW:
      return new $M(dse(e));
    case ka.BASE64:
    case ka.BASE64URL:
      return new $M(fse(t, e));
    case ka.DATA_URL:
      return new $M(DHe(e), AHe(e));
  }
  throw hq();
}
function dse(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const s = r, i = t.charCodeAt(++n);
        r = 65536 | (s & 1023) << 10 | i & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function OHe(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw rw(ka.DATA_URL, "Malformed data URL.");
  }
  return dse(e);
}
function fse(t, e) {
  switch (t) {
    case ka.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw rw(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case ka.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw rw(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = NHe(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : rw(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class pse {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw rw(ka.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = MHe(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function DHe(t) {
  const e = new pse(t);
  return e.base64 ? fse(ka.BASE64, e.rest) : OHe(e.rest);
}
function AHe(t) {
  return new pse(t).contentType;
}
function MHe(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class gu {
  constructor(e, n) {
    let r = 0, s = "";
    hK(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (hK(this.data_)) {
      const r = this.data_, s = kHe(r, e, n);
      return s === null ? null : new gu(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new gu(r, !0);
    }
  }
  static getBlob(...e) {
    if (fq()) {
      const n = e.map((r) => r instanceof gu ? r.data_ : r);
      return new gu(RHe.apply(null, n));
    } else {
      const n = e.map((o) => dq(o) ? PHe(ka.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new gu(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function _se(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return yHe(e) ? e : null;
}
function xHe(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function LHe(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function mse(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function UHe(t, e) {
  return e;
}
class bs {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || UHe;
  }
}
let ZE = null;
function FHe(t) {
  return !dq(t) || t.length < 2 ? t : mse(t);
}
function gse() {
  if (ZE)
    return ZE;
  const t = [];
  t.push(new bs("bucket")), t.push(new bs("generation")), t.push(new bs("metageneration")), t.push(new bs("name", "fullPath", !0));
  function e(i, o) {
    return FHe(o);
  }
  const n = new bs("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new bs("size");
  return s.xform = r, t.push(s), t.push(new bs("timeCreated")), t.push(new bs("updated")), t.push(new bs("md5Hash", null, !0)), t.push(new bs("cacheControl", null, !0)), t.push(new bs("contentDisposition", null, !0)), t.push(new bs("contentEncoding", null, !0)), t.push(new bs("contentLanguage", null, !0)), t.push(new bs("contentType", null, !0)), t.push(new bs("metadata", "customMetadata", !0)), ZE = t, ZE;
}
function YHe(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new Ei(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function WHe(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return YHe(r, t), r;
}
function yse(t, e, n) {
  const r = _se(e);
  return r === null ? null : WHe(t, r, n);
}
function BHe(t, e, n, r) {
  const s = _se(e);
  if (s === null || !dq(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((a) => {
    const c = t.bucket, l = t.fullPath, h = "/b/" + o(c) + "/o/" + o(l), u = lO(h, n, r), d = hse({
      alt: "media",
      token: a
    });
    return u + d;
  })[0];
}
function qHe(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class pq {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function vse(t) {
  if (!t)
    throw hq();
}
function HHe(t, e) {
  function n(r, s) {
    const i = yse(t, s, e);
    return vse(i !== null), i;
  }
  return n;
}
function jHe(t, e) {
  function n(r, s) {
    const i = yse(t, s, e);
    return vse(i !== null), BHe(i, s, t.host, t._protocol);
  }
  return n;
}
function wse(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? (
      /* This exact message string is the only consistent part of the */
      /* server's error response that identifies it as an App Check error. */
      n.getErrorText().includes("Firebase App Check token is invalid") ? s = rHe() : s = nHe()
    ) : n.getStatus() === 402 ? s = tHe(t.bucket) : n.getStatus() === 403 ? s = sHe(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function Cse(t) {
  const e = wse(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = eHe(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function VHe(t, e, n) {
  const r = e.fullServerUrl(), s = lO(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new pq(s, i, jHe(t, n), o);
  return a.errorHandler = Cse(e), a;
}
function zHe(t, e) {
  const n = e.fullServerUrl(), r = lO(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new pq(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = Cse(e), a;
}
function GHe(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function $He(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = GHe(null, e)), r;
}
function KHe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = $He(e, r, s), h = qHe(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = gu.getBlob(u, r, d);
  if (f === null)
    throw uHe();
  const p = { name: l.fullPath }, _ = lO(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new pq(_, m, HHe(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = wse(e), y;
}
class JHe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = nf.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = nf.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = nf.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw Ay("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw Ay("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw Ay("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw Ay("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw Ay("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class QHe extends JHe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function _q() {
  return new QHe();
}
class $f {
  constructor(e, n) {
    this._service = e, n instanceof Ei ? this._location = n : this._location = Ei.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new $f(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new Ei(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return mse(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = xHe(this._location.path);
    if (e === null)
      return null;
    const n = new Ei(this._location.bucket, e);
    return new $f(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw fHe(e);
  }
}
function ZHe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = KHe(t.storage, t._location, gse(), new gu(e, !0), n);
  return t.storage.makeRequestWithTokens(r, _q).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function XHe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = VHe(t.storage, t._location, gse());
  return t.storage.makeRequestWithTokens(e, _q).then((n) => {
    if (n === null)
      throw hHe();
    return n;
  });
}
function eje(t) {
  t._throwIfRoot("deleteObject");
  const e = zHe(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, _q);
}
function tje(t, e) {
  const n = LHe(t._location.path, e), r = new Ei(t._location.bucket, n);
  return new $f(t.storage, r);
}
function nje(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function rje(t, e) {
  return new $f(t, e);
}
function Tse(t, e) {
  if (t instanceof mq) {
    const n = t;
    if (n._bucket == null)
      throw lHe();
    const r = new $f(n, n._bucket);
    return e != null ? Tse(r, e) : r;
  } else
    return e !== void 0 ? tje(t, e) : t;
}
function sje(t, e) {
  if (e && nje(e)) {
    if (t instanceof mq)
      return rje(t, e);
    throw NU("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return Tse(t, e);
}
function fK(t, e) {
  const n = e == null ? void 0 : e[lse];
  return n == null ? null : Ei.makeFromBucketSpec(n, t);
}
function ije(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : Bne(s, t.app.options.projectId));
}
class mq {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = cse, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = Zqe, this._maxUploadRetryTime = Xqe, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = Ei.makeFromBucketSpec(s, this._host) : this._bucket = fK(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = Ei.makeFromBucketSpec(this._url, e) : this._bucket = fK(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    dK(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    dK(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new $f(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new pHe(use());
    {
      const o = SHe(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const pK = "@firebase/storage", _K = "0.11.2", bse = "storage";
function oje(t, e, n) {
  return t = us(t), ZHe(t, e, n);
}
function aje(t) {
  return t = us(t), XHe(t);
}
function cje(t) {
  return t = us(t), eje(t);
}
function KM(t, e) {
  return t = us(t), sje(t, e);
}
function lje(t = $ne(), e) {
  t = us(t);
  const n = Gne(t, bse).getImmediate({
    identifier: e
  }), r = Wne("storage");
  return r && uje(n, ...r), n;
}
function uje(t, e, n, r = {}) {
  ije(t, e, n, r);
}
function hje(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new mq(n, r, s, e, yb);
}
function dje() {
  jf(new xh(
    bse,
    hje,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), Xc(pK, _K, ""), Xc(pK, _K, "esm2017");
}
dje();
class Ese {
  constructor() {
    this.db = Kqe(), this.storage = lje();
  }
  async get(e) {
    let n = await Fqe(JE(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await Uqe(JE(this.db, e), n);
  }
  async set(e, n) {
    return await Lqe(JE(this.db, e), n);
  }
  async watch(e, n, r) {
    return Wqe(Bqe(JE(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await oje(KM(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await cje(KM(this.storage, e));
  }
  async objectUrl(e) {
    return await aje(KM(this.storage, e));
  }
}
class fje extends Ese {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch($p.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set($p.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set($p.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get($p.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get($p.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
const pje = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, _je = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, mje = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, gje = {
  localhost: pje,
  hybrid: _je,
  ihunterdev: mje,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, yje = !1;
class N_ {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return gje[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && yje ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  // static firebaseConfig() {
  //     if(!process)
  //     return process && process?.env?.FIREBASE_CONFIG && JSON.parse(process.env.FIREBASE_CONFIG);
  // }
  // static isBrowser() {
  //     return isBrowser;
  // }
  // static isNode() {
  //     return isNode;
  // }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class XE extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const yi = "application/json", vje = "application/octet-stream";
class Sse {
  constructor() {
  }
  get apiServer() {
    return N_.config.functionURL;
  }
  get tileServer() {
    return N_.config.tileURL;
  }
  get webServer() {
    return N_.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let s = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || yi,
        Accept: r || yi
      }
    };
    return this.request(e, s);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: yi
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let i = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || yi,
        Accept: s || yi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async patch(e, n, r, s) {
    let i = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || yi,
        Accept: s || yi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async post(e, n, r, s) {
    let i = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || yi,
        Accept: s || yi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async delete(e, n, r) {
    let s = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || yi,
        Accept: r || yi
      }
    };
    return this.request(e, s);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new XE(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(yi)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new XE(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(yi) ? r(await i.json()) : o && o.startsWith(vje) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new XE(500, i.message, i))) : s(new XE(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class wje {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var e0 = { exports: {} };
function Cje(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
e0.exports = Cje;
const J = (e0.exports == null ? {} : e0.exports).default || e0.exports, iu = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, mK = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class Ui {
  static TileServer() {
    return N_.config.tileURL;
  }
  static WebServer() {
    return N_.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return iu.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return J(iu.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return J(iu.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return J(iu.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + J(mK.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), J(iu.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), J(iu.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + J(mK.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return J(iu.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return J(iu.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const Js = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, JM = {
  REGIONS: "/product/regions/{region}"
}, Qs = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, gK = {
  PRODUCT: "/product/skus/{product}"
}, yK = {
  PRODUCT: "/product/prices/{product}"
}, Tje = {
  PROMOTIONS: "/store/promotions"
};
class bje {
  static AllRegions() {
    return QM(J(JM.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return JM.REGIONS;
  }
  static Regions(e) {
    return J(JM.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Qs.REGION;
  }
  static Region(e) {
    return J(Qs.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return Js.REGION;
  }
  static RegionMetadata(e) {
    return J(Js.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Qs.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return J(Qs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return Js.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return J(Js.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Qs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return J(Qs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return Js.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return J(Js.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Qs.RESOURCE;
  }
  static Resource(e, n) {
    return J(Qs.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return Js.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return J(Js.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Qs.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return J(Qs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return Js.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return J(Js.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Qs.MAP;
  }
  static Map(e, n, r, s) {
    return J(Qs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return Js.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return J(Js.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Qs.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return J(Qs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return Js.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return J(Js.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return QM(J(gK.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return J(gK.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return QM(J(yK.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return J(yK.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return Tje.PROMOTIONS;
  }
}
const QM = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class Eje {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const vK = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, wK = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, CK = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, TK = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, ZM = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, bK = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, Sje = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
class ma {
  static RegionPropertyMap() {
    return vK.METADATA;
  }
  static SubscriptionPropertyMap() {
    return wK.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return CK.METADATA;
  }
  static ResourcePropertyMap() {
    return TK.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return ZM.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return ZM.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return bK.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties(vK.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(wK.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(CK.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(TK.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(ZM.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(bK.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(Sje.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
}
var t0 = { exports: {} };
function Ije(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
t0.exports = Ije;
const n0 = (t0.exports == null ? {} : t0.exports).default || t0.exports;
var r0 = { exports: {} };
async function Rje(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
r0.exports = Rje;
const PU = (r0.exports == null ? {} : r0.exports).default || r0.exports;
Promise.allMap = PU;
class kje {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, ma.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, ma.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, ma.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, ma.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, ma.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[ma.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, ma.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = n0(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = n0(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[ma.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, ma.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = n0(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class Nje extends Eje {
  constructor(e) {
    super(e), this.http = new Sse(), this.builder = new kje(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(Ui.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(Ui.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(Ui.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Ui.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(Ui.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Ui.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(Ui.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + Ui.CountyMetadata(e)), await this.http.getJson(Ui.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + Ui.CountyProductMetadata(e, n)), await this.http.getJson(Ui.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const My = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class Pje {
  static Apple(e) {
    return J(My.APPLE, { uid: e });
  }
  static Android(e) {
    return J(My.ANDROID, { uid: e });
  }
  static Web(e) {
    return J(My.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return J(My.WEB, { uid: e }) + J(My.SKU, { region: n, sku: r });
  }
}
const jr = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, xy = {
  CUSTOMER: `/${jr.USERS}/{uid}/${jr.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${jr.USERS}/{uid}/${jr.SUBSCRIPTIONS}`,
  PRODUCT: `/${jr.PRODUCTS}/{product}`,
  PRICE: `/${jr.PRODUCTS}/{product}/${jr.PRICES}/{price}`
};
class Oje {
  static Customer(e, n) {
    return J(xy.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return J(xy.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return xy.CUSTOMER;
  }
  static PriceRoute() {
    return xy.PRICE;
  }
  static ProductRoute() {
    return xy.PRODUCT;
  }
  static Users() {
    return jr.USERS;
  }
  static Customers() {
    return jr.CUSTOMERS;
  }
  static Subscriptions() {
    return jr.SUBSCRIPTIONS;
  }
  static Schedules() {
    return jr.SCHEDULES;
  }
  static Products() {
    return jr.PRODUCTS;
  }
  static Prices() {
    return jr.PRICES;
  }
  static Promotions() {
    return jr.PROMOTIONS;
  }
  static Payments() {
    return jr.PAYMENTS;
  }
  static Coupons() {
    return jr.COUPONS;
  }
  static Invoices() {
    return jr.INVOICES;
  }
}
const ad = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class Kp {
  static Preferences(e) {
    return J(ad.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return J(ad.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return ad.OPT_IN;
  }
  static Status(e) {
    return J(ad.STATUS, { uid: e });
  }
  static StatusRoute() {
    return ad.STATUS;
  }
  static Email(e) {
    return J(ad.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return ad.EMAIL;
  }
}
const EK = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class Dje {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, EK.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * EK.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const cd = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, XM = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, SK = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, e1 = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, IK = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, Ly = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, ld = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, RK = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class gq {
  static OutgoingContactRequestProcessedRoute() {
    return cd.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return J(cd.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return J(cd.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return cd.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return J(cd.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return cd.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return J(cd.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return XM.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return J(XM.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return J(XM.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return J(SK.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return SK.DATA;
  }
  static Chat(e) {
    return J(e1.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var r, s;
    const i = Date.now();
    let o = e.length > 2, a = {}, c = {}, l = {};
    for (let u of e)
      a[u] = !0, c[u] = ((r = n == null ? void 0 : n.lastviewed) == null ? void 0 : r[u]) || i, l[u] = ((s = n == null ? void 0 : n.lastcleared) == null ? void 0 : s[u]) || i;
    let h = {
      participants: a,
      isgroupchat: o,
      lastviewed: c,
      lastcleared: l
    };
    return o && (h.chatname = e1.GROUP_NAME), h;
  }
  static ChatParticipants(e) {
    return J(e1.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return J(IK.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return IK.DATA;
  }
  static MessageRoute() {
    return Ly.ROUTE;
  }
  static Message(e, n) {
    const r = gq.MessageKey(n);
    return J(Ly.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: ld.NOTIFY_ALL,
      type: Ly.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return J(Ly.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return Ly.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return J(ld.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return J(ld.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === ld.NOTIFY_ALL ? ld.TITLE_FROM_NOTIFY_ALL : J(ld.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return J(RK.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return ld.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return RK.UNKNOWN_NAME;
  }
}
const Ise = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
}, D = function(t, e) {
  if (!t)
    throw pg(e);
}, pg = function(t) {
  return new Error("Firebase Database (" + Ise.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
}, Rse = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, Aje = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, yq = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(Rse(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : Aje(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], o = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const a = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const c = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || o == null || a == null || c == null)
        throw new Mje();
      const l = i << 2 | o >> 4;
      if (r.push(l), a !== 64) {
        const h = o << 4 & 240 | a >> 2;
        if (r.push(h), c !== 64) {
          const u = a << 6 & 192 | c;
          r.push(u);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class Mje extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const kse = function(t) {
  const e = Rse(t);
  return yq.encodeByteArray(e, !0);
}, zR = function(t) {
  return kse(t).replace(/\./g, "");
}, GR = function(t) {
  try {
    return yq.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function xje(t) {
  return Nse(void 0, t);
}
function Nse(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !Lje(n) || (t[n] = Nse(t[n], e[n]));
  return t;
}
function Lje(t) {
  return t !== "__proto__";
}
function Uje() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const Fje = () => Uje().__FIREBASE_DEFAULTS__, Yje = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, Wje = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && GR(t[1]);
  return e && JSON.parse(e);
}, uO = () => {
  try {
    return Fje() || Yje() || Wje();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, Bje = (t) => {
  var e, n;
  return (n = (e = uO()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, Pse = (t) => {
  const e = Bje(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, qje = () => {
  var t;
  return (t = uO()) === null || t === void 0 ? void 0 : t.config;
}, Hje = (t) => {
  var e;
  return (e = uO()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
class Sb {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function Ose(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    zR(JSON.stringify(n)),
    zR(JSON.stringify(o)),
    ""
  ].join(".");
}
function La() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function vq() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(La());
}
function jje() {
  var t;
  const e = (t = uO()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function Vje() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function Dse() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function Ase() {
  return Ise.NODE_ADMIN === !0;
}
function zje() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function Gje() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const $je = "FirebaseError";
class Yl extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = $je, Object.setPrototypeOf(this, Yl.prototype), Error.captureStackTrace && Error.captureStackTrace(this, Ib.prototype.create);
  }
}
class Ib {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? Kje(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new Yl(s, a, r);
  }
}
function Kje(t, e) {
  return t.replace(Jje, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const Jje = /\{\$([^}]+)}/g;
function EC(t) {
  return JSON.parse(t);
}
function gr(t) {
  return JSON.stringify(t);
}
const Mse = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = EC(GR(i[0]) || ""), n = EC(GR(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, Qje = function(t) {
  const e = Mse(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, Zje = function(t) {
  const e = Mse(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function $a(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function xm(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function kK(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function $R(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function OU(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (NK(i) && NK(o)) {
      if (!OU(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function NK(t) {
  return t !== null && typeof t == "object";
}
function wq(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function Ev(t) {
  const e = {};
  return t.replace(/^\?/, "").split("&").forEach((n) => {
    if (n) {
      const [r, s] = n.split("=");
      e[decodeURIComponent(r)] = decodeURIComponent(s);
    }
  }), e;
}
function Sv(t) {
  const e = t.indexOf("?");
  if (!e)
    return "";
  const n = t.indexOf("#", e);
  return t.substring(e, n > 0 ? n : void 0);
}
class Xje {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function eVe(t, e) {
  const n = new tVe(t, e);
  return n.subscribe.bind(n);
}
class tVe {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    nVe(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = t1), s.error === void 0 && (s.error = t1), s.complete === void 0 && (s.complete = t1);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function nVe(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function t1() {
}
function hO(t, e) {
  return `${t} failed: ${e} argument `;
}
const rVe = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, D(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, dO = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function hs(t) {
  return t && t._delegate ? t._delegate : t;
}
class Yh {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const Nd = "[DEFAULT]";
class sVe {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new Sb();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (oVe(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: Nd });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = Nd) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = Nd) {
    return this.instances.has(e);
  }
  getOptions(e = Nd) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: iVe(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = Nd) {
    return this.component ? this.component.multipleInstances ? e : Nd : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function iVe(t) {
  return t === Nd ? void 0 : t;
}
function oVe(t) {
  return t.instantiationMode === "EAGER";
}
class aVe {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new sVe(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var Pt;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(Pt || (Pt = {}));
const cVe = {
  debug: Pt.DEBUG,
  verbose: Pt.VERBOSE,
  info: Pt.INFO,
  warn: Pt.WARN,
  error: Pt.ERROR,
  silent: Pt.SILENT
}, lVe = Pt.INFO, uVe = {
  [Pt.DEBUG]: "log",
  [Pt.VERBOSE]: "log",
  [Pt.INFO]: "info",
  [Pt.WARN]: "warn",
  [Pt.ERROR]: "error"
}, hVe = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = uVe[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class Cq {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = lVe, this._logHandler = hVe, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in Pt))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? cVe[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, Pt.DEBUG, ...e), this._logHandler(this, Pt.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, Pt.VERBOSE, ...e), this._logHandler(this, Pt.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, Pt.INFO, ...e), this._logHandler(this, Pt.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, Pt.WARN, ...e), this._logHandler(this, Pt.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, Pt.ERROR, ...e), this._logHandler(this, Pt.ERROR, ...e);
  }
}
const dVe = (t, e) => e.some((n) => t instanceof n);
let PK, OK;
function fVe() {
  return PK || (PK = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function pVe() {
  return OK || (OK = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const xse = /* @__PURE__ */ new WeakMap(), DU = /* @__PURE__ */ new WeakMap(), Lse = /* @__PURE__ */ new WeakMap(), n1 = /* @__PURE__ */ new WeakMap(), Tq = /* @__PURE__ */ new WeakMap();
function _Ve(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(ih(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && xse.set(n, t);
  }).catch(() => {
  }), Tq.set(e, t), e;
}
function mVe(t) {
  if (DU.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  DU.set(t, e);
}
let AU = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return DU.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || Lse.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return ih(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function gVe(t) {
  AU = t(AU);
}
function yVe(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(r1(this), e, ...n);
    return Lse.set(r, e.sort ? e.sort() : [e]), ih(r);
  } : pVe().includes(t) ? function(...e) {
    return t.apply(r1(this), e), ih(xse.get(this));
  } : function(...e) {
    return ih(t.apply(r1(this), e));
  };
}
function vVe(t) {
  return typeof t == "function" ? yVe(t) : (t instanceof IDBTransaction && mVe(t), dVe(t, fVe()) ? new Proxy(t, AU) : t);
}
function ih(t) {
  if (t instanceof IDBRequest)
    return _Ve(t);
  if (n1.has(t))
    return n1.get(t);
  const e = vVe(t);
  return e !== t && (n1.set(t, e), Tq.set(e, t)), e;
}
const r1 = (t) => Tq.get(t);
function wVe(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = ih(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(ih(o.result), c.oldVersion, c.newVersion, ih(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const CVe = ["get", "getKey", "getAll", "getAllKeys", "count"], TVe = ["put", "add", "delete", "clear"], s1 = /* @__PURE__ */ new Map();
function DK(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (s1.get(e))
    return s1.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = TVe.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || CVe.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return s1.set(e, i), i;
}
gVe((t) => ({
  ...t,
  get: (e, n, r) => DK(e, n) || t.get(e, n, r),
  has: (e, n) => !!DK(e, n) || t.has(e, n)
}));
class bVe {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((e) => {
      if (EVe(e)) {
        const n = e.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((e) => e).join(" ");
  }
}
function EVe(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const MU = "@firebase/app", AK = "0.9.8", Kf = new Cq("@firebase/app"), SVe = "@firebase/app-compat", IVe = "@firebase/analytics-compat", RVe = "@firebase/analytics", kVe = "@firebase/app-check-compat", NVe = "@firebase/app-check", PVe = "@firebase/auth", OVe = "@firebase/auth-compat", DVe = "@firebase/database", AVe = "@firebase/database-compat", MVe = "@firebase/functions", xVe = "@firebase/functions-compat", LVe = "@firebase/installations", UVe = "@firebase/installations-compat", FVe = "@firebase/messaging", YVe = "@firebase/messaging-compat", WVe = "@firebase/performance", BVe = "@firebase/performance-compat", qVe = "@firebase/remote-config", HVe = "@firebase/remote-config-compat", jVe = "@firebase/storage", VVe = "@firebase/storage-compat", zVe = "@firebase/firestore", GVe = "@firebase/firestore-compat", $Ve = "firebase", KVe = "9.20.0", xU = "[DEFAULT]", JVe = {
  [MU]: "fire-core",
  [SVe]: "fire-core-compat",
  [RVe]: "fire-analytics",
  [IVe]: "fire-analytics-compat",
  [NVe]: "fire-app-check",
  [kVe]: "fire-app-check-compat",
  [PVe]: "fire-auth",
  [OVe]: "fire-auth-compat",
  [DVe]: "fire-rtdb",
  [AVe]: "fire-rtdb-compat",
  [MVe]: "fire-fn",
  [xVe]: "fire-fn-compat",
  [LVe]: "fire-iid",
  [UVe]: "fire-iid-compat",
  [FVe]: "fire-fcm",
  [YVe]: "fire-fcm-compat",
  [WVe]: "fire-perf",
  [BVe]: "fire-perf-compat",
  [qVe]: "fire-rc",
  [HVe]: "fire-rc-compat",
  [jVe]: "fire-gcs",
  [VVe]: "fire-gcs-compat",
  [zVe]: "fire-fst",
  [GVe]: "fire-fst-compat",
  "fire-js": "fire-js",
  [$Ve]: "fire-js-all"
}, KR = /* @__PURE__ */ new Map(), LU = /* @__PURE__ */ new Map();
function QVe(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    Kf.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function Jf(t) {
  const e = t.name;
  if (LU.has(e))
    return Kf.debug(`There were multiple attempts to register component ${e}.`), !1;
  LU.set(e, t);
  for (const n of KR.values())
    QVe(n, t);
  return !0;
}
function Use(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const ZVe = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, oh = new Ib("app", "Firebase", ZVe);
class XVe {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new Yh(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw oh.create("app-deleted", { appName: this._name });
  }
}
const Rb = KVe;
function e6e(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: xU, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw oh.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = qje()), !n)
    throw oh.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = KR.get(s);
  if (i) {
    if (OU(n, i.options) && OU(r, i.config))
      return i;
    throw oh.create("duplicate-app", { appName: s });
  }
  const o = new aVe(s);
  for (const c of LU.values())
    o.addComponent(c);
  const a = new XVe(n, r, o);
  return KR.set(s, a), a;
}
function Fse(t = xU) {
  const e = KR.get(t);
  if (!e && t === xU)
    return e6e();
  if (!e)
    throw oh.create("no-app", { appName: t });
  return e;
}
function tl(t, e, n) {
  var r;
  let s = (r = JVe[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), Kf.warn(a.join(" "));
    return;
  }
  Jf(new Yh(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const t6e = "firebase-heartbeat-database", n6e = 1, SC = "firebase-heartbeat-store";
let i1 = null;
function Yse() {
  return i1 || (i1 = wVe(t6e, n6e, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(SC);
      }
    }
  }).catch((t) => {
    throw oh.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), i1;
}
async function r6e(t) {
  try {
    return (await Yse()).transaction(SC).objectStore(SC).get(Wse(t));
  } catch (e) {
    if (e instanceof Yl)
      Kf.warn(e.message);
    else {
      const n = oh.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      Kf.warn(n.message);
    }
  }
}
async function MK(t, e) {
  try {
    const n = (await Yse()).transaction(SC, "readwrite");
    return await n.objectStore(SC).put(e, Wse(t)), n.done;
  } catch (n) {
    if (n instanceof Yl)
      Kf.warn(n.message);
    else {
      const r = oh.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      Kf.warn(r.message);
    }
  }
}
function Wse(t) {
  return `${t.name}!${t.options.appId}`;
}
const s6e = 1024, i6e = 30 * 24 * 60 * 60 * 1e3;
class o6e {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new c6e(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const e = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), n = xK();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === n || this._heartbeatsCache.heartbeats.some((r) => r.date === n)))
      return this._heartbeatsCache.heartbeats.push({ date: n, agent: e }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const s = new Date(r.date).valueOf();
        return Date.now() - s <= i6e;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = xK(), { heartbeatsToSend: n, unsentEntries: r } = a6e(this._heartbeatsCache.heartbeats), s = zR(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function xK() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function a6e(t, e = s6e) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), LK(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), LK(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class c6e {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return zje() ? Gje().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await r6e(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return MK(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return MK(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function LK(t) {
  return zR(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function l6e(t) {
  Jf(new Yh(
    "platform-logger",
    (e) => new bVe(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Jf(new Yh(
    "heartbeat",
    (e) => new o6e(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), tl(MU, AK, t), tl(MU, AK, "esm2017"), tl("fire-js", "");
}
l6e("");
const UK = "@firebase/database", FK = "0.14.4";
let Bse = "";
function u6e(t) {
  Bse = t;
}
class h6e {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), gr(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : EC(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class d6e {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return $a(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const qse = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new h6e(e);
    }
  } catch {
  }
  return new d6e();
}, Fd = qse("localStorage"), f6e = qse("sessionStorage"), P_ = new Cq("@firebase/database"), p6e = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), Hse = function(t) {
  const e = rVe(t), n = new Xje();
  n.update(e);
  const r = n.digest();
  return yq.encodeByteArray(r);
}, kb = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += kb.apply(null, r) : typeof r == "object" ? e += gr(r) : e += r, e += " ";
  }
  return e;
};
let sw = null, YK = !0;
const _6e = function(t, e) {
  D(!e, "Can't turn on custom loggers persistently."), P_.logLevel = Pt.VERBOSE, sw = P_.log.bind(P_);
}, Qr = function(...t) {
  if (YK === !0 && (YK = !1, sw === null && f6e.get("logging_enabled") === !0 && _6e()), sw) {
    const e = kb.apply(null, t);
    sw(e);
  }
}, Nb = function(t) {
  return function(...e) {
    Qr(t, ...e);
  };
}, UU = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + kb(...t);
  P_.error(e);
}, Cl = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${kb(...t)}`;
  throw P_.error(e), new Error(e);
}, Fs = function(...t) {
  const e = "FIREBASE WARNING: " + kb(...t);
  P_.warn(e);
}, m6e = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && Fs("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, bq = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, g6e = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, Lm = "[MIN_NAME]", Qf = "[MAX_NAME]", mp = function(t, e) {
  if (t === e)
    return 0;
  if (t === Lm || e === Qf)
    return -1;
  if (e === Lm || t === Qf)
    return 1;
  {
    const n = WK(t), r = WK(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, y6e = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, Uy = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + gr(e));
}, Eq = function(t) {
  if (typeof t != "object" || t === null)
    return gr(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += gr(e[r]), n += ":", n += Eq(t[e[r]]);
  return n += "}", n;
}, jse = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function ss(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const Vse = function(t) {
  D(!bq(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, v6e = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, w6e = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function C6e(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const T6e = new RegExp("^-?(0*)\\d{1,10}$"), b6e = -2147483648, E6e = 2147483647, WK = function(t) {
  if (T6e.test(t)) {
    const e = Number(t);
    if (e >= b6e && e <= E6e)
      return e;
  }
  return null;
}, _g = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw Fs("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, S6e = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, iw = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class I6e {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    Fs(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class R6e {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? (Qr("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', Fs(e);
  }
}
class s0 {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
s0.OWNER = "owner";
const Sq = "5", zse = "v", Gse = "s", $se = "r", Kse = "f", Jse = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, Qse = "ls", Zse = "p", FU = "ac", Xse = "websocket", eie = "long_polling";
class tie {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = Fd.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && Fd.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function k6e(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function nie(t, e, n) {
  D(typeof e == "string", "typeof type must == string"), D(typeof n == "object", "typeof params must == object");
  let r;
  if (e === Xse)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === eie)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  k6e(t) && (n.ns = t.namespace);
  const s = [];
  return ss(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class N6e {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    $a(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return xje(this.counters_);
  }
}
const o1 = {}, a1 = {};
function Iq(t) {
  const e = t.toString();
  return o1[e] || (o1[e] = new N6e()), o1[e];
}
function P6e(t, e) {
  const n = t.toString();
  return a1[n] || (a1[n] = e()), a1[n];
}
class O6e {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && _g(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const BK = "start", D6e = "close", A6e = "pLPCommand", M6e = "pRTLPCB", rie = "id", sie = "pw", iie = "ser", x6e = "cb", L6e = "seg", U6e = "ts", F6e = "d", Y6e = "dframe", oie = 1870, aie = 30, W6e = oie - aie, B6e = 25e3, q6e = 3e4;
let H6e = class Iv {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = Nb(e), this.stats_ = Iq(n), this.urlFn = (c) => (this.appCheckToken && (c[FU] = this.appCheckToken), nie(n, eie, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new O6e(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(q6e)), g6e(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new Rq((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === BK)
            this.id = a, this.password = c;
          else if (o === D6e)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[BK] = "t", r[iie] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[x6e] = this.scriptTagHolder.uniqueCallbackIdentifier), r[zse] = Sq, this.transportSessionId && (r[Gse] = this.transportSessionId), this.lastSessionId && (r[Qse] = this.lastSessionId), this.applicationId && (r[Zse] = this.applicationId), this.appCheckToken && (r[FU] = this.appCheckToken), typeof location < "u" && location.hostname && Jse.test(location.hostname) && (r[$se] = Kse);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    Iv.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    Iv.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return Iv.forceAllow_ ? !0 : !Iv.forceDisallow_ && typeof document < "u" && document.createElement != null && !v6e() && !w6e();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = gr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = kse(n), s = jse(r, W6e);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[Y6e] = "t", r[rie] = e, r[sie] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = gr(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
};
class Rq {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = p6e(), window[A6e + this.uniqueCallbackIdentifier] = e, window[M6e + this.uniqueCallbackIdentifier] = n, this.myIFrame = Rq.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        Qr("frame writing exception"), a.stack && Qr(a.stack), Qr(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || Qr("No IE domain setting required");
      } catch {
        const n = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + n + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[rie] = this.myID, e[sie] = this.myPW, e[iie] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + aie + r.length <= oie; ) {
        const i = this.pendingSegs.shift();
        r = r + "&" + L6e + s + "=" + i.seg + "&" + U6e + s + "=" + i.ts + "&" + F6e + s + "=" + i.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(B6e)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          Qr("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const j6e = 16384, V6e = 45e3;
let JR = null;
typeof MozWebSocket < "u" ? JR = MozWebSocket : typeof WebSocket < "u" && (JR = WebSocket);
class Io {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = Nb(this.connId), this.stats_ = Iq(n), this.connURL = Io.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[zse] = Sq, typeof location < "u" && location.hostname && Jse.test(location.hostname) && (o[$se] = Kse), n && (o[Gse] = n), r && (o[Qse] = r), s && (o[FU] = s), i && (o[Zse] = i), nie(e, Xse, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, Fd.set("previous_websocket_failure", !0);
    try {
      let r;
      Ase(), this.mySock = new JR(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    Io.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && JR !== null && !Io.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return Fd.isInMemoryStorage || Fd.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    Fd.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = EC(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (D(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = gr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = jse(n, j6e);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(V6e));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
Io.responsesRequiredToBeHealthy = 2;
Io.healthyTimeout = 3e4;
class IC {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [H6e, Io];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = Io && Io.isAvailable();
    let r = n && !Io.previouslyFailed();
    if (e.webSocketOnly && (n || Fs("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [Io];
    else {
      const s = this.transports_ = [];
      for (const i of IC.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      IC.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
IC.globalTransportInitialized_ = !1;
const z6e = 6e4, G6e = 5e3, $6e = 10 * 1024, K6e = 100 * 1024, c1 = "t", qK = "d", J6e = "s", HK = "r", Q6e = "e", jK = "o", VK = "a", zK = "n", GK = "p", Z6e = "h";
class X6e {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = Nb("c:" + this.id + ":"), this.transportManager_ = new IC(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = iw(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > K6e ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > $6e ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (c1 in e) {
      const n = e[c1];
      n === VK ? this.upgradeIfSecondaryHealthy_() : n === HK ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === jK && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = Uy("t", e), r = Uy("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: GK, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: VK, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: zK, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = Uy("t", e), r = Uy("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = Uy(c1, e);
    if (qK in e) {
      const r = e[qK];
      if (n === Z6e) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === zK) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === J6e ? this.onConnectionShutdown_(r) : n === HK ? this.onReset_(r) : n === Q6e ? UU("Server Error: " + r) : n === jK ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : UU("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), Sq !== r && Fs("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), iw(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(z6e));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : iw(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(G6e));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: GK, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (Fd.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class cie {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class lie {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, D(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    D(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class QR extends lie {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !vq() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new QR();
  }
  getInitialEvent(e) {
    return D(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const $K = 32, KK = 768;
class Lt {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function ht() {
  return new Lt("");
}
function Ie(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function Wh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function rn(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new Lt(t.pieces_, e);
}
function kq(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function e4e(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function RC(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function uie(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new Lt(e, 0);
}
function Pn(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof Lt)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new Lt(n, 0);
}
function We(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function Os(t, e) {
  const n = Ie(t), r = Ie(e);
  if (n === null)
    return e;
  if (n === r)
    return Os(rn(t), rn(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function t4e(t, e) {
  const n = RC(t, 0), r = RC(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = mp(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function Nq(t, e) {
  if (Wh(t) !== Wh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function Ji(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (Wh(t) > Wh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class n4e {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = RC(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += dO(this.parts_[r]);
    hie(this);
  }
}
function r4e(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += dO(e), hie(t);
}
function s4e(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= dO(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function hie(t) {
  if (t.byteLength_ > KK)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + KK + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > $K)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + $K + ") or object contains a cycle " + Pd(t));
}
function Pd(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class Pq extends lie {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new Pq();
  }
  getInitialEvent(e) {
    return D(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const Fy = 1e3, i4e = 60 * 5 * 1e3, JK = 30 * 1e3, o4e = 1.3, a4e = 3e4, c4e = "server_kill", QK = 3;
class nl extends cie {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = nl.nextPersistentConnectionId_++, this.log_ = Nb("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = Fy, this.maxReconnectDelay_ = i4e, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !Ase())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    Pq.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && QR.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(gr(i)), D(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new Sb(), r = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (i) => {
        const o = i.d;
        i.s === "ok" ? n.resolve(o) : n.reject(o);
      }
    };
    this.outstandingGets_.push(r), this.outstandingGetCount_++;
    const s = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(s), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), D(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), D(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      nl.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && $a(e, "w")) {
      const r = xm(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        Fs(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || Zje(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = JK);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = Qje(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), D(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const s = r.d;
            this.log_("reportStats", "Error sending stats: " + s);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + gr(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : UU("Unrecognized action received from server: " + gr(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    D(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = Fy, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = Fy, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > a4e && (this.reconnectDelay_ = Fy), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * o4e);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + nl.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        D(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? Qr("getToken() completed but was canceled") : (Qr("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new X6e(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            Fs(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(c4e);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && Fs(u), c());
      }
    }
  }
  interrupt(e) {
    Qr("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    Qr("Resuming connection for reason: " + e), delete this.interruptReasons_[e], kK(this.interruptReasons_) && (this.reconnectDelay_ = Fy, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => Eq(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new Lt(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    Qr("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= QK && (this.reconnectDelay_ = JK, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    Qr("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= QK && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + Bse.replace(/\./g, "-")] = 1, vq() ? e["framework.cordova"] = 1 : Dse() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = QR.getInstance().currentlyOnline();
    return kK(this.interruptReasons_) && e;
  }
}
nl.nextPersistentConnectionId_ = 0;
nl.nextConnectionId_ = 0;
class Ae {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new Ae(e, n);
  }
}
class fO {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new Ae(Lm, e), s = new Ae(Lm, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return Ae.MIN;
  }
}
let eS;
class die extends fO {
  static get __EMPTY_NODE() {
    return eS;
  }
  static set __EMPTY_NODE(e) {
    eS = e;
  }
  compare(e, n) {
    return mp(e.name, n.name);
  }
  isDefinedOn(e) {
    throw pg("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return Ae.MIN;
  }
  maxPost() {
    return new Ae(Qf, eS);
  }
  makePost(e, n) {
    return D(typeof e == "string", "KeyIndex indexValue must always be a string."), new Ae(e, eS);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const O_ = new die();
class tS {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class Ur {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? Ur.RED, this.left = s ?? hi.EMPTY_NODE, this.right = i ?? hi.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new Ur(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return hi.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return hi.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, Ur.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, Ur.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
Ur.RED = !0;
Ur.BLACK = !1;
class l4e {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new Ur(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class hi {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = hi.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new hi(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, Ur.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new hi(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, Ur.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new tS(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new tS(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new tS(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new tS(this.root_, null, this.comparator_, !0, e);
  }
}
hi.EMPTY_NODE = new l4e();
function u4e(t, e) {
  return mp(t.name, e.name);
}
function Oq(t, e) {
  return mp(t, e);
}
let YU;
function h4e(t) {
  YU = t;
}
const fie = function(t) {
  return typeof t == "number" ? "number:" + Vse(t) : "string:" + t;
}, pie = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    D(typeof e == "string" || typeof e == "number" || typeof e == "object" && $a(e, ".sv"), "Priority must be a string or number.");
  } else
    D(t === YU || t.isEmpty(), "priority of unexpected type.");
  D(t === YU || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let ZK;
class Pr {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = Pr.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, D(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), pie(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    ZK = e;
  }
  static get __childrenNodeConstructor() {
    return ZK;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new Pr(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : Pr.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return We(e) ? this : Ie(e) === ".priority" ? this.priorityNode_ : Pr.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : Pr.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Ie(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (D(r !== ".priority" || Wh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, Pr.__childrenNodeConstructor.EMPTY_NODE.updateChild(rn(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + fie(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += Vse(this.value_) : e += this.value_, this.lazyHash_ = Hse(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === Pr.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof Pr.__childrenNodeConstructor ? -1 : (D(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = Pr.VALUE_TYPE_ORDER.indexOf(n), i = Pr.VALUE_TYPE_ORDER.indexOf(r);
    return D(s >= 0, "Unknown leaf type: " + n), D(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
Pr.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let _ie, mie;
function d4e(t) {
  _ie = t;
}
function f4e(t) {
  mie = t;
}
class p4e extends fO {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? mp(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return Ae.MIN;
  }
  maxPost() {
    return new Ae(Qf, new Pr("[PRIORITY-POST]", mie));
  }
  makePost(e, n) {
    const r = _ie(e);
    return new Ae(n, new Pr("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const Un = new p4e(), _4e = Math.log(2);
class m4e {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / _4e, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const ZR = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new Ur(d, u.node, Ur.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new Ur(d, u.node, Ur.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new Ur(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, Ur.BLACK) : (d(m, Ur.BLACK), d(m, Ur.RED));
    }
    return h;
  }, o = new m4e(t.length), a = i(o);
  return new hi(r || e, a);
};
let l1;
const Up = {};
class Ic {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return D(Up && Un, "ChildrenNode.ts has not been loaded"), l1 = l1 || new Ic({ ".priority": Up }, { ".priority": Un }), l1;
  }
  get(e) {
    const n = xm(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof hi ? n : null;
  }
  hasIndex(e) {
    return $a(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    D(e !== O_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(Ae.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = ZR(r, e.getCompare()) : a = Up;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new Ic(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = $R(this.indexes_, (s, i) => {
      const o = xm(this.indexSet_, i);
      if (D(o, "Missing index implementation for " + i), s === Up)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(Ae.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), ZR(a, o.getCompare());
        } else
          return Up;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new Ae(e.name, a))), c.insert(e, e.node);
      }
    });
    return new Ic(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = $R(this.indexes_, (s) => {
      if (s === Up)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new Ae(e.name, i)) : s;
      }
    });
    return new Ic(r, this.indexSet_);
  }
}
let Yy;
class ue {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && pie(this.priorityNode_), this.children_.isEmpty() && D(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return Yy || (Yy = new ue(new hi(Oq), null, Ic.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || Yy;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new ue(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? Yy : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = Ie(e);
    return n === null ? this : this.getImmediateChild(n).getChild(rn(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (D(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new Ae(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? Yy : this.priorityNode_;
      return new ue(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Ie(e);
    if (r === null)
      return n;
    {
      D(Ie(e) !== ".priority" || Wh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(rn(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(Un, (o, a) => {
      n[o] = a.val(e), r++, i && ue.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + fie(this.getPriority().val()) + ":"), this.forEachChild(Un, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : Hse(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new Ae(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new Ae(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new Ae(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, Ae.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, Ae.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === Pb ? -1 : 0;
  }
  withIndex(e) {
    if (e === O_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new ue(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === O_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(Un), s = n.getIterator(Un);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === O_ ? null : this.indexMap_.get(e.toString());
  }
}
ue.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class g4e extends ue {
  constructor() {
    super(new hi(Oq), ue.EMPTY_NODE, Ic.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return ue.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const Pb = new g4e();
Object.defineProperties(Ae, {
  MIN: {
    value: new Ae(Lm, ue.EMPTY_NODE)
  },
  MAX: {
    value: new Ae(Qf, Pb)
  }
});
die.__EMPTY_NODE = ue.EMPTY_NODE;
Pr.__childrenNodeConstructor = ue;
h4e(Pb);
f4e(Pb);
const y4e = !0;
function ur(t, e = null) {
  if (t === null)
    return ue.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), D(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new Pr(n, ur(e));
  }
  if (!(t instanceof Array) && y4e) {
    const n = [];
    let r = !1;
    if (ss(t, (i, o) => {
      if (i.substring(0, 1) !== ".") {
        const a = ur(o);
        a.isEmpty() || (r = r || !a.getPriority().isEmpty(), n.push(new Ae(i, a)));
      }
    }), n.length === 0)
      return ue.EMPTY_NODE;
    const s = ZR(n, u4e, (i) => i.name, Oq);
    if (r) {
      const i = ZR(n, Un.getCompare());
      return new ue(s, ur(e), new Ic({ ".priority": i }, { ".priority": Un }));
    } else
      return new ue(s, ur(e), Ic.Default);
  } else {
    let n = ue.EMPTY_NODE;
    return ss(t, (r, s) => {
      if ($a(t, r) && r.substring(0, 1) !== ".") {
        const i = ur(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(ur(e));
  }
}
d4e(ur);
class v4e extends fO {
  constructor(e) {
    super(), this.indexPath_ = e, D(!We(e) && Ie(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? mp(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = ur(e), s = ue.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new Ae(n, s);
  }
  maxPost() {
    const e = ue.EMPTY_NODE.updateChild(this.indexPath_, Pb);
    return new Ae(Qf, e);
  }
  toString() {
    return RC(this.indexPath_, 0).join("/");
  }
}
class w4e extends fO {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? mp(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return Ae.MIN;
  }
  maxPost() {
    return Ae.MAX;
  }
  makePost(e, n) {
    const r = ur(e);
    return new Ae(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const C4e = new w4e();
function gie(t) {
  return { type: "value", snapshotNode: t };
}
function Um(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function kC(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function NC(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function T4e(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class Dq {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    D(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(kC(n, a)) : D(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(Um(n, r)) : o.trackChildChange(NC(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(Un, (s, i) => {
      n.hasChild(s) || r.trackChildChange(kC(s, i));
    }), n.isLeafNode() || n.forEachChild(Un, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(NC(s, i, o));
      } else
        r.trackChildChange(Um(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? ue.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class PC {
  constructor(e) {
    this.indexedFilter_ = new Dq(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = PC.getStartPost_(e), this.endPost_ = PC.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new Ae(n, r)) || (r = ue.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = ue.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(ue.EMPTY_NODE);
    const i = this;
    return n.forEachChild(Un, (o, a) => {
      i.matches(new Ae(o, a)) || (s = s.updateImmediateChild(o, ue.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class b4e {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new PC(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new Ae(n, r)) || (r = ue.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = ue.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = ue.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(ue.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, ue.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    D(a.numChildren() === this.limit_, "");
    const c = new Ae(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(NC(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(kC(n, u));
        const p = a.updateImmediateChild(n, ue.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(Um(d.name, d.node)), p.updateImmediateChild(d.name, d.node)) : p;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(kC(l.name, l.node)), i.trackChildChange(Um(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, ue.EMPTY_NODE)) : e;
  }
}
class Aq {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = Un;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return D(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return D(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : Lm;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return D(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return D(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : Qf;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return D(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === Un;
  }
  copy() {
    const e = new Aq();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function E4e(t) {
  return t.loadsAllData() ? new Dq(t.getIndex()) : t.hasLimit() ? new b4e(t) : new PC(t);
}
function XK(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === Un ? n = "$priority" : t.index_ === C4e ? n = "$value" : t.index_ === O_ ? n = "$key" : (D(t.index_ instanceof v4e, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = gr(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = gr(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + gr(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = gr(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + gr(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function e5(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== Un && (e.i = t.index_.toString()), e;
}
class XR extends cie {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = Nb("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (D(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = XR.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = XK(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), xm(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = XR.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = XK(e._queryParams), r = e._path.toString(), s = new Sb();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + wq(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = EC(a.responseText);
            } catch {
              Fs("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && Fs("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class S4e {
  constructor() {
    this.rootNode_ = ue.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function ek() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function yie(t, e, n) {
  if (We(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = Ie(e);
    t.children.has(r) || t.children.set(r, ek());
    const s = t.children.get(r);
    e = rn(e), yie(s, e, n);
  }
}
function WU(t, e, n) {
  t.value !== null ? n(e, t.value) : I4e(t, (r, s) => {
    const i = new Lt(e.toString() + "/" + r);
    WU(s, i, n);
  });
}
function I4e(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class R4e {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && ss(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const t5 = 10 * 1e3, k4e = 30 * 1e3, N4e = 5 * 60 * 1e3;
class P4e {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new R4e(e);
    const r = t5 + (k4e - t5) * Math.random();
    iw(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    ss(e, (s, i) => {
      i > 0 && $a(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), iw(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * N4e));
  }
}
var Ao;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(Ao || (Ao = {}));
function Mq() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function xq() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function Lq(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class tk {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = Ao.ACK_USER_WRITE, this.source = Mq();
  }
  operationForChild(e) {
    if (We(this.path)) {
      if (this.affectedTree.value != null)
        return D(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new Lt(e));
        return new tk(ht(), n, this.revert);
      }
    } else return D(Ie(this.path) === e, "operationForChild called for unrelated child."), new tk(rn(this.path), this.affectedTree, this.revert);
  }
}
class OC {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = Ao.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return We(this.path) ? new OC(this.source, ht()) : new OC(this.source, rn(this.path));
  }
}
class Zf {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = Ao.OVERWRITE;
  }
  operationForChild(e) {
    return We(this.path) ? new Zf(this.source, ht(), this.snap.getImmediateChild(e)) : new Zf(this.source, rn(this.path), this.snap);
  }
}
class Fm {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = Ao.MERGE;
  }
  operationForChild(e) {
    if (We(this.path)) {
      const n = this.children.subtree(new Lt(e));
      return n.isEmpty() ? null : n.value ? new Zf(this.source, ht(), n.value) : new Fm(this.source, ht(), n);
    } else
      return D(Ie(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new Fm(this.source, rn(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class Bh {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (We(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = Ie(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class O4e {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function D4e(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(T4e(o.childName, o.snapshotNode));
  }), Wy(t, s, "child_removed", e, r, n), Wy(t, s, "child_added", e, r, n), Wy(t, s, "child_moved", i, r, n), Wy(t, s, "child_changed", e, r, n), Wy(t, s, "value", e, r, n), s;
}
function Wy(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => M4e(t, a, c)), o.forEach((a) => {
    const c = A4e(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function A4e(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function M4e(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw pg("Should only compare child_ events.");
  const r = new Ae(e.childName, e.snapshotNode), s = new Ae(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function pO(t, e) {
  return { eventCache: t, serverCache: e };
}
function ow(t, e, n, r) {
  return pO(new Bh(e, n, r), t.serverCache);
}
function vie(t, e, n, r) {
  return pO(t.eventCache, new Bh(e, n, r));
}
function nk(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function Xf(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let u1;
const x4e = () => (u1 || (u1 = new hi(y6e)), u1);
class Kt {
  constructor(e, n = x4e()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new Kt(null);
    return ss(e, (r, s) => {
      n = n.set(new Lt(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: ht(), value: this.value };
    if (We(e))
      return null;
    {
      const r = Ie(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(rn(e), n);
        return i != null ? { path: Pn(new Lt(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (We(e))
      return this;
    {
      const n = Ie(e), r = this.children.get(n);
      return r !== null ? r.subtree(rn(e)) : new Kt(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (We(e))
      return new Kt(n, this.children);
    {
      const r = Ie(e), s = (this.children.get(r) || new Kt(null)).set(rn(e), n), i = this.children.insert(r, s);
      return new Kt(this.value, i);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (We(e))
      return this.children.isEmpty() ? new Kt(null) : new Kt(null, this.children);
    {
      const n = Ie(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(rn(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new Kt(null) : new Kt(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (We(e))
      return this.value;
    {
      const n = Ie(e), r = this.children.get(n);
      return r ? r.get(rn(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (We(e))
      return n;
    {
      const r = Ie(e), s = (this.children.get(r) || new Kt(null)).setTree(rn(e), n);
      let i;
      return s.isEmpty() ? i = this.children.remove(r) : i = this.children.insert(r, s), new Kt(this.value, i);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(ht(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(Pn(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, ht(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (We(e))
      return null;
    {
      const i = Ie(e), o = this.children.get(i);
      return o ? o.findOnPath_(rn(e), Pn(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, ht(), n);
  }
  foreachOnPath_(e, n, r) {
    if (We(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = Ie(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(rn(e), Pn(n, s), r) : new Kt(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(ht(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(Pn(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class zo {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new zo(new Kt(null));
  }
}
function aw(t, e, n) {
  if (We(e))
    return new zo(new Kt(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = Os(s, e);
      return i = i.updateChild(o, n), new zo(t.writeTree_.set(s, i));
    } else {
      const s = new Kt(n), i = t.writeTree_.setTree(e, s);
      return new zo(i);
    }
  }
}
function BU(t, e, n) {
  let r = t;
  return ss(n, (s, i) => {
    r = aw(r, Pn(e, s), i);
  }), r;
}
function n5(t, e) {
  if (We(e))
    return zo.empty();
  {
    const n = t.writeTree_.setTree(e, new Kt(null));
    return new zo(n);
  }
}
function qU(t, e) {
  return gp(t, e) != null;
}
function gp(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(Os(n.path, e)) : null;
}
function r5(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(Un, (r, s) => {
    e.push(new Ae(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new Ae(r, s.value));
  }), e;
}
function ah(t, e) {
  if (We(e))
    return t;
  {
    const n = gp(t, e);
    return n != null ? new zo(new Kt(n)) : new zo(t.writeTree_.subtree(e));
  }
}
function HU(t) {
  return t.writeTree_.isEmpty();
}
function Ym(t, e) {
  return wie(ht(), t.writeTree_, e);
}
function wie(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (D(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = wie(Pn(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(Pn(t, ".priority"), r)), n;
  }
}
function _O(t, e) {
  return Eie(e, t);
}
function L4e(t, e, n, r, s) {
  D(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = aw(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function U4e(t, e, n, r) {
  D(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = BU(t.visibleWrites, e, n), t.lastWriteId = r;
}
function F4e(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function Y4e(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  D(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && W4e(a, r.path) ? s = !1 : Ji(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return B4e(t), !0;
    if (r.snap)
      t.visibleWrites = n5(t.visibleWrites, r.path);
    else {
      const a = r.children;
      ss(a, (c) => {
        t.visibleWrites = n5(t.visibleWrites, Pn(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function W4e(t, e) {
  if (t.snap)
    return Ji(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && Ji(Pn(t.path, n), e))
      return !0;
  return !1;
}
function B4e(t) {
  t.visibleWrites = Cie(t.allWrites, q4e, ht()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function q4e(t) {
  return t.visible;
}
function Cie(t, e, n) {
  let r = zo.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        Ji(n, o) ? (a = Os(n, o), r = aw(r, a, i.snap)) : Ji(o, n) && (a = Os(o, n), r = aw(r, ht(), i.snap.getChild(a)));
      else if (i.children) {
        if (Ji(n, o))
          a = Os(n, o), r = BU(r, a, i.children);
        else if (Ji(o, n))
          if (a = Os(o, n), We(a))
            r = BU(r, ht(), i.children);
          else {
            const c = xm(i.children, Ie(a));
            if (c) {
              const l = c.getChild(rn(a));
              r = aw(r, ht(), l);
            }
          }
      } else
        throw pg("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function Tie(t, e, n, r, s) {
  if (!r && !s) {
    const i = gp(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = ah(t.visibleWrites, e);
      if (HU(o))
        return n;
      if (n == null && !qU(o, ht()))
        return null;
      {
        const a = n || ue.EMPTY_NODE;
        return Ym(o, a);
      }
    }
  } else {
    const i = ah(t.visibleWrites, e);
    if (!s && HU(i))
      return n;
    if (!s && n == null && !qU(i, ht()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && (Ji(l.path, e) || Ji(e, l.path));
      }, a = Cie(t.allWrites, o, e), c = n || ue.EMPTY_NODE;
      return Ym(a, c);
    }
  }
}
function H4e(t, e, n) {
  let r = ue.EMPTY_NODE;
  const s = gp(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(Un, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = ah(t.visibleWrites, e);
    return n.forEachChild(Un, (o, a) => {
      const c = Ym(ah(i, new Lt(o)), a);
      r = r.updateImmediateChild(o, c);
    }), r5(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = ah(t.visibleWrites, e);
    return r5(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function j4e(t, e, n, r, s) {
  D(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = Pn(e, n);
  if (qU(t.visibleWrites, i))
    return null;
  {
    const o = ah(t.visibleWrites, i);
    return HU(o) ? s.getChild(n) : Ym(o, s.getChild(n));
  }
}
function V4e(t, e, n, r) {
  const s = Pn(e, n), i = gp(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = ah(t.visibleWrites, s);
    return Ym(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function z4e(t, e) {
  return gp(t.visibleWrites, e);
}
function G4e(t, e, n, r, s, i, o) {
  let a;
  const c = ah(t.visibleWrites, e), l = gp(c, ht());
  if (l != null)
    a = l;
  else if (n != null)
    a = Ym(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function $4e() {
  return {
    visibleWrites: zo.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function rk(t, e, n, r) {
  return Tie(t.writeTree, t.treePath, e, n, r);
}
function Uq(t, e) {
  return H4e(t.writeTree, t.treePath, e);
}
function s5(t, e, n, r) {
  return j4e(t.writeTree, t.treePath, e, n, r);
}
function sk(t, e) {
  return z4e(t.writeTree, Pn(t.treePath, e));
}
function K4e(t, e, n, r, s, i) {
  return G4e(t.writeTree, t.treePath, e, n, r, s, i);
}
function Fq(t, e, n) {
  return V4e(t.writeTree, t.treePath, e, n);
}
function bie(t, e) {
  return Eie(Pn(t.treePath, e), t.writeTree);
}
function Eie(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class J4e {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    D(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), D(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, NC(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, kC(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, Um(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, NC(r, e.snapshotNode, s.oldSnap));
      else
        throw pg("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class Q4e {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const Sie = new Q4e();
class Yq {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new Bh(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return Fq(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : Xf(this.viewCache_), i = K4e(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function Z4e(t) {
  return { filter: t };
}
function X4e(t, e) {
  D(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), D(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function e3e(t, e, n, r, s) {
  const i = new J4e();
  let o, a;
  if (n.type === Ao.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = jU(t, e, l.path, l.snap, r, s, i) : (D(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !We(l.path), o = ik(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === Ao.MERGE) {
    const l = n;
    l.source.fromUser ? o = n3e(t, e, l.path, l.children, r, s, i) : (D(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = VU(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === Ao.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = i3e(t, e, l.path, r, s, i) : o = r3e(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === Ao.LISTEN_COMPLETE)
    o = s3e(t, e, n.path, r, i);
  else
    throw pg("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return t3e(e, o, c), { viewCache: o, changes: c };
}
function t3e(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = nk(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(gie(nk(e)));
  }
}
function Iie(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (sk(r, n) != null)
    return e;
  {
    let a, c;
    if (We(n))
      if (D(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = Xf(e), h = l instanceof ue ? l : ue.EMPTY_NODE, u = Uq(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = rk(r, Xf(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = Ie(n);
      if (l === ".priority") {
        D(Wh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = s5(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = rn(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = s5(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = Fq(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return ow(e, a, o.isFullyInitialized() || We(n), t.filter.filtersNodes());
  }
}
function ik(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (We(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = Ie(n);
    if (!c.isCompleteForPath(n) && Wh(n) > 1)
      return e;
    const p = rn(n), _ = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), _) : l = h.updateChild(c.getNode(), f, _, p, Sie, null);
  }
  const u = vie(e, l, c.isFullyInitialized() || We(n), h.filtersNodes()), d = new Yq(s, u, i);
  return Iie(t, u, n, s, d, a);
}
function jU(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new Yq(s, e, i);
  if (We(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = ow(e, l, !0, t.filter.filtersNodes());
  else {
    const u = Ie(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = ow(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = rn(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (We(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? kq(d) === ".priority" && _.getChild(uie(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = ue.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = ow(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function i5(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function n3e(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = Pn(n, c);
    i5(e, Ie(h)) && (a = jU(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = Pn(n, c);
    i5(e, Ie(h)) || (a = jU(t, a, h, l, s, i, o));
  }), a;
}
function o5(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function VU(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  We(n) ? l = r : l = new Kt(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = o5(t, f, d);
      c = ik(t, c, new Lt(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = o5(t, p, d);
      c = ik(t, c, new Lt(u), _, s, i, o, a);
    }
  }), c;
}
function r3e(t, e, n, r, s, i, o) {
  if (sk(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (We(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return ik(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (We(n)) {
      let l = new Kt(null);
      return c.getNode().forEachChild(O_, (h, u) => {
        l = l.set(new Lt(h), u);
      }), VU(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new Kt(null);
    return r.foreach((h, u) => {
      const d = Pn(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), VU(t, e, n, l, s, i, a, o);
  }
}
function s3e(t, e, n, r, s) {
  const i = e.serverCache, o = vie(e, i.getNode(), i.isFullyInitialized() || We(n), i.isFiltered());
  return Iie(t, o, n, r, Sie, s);
}
function i3e(t, e, n, r, s, i) {
  let o;
  if (sk(r, n) != null)
    return e;
  {
    const a = new Yq(r, e, s), c = e.eventCache.getNode();
    let l;
    if (We(n) || Ie(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = rk(r, Xf(e));
      else {
        const u = e.serverCache.getNode();
        D(u instanceof ue, "serverChildren would be complete if leaf node"), h = Uq(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = Ie(n);
      let u = Fq(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, rn(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, ue.EMPTY_NODE, rn(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = rk(r, Xf(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || sk(r, ht()) != null, ow(e, l, o, t.filter.filtersNodes());
  }
}
class o3e {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new Dq(r.getIndex()), i = E4e(r);
    this.processor_ = Z4e(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(ue.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(ue.EMPTY_NODE, a.getNode(), null), h = new Bh(c, o.isFullyInitialized(), s.filtersNodes()), u = new Bh(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = pO(u, h), this.eventGenerator_ = new O4e(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function a3e(t) {
  return t.viewCache_.serverCache.getNode();
}
function c3e(t) {
  return nk(t.viewCache_);
}
function l3e(t, e) {
  const n = Xf(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !We(e) && !n.getImmediateChild(Ie(e)).isEmpty()) ? n.getChild(e) : null;
}
function a5(t) {
  return t.eventRegistrations_.length === 0;
}
function u3e(t, e) {
  t.eventRegistrations_.push(e);
}
function c5(t, e, n) {
  const r = [];
  if (n) {
    D(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function l5(t, e, n, r) {
  e.type === Ao.MERGE && e.source.queryId !== null && (D(Xf(t.viewCache_), "We should always have a full cache before handling merges"), D(nk(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = e3e(t.processor_, s, e, n, r);
  return X4e(t.processor_, i.viewCache), D(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, Rie(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function h3e(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(Un, (s, i) => {
    r.push(Um(s, i));
  }), n.isFullyInitialized() && r.push(gie(n.getNode())), Rie(t, r, n.getNode(), e);
}
function Rie(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return D4e(t.eventGenerator_, e, n, s);
}
let ok;
class kie {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function d3e(t) {
  D(!ok, "__referenceConstructor has already been defined"), ok = t;
}
function f3e() {
  return D(ok, "Reference.ts has not been loaded"), ok;
}
function p3e(t) {
  return t.views.size === 0;
}
function Wq(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return D(i != null, "SyncTree gave us an op for an invalid query."), l5(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat(l5(o, e, n, r));
    return i;
  }
}
function Nie(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = rk(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof ue ? (a = Uq(n, r), c = !1) : (a = ue.EMPTY_NODE, c = !1);
    const l = pO(new Bh(a, c, !1), new Bh(r, s, !1));
    return new o3e(e, l);
  }
  return o;
}
function _3e(t, e, n, r, s, i) {
  const o = Nie(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), u3e(o, n), h3e(o, n);
}
function m3e(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = qh(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(c5(l, n, r)), a5(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(c5(c, n, r)), a5(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !qh(t) && i.push(new (f3e())(e._repo, e._path)), { removed: i, events: o };
}
function Pie(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function ch(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || l3e(r, e);
  return n;
}
function Oie(t, e) {
  if (e._queryParams.loadsAllData())
    return mO(t);
  {
    const n = e._queryIdentifier;
    return t.views.get(n);
  }
}
function Die(t, e) {
  return Oie(t, e) != null;
}
function qh(t) {
  return mO(t) != null;
}
function mO(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let ak;
function g3e(t) {
  D(!ak, "__referenceConstructor has already been defined"), ak = t;
}
function y3e() {
  return D(ak, "Reference.ts has not been loaded"), ak;
}
let v3e = 1;
class u5 {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new Kt(null), this.pendingWriteTree_ = $4e(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function Aie(t, e, n, r, s) {
  return L4e(t.pendingWriteTree_, e, n, r, s), s ? mg(t, new Zf(Mq(), e, n)) : [];
}
function w3e(t, e, n, r) {
  U4e(t.pendingWriteTree_, e, n, r);
  const s = Kt.fromObject(n);
  return mg(t, new Fm(Mq(), e, s));
}
function Su(t, e, n = !1) {
  const r = F4e(t.pendingWriteTree_, e);
  if (Y4e(t.pendingWriteTree_, e)) {
    let s = new Kt(null);
    return r.snap != null ? s = s.set(ht(), !0) : ss(r.children, (i) => {
      s = s.set(new Lt(i), !0);
    }), mg(t, new tk(r.path, s, n));
  } else
    return [];
}
function Ob(t, e, n) {
  return mg(t, new Zf(xq(), e, n));
}
function C3e(t, e, n) {
  const r = Kt.fromObject(n);
  return mg(t, new Fm(xq(), e, r));
}
function T3e(t, e) {
  return mg(t, new OC(xq(), e));
}
function b3e(t, e, n) {
  const r = qq(t, n);
  if (r) {
    const s = Hq(r), i = s.path, o = s.queryId, a = Os(i, e), c = new OC(Lq(o), a);
    return jq(t, i, c);
  } else
    return [];
}
function ck(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || Die(o, e))) {
    const c = m3e(o, e, n, r);
    p3e(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => qh(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = I3e(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = Uie(t, _);
            t.listenProvider_.startListening(cw(m), DC(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(cw(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(gO(d));
        t.listenProvider_.stopListening(cw(d), f);
      }));
    }
    R3e(t, l);
  }
  return a;
}
function Mie(t, e, n, r) {
  const s = qq(t, r);
  if (s != null) {
    const i = Hq(s), o = i.path, a = i.queryId, c = Os(o, e), l = new Zf(Lq(a), c, n);
    return jq(t, o, l);
  } else
    return [];
}
function E3e(t, e, n, r) {
  const s = qq(t, r);
  if (s) {
    const i = Hq(s), o = i.path, a = i.queryId, c = Os(o, e), l = Kt.fromObject(n), h = new Fm(Lq(a), c, l);
    return jq(t, o, h);
  } else
    return [];
}
function zU(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = Os(d, s);
    i = i || ch(f, p), o = o || qh(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || qh(a), i = i || ch(a, ht())) : (a = new kie(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = ue.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((d, f) => {
    const p = ch(f, ht());
    p && (i = i.updateImmediateChild(d, p));
  }));
  const l = Die(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = gO(e);
    D(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = k3e();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = _O(t.pendingWriteTree_, s);
  let u = _3e(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = Oie(a, e);
    u = u.concat(N3e(t, e, d));
  }
  return u;
}
function Bq(t, e, n) {
  const r = t.pendingWriteTree_, s = t.syncPointTree_.findOnPath(e, (i, o) => {
    const a = Os(i, e), c = ch(o, a);
    if (c)
      return c;
  });
  return Tie(r, e, s, n, !0);
}
function S3e(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = Os(l, n);
    r = r || ch(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || ch(s, ht()) : (s = new kie(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new Bh(r, !0, !1) : null, a = _O(t.pendingWriteTree_, e._path), c = Nie(s, e, a, i ? o.getNode() : ue.EMPTY_NODE, i);
  return c3e(c);
}
function mg(t, e) {
  return xie(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    _O(t.pendingWriteTree_, ht())
  );
}
function xie(t, e, n, r) {
  if (We(t.path))
    return Lie(t, e, n, r);
  {
    const s = e.get(ht());
    n == null && s != null && (n = ch(s, ht()));
    let i = [];
    const o = Ie(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = bie(r, o);
      i = i.concat(xie(a, c, l, h));
    }
    return s && (i = i.concat(Wq(s, t, r, n))), i;
  }
}
function Lie(t, e, n, r) {
  const s = e.get(ht());
  n == null && s != null && (n = ch(s, ht()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = bie(r, o), h = t.operationForChild(o);
    h && (i = i.concat(Lie(h, a, c, l)));
  }), s && (i = i.concat(Wq(s, t, r, n))), i;
}
function Uie(t, e) {
  const n = e.query, r = DC(t, n);
  return {
    hashFn: () => (a3e(e) || ue.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? b3e(t, n._path, r) : T3e(t, n._path);
      {
        const i = C6e(s, n);
        return ck(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function DC(t, e) {
  const n = gO(e);
  return t.queryToTagMap.get(n);
}
function gO(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function qq(t, e) {
  return t.tagToQueryMap.get(e);
}
function Hq(t) {
  const e = t.indexOf("$");
  return D(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new Lt(t.substr(0, e))
  };
}
function jq(t, e, n) {
  const r = t.syncPointTree_.get(e);
  D(r, "Missing sync point for query tag that we're tracking");
  const s = _O(t.pendingWriteTree_, e);
  return Wq(r, n, s, null);
}
function I3e(t) {
  return t.fold((e, n, r) => {
    if (n && qh(n))
      return [mO(n)];
    {
      let s = [];
      return n && (s = Pie(n)), ss(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function cw(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (y3e())(t._repo, t._path) : t;
}
function R3e(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = gO(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function k3e() {
  return v3e++;
}
function N3e(t, e, n) {
  const r = e._path, s = DC(t, e), i = Uie(t, n), o = t.listenProvider_.startListening(cw(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    D(!qh(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!We(l) && h && qh(h))
        return [mO(h).query];
      {
        let d = [];
        return h && (d = d.concat(Pie(h).map((f) => f.query))), ss(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(cw(h), DC(t, h));
    }
  }
  return o;
}
class Vq {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new Vq(n);
  }
  node() {
    return this.node_;
  }
}
class zq {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = Pn(this.path_, e);
    return new zq(this.syncTree_, n);
  }
  node() {
    return Bq(this.syncTree_, this.path_);
  }
}
const P3e = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, h5 = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (D(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return O3e(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return D3e(t[".sv"], e);
  D(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, O3e = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      D(!1, "Unexpected server value: " + t);
  }
}, D3e = function(t, e, n) {
  t.hasOwnProperty("increment") || D(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && D(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (D(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const i = s.getValue();
  return typeof i != "number" ? r : i + r;
}, Fie = function(t, e, n, r) {
  return Gq(e, new zq(n, t), r);
}, Yie = function(t, e, n) {
  return Gq(t, new Vq(e), n);
};
function Gq(t, e, n) {
  const r = t.getPriority().val(), s = h5(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = h5(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new Pr(a, ur(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new Pr(s))), o.forEachChild(Un, (a, c) => {
      const l = Gq(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class $q {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function Kq(t, e) {
  let n = e instanceof Lt ? e : new Lt(e), r = t, s = Ie(n);
  for (; s !== null; ) {
    const i = xm(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new $q(s, r, i), n = rn(n), s = Ie(n);
  }
  return r;
}
function gg(t) {
  return t.node.value;
}
function Wie(t, e) {
  t.node.value = e, GU(t);
}
function Bie(t) {
  return t.node.childCount > 0;
}
function A3e(t) {
  return gg(t) === void 0 && !Bie(t);
}
function yO(t, e) {
  ss(t.node.children, (n, r) => {
    e(new $q(n, t, r));
  });
}
function qie(t, e, n, r) {
  n && !r && e(t), yO(t, (s) => {
    qie(s, e, !0, r);
  }), n && r && e(t);
}
function M3e(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function Db(t) {
  return new Lt(t.parent === null ? t.name : Db(t.parent) + "/" + t.name);
}
function GU(t) {
  t.parent !== null && x3e(t.parent, t.name, t);
}
function x3e(t, e, n) {
  const r = A3e(n), s = $a(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, GU(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, GU(t));
}
const L3e = /[\[\].#$\/\u0000-\u001F\u007F]/, U3e = /[\[\].#$\u0000-\u001F\u007F]/, h1 = 10 * 1024 * 1024, Jq = function(t) {
  return typeof t == "string" && t.length !== 0 && !L3e.test(t);
}, Hie = function(t) {
  return typeof t == "string" && t.length !== 0 && !U3e.test(t);
}, F3e = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), Hie(t);
}, Y3e = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !bq(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $a(t, ".sv");
}, W3e = function(t, e, n, r) {
  vO(hO(t, "value"), e, n);
}, vO = function(t, e, n) {
  const r = n instanceof Lt ? new n4e(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + Pd(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + Pd(r) + " with contents = " + e.toString());
  if (bq(e))
    throw new Error(t + "contains " + e.toString() + " " + Pd(r));
  if (typeof e == "string" && e.length > h1 / 3 && dO(e) > h1)
    throw new Error(t + "contains a string greater than " + h1 + " utf8 bytes " + Pd(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (ss(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !Jq(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + Pd(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      r4e(r, o), vO(t, a, r), s4e(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + Pd(r) + " in addition to actual children.");
  }
}, B3e = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = RC(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1) && !Jq(i[o]))
        throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
  }
  e.sort(t4e);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && Ji(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, q3e = function(t, e, n, r) {
  const s = hO(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  ss(e, (o, a) => {
    const c = new Lt(o);
    if (vO(s, a, Pn(n, c)), kq(c) === ".priority" && !Y3e(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), B3e(s, i);
}, jie = function(t, e, n, r) {
  if (!Hie(n))
    throw new Error(hO(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, H3e = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), jie(t, e, n);
}, j3e = function(t, e) {
  if (Ie(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, V3e = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !Jq(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !F3e(n))
    throw new Error(hO(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class z3e {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function wO(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !Nq(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function Vie(t, e, n) {
  wO(t, n), zie(t, (r) => Nq(r, e));
}
function ro(t, e, n) {
  wO(t, n), zie(t, (r) => Ji(r, e) || Ji(e, r));
}
function zie(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (G3e(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function G3e(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      sw && Qr("event: " + n.toString()), _g(r);
    }
  }
}
const $3e = "repo_interrupt", K3e = 25;
class J3e {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new z3e(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = ek(), this.transactionQueueTree_ = new $q(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function Q3e(t, e, n) {
  if (t.stats_ = Iq(t.repoInfo_), t.forceRestClient_ || S6e())
    t.server_ = new XR(t.repoInfo_, (r, s, i, o) => {
      d5(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => f5(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        gr(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new nl(t.repoInfo_, e, (r, s, i, o) => {
      d5(t, r, s, i, o);
    }, (r) => {
      f5(t, r);
    }, (r) => {
      X3e(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = P6e(t.repoInfo_, () => new P4e(t.stats_, t.server_)), t.infoData_ = new S4e(), t.infoSyncTree_ = new u5({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = Ob(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), Qq(t, "connected", !1), t.serverSyncTree_ = new u5({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      ro(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function Z3e(t) {
  const e = t.infoData_.getNode(new Lt(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + e;
}
function CO(t) {
  return P3e({
    timestamp: Z3e(t)
  });
}
function d5(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new Lt(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = $R(n, (l) => ur(l));
      o = E3e(t.serverSyncTree_, i, c, s);
    } else {
      const c = ur(n);
      o = Mie(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = $R(n, (l) => ur(l));
    o = C3e(t.serverSyncTree_, i, c);
  } else {
    const c = ur(n);
    o = Ob(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = Wm(t, i)), ro(t.eventQueue_, a, o);
}
function f5(t, e) {
  Qq(t, "connected", e), e === !1 && rze(t);
}
function X3e(t, e) {
  ss(e, (n, r) => {
    Qq(t, n, r);
  });
}
function Qq(t, e, n) {
  const r = new Lt("/.info/" + e), s = ur(n);
  t.infoData_.updateSnapshot(r, s);
  const i = Ob(t.infoSyncTree_, r, s);
  ro(t.eventQueue_, r, i);
}
function Zq(t) {
  return t.nextWriteId_++;
}
function eze(t, e, n) {
  const r = S3e(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = ur(s).withIndex(e._queryParams.getIndex());
    zU(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = Ob(t.serverSyncTree_, e._path, i);
    else {
      const a = DC(t.serverSyncTree_, e);
      o = Mie(t.serverSyncTree_, e._path, i, a);
    }
    return ro(t.eventQueue_, e._path, o), ck(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (Ab(t, "get for query " + gr(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function tze(t, e, n, r, s) {
  Ab(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = CO(t), o = ur(n, r), a = Bq(t.serverSyncTree_, e), c = Yie(o, a, i), l = Zq(t), h = Aie(t.serverSyncTree_, e, c, l, !0);
  wO(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || Fs("set at " + e + " failed: " + d);
    const _ = Su(t.serverSyncTree_, l, !p);
    ro(t.eventQueue_, e, _), $U(t, s, d, f);
  });
  const u = eH(t, e);
  Wm(t, u), ro(t.eventQueue_, u, []);
}
function nze(t, e, n, r) {
  Ab(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = CO(t), o = {};
  if (ss(n, (a, c) => {
    s = !1, o[a] = Fie(Pn(e, a), ur(c), t.serverSyncTree_, i);
  }), s)
    Qr("update() called with empty data.  Don't do anything."), $U(t, r, "ok", void 0);
  else {
    const a = Zq(t), c = w3e(t.serverSyncTree_, e, o, a);
    wO(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || Fs("update at " + e + " failed: " + l);
      const d = Su(t.serverSyncTree_, a, !u), f = d.length > 0 ? Wm(t, e) : e;
      ro(t.eventQueue_, f, d), $U(t, r, l, h);
    }), ss(n, (l) => {
      const h = eH(t, Pn(e, l));
      Wm(t, h);
    }), ro(t.eventQueue_, e, []);
  }
}
function rze(t) {
  Ab(t, "onDisconnectEvents");
  const e = CO(t), n = ek();
  WU(t.onDisconnect_, ht(), (s, i) => {
    const o = Fie(s, i, t.serverSyncTree_, e);
    yie(n, s, o);
  });
  let r = [];
  WU(n, ht(), (s, i) => {
    r = r.concat(Ob(t.serverSyncTree_, s, i));
    const o = eH(t, s);
    Wm(t, o);
  }), t.onDisconnect_ = ek(), ro(t.eventQueue_, ht(), r);
}
function sze(t, e, n) {
  let r;
  Ie(e._path) === ".info" ? r = zU(t.infoSyncTree_, e, n) : r = zU(t.serverSyncTree_, e, n), Vie(t.eventQueue_, e._path, r);
}
function p5(t, e, n) {
  let r;
  Ie(e._path) === ".info" ? r = ck(t.infoSyncTree_, e, n) : r = ck(t.serverSyncTree_, e, n), Vie(t.eventQueue_, e._path, r);
}
function ize(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt($3e);
}
function Ab(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), Qr(n, ...e);
}
function $U(t, e, n, r) {
  e && _g(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function Gie(t, e, n) {
  return Bq(t.serverSyncTree_, e, n) || ue.EMPTY_NODE;
}
function Xq(t, e = t.transactionQueueTree_) {
  if (e || TO(t, e), gg(e)) {
    const n = Kie(t, e);
    D(n.length > 0, "Sending zero length transaction queue"), n.every(
      (r) => r.status === 0
      /* TransactionStatus.RUN */
    ) && oze(t, Db(e), n);
  } else Bie(e) && yO(e, (n) => {
    Xq(t, n);
  });
}
function oze(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = Gie(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    D(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = Os(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    Ab(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(Su(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      TO(t, Kq(t.transactionQueueTree_, e)), Xq(t, t.transactionQueueTree_), ro(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        _g(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        Fs("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      Wm(t, e);
    }
  }, o);
}
function Wm(t, e) {
  const n = $ie(t, e), r = Db(n), s = Kie(t, n);
  return aze(t, s, r), r;
}
function aze(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const i = e.filter((o) => o.status === 0).map((o) => o.currentWriteId);
  for (let o = 0; o < e.length; o++) {
    const a = e[o], c = Os(n, a.path);
    let l = !1, h;
    if (D(c !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), a.status === 4)
      l = !0, h = a.abortReason, s = s.concat(Su(t.serverSyncTree_, a.currentWriteId, !0));
    else if (a.status === 0)
      if (a.retryCount >= K3e)
        l = !0, h = "maxretry", s = s.concat(Su(t.serverSyncTree_, a.currentWriteId, !0));
      else {
        const u = Gie(t, a.path, i);
        a.currentInputSnapshot = u;
        const d = e[o].update(u.val());
        if (d !== void 0) {
          vO("transaction failed: Data returned ", d, a.path);
          let f = ur(d);
          typeof d == "object" && d != null && $a(d, ".priority") || (f = f.updatePriority(u.getPriority()));
          const p = a.currentWriteId, _ = CO(t), m = Yie(f, u, _);
          a.currentOutputSnapshotRaw = f, a.currentOutputSnapshotResolved = m, a.currentWriteId = Zq(t), i.splice(i.indexOf(p), 1), s = s.concat(Aie(t.serverSyncTree_, a.path, m, a.currentWriteId, a.applyLocally)), s = s.concat(Su(t.serverSyncTree_, p, !0));
        } else
          l = !0, h = "nodata", s = s.concat(Su(t.serverSyncTree_, a.currentWriteId, !0));
      }
    ro(t.eventQueue_, n, s), s = [], l && (e[o].status = 2, function(u) {
      setTimeout(u, Math.floor(0));
    }(e[o].unwatcher), e[o].onComplete && (h === "nodata" ? r.push(() => e[o].onComplete(null, !1, e[o].currentInputSnapshot)) : r.push(() => e[o].onComplete(new Error(h), !1, null))));
  }
  TO(t, t.transactionQueueTree_);
  for (let o = 0; o < r.length; o++)
    _g(r[o]);
  Xq(t, t.transactionQueueTree_);
}
function $ie(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = Ie(e); n !== null && gg(r) === void 0; )
    r = Kq(r, n), e = rn(e), n = Ie(e);
  return r;
}
function Kie(t, e) {
  const n = [];
  return Jie(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function Jie(t, e, n) {
  const r = gg(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  yO(e, (s) => {
    Jie(t, s, n);
  });
}
function TO(t, e) {
  const n = gg(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, Wie(e, n.length > 0 ? n : void 0);
  }
  yO(e, (r) => {
    TO(t, r);
  });
}
function eH(t, e) {
  const n = Db($ie(t, e)), r = Kq(t.transactionQueueTree_, e);
  return M3e(r, (s) => {
    d1(t, s);
  }), d1(t, r), qie(r, (s) => {
    d1(t, s);
  }), n;
}
function d1(t, e) {
  const n = gg(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (D(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (D(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(Su(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? Wie(e, void 0) : n.length = i + 1, ro(t.eventQueue_, Db(e), s);
    for (let o = 0; o < r.length; o++)
      _g(r[o]);
  }
}
function cze(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function lze(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : Fs(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const _5 = function(t, e) {
  const n = uze(t), r = n.namespace;
  n.domain === "firebase.com" && Cl(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && Cl("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || m6e();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new tie(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new Lt(n.pathString)
  };
}, uze = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = cze(t.substring(h, u)));
    const d = lze(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class hze {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + gr(this.snapshot.exportVal());
  }
}
class dze {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class Qie {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return D(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class tH {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return We(this._path) ? null : kq(this._path);
  }
  get ref() {
    return new Wl(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = e5(this._queryParams), n = Eq(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return e5(this._queryParams);
  }
  isEqual(e) {
    if (e = hs(e), !(e instanceof tH))
      return !1;
    const n = this._repo === e._repo, r = Nq(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + e4e(this._path);
  }
}
class Wl extends tH {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new Aq(), !1);
  }
  get parent() {
    const e = uie(this._path);
    return e === null ? null : new Wl(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class AC {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new Lt(e), r = KU(this.ref, e);
    return new AC(this._node.getChild(n), r, Un);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (n, r) => e(new AC(r, KU(this.ref, n), Un)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new Lt(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function nS(t, e) {
  return t = hs(t), t._checkNotDeleted("ref"), e !== void 0 ? KU(t._root, e) : t._root;
}
function KU(t, e) {
  return t = hs(t), Ie(t._path) === null ? H3e("child", "path", e) : jie("child", "path", e), new Wl(t._repo, Pn(t._path, e));
}
function fze(t, e) {
  t = hs(t), j3e("set", t._path), W3e("set", e, t._path);
  const n = new Sb();
  return tze(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function pze(t, e) {
  q3e("update", e, t._path);
  const n = new Sb();
  return nze(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function _ze(t) {
  t = hs(t);
  const e = new Qie(() => {
  }), n = new bO(e);
  return eze(t._repo, t, n).then((r) => new AC(r, new Wl(t._repo, t._path), t._queryParams.getIndex()));
}
class bO {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new hze("value", this, new AC(e.snapshotNode, new Wl(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new dze(this, e, n) : null;
  }
  matches(e) {
    return e instanceof bO ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function mze(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      p5(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new Qie(n, i || void 0), a = new bO(o);
  return sze(t._repo, t, a), () => p5(t._repo, t, a);
}
function gze(t, e, n, r) {
  return mze(t, "value", e, n, r);
}
function yze(t, ...e) {
  let n = hs(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
d3e(Wl);
g3e(Wl);
const vze = "FIREBASE_DATABASE_EMULATOR_HOST", JU = {};
let wze = !1;
function Cze(t, e, n, r) {
  t.repoInfo_ = new tie(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function Tze(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || Cl("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), Qr("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = _5(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[vze]), c ? (i = `http://${c}?ns=${a.namespace}`, o = _5(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new R6e(t.name, t.options, e);
  V3e("Invalid Firebase Database URL", o), We(o.path) || Cl("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = Eze(a, t, l, new I6e(t.name, n));
  return new Sze(h, t);
}
function bze(t, e) {
  const n = JU[e];
  (!n || n[t.key] !== t) && Cl(`Database ${e}(${t.repoInfo_}) has already been deleted.`), ize(t), delete n[t.key];
}
function Eze(t, e, n, r) {
  let s = JU[e.name];
  s || (s = {}, JU[e.name] = s);
  let i = s[t.toURLString()];
  return i && Cl("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new J3e(t, wze, n, r), s[t.toURLString()] = i, i;
}
class Sze {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (Q3e(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Wl(this._repo, ht())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (bze(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && Cl("Cannot call " + e + " on a deleted database.");
  }
}
function Ize(t = Fse(), e) {
  const n = Use(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = Pse("database");
    r && Rze(n, ...r);
  }
  return n;
}
function Rze(t, e, n, r = {}) {
  t = hs(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && Cl("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && Cl('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new s0(s0.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : Ose(r.mockUserToken, t.app.options.projectId);
    i = new s0(o);
  }
  Cze(s, e, n, i);
}
function kze(t) {
  u6e(Rb), Jf(new Yh(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return Tze(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), tl(UK, FK, t), tl(UK, FK, "esm2017");
}
nl.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
nl.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
kze();
const Zie = "firebasestorage.googleapis.com", Xie = "storageBucket", Nze = 2 * 60 * 1e3, Pze = 10 * 60 * 1e3;
class Qn extends Yl {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(f1(e), `Firebase Storage: ${n} (${f1(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, Qn.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return f1(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var jn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(jn || (jn = {}));
function f1(t) {
  return "storage/" + t;
}
function nH() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new Qn(jn.UNKNOWN, t);
}
function Oze(t) {
  return new Qn(jn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function Dze(t) {
  return new Qn(jn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function Aze() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new Qn(jn.UNAUTHENTICATED, t);
}
function Mze() {
  return new Qn(jn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function xze(t) {
  return new Qn(jn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function Lze() {
  return new Qn(jn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function Uze() {
  return new Qn(jn.CANCELED, "User canceled the upload/download.");
}
function Fze(t) {
  return new Qn(jn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function Yze(t) {
  return new Qn(jn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function Wze() {
  return new Qn(jn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + Xie + "' property when initializing the app?");
}
function Bze() {
  return new Qn(jn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function qze() {
  return new Qn(jn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function Hze(t) {
  return new Qn(jn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function QU(t) {
  return new Qn(jn.INVALID_ARGUMENT, t);
}
function eoe() {
  return new Qn(jn.APP_DELETED, "The Firebase app was deleted.");
}
function jze(t) {
  return new Qn(jn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function lw(t, e) {
  return new Qn(jn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function By(t) {
  throw new Qn(jn.INTERNAL_ERROR, "Internal error: " + t);
}
class Si {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = Si.makeFromUrl(e, n);
    } catch {
      return new Si(e, "");
    }
    if (r.path === "")
      return r;
    throw Yze(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(g) {
      g.path.charAt(g.path.length - 1) === "/" && (g.path_ = g.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(g) {
      g.path_ = decodeURIComponent(g.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === Zie ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), y = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let g = 0; g < y.length; g++) {
      const w = y[g], de = w.regex.exec(e);
      if (de) {
        const we = de[w.indices.bucket];
        let fe = de[w.indices.path];
        fe || (fe = ""), r = new Si(we, fe), w.postModify(r);
        break;
      }
    }
    if (r == null)
      throw Fze(e);
    return r;
  }
}
class Vze {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function zze(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let y;
    a === 1 ? (a = 2, y = 0) : y = (r + Math.random()) * 1e3, u(y);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function Gze(t) {
  t(!1);
}
function $ze(t) {
  return t !== void 0;
}
function Kze(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function rH(t) {
  return typeof t == "string" || t instanceof String;
}
function m5(t) {
  return sH() && t instanceof Blob;
}
function sH() {
  return typeof Blob < "u" && !jje();
}
function g5(t, e, n, r) {
  if (r < e)
    throw QU(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw QU(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function EO(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function toe(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var rf;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(rf || (rf = {}));
function Jze(t, e) {
  const n = t >= 500 && t < 600, r = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, s = e.indexOf(t) !== -1;
  return n || r || s;
}
class Qze {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new rS(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === rf.NO_ERROR, c = i.getStatus();
        if (!a || Jze(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === rf.ABORT;
          r(!1, new rS(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new rS(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          $ze(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = nH();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? eoe() : Uze();
        o(c);
      } else {
        const c = Lze();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new rS(!1, null, !0)) : this.backoffId_ = zze(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && Gze(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class rS {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function Zze(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function Xze(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function eGe(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function tGe(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function nGe(t, e, n, r, s, i, o = !0) {
  const a = toe(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return eGe(l, e), Zze(l, n), Xze(l, i), tGe(l, r), new Qze(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function rGe() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function sGe(...t) {
  const e = rGe();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (sH())
      return new Blob(t);
    throw new Qn(jn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function iGe(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function oGe(t) {
  if (typeof atob > "u")
    throw Hze("base-64");
  return atob(t);
}
const Na = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class p1 {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function aGe(t, e) {
  switch (t) {
    case Na.RAW:
      return new p1(noe(e));
    case Na.BASE64:
    case Na.BASE64URL:
      return new p1(roe(t, e));
    case Na.DATA_URL:
      return new p1(lGe(e), uGe(e));
  }
  throw nH();
}
function noe(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const s = r, i = t.charCodeAt(++n);
        r = 65536 | (s & 1023) << 10 | i & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function cGe(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw lw(Na.DATA_URL, "Malformed data URL.");
  }
  return noe(e);
}
function roe(t, e) {
  switch (t) {
    case Na.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw lw(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case Na.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw lw(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = oGe(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : lw(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class soe {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw lw(Na.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = hGe(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function lGe(t) {
  const e = new soe(t);
  return e.base64 ? roe(Na.BASE64, e.rest) : cGe(e.rest);
}
function uGe(t) {
  return new soe(t).contentType;
}
function hGe(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class yu {
  constructor(e, n) {
    let r = 0, s = "";
    m5(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (m5(this.data_)) {
      const r = this.data_, s = iGe(r, e, n);
      return s === null ? null : new yu(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new yu(r, !0);
    }
  }
  static getBlob(...e) {
    if (sH()) {
      const n = e.map((r) => r instanceof yu ? r.data_ : r);
      return new yu(sGe.apply(null, n));
    } else {
      const n = e.map((o) => rH(o) ? aGe(Na.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new yu(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function ioe(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return Kze(e) ? e : null;
}
function dGe(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function fGe(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function ooe(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function pGe(t, e) {
  return e;
}
class Es {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || pGe;
  }
}
let sS = null;
function _Ge(t) {
  return !rH(t) || t.length < 2 ? t : ooe(t);
}
function aoe() {
  if (sS)
    return sS;
  const t = [];
  t.push(new Es("bucket")), t.push(new Es("generation")), t.push(new Es("metageneration")), t.push(new Es("name", "fullPath", !0));
  function e(i, o) {
    return _Ge(o);
  }
  const n = new Es("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new Es("size");
  return s.xform = r, t.push(s), t.push(new Es("timeCreated")), t.push(new Es("updated")), t.push(new Es("md5Hash", null, !0)), t.push(new Es("cacheControl", null, !0)), t.push(new Es("contentDisposition", null, !0)), t.push(new Es("contentEncoding", null, !0)), t.push(new Es("contentLanguage", null, !0)), t.push(new Es("contentType", null, !0)), t.push(new Es("metadata", "customMetadata", !0)), sS = t, sS;
}
function mGe(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new Si(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function gGe(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return mGe(r, t), r;
}
function coe(t, e, n) {
  const r = ioe(e);
  return r === null ? null : gGe(t, r, n);
}
function yGe(t, e, n, r) {
  const s = ioe(e);
  if (s === null || !rH(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((a) => {
    const c = t.bucket, l = t.fullPath, h = "/b/" + o(c) + "/o/" + o(l), u = EO(h, n, r), d = toe({
      alt: "media",
      token: a
    });
    return u + d;
  })[0];
}
function vGe(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class iH {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function loe(t) {
  if (!t)
    throw nH();
}
function wGe(t, e) {
  function n(r, s) {
    const i = coe(t, s, e);
    return loe(i !== null), i;
  }
  return n;
}
function CGe(t, e) {
  function n(r, s) {
    const i = coe(t, s, e);
    return loe(i !== null), yGe(i, s, t.host, t._protocol);
  }
  return n;
}
function uoe(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? (
      /* This exact message string is the only consistent part of the */
      /* server's error response that identifies it as an App Check error. */
      n.getErrorText().includes("Firebase App Check token is invalid") ? s = Mze() : s = Aze()
    ) : n.getStatus() === 402 ? s = Dze(t.bucket) : n.getStatus() === 403 ? s = xze(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function hoe(t) {
  const e = uoe(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = Oze(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function TGe(t, e, n) {
  const r = e.fullServerUrl(), s = EO(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new iH(s, i, CGe(t, n), o);
  return a.errorHandler = hoe(e), a;
}
function bGe(t, e) {
  const n = e.fullServerUrl(), r = EO(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new iH(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = hoe(e), a;
}
function EGe(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function SGe(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = EGe(null, e)), r;
}
function IGe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = SGe(e, r, s), h = vGe(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = yu.getBlob(u, r, d);
  if (f === null)
    throw Bze();
  const p = { name: l.fullPath }, _ = EO(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new iH(_, m, wGe(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = uoe(e), y;
}
class RGe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = rf.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = rf.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = rf.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw By("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw By("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw By("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw By("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw By("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class kGe extends RGe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function oH() {
  return new kGe();
}
class ep {
  constructor(e, n) {
    this._service = e, n instanceof Si ? this._location = n : this._location = Si.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new ep(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new Si(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return ooe(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = dGe(this._location.path);
    if (e === null)
      return null;
    const n = new Si(this._location.bucket, e);
    return new ep(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw jze(e);
  }
}
function NGe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = IGe(t.storage, t._location, aoe(), new yu(e, !0), n);
  return t.storage.makeRequestWithTokens(r, oH).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function PGe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = TGe(t.storage, t._location, aoe());
  return t.storage.makeRequestWithTokens(e, oH).then((n) => {
    if (n === null)
      throw qze();
    return n;
  });
}
function OGe(t) {
  t._throwIfRoot("deleteObject");
  const e = bGe(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, oH);
}
function DGe(t, e) {
  const n = fGe(t._location.path, e), r = new Si(t._location.bucket, n);
  return new ep(t.storage, r);
}
function AGe(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function MGe(t, e) {
  return new ep(t, e);
}
function doe(t, e) {
  if (t instanceof aH) {
    const n = t;
    if (n._bucket == null)
      throw Wze();
    const r = new ep(n, n._bucket);
    return e != null ? doe(r, e) : r;
  } else
    return e !== void 0 ? DGe(t, e) : t;
}
function xGe(t, e) {
  if (e && AGe(e)) {
    if (t instanceof aH)
      return MGe(t, e);
    throw QU("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return doe(t, e);
}
function y5(t, e) {
  const n = e == null ? void 0 : e[Xie];
  return n == null ? null : Si.makeFromBucketSpec(n, t);
}
function LGe(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : Ose(s, t.app.options.projectId));
}
class aH {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = Zie, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = Nze, this._maxUploadRetryTime = Pze, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = Si.makeFromBucketSpec(s, this._host) : this._bucket = y5(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = Si.makeFromBucketSpec(this._url, e) : this._bucket = y5(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    g5(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    g5(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new ep(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new Vze(eoe());
    {
      const o = nGe(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const v5 = "@firebase/storage", w5 = "0.11.2", foe = "storage";
function UGe(t, e, n) {
  return t = hs(t), NGe(t, e, n);
}
function FGe(t) {
  return t = hs(t), PGe(t);
}
function YGe(t) {
  return t = hs(t), OGe(t);
}
function _1(t, e) {
  return t = hs(t), xGe(t, e);
}
function WGe(t = Fse(), e) {
  t = hs(t);
  const n = Use(t, foe).getImmediate({
    identifier: e
  }), r = Pse("storage");
  return r && BGe(n, ...r), n;
}
function BGe(t, e, n, r = {}) {
  LGe(t, e, n, r);
}
function qGe(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new aH(n, r, s, e, Rb);
}
function HGe() {
  Jf(new Yh(
    foe,
    qGe,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), tl(v5, w5, ""), tl(v5, w5, "esm2017");
}
HGe();
class poe {
  constructor() {
    this.db = Ize(), this.storage = WGe();
  }
  async get(e) {
    let n = await _ze(nS(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await pze(nS(this.db, e), n);
  }
  async set(e, n) {
    return await fze(nS(this.db, e), n);
  }
  async watch(e, n, r) {
    return gze(yze(nS(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await UGe(_1(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await YGe(_1(this.storage, e));
  }
  async objectUrl(e) {
    return await FGe(_1(this.storage, e));
  }
}
class jGe extends poe {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch(Kp.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set(Kp.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set(Kp.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get(Kp.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get(Kp.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
const VGe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "http://localhost:5009/?ns=ihunterdev",
  functionURL: "http://localhost:5001/ihunterdev/us-central1",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L",
  local: !0
}, zGe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, GGe = {
  apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
  authDomain: "ihunterdev.firebaseapp.com",
  databaseURL: "https://ihunterdev.firebaseio.com",
  functionURL: "https://dev.api.ihunterapp.com",
  webAppURL: "https://dev.ihunterapp.com",
  storeURL: "https://dev.store.ihunterapp.com",
  tileURL: "https://tiles.ihunterapp.com",
  projectId: "ihunterdev",
  storageBucket: "ihunterdev.appspot.com",
  messagingSenderId: "30374332665",
  appId: "1:30374332665:web:afb375302cc2fc54a93a28",
  measurementId: "G-Z989Q1X51L"
}, $Ge = {
  localhost: VGe,
  hybrid: zGe,
  ihunterdev: GGe,
  "ihunter-d5eab": {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com",
    webAppURL: "https://web.ihunterapp.com",
    storeURL: "https://store.ihunterapp.com",
    tileURL: "https://tiles.ihunterapp.com",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:a6f9a8a200fd2257b30de3",
    measurementId: "G-JJW704CK6Z"
  }
}, KGe = !1;
class D_ {
  // Deprecated: firebase config needs to be seperately defined by for deployment environment and shouldn't be common code
  static get config() {
    return $Ge[this.projectId];
  }
  static get projectId() {
    return this.isProduction() ? "ihunter-d5eab" : this.isDevelopment() || this.isLocal() && KGe ? "ihunterdev" : (this.isLocal(), "localhost");
  }
  // static firebaseConfig() {
  //     if(!process)
  //     return process && process?.env?.FIREBASE_CONFIG && JSON.parse(process.env.FIREBASE_CONFIG);
  // }
  // static isBrowser() {
  //     return isBrowser;
  // }
  // static isNode() {
  //     return isNode;
  // }
  static isLocal() {
    return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
  }
  static isDevelopment() {
    return window.location.hostname.includes("dev");
  }
  static isProduction() {
    return !this.isDevelopment() && !this.isLocal();
  }
}
class iS extends Error {
  constructor(e, n, r) {
    super(n), this.name = "NetworkError", this.status = e, this.cause = r, this.stack = null, this.details = null;
  }
}
const vi = "application/json", JGe = "application/octet-stream";
class _oe {
  constructor() {
  }
  get apiServer() {
    return D_.config.functionURL;
  }
  get tileServer() {
    return D_.config.tileURL;
  }
  get webServer() {
    return D_.config.webAppURL;
  }
  async getAccessToken() {
    return await window.app.getFirebaseToken();
  }
  async get(e, n, r) {
    let s = {
      method: "GET",
      headers: {
        Authorization: "Firebase " + await await this.getAccessToken(),
        "Content-Type": n || vi,
        Accept: r || vi
      }
    };
    return this.request(e, s);
  }
  async getJson(e, n) {
    let r = {
      method: "GET",
      headers: {
        Accept: vi
      }
    };
    if (n) {
      let s = await this.getAccessToken();
      r.headers.Authorization = "Firebase " + s;
    }
    return this.request(e, r);
  }
  async put(e, n, r, s) {
    let i = {
      method: "PUT",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || vi,
        Accept: s || vi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async patch(e, n, r, s) {
    let i = {
      method: "PATCH",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || vi,
        Accept: s || vi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async post(e, n, r, s) {
    let i = {
      method: "POST",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": r || vi,
        Accept: s || vi
      },
      body: JSON.stringify(n)
    };
    return this.request(e, i);
  }
  async delete(e, n, r) {
    let s = {
      method: "DELETE",
      headers: {
        Authorization: "Firebase " + await this.getAccessToken(),
        "Content-Type": n || vi,
        Accept: r || vi
      }
    };
    return this.request(e, s);
  }
  async request(e, n) {
    return n || (n = {}), n.headers || (n.headers = {}), new Promise((r, s) => {
      try {
        return fetch ? fetch(e, n).then(async (i) => {
          if (i) {
            if (!i.ok) {
              let o = new iS(i.status, i.statusText), a = i.headers.get("Content-Type");
              if (a && a.startsWith(vi)) {
                let c = await i.json();
                o.stack = c.stack, o.message = c.error;
              } else {
                let c = await i.text();
                !o.message || o.message === "" ? o.message = c : o.details = c;
              }
              return s(o);
            }
          } else return s(new iS(500, "Unexpected response"));
          if (i.body && i.status === 200) {
            let o = n.headers.Accept || i.headers.get("Content-Type");
            return o && o.startsWith(vi) ? r(await i.json()) : o && o.startsWith(JGe) ? r(await i.blob()) : r(await i.text());
          } else
            return r();
        }).catch((i) => s(new iS(500, i.message, i))) : s(new iS(500, "No fetch"));
      } catch (i) {
        return s(i);
      }
    });
  }
}
class QGe {
  constructor() {
    this.clientId = this.lookupClientId();
  }
  report(e, n) {
    gtag("event", e, n);
  }
  lookupClientId() {
    try {
      var e = {};
      return document.cookie.split(";").forEach((n) => {
        var r = n.split("="), s = r[0].trim(), i = r[1];
        e[s] = i;
      }), e._ga.substring(6);
    } catch {
      return console.warn("Analytics client ID not found."), null;
    }
  }
}
var i0 = { exports: {} };
function ZGe(t, e) {
  return t.replace(/\{\s*([^}\s]+)\s*\}/g, (n, r) => e[r]);
}
i0.exports = ZGe;
const Z = (i0.exports == null ? {} : i0.exports).default || i0.exports, ou = {
  REGIONS: "/res/provinces/provinces.json",
  REGION: "/res/provinces/{region}/province.json",
  RESOURCE: "/res/provinces/{region}/{resource}/metadata.json",
  RESOURCE_LAYER: "/res/provinces/{region}/{resource}/{layer}/metadata.json",
  SUBSCRIPTION: "/res/provinces/{region}/{subscription}/metadata.json",
  SUBSCRIPTION_LAYER: "/res/provinces/{region}/{subscription}/{layer}/metadata.json",
  COUNTIES: "/tiles/{region}/counties.json",
  COUNTY: "/tiles/{region}/{county}/metadata.json"
}, C5 = {
  RESOURCE_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1",
  SUBSCRIPTION_LAYER: "/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2"
};
class Fi {
  static TileServer() {
    return D_.config.tileURL;
  }
  static WebServer() {
    return D_.config.webAppURL;
  }
  static CopyProperties(e, n, r) {
    r || (r = {});
    for (let s in e) {
      const i = e[s];
      n[i] && (r[s] = n[i]);
    }
    return r;
  }
  static RegionsPath() {
    return ou.REGIONS;
  }
  static AllRegions() {
    return this.WebServer() + this.RegionsPath() + this.cacheBuster();
  }
  static RegionPath(e) {
    return Z(ou.REGION, { region: e });
  }
  static RegionMetadata(e) {
    return this.WebServer() + this.RegionPath(e) + this.cacheBuster();
  }
  static ResourcePath(e, n) {
    return Z(ou.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadata(e, n) {
    return this.WebServer() + this.ResourcePath(e, n) + this.cacheBuster();
  }
  static ResourceLayerPath(e, n, r) {
    return Z(ou.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadata(e, n, r) {
    return this.WebServer() + this.ResourceLayerPath(e, n, r) + this.cacheBuster();
  }
  static ResourceLayerBoundarydata(e, n, r) {
    return this.WebServer() + Z(C5.RESOURCE_LAYER, { region: e, resource: n, layer: r }) + this.cacheBuster();
  }
  static SubscriptionPath(e, n) {
    return (!n || n === "pro") && (n = "subscription"), Z(ou.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadata(e, n) {
    return this.WebServer() + this.SubscriptionPath(e, n) + this.cacheBuster();
  }
  static SubscriptionLayerPath(e, n, r) {
    return (!n || n === "pro") && (n = "subscription"), Z(ou.SUBSCRIPTION_LAYER, { region: e, layer: r, subscription: n });
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return this.WebServer() + this.SubscriptionLayerPath(e, n, r) + this.cacheBuster();
  }
  static SubscriptionLayerBoundarydata(e, n) {
    return this.WebServer() + Z(C5.SUBSCRIPTION_LAYER, { region: e, layer: n }) + this.cacheBuster();
  }
  static CountyPath(e) {
    return Z(ou.COUNTIES, { region: e });
  }
  static CountyMetadata(e) {
    return this.TileServer() + this.CountyPath(e) + this.cacheBuster();
  }
  static CountyProductPath(e, n) {
    return Z(ou.COUNTY, { region: e, county: n });
  }
  static CountyProductMetadata(e, n) {
    return this.TileServer() + this.CountyProductPath(e, n) + this.cacheBuster();
  }
  static cacheBuster() {
    return "?v=" + Date.now();
  }
}
const Zs = {
  REGION: "/product/metadata/{region}/region",
  SUBSCRIPTION: "/product/metadata/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}",
  RESOURCE: "/product/metadata/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/metadata/{region}/resources_layers/{resource}/{layer}",
  MAP: "/product/metadata/{region}/maps/{resource}/{layer}/{map}",
  PRODUCT: "/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}"
}, m1 = {
  REGIONS: "/product/regions/{region}"
}, Xs = {
  REGION: "/product/catalog/{region}",
  SUBSCRIPTION: "/product/catalog/{region}/subscriptions/{subscription}",
  SUBSCRIPTION_LAYER: "/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}",
  RESOURCE: "/product/catalog/{region}/resources/{resource}",
  RESOURCE_LAYER: "/product/catalog/{region}/resources/{resource}/layers/{layer}",
  MAP: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}",
  PRODUCT: "/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}"
}, T5 = {
  PRODUCT: "/product/skus/{product}"
}, b5 = {
  PRODUCT: "/product/prices/{product}"
}, XGe = {
  PROMOTIONS: "/store/promotions"
};
class e$e {
  static AllRegions() {
    return g1(Z(m1.REGIONS, { region: "" }));
  }
  static RegionsRoute() {
    return m1.REGIONS;
  }
  static Regions(e) {
    return Z(m1.REGIONS, { region: e });
  }
  static RegionRoute() {
    return Xs.REGION;
  }
  static Region(e) {
    return Z(Xs.REGION, { region: e });
  }
  static RegionMetadataRoute() {
    return Zs.REGION;
  }
  static RegionMetadata(e) {
    return Z(Zs.REGION, { region: e });
  }
  static SubscriptionRoute() {
    return Xs.SUBSCRIPTION;
  }
  static Subscription(e, n) {
    return Z(Xs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionMetadataRoute() {
    return Zs.SUBSCRIPTION;
  }
  static SubscriptionMetadata(e, n) {
    return Z(Zs.SUBSCRIPTION, { region: e, subscription: n });
  }
  static SubscriptionLayerRoute() {
    return Xs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayer(e, n, r) {
    return Z(Xs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static SubscriptionLayerMetadataRoute() {
    return Zs.SUBSCRIPTION_LAYER;
  }
  static SubscriptionLayerMetadata(e, n, r) {
    return Z(Zs.SUBSCRIPTION_LAYER, { region: e, subscription: n, layer: r });
  }
  static ResourceRoute() {
    return Xs.RESOURCE;
  }
  static Resource(e, n) {
    return Z(Xs.RESOURCE, { region: e, resource: n });
  }
  static ResourceMetadataRoute() {
    return Zs.RESOURCE;
  }
  static ResourceMetadata(e, n) {
    return Z(Zs.RESOURCE, { region: e, resource: n });
  }
  static ResourceLayerRoute() {
    return Xs.RESOURCE_LAYER;
  }
  static ResourceLayer(e, n, r) {
    return Z(Xs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static ResourceLayerMetadataRoute() {
    return Zs.RESOURCE_LAYER;
  }
  static ResourceLayerMetadata(e, n, r) {
    return Z(Zs.RESOURCE_LAYER, { region: e, resource: n, layer: r });
  }
  static MapRoute() {
    return Xs.MAP;
  }
  static Map(e, n, r, s) {
    return Z(Xs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static MapMetadataRoute() {
    return Zs.MAP;
  }
  static MapMetadata(e, n, r, s) {
    return Z(Zs.MAP, { region: e, resource: n, layer: r, map: s });
  }
  static ProductRoute() {
    return Xs.PRODUCT;
  }
  static Product(e, n, r, s, i) {
    return Z(Xs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static ProductMetadataRoute() {
    return Zs.PRODUCT;
  }
  static ProductMetadata(e, n, r, s, i) {
    return Z(Zs.PRODUCT, { region: e, resource: n, layer: r, map: s, product: i });
  }
  static AllProductSkus() {
    return g1(Z(T5.PRODUCT, { product: "" }));
  }
  static ProductSku(e) {
    return Z(T5.PRODUCT, { product: e });
  }
  static AllProductPrices() {
    return g1(Z(b5.PRODUCT, { product: "" }));
  }
  static ProductPrice(e) {
    return Z(b5.PRODUCT, { product: e });
  }
  static AllStorePromotions() {
    return XGe.PROMOTIONS;
  }
}
const g1 = (t) => t.endsWith("/") ? t.slice(0, -1) : t;
class t$e {
  constructor(e) {
    this.product = e, this.regions = {}, this.catalog = {}, this.writable = !1;
  }
  get readonly() {
    return !this.writable;
  }
  processRegions(e, n) {
    let r = {};
    Object.assign(r, this.regions);
    for (let s of e)
      this.regions[s] ? r[s] && delete r[s] : (this.regions[s] = !0, this.watchRegion(s, n));
    for (let s in r)
      console.log("Unwatching region: " + s), delete this.regions[s], this.unwatchRegion(s);
  }
  unwatchCatalog() {
  }
  unwatchRegion(e) {
  }
}
const E5 = {
  METADATA: {
    name: "PROVINCE_NAME",
    short: "PROVINCE_NAME_SHORT",
    project: "project",
    resource_id: "SERVER_FOLDER",
    regulations_version: "regulations_version",
    subscription_version: "subscription_version",
    url: "STR_PROVINCE_URL"
  }
}, S5 = {
  METADATA: {
    name: "name",
    sku_web: "sku_web",
    sku_ios: "sku",
    sku_android: "sku_android",
    url: "more_info_url",
    header: "description_header",
    description: "description",
    generic: "name_generic"
  }
}, I5 = {
  METADATA: {
    name: "name",
    color: "color"
  }
}, R5 = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  }
}, y1 = {
  METADATA: {
    name: "name",
    name_plural: "name_plural",
    color: "color"
    // description: 'what_is_description',
  },
  BOUNDARYDATA: {
    name: "Title",
    product: "Subtitle"
    // north: 'North',
    // east: 'East',
    // south: 'South',
    // west: 'West'
  }
}, k5 = {
  METADATA: {
    name: "name",
    description: "description"
  },
  BOUNDARYDATA: {
    name: "Title"
    //product: 'Subtitle'
  }
}, n$e = {
  METADATA: {
    //sku: 'name', // This is a bad assumption; name != sku
    bounds: "bounds"
  }
};
class ga {
  static RegionPropertyMap() {
    return E5.METADATA;
  }
  static SubscriptionPropertyMap() {
    return S5.METADATA;
  }
  static SubscriptionLayerPropertyMap() {
    return I5.METADATA;
  }
  static ResourcePropertyMap() {
    return R5.METADATA;
  }
  static ResourceLayerPropertyMap() {
    return y1.METADATA;
  }
  static ResourceLayerBoundarydataPropertyMap() {
    return y1.BOUNDARYDATA;
  }
  static ProductBoundarydataPropertyMap() {
    return k5.BOUNDARYDATA;
  }
  static ExtractRegionFromMetadata(e, n) {
    return this.CopyProperties(E5.METADATA, e, n);
  }
  static ExtractSubscriptionFromMetadata(e, n) {
    return this.CopyProperties(S5.METADATA, e, n);
  }
  static ExtractSubscriptionLayerFromMetadata(e, n) {
    return this.CopyProperties(I5.METADATA, e, n);
  }
  static ExtractResourceFromMetadata(e, n) {
    return this.CopyProperties(R5.METADATA, e, n);
  }
  static ExtractResourceLayerFromMetadata(e, n) {
    return this.CopyProperties(y1.METADATA, e, n);
  }
  static ExtractMapFromMetadata(e, n) {
    return this.CopyProperties(k5.METADATA, e, n);
  }
  static ExtractProductFromMetadata(e, n) {
    return this.CopyProperties(n$e.METADATA, e, n);
  }
  static CopyProperties(e, n, r) {
    if (r || (r = {}), n)
      for (let s in e) {
        const i = e[s];
        n[i] && (r[s] = n[i]);
      }
    return r;
  }
}
var o0 = { exports: {} };
function r$e(t) {
  let e = t.lastIndexOf("_"), n = t.slice(e + 1);
  return {
    map: t.slice(0, e).replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""),
    // Trim non-alphanumeric
    version: n
  };
}
o0.exports = r$e;
const a0 = (o0.exports == null ? {} : o0.exports).default || o0.exports;
var c0 = { exports: {} };
async function s$e(t) {
  const e = await Promise.all(Object.values(t));
  return Object.fromEntries(Object.keys(t).map((n, r) => [n, e[r]]));
}
c0.exports = s$e;
const ZU = (c0.exports == null ? {} : c0.exports).default || c0.exports;
Promise.allMap = ZU;
class i$e {
  constructor(e) {
    this.provider = e, this.includeMetadata = !0, this.countyLayerBoundarydata = {};
  }
  async getRegions() {
    return this.provider.getRegions();
  }
  copyProperties(e, n, r) {
    for (let s in r) {
      const i = r[s];
      n[i] && i && (e[s] = n[i]);
    }
    return e;
  }
  addMetadata(e, n) {
    this.includeMetadata && e && n && (e.metadata = n);
  }
  async getRegion(e) {
    let n = await this.provider.getRegionMetadata(e), r = {
      id: e,
      product: this.provider.product,
      resources: {}
    };
    this.addMetadata(r, n), this.copyProperties(r, n, ga.RegionPropertyMap());
    let s = r.resource_id || e;
    if (n && n.required_resources) {
      let i = {};
      for (let o of n.required_resources)
        i[o] = this.getResource(s, o);
      r.resources = await Promise.allMap(i);
    }
    if (n && n.subscriptions) {
      let i = {};
      for (let o of n.subscriptions)
        i[o] = this.getSubscription(s, o);
      r.subscriptions = await Promise.allMap(i);
    }
    return r;
  }
  async getResource(e, n) {
    let r = await this.provider.getResourceMetadata(e, n), s = {
      layers: {}
    };
    this.addMetadata(s, r), this.copyProperties(s, r, ga.ResourcePropertyMap());
    let i = {};
    for (let o of r.layers)
      i[o] = this.getResourceLayer(e, n, o);
    return s.layers = await Promise.allMap(i), s;
  }
  async getResourceLayer(e, n, r) {
    let s = await this.provider.getResourceLayerMetadata(e, n, r), i = {};
    return this.addMetadata(i, s), this.copyProperties(i, s, ga.ResourceLayerPropertyMap()), n === "purchasable_layers" && r === "county" && (i.maps = await this.getCountyMaps(e)), i;
  }
  async getSubscription(e, n) {
    let r = await this.provider.getSubscriptionMetadata(e, n), s = {};
    if (this.addMetadata(s, r), this.copyProperties(s, r, ga.SubscriptionPropertyMap()), r.layers) {
      s.layers = {};
      let i = {};
      for (let o of r.layers)
        i[o] = this.getSubscriptionLayer(e, n, o);
      s.layers = await Promise.allMap(i);
    }
    return s;
  }
  async getSubscriptionLayer(e, n, r) {
    let s = {};
    try {
      let i = await this.provider.getSubscriptionLayerMetadata(e, n, r);
      if (!i)
        return s;
      this.addMetadata(s, i), this.copyProperties(s, i, ga.SubscriptionLayerPropertyMap());
    } catch (i) {
      console.error(i);
    }
    return s;
  }
  async getCountyMapMetadata(e, n) {
    this.countyLayerBoundarydata[e] || (this.countyLayerBoundarydata[e] = await this.provider.getCountyLayerBoundarydata(e));
    let r = this.countyLayerBoundarydata[e];
    if (r)
      for (let s in r) {
        let i = r[s], o = i[ga.ResourceLayerBoundarydataPropertyMap().product];
        if (n === o) {
          let a = {};
          return this.copyProperties(a, i, ga.ProductBoundarydataPropertyMap()), a;
        }
      }
    return console.error("getCountyMapMetadata failed to metch metadata for " + n), null;
  }
  async getCountyMaps(e) {
    let n = await this.provider.getCountyLayerMetadata(e), r = {};
    for (let o of n.counties) {
      let a = a0(o);
      r[a.map] || (r[a.map] = {
        products: {}
      }), r[a.map].products[o] = this.getCountyProduct(e, o);
    }
    let s = await Promise.allMap(r);
    for (let o in n.freeupdates) {
      let a = n.freeupdates[o], c = a0(a);
      s[c.map].products[a].freeupdate = o;
    }
    let i = await this.provider.getCountyLayerBoundarydata(e);
    if (i)
      for (let o in i) {
        let a = i[o], c = a[ga.ResourceLayerBoundarydataPropertyMap().product];
        s[c] && this.copyProperties(s[c], a, ga.ProductBoundarydataPropertyMap());
      }
    return s;
  }
  async getCountyProduct(e, n) {
    let r = a0(n), s = {
      map: r.map,
      version: r.version
    };
    return this.includeMetadata && this.addMetadata(s, await this.provider.getCountyProductMetadata(e, n)), s;
  }
  // getProductId(sku) {
  //     let product = sku.slice(0, -5);     // Remove date
  //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
  // }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }
}
class o$e extends t$e {
  constructor(e) {
    super(e), this.http = new _oe(), this.builder = new i$e(this), this.builder.includeMetadata = !1;
  }
  async getRegions() {
    return (await this.http.getJson(Fi.AllRegions())).provinces;
  }
  watchCatalog(e) {
    this.builder.getRegions().then((n) => this.processRegions(n, e)).catch((n) => {
      throw n;
    });
  }
  watchRegions(e, n) {
    this.processRegions(e, n);
  }
  watchRegion(e, n) {
    this.builder.getRegion(e).then((r) => (this.catalog[e] = r, n(e, this.catalog[e]))).catch((r) => {
      throw r;
    });
  }
  async getRegionMetadata(e) {
    return this.http.getJson(Fi.RegionMetadata(e));
  }
  async getSubscriptionMetadata(e, n) {
    try {
      return await this.http.getJson(Fi.SubscriptionMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getSubscriptionLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Fi.SubscriptionLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceMetadata(e, n) {
    try {
      return await this.http.getJson(Fi.ResourceMetadata(e, n));
    } catch {
      return null;
    }
  }
  async getResourceLayerMetadata(e, n, r) {
    try {
      return await this.http.getJson(Fi.ResourceLayerMetadata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerBoundarydata(e, n, r) {
    try {
      return await this.http.getJson(Fi.ResourceLayerBoundarydata(e, n, r));
    } catch {
      return null;
    }
  }
  async getResourceLayerMapMetadata(e, n, r, s) {
    try {
      return n === "purchasable_layers" && r === "county" ? this.builder.getCountyMapMetadata(e, s) : null;
    } catch {
      return null;
    }
  }
  async getResourceLayerProductMetadata(e, n, r, s, i) {
    try {
      return n === "purchasable_layers" && r === "county" ? await this.getCountyProductMetadata(e, i) : null;
    } catch {
      return null;
    }
  }
  async getCountyLayerMetadata(e) {
    try {
      return console.log("fetch: " + Fi.CountyMetadata(e)), await this.http.getJson(Fi.CountyMetadata(e));
    } catch {
      return null;
    }
  }
  async getCountyLayerBoundarydata(e) {
    return this.getResourceLayerBoundarydata(e, "purchasable_layers", "county");
  }
  async getCountyProductMetadata(e, n) {
    try {
      return console.log("fetch: " + Fi.CountyProductMetadata(e, n)), await this.http.getJson(Fi.CountyProductMetadata(e, n));
    } catch {
      return null;
    }
  }
  getProductId(e) {
    return e.slice(0, -5).replace(/\W+$/, "");
  }
  // splitMapProduct(sku) {
  //     let split = sku.lastIndexOf('_');
  //     let version = sku.slice(split + 1);
  //     let product = sku.slice(0, split);     // Remove date
  //     return {
  //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
  //         version: version
  //     };
  // }   
}
const qy = {
  APPLE: "/purchases_ios/{uid}",
  ANDROID: "/purchases_android/{uid}",
  WEB: "/purchases_web/{uid}",
  SKU: "/{region}/{sku}"
};
class a$e {
  static Apple(e) {
    return Z(qy.APPLE, { uid: e });
  }
  static Android(e) {
    return Z(qy.ANDROID, { uid: e });
  }
  static Web(e) {
    return Z(qy.WEB, { uid: e });
  }
  static WebPurchase(e, n, r) {
    return Z(qy.WEB, { uid: e }) + Z(qy.SKU, { region: n, sku: r });
  }
}
const Vr = {
  USERS: "users",
  CUSTOMERS: "customers",
  SUBSCRIPTIONS: "subscriptions",
  SCHEDULES: "schedules",
  PRODUCTS: "products",
  PRICES: "prices",
  PROMOTIONS: "promotions",
  COUPONS: "coupons",
  PAYMENTS: "payments",
  INVOICES: "invoices"
}, Hy = {
  CUSTOMER: `/${Vr.USERS}/{uid}/${Vr.CUSTOMERS}/{cid}`,
  SUBSCRIPTION: `/${Vr.USERS}/{uid}/${Vr.SUBSCRIPTIONS}`,
  PRODUCT: `/${Vr.PRODUCTS}/{product}`,
  PRICE: `/${Vr.PRODUCTS}/{product}/${Vr.PRICES}/{price}`
};
class c$e {
  static Customer(e, n) {
    return Z(Hy.CUSTOMER, { uid: e, cid: n });
  }
  static SubscriptionsCollection(e) {
    return Z(Hy.SUBSCRIPTION, { uid: e });
  }
  // static CustomerPromotionClaim(uid, claim) {
  //     return templ8r(BILLING.PROMOTION, {uid, claim});
  // }
  static CustomerRoute() {
    return Hy.CUSTOMER;
  }
  static PriceRoute() {
    return Hy.PRICE;
  }
  static ProductRoute() {
    return Hy.PRODUCT;
  }
  static Users() {
    return Vr.USERS;
  }
  static Customers() {
    return Vr.CUSTOMERS;
  }
  static Subscriptions() {
    return Vr.SUBSCRIPTIONS;
  }
  static Schedules() {
    return Vr.SCHEDULES;
  }
  static Products() {
    return Vr.PRODUCTS;
  }
  static Prices() {
    return Vr.PRICES;
  }
  static Promotions() {
    return Vr.PROMOTIONS;
  }
  static Payments() {
    return Vr.PAYMENTS;
  }
  static Coupons() {
    return Vr.COUPONS;
  }
  static Invoices() {
    return Vr.INVOICES;
  }
}
const ud = {
  PREFERENCES: "/mailinglist/users/{uid}",
  OPT_IN: "/mailinglist/users/{uid}/optin",
  // Set to true to opt-in to mailing list
  STATUS: "/mailinglist/users/{uid}/status",
  // Latest status from Mailchimp (pending, subscribed, unsubscribed, cleaned)
  EMAIL: "/mailinglist/users/{uid}/email"
  // Email address used for mailing list
};
class Jp {
  static Preferences(e) {
    return Z(ud.PREFERENCES, { uid: e });
  }
  static OptIn(e) {
    return Z(ud.OPT_IN, { uid: e });
  }
  static OptInRoute() {
    return ud.OPT_IN;
  }
  static Status(e) {
    return Z(ud.STATUS, { uid: e });
  }
  static StatusRoute() {
    return ud.STATUS;
  }
  static Email(e) {
    return Z(ud.EMAIL, { uid: e });
  }
  static EmailRoute() {
    return ud.EMAIL;
  }
}
const N5 = {
  UPGRADE: 0.2
  // 20% off map upgrades
};
class l$e {
  static ApplyUpgradeDiscount(e) {
    return this.ApplyDiscount(e, N5.UPGRADE);
  }
  static UpgradeDiscount() {
    return 100 * N5.UPGRADE;
  }
  static ApplyDiscount(e, n) {
    return (e * (1 - n)).toFixed(2);
  }
}
const hd = {
  CREATE_CONTACT_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}",
  CREATE_CONTACT_PROCESSED_ROUTE: "/outgoingrequests/{requesterUid}/{responderUid}/processed",
  CREATE_CHAT_ROUTE: "/outgoingrequests/chats/{requesterUid}/{chatKey}",
  CREATE_MESSAGE_ROUTE: "/outgoingrequests/messages/{requesterUid}/{chatKey}"
}, v1 = {
  CREATE_CONTACT_ROUTE: "/requests/{responderUid}/{requesterUid}",
  CREATE_CONTACT_ACCEPTED_ROUTE: "/requests/{responderUid}/{requesterUid}/accepted"
}, P5 = {
  ROUTE: "/contacts/{uid1}/{uid2}",
  DATA: { accepted: !0 }
}, w1 = {
  ROUTE: "/conversations/chats/{chatKey}",
  PARTICIPANTS_ROUTE: "/conversations/chats/{chatKey}/participants",
  GROUP_NAME: "New Group"
}, O5 = {
  ROUTE: "/activeconversations/{uid}/{chatKey}",
  DATA: !0
}, jy = {
  ROUTE: "/conversations/messages/{chatKey}/{messageKey}",
  TYPE: "notification",
  FIRST_SOLO_MESSAGE_TEXT: "You are now connected",
  FIRST_GROUP_MESSAGE_TEXT: "{usernames} have joined the group"
}, dd = {
  ROUTE: "/notifications/{uid}",
  FCM_TOKEN_ROUTE: "/notifications/{uid}/{fcmToken}",
  NOTIFY_ALL: "notify_all",
  TITLE_FROM_NOTIFY_ALL: "Notification",
  TITLE_FROM_SENDER: "New iHunter message from {senderUsername}"
}, D5 = {
  ROUTE: "/users/{uid}",
  UNKNOWN_NAME: "Unknown Name"
};
class cH {
  static OutgoingContactRequestProcessedRoute() {
    return hd.CREATE_CONTACT_PROCESSED_ROUTE;
  }
  static OutgoingContactRequestProcessed(e, n) {
    return Z(hd.CREATE_CONTACT_PROCESSED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingContactRequest(e, n) {
    return Z(hd.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static OutgoingChatRequestRoute() {
    return hd.CREATE_CHAT_ROUTE;
  }
  static OutgoingChatRequest(e, n) {
    return Z(hd.CREATE_CHAT_ROUTE, { requesterUid: e, chatKey: n });
  }
  static OutgoingChatRequestData(e) {
    const n = { participants: {} };
    for (let r of e)
      n.participants[r] = !0;
    return n;
  }
  static OutgoingMessageRequestRoute() {
    return hd.CREATE_MESSAGE_ROUTE;
  }
  static OutgoingMessageRequest(e, n) {
    return Z(hd.CREATE_MESSAGE_ROUTE, { requesterUid: e, chatKey: n });
  }
  static IncomingContactRequestAcceptedRoute() {
    return v1.CREATE_CONTACT_ACCEPTED_ROUTE;
  }
  static IncomingContactRequestAccepted(e, n) {
    return Z(v1.CREATE_CONTACT_ACCEPTED_ROUTE, { responderUid: n, requesterUid: e });
  }
  static IncomingContactRequest(e, n) {
    return Z(v1.CREATE_CONTACT_ROUTE, { responderUid: n, requesterUid: e });
  }
  static Contact(e, n) {
    return Z(P5.ROUTE, { uid1: e, uid2: n });
  }
  static ContactData() {
    return P5.DATA;
  }
  static Chat(e) {
    return Z(w1.ROUTE, { chatKey: e });
  }
  static ChatData(e, n) {
    var r, s;
    const i = Date.now();
    let o = e.length > 2, a = {}, c = {}, l = {};
    for (let u of e)
      a[u] = !0, c[u] = ((r = n == null ? void 0 : n.lastviewed) == null ? void 0 : r[u]) || i, l[u] = ((s = n == null ? void 0 : n.lastcleared) == null ? void 0 : s[u]) || i;
    let h = {
      participants: a,
      isgroupchat: o,
      lastviewed: c,
      lastcleared: l
    };
    return o && (h.chatname = w1.GROUP_NAME), h;
  }
  static ChatParticipants(e) {
    return Z(w1.PARTICIPANTS_ROUTE, { chatKey: e });
  }
  static ActiveConversation(e, n) {
    return Z(O5.ROUTE, { uid: e, chatKey: n });
  }
  static ActiveConversationData() {
    return O5.DATA;
  }
  static MessageRoute() {
    return jy.ROUTE;
  }
  static Message(e, n) {
    const r = cH.MessageKey(n);
    return Z(jy.ROUTE, { chatKey: e, messageKey: r });
  }
  static MessageData() {
    return {
      uid: dd.NOTIFY_ALL,
      type: jy.TYPE,
      time: Date.now()
    };
  }
  static FirstMessageText(e) {
    if (e.length > 2) {
      let n = e.join(", ");
      return Z(jy.FIRST_GROUP_MESSAGE_TEXT, { usernames: n });
    } else
      return jy.FIRST_SOLO_MESSAGE_TEXT;
  }
  static MessageKey(e) {
    return `${(/* @__PURE__ */ new Date()).toISOString().replace(/\.[0-9]{3}/, "").replace("T", " ").replace("Z", "")}_${e}`;
  }
  static Notifications(e) {
    return Z(dd.ROUTE, { uid: e });
  }
  static NotificationFCMToken(e, n) {
    return Z(dd.FCM_TOKEN_ROUTE, { uid: e, fcmToken: n });
  }
  static NotificationTitle(e, n) {
    return e === dd.NOTIFY_ALL ? dd.TITLE_FROM_NOTIFY_ALL : Z(dd.TITLE_FROM_SENDER, { senderUsername: n });
  }
  static User(e) {
    return Z(D5.ROUTE, { uid: e });
  }
  static NotifyAll() {
    return dd.NOTIFY_ALL;
  }
  static UnknownUserName() {
    return D5.UNKNOWN_NAME;
  }
}
const moe = {
  /**
   * @define {boolean} Whether this is the client Node.js SDK.
   */
  NODE_CLIENT: !1,
  /**
   * @define {boolean} Whether this is the Admin Node.js SDK.
   */
  NODE_ADMIN: !1,
  /**
   * Firebase SDK Version
   */
  SDK_VERSION: "${JSCORE_VERSION}"
}, A = function(t, e) {
  if (!t)
    throw yg(e);
}, yg = function(t) {
  return new Error("Firebase Database (" + moe.SDK_VERSION + ") INTERNAL ASSERT FAILED: " + t);
}, goe = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, u$e = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, lH = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, l = c ? t[s + 2] : 0, h = i >> 2, u = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | l >> 6, f = l & 63;
      c || (f = 64, o || (d = 64)), r.push(n[h], n[u], n[d], n[f]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(goe(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : u$e(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], o = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const a = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const c = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || o == null || a == null || c == null)
        throw new h$e();
      const l = i << 2 | o >> 4;
      if (r.push(l), a !== 64) {
        const h = o << 4 & 240 | a >> 2;
        if (r.push(h), c !== 64) {
          const u = a << 6 & 192 | c;
          r.push(u);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class h$e extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const yoe = function(t) {
  const e = goe(t);
  return lH.encodeByteArray(e, !0);
}, lk = function(t) {
  return yoe(t).replace(/\./g, "");
}, XU = function(t) {
  try {
    return lH.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
function d$e(t) {
  return voe(void 0, t);
}
function voe(t, e) {
  if (!(e instanceof Object))
    return e;
  switch (e.constructor) {
    case Date:
      const n = e;
      return new Date(n.getTime());
    case Object:
      t === void 0 && (t = {});
      break;
    case Array:
      t = [];
      break;
    default:
      return e;
  }
  for (const n in e)
    !e.hasOwnProperty(n) || !f$e(n) || (t[n] = voe(t[n], e[n]));
  return t;
}
function f$e(t) {
  return t !== "__proto__";
}
function p$e() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
const _$e = () => p$e().__FIREBASE_DEFAULTS__, m$e = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, g$e = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && XU(t[1]);
  return e && JSON.parse(e);
}, uH = () => {
  try {
    return _$e() || m$e() || g$e();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, y$e = (t) => {
  var e, n;
  return (n = (e = uH()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, woe = (t) => {
  const e = y$e(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const r = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), r] : [e.substring(0, n), r];
}, v$e = () => {
  var t;
  return (t = uH()) === null || t === void 0 ? void 0 : t.config;
};
class Mb {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, r) => {
      n ? this.reject(n) : this.resolve(r), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, r));
    };
  }
}
function Coe(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, r = e || "demo-project", s = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${r}`,
    aud: r,
    iat: s,
    exp: s + 3600,
    auth_time: s,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t);
  return [
    lk(JSON.stringify(n)),
    lk(JSON.stringify(o)),
    ""
  ].join(".");
}
function w$e() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function Toe() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(w$e());
}
function C$e() {
  var t;
  const e = (t = uH()) === null || t === void 0 ? void 0 : t.forceEnvironment;
  if (e === "node")
    return !0;
  if (e === "browser")
    return !1;
  try {
    return Object.prototype.toString.call(global.process) === "[object process]";
  } catch {
    return !1;
  }
}
function T$e() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function boe() {
  return moe.NODE_ADMIN === !0;
}
function b$e() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function E$e() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
const S$e = "FirebaseError";
class vg extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = S$e, Object.setPrototypeOf(this, vg.prototype), Error.captureStackTrace && Error.captureStackTrace(this, Eoe.prototype.create);
  }
}
class Eoe {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? I$e(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new vg(s, a, r);
  }
}
function I$e(t, e) {
  return t.replace(R$e, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const R$e = /\{\$([^}]+)}/g;
function MC(t) {
  return JSON.parse(t);
}
function yr(t) {
  return JSON.stringify(t);
}
const Soe = function(t) {
  let e = {}, n = {}, r = {}, s = "";
  try {
    const i = t.split(".");
    e = MC(XU(i[0]) || ""), n = MC(XU(i[1]) || ""), s = i[2], r = n.d || {}, delete n.d;
  } catch {
  }
  return {
    header: e,
    claims: n,
    data: r,
    signature: s
  };
}, k$e = function(t) {
  const e = Soe(t), n = e.claims;
  return !!n && typeof n == "object" && n.hasOwnProperty("iat");
}, N$e = function(t) {
  const e = Soe(t).claims;
  return typeof e == "object" && e.admin === !0;
};
function Ka(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function Bm(t, e) {
  if (Object.prototype.hasOwnProperty.call(t, e))
    return t[e];
}
function A5(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function uk(t, e, n) {
  const r = {};
  for (const s in t)
    Object.prototype.hasOwnProperty.call(t, s) && (r[s] = e.call(n, t[s], s, t));
  return r;
}
function eF(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), r = Object.keys(e);
  for (const s of n) {
    if (!r.includes(s))
      return !1;
    const i = t[s], o = e[s];
    if (M5(i) && M5(o)) {
      if (!eF(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const s of r)
    if (!n.includes(s))
      return !1;
  return !0;
}
function M5(t) {
  return t !== null && typeof t == "object";
}
function P$e(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
class O$e {
  constructor() {
    this.chain_ = [], this.buf_ = [], this.W_ = [], this.pad_ = [], this.inbuf_ = 0, this.total_ = 0, this.blockSize = 512 / 8, this.pad_[0] = 128;
    for (let e = 1; e < this.blockSize; ++e)
      this.pad_[e] = 0;
    this.reset();
  }
  reset() {
    this.chain_[0] = 1732584193, this.chain_[1] = 4023233417, this.chain_[2] = 2562383102, this.chain_[3] = 271733878, this.chain_[4] = 3285377520, this.inbuf_ = 0, this.total_ = 0;
  }
  /**
   * Internal compress helper function.
   * @param buf Block to compress.
   * @param offset Offset of the block in the buffer.
   * @private
   */
  compress_(e, n) {
    n || (n = 0);
    const r = this.W_;
    if (typeof e == "string")
      for (let u = 0; u < 16; u++)
        r[u] = e.charCodeAt(n) << 24 | e.charCodeAt(n + 1) << 16 | e.charCodeAt(n + 2) << 8 | e.charCodeAt(n + 3), n += 4;
    else
      for (let u = 0; u < 16; u++)
        r[u] = e[n] << 24 | e[n + 1] << 16 | e[n + 2] << 8 | e[n + 3], n += 4;
    for (let u = 16; u < 80; u++) {
      const d = r[u - 3] ^ r[u - 8] ^ r[u - 14] ^ r[u - 16];
      r[u] = (d << 1 | d >>> 31) & 4294967295;
    }
    let s = this.chain_[0], i = this.chain_[1], o = this.chain_[2], a = this.chain_[3], c = this.chain_[4], l, h;
    for (let u = 0; u < 80; u++) {
      u < 40 ? u < 20 ? (l = a ^ i & (o ^ a), h = 1518500249) : (l = i ^ o ^ a, h = 1859775393) : u < 60 ? (l = i & o | a & (i | o), h = 2400959708) : (l = i ^ o ^ a, h = 3395469782);
      const d = (s << 5 | s >>> 27) + l + c + h + r[u] & 4294967295;
      c = a, a = o, o = (i << 30 | i >>> 2) & 4294967295, i = s, s = d;
    }
    this.chain_[0] = this.chain_[0] + s & 4294967295, this.chain_[1] = this.chain_[1] + i & 4294967295, this.chain_[2] = this.chain_[2] + o & 4294967295, this.chain_[3] = this.chain_[3] + a & 4294967295, this.chain_[4] = this.chain_[4] + c & 4294967295;
  }
  update(e, n) {
    if (e == null)
      return;
    n === void 0 && (n = e.length);
    const r = n - this.blockSize;
    let s = 0;
    const i = this.buf_;
    let o = this.inbuf_;
    for (; s < n; ) {
      if (o === 0)
        for (; s <= r; )
          this.compress_(e, s), s += this.blockSize;
      if (typeof e == "string") {
        for (; s < n; )
          if (i[o] = e.charCodeAt(s), ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
      } else
        for (; s < n; )
          if (i[o] = e[s], ++o, ++s, o === this.blockSize) {
            this.compress_(i), o = 0;
            break;
          }
    }
    this.inbuf_ = o, this.total_ += n;
  }
  /** @override */
  digest() {
    const e = [];
    let n = this.total_ * 8;
    this.inbuf_ < 56 ? this.update(this.pad_, 56 - this.inbuf_) : this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
    for (let s = this.blockSize - 1; s >= 56; s--)
      this.buf_[s] = n & 255, n /= 256;
    this.compress_(this.buf_);
    let r = 0;
    for (let s = 0; s < 5; s++)
      for (let i = 24; i >= 0; i -= 8)
        e[r] = this.chain_[s] >> i & 255, ++r;
    return e;
  }
}
function SO(t, e) {
  return `${t} failed: ${e} argument `;
}
const D$e = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    if (s >= 55296 && s <= 56319) {
      const i = s - 55296;
      r++, A(r < t.length, "Surrogate pair missing trail surrogate.");
      const o = t.charCodeAt(r) - 56320;
      s = 65536 + (i << 10) + o;
    }
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : s < 65536 ? (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, IO = function(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) {
    const r = t.charCodeAt(n);
    r < 128 ? e++ : r < 2048 ? e += 2 : r >= 55296 && r <= 56319 ? (e += 4, n++) : e += 3;
  }
  return e;
};
function io(t) {
  return t && t._delegate ? t._delegate : t;
}
class qm {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
const Od = "[DEFAULT]";
class A$e {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const r = new Mb();
      if (this.instancesDeferred.set(n, r), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const s = this.getOrInitializeService({
            instanceIdentifier: n
          });
          s && r.resolve(s);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const r = this.normalizeInstanceIdentifier(e == null ? void 0 : e.identifier), s = (n = e == null ? void 0 : e.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(r) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: r
        });
      } catch (i) {
        if (s)
          return null;
        throw i;
      }
    else {
      if (s)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (x$e(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: Od });
        } catch {
        }
      for (const [n, r] of this.instancesDeferred.entries()) {
        const s = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: s
          });
          r.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = Od) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = Od) {
    return this.instances.has(e);
  }
  getOptions(e = Od) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, r = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(r))
      throw Error(`${this.name}(${r}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const s = this.getOrInitializeService({
      instanceIdentifier: r,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      r === a && o.resolve(s);
    }
    return s;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var r;
    const s = this.normalizeInstanceIdentifier(n), i = (r = this.onInitCallbacks.get(s)) !== null && r !== void 0 ? r : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(s, i);
    const o = this.instances.get(s);
    return o && e(o, s), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const r = this.onInitCallbacks.get(n);
    if (r)
      for (const s of r)
        try {
          s(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let r = this.instances.get(e);
    if (!r && this.component && (r = this.component.instanceFactory(this.container, {
      instanceIdentifier: M$e(e),
      options: n
    }), this.instances.set(e, r), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(r, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, r);
      } catch {
      }
    return r || null;
  }
  normalizeInstanceIdentifier(e = Od) {
    return this.component ? this.component.multipleInstances ? e : Od : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function M$e(t) {
  return t === Od ? void 0 : t;
}
function x$e(t) {
  return t.instantiationMode === "EAGER";
}
class L$e {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new A$e(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
var Qt;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(Qt || (Qt = {}));
const U$e = {
  debug: Qt.DEBUG,
  verbose: Qt.VERBOSE,
  info: Qt.INFO,
  warn: Qt.WARN,
  error: Qt.ERROR,
  silent: Qt.SILENT
}, F$e = Qt.INFO, Y$e = {
  [Qt.DEBUG]: "log",
  [Qt.VERBOSE]: "log",
  [Qt.INFO]: "info",
  [Qt.WARN]: "warn",
  [Qt.ERROR]: "error"
}, W$e = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = Y$e[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class Ioe {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = F$e, this._logHandler = W$e, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in Qt))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? U$e[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, Qt.DEBUG, ...e), this._logHandler(this, Qt.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, Qt.VERBOSE, ...e), this._logHandler(this, Qt.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, Qt.INFO, ...e), this._logHandler(this, Qt.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, Qt.WARN, ...e), this._logHandler(this, Qt.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, Qt.ERROR, ...e), this._logHandler(this, Qt.ERROR, ...e);
  }
}
const B$e = (t, e) => e.some((n) => t instanceof n);
let x5, L5;
function q$e() {
  return x5 || (x5 = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function H$e() {
  return L5 || (L5 = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const Roe = /* @__PURE__ */ new WeakMap(), tF = /* @__PURE__ */ new WeakMap(), koe = /* @__PURE__ */ new WeakMap(), C1 = /* @__PURE__ */ new WeakMap(), hH = /* @__PURE__ */ new WeakMap();
function j$e(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(lh(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && Roe.set(n, t);
  }).catch(() => {
  }), hH.set(e, t), e;
}
function V$e(t) {
  if (tF.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  tF.set(t, e);
}
let nF = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return tF.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || koe.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return lh(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function z$e(t) {
  nF = t(nF);
}
function G$e(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(T1(this), e, ...n);
    return koe.set(r, e.sort ? e.sort() : [e]), lh(r);
  } : H$e().includes(t) ? function(...e) {
    return t.apply(T1(this), e), lh(Roe.get(this));
  } : function(...e) {
    return lh(t.apply(T1(this), e));
  };
}
function $$e(t) {
  return typeof t == "function" ? G$e(t) : (t instanceof IDBTransaction && V$e(t), B$e(t, q$e()) ? new Proxy(t, nF) : t);
}
function lh(t) {
  if (t instanceof IDBRequest)
    return j$e(t);
  if (C1.has(t))
    return C1.get(t);
  const e = $$e(t);
  return e !== t && (C1.set(t, e), hH.set(e, t)), e;
}
const T1 = (t) => hH.get(t);
function K$e(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = lh(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(lh(o.result), c.oldVersion, c.newVersion, lh(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", () => s());
  }).catch(() => {
  }), a;
}
const J$e = ["get", "getKey", "getAll", "getAllKeys", "count"], Q$e = ["put", "add", "delete", "clear"], b1 = /* @__PURE__ */ new Map();
function U5(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (b1.get(e))
    return b1.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = Q$e.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || J$e.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let l = c.store;
    return r && (l = l.index(a.shift())), (await Promise.all([
      l[n](...a),
      s && c.done
    ]))[0];
  };
  return b1.set(e, i), i;
}
z$e((t) => ({
  ...t,
  get: (e, n, r) => U5(e, n) || t.get(e, n, r),
  has: (e, n) => !!U5(e, n) || t.has(e, n)
}));
class Z$e {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((e) => {
      if (X$e(e)) {
        const n = e.getImmediate();
        return `${n.library}/${n.version}`;
      } else
        return null;
    }).filter((e) => e).join(" ");
  }
}
function X$e(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const rF = "@firebase/app", F5 = "0.9.8", tp = new Ioe("@firebase/app"), eKe = "@firebase/app-compat", tKe = "@firebase/analytics-compat", nKe = "@firebase/analytics", rKe = "@firebase/app-check-compat", sKe = "@firebase/app-check", iKe = "@firebase/auth", oKe = "@firebase/auth-compat", aKe = "@firebase/database", cKe = "@firebase/database-compat", lKe = "@firebase/functions", uKe = "@firebase/functions-compat", hKe = "@firebase/installations", dKe = "@firebase/installations-compat", fKe = "@firebase/messaging", pKe = "@firebase/messaging-compat", _Ke = "@firebase/performance", mKe = "@firebase/performance-compat", gKe = "@firebase/remote-config", yKe = "@firebase/remote-config-compat", vKe = "@firebase/storage", wKe = "@firebase/storage-compat", CKe = "@firebase/firestore", TKe = "@firebase/firestore-compat", bKe = "firebase", EKe = "9.20.0", sF = "[DEFAULT]", SKe = {
  [rF]: "fire-core",
  [eKe]: "fire-core-compat",
  [nKe]: "fire-analytics",
  [tKe]: "fire-analytics-compat",
  [sKe]: "fire-app-check",
  [rKe]: "fire-app-check-compat",
  [iKe]: "fire-auth",
  [oKe]: "fire-auth-compat",
  [aKe]: "fire-rtdb",
  [cKe]: "fire-rtdb-compat",
  [lKe]: "fire-fn",
  [uKe]: "fire-fn-compat",
  [hKe]: "fire-iid",
  [dKe]: "fire-iid-compat",
  [fKe]: "fire-fcm",
  [pKe]: "fire-fcm-compat",
  [_Ke]: "fire-perf",
  [mKe]: "fire-perf-compat",
  [gKe]: "fire-rc",
  [yKe]: "fire-rc-compat",
  [vKe]: "fire-gcs",
  [wKe]: "fire-gcs-compat",
  [CKe]: "fire-fst",
  [TKe]: "fire-fst-compat",
  "fire-js": "fire-js",
  [bKe]: "fire-js-all"
}, hk = /* @__PURE__ */ new Map(), iF = /* @__PURE__ */ new Map();
function IKe(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    tp.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function xC(t) {
  const e = t.name;
  if (iF.has(e))
    return tp.debug(`There were multiple attempts to register component ${e}.`), !1;
  iF.set(e, t);
  for (const n of hk.values())
    IKe(n, t);
  return !0;
}
function Noe(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
const RKe = {
  "no-app": "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  "bad-app-name": "Illegal App name: '{$appName}",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, uh = new Eoe("app", "Firebase", RKe);
class kKe {
  constructor(e, n, r) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = r, this.container.addComponent(new qm(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw uh.create("app-deleted", { appName: this._name });
  }
}
const Poe = EKe;
function NKe(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const r = Object.assign({ name: sF, automaticDataCollectionEnabled: !1 }, e), s = r.name;
  if (typeof s != "string" || !s)
    throw uh.create("bad-app-name", {
      appName: String(s)
    });
  if (n || (n = v$e()), !n)
    throw uh.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = hk.get(s);
  if (i) {
    if (eF(n, i.options) && eF(r, i.config))
      return i;
    throw uh.create("duplicate-app", { appName: s });
  }
  const o = new L$e(s);
  for (const c of iF.values())
    o.addComponent(c);
  const a = new kKe(n, r, o);
  return hk.set(s, a), a;
}
function Ooe(t = sF) {
  const e = hk.get(t);
  if (!e && t === sF)
    return NKe();
  if (!e)
    throw uh.create("no-app", { appName: t });
  return e;
}
function sf(t, e, n) {
  var r;
  let s = (r = SKe[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), tp.warn(a.join(" "));
    return;
  }
  xC(new qm(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
const PKe = "firebase-heartbeat-database", OKe = 1, LC = "firebase-heartbeat-store";
let E1 = null;
function Doe() {
  return E1 || (E1 = K$e(PKe, OKe, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(LC);
      }
    }
  }).catch((t) => {
    throw uh.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), E1;
}
async function DKe(t) {
  try {
    return (await Doe()).transaction(LC).objectStore(LC).get(Aoe(t));
  } catch (e) {
    if (e instanceof vg)
      tp.warn(e.message);
    else {
      const n = uh.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      tp.warn(n.message);
    }
  }
}
async function Y5(t, e) {
  try {
    const n = (await Doe()).transaction(LC, "readwrite");
    return await n.objectStore(LC).put(e, Aoe(t)), n.done;
  } catch (n) {
    if (n instanceof vg)
      tp.warn(n.message);
    else {
      const r = uh.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      tp.warn(r.message);
    }
  }
}
function Aoe(t) {
  return `${t.name}!${t.options.appId}`;
}
const AKe = 1024, MKe = 30 * 24 * 60 * 60 * 1e3;
class xKe {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new UKe(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const e = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), n = W5();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === n || this._heartbeatsCache.heartbeats.some((r) => r.date === n)))
      return this._heartbeatsCache.heartbeats.push({ date: n, agent: e }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const s = new Date(r.date).valueOf();
        return Date.now() - s <= MKe;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = W5(), { heartbeatsToSend: n, unsentEntries: r } = LKe(this._heartbeatsCache.heartbeats), s = lk(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, r.length > 0 ? (this._heartbeatsCache.heartbeats = r, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), s;
  }
}
function W5() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function LKe(t, e = AKe) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), B5(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), B5(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class UKe {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return b$e() ? E$e().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await DKe(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return Y5(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return Y5(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function B5(t) {
  return lk(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
function FKe(t) {
  xC(new qm(
    "platform-logger",
    (e) => new Z$e(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), xC(new qm(
    "heartbeat",
    (e) => new xKe(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), sf(rF, F5, t), sf(rF, F5, "esm2017"), sf("fire-js", "");
}
FKe("");
const q5 = "@firebase/database", H5 = "0.14.4";
let Moe = "";
function YKe(t) {
  Moe = t;
}
class WKe {
  /**
   * @param domStorage_ - The underlying storage object (e.g. localStorage or sessionStorage)
   */
  constructor(e) {
    this.domStorage_ = e, this.prefix_ = "firebase:";
  }
  /**
   * @param key - The key to save the value under
   * @param value - The value being stored, or null to remove the key.
   */
  set(e, n) {
    n == null ? this.domStorage_.removeItem(this.prefixedName_(e)) : this.domStorage_.setItem(this.prefixedName_(e), yr(n));
  }
  /**
   * @returns The value that was stored under this key, or null
   */
  get(e) {
    const n = this.domStorage_.getItem(this.prefixedName_(e));
    return n == null ? null : MC(n);
  }
  remove(e) {
    this.domStorage_.removeItem(this.prefixedName_(e));
  }
  prefixedName_(e) {
    return this.prefix_ + e;
  }
  toString() {
    return this.domStorage_.toString();
  }
}
class BKe {
  constructor() {
    this.cache_ = {}, this.isInMemoryStorage = !0;
  }
  set(e, n) {
    n == null ? delete this.cache_[e] : this.cache_[e] = n;
  }
  get(e) {
    return Ka(this.cache_, e) ? this.cache_[e] : null;
  }
  remove(e) {
    delete this.cache_[e];
  }
}
const xoe = function(t) {
  try {
    if (typeof window < "u" && typeof window[t] < "u") {
      const e = window[t];
      return e.setItem("firebase:sentinel", "cache"), e.removeItem("firebase:sentinel"), new WKe(e);
    }
  } catch {
  }
  return new BKe();
}, Yd = xoe("localStorage"), qKe = xoe("sessionStorage"), A_ = new Ioe("@firebase/database"), HKe = /* @__PURE__ */ function() {
  let t = 1;
  return function() {
    return t++;
  };
}(), Loe = function(t) {
  const e = D$e(t), n = new O$e();
  n.update(e);
  const r = n.digest();
  return lH.encodeByteArray(r);
}, xb = function(...t) {
  let e = "";
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    Array.isArray(r) || r && typeof r == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof r.length == "number" ? e += xb.apply(null, r) : typeof r == "object" ? e += yr(r) : e += r, e += " ";
  }
  return e;
};
let uw = null, j5 = !0;
const jKe = function(t, e) {
  A(!e, "Can't turn on custom loggers persistently."), A_.logLevel = Qt.VERBOSE, uw = A_.log.bind(A_);
}, Zr = function(...t) {
  if (j5 === !0 && (j5 = !1, uw === null && qKe.get("logging_enabled") === !0 && jKe()), uw) {
    const e = xb.apply(null, t);
    uw(e);
  }
}, Lb = function(t) {
  return function(...e) {
    Zr(t, ...e);
  };
}, oF = function(...t) {
  const e = "FIREBASE INTERNAL ERROR: " + xb(...t);
  A_.error(e);
}, Tl = function(...t) {
  const e = `FIREBASE FATAL ERROR: ${xb(...t)}`;
  throw A_.error(e), new Error(e);
}, Ys = function(...t) {
  const e = "FIREBASE WARNING: " + xb(...t);
  A_.warn(e);
}, VKe = function() {
  typeof window < "u" && window.location && window.location.protocol && window.location.protocol.indexOf("https:") !== -1 && Ys("Insecure Firebase access from a secure page. Please use https in calls to new Firebase().");
}, dH = function(t) {
  return typeof t == "number" && (t !== t || // NaN
  t === Number.POSITIVE_INFINITY || t === Number.NEGATIVE_INFINITY);
}, zKe = function(t) {
  if (document.readyState === "complete")
    t();
  else {
    let e = !1;
    const n = function() {
      if (!document.body) {
        setTimeout(n, Math.floor(10));
        return;
      }
      e || (e = !0, t());
    };
    document.addEventListener ? (document.addEventListener("DOMContentLoaded", n, !1), window.addEventListener("load", n, !1)) : document.attachEvent && (document.attachEvent("onreadystatechange", () => {
      document.readyState === "complete" && n();
    }), window.attachEvent("onload", n));
  }
}, Hm = "[MIN_NAME]", np = "[MAX_NAME]", yp = function(t, e) {
  if (t === e)
    return 0;
  if (t === Hm || e === np)
    return -1;
  if (e === Hm || t === np)
    return 1;
  {
    const n = V5(t), r = V5(e);
    return n !== null ? r !== null ? n - r === 0 ? t.length - e.length : n - r : -1 : r !== null ? 1 : t < e ? -1 : 1;
  }
}, GKe = function(t, e) {
  return t === e ? 0 : t < e ? -1 : 1;
}, Vy = function(t, e) {
  if (e && t in e)
    return e[t];
  throw new Error("Missing required key (" + t + ") in object: " + yr(e));
}, fH = function(t) {
  if (typeof t != "object" || t === null)
    return yr(t);
  const e = [];
  for (const r in t)
    e.push(r);
  e.sort();
  let n = "{";
  for (let r = 0; r < e.length; r++)
    r !== 0 && (n += ","), n += yr(e[r]), n += ":", n += fH(t[e[r]]);
  return n += "}", n;
}, Uoe = function(t, e) {
  const n = t.length;
  if (n <= e)
    return [t];
  const r = [];
  for (let s = 0; s < n; s += e)
    s + e > n ? r.push(t.substring(s, n)) : r.push(t.substring(s, s + e));
  return r;
};
function is(t, e) {
  for (const n in t)
    t.hasOwnProperty(n) && e(n, t[n]);
}
const Foe = function(t) {
  A(!dH(t), "Invalid JSON number");
  const e = 11, n = 52, r = (1 << e - 1) - 1;
  let s, i, o, a, c;
  t === 0 ? (i = 0, o = 0, s = 1 / t === -1 / 0 ? 1 : 0) : (s = t < 0, t = Math.abs(t), t >= Math.pow(2, 1 - r) ? (a = Math.min(Math.floor(Math.log(t) / Math.LN2), r), i = a + r, o = Math.round(t * Math.pow(2, n - a) - Math.pow(2, n))) : (i = 0, o = Math.round(t / Math.pow(2, 1 - r - n))));
  const l = [];
  for (c = n; c; c -= 1)
    l.push(o % 2 ? 1 : 0), o = Math.floor(o / 2);
  for (c = e; c; c -= 1)
    l.push(i % 2 ? 1 : 0), i = Math.floor(i / 2);
  l.push(s ? 1 : 0), l.reverse();
  const h = l.join("");
  let u = "";
  for (c = 0; c < 64; c += 8) {
    let d = parseInt(h.substr(c, 8), 2).toString(16);
    d.length === 1 && (d = "0" + d), u = u + d;
  }
  return u.toLowerCase();
}, $Ke = function() {
  return !!(typeof window == "object" && window.chrome && window.chrome.extension && !/^chrome/.test(window.location.href));
}, KKe = function() {
  return typeof Windows == "object" && typeof Windows.UI == "object";
};
function JKe(t, e) {
  let n = "Unknown Error";
  t === "too_big" ? n = "The data requested exceeds the maximum size that can be accessed with a single request." : t === "permission_denied" ? n = "Client doesn't have permission to access the desired data." : t === "unavailable" && (n = "The service is unavailable");
  const r = new Error(t + " at " + e._path.toString() + ": " + n);
  return r.code = t.toUpperCase(), r;
}
const QKe = new RegExp("^-?(0*)\\d{1,10}$"), ZKe = -2147483648, XKe = 2147483647, V5 = function(t) {
  if (QKe.test(t)) {
    const e = Number(t);
    if (e >= ZKe && e <= XKe)
      return e;
  }
  return null;
}, wg = function(t) {
  try {
    t();
  } catch (e) {
    setTimeout(() => {
      const n = e.stack || "";
      throw Ys("Exception was thrown by user callback.", n), e;
    }, Math.floor(0));
  }
}, e5e = function() {
  return (typeof window == "object" && window.navigator && window.navigator.userAgent || "").search(/googlebot|google webmaster tools|bingbot|yahoo! slurp|baiduspider|yandexbot|duckduckbot/i) >= 0;
}, hw = function(t, e) {
  const n = setTimeout(t, e);
  return typeof n == "number" && // @ts-ignore Is only defined in Deno environments.
  typeof Deno < "u" && // @ts-ignore Deno and unrefTimer are only defined in Deno environments.
  Deno.unrefTimer ? Deno.unrefTimer(n) : typeof n == "object" && n.unref && n.unref(), n;
};
class t5e {
  constructor(e, n) {
    this.appName_ = e, this.appCheckProvider = n, this.appCheck = n == null ? void 0 : n.getImmediate({ optional: !0 }), this.appCheck || n == null || n.get().then((r) => this.appCheck = r);
  }
  getToken(e) {
    return this.appCheck ? this.appCheck.getToken(e) : new Promise((n, r) => {
      setTimeout(() => {
        this.appCheck ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    var n;
    (n = this.appCheckProvider) === null || n === void 0 || n.get().then((r) => r.addTokenListener(e));
  }
  notifyForInvalidToken() {
    Ys(`Provided AppCheck credentials for the app named "${this.appName_}" are invalid. This usually indicates your app was not initialized correctly.`);
  }
}
class n5e {
  constructor(e, n, r) {
    this.appName_ = e, this.firebaseOptions_ = n, this.authProvider_ = r, this.auth_ = null, this.auth_ = r.getImmediate({ optional: !0 }), this.auth_ || r.onInit((s) => this.auth_ = s);
  }
  getToken(e) {
    return this.auth_ ? this.auth_.getToken(e).catch((n) => n && n.code === "auth/token-not-initialized" ? (Zr("Got auth/token-not-initialized error.  Treating as null token."), null) : Promise.reject(n)) : new Promise((n, r) => {
      setTimeout(() => {
        this.auth_ ? this.getToken(e).then(n, r) : n(null);
      }, 0);
    });
  }
  addTokenChangeListener(e) {
    this.auth_ ? this.auth_.addAuthTokenListener(e) : this.authProvider_.get().then((n) => n.addAuthTokenListener(e));
  }
  removeTokenChangeListener(e) {
    this.authProvider_.get().then((n) => n.removeAuthTokenListener(e));
  }
  notifyForInvalidToken() {
    let e = 'Provided authentication credentials for the app named "' + this.appName_ + '" are invalid. This usually indicates your app was not initialized correctly. ';
    "credential" in this.firebaseOptions_ ? e += 'Make sure the "credential" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : "serviceAccount" in this.firebaseOptions_ ? e += 'Make sure the "serviceAccount" property provided to initializeApp() is authorized to access the specified "databaseURL" and is from the correct project.' : e += 'Make sure the "apiKey" and "databaseURL" properties provided to initializeApp() match the values provided for your app at https://console.firebase.google.com/.', Ys(e);
  }
}
class l0 {
  constructor(e) {
    this.accessToken = e;
  }
  getToken(e) {
    return Promise.resolve({
      accessToken: this.accessToken
    });
  }
  addTokenChangeListener(e) {
    e(this.accessToken);
  }
  removeTokenChangeListener(e) {
  }
  notifyForInvalidToken() {
  }
}
l0.OWNER = "owner";
const pH = "5", Yoe = "v", Woe = "s", Boe = "r", qoe = "f", Hoe = /(console\.firebase|firebase-console-\w+\.corp|firebase\.corp)\.google\.com/, joe = "ls", Voe = "p", aF = "ac", zoe = "websocket", Goe = "long_polling";
class $oe {
  /**
   * @param host - Hostname portion of the url for the repo
   * @param secure - Whether or not this repo is accessed over ssl
   * @param namespace - The namespace represented by the repo
   * @param webSocketOnly - Whether to prefer websockets over all other transports (used by Nest).
   * @param nodeAdmin - Whether this instance uses Admin SDK credentials
   * @param persistenceKey - Override the default session persistence storage key
   */
  constructor(e, n, r, s, i = !1, o = "", a = !1, c = !1) {
    this.secure = n, this.namespace = r, this.webSocketOnly = s, this.nodeAdmin = i, this.persistenceKey = o, this.includeNamespaceInQueryParams = a, this.isUsingEmulator = c, this._host = e.toLowerCase(), this._domain = this._host.substr(this._host.indexOf(".") + 1), this.internalHost = Yd.get("host:" + e) || this._host;
  }
  isCacheableHost() {
    return this.internalHost.substr(0, 2) === "s-";
  }
  isCustomHost() {
    return this._domain !== "firebaseio.com" && this._domain !== "firebaseio-demo.com";
  }
  get host() {
    return this._host;
  }
  set host(e) {
    e !== this.internalHost && (this.internalHost = e, this.isCacheableHost() && Yd.set("host:" + this._host, this.internalHost));
  }
  toString() {
    let e = this.toURLString();
    return this.persistenceKey && (e += "<" + this.persistenceKey + ">"), e;
  }
  toURLString() {
    const e = this.secure ? "https://" : "http://", n = this.includeNamespaceInQueryParams ? `?ns=${this.namespace}` : "";
    return `${e}${this.host}/${n}`;
  }
}
function r5e(t) {
  return t.host !== t.internalHost || t.isCustomHost() || t.includeNamespaceInQueryParams;
}
function Koe(t, e, n) {
  A(typeof e == "string", "typeof type must == string"), A(typeof n == "object", "typeof params must == object");
  let r;
  if (e === zoe)
    r = (t.secure ? "wss://" : "ws://") + t.internalHost + "/.ws?";
  else if (e === Goe)
    r = (t.secure ? "https://" : "http://") + t.internalHost + "/.lp?";
  else
    throw new Error("Unknown connection type: " + e);
  r5e(t) && (n.ns = t.namespace);
  const s = [];
  return is(n, (i, o) => {
    s.push(i + "=" + o);
  }), r + s.join("&");
}
class s5e {
  constructor() {
    this.counters_ = {};
  }
  incrementCounter(e, n = 1) {
    Ka(this.counters_, e) || (this.counters_[e] = 0), this.counters_[e] += n;
  }
  get() {
    return d$e(this.counters_);
  }
}
const S1 = {}, I1 = {};
function _H(t) {
  const e = t.toString();
  return S1[e] || (S1[e] = new s5e()), S1[e];
}
function i5e(t, e) {
  const n = t.toString();
  return I1[n] || (I1[n] = e()), I1[n];
}
class o5e {
  /**
   * @param onMessage_
   */
  constructor(e) {
    this.onMessage_ = e, this.pendingResponses = [], this.currentResponseNum = 0, this.closeAfterResponse = -1, this.onClose = null;
  }
  closeAfter(e, n) {
    this.closeAfterResponse = e, this.onClose = n, this.closeAfterResponse < this.currentResponseNum && (this.onClose(), this.onClose = null);
  }
  /**
   * Each message from the server comes with a response number, and an array of data. The responseNumber
   * allows us to ensure that we process them in the right order, since we can't be guaranteed that all
   * browsers will respond in the same order as the requests we sent
   */
  handleResponse(e, n) {
    for (this.pendingResponses[e] = n; this.pendingResponses[this.currentResponseNum]; ) {
      const r = this.pendingResponses[this.currentResponseNum];
      delete this.pendingResponses[this.currentResponseNum];
      for (let s = 0; s < r.length; ++s)
        r[s] && wg(() => {
          this.onMessage_(r[s]);
        });
      if (this.currentResponseNum === this.closeAfterResponse) {
        this.onClose && (this.onClose(), this.onClose = null);
        break;
      }
      this.currentResponseNum++;
    }
  }
}
const z5 = "start", a5e = "close", c5e = "pLPCommand", l5e = "pRTLPCB", Joe = "id", Qoe = "pw", Zoe = "ser", u5e = "cb", h5e = "seg", d5e = "ts", f5e = "d", p5e = "dframe", Xoe = 1870, eae = 30, _5e = Xoe - eae, m5e = 25e3, g5e = 3e4;
class c_ {
  /**
   * @param connId An identifier for this connection, used for logging
   * @param repoInfo The info for the endpoint to send data to.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The AppCheck token for this client.
   * @param authToken The AuthToken to use for this connection.
   * @param transportSessionId Optional transportSessionid if we are
   * reconnecting for an existing transport session
   * @param lastSessionId Optional lastSessionId if the PersistentConnection has
   * already created a connection previously
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.repoInfo = n, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.transportSessionId = o, this.lastSessionId = a, this.bytesSent = 0, this.bytesReceived = 0, this.everConnected_ = !1, this.log_ = Lb(e), this.stats_ = _H(n), this.urlFn = (c) => (this.appCheckToken && (c[aF] = this.appCheckToken), Koe(n, Goe, c));
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.curSegmentNum = 0, this.onDisconnect_ = n, this.myPacketOrderer = new o5e(e), this.isClosed_ = !1, this.connectTimeoutTimer_ = setTimeout(() => {
      this.log_("Timed out trying to connect."), this.onClosed_(), this.connectTimeoutTimer_ = null;
    }, Math.floor(g5e)), zKe(() => {
      if (this.isClosed_)
        return;
      this.scriptTagHolder = new mH((...i) => {
        const [o, a, c, l, h] = i;
        if (this.incrementIncomingBytes_(i), !!this.scriptTagHolder)
          if (this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null), this.everConnected_ = !0, o === z5)
            this.id = a, this.password = c;
          else if (o === a5e)
            a ? (this.scriptTagHolder.sendNewPolls = !1, this.myPacketOrderer.closeAfter(a, () => {
              this.onClosed_();
            })) : this.onClosed_();
          else
            throw new Error("Unrecognized command received: " + o);
      }, (...i) => {
        const [o, a] = i;
        this.incrementIncomingBytes_(i), this.myPacketOrderer.handleResponse(o, a);
      }, () => {
        this.onClosed_();
      }, this.urlFn);
      const r = {};
      r[z5] = "t", r[Zoe] = Math.floor(Math.random() * 1e8), this.scriptTagHolder.uniqueCallbackIdentifier && (r[u5e] = this.scriptTagHolder.uniqueCallbackIdentifier), r[Yoe] = pH, this.transportSessionId && (r[Woe] = this.transportSessionId), this.lastSessionId && (r[joe] = this.lastSessionId), this.applicationId && (r[Voe] = this.applicationId), this.appCheckToken && (r[aF] = this.appCheckToken), typeof location < "u" && location.hostname && Hoe.test(location.hostname) && (r[Boe] = qoe);
      const s = this.urlFn(r);
      this.log_("Connecting via long-poll to " + s), this.scriptTagHolder.addTag(s, () => {
      });
    });
  }
  /**
   * Call this when a handshake has completed successfully and we want to consider the connection established
   */
  start() {
    this.scriptTagHolder.startLongPoll(this.id, this.password), this.addDisconnectPingFrame(this.id, this.password);
  }
  /**
   * Forces long polling to be considered as a potential transport
   */
  static forceAllow() {
    c_.forceAllow_ = !0;
  }
  /**
   * Forces longpolling to not be considered as a potential transport
   */
  static forceDisallow() {
    c_.forceDisallow_ = !0;
  }
  // Static method, use string literal so it can be accessed in a generic way
  static isAvailable() {
    return c_.forceAllow_ ? !0 : !c_.forceDisallow_ && typeof document < "u" && document.createElement != null && !$Ke() && !KKe();
  }
  /**
   * No-op for polling
   */
  markConnectionHealthy() {
  }
  /**
   * Stops polling and cleans up the iframe
   */
  shutdown_() {
    this.isClosed_ = !0, this.scriptTagHolder && (this.scriptTagHolder.close(), this.scriptTagHolder = null), this.myDisconnFrame && (document.body.removeChild(this.myDisconnFrame), this.myDisconnFrame = null), this.connectTimeoutTimer_ && (clearTimeout(this.connectTimeoutTimer_), this.connectTimeoutTimer_ = null);
  }
  /**
   * Triggered when this transport is closed
   */
  onClosed_() {
    this.isClosed_ || (this.log_("Longpoll is closing itself"), this.shutdown_(), this.onDisconnect_ && (this.onDisconnect_(this.everConnected_), this.onDisconnect_ = null));
  }
  /**
   * External-facing close handler. RealTime has requested we shut down. Kill our connection and tell the server
   * that we've left.
   */
  close() {
    this.isClosed_ || (this.log_("Longpoll is being closed."), this.shutdown_());
  }
  /**
   * Send the JSON object down to the server. It will need to be stringified, base64 encoded, and then
   * broken into chunks (since URLs have a small maximum length).
   * @param data - The JSON data to transmit.
   */
  send(e) {
    const n = yr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = yoe(n), s = Uoe(r, _5e);
    for (let i = 0; i < s.length; i++)
      this.scriptTagHolder.enqueueSegment(this.curSegmentNum, s.length, s[i]), this.curSegmentNum++;
  }
  /**
   * This is how we notify the server that we're leaving.
   * We aren't able to send requests with DHTML on a window close event, but we can
   * trigger XHR requests in some browsers (everything but Opera basically).
   */
  addDisconnectPingFrame(e, n) {
    this.myDisconnFrame = document.createElement("iframe");
    const r = {};
    r[p5e] = "t", r[Joe] = e, r[Qoe] = n, this.myDisconnFrame.src = this.urlFn(r), this.myDisconnFrame.style.display = "none", document.body.appendChild(this.myDisconnFrame);
  }
  /**
   * Used to track the bytes received by this client
   */
  incrementIncomingBytes_(e) {
    const n = yr(e).length;
    this.bytesReceived += n, this.stats_.incrementCounter("bytes_received", n);
  }
}
class mH {
  /**
   * @param commandCB - The callback to be called when control commands are recevied from the server.
   * @param onMessageCB - The callback to be triggered when responses arrive from the server.
   * @param onDisconnect - The callback to be triggered when this tag holder is closed
   * @param urlFn - A function that provides the URL of the endpoint to send data to.
   */
  constructor(e, n, r, s) {
    this.onDisconnect = r, this.urlFn = s, this.outstandingRequests = /* @__PURE__ */ new Set(), this.pendingSegs = [], this.currentSerial = Math.floor(Math.random() * 1e8), this.sendNewPolls = !0;
    {
      this.uniqueCallbackIdentifier = HKe(), window[c5e + this.uniqueCallbackIdentifier] = e, window[l5e + this.uniqueCallbackIdentifier] = n, this.myIFrame = mH.createIFrame_();
      let i = "";
      this.myIFrame.src && this.myIFrame.src.substr(0, 11) === "javascript:" && (i = '<script>document.domain="' + document.domain + '";<\/script>');
      const o = "<html><body>" + i + "</body></html>";
      try {
        this.myIFrame.doc.open(), this.myIFrame.doc.write(o), this.myIFrame.doc.close();
      } catch (a) {
        Zr("frame writing exception"), a.stack && Zr(a.stack), Zr(a);
      }
    }
  }
  /**
   * Each browser has its own funny way to handle iframes. Here we mush them all together into one object that I can
   * actually use.
   */
  static createIFrame_() {
    const e = document.createElement("iframe");
    if (e.style.display = "none", document.body) {
      document.body.appendChild(e);
      try {
        e.contentWindow.document || Zr("No IE domain setting required");
      } catch {
        const n = document.domain;
        e.src = "javascript:void((function(){document.open();document.domain='" + n + "';document.close();})())";
      }
    } else
      throw "Document body has not initialized. Wait to initialize Firebase until after the document is ready.";
    return e.contentDocument ? e.doc = e.contentDocument : e.contentWindow ? e.doc = e.contentWindow.document : e.document && (e.doc = e.document), e;
  }
  /**
   * Cancel all outstanding queries and remove the frame.
   */
  close() {
    this.alive = !1, this.myIFrame && (this.myIFrame.doc.body.textContent = "", setTimeout(() => {
      this.myIFrame !== null && (document.body.removeChild(this.myIFrame), this.myIFrame = null);
    }, Math.floor(0)));
    const e = this.onDisconnect;
    e && (this.onDisconnect = null, e());
  }
  /**
   * Actually start the long-polling session by adding the first script tag(s) to the iframe.
   * @param id - The ID of this connection
   * @param pw - The password for this connection
   */
  startLongPoll(e, n) {
    for (this.myID = e, this.myPW = n, this.alive = !0; this.newRequest_(); )
      ;
  }
  /**
   * This is called any time someone might want a script tag to be added. It adds a script tag when there aren't
   * too many outstanding requests and we are still alive.
   *
   * If there are outstanding packet segments to send, it sends one. If there aren't, it sends a long-poll anyways if
   * needed.
   */
  newRequest_() {
    if (this.alive && this.sendNewPolls && this.outstandingRequests.size < (this.pendingSegs.length > 0 ? 2 : 1)) {
      this.currentSerial++;
      const e = {};
      e[Joe] = this.myID, e[Qoe] = this.myPW, e[Zoe] = this.currentSerial;
      let n = this.urlFn(e), r = "", s = 0;
      for (; this.pendingSegs.length > 0 && this.pendingSegs[0].d.length + eae + r.length <= Xoe; ) {
        const i = this.pendingSegs.shift();
        r = r + "&" + h5e + s + "=" + i.seg + "&" + d5e + s + "=" + i.ts + "&" + f5e + s + "=" + i.d, s++;
      }
      return n = n + r, this.addLongPollTag_(n, this.currentSerial), !0;
    } else
      return !1;
  }
  /**
   * Queue a packet for transmission to the server.
   * @param segnum - A sequential id for this packet segment used for reassembly
   * @param totalsegs - The total number of segments in this packet
   * @param data - The data for this segment.
   */
  enqueueSegment(e, n, r) {
    this.pendingSegs.push({ seg: e, ts: n, d: r }), this.alive && this.newRequest_();
  }
  /**
   * Add a script tag for a regular long-poll request.
   * @param url - The URL of the script tag.
   * @param serial - The serial number of the request.
   */
  addLongPollTag_(e, n) {
    this.outstandingRequests.add(n);
    const r = () => {
      this.outstandingRequests.delete(n), this.newRequest_();
    }, s = setTimeout(r, Math.floor(m5e)), i = () => {
      clearTimeout(s), r();
    };
    this.addTag(e, i);
  }
  /**
   * Add an arbitrary script tag to the iframe.
   * @param url - The URL for the script tag source.
   * @param loadCB - A callback to be triggered once the script has loaded.
   */
  addTag(e, n) {
    setTimeout(() => {
      try {
        if (!this.sendNewPolls)
          return;
        const r = this.myIFrame.doc.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = e, r.onload = r.onreadystatechange = function() {
          const s = r.readyState;
          (!s || s === "loaded" || s === "complete") && (r.onload = r.onreadystatechange = null, r.parentNode && r.parentNode.removeChild(r), n());
        }, r.onerror = () => {
          Zr("Long-poll script failed to load: " + e), this.sendNewPolls = !1, this.close();
        }, this.myIFrame.doc.body.appendChild(r);
      } catch {
      }
    }, Math.floor(1));
  }
}
const y5e = 16384, v5e = 45e3;
let dk = null;
typeof MozWebSocket < "u" ? dk = MozWebSocket : typeof WebSocket < "u" && (dk = WebSocket);
class Ro {
  /**
   * @param connId identifier for this transport
   * @param repoInfo The info for the websocket endpoint.
   * @param applicationId The Firebase App ID for this project.
   * @param appCheckToken The App Check Token for this client.
   * @param authToken The Auth Token for this client.
   * @param transportSessionId Optional transportSessionId if this is connecting
   * to an existing transport session
   * @param lastSessionId Optional lastSessionId if there was a previous
   * connection
   */
  constructor(e, n, r, s, i, o, a) {
    this.connId = e, this.applicationId = r, this.appCheckToken = s, this.authToken = i, this.keepaliveTimer = null, this.frames = null, this.totalFrames = 0, this.bytesSent = 0, this.bytesReceived = 0, this.log_ = Lb(this.connId), this.stats_ = _H(n), this.connURL = Ro.connectionURL_(n, o, a, s, r), this.nodeAdmin = n.nodeAdmin;
  }
  /**
   * @param repoInfo - The info for the websocket endpoint.
   * @param transportSessionId - Optional transportSessionId if this is connecting to an existing transport
   *                                         session
   * @param lastSessionId - Optional lastSessionId if there was a previous connection
   * @returns connection url
   */
  static connectionURL_(e, n, r, s, i) {
    const o = {};
    return o[Yoe] = pH, typeof location < "u" && location.hostname && Hoe.test(location.hostname) && (o[Boe] = qoe), n && (o[Woe] = n), r && (o[joe] = r), s && (o[aF] = s), i && (o[Voe] = i), Koe(e, zoe, o);
  }
  /**
   * @param onMessage - Callback when messages arrive
   * @param onDisconnect - Callback with connection lost.
   */
  open(e, n) {
    this.onDisconnect = n, this.onMessage = e, this.log_("Websocket connecting to " + this.connURL), this.everConnected_ = !1, Yd.set("previous_websocket_failure", !0);
    try {
      let r;
      boe(), this.mySock = new dk(this.connURL, [], r);
    } catch (r) {
      this.log_("Error instantiating WebSocket.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
      return;
    }
    this.mySock.onopen = () => {
      this.log_("Websocket connected."), this.everConnected_ = !0;
    }, this.mySock.onclose = () => {
      this.log_("Websocket connection was disconnected."), this.mySock = null, this.onClosed_();
    }, this.mySock.onmessage = (r) => {
      this.handleIncomingFrame(r);
    }, this.mySock.onerror = (r) => {
      this.log_("WebSocket error.  Closing connection.");
      const s = r.message || r.data;
      s && this.log_(s), this.onClosed_();
    };
  }
  /**
   * No-op for websockets, we don't need to do anything once the connection is confirmed as open
   */
  start() {
  }
  static forceDisallow() {
    Ro.forceDisallow_ = !0;
  }
  static isAvailable() {
    let e = !1;
    if (typeof navigator < "u" && navigator.userAgent) {
      const n = /Android ([0-9]{0,}\.[0-9]{0,})/, r = navigator.userAgent.match(n);
      r && r.length > 1 && parseFloat(r[1]) < 4.4 && (e = !0);
    }
    return !e && dk !== null && !Ro.forceDisallow_;
  }
  /**
   * Returns true if we previously failed to connect with this transport.
   */
  static previouslyFailed() {
    return Yd.isInMemoryStorage || Yd.get("previous_websocket_failure") === !0;
  }
  markConnectionHealthy() {
    Yd.remove("previous_websocket_failure");
  }
  appendFrame_(e) {
    if (this.frames.push(e), this.frames.length === this.totalFrames) {
      const n = this.frames.join("");
      this.frames = null;
      const r = MC(n);
      this.onMessage(r);
    }
  }
  /**
   * @param frameCount - The number of frames we are expecting from the server
   */
  handleNewFrameCount_(e) {
    this.totalFrames = e, this.frames = [];
  }
  /**
   * Attempts to parse a frame count out of some text. If it can't, assumes a value of 1
   * @returns Any remaining data to be process, or null if there is none
   */
  extractFrameCount_(e) {
    if (A(this.frames === null, "We already have a frame buffer"), e.length <= 6) {
      const n = Number(e);
      if (!isNaN(n))
        return this.handleNewFrameCount_(n), null;
    }
    return this.handleNewFrameCount_(1), e;
  }
  /**
   * Process a websocket frame that has arrived from the server.
   * @param mess - The frame data
   */
  handleIncomingFrame(e) {
    if (this.mySock === null)
      return;
    const n = e.data;
    if (this.bytesReceived += n.length, this.stats_.incrementCounter("bytes_received", n.length), this.resetKeepAlive(), this.frames !== null)
      this.appendFrame_(n);
    else {
      const r = this.extractFrameCount_(n);
      r !== null && this.appendFrame_(r);
    }
  }
  /**
   * Send a message to the server
   * @param data - The JSON object to transmit
   */
  send(e) {
    this.resetKeepAlive();
    const n = yr(e);
    this.bytesSent += n.length, this.stats_.incrementCounter("bytes_sent", n.length);
    const r = Uoe(n, y5e);
    r.length > 1 && this.sendString_(String(r.length));
    for (let s = 0; s < r.length; s++)
      this.sendString_(r[s]);
  }
  shutdown_() {
    this.isClosed_ = !0, this.keepaliveTimer && (clearInterval(this.keepaliveTimer), this.keepaliveTimer = null), this.mySock && (this.mySock.close(), this.mySock = null);
  }
  onClosed_() {
    this.isClosed_ || (this.log_("WebSocket is closing itself"), this.shutdown_(), this.onDisconnect && (this.onDisconnect(this.everConnected_), this.onDisconnect = null));
  }
  /**
   * External-facing close handler.
   * Close the websocket and kill the connection.
   */
  close() {
    this.isClosed_ || (this.log_("WebSocket is being closed"), this.shutdown_());
  }
  /**
   * Kill the current keepalive timer and start a new one, to ensure that it always fires N seconds after
   * the last activity.
   */
  resetKeepAlive() {
    clearInterval(this.keepaliveTimer), this.keepaliveTimer = setInterval(() => {
      this.mySock && this.sendString_("0"), this.resetKeepAlive();
    }, Math.floor(v5e));
  }
  /**
   * Send a string over the websocket.
   *
   * @param str - String to send.
   */
  sendString_(e) {
    try {
      this.mySock.send(e);
    } catch (n) {
      this.log_("Exception thrown from WebSocket.send():", n.message || n.data, "Closing connection."), setTimeout(this.onClosed_.bind(this), 0);
    }
  }
}
Ro.responsesRequiredToBeHealthy = 2;
Ro.healthyTimeout = 3e4;
class UC {
  /**
   * @param repoInfo - Metadata around the namespace we're connecting to
   */
  constructor(e) {
    this.initTransports_(e);
  }
  static get ALL_TRANSPORTS() {
    return [c_, Ro];
  }
  /**
   * Returns whether transport has been selected to ensure WebSocketConnection or BrowserPollConnection are not called after
   * TransportManager has already set up transports_
   */
  static get IS_TRANSPORT_INITIALIZED() {
    return this.globalTransportInitialized_;
  }
  initTransports_(e) {
    const n = Ro && Ro.isAvailable();
    let r = n && !Ro.previouslyFailed();
    if (e.webSocketOnly && (n || Ys("wss:// URL used, but browser isn't known to support websockets.  Trying anyway."), r = !0), r)
      this.transports_ = [Ro];
    else {
      const s = this.transports_ = [];
      for (const i of UC.ALL_TRANSPORTS)
        i && i.isAvailable() && s.push(i);
      UC.globalTransportInitialized_ = !0;
    }
  }
  /**
   * @returns The constructor for the initial transport to use
   */
  initialTransport() {
    if (this.transports_.length > 0)
      return this.transports_[0];
    throw new Error("No transports available");
  }
  /**
   * @returns The constructor for the next transport, or null
   */
  upgradeTransport() {
    return this.transports_.length > 1 ? this.transports_[1] : null;
  }
}
UC.globalTransportInitialized_ = !1;
const w5e = 6e4, C5e = 5e3, T5e = 10 * 1024, b5e = 100 * 1024, R1 = "t", G5 = "d", E5e = "s", $5 = "r", S5e = "e", K5 = "o", J5 = "a", Q5 = "n", Z5 = "p", I5e = "h";
class R5e {
  /**
   * @param id - an id for this connection
   * @param repoInfo_ - the info for the endpoint to connect to
   * @param applicationId_ - the Firebase App ID for this project
   * @param appCheckToken_ - The App Check Token for this device.
   * @param authToken_ - The auth token for this session.
   * @param onMessage_ - the callback to be triggered when a server-push message arrives
   * @param onReady_ - the callback to be triggered when this connection is ready to send messages.
   * @param onDisconnect_ - the callback to be triggered when a connection was lost
   * @param onKill_ - the callback to be triggered when this connection has permanently shut down.
   * @param lastSessionId - last session id in persistent connection. is used to clean up old session in real-time server
   */
  constructor(e, n, r, s, i, o, a, c, l, h) {
    this.id = e, this.repoInfo_ = n, this.applicationId_ = r, this.appCheckToken_ = s, this.authToken_ = i, this.onMessage_ = o, this.onReady_ = a, this.onDisconnect_ = c, this.onKill_ = l, this.lastSessionId = h, this.connectionCount = 0, this.pendingDataMessages = [], this.state_ = 0, this.log_ = Lb("c:" + this.id + ":"), this.transportManager_ = new UC(n), this.log_("Connection created"), this.start_();
  }
  /**
   * Starts a connection attempt
   */
  start_() {
    const e = this.transportManager_.initialTransport();
    this.conn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, null, this.lastSessionId), this.primaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.conn_), r = this.disconnReceiver_(this.conn_);
    this.tx_ = this.conn_, this.rx_ = this.conn_, this.secondaryConn_ = null, this.isHealthy_ = !1, setTimeout(() => {
      this.conn_ && this.conn_.open(n, r);
    }, Math.floor(0));
    const s = e.healthyTimeout || 0;
    s > 0 && (this.healthyTimeout_ = hw(() => {
      this.healthyTimeout_ = null, this.isHealthy_ || (this.conn_ && this.conn_.bytesReceived > b5e ? (this.log_("Connection exceeded healthy timeout but has received " + this.conn_.bytesReceived + " bytes.  Marking connection healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()) : this.conn_ && this.conn_.bytesSent > T5e ? this.log_("Connection exceeded healthy timeout but has sent " + this.conn_.bytesSent + " bytes.  Leaving connection alive.") : (this.log_("Closing unhealthy connection after timeout."), this.close()));
    }, Math.floor(s)));
  }
  nextTransportId_() {
    return "c:" + this.id + ":" + this.connectionCount++;
  }
  disconnReceiver_(e) {
    return (n) => {
      e === this.conn_ ? this.onConnectionLost_(n) : e === this.secondaryConn_ ? (this.log_("Secondary connection lost."), this.onSecondaryConnectionLost_()) : this.log_("closing an old connection");
    };
  }
  connReceiver_(e) {
    return (n) => {
      this.state_ !== 2 && (e === this.rx_ ? this.onPrimaryMessageReceived_(n) : e === this.secondaryConn_ ? this.onSecondaryMessageReceived_(n) : this.log_("message on old connection"));
    };
  }
  /**
   * @param dataMsg - An arbitrary data message to be sent to the server
   */
  sendRequest(e) {
    const n = { t: "d", d: e };
    this.sendData_(n);
  }
  tryCleanupConnection() {
    this.tx_ === this.secondaryConn_ && this.rx_ === this.secondaryConn_ && (this.log_("cleaning up and promoting a connection: " + this.secondaryConn_.connId), this.conn_ = this.secondaryConn_, this.secondaryConn_ = null);
  }
  onSecondaryControl_(e) {
    if (R1 in e) {
      const n = e[R1];
      n === J5 ? this.upgradeIfSecondaryHealthy_() : n === $5 ? (this.log_("Got a reset on secondary, closing it"), this.secondaryConn_.close(), (this.tx_ === this.secondaryConn_ || this.rx_ === this.secondaryConn_) && this.close()) : n === K5 && (this.log_("got pong on secondary."), this.secondaryResponsesRequired_--, this.upgradeIfSecondaryHealthy_());
    }
  }
  onSecondaryMessageReceived_(e) {
    const n = Vy("t", e), r = Vy("d", e);
    if (n === "c")
      this.onSecondaryControl_(r);
    else if (n === "d")
      this.pendingDataMessages.push(r);
    else
      throw new Error("Unknown protocol layer: " + n);
  }
  upgradeIfSecondaryHealthy_() {
    this.secondaryResponsesRequired_ <= 0 ? (this.log_("Secondary connection is healthy."), this.isHealthy_ = !0, this.secondaryConn_.markConnectionHealthy(), this.proceedWithUpgrade_()) : (this.log_("sending ping on secondary."), this.secondaryConn_.send({ t: "c", d: { t: Z5, d: {} } }));
  }
  proceedWithUpgrade_() {
    this.secondaryConn_.start(), this.log_("sending client ack on secondary"), this.secondaryConn_.send({ t: "c", d: { t: J5, d: {} } }), this.log_("Ending transmission on primary"), this.conn_.send({ t: "c", d: { t: Q5, d: {} } }), this.tx_ = this.secondaryConn_, this.tryCleanupConnection();
  }
  onPrimaryMessageReceived_(e) {
    const n = Vy("t", e), r = Vy("d", e);
    n === "c" ? this.onControl_(r) : n === "d" && this.onDataMessage_(r);
  }
  onDataMessage_(e) {
    this.onPrimaryResponse_(), this.onMessage_(e);
  }
  onPrimaryResponse_() {
    this.isHealthy_ || (this.primaryResponsesRequired_--, this.primaryResponsesRequired_ <= 0 && (this.log_("Primary connection is healthy."), this.isHealthy_ = !0, this.conn_.markConnectionHealthy()));
  }
  onControl_(e) {
    const n = Vy(R1, e);
    if (G5 in e) {
      const r = e[G5];
      if (n === I5e) {
        const s = Object.assign({}, r);
        this.repoInfo_.isUsingEmulator && (s.h = this.repoInfo_.host), this.onHandshake_(s);
      } else if (n === Q5) {
        this.log_("recvd end transmission on primary"), this.rx_ = this.secondaryConn_;
        for (let s = 0; s < this.pendingDataMessages.length; ++s)
          this.onDataMessage_(this.pendingDataMessages[s]);
        this.pendingDataMessages = [], this.tryCleanupConnection();
      } else n === E5e ? this.onConnectionShutdown_(r) : n === $5 ? this.onReset_(r) : n === S5e ? oF("Server Error: " + r) : n === K5 ? (this.log_("got pong on primary."), this.onPrimaryResponse_(), this.sendPingOnPrimaryIfNecessary_()) : oF("Unknown control packet command: " + n);
    }
  }
  /**
   * @param handshake - The handshake data returned from the server
   */
  onHandshake_(e) {
    const n = e.ts, r = e.v, s = e.h;
    this.sessionId = e.s, this.repoInfo_.host = s, this.state_ === 0 && (this.conn_.start(), this.onConnectionEstablished_(this.conn_, n), pH !== r && Ys("Protocol version mismatch detected"), this.tryStartUpgrade_());
  }
  tryStartUpgrade_() {
    const e = this.transportManager_.upgradeTransport();
    e && this.startUpgrade_(e);
  }
  startUpgrade_(e) {
    this.secondaryConn_ = new e(this.nextTransportId_(), this.repoInfo_, this.applicationId_, this.appCheckToken_, this.authToken_, this.sessionId), this.secondaryResponsesRequired_ = e.responsesRequiredToBeHealthy || 0;
    const n = this.connReceiver_(this.secondaryConn_), r = this.disconnReceiver_(this.secondaryConn_);
    this.secondaryConn_.open(n, r), hw(() => {
      this.secondaryConn_ && (this.log_("Timed out trying to upgrade."), this.secondaryConn_.close());
    }, Math.floor(w5e));
  }
  onReset_(e) {
    this.log_("Reset packet received.  New host: " + e), this.repoInfo_.host = e, this.state_ === 1 ? this.close() : (this.closeConnections_(), this.start_());
  }
  onConnectionEstablished_(e, n) {
    this.log_("Realtime connection established."), this.conn_ = e, this.state_ = 1, this.onReady_ && (this.onReady_(n, this.sessionId), this.onReady_ = null), this.primaryResponsesRequired_ === 0 ? (this.log_("Primary connection is healthy."), this.isHealthy_ = !0) : hw(() => {
      this.sendPingOnPrimaryIfNecessary_();
    }, Math.floor(C5e));
  }
  sendPingOnPrimaryIfNecessary_() {
    !this.isHealthy_ && this.state_ === 1 && (this.log_("sending ping on primary."), this.sendData_({ t: "c", d: { t: Z5, d: {} } }));
  }
  onSecondaryConnectionLost_() {
    const e = this.secondaryConn_;
    this.secondaryConn_ = null, (this.tx_ === e || this.rx_ === e) && this.close();
  }
  /**
   * @param everConnected - Whether or not the connection ever reached a server. Used to determine if
   * we should flush the host cache
   */
  onConnectionLost_(e) {
    this.conn_ = null, !e && this.state_ === 0 ? (this.log_("Realtime connection failed."), this.repoInfo_.isCacheableHost() && (Yd.remove("host:" + this.repoInfo_.host), this.repoInfo_.internalHost = this.repoInfo_.host)) : this.state_ === 1 && this.log_("Realtime connection lost."), this.close();
  }
  onConnectionShutdown_(e) {
    this.log_("Connection shutdown command received. Shutting down..."), this.onKill_ && (this.onKill_(e), this.onKill_ = null), this.onDisconnect_ = null, this.close();
  }
  sendData_(e) {
    if (this.state_ !== 1)
      throw "Connection is not connected";
    this.tx_.send(e);
  }
  /**
   * Cleans up this connection, calling the appropriate callbacks
   */
  close() {
    this.state_ !== 2 && (this.log_("Closing realtime connection."), this.state_ = 2, this.closeConnections_(), this.onDisconnect_ && (this.onDisconnect_(), this.onDisconnect_ = null));
  }
  closeConnections_() {
    this.log_("Shutting down all connections"), this.conn_ && (this.conn_.close(), this.conn_ = null), this.secondaryConn_ && (this.secondaryConn_.close(), this.secondaryConn_ = null), this.healthyTimeout_ && (clearTimeout(this.healthyTimeout_), this.healthyTimeout_ = null);
  }
}
class tae {
  put(e, n, r, s) {
  }
  merge(e, n, r, s) {
  }
  /**
   * Refreshes the auth token for the current connection.
   * @param token - The authentication token
   */
  refreshAuthToken(e) {
  }
  /**
   * Refreshes the app check token for the current connection.
   * @param token The app check token
   */
  refreshAppCheckToken(e) {
  }
  onDisconnectPut(e, n, r) {
  }
  onDisconnectMerge(e, n, r) {
  }
  onDisconnectCancel(e, n) {
  }
  reportStats(e) {
  }
}
class nae {
  constructor(e) {
    this.allowedEvents_ = e, this.listeners_ = {}, A(Array.isArray(e) && e.length > 0, "Requires a non-empty array");
  }
  /**
   * To be called by derived classes to trigger events.
   */
  trigger(e, ...n) {
    if (Array.isArray(this.listeners_[e])) {
      const r = [...this.listeners_[e]];
      for (let s = 0; s < r.length; s++)
        r[s].callback.apply(r[s].context, n);
    }
  }
  on(e, n, r) {
    this.validateEventType_(e), this.listeners_[e] = this.listeners_[e] || [], this.listeners_[e].push({ callback: n, context: r });
    const s = this.getInitialEvent(e);
    s && n.apply(r, s);
  }
  off(e, n, r) {
    this.validateEventType_(e);
    const s = this.listeners_[e] || [];
    for (let i = 0; i < s.length; i++)
      if (s[i].callback === n && (!r || r === s[i].context)) {
        s.splice(i, 1);
        return;
      }
  }
  validateEventType_(e) {
    A(this.allowedEvents_.find((n) => n === e), "Unknown event: " + e);
  }
}
class fk extends nae {
  constructor() {
    super(["online"]), this.online_ = !0, typeof window < "u" && typeof window.addEventListener < "u" && !Toe() && (window.addEventListener("online", () => {
      this.online_ || (this.online_ = !0, this.trigger("online", !0));
    }, !1), window.addEventListener("offline", () => {
      this.online_ && (this.online_ = !1, this.trigger("online", !1));
    }, !1));
  }
  static getInstance() {
    return new fk();
  }
  getInitialEvent(e) {
    return A(e === "online", "Unknown event type: " + e), [this.online_];
  }
  currentlyOnline() {
    return this.online_;
  }
}
const X5 = 32, e9 = 768;
class Ut {
  /**
   * @param pathOrString - Path string to parse, or another path, or the raw
   * tokens array
   */
  constructor(e, n) {
    if (n === void 0) {
      this.pieces_ = e.split("/");
      let r = 0;
      for (let s = 0; s < this.pieces_.length; s++)
        this.pieces_[s].length > 0 && (this.pieces_[r] = this.pieces_[s], r++);
      this.pieces_.length = r, this.pieceNum_ = 0;
    } else
      this.pieces_ = e, this.pieceNum_ = n;
  }
  toString() {
    let e = "";
    for (let n = this.pieceNum_; n < this.pieces_.length; n++)
      this.pieces_[n] !== "" && (e += "/" + this.pieces_[n]);
    return e || "/";
  }
}
function dt() {
  return new Ut("");
}
function Re(t) {
  return t.pieceNum_ >= t.pieces_.length ? null : t.pieces_[t.pieceNum_];
}
function Hh(t) {
  return t.pieces_.length - t.pieceNum_;
}
function sn(t) {
  let e = t.pieceNum_;
  return e < t.pieces_.length && e++, new Ut(t.pieces_, e);
}
function gH(t) {
  return t.pieceNum_ < t.pieces_.length ? t.pieces_[t.pieces_.length - 1] : null;
}
function k5e(t) {
  let e = "";
  for (let n = t.pieceNum_; n < t.pieces_.length; n++)
    t.pieces_[n] !== "" && (e += "/" + encodeURIComponent(String(t.pieces_[n])));
  return e || "/";
}
function FC(t, e = 0) {
  return t.pieces_.slice(t.pieceNum_ + e);
}
function rae(t) {
  if (t.pieceNum_ >= t.pieces_.length)
    return null;
  const e = [];
  for (let n = t.pieceNum_; n < t.pieces_.length - 1; n++)
    e.push(t.pieces_[n]);
  return new Ut(e, 0);
}
function On(t, e) {
  const n = [];
  for (let r = t.pieceNum_; r < t.pieces_.length; r++)
    n.push(t.pieces_[r]);
  if (e instanceof Ut)
    for (let r = e.pieceNum_; r < e.pieces_.length; r++)
      n.push(e.pieces_[r]);
  else {
    const r = e.split("/");
    for (let s = 0; s < r.length; s++)
      r[s].length > 0 && n.push(r[s]);
  }
  return new Ut(n, 0);
}
function Be(t) {
  return t.pieceNum_ >= t.pieces_.length;
}
function Ds(t, e) {
  const n = Re(t), r = Re(e);
  if (n === null)
    return e;
  if (n === r)
    return Ds(sn(t), sn(e));
  throw new Error("INTERNAL ERROR: innerPath (" + e + ") is not within outerPath (" + t + ")");
}
function N5e(t, e) {
  const n = FC(t, 0), r = FC(e, 0);
  for (let s = 0; s < n.length && s < r.length; s++) {
    const i = yp(n[s], r[s]);
    if (i !== 0)
      return i;
  }
  return n.length === r.length ? 0 : n.length < r.length ? -1 : 1;
}
function yH(t, e) {
  if (Hh(t) !== Hh(e))
    return !1;
  for (let n = t.pieceNum_, r = e.pieceNum_; n <= t.pieces_.length; n++, r++)
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
  return !0;
}
function Qi(t, e) {
  let n = t.pieceNum_, r = e.pieceNum_;
  if (Hh(t) > Hh(e))
    return !1;
  for (; n < t.pieces_.length; ) {
    if (t.pieces_[n] !== e.pieces_[r])
      return !1;
    ++n, ++r;
  }
  return !0;
}
class P5e {
  /**
   * @param path - Initial Path.
   * @param errorPrefix_ - Prefix for any error messages.
   */
  constructor(e, n) {
    this.errorPrefix_ = n, this.parts_ = FC(e, 0), this.byteLength_ = Math.max(1, this.parts_.length);
    for (let r = 0; r < this.parts_.length; r++)
      this.byteLength_ += IO(this.parts_[r]);
    sae(this);
  }
}
function O5e(t, e) {
  t.parts_.length > 0 && (t.byteLength_ += 1), t.parts_.push(e), t.byteLength_ += IO(e), sae(t);
}
function D5e(t) {
  const e = t.parts_.pop();
  t.byteLength_ -= IO(e), t.parts_.length > 0 && (t.byteLength_ -= 1);
}
function sae(t) {
  if (t.byteLength_ > e9)
    throw new Error(t.errorPrefix_ + "has a key path longer than " + e9 + " bytes (" + t.byteLength_ + ").");
  if (t.parts_.length > X5)
    throw new Error(t.errorPrefix_ + "path specified exceeds the maximum depth that can be written (" + X5 + ") or object contains a cycle " + Dd(t));
}
function Dd(t) {
  return t.parts_.length === 0 ? "" : "in property '" + t.parts_.join(".") + "'";
}
class vH extends nae {
  constructor() {
    super(["visible"]);
    let e, n;
    typeof document < "u" && typeof document.addEventListener < "u" && (typeof document.hidden < "u" ? (n = "visibilitychange", e = "hidden") : typeof document.mozHidden < "u" ? (n = "mozvisibilitychange", e = "mozHidden") : typeof document.msHidden < "u" ? (n = "msvisibilitychange", e = "msHidden") : typeof document.webkitHidden < "u" && (n = "webkitvisibilitychange", e = "webkitHidden")), this.visible_ = !0, n && document.addEventListener(n, () => {
      const r = !document[e];
      r !== this.visible_ && (this.visible_ = r, this.trigger("visible", r));
    }, !1);
  }
  static getInstance() {
    return new vH();
  }
  getInitialEvent(e) {
    return A(e === "visible", "Unknown event type: " + e), [this.visible_];
  }
}
const zy = 1e3, A5e = 60 * 5 * 1e3, t9 = 30 * 1e3, M5e = 1.3, x5e = 3e4, L5e = "server_kill", n9 = 3;
class rl extends tae {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param applicationId_ - The Firebase App ID for this project
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s, i, o, a, c) {
    if (super(), this.repoInfo_ = e, this.applicationId_ = n, this.onDataUpdate_ = r, this.onConnectStatus_ = s, this.onServerInfoUpdate_ = i, this.authTokenProvider_ = o, this.appCheckTokenProvider_ = a, this.authOverride_ = c, this.id = rl.nextPersistentConnectionId_++, this.log_ = Lb("p:" + this.id + ":"), this.interruptReasons_ = {}, this.listens = /* @__PURE__ */ new Map(), this.outstandingPuts_ = [], this.outstandingGets_ = [], this.outstandingPutCount_ = 0, this.outstandingGetCount_ = 0, this.onDisconnectRequestQueue_ = [], this.connected_ = !1, this.reconnectDelay_ = zy, this.maxReconnectDelay_ = A5e, this.securityDebugCallback_ = null, this.lastSessionId = null, this.establishConnectionTimer_ = null, this.visible_ = !1, this.requestCBHash_ = {}, this.requestNumber_ = 0, this.realtime_ = null, this.authToken_ = null, this.appCheckToken_ = null, this.forceTokenRefresh_ = !1, this.invalidAuthTokenCount_ = 0, this.invalidAppCheckTokenCount_ = 0, this.firstConnection_ = !0, this.lastConnectionAttemptTime_ = null, this.lastConnectionEstablishedTime_ = null, c && !boe())
      throw new Error("Auth override specified in options, but not supported on non Node.js platforms");
    vH.getInstance().on("visible", this.onVisible_, this), e.host.indexOf("fblocal") === -1 && fk.getInstance().on("online", this.onOnline_, this);
  }
  sendRequest(e, n, r) {
    const s = ++this.requestNumber_, i = { r: s, a: e, b: n };
    this.log_(yr(i)), A(this.connected_, "sendRequest call when we're not connected not allowed."), this.realtime_.sendRequest(i), r && (this.requestCBHash_[s] = r);
  }
  get(e) {
    this.initConnection_();
    const n = new Mb(), r = {
      action: "g",
      request: {
        p: e._path.toString(),
        q: e._queryObject
      },
      onComplete: (i) => {
        const o = i.d;
        i.s === "ok" ? n.resolve(o) : n.reject(o);
      }
    };
    this.outstandingGets_.push(r), this.outstandingGetCount_++;
    const s = this.outstandingGets_.length - 1;
    return this.connected_ && this.sendGet_(s), n.promise;
  }
  listen(e, n, r, s) {
    this.initConnection_();
    const i = e._queryIdentifier, o = e._path.toString();
    this.log_("Listen called for " + o + " " + i), this.listens.has(o) || this.listens.set(o, /* @__PURE__ */ new Map()), A(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "listen() called for non-default but complete query"), A(!this.listens.get(o).has(i), "listen() called twice for same path/queryId.");
    const a = {
      onComplete: s,
      hashFn: n,
      query: e,
      tag: r
    };
    this.listens.get(o).set(i, a), this.connected_ && this.sendListen_(a);
  }
  sendGet_(e) {
    const n = this.outstandingGets_[e];
    this.sendRequest("g", n.request, (r) => {
      delete this.outstandingGets_[e], this.outstandingGetCount_--, this.outstandingGetCount_ === 0 && (this.outstandingGets_ = []), n.onComplete && n.onComplete(r);
    });
  }
  sendListen_(e) {
    const n = e.query, r = n._path.toString(), s = n._queryIdentifier;
    this.log_("Listen on " + r + " for " + s);
    const i = {
      /*path*/
      p: r
    }, o = "q";
    e.tag && (i.q = n._queryObject, i.t = e.tag), i.h = e.hashFn(), this.sendRequest(o, i, (a) => {
      const c = a.d, l = a.s;
      rl.warnOnListenWarnings_(c, n), (this.listens.get(r) && this.listens.get(r).get(s)) === e && (this.log_("listen response", a), l !== "ok" && this.removeListen_(r, s), e.onComplete && e.onComplete(l, c));
    });
  }
  static warnOnListenWarnings_(e, n) {
    if (e && typeof e == "object" && Ka(e, "w")) {
      const r = Bm(e, "w");
      if (Array.isArray(r) && ~r.indexOf("no_index")) {
        const s = '".indexOn": "' + n._queryParams.getIndex().toString() + '"', i = n._path.toString();
        Ys(`Using an unspecified index. Your data will be downloaded and filtered on the client. Consider adding ${s} at ${i} to your security rules for better performance.`);
      }
    }
  }
  refreshAuthToken(e) {
    this.authToken_ = e, this.log_("Auth token refreshed"), this.authToken_ ? this.tryAuth() : this.connected_ && this.sendRequest("unauth", {}, () => {
    }), this.reduceReconnectDelayIfAdminCredential_(e);
  }
  reduceReconnectDelayIfAdminCredential_(e) {
    (e && e.length === 40 || N$e(e)) && (this.log_("Admin auth credential detected.  Reducing max reconnect time."), this.maxReconnectDelay_ = t9);
  }
  refreshAppCheckToken(e) {
    this.appCheckToken_ = e, this.log_("App check token refreshed"), this.appCheckToken_ ? this.tryAppCheck() : this.connected_ && this.sendRequest("unappeck", {}, () => {
    });
  }
  /**
   * Attempts to authenticate with the given credentials. If the authentication attempt fails, it's triggered like
   * a auth revoked (the connection is closed).
   */
  tryAuth() {
    if (this.connected_ && this.authToken_) {
      const e = this.authToken_, n = k$e(e) ? "auth" : "gauth", r = { cred: e };
      this.authOverride_ === null ? r.noauth = !0 : typeof this.authOverride_ == "object" && (r.authvar = this.authOverride_), this.sendRequest(n, r, (s) => {
        const i = s.s, o = s.d || "error";
        this.authToken_ === e && (i === "ok" ? this.invalidAuthTokenCount_ = 0 : this.onAuthRevoked_(i, o));
      });
    }
  }
  /**
   * Attempts to authenticate with the given token. If the authentication
   * attempt fails, it's triggered like the token was revoked (the connection is
   * closed).
   */
  tryAppCheck() {
    this.connected_ && this.appCheckToken_ && this.sendRequest("appcheck", { token: this.appCheckToken_ }, (e) => {
      const n = e.s, r = e.d || "error";
      n === "ok" ? this.invalidAppCheckTokenCount_ = 0 : this.onAppCheckRevoked_(n, r);
    });
  }
  /**
   * @inheritDoc
   */
  unlisten(e, n) {
    const r = e._path.toString(), s = e._queryIdentifier;
    this.log_("Unlisten called for " + r + " " + s), A(e._queryParams.isDefault() || !e._queryParams.loadsAllData(), "unlisten() called for non-default but complete query"), this.removeListen_(r, s) && this.connected_ && this.sendUnlisten_(r, s, e._queryObject, n);
  }
  sendUnlisten_(e, n, r, s) {
    this.log_("Unlisten on " + e + " for " + n);
    const i = {
      /*path*/
      p: e
    }, o = "n";
    s && (i.q = r, i.t = s), this.sendRequest(o, i);
  }
  onDisconnectPut(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("o", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "o",
      data: n,
      onComplete: r
    });
  }
  onDisconnectMerge(e, n, r) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("om", e, n, r) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "om",
      data: n,
      onComplete: r
    });
  }
  onDisconnectCancel(e, n) {
    this.initConnection_(), this.connected_ ? this.sendOnDisconnect_("oc", e, null, n) : this.onDisconnectRequestQueue_.push({
      pathString: e,
      action: "oc",
      data: null,
      onComplete: n
    });
  }
  sendOnDisconnect_(e, n, r, s) {
    const i = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    this.log_("onDisconnect " + e, i), this.sendRequest(e, i, (o) => {
      s && setTimeout(() => {
        s(o.s, o.d);
      }, Math.floor(0));
    });
  }
  put(e, n, r, s) {
    this.putInternal("p", e, n, r, s);
  }
  merge(e, n, r, s) {
    this.putInternal("m", e, n, r, s);
  }
  putInternal(e, n, r, s, i) {
    this.initConnection_();
    const o = {
      /*path*/
      p: n,
      /*data*/
      d: r
    };
    i !== void 0 && (o.h = i), this.outstandingPuts_.push({
      action: e,
      request: o,
      onComplete: s
    }), this.outstandingPutCount_++;
    const a = this.outstandingPuts_.length - 1;
    this.connected_ ? this.sendPut_(a) : this.log_("Buffering put: " + n);
  }
  sendPut_(e) {
    const n = this.outstandingPuts_[e].action, r = this.outstandingPuts_[e].request, s = this.outstandingPuts_[e].onComplete;
    this.outstandingPuts_[e].queued = this.connected_, this.sendRequest(n, r, (i) => {
      this.log_(n + " response", i), delete this.outstandingPuts_[e], this.outstandingPutCount_--, this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []), s && s(i.s, i.d);
    });
  }
  reportStats(e) {
    if (this.connected_) {
      const n = {
        /*counters*/
        c: e
      };
      this.log_("reportStats", n), this.sendRequest(
        /*stats*/
        "s",
        n,
        (r) => {
          if (r.s !== "ok") {
            const s = r.d;
            this.log_("reportStats", "Error sending stats: " + s);
          }
        }
      );
    }
  }
  onDataMessage_(e) {
    if ("r" in e) {
      this.log_("from server: " + yr(e));
      const n = e.r, r = this.requestCBHash_[n];
      r && (delete this.requestCBHash_[n], r(e.b));
    } else {
      if ("error" in e)
        throw "A server-side error has occurred: " + e.error;
      "a" in e && this.onDataPush_(e.a, e.b);
    }
  }
  onDataPush_(e, n) {
    this.log_("handleServerMessage", e, n), e === "d" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge*/
      !1,
      n.t
    ) : e === "m" ? this.onDataUpdate_(
      n.p,
      n.d,
      /*isMerge=*/
      !0,
      n.t
    ) : e === "c" ? this.onListenRevoked_(n.p, n.q) : e === "ac" ? this.onAuthRevoked_(n.s, n.d) : e === "apc" ? this.onAppCheckRevoked_(n.s, n.d) : e === "sd" ? this.onSecurityDebugPacket_(n) : oF("Unrecognized action received from server: " + yr(e) + `
Are you using the latest client?`);
  }
  onReady_(e, n) {
    this.log_("connection ready"), this.connected_ = !0, this.lastConnectionEstablishedTime_ = (/* @__PURE__ */ new Date()).getTime(), this.handleTimestamp_(e), this.lastSessionId = n, this.firstConnection_ && this.sendConnectStats_(), this.restoreState_(), this.firstConnection_ = !1, this.onConnectStatus_(!0);
  }
  scheduleConnect_(e) {
    A(!this.realtime_, "Scheduling a connect when we're already connected/ing?"), this.establishConnectionTimer_ && clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = setTimeout(() => {
      this.establishConnectionTimer_ = null, this.establishConnection_();
    }, Math.floor(e));
  }
  initConnection_() {
    !this.realtime_ && this.firstConnection_ && this.scheduleConnect_(0);
  }
  onVisible_(e) {
    e && !this.visible_ && this.reconnectDelay_ === this.maxReconnectDelay_ && (this.log_("Window became visible.  Reducing delay."), this.reconnectDelay_ = zy, this.realtime_ || this.scheduleConnect_(0)), this.visible_ = e;
  }
  onOnline_(e) {
    e ? (this.log_("Browser went online."), this.reconnectDelay_ = zy, this.realtime_ || this.scheduleConnect_(0)) : (this.log_("Browser went offline.  Killing connection."), this.realtime_ && this.realtime_.close());
  }
  onRealtimeDisconnect_() {
    if (this.log_("data client disconnected"), this.connected_ = !1, this.realtime_ = null, this.cancelSentTransactions_(), this.requestCBHash_ = {}, this.shouldReconnect_()) {
      this.visible_ ? this.lastConnectionEstablishedTime_ && ((/* @__PURE__ */ new Date()).getTime() - this.lastConnectionEstablishedTime_ > x5e && (this.reconnectDelay_ = zy), this.lastConnectionEstablishedTime_ = null) : (this.log_("Window isn't visible.  Delaying reconnect."), this.reconnectDelay_ = this.maxReconnectDelay_, this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime());
      const e = (/* @__PURE__ */ new Date()).getTime() - this.lastConnectionAttemptTime_;
      let n = Math.max(0, this.reconnectDelay_ - e);
      n = Math.random() * n, this.log_("Trying to reconnect in " + n + "ms"), this.scheduleConnect_(n), this.reconnectDelay_ = Math.min(this.maxReconnectDelay_, this.reconnectDelay_ * M5e);
    }
    this.onConnectStatus_(!1);
  }
  async establishConnection_() {
    if (this.shouldReconnect_()) {
      this.log_("Making a connection attempt"), this.lastConnectionAttemptTime_ = (/* @__PURE__ */ new Date()).getTime(), this.lastConnectionEstablishedTime_ = null;
      const e = this.onDataMessage_.bind(this), n = this.onReady_.bind(this), r = this.onRealtimeDisconnect_.bind(this), s = this.id + ":" + rl.nextConnectionId_++, i = this.lastSessionId;
      let o = !1, a = null;
      const c = function() {
        a ? a.close() : (o = !0, r());
      }, l = function(u) {
        A(a, "sendRequest call when we're not connected not allowed."), a.sendRequest(u);
      };
      this.realtime_ = {
        close: c,
        sendRequest: l
      };
      const h = this.forceTokenRefresh_;
      this.forceTokenRefresh_ = !1;
      try {
        const [u, d] = await Promise.all([
          this.authTokenProvider_.getToken(h),
          this.appCheckTokenProvider_.getToken(h)
        ]);
        o ? Zr("getToken() completed but was canceled") : (Zr("getToken() completed. Creating connection."), this.authToken_ = u && u.accessToken, this.appCheckToken_ = d && d.token, a = new R5e(
          s,
          this.repoInfo_,
          this.applicationId_,
          this.appCheckToken_,
          this.authToken_,
          e,
          n,
          r,
          /* onKill= */
          (f) => {
            Ys(f + " (" + this.repoInfo_.toString() + ")"), this.interrupt(L5e);
          },
          i
        ));
      } catch (u) {
        this.log_("Failed to get token: " + u), o || (this.repoInfo_.nodeAdmin && Ys(u), c());
      }
    }
  }
  interrupt(e) {
    Zr("Interrupting connection for reason: " + e), this.interruptReasons_[e] = !0, this.realtime_ ? this.realtime_.close() : (this.establishConnectionTimer_ && (clearTimeout(this.establishConnectionTimer_), this.establishConnectionTimer_ = null), this.connected_ && this.onRealtimeDisconnect_());
  }
  resume(e) {
    Zr("Resuming connection for reason: " + e), delete this.interruptReasons_[e], A5(this.interruptReasons_) && (this.reconnectDelay_ = zy, this.realtime_ || this.scheduleConnect_(0));
  }
  handleTimestamp_(e) {
    const n = e - (/* @__PURE__ */ new Date()).getTime();
    this.onServerInfoUpdate_({ serverTimeOffset: n });
  }
  cancelSentTransactions_() {
    for (let e = 0; e < this.outstandingPuts_.length; e++) {
      const n = this.outstandingPuts_[e];
      n && /*hash*/
      "h" in n.request && n.queued && (n.onComplete && n.onComplete("disconnect"), delete this.outstandingPuts_[e], this.outstandingPutCount_--);
    }
    this.outstandingPutCount_ === 0 && (this.outstandingPuts_ = []);
  }
  onListenRevoked_(e, n) {
    let r;
    n ? r = n.map((i) => fH(i)).join("$") : r = "default";
    const s = this.removeListen_(e, r);
    s && s.onComplete && s.onComplete("permission_denied");
  }
  removeListen_(e, n) {
    const r = new Ut(e).toString();
    let s;
    if (this.listens.has(r)) {
      const i = this.listens.get(r);
      s = i.get(n), i.delete(n), i.size === 0 && this.listens.delete(r);
    } else
      s = void 0;
    return s;
  }
  onAuthRevoked_(e, n) {
    Zr("Auth token revoked: " + e + "/" + n), this.authToken_ = null, this.forceTokenRefresh_ = !0, this.realtime_.close(), (e === "invalid_token" || e === "permission_denied") && (this.invalidAuthTokenCount_++, this.invalidAuthTokenCount_ >= n9 && (this.reconnectDelay_ = t9, this.authTokenProvider_.notifyForInvalidToken()));
  }
  onAppCheckRevoked_(e, n) {
    Zr("App check token revoked: " + e + "/" + n), this.appCheckToken_ = null, this.forceTokenRefresh_ = !0, (e === "invalid_token" || e === "permission_denied") && (this.invalidAppCheckTokenCount_++, this.invalidAppCheckTokenCount_ >= n9 && this.appCheckTokenProvider_.notifyForInvalidToken());
  }
  onSecurityDebugPacket_(e) {
    this.securityDebugCallback_ ? this.securityDebugCallback_(e) : "msg" in e && console.log("FIREBASE: " + e.msg.replace(`
`, `
FIREBASE: `));
  }
  restoreState_() {
    this.tryAuth(), this.tryAppCheck();
    for (const e of this.listens.values())
      for (const n of e.values())
        this.sendListen_(n);
    for (let e = 0; e < this.outstandingPuts_.length; e++)
      this.outstandingPuts_[e] && this.sendPut_(e);
    for (; this.onDisconnectRequestQueue_.length; ) {
      const e = this.onDisconnectRequestQueue_.shift();
      this.sendOnDisconnect_(e.action, e.pathString, e.data, e.onComplete);
    }
    for (let e = 0; e < this.outstandingGets_.length; e++)
      this.outstandingGets_[e] && this.sendGet_(e);
  }
  /**
   * Sends client stats for first connection
   */
  sendConnectStats_() {
    const e = {};
    let n = "js";
    e["sdk." + n + "." + Moe.replace(/\./g, "-")] = 1, Toe() ? e["framework.cordova"] = 1 : T$e() && (e["framework.reactnative"] = 1), this.reportStats(e);
  }
  shouldReconnect_() {
    const e = fk.getInstance().currentlyOnline();
    return A5(this.interruptReasons_) && e;
  }
}
rl.nextPersistentConnectionId_ = 0;
rl.nextConnectionId_ = 0;
class Me {
  constructor(e, n) {
    this.name = e, this.node = n;
  }
  static Wrap(e, n) {
    return new Me(e, n);
  }
}
class RO {
  /**
   * @returns A standalone comparison function for
   * this index
   */
  getCompare() {
    return this.compare.bind(this);
  }
  /**
   * Given a before and after value for a node, determine if the indexed value has changed. Even if they are different,
   * it's possible that the changes are isolated to parts of the snapshot that are not indexed.
   *
   *
   * @returns True if the portion of the snapshot being indexed changed between oldNode and newNode
   */
  indexedValueChanged(e, n) {
    const r = new Me(Hm, e), s = new Me(Hm, n);
    return this.compare(r, s) !== 0;
  }
  /**
   * @returns a node wrapper that will sort equal to or less than
   * any other node wrapper, using this index
   */
  minPost() {
    return Me.MIN;
  }
}
let oS;
class iae extends RO {
  static get __EMPTY_NODE() {
    return oS;
  }
  static set __EMPTY_NODE(e) {
    oS = e;
  }
  compare(e, n) {
    return yp(e.name, n.name);
  }
  isDefinedOn(e) {
    throw yg("KeyIndex.isDefinedOn not expected to be called.");
  }
  indexedValueChanged(e, n) {
    return !1;
  }
  minPost() {
    return Me.MIN;
  }
  maxPost() {
    return new Me(np, oS);
  }
  makePost(e, n) {
    return A(typeof e == "string", "KeyIndex indexValue must always be a string."), new Me(e, oS);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".key";
  }
}
const M_ = new iae();
class aS {
  /**
   * @param node - Node to iterate.
   * @param isReverse_ - Whether or not to iterate in reverse
   */
  constructor(e, n, r, s, i = null) {
    this.isReverse_ = s, this.resultGenerator_ = i, this.nodeStack_ = [];
    let o = 1;
    for (; !e.isEmpty(); )
      if (e = e, o = n ? r(e.key, n) : 1, s && (o *= -1), o < 0)
        this.isReverse_ ? e = e.left : e = e.right;
      else if (o === 0) {
        this.nodeStack_.push(e);
        break;
      } else
        this.nodeStack_.push(e), this.isReverse_ ? e = e.right : e = e.left;
  }
  getNext() {
    if (this.nodeStack_.length === 0)
      return null;
    let e = this.nodeStack_.pop(), n;
    if (this.resultGenerator_ ? n = this.resultGenerator_(e.key, e.value) : n = { key: e.key, value: e.value }, this.isReverse_)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack_.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack_.length > 0;
  }
  peek() {
    if (this.nodeStack_.length === 0)
      return null;
    const e = this.nodeStack_[this.nodeStack_.length - 1];
    return this.resultGenerator_ ? this.resultGenerator_(e.key, e.value) : { key: e.key, value: e.value };
  }
}
class Fr {
  /**
   * @param key - Key associated with this node.
   * @param value - Value associated with this node.
   * @param color - Whether this node is red.
   * @param left - Left child.
   * @param right - Right child.
   */
  constructor(e, n, r, s, i) {
    this.key = e, this.value = n, this.color = r ?? Fr.RED, this.left = s ?? di.EMPTY_NODE, this.right = i ?? di.EMPTY_NODE;
  }
  /**
   * Returns a copy of the current node, optionally replacing pieces of it.
   *
   * @param key - New key for the node, or null.
   * @param value - New value for the node, or null.
   * @param color - New color for the node, or null.
   * @param left - New left child for the node, or null.
   * @param right - New right child for the node, or null.
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return new Fr(e ?? this.key, n ?? this.value, r ?? this.color, s ?? this.left, i ?? this.right);
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return this.left.count() + 1 + this.right.count();
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !1;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   *   node.  If it returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || !!e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  /**
   * @returns The minimum node in the tree.
   */
  min_() {
    return this.left.isEmpty() ? this : this.left.min_();
  }
  /**
   * @returns The maximum key in the tree.
   */
  minKey() {
    return this.min_().key;
  }
  /**
   * @returns The maximum key in the tree.
   */
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  /**
   * @param key - Key to insert.
   * @param value - Value to insert.
   * @param comparator - Comparator.
   * @returns New tree, with the key/value added.
   */
  insert(e, n, r) {
    let s = this;
    const i = r(e, s.key);
    return i < 0 ? s = s.copy(null, null, null, s.left.insert(e, n, r), null) : i === 0 ? s = s.copy(null, n, null, null, null) : s = s.copy(null, null, null, null, s.right.insert(e, n, r)), s.fixUp_();
  }
  /**
   * @returns New tree, with the minimum key removed.
   */
  removeMin_() {
    if (this.left.isEmpty())
      return di.EMPTY_NODE;
    let e = this;
    return !e.left.isRed_() && !e.left.left.isRed_() && (e = e.moveRedLeft_()), e = e.copy(null, null, null, e.left.removeMin_(), null), e.fixUp_();
  }
  /**
   * @param key - The key of the item to remove.
   * @param comparator - Comparator.
   * @returns New tree, with the specified item removed.
   */
  remove(e, n) {
    let r, s;
    if (r = this, n(e, r.key) < 0)
      !r.left.isEmpty() && !r.left.isRed_() && !r.left.left.isRed_() && (r = r.moveRedLeft_()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed_() && (r = r.rotateRight_()), !r.right.isEmpty() && !r.right.isRed_() && !r.right.left.isRed_() && (r = r.moveRedRight_()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return di.EMPTY_NODE;
        s = r.right.min_(), r = r.copy(s.key, s.value, null, null, r.right.removeMin_());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp_();
  }
  /**
   * @returns Whether this is a RED node.
   */
  isRed_() {
    return this.color;
  }
  /**
   * @returns New tree after performing any needed rotations.
   */
  fixUp_() {
    let e = this;
    return e.right.isRed_() && !e.left.isRed_() && (e = e.rotateLeft_()), e.left.isRed_() && e.left.left.isRed_() && (e = e.rotateRight_()), e.left.isRed_() && e.right.isRed_() && (e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedLeft.
   */
  moveRedLeft_() {
    let e = this.colorFlip_();
    return e.right.left.isRed_() && (e = e.copy(null, null, null, null, e.right.rotateRight_()), e = e.rotateLeft_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after moveRedRight.
   */
  moveRedRight_() {
    let e = this.colorFlip_();
    return e.left.left.isRed_() && (e = e.rotateRight_(), e = e.colorFlip_()), e;
  }
  /**
   * @returns New tree, after rotateLeft.
   */
  rotateLeft_() {
    const e = this.copy(null, null, Fr.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  /**
   * @returns New tree, after rotateRight.
   */
  rotateRight_() {
    const e = this.copy(null, null, Fr.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  /**
   * @returns Newt ree, after colorFlip.
   */
  colorFlip_() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  /**
   * For testing.
   *
   * @returns True if all is well.
   */
  checkMaxDepth_() {
    const e = this.check_();
    return Math.pow(2, e) <= this.count() + 1;
  }
  check_() {
    if (this.isRed_() && this.left.isRed_())
      throw new Error("Red node has red child(" + this.key + "," + this.value + ")");
    if (this.right.isRed_())
      throw new Error("Right child of (" + this.key + "," + this.value + ") is red");
    const e = this.left.check_();
    if (e !== this.right.check_())
      throw new Error("Black depths differ");
    return e + (this.isRed_() ? 0 : 1);
  }
}
Fr.RED = !0;
Fr.BLACK = !1;
class U5e {
  /**
   * Returns a copy of the current node.
   *
   * @returns The node copy.
   */
  copy(e, n, r, s, i) {
    return this;
  }
  /**
   * Returns a copy of the tree, with the specified key/value added.
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @param comparator - Comparator.
   * @returns New tree, with item added.
   */
  insert(e, n, r) {
    return new Fr(e, n, null);
  }
  /**
   * Returns a copy of the tree, with the specified key removed.
   *
   * @param key - The key to remove.
   * @param comparator - Comparator.
   * @returns New tree, with item removed.
   */
  remove(e, n) {
    return this;
  }
  /**
   * @returns The total number of nodes in the tree.
   */
  count() {
    return 0;
  }
  /**
   * @returns True if the tree is empty.
   */
  isEmpty() {
    return !0;
  }
  /**
   * Traverses the tree in key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  inorderTraversal(e) {
    return !1;
  }
  /**
   * Traverses the tree in reverse key order and calls the specified action function
   * for each node.
   *
   * @param action - Callback function to be called for each
   * node.  If it returns true, traversal is aborted.
   * @returns True if traversal was aborted.
   */
  reverseTraversal(e) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  check_() {
    return 0;
  }
  /**
   * @returns Whether this node is red.
   */
  isRed_() {
    return !1;
  }
}
class di {
  /**
   * @param comparator_ - Key comparator.
   * @param root_ - Optional root node for the map.
   */
  constructor(e, n = di.EMPTY_NODE) {
    this.comparator_ = e, this.root_ = n;
  }
  /**
   * Returns a copy of the map, with the specified key/value added or replaced.
   * (TODO: We should perhaps rename this method to 'put')
   *
   * @param key - Key to be added.
   * @param value - Value to be added.
   * @returns New map, with item added.
   */
  insert(e, n) {
    return new di(this.comparator_, this.root_.insert(e, n, this.comparator_).copy(null, null, Fr.BLACK, null, null));
  }
  /**
   * Returns a copy of the map, with the specified key removed.
   *
   * @param key - The key to remove.
   * @returns New map, with item removed.
   */
  remove(e) {
    return new di(this.comparator_, this.root_.remove(e, this.comparator_).copy(null, null, Fr.BLACK, null, null));
  }
  /**
   * Returns the value of the node with the given key, or null.
   *
   * @param key - The key to look up.
   * @returns The value of the node with the given key, or null if the
   * key doesn't exist.
   */
  get(e) {
    let n, r = this.root_;
    for (; !r.isEmpty(); ) {
      if (n = this.comparator_(e, r.key), n === 0)
        return r.value;
      n < 0 ? r = r.left : n > 0 && (r = r.right);
    }
    return null;
  }
  /**
   * Returns the key of the item *before* the specified key, or null if key is the first item.
   * @param key - The key to find the predecessor of
   * @returns The predecessor key.
   */
  getPredecessorKey(e) {
    let n, r = this.root_, s = null;
    for (; !r.isEmpty(); )
      if (n = this.comparator_(e, r.key), n === 0) {
        if (r.left.isEmpty())
          return s ? s.key : null;
        for (r = r.left; !r.right.isEmpty(); )
          r = r.right;
        return r.key;
      } else n < 0 ? r = r.left : n > 0 && (s = r, r = r.right);
    throw new Error("Attempted to find predecessor key for a nonexistent key.  What gives?");
  }
  /**
   * @returns True if the map is empty.
   */
  isEmpty() {
    return this.root_.isEmpty();
  }
  /**
   * @returns The total number of nodes in the map.
   */
  count() {
    return this.root_.count();
  }
  /**
   * @returns The minimum key in the map.
   */
  minKey() {
    return this.root_.minKey();
  }
  /**
   * @returns The maximum key in the map.
   */
  maxKey() {
    return this.root_.maxKey();
  }
  /**
   * Traverses the map in key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns The first truthy value returned by action, or the last falsey
   *   value returned by action
   */
  inorderTraversal(e) {
    return this.root_.inorderTraversal(e);
  }
  /**
   * Traverses the map in reverse key order and calls the specified action function
   * for each key/value pair.
   *
   * @param action - Callback function to be called
   * for each key/value pair.  If action returns true, traversal is aborted.
   * @returns True if the traversal was aborted.
   */
  reverseTraversal(e) {
    return this.root_.reverseTraversal(e);
  }
  /**
   * Returns an iterator over the SortedMap.
   * @returns The iterator.
   */
  getIterator(e) {
    return new aS(this.root_, null, this.comparator_, !1, e);
  }
  getIteratorFrom(e, n) {
    return new aS(this.root_, e, this.comparator_, !1, n);
  }
  getReverseIteratorFrom(e, n) {
    return new aS(this.root_, e, this.comparator_, !0, n);
  }
  getReverseIterator(e) {
    return new aS(this.root_, null, this.comparator_, !0, e);
  }
}
di.EMPTY_NODE = new U5e();
function F5e(t, e) {
  return yp(t.name, e.name);
}
function wH(t, e) {
  return yp(t, e);
}
let cF;
function Y5e(t) {
  cF = t;
}
const oae = function(t) {
  return typeof t == "number" ? "number:" + Foe(t) : "string:" + t;
}, aae = function(t) {
  if (t.isLeafNode()) {
    const e = t.val();
    A(typeof e == "string" || typeof e == "number" || typeof e == "object" && Ka(e, ".sv"), "Priority must be a string or number.");
  } else
    A(t === cF || t.isEmpty(), "priority of unexpected type.");
  A(t === cF || t.getPriority().isEmpty(), "Priority nodes can't have a priority of their own.");
};
let r9;
class Or {
  /**
   * @param value_ - The value to store in this leaf node. The object type is
   * possible in the event of a deferred value
   * @param priorityNode_ - The priority of this node.
   */
  constructor(e, n = Or.__childrenNodeConstructor.EMPTY_NODE) {
    this.value_ = e, this.priorityNode_ = n, this.lazyHash_ = null, A(this.value_ !== void 0 && this.value_ !== null, "LeafNode shouldn't be created with null/undefined value."), aae(this.priorityNode_);
  }
  static set __childrenNodeConstructor(e) {
    r9 = e;
  }
  static get __childrenNodeConstructor() {
    return r9;
  }
  /** @inheritDoc */
  isLeafNode() {
    return !0;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return new Or(this.value_, e);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    return e === ".priority" ? this.priorityNode_ : Or.__childrenNodeConstructor.EMPTY_NODE;
  }
  /** @inheritDoc */
  getChild(e) {
    return Be(e) ? this : Re(e) === ".priority" ? this.priorityNode_ : Or.__childrenNodeConstructor.EMPTY_NODE;
  }
  hasChild() {
    return !1;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n) {
    return null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    return e === ".priority" ? this.updatePriority(n) : n.isEmpty() && e !== ".priority" ? this : Or.__childrenNodeConstructor.EMPTY_NODE.updateImmediateChild(e, n).updatePriority(this.priorityNode_);
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Re(e);
    return r === null ? n : n.isEmpty() && r !== ".priority" ? this : (A(r !== ".priority" || Hh(e) === 1, ".priority must be the last token in a path"), this.updateImmediateChild(r, Or.__childrenNodeConstructor.EMPTY_NODE.updateChild(sn(e), n)));
  }
  /** @inheritDoc */
  isEmpty() {
    return !1;
  }
  /** @inheritDoc */
  numChildren() {
    return 0;
  }
  /** @inheritDoc */
  forEachChild(e, n) {
    return !1;
  }
  val(e) {
    return e && !this.getPriority().isEmpty() ? {
      ".value": this.getValue(),
      ".priority": this.getPriority().val()
    } : this.getValue();
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.priorityNode_.isEmpty() || (e += "priority:" + oae(this.priorityNode_.val()) + ":");
      const n = typeof this.value_;
      e += n + ":", n === "number" ? e += Foe(this.value_) : e += this.value_, this.lazyHash_ = Loe(e);
    }
    return this.lazyHash_;
  }
  /**
   * Returns the value of the leaf node.
   * @returns The value of the node.
   */
  getValue() {
    return this.value_;
  }
  compareTo(e) {
    return e === Or.__childrenNodeConstructor.EMPTY_NODE ? 1 : e instanceof Or.__childrenNodeConstructor ? -1 : (A(e.isLeafNode(), "Unknown node type"), this.compareToLeafNode_(e));
  }
  /**
   * Comparison specifically for two leaf nodes
   */
  compareToLeafNode_(e) {
    const n = typeof e.value_, r = typeof this.value_, s = Or.VALUE_TYPE_ORDER.indexOf(n), i = Or.VALUE_TYPE_ORDER.indexOf(r);
    return A(s >= 0, "Unknown leaf type: " + n), A(i >= 0, "Unknown leaf type: " + r), s === i ? r === "object" ? 0 : this.value_ < e.value_ ? -1 : this.value_ === e.value_ ? 0 : 1 : i - s;
  }
  withIndex() {
    return this;
  }
  isIndexed() {
    return !0;
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode()) {
      const n = e;
      return this.value_ === n.value_ && this.priorityNode_.equals(n.priorityNode_);
    } else
      return !1;
  }
}
Or.VALUE_TYPE_ORDER = ["object", "boolean", "number", "string"];
let cae, lae;
function W5e(t) {
  cae = t;
}
function B5e(t) {
  lae = t;
}
class q5e extends RO {
  compare(e, n) {
    const r = e.node.getPriority(), s = n.node.getPriority(), i = r.compareTo(s);
    return i === 0 ? yp(e.name, n.name) : i;
  }
  isDefinedOn(e) {
    return !e.getPriority().isEmpty();
  }
  indexedValueChanged(e, n) {
    return !e.getPriority().equals(n.getPriority());
  }
  minPost() {
    return Me.MIN;
  }
  maxPost() {
    return new Me(np, new Or("[PRIORITY-POST]", lae));
  }
  makePost(e, n) {
    const r = cae(e);
    return new Me(n, new Or("[PRIORITY-POST]", r));
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".priority";
  }
}
const Fn = new q5e(), H5e = Math.log(2);
class j5e {
  constructor(e) {
    const n = (i) => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parseInt(Math.log(i) / H5e, 10)
    ), r = (i) => parseInt(Array(i + 1).join("1"), 2);
    this.count = n(e + 1), this.current_ = this.count - 1;
    const s = r(this.count);
    this.bits_ = e + 1 & s;
  }
  nextBitIsOne() {
    const e = !(this.bits_ & 1 << this.current_);
    return this.current_--, e;
  }
}
const pk = function(t, e, n, r) {
  t.sort(e);
  const s = function(c, l) {
    const h = l - c;
    let u, d;
    if (h === 0)
      return null;
    if (h === 1)
      return u = t[c], d = n ? n(u) : u, new Fr(d, u.node, Fr.BLACK, null, null);
    {
      const f = parseInt(h / 2, 10) + c, p = s(c, f), _ = s(f + 1, l);
      return u = t[f], d = n ? n(u) : u, new Fr(d, u.node, Fr.BLACK, p, _);
    }
  }, i = function(c) {
    let l = null, h = null, u = t.length;
    const d = function(p, _) {
      const m = u - p, v = u;
      u -= p;
      const y = s(m + 1, v), g = t[m], w = n ? n(g) : g;
      f(new Fr(w, g.node, _, null, y));
    }, f = function(p) {
      l ? (l.left = p, l = p) : (h = p, l = p);
    };
    for (let p = 0; p < c.count; ++p) {
      const _ = c.nextBitIsOne(), m = Math.pow(2, c.count - (p + 1));
      _ ? d(m, Fr.BLACK) : (d(m, Fr.BLACK), d(m, Fr.RED));
    }
    return h;
  }, o = new j5e(t.length), a = i(o);
  return new di(r || e, a);
};
let k1;
const Fp = {};
class Rc {
  constructor(e, n) {
    this.indexes_ = e, this.indexSet_ = n;
  }
  /**
   * The default IndexMap for nodes without a priority
   */
  static get Default() {
    return A(Fp && Fn, "ChildrenNode.ts has not been loaded"), k1 = k1 || new Rc({ ".priority": Fp }, { ".priority": Fn }), k1;
  }
  get(e) {
    const n = Bm(this.indexes_, e);
    if (!n)
      throw new Error("No index defined for " + e);
    return n instanceof di ? n : null;
  }
  hasIndex(e) {
    return Ka(this.indexSet_, e.toString());
  }
  addIndex(e, n) {
    A(e !== M_, "KeyIndex always exists and isn't meant to be added to the IndexMap.");
    const r = [];
    let s = !1;
    const i = n.getIterator(Me.Wrap);
    let o = i.getNext();
    for (; o; )
      s = s || e.isDefinedOn(o.node), r.push(o), o = i.getNext();
    let a;
    s ? a = pk(r, e.getCompare()) : a = Fp;
    const c = e.toString(), l = Object.assign({}, this.indexSet_);
    l[c] = e;
    const h = Object.assign({}, this.indexes_);
    return h[c] = a, new Rc(h, l);
  }
  /**
   * Ensure that this node is properly tracked in any indexes that we're maintaining
   */
  addToIndexes(e, n) {
    const r = uk(this.indexes_, (s, i) => {
      const o = Bm(this.indexSet_, i);
      if (A(o, "Missing index implementation for " + i), s === Fp)
        if (o.isDefinedOn(e.node)) {
          const a = [], c = n.getIterator(Me.Wrap);
          let l = c.getNext();
          for (; l; )
            l.name !== e.name && a.push(l), l = c.getNext();
          return a.push(e), pk(a, o.getCompare());
        } else
          return Fp;
      else {
        const a = n.get(e.name);
        let c = s;
        return a && (c = c.remove(new Me(e.name, a))), c.insert(e, e.node);
      }
    });
    return new Rc(r, this.indexSet_);
  }
  /**
   * Create a new IndexMap instance with the given value removed
   */
  removeFromIndexes(e, n) {
    const r = uk(this.indexes_, (s) => {
      if (s === Fp)
        return s;
      {
        const i = n.get(e.name);
        return i ? s.remove(new Me(e.name, i)) : s;
      }
    });
    return new Rc(r, this.indexSet_);
  }
}
let Gy;
class he {
  /**
   * @param children_ - List of children of this node..
   * @param priorityNode_ - The priority of this node (as a snapshot node).
   */
  constructor(e, n, r) {
    this.children_ = e, this.priorityNode_ = n, this.indexMap_ = r, this.lazyHash_ = null, this.priorityNode_ && aae(this.priorityNode_), this.children_.isEmpty() && A(!this.priorityNode_ || this.priorityNode_.isEmpty(), "An empty node cannot have a priority");
  }
  static get EMPTY_NODE() {
    return Gy || (Gy = new he(new di(wH), null, Rc.Default));
  }
  /** @inheritDoc */
  isLeafNode() {
    return !1;
  }
  /** @inheritDoc */
  getPriority() {
    return this.priorityNode_ || Gy;
  }
  /** @inheritDoc */
  updatePriority(e) {
    return this.children_.isEmpty() ? this : new he(this.children_, e, this.indexMap_);
  }
  /** @inheritDoc */
  getImmediateChild(e) {
    if (e === ".priority")
      return this.getPriority();
    {
      const n = this.children_.get(e);
      return n === null ? Gy : n;
    }
  }
  /** @inheritDoc */
  getChild(e) {
    const n = Re(e);
    return n === null ? this : this.getImmediateChild(n).getChild(sn(e));
  }
  /** @inheritDoc */
  hasChild(e) {
    return this.children_.get(e) !== null;
  }
  /** @inheritDoc */
  updateImmediateChild(e, n) {
    if (A(n, "We should always be passing snapshot nodes"), e === ".priority")
      return this.updatePriority(n);
    {
      const r = new Me(e, n);
      let s, i;
      n.isEmpty() ? (s = this.children_.remove(e), i = this.indexMap_.removeFromIndexes(r, this.children_)) : (s = this.children_.insert(e, n), i = this.indexMap_.addToIndexes(r, this.children_));
      const o = s.isEmpty() ? Gy : this.priorityNode_;
      return new he(s, o, i);
    }
  }
  /** @inheritDoc */
  updateChild(e, n) {
    const r = Re(e);
    if (r === null)
      return n;
    {
      A(Re(e) !== ".priority" || Hh(e) === 1, ".priority must be the last token in a path");
      const s = this.getImmediateChild(r).updateChild(sn(e), n);
      return this.updateImmediateChild(r, s);
    }
  }
  /** @inheritDoc */
  isEmpty() {
    return this.children_.isEmpty();
  }
  /** @inheritDoc */
  numChildren() {
    return this.children_.count();
  }
  /** @inheritDoc */
  val(e) {
    if (this.isEmpty())
      return null;
    const n = {};
    let r = 0, s = 0, i = !0;
    if (this.forEachChild(Fn, (o, a) => {
      n[o] = a.val(e), r++, i && he.INTEGER_REGEXP_.test(o) ? s = Math.max(s, Number(o)) : i = !1;
    }), !e && i && s < 2 * r) {
      const o = [];
      for (const a in n)
        o[a] = n[a];
      return o;
    } else
      return e && !this.getPriority().isEmpty() && (n[".priority"] = this.getPriority().val()), n;
  }
  /** @inheritDoc */
  hash() {
    if (this.lazyHash_ === null) {
      let e = "";
      this.getPriority().isEmpty() || (e += "priority:" + oae(this.getPriority().val()) + ":"), this.forEachChild(Fn, (n, r) => {
        const s = r.hash();
        s !== "" && (e += ":" + n + ":" + s);
      }), this.lazyHash_ = e === "" ? "" : Loe(e);
    }
    return this.lazyHash_;
  }
  /** @inheritDoc */
  getPredecessorChildName(e, n, r) {
    const s = this.resolveIndex_(r);
    if (s) {
      const i = s.getPredecessorKey(new Me(e, n));
      return i ? i.name : null;
    } else
      return this.children_.getPredecessorKey(e);
  }
  getFirstChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.minKey();
      return r && r.name;
    } else
      return this.children_.minKey();
  }
  getFirstChild(e) {
    const n = this.getFirstChildName(e);
    return n ? new Me(n, this.children_.get(n)) : null;
  }
  /**
   * Given an index, return the key name of the largest value we have, according to that index
   */
  getLastChildName(e) {
    const n = this.resolveIndex_(e);
    if (n) {
      const r = n.maxKey();
      return r && r.name;
    } else
      return this.children_.maxKey();
  }
  getLastChild(e) {
    const n = this.getLastChildName(e);
    return n ? new Me(n, this.children_.get(n)) : null;
  }
  forEachChild(e, n) {
    const r = this.resolveIndex_(e);
    return r ? r.inorderTraversal((s) => n(s.name, s.node)) : this.children_.inorderTraversal(n);
  }
  getIterator(e) {
    return this.getIteratorFrom(e.minPost(), e);
  }
  getIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getIteratorFrom(e.name, Me.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) < 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  getReverseIterator(e) {
    return this.getReverseIteratorFrom(e.maxPost(), e);
  }
  getReverseIteratorFrom(e, n) {
    const r = this.resolveIndex_(n);
    if (r)
      return r.getReverseIteratorFrom(e, (s) => s);
    {
      const s = this.children_.getReverseIteratorFrom(e.name, Me.Wrap);
      let i = s.peek();
      for (; i != null && n.compare(i, e) > 0; )
        s.getNext(), i = s.peek();
      return s;
    }
  }
  compareTo(e) {
    return this.isEmpty() ? e.isEmpty() ? 0 : -1 : e.isLeafNode() || e.isEmpty() ? 1 : e === Ub ? -1 : 0;
  }
  withIndex(e) {
    if (e === M_ || this.indexMap_.hasIndex(e))
      return this;
    {
      const n = this.indexMap_.addIndex(e, this.children_);
      return new he(this.children_, this.priorityNode_, n);
    }
  }
  isIndexed(e) {
    return e === M_ || this.indexMap_.hasIndex(e);
  }
  equals(e) {
    if (e === this)
      return !0;
    if (e.isLeafNode())
      return !1;
    {
      const n = e;
      if (this.getPriority().equals(n.getPriority()))
        if (this.children_.count() === n.children_.count()) {
          const r = this.getIterator(Fn), s = n.getIterator(Fn);
          let i = r.getNext(), o = s.getNext();
          for (; i && o; ) {
            if (i.name !== o.name || !i.node.equals(o.node))
              return !1;
            i = r.getNext(), o = s.getNext();
          }
          return i === null && o === null;
        } else
          return !1;
      else return !1;
    }
  }
  /**
   * Returns a SortedMap ordered by index, or null if the default (by-key) ordering can be used
   * instead.
   *
   */
  resolveIndex_(e) {
    return e === M_ ? null : this.indexMap_.get(e.toString());
  }
}
he.INTEGER_REGEXP_ = /^(0|[1-9]\d*)$/;
class V5e extends he {
  constructor() {
    super(new di(wH), he.EMPTY_NODE, Rc.Default);
  }
  compareTo(e) {
    return e === this ? 0 : 1;
  }
  equals(e) {
    return e === this;
  }
  getPriority() {
    return this;
  }
  getImmediateChild(e) {
    return he.EMPTY_NODE;
  }
  isEmpty() {
    return !1;
  }
}
const Ub = new V5e();
Object.defineProperties(Me, {
  MIN: {
    value: new Me(Hm, he.EMPTY_NODE)
  },
  MAX: {
    value: new Me(np, Ub)
  }
});
iae.__EMPTY_NODE = he.EMPTY_NODE;
Or.__childrenNodeConstructor = he;
Y5e(Ub);
B5e(Ub);
const z5e = !0;
function hr(t, e = null) {
  if (t === null)
    return he.EMPTY_NODE;
  if (typeof t == "object" && ".priority" in t && (e = t[".priority"]), A(e === null || typeof e == "string" || typeof e == "number" || typeof e == "object" && ".sv" in e, "Invalid priority type found: " + typeof e), typeof t == "object" && ".value" in t && t[".value"] !== null && (t = t[".value"]), typeof t != "object" || ".sv" in t) {
    const n = t;
    return new Or(n, hr(e));
  }
  if (!(t instanceof Array) && z5e) {
    const n = [];
    let r = !1;
    if (is(t, (i, o) => {
      if (i.substring(0, 1) !== ".") {
        const a = hr(o);
        a.isEmpty() || (r = r || !a.getPriority().isEmpty(), n.push(new Me(i, a)));
      }
    }), n.length === 0)
      return he.EMPTY_NODE;
    const s = pk(n, F5e, (i) => i.name, wH);
    if (r) {
      const i = pk(n, Fn.getCompare());
      return new he(s, hr(e), new Rc({ ".priority": i }, { ".priority": Fn }));
    } else
      return new he(s, hr(e), Rc.Default);
  } else {
    let n = he.EMPTY_NODE;
    return is(t, (r, s) => {
      if (Ka(t, r) && r.substring(0, 1) !== ".") {
        const i = hr(s);
        (i.isLeafNode() || !i.isEmpty()) && (n = n.updateImmediateChild(r, i));
      }
    }), n.updatePriority(hr(e));
  }
}
W5e(hr);
class G5e extends RO {
  constructor(e) {
    super(), this.indexPath_ = e, A(!Be(e) && Re(e) !== ".priority", "Can't create PathIndex with empty path or .priority key");
  }
  extractChild(e) {
    return e.getChild(this.indexPath_);
  }
  isDefinedOn(e) {
    return !e.getChild(this.indexPath_).isEmpty();
  }
  compare(e, n) {
    const r = this.extractChild(e.node), s = this.extractChild(n.node), i = r.compareTo(s);
    return i === 0 ? yp(e.name, n.name) : i;
  }
  makePost(e, n) {
    const r = hr(e), s = he.EMPTY_NODE.updateChild(this.indexPath_, r);
    return new Me(n, s);
  }
  maxPost() {
    const e = he.EMPTY_NODE.updateChild(this.indexPath_, Ub);
    return new Me(np, e);
  }
  toString() {
    return FC(this.indexPath_, 0).join("/");
  }
}
class $5e extends RO {
  compare(e, n) {
    const r = e.node.compareTo(n.node);
    return r === 0 ? yp(e.name, n.name) : r;
  }
  isDefinedOn(e) {
    return !0;
  }
  indexedValueChanged(e, n) {
    return !e.equals(n);
  }
  minPost() {
    return Me.MIN;
  }
  maxPost() {
    return Me.MAX;
  }
  makePost(e, n) {
    const r = hr(e);
    return new Me(n, r);
  }
  /**
   * @returns String representation for inclusion in a query spec
   */
  toString() {
    return ".value";
  }
}
const K5e = new $5e();
function uae(t) {
  return { type: "value", snapshotNode: t };
}
function jm(t, e) {
  return { type: "child_added", snapshotNode: e, childName: t };
}
function YC(t, e) {
  return { type: "child_removed", snapshotNode: e, childName: t };
}
function WC(t, e, n) {
  return {
    type: "child_changed",
    snapshotNode: e,
    childName: t,
    oldSnap: n
  };
}
function J5e(t, e) {
  return { type: "child_moved", snapshotNode: e, childName: t };
}
class CH {
  constructor(e) {
    this.index_ = e;
  }
  updateChild(e, n, r, s, i, o) {
    A(e.isIndexed(this.index_), "A node must be indexed if only a child is updated");
    const a = e.getImmediateChild(n);
    return a.getChild(s).equals(r.getChild(s)) && a.isEmpty() === r.isEmpty() || (o != null && (r.isEmpty() ? e.hasChild(n) ? o.trackChildChange(YC(n, a)) : A(e.isLeafNode(), "A child remove without an old child only makes sense on a leaf node") : a.isEmpty() ? o.trackChildChange(jm(n, r)) : o.trackChildChange(WC(n, r, a))), e.isLeafNode() && r.isEmpty()) ? e : e.updateImmediateChild(n, r).withIndex(this.index_);
  }
  updateFullNode(e, n, r) {
    return r != null && (e.isLeafNode() || e.forEachChild(Fn, (s, i) => {
      n.hasChild(s) || r.trackChildChange(YC(s, i));
    }), n.isLeafNode() || n.forEachChild(Fn, (s, i) => {
      if (e.hasChild(s)) {
        const o = e.getImmediateChild(s);
        o.equals(i) || r.trackChildChange(WC(s, i, o));
      } else
        r.trackChildChange(jm(s, i));
    })), n.withIndex(this.index_);
  }
  updatePriority(e, n) {
    return e.isEmpty() ? he.EMPTY_NODE : e.updatePriority(n);
  }
  filtersNodes() {
    return !1;
  }
  getIndexedFilter() {
    return this;
  }
  getIndex() {
    return this.index_;
  }
}
class BC {
  constructor(e) {
    this.indexedFilter_ = new CH(e.getIndex()), this.index_ = e.getIndex(), this.startPost_ = BC.getStartPost_(e), this.endPost_ = BC.getEndPost_(e), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  getStartPost() {
    return this.startPost_;
  }
  getEndPost() {
    return this.endPost_;
  }
  matches(e) {
    const n = this.startIsInclusive_ ? this.index_.compare(this.getStartPost(), e) <= 0 : this.index_.compare(this.getStartPost(), e) < 0, r = this.endIsInclusive_ ? this.index_.compare(e, this.getEndPost()) <= 0 : this.index_.compare(e, this.getEndPost()) < 0;
    return n && r;
  }
  updateChild(e, n, r, s, i, o) {
    return this.matches(new Me(n, r)) || (r = he.EMPTY_NODE), this.indexedFilter_.updateChild(e, n, r, s, i, o);
  }
  updateFullNode(e, n, r) {
    n.isLeafNode() && (n = he.EMPTY_NODE);
    let s = n.withIndex(this.index_);
    s = s.updatePriority(he.EMPTY_NODE);
    const i = this;
    return n.forEachChild(Fn, (o, a) => {
      i.matches(new Me(o, a)) || (s = s.updateImmediateChild(o, he.EMPTY_NODE));
    }), this.indexedFilter_.updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.indexedFilter_;
  }
  getIndex() {
    return this.index_;
  }
  static getStartPost_(e) {
    if (e.hasStart()) {
      const n = e.getIndexStartName();
      return e.getIndex().makePost(e.getIndexStartValue(), n);
    } else
      return e.getIndex().minPost();
  }
  static getEndPost_(e) {
    if (e.hasEnd()) {
      const n = e.getIndexEndName();
      return e.getIndex().makePost(e.getIndexEndValue(), n);
    } else
      return e.getIndex().maxPost();
  }
}
class Q5e {
  constructor(e) {
    this.withinDirectionalStart = (n) => this.reverse_ ? this.withinEndPost(n) : this.withinStartPost(n), this.withinDirectionalEnd = (n) => this.reverse_ ? this.withinStartPost(n) : this.withinEndPost(n), this.withinStartPost = (n) => {
      const r = this.index_.compare(this.rangedFilter_.getStartPost(), n);
      return this.startIsInclusive_ ? r <= 0 : r < 0;
    }, this.withinEndPost = (n) => {
      const r = this.index_.compare(n, this.rangedFilter_.getEndPost());
      return this.endIsInclusive_ ? r <= 0 : r < 0;
    }, this.rangedFilter_ = new BC(e), this.index_ = e.getIndex(), this.limit_ = e.getLimit(), this.reverse_ = !e.isViewFromLeft(), this.startIsInclusive_ = !e.startAfterSet_, this.endIsInclusive_ = !e.endBeforeSet_;
  }
  updateChild(e, n, r, s, i, o) {
    return this.rangedFilter_.matches(new Me(n, r)) || (r = he.EMPTY_NODE), e.getImmediateChild(n).equals(r) ? e : e.numChildren() < this.limit_ ? this.rangedFilter_.getIndexedFilter().updateChild(e, n, r, s, i, o) : this.fullLimitUpdateChild_(e, n, r, i, o);
  }
  updateFullNode(e, n, r) {
    let s;
    if (n.isLeafNode() || n.isEmpty())
      s = he.EMPTY_NODE.withIndex(this.index_);
    else if (this.limit_ * 2 < n.numChildren() && n.isIndexed(this.index_)) {
      s = he.EMPTY_NODE.withIndex(this.index_);
      let i;
      this.reverse_ ? i = n.getReverseIteratorFrom(this.rangedFilter_.getEndPost(), this.index_) : i = n.getIteratorFrom(this.rangedFilter_.getStartPost(), this.index_);
      let o = 0;
      for (; i.hasNext() && o < this.limit_; ) {
        const a = i.getNext();
        if (this.withinDirectionalStart(a))
          if (this.withinDirectionalEnd(a))
            s = s.updateImmediateChild(a.name, a.node), o++;
          else
            break;
        else continue;
      }
    } else {
      s = n.withIndex(this.index_), s = s.updatePriority(he.EMPTY_NODE);
      let i;
      this.reverse_ ? i = s.getReverseIterator(this.index_) : i = s.getIterator(this.index_);
      let o = 0;
      for (; i.hasNext(); ) {
        const a = i.getNext();
        o < this.limit_ && this.withinDirectionalStart(a) && this.withinDirectionalEnd(a) ? o++ : s = s.updateImmediateChild(a.name, he.EMPTY_NODE);
      }
    }
    return this.rangedFilter_.getIndexedFilter().updateFullNode(e, s, r);
  }
  updatePriority(e, n) {
    return e;
  }
  filtersNodes() {
    return !0;
  }
  getIndexedFilter() {
    return this.rangedFilter_.getIndexedFilter();
  }
  getIndex() {
    return this.index_;
  }
  fullLimitUpdateChild_(e, n, r, s, i) {
    let o;
    if (this.reverse_) {
      const u = this.index_.getCompare();
      o = (d, f) => u(f, d);
    } else
      o = this.index_.getCompare();
    const a = e;
    A(a.numChildren() === this.limit_, "");
    const c = new Me(n, r), l = this.reverse_ ? a.getFirstChild(this.index_) : a.getLastChild(this.index_), h = this.rangedFilter_.matches(c);
    if (a.hasChild(n)) {
      const u = a.getImmediateChild(n);
      let d = s.getChildAfterChild(this.index_, l, this.reverse_);
      for (; d != null && (d.name === n || a.hasChild(d.name)); )
        d = s.getChildAfterChild(this.index_, d, this.reverse_);
      const f = d == null ? 1 : o(d, c);
      if (h && !r.isEmpty() && f >= 0)
        return i != null && i.trackChildChange(WC(n, r, u)), a.updateImmediateChild(n, r);
      {
        i != null && i.trackChildChange(YC(n, u));
        const p = a.updateImmediateChild(n, he.EMPTY_NODE);
        return d != null && this.rangedFilter_.matches(d) ? (i != null && i.trackChildChange(jm(d.name, d.node)), p.updateImmediateChild(d.name, d.node)) : p;
      }
    } else return r.isEmpty() ? e : h && o(l, c) >= 0 ? (i != null && (i.trackChildChange(YC(l.name, l.node)), i.trackChildChange(jm(n, r))), a.updateImmediateChild(n, r).updateImmediateChild(l.name, he.EMPTY_NODE)) : e;
  }
}
class TH {
  constructor() {
    this.limitSet_ = !1, this.startSet_ = !1, this.startNameSet_ = !1, this.startAfterSet_ = !1, this.endSet_ = !1, this.endNameSet_ = !1, this.endBeforeSet_ = !1, this.limit_ = 0, this.viewFrom_ = "", this.indexStartValue_ = null, this.indexStartName_ = "", this.indexEndValue_ = null, this.indexEndName_ = "", this.index_ = Fn;
  }
  hasStart() {
    return this.startSet_;
  }
  /**
   * @returns True if it would return from left.
   */
  isViewFromLeft() {
    return this.viewFrom_ === "" ? this.startSet_ : this.viewFrom_ === "l";
  }
  /**
   * Only valid to call if hasStart() returns true
   */
  getIndexStartValue() {
    return A(this.startSet_, "Only valid if start has been set"), this.indexStartValue_;
  }
  /**
   * Only valid to call if hasStart() returns true.
   * Returns the starting key name for the range defined by these query parameters
   */
  getIndexStartName() {
    return A(this.startSet_, "Only valid if start has been set"), this.startNameSet_ ? this.indexStartName_ : Hm;
  }
  hasEnd() {
    return this.endSet_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   */
  getIndexEndValue() {
    return A(this.endSet_, "Only valid if end has been set"), this.indexEndValue_;
  }
  /**
   * Only valid to call if hasEnd() returns true.
   * Returns the end key name for the range defined by these query parameters
   */
  getIndexEndName() {
    return A(this.endSet_, "Only valid if end has been set"), this.endNameSet_ ? this.indexEndName_ : np;
  }
  hasLimit() {
    return this.limitSet_;
  }
  /**
   * @returns True if a limit has been set and it has been explicitly anchored
   */
  hasAnchoredLimit() {
    return this.limitSet_ && this.viewFrom_ !== "";
  }
  /**
   * Only valid to call if hasLimit() returns true
   */
  getLimit() {
    return A(this.limitSet_, "Only valid if limit has been set"), this.limit_;
  }
  getIndex() {
    return this.index_;
  }
  loadsAllData() {
    return !(this.startSet_ || this.endSet_ || this.limitSet_);
  }
  isDefault() {
    return this.loadsAllData() && this.index_ === Fn;
  }
  copy() {
    const e = new TH();
    return e.limitSet_ = this.limitSet_, e.limit_ = this.limit_, e.startSet_ = this.startSet_, e.startAfterSet_ = this.startAfterSet_, e.indexStartValue_ = this.indexStartValue_, e.startNameSet_ = this.startNameSet_, e.indexStartName_ = this.indexStartName_, e.endSet_ = this.endSet_, e.endBeforeSet_ = this.endBeforeSet_, e.indexEndValue_ = this.indexEndValue_, e.endNameSet_ = this.endNameSet_, e.indexEndName_ = this.indexEndName_, e.index_ = this.index_, e.viewFrom_ = this.viewFrom_, e;
  }
}
function Z5e(t) {
  return t.loadsAllData() ? new CH(t.getIndex()) : t.hasLimit() ? new Q5e(t) : new BC(t);
}
function s9(t) {
  const e = {};
  if (t.isDefault())
    return e;
  let n;
  if (t.index_ === Fn ? n = "$priority" : t.index_ === K5e ? n = "$value" : t.index_ === M_ ? n = "$key" : (A(t.index_ instanceof G5e, "Unrecognized index type!"), n = t.index_.toString()), e.orderBy = yr(n), t.startSet_) {
    const r = t.startAfterSet_ ? "startAfter" : "startAt";
    e[r] = yr(t.indexStartValue_), t.startNameSet_ && (e[r] += "," + yr(t.indexStartName_));
  }
  if (t.endSet_) {
    const r = t.endBeforeSet_ ? "endBefore" : "endAt";
    e[r] = yr(t.indexEndValue_), t.endNameSet_ && (e[r] += "," + yr(t.indexEndName_));
  }
  return t.limitSet_ && (t.isViewFromLeft() ? e.limitToFirst = t.limit_ : e.limitToLast = t.limit_), e;
}
function i9(t) {
  const e = {};
  if (t.startSet_ && (e.sp = t.indexStartValue_, t.startNameSet_ && (e.sn = t.indexStartName_), e.sin = !t.startAfterSet_), t.endSet_ && (e.ep = t.indexEndValue_, t.endNameSet_ && (e.en = t.indexEndName_), e.ein = !t.endBeforeSet_), t.limitSet_) {
    e.l = t.limit_;
    let n = t.viewFrom_;
    n === "" && (t.isViewFromLeft() ? n = "l" : n = "r"), e.vf = n;
  }
  return t.index_ !== Fn && (e.i = t.index_.toString()), e;
}
class _k extends tae {
  /**
   * @param repoInfo_ - Data about the namespace we are connecting to
   * @param onDataUpdate_ - A callback for new data from the server
   */
  constructor(e, n, r, s) {
    super(), this.repoInfo_ = e, this.onDataUpdate_ = n, this.authTokenProvider_ = r, this.appCheckTokenProvider_ = s, this.log_ = Lb("p:rest:"), this.listens_ = {};
  }
  reportStats(e) {
    throw new Error("Method not implemented.");
  }
  static getListenId_(e, n) {
    return n !== void 0 ? "tag$" + n : (A(e._queryParams.isDefault(), "should have a tag if it's not a default query."), e._path.toString());
  }
  /** @inheritDoc */
  listen(e, n, r, s) {
    const i = e._path.toString();
    this.log_("Listen called for " + i + " " + e._queryIdentifier);
    const o = _k.getListenId_(e, r), a = {};
    this.listens_[o] = a;
    const c = s9(e._queryParams);
    this.restRequest_(i + ".json", c, (l, h) => {
      let u = h;
      if (l === 404 && (u = null, l = null), l === null && this.onDataUpdate_(
        i,
        u,
        /*isMerge=*/
        !1,
        r
      ), Bm(this.listens_, o) === a) {
        let d;
        l ? l === 401 ? d = "permission_denied" : d = "rest_error:" + l : d = "ok", s(d, null);
      }
    });
  }
  /** @inheritDoc */
  unlisten(e, n) {
    const r = _k.getListenId_(e, n);
    delete this.listens_[r];
  }
  get(e) {
    const n = s9(e._queryParams), r = e._path.toString(), s = new Mb();
    return this.restRequest_(r + ".json", n, (i, o) => {
      let a = o;
      i === 404 && (a = null, i = null), i === null ? (this.onDataUpdate_(
        r,
        a,
        /*isMerge=*/
        !1,
        /*tag=*/
        null
      ), s.resolve(a)) : s.reject(new Error(a));
    }), s.promise;
  }
  /** @inheritDoc */
  refreshAuthToken(e) {
  }
  /**
   * Performs a REST request to the given path, with the provided query string parameters,
   * and any auth credentials we have.
   */
  restRequest_(e, n = {}, r) {
    return n.format = "export", Promise.all([
      this.authTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      ),
      this.appCheckTokenProvider_.getToken(
        /*forceRefresh=*/
        !1
      )
    ]).then(([s, i]) => {
      s && s.accessToken && (n.auth = s.accessToken), i && i.token && (n.ac = i.token);
      const o = (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host + e + "?ns=" + this.repoInfo_.namespace + P$e(n);
      this.log_("Sending REST request for " + o);
      const a = new XMLHttpRequest();
      a.onreadystatechange = () => {
        if (r && a.readyState === 4) {
          this.log_("REST Response for " + o + " received. status:", a.status, "response:", a.responseText);
          let c = null;
          if (a.status >= 200 && a.status < 300) {
            try {
              c = MC(a.responseText);
            } catch {
              Ys("Failed to parse JSON response for " + o + ": " + a.responseText);
            }
            r(null, c);
          } else
            a.status !== 401 && a.status !== 404 && Ys("Got unsuccessful REST response for " + o + " Status: " + a.status), r(a.status);
          r = null;
        }
      }, a.open(
        "GET",
        o,
        /*asynchronous=*/
        !0
      ), a.send();
    });
  }
}
class X5e {
  constructor() {
    this.rootNode_ = he.EMPTY_NODE;
  }
  getNode(e) {
    return this.rootNode_.getChild(e);
  }
  updateSnapshot(e, n) {
    this.rootNode_ = this.rootNode_.updateChild(e, n);
  }
}
function mk() {
  return {
    value: null,
    children: /* @__PURE__ */ new Map()
  };
}
function hae(t, e, n) {
  if (Be(e))
    t.value = n, t.children.clear();
  else if (t.value !== null)
    t.value = t.value.updateChild(e, n);
  else {
    const r = Re(e);
    t.children.has(r) || t.children.set(r, mk());
    const s = t.children.get(r);
    e = sn(e), hae(s, e, n);
  }
}
function lF(t, e, n) {
  t.value !== null ? n(e, t.value) : e9e(t, (r, s) => {
    const i = new Ut(e.toString() + "/" + r);
    lF(s, i, n);
  });
}
function e9e(t, e) {
  t.children.forEach((n, r) => {
    e(r, n);
  });
}
class t9e {
  constructor(e) {
    this.collection_ = e, this.last_ = null;
  }
  get() {
    const e = this.collection_.get(), n = Object.assign({}, e);
    return this.last_ && is(this.last_, (r, s) => {
      n[r] = n[r] - s;
    }), this.last_ = e, n;
  }
}
const o9 = 10 * 1e3, n9e = 30 * 1e3, r9e = 5 * 60 * 1e3;
class s9e {
  constructor(e, n) {
    this.server_ = n, this.statsToReport_ = {}, this.statsListener_ = new t9e(e);
    const r = o9 + (n9e - o9) * Math.random();
    hw(this.reportStats_.bind(this), Math.floor(r));
  }
  reportStats_() {
    const e = this.statsListener_.get(), n = {};
    let r = !1;
    is(e, (s, i) => {
      i > 0 && Ka(this.statsToReport_, s) && (n[s] = i, r = !0);
    }), r && this.server_.reportStats(n), hw(this.reportStats_.bind(this), Math.floor(Math.random() * 2 * r9e));
  }
}
var Mo;
(function(t) {
  t[t.OVERWRITE = 0] = "OVERWRITE", t[t.MERGE = 1] = "MERGE", t[t.ACK_USER_WRITE = 2] = "ACK_USER_WRITE", t[t.LISTEN_COMPLETE = 3] = "LISTEN_COMPLETE";
})(Mo || (Mo = {}));
function bH() {
  return {
    fromUser: !0,
    fromServer: !1,
    queryId: null,
    tagged: !1
  };
}
function EH() {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: null,
    tagged: !1
  };
}
function SH(t) {
  return {
    fromUser: !1,
    fromServer: !0,
    queryId: t,
    tagged: !0
  };
}
class gk {
  /**
   * @param affectedTree - A tree containing true for each affected path. Affected paths can't overlap.
   */
  constructor(e, n, r) {
    this.path = e, this.affectedTree = n, this.revert = r, this.type = Mo.ACK_USER_WRITE, this.source = bH();
  }
  operationForChild(e) {
    if (Be(this.path)) {
      if (this.affectedTree.value != null)
        return A(this.affectedTree.children.isEmpty(), "affectedTree should not have overlapping affected paths."), this;
      {
        const n = this.affectedTree.subtree(new Ut(e));
        return new gk(dt(), n, this.revert);
      }
    } else return A(Re(this.path) === e, "operationForChild called for unrelated child."), new gk(sn(this.path), this.affectedTree, this.revert);
  }
}
class qC {
  constructor(e, n) {
    this.source = e, this.path = n, this.type = Mo.LISTEN_COMPLETE;
  }
  operationForChild(e) {
    return Be(this.path) ? new qC(this.source, dt()) : new qC(this.source, sn(this.path));
  }
}
class rp {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.snap = r, this.type = Mo.OVERWRITE;
  }
  operationForChild(e) {
    return Be(this.path) ? new rp(this.source, dt(), this.snap.getImmediateChild(e)) : new rp(this.source, sn(this.path), this.snap);
  }
}
class Vm {
  constructor(e, n, r) {
    this.source = e, this.path = n, this.children = r, this.type = Mo.MERGE;
  }
  operationForChild(e) {
    if (Be(this.path)) {
      const n = this.children.subtree(new Ut(e));
      return n.isEmpty() ? null : n.value ? new rp(this.source, dt(), n.value) : new Vm(this.source, dt(), n);
    } else
      return A(Re(this.path) === e, "Can't get a merge for a child not on the path of the operation"), new Vm(this.source, sn(this.path), this.children);
  }
  toString() {
    return "Operation(" + this.path + ": " + this.source.toString() + " merge: " + this.children.toString() + ")";
  }
}
class jh {
  constructor(e, n, r) {
    this.node_ = e, this.fullyInitialized_ = n, this.filtered_ = r;
  }
  /**
   * Returns whether this node was fully initialized with either server data or a complete overwrite by the client
   */
  isFullyInitialized() {
    return this.fullyInitialized_;
  }
  /**
   * Returns whether this node is potentially missing children due to a filter applied to the node
   */
  isFiltered() {
    return this.filtered_;
  }
  isCompleteForPath(e) {
    if (Be(e))
      return this.isFullyInitialized() && !this.filtered_;
    const n = Re(e);
    return this.isCompleteForChild(n);
  }
  isCompleteForChild(e) {
    return this.isFullyInitialized() && !this.filtered_ || this.node_.hasChild(e);
  }
  getNode() {
    return this.node_;
  }
}
class i9e {
  constructor(e) {
    this.query_ = e, this.index_ = this.query_._queryParams.getIndex();
  }
}
function o9e(t, e, n, r) {
  const s = [], i = [];
  return e.forEach((o) => {
    o.type === "child_changed" && t.index_.indexedValueChanged(o.oldSnap, o.snapshotNode) && i.push(J5e(o.childName, o.snapshotNode));
  }), $y(t, s, "child_removed", e, r, n), $y(t, s, "child_added", e, r, n), $y(t, s, "child_moved", i, r, n), $y(t, s, "child_changed", e, r, n), $y(t, s, "value", e, r, n), s;
}
function $y(t, e, n, r, s, i) {
  const o = r.filter((a) => a.type === n);
  o.sort((a, c) => c9e(t, a, c)), o.forEach((a) => {
    const c = a9e(t, a, i);
    s.forEach((l) => {
      l.respondsTo(a.type) && e.push(l.createEvent(c, t.query_));
    });
  });
}
function a9e(t, e, n) {
  return e.type === "value" || e.type === "child_removed" || (e.prevName = n.getPredecessorChildName(e.childName, e.snapshotNode, t.index_)), e;
}
function c9e(t, e, n) {
  if (e.childName == null || n.childName == null)
    throw yg("Should only compare child_ events.");
  const r = new Me(e.childName, e.snapshotNode), s = new Me(n.childName, n.snapshotNode);
  return t.index_.compare(r, s);
}
function kO(t, e) {
  return { eventCache: t, serverCache: e };
}
function dw(t, e, n, r) {
  return kO(new jh(e, n, r), t.serverCache);
}
function dae(t, e, n, r) {
  return kO(t.eventCache, new jh(e, n, r));
}
function yk(t) {
  return t.eventCache.isFullyInitialized() ? t.eventCache.getNode() : null;
}
function sp(t) {
  return t.serverCache.isFullyInitialized() ? t.serverCache.getNode() : null;
}
let N1;
const l9e = () => (N1 || (N1 = new di(GKe)), N1);
class Jt {
  constructor(e, n = l9e()) {
    this.value = e, this.children = n;
  }
  static fromObject(e) {
    let n = new Jt(null);
    return is(e, (r, s) => {
      n = n.set(new Ut(r), s);
    }), n;
  }
  /**
   * True if the value is empty and there are no children
   */
  isEmpty() {
    return this.value === null && this.children.isEmpty();
  }
  /**
   * Given a path and predicate, return the first node and the path to that node
   * where the predicate returns true.
   *
   * TODO Do a perf test -- If we're creating a bunch of `{path: value:}`
   * objects on the way back out, it may be better to pass down a pathSoFar obj.
   *
   * @param relativePath - The remainder of the path
   * @param predicate - The predicate to satisfy to return a node
   */
  findRootMostMatchingPathAndValue(e, n) {
    if (this.value != null && n(this.value))
      return { path: dt(), value: this.value };
    if (Be(e))
      return null;
    {
      const r = Re(e), s = this.children.get(r);
      if (s !== null) {
        const i = s.findRootMostMatchingPathAndValue(sn(e), n);
        return i != null ? { path: On(new Ut(r), i.path), value: i.value } : null;
      } else
        return null;
    }
  }
  /**
   * Find, if it exists, the shortest subpath of the given path that points a defined
   * value in the tree
   */
  findRootMostValueAndPath(e) {
    return this.findRootMostMatchingPathAndValue(e, () => !0);
  }
  /**
   * @returns The subtree at the given path
   */
  subtree(e) {
    if (Be(e))
      return this;
    {
      const n = Re(e), r = this.children.get(n);
      return r !== null ? r.subtree(sn(e)) : new Jt(null);
    }
  }
  /**
   * Sets a value at the specified path.
   *
   * @param relativePath - Path to set value at.
   * @param toSet - Value to set.
   * @returns Resulting tree.
   */
  set(e, n) {
    if (Be(e))
      return new Jt(n, this.children);
    {
      const r = Re(e), s = (this.children.get(r) || new Jt(null)).set(sn(e), n), i = this.children.insert(r, s);
      return new Jt(this.value, i);
    }
  }
  /**
   * Removes the value at the specified path.
   *
   * @param relativePath - Path to value to remove.
   * @returns Resulting tree.
   */
  remove(e) {
    if (Be(e))
      return this.children.isEmpty() ? new Jt(null) : new Jt(null, this.children);
    {
      const n = Re(e), r = this.children.get(n);
      if (r) {
        const s = r.remove(sn(e));
        let i;
        return s.isEmpty() ? i = this.children.remove(n) : i = this.children.insert(n, s), this.value === null && i.isEmpty() ? new Jt(null) : new Jt(this.value, i);
      } else
        return this;
    }
  }
  /**
   * Gets a value from the tree.
   *
   * @param relativePath - Path to get value for.
   * @returns Value at path, or null.
   */
  get(e) {
    if (Be(e))
      return this.value;
    {
      const n = Re(e), r = this.children.get(n);
      return r ? r.get(sn(e)) : null;
    }
  }
  /**
   * Replace the subtree at the specified path with the given new tree.
   *
   * @param relativePath - Path to replace subtree for.
   * @param newTree - New tree.
   * @returns Resulting tree.
   */
  setTree(e, n) {
    if (Be(e))
      return n;
    {
      const r = Re(e), s = (this.children.get(r) || new Jt(null)).setTree(sn(e), n);
      let i;
      return s.isEmpty() ? i = this.children.remove(r) : i = this.children.insert(r, s), new Jt(this.value, i);
    }
  }
  /**
   * Performs a depth first fold on this tree. Transforms a tree into a single
   * value, given a function that operates on the path to a node, an optional
   * current value, and a map of child names to folded subtrees
   */
  fold(e) {
    return this.fold_(dt(), e);
  }
  /**
   * Recursive helper for public-facing fold() method
   */
  fold_(e, n) {
    const r = {};
    return this.children.inorderTraversal((s, i) => {
      r[s] = i.fold_(On(e, s), n);
    }), n(e, this.value, r);
  }
  /**
   * Find the first matching value on the given path. Return the result of applying f to it.
   */
  findOnPath(e, n) {
    return this.findOnPath_(e, dt(), n);
  }
  findOnPath_(e, n, r) {
    const s = this.value ? r(n, this.value) : !1;
    if (s)
      return s;
    if (Be(e))
      return null;
    {
      const i = Re(e), o = this.children.get(i);
      return o ? o.findOnPath_(sn(e), On(n, i), r) : null;
    }
  }
  foreachOnPath(e, n) {
    return this.foreachOnPath_(e, dt(), n);
  }
  foreachOnPath_(e, n, r) {
    if (Be(e))
      return this;
    {
      this.value && r(n, this.value);
      const s = Re(e), i = this.children.get(s);
      return i ? i.foreachOnPath_(sn(e), On(n, s), r) : new Jt(null);
    }
  }
  /**
   * Calls the given function for each node in the tree that has a value.
   *
   * @param f - A function to be called with the path from the root of the tree to
   * a node, and the value at that node. Called in depth-first order.
   */
  foreach(e) {
    this.foreach_(dt(), e);
  }
  foreach_(e, n) {
    this.children.inorderTraversal((r, s) => {
      s.foreach_(On(e, r), n);
    }), this.value && n(e, this.value);
  }
  foreachChild(e) {
    this.children.inorderTraversal((n, r) => {
      r.value && e(n, r.value);
    });
  }
}
class Go {
  constructor(e) {
    this.writeTree_ = e;
  }
  static empty() {
    return new Go(new Jt(null));
  }
}
function fw(t, e, n) {
  if (Be(e))
    return new Go(new Jt(n));
  {
    const r = t.writeTree_.findRootMostValueAndPath(e);
    if (r != null) {
      const s = r.path;
      let i = r.value;
      const o = Ds(s, e);
      return i = i.updateChild(o, n), new Go(t.writeTree_.set(s, i));
    } else {
      const s = new Jt(n), i = t.writeTree_.setTree(e, s);
      return new Go(i);
    }
  }
}
function uF(t, e, n) {
  let r = t;
  return is(n, (s, i) => {
    r = fw(r, On(e, s), i);
  }), r;
}
function a9(t, e) {
  if (Be(e))
    return Go.empty();
  {
    const n = t.writeTree_.setTree(e, new Jt(null));
    return new Go(n);
  }
}
function hF(t, e) {
  return vp(t, e) != null;
}
function vp(t, e) {
  const n = t.writeTree_.findRootMostValueAndPath(e);
  return n != null ? t.writeTree_.get(n.path).getChild(Ds(n.path, e)) : null;
}
function c9(t) {
  const e = [], n = t.writeTree_.value;
  return n != null ? n.isLeafNode() || n.forEachChild(Fn, (r, s) => {
    e.push(new Me(r, s));
  }) : t.writeTree_.children.inorderTraversal((r, s) => {
    s.value != null && e.push(new Me(r, s.value));
  }), e;
}
function hh(t, e) {
  if (Be(e))
    return t;
  {
    const n = vp(t, e);
    return n != null ? new Go(new Jt(n)) : new Go(t.writeTree_.subtree(e));
  }
}
function dF(t) {
  return t.writeTree_.isEmpty();
}
function zm(t, e) {
  return fae(dt(), t.writeTree_, e);
}
function fae(t, e, n) {
  if (e.value != null)
    return n.updateChild(t, e.value);
  {
    let r = null;
    return e.children.inorderTraversal((s, i) => {
      s === ".priority" ? (A(i.value !== null, "Priority writes must always be leaf nodes"), r = i.value) : n = fae(On(t, s), i, n);
    }), !n.getChild(t).isEmpty() && r !== null && (n = n.updateChild(On(t, ".priority"), r)), n;
  }
}
function NO(t, e) {
  return gae(e, t);
}
function u9e(t, e, n, r, s) {
  A(r > t.lastWriteId, "Stacking an older write on top of newer ones"), s === void 0 && (s = !0), t.allWrites.push({
    path: e,
    snap: n,
    writeId: r,
    visible: s
  }), s && (t.visibleWrites = fw(t.visibleWrites, e, n)), t.lastWriteId = r;
}
function h9e(t, e, n, r) {
  A(r > t.lastWriteId, "Stacking an older merge on top of newer ones"), t.allWrites.push({
    path: e,
    children: n,
    writeId: r,
    visible: !0
  }), t.visibleWrites = uF(t.visibleWrites, e, n), t.lastWriteId = r;
}
function d9e(t, e) {
  for (let n = 0; n < t.allWrites.length; n++) {
    const r = t.allWrites[n];
    if (r.writeId === e)
      return r;
  }
  return null;
}
function f9e(t, e) {
  const n = t.allWrites.findIndex((a) => a.writeId === e);
  A(n >= 0, "removeWrite called with nonexistent writeId.");
  const r = t.allWrites[n];
  t.allWrites.splice(n, 1);
  let s = r.visible, i = !1, o = t.allWrites.length - 1;
  for (; s && o >= 0; ) {
    const a = t.allWrites[o];
    a.visible && (o >= n && p9e(a, r.path) ? s = !1 : Qi(r.path, a.path) && (i = !0)), o--;
  }
  if (s) {
    if (i)
      return _9e(t), !0;
    if (r.snap)
      t.visibleWrites = a9(t.visibleWrites, r.path);
    else {
      const a = r.children;
      is(a, (c) => {
        t.visibleWrites = a9(t.visibleWrites, On(r.path, c));
      });
    }
    return !0;
  } else return !1;
}
function p9e(t, e) {
  if (t.snap)
    return Qi(t.path, e);
  for (const n in t.children)
    if (t.children.hasOwnProperty(n) && Qi(On(t.path, n), e))
      return !0;
  return !1;
}
function _9e(t) {
  t.visibleWrites = pae(t.allWrites, m9e, dt()), t.allWrites.length > 0 ? t.lastWriteId = t.allWrites[t.allWrites.length - 1].writeId : t.lastWriteId = -1;
}
function m9e(t) {
  return t.visible;
}
function pae(t, e, n) {
  let r = Go.empty();
  for (let s = 0; s < t.length; ++s) {
    const i = t[s];
    if (e(i)) {
      const o = i.path;
      let a;
      if (i.snap)
        Qi(n, o) ? (a = Ds(n, o), r = fw(r, a, i.snap)) : Qi(o, n) && (a = Ds(o, n), r = fw(r, dt(), i.snap.getChild(a)));
      else if (i.children) {
        if (Qi(n, o))
          a = Ds(n, o), r = uF(r, a, i.children);
        else if (Qi(o, n))
          if (a = Ds(o, n), Be(a))
            r = uF(r, dt(), i.children);
          else {
            const c = Bm(i.children, Re(a));
            if (c) {
              const l = c.getChild(sn(a));
              r = fw(r, dt(), l);
            }
          }
      } else
        throw yg("WriteRecord should have .snap or .children");
    }
  }
  return r;
}
function _ae(t, e, n, r, s) {
  if (!r && !s) {
    const i = vp(t.visibleWrites, e);
    if (i != null)
      return i;
    {
      const o = hh(t.visibleWrites, e);
      if (dF(o))
        return n;
      if (n == null && !hF(o, dt()))
        return null;
      {
        const a = n || he.EMPTY_NODE;
        return zm(o, a);
      }
    }
  } else {
    const i = hh(t.visibleWrites, e);
    if (!s && dF(i))
      return n;
    if (!s && n == null && !hF(i, dt()))
      return null;
    {
      const o = function(l) {
        return (l.visible || s) && (!r || !~r.indexOf(l.writeId)) && (Qi(l.path, e) || Qi(e, l.path));
      }, a = pae(t.allWrites, o, e), c = n || he.EMPTY_NODE;
      return zm(a, c);
    }
  }
}
function g9e(t, e, n) {
  let r = he.EMPTY_NODE;
  const s = vp(t.visibleWrites, e);
  if (s)
    return s.isLeafNode() || s.forEachChild(Fn, (i, o) => {
      r = r.updateImmediateChild(i, o);
    }), r;
  if (n) {
    const i = hh(t.visibleWrites, e);
    return n.forEachChild(Fn, (o, a) => {
      const c = zm(hh(i, new Ut(o)), a);
      r = r.updateImmediateChild(o, c);
    }), c9(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  } else {
    const i = hh(t.visibleWrites, e);
    return c9(i).forEach((o) => {
      r = r.updateImmediateChild(o.name, o.node);
    }), r;
  }
}
function y9e(t, e, n, r, s) {
  A(r || s, "Either existingEventSnap or existingServerSnap must exist");
  const i = On(e, n);
  if (hF(t.visibleWrites, i))
    return null;
  {
    const o = hh(t.visibleWrites, i);
    return dF(o) ? s.getChild(n) : zm(o, s.getChild(n));
  }
}
function v9e(t, e, n, r) {
  const s = On(e, n), i = vp(t.visibleWrites, s);
  if (i != null)
    return i;
  if (r.isCompleteForChild(n)) {
    const o = hh(t.visibleWrites, s);
    return zm(o, r.getNode().getImmediateChild(n));
  } else
    return null;
}
function w9e(t, e) {
  return vp(t.visibleWrites, e);
}
function C9e(t, e, n, r, s, i, o) {
  let a;
  const c = hh(t.visibleWrites, e), l = vp(c, dt());
  if (l != null)
    a = l;
  else if (n != null)
    a = zm(c, n);
  else
    return [];
  if (a = a.withIndex(o), !a.isEmpty() && !a.isLeafNode()) {
    const h = [], u = o.getCompare(), d = i ? a.getReverseIteratorFrom(r, o) : a.getIteratorFrom(r, o);
    let f = d.getNext();
    for (; f && h.length < s; )
      u(f, r) !== 0 && h.push(f), f = d.getNext();
    return h;
  } else
    return [];
}
function T9e() {
  return {
    visibleWrites: Go.empty(),
    allWrites: [],
    lastWriteId: -1
  };
}
function vk(t, e, n, r) {
  return _ae(t.writeTree, t.treePath, e, n, r);
}
function IH(t, e) {
  return g9e(t.writeTree, t.treePath, e);
}
function l9(t, e, n, r) {
  return y9e(t.writeTree, t.treePath, e, n, r);
}
function wk(t, e) {
  return w9e(t.writeTree, On(t.treePath, e));
}
function b9e(t, e, n, r, s, i) {
  return C9e(t.writeTree, t.treePath, e, n, r, s, i);
}
function RH(t, e, n) {
  return v9e(t.writeTree, t.treePath, e, n);
}
function mae(t, e) {
  return gae(On(t.treePath, e), t.writeTree);
}
function gae(t, e) {
  return {
    treePath: t,
    writeTree: e
  };
}
class E9e {
  constructor() {
    this.changeMap = /* @__PURE__ */ new Map();
  }
  trackChildChange(e) {
    const n = e.type, r = e.childName;
    A(n === "child_added" || n === "child_changed" || n === "child_removed", "Only child changes supported for tracking"), A(r !== ".priority", "Only non-priority child changes can be tracked.");
    const s = this.changeMap.get(r);
    if (s) {
      const i = s.type;
      if (n === "child_added" && i === "child_removed")
        this.changeMap.set(r, WC(r, e.snapshotNode, s.snapshotNode));
      else if (n === "child_removed" && i === "child_added")
        this.changeMap.delete(r);
      else if (n === "child_removed" && i === "child_changed")
        this.changeMap.set(r, YC(r, s.oldSnap));
      else if (n === "child_changed" && i === "child_added")
        this.changeMap.set(r, jm(r, e.snapshotNode));
      else if (n === "child_changed" && i === "child_changed")
        this.changeMap.set(r, WC(r, e.snapshotNode, s.oldSnap));
      else
        throw yg("Illegal combination of changes: " + e + " occurred after " + s);
    } else
      this.changeMap.set(r, e);
  }
  getChanges() {
    return Array.from(this.changeMap.values());
  }
}
class S9e {
  getCompleteChild(e) {
    return null;
  }
  getChildAfterChild(e, n, r) {
    return null;
  }
}
const yae = new S9e();
class kH {
  constructor(e, n, r = null) {
    this.writes_ = e, this.viewCache_ = n, this.optCompleteServerCache_ = r;
  }
  getCompleteChild(e) {
    const n = this.viewCache_.eventCache;
    if (n.isCompleteForChild(e))
      return n.getNode().getImmediateChild(e);
    {
      const r = this.optCompleteServerCache_ != null ? new jh(this.optCompleteServerCache_, !0, !1) : this.viewCache_.serverCache;
      return RH(this.writes_, e, r);
    }
  }
  getChildAfterChild(e, n, r) {
    const s = this.optCompleteServerCache_ != null ? this.optCompleteServerCache_ : sp(this.viewCache_), i = b9e(this.writes_, s, n, 1, r, e);
    return i.length === 0 ? null : i[0];
  }
}
function I9e(t) {
  return { filter: t };
}
function R9e(t, e) {
  A(e.eventCache.getNode().isIndexed(t.filter.getIndex()), "Event snap not indexed"), A(e.serverCache.getNode().isIndexed(t.filter.getIndex()), "Server snap not indexed");
}
function k9e(t, e, n, r, s) {
  const i = new E9e();
  let o, a;
  if (n.type === Mo.OVERWRITE) {
    const l = n;
    l.source.fromUser ? o = fF(t, e, l.path, l.snap, r, s, i) : (A(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered() && !Be(l.path), o = Ck(t, e, l.path, l.snap, r, s, a, i));
  } else if (n.type === Mo.MERGE) {
    const l = n;
    l.source.fromUser ? o = P9e(t, e, l.path, l.children, r, s, i) : (A(l.source.fromServer, "Unknown source."), a = l.source.tagged || e.serverCache.isFiltered(), o = pF(t, e, l.path, l.children, r, s, a, i));
  } else if (n.type === Mo.ACK_USER_WRITE) {
    const l = n;
    l.revert ? o = A9e(t, e, l.path, r, s, i) : o = O9e(t, e, l.path, l.affectedTree, r, s, i);
  } else if (n.type === Mo.LISTEN_COMPLETE)
    o = D9e(t, e, n.path, r, i);
  else
    throw yg("Unknown operation type: " + n.type);
  const c = i.getChanges();
  return N9e(e, o, c), { viewCache: o, changes: c };
}
function N9e(t, e, n) {
  const r = e.eventCache;
  if (r.isFullyInitialized()) {
    const s = r.getNode().isLeafNode() || r.getNode().isEmpty(), i = yk(t);
    (n.length > 0 || !t.eventCache.isFullyInitialized() || s && !r.getNode().equals(i) || !r.getNode().getPriority().equals(i.getPriority())) && n.push(uae(yk(e)));
  }
}
function vae(t, e, n, r, s, i) {
  const o = e.eventCache;
  if (wk(r, n) != null)
    return e;
  {
    let a, c;
    if (Be(n))
      if (A(e.serverCache.isFullyInitialized(), "If change path is empty, we must have complete server data"), e.serverCache.isFiltered()) {
        const l = sp(e), h = l instanceof he ? l : he.EMPTY_NODE, u = IH(r, h);
        a = t.filter.updateFullNode(e.eventCache.getNode(), u, i);
      } else {
        const l = vk(r, sp(e));
        a = t.filter.updateFullNode(e.eventCache.getNode(), l, i);
      }
    else {
      const l = Re(n);
      if (l === ".priority") {
        A(Hh(n) === 1, "Can't have a priority with additional path components");
        const h = o.getNode();
        c = e.serverCache.getNode();
        const u = l9(r, n, h, c);
        u != null ? a = t.filter.updatePriority(h, u) : a = o.getNode();
      } else {
        const h = sn(n);
        let u;
        if (o.isCompleteForChild(l)) {
          c = e.serverCache.getNode();
          const d = l9(r, n, o.getNode(), c);
          d != null ? u = o.getNode().getImmediateChild(l).updateChild(h, d) : u = o.getNode().getImmediateChild(l);
        } else
          u = RH(r, l, e.serverCache);
        u != null ? a = t.filter.updateChild(o.getNode(), l, u, h, s, i) : a = o.getNode();
      }
    }
    return dw(e, a, o.isFullyInitialized() || Be(n), t.filter.filtersNodes());
  }
}
function Ck(t, e, n, r, s, i, o, a) {
  const c = e.serverCache;
  let l;
  const h = o ? t.filter : t.filter.getIndexedFilter();
  if (Be(n))
    l = h.updateFullNode(c.getNode(), r, null);
  else if (h.filtersNodes() && !c.isFiltered()) {
    const f = c.getNode().updateChild(n, r);
    l = h.updateFullNode(c.getNode(), f, null);
  } else {
    const f = Re(n);
    if (!c.isCompleteForPath(n) && Hh(n) > 1)
      return e;
    const p = sn(n), _ = c.getNode().getImmediateChild(f).updateChild(p, r);
    f === ".priority" ? l = h.updatePriority(c.getNode(), _) : l = h.updateChild(c.getNode(), f, _, p, yae, null);
  }
  const u = dae(e, l, c.isFullyInitialized() || Be(n), h.filtersNodes()), d = new kH(s, u, i);
  return vae(t, u, n, s, d, a);
}
function fF(t, e, n, r, s, i, o) {
  const a = e.eventCache;
  let c, l;
  const h = new kH(s, e, i);
  if (Be(n))
    l = t.filter.updateFullNode(e.eventCache.getNode(), r, o), c = dw(e, l, !0, t.filter.filtersNodes());
  else {
    const u = Re(n);
    if (u === ".priority")
      l = t.filter.updatePriority(e.eventCache.getNode(), r), c = dw(e, l, a.isFullyInitialized(), a.isFiltered());
    else {
      const d = sn(n), f = a.getNode().getImmediateChild(u);
      let p;
      if (Be(d))
        p = r;
      else {
        const _ = h.getCompleteChild(u);
        _ != null ? gH(d) === ".priority" && _.getChild(rae(d)).isEmpty() ? p = _ : p = _.updateChild(d, r) : p = he.EMPTY_NODE;
      }
      if (f.equals(p))
        c = e;
      else {
        const _ = t.filter.updateChild(a.getNode(), u, p, d, h, o);
        c = dw(e, _, a.isFullyInitialized(), t.filter.filtersNodes());
      }
    }
  }
  return c;
}
function u9(t, e) {
  return t.eventCache.isCompleteForChild(e);
}
function P9e(t, e, n, r, s, i, o) {
  let a = e;
  return r.foreach((c, l) => {
    const h = On(n, c);
    u9(e, Re(h)) && (a = fF(t, a, h, l, s, i, o));
  }), r.foreach((c, l) => {
    const h = On(n, c);
    u9(e, Re(h)) || (a = fF(t, a, h, l, s, i, o));
  }), a;
}
function h9(t, e, n) {
  return n.foreach((r, s) => {
    e = e.updateChild(r, s);
  }), e;
}
function pF(t, e, n, r, s, i, o, a) {
  if (e.serverCache.getNode().isEmpty() && !e.serverCache.isFullyInitialized())
    return e;
  let c = e, l;
  Be(n) ? l = r : l = new Jt(null).setTree(n, r);
  const h = e.serverCache.getNode();
  return l.children.inorderTraversal((u, d) => {
    if (h.hasChild(u)) {
      const f = e.serverCache.getNode().getImmediateChild(u), p = h9(t, f, d);
      c = Ck(t, c, new Ut(u), p, s, i, o, a);
    }
  }), l.children.inorderTraversal((u, d) => {
    const f = !e.serverCache.isCompleteForChild(u) && d.value === null;
    if (!h.hasChild(u) && !f) {
      const p = e.serverCache.getNode().getImmediateChild(u), _ = h9(t, p, d);
      c = Ck(t, c, new Ut(u), _, s, i, o, a);
    }
  }), c;
}
function O9e(t, e, n, r, s, i, o) {
  if (wk(s, n) != null)
    return e;
  const a = e.serverCache.isFiltered(), c = e.serverCache;
  if (r.value != null) {
    if (Be(n) && c.isFullyInitialized() || c.isCompleteForPath(n))
      return Ck(t, e, n, c.getNode().getChild(n), s, i, a, o);
    if (Be(n)) {
      let l = new Jt(null);
      return c.getNode().forEachChild(M_, (h, u) => {
        l = l.set(new Ut(h), u);
      }), pF(t, e, n, l, s, i, a, o);
    } else
      return e;
  } else {
    let l = new Jt(null);
    return r.foreach((h, u) => {
      const d = On(n, h);
      c.isCompleteForPath(d) && (l = l.set(h, c.getNode().getChild(d)));
    }), pF(t, e, n, l, s, i, a, o);
  }
}
function D9e(t, e, n, r, s) {
  const i = e.serverCache, o = dae(e, i.getNode(), i.isFullyInitialized() || Be(n), i.isFiltered());
  return vae(t, o, n, r, yae, s);
}
function A9e(t, e, n, r, s, i) {
  let o;
  if (wk(r, n) != null)
    return e;
  {
    const a = new kH(r, e, s), c = e.eventCache.getNode();
    let l;
    if (Be(n) || Re(n) === ".priority") {
      let h;
      if (e.serverCache.isFullyInitialized())
        h = vk(r, sp(e));
      else {
        const u = e.serverCache.getNode();
        A(u instanceof he, "serverChildren would be complete if leaf node"), h = IH(r, u);
      }
      h = h, l = t.filter.updateFullNode(c, h, i);
    } else {
      const h = Re(n);
      let u = RH(r, h, e.serverCache);
      u == null && e.serverCache.isCompleteForChild(h) && (u = c.getImmediateChild(h)), u != null ? l = t.filter.updateChild(c, h, u, sn(n), a, i) : e.eventCache.getNode().hasChild(h) ? l = t.filter.updateChild(c, h, he.EMPTY_NODE, sn(n), a, i) : l = c, l.isEmpty() && e.serverCache.isFullyInitialized() && (o = vk(r, sp(e)), o.isLeafNode() && (l = t.filter.updateFullNode(l, o, i)));
    }
    return o = e.serverCache.isFullyInitialized() || wk(r, dt()) != null, dw(e, l, o, t.filter.filtersNodes());
  }
}
class M9e {
  constructor(e, n) {
    this.query_ = e, this.eventRegistrations_ = [];
    const r = this.query_._queryParams, s = new CH(r.getIndex()), i = Z5e(r);
    this.processor_ = I9e(i);
    const o = n.serverCache, a = n.eventCache, c = s.updateFullNode(he.EMPTY_NODE, o.getNode(), null), l = i.updateFullNode(he.EMPTY_NODE, a.getNode(), null), h = new jh(c, o.isFullyInitialized(), s.filtersNodes()), u = new jh(l, a.isFullyInitialized(), i.filtersNodes());
    this.viewCache_ = kO(u, h), this.eventGenerator_ = new i9e(this.query_);
  }
  get query() {
    return this.query_;
  }
}
function x9e(t) {
  return t.viewCache_.serverCache.getNode();
}
function L9e(t) {
  return yk(t.viewCache_);
}
function U9e(t, e) {
  const n = sp(t.viewCache_);
  return n && (t.query._queryParams.loadsAllData() || !Be(e) && !n.getImmediateChild(Re(e)).isEmpty()) ? n.getChild(e) : null;
}
function d9(t) {
  return t.eventRegistrations_.length === 0;
}
function F9e(t, e) {
  t.eventRegistrations_.push(e);
}
function f9(t, e, n) {
  const r = [];
  if (n) {
    A(e == null, "A cancel should cancel all event registrations.");
    const s = t.query._path;
    t.eventRegistrations_.forEach((i) => {
      const o = i.createCancelEvent(n, s);
      o && r.push(o);
    });
  }
  if (e) {
    let s = [];
    for (let i = 0; i < t.eventRegistrations_.length; ++i) {
      const o = t.eventRegistrations_[i];
      if (!o.matches(e))
        s.push(o);
      else if (e.hasAnyCallback()) {
        s = s.concat(t.eventRegistrations_.slice(i + 1));
        break;
      }
    }
    t.eventRegistrations_ = s;
  } else
    t.eventRegistrations_ = [];
  return r;
}
function p9(t, e, n, r) {
  e.type === Mo.MERGE && e.source.queryId !== null && (A(sp(t.viewCache_), "We should always have a full cache before handling merges"), A(yk(t.viewCache_), "Missing event cache, even though we have a server cache"));
  const s = t.viewCache_, i = k9e(t.processor_, s, e, n, r);
  return R9e(t.processor_, i.viewCache), A(i.viewCache.serverCache.isFullyInitialized() || !s.serverCache.isFullyInitialized(), "Once a server snap is complete, it should never go back"), t.viewCache_ = i.viewCache, wae(t, i.changes, i.viewCache.eventCache.getNode(), null);
}
function Y9e(t, e) {
  const n = t.viewCache_.eventCache, r = [];
  return n.getNode().isLeafNode() || n.getNode().forEachChild(Fn, (s, i) => {
    r.push(jm(s, i));
  }), n.isFullyInitialized() && r.push(uae(n.getNode())), wae(t, r, n.getNode(), e);
}
function wae(t, e, n, r) {
  const s = r ? [r] : t.eventRegistrations_;
  return o9e(t.eventGenerator_, e, n, s);
}
let Tk;
class Cae {
  constructor() {
    this.views = /* @__PURE__ */ new Map();
  }
}
function W9e(t) {
  A(!Tk, "__referenceConstructor has already been defined"), Tk = t;
}
function B9e() {
  return A(Tk, "Reference.ts has not been loaded"), Tk;
}
function q9e(t) {
  return t.views.size === 0;
}
function NH(t, e, n, r) {
  const s = e.source.queryId;
  if (s !== null) {
    const i = t.views.get(s);
    return A(i != null, "SyncTree gave us an op for an invalid query."), p9(i, e, n, r);
  } else {
    let i = [];
    for (const o of t.views.values())
      i = i.concat(p9(o, e, n, r));
    return i;
  }
}
function Tae(t, e, n, r, s) {
  const i = e._queryIdentifier, o = t.views.get(i);
  if (!o) {
    let a = vk(n, s ? r : null), c = !1;
    a ? c = !0 : r instanceof he ? (a = IH(n, r), c = !1) : (a = he.EMPTY_NODE, c = !1);
    const l = kO(new jh(a, c, !1), new jh(r, s, !1));
    return new M9e(e, l);
  }
  return o;
}
function H9e(t, e, n, r, s, i) {
  const o = Tae(t, e, r, s, i);
  return t.views.has(e._queryIdentifier) || t.views.set(e._queryIdentifier, o), F9e(o, n), Y9e(o, n);
}
function j9e(t, e, n, r) {
  const s = e._queryIdentifier, i = [];
  let o = [];
  const a = Vh(t);
  if (s === "default")
    for (const [c, l] of t.views.entries())
      o = o.concat(f9(l, n, r)), d9(l) && (t.views.delete(c), l.query._queryParams.loadsAllData() || i.push(l.query));
  else {
    const c = t.views.get(s);
    c && (o = o.concat(f9(c, n, r)), d9(c) && (t.views.delete(s), c.query._queryParams.loadsAllData() || i.push(c.query)));
  }
  return a && !Vh(t) && i.push(new (B9e())(e._repo, e._path)), { removed: i, events: o };
}
function bae(t) {
  const e = [];
  for (const n of t.views.values())
    n.query._queryParams.loadsAllData() || e.push(n);
  return e;
}
function dh(t, e) {
  let n = null;
  for (const r of t.views.values())
    n = n || U9e(r, e);
  return n;
}
function Eae(t, e) {
  if (e._queryParams.loadsAllData())
    return PO(t);
  {
    const n = e._queryIdentifier;
    return t.views.get(n);
  }
}
function Sae(t, e) {
  return Eae(t, e) != null;
}
function Vh(t) {
  return PO(t) != null;
}
function PO(t) {
  for (const e of t.views.values())
    if (e.query._queryParams.loadsAllData())
      return e;
  return null;
}
let bk;
function V9e(t) {
  A(!bk, "__referenceConstructor has already been defined"), bk = t;
}
function z9e() {
  return A(bk, "Reference.ts has not been loaded"), bk;
}
let G9e = 1;
class _9 {
  /**
   * @param listenProvider_ - Used by SyncTree to start / stop listening
   *   to server data.
   */
  constructor(e) {
    this.listenProvider_ = e, this.syncPointTree_ = new Jt(null), this.pendingWriteTree_ = T9e(), this.tagToQueryMap = /* @__PURE__ */ new Map(), this.queryToTagMap = /* @__PURE__ */ new Map();
  }
}
function Iae(t, e, n, r, s) {
  return u9e(t.pendingWriteTree_, e, n, r, s), s ? Cg(t, new rp(bH(), e, n)) : [];
}
function $9e(t, e, n, r) {
  h9e(t.pendingWriteTree_, e, n, r);
  const s = Jt.fromObject(n);
  return Cg(t, new Vm(bH(), e, s));
}
function Iu(t, e, n = !1) {
  const r = d9e(t.pendingWriteTree_, e);
  if (f9e(t.pendingWriteTree_, e)) {
    let s = new Jt(null);
    return r.snap != null ? s = s.set(dt(), !0) : is(r.children, (i) => {
      s = s.set(new Ut(i), !0);
    }), Cg(t, new gk(r.path, s, n));
  } else
    return [];
}
function Fb(t, e, n) {
  return Cg(t, new rp(EH(), e, n));
}
function K9e(t, e, n) {
  const r = Jt.fromObject(n);
  return Cg(t, new Vm(EH(), e, r));
}
function J9e(t, e) {
  return Cg(t, new qC(EH(), e));
}
function Q9e(t, e, n) {
  const r = OH(t, n);
  if (r) {
    const s = DH(r), i = s.path, o = s.queryId, a = Ds(i, e), c = new qC(SH(o), a);
    return AH(t, i, c);
  } else
    return [];
}
function Ek(t, e, n, r, s = !1) {
  const i = e._path, o = t.syncPointTree_.get(i);
  let a = [];
  if (o && (e._queryIdentifier === "default" || Sae(o, e))) {
    const c = j9e(o, e, n, r);
    q9e(o) && (t.syncPointTree_ = t.syncPointTree_.remove(i));
    const l = c.removed;
    if (a = c.events, !s) {
      const h = l.findIndex((d) => d._queryParams.loadsAllData()) !== -1, u = t.syncPointTree_.findOnPath(i, (d, f) => Vh(f));
      if (h && !u) {
        const d = t.syncPointTree_.subtree(i);
        if (!d.isEmpty()) {
          const f = e8e(d);
          for (let p = 0; p < f.length; ++p) {
            const _ = f[p], m = _.query, v = Pae(t, _);
            t.listenProvider_.startListening(pw(m), HC(t, m), v.hashFn, v.onComplete);
          }
        }
      }
      !u && l.length > 0 && !r && (h ? t.listenProvider_.stopListening(pw(e), null) : l.forEach((d) => {
        const f = t.queryToTagMap.get(OO(d));
        t.listenProvider_.stopListening(pw(d), f);
      }));
    }
    t8e(t, l);
  }
  return a;
}
function Rae(t, e, n, r) {
  const s = OH(t, r);
  if (s != null) {
    const i = DH(s), o = i.path, a = i.queryId, c = Ds(o, e), l = new rp(SH(a), c, n);
    return AH(t, o, l);
  } else
    return [];
}
function Z9e(t, e, n, r) {
  const s = OH(t, r);
  if (s) {
    const i = DH(s), o = i.path, a = i.queryId, c = Ds(o, e), l = Jt.fromObject(n), h = new Vm(SH(a), c, l);
    return AH(t, o, h);
  } else
    return [];
}
function _F(t, e, n, r = !1) {
  const s = e._path;
  let i = null, o = !1;
  t.syncPointTree_.foreachOnPath(s, (d, f) => {
    const p = Ds(d, s);
    i = i || dh(f, p), o = o || Vh(f);
  });
  let a = t.syncPointTree_.get(s);
  a ? (o = o || Vh(a), i = i || dh(a, dt())) : (a = new Cae(), t.syncPointTree_ = t.syncPointTree_.set(s, a));
  let c;
  i != null ? c = !0 : (c = !1, i = he.EMPTY_NODE, t.syncPointTree_.subtree(s).foreachChild((d, f) => {
    const p = dh(f, dt());
    p && (i = i.updateImmediateChild(d, p));
  }));
  const l = Sae(a, e);
  if (!l && !e._queryParams.loadsAllData()) {
    const d = OO(e);
    A(!t.queryToTagMap.has(d), "View does not exist, but we have a tag");
    const f = n8e();
    t.queryToTagMap.set(d, f), t.tagToQueryMap.set(f, d);
  }
  const h = NO(t.pendingWriteTree_, s);
  let u = H9e(a, e, n, h, i, c);
  if (!l && !o && !r) {
    const d = Eae(a, e);
    u = u.concat(r8e(t, e, d));
  }
  return u;
}
function PH(t, e, n) {
  const r = t.pendingWriteTree_, s = t.syncPointTree_.findOnPath(e, (i, o) => {
    const a = Ds(i, e), c = dh(o, a);
    if (c)
      return c;
  });
  return _ae(r, e, s, n, !0);
}
function X9e(t, e) {
  const n = e._path;
  let r = null;
  t.syncPointTree_.foreachOnPath(n, (l, h) => {
    const u = Ds(l, n);
    r = r || dh(h, u);
  });
  let s = t.syncPointTree_.get(n);
  s ? r = r || dh(s, dt()) : (s = new Cae(), t.syncPointTree_ = t.syncPointTree_.set(n, s));
  const i = r != null, o = i ? new jh(r, !0, !1) : null, a = NO(t.pendingWriteTree_, e._path), c = Tae(s, e, a, i ? o.getNode() : he.EMPTY_NODE, i);
  return L9e(c);
}
function Cg(t, e) {
  return kae(
    e,
    t.syncPointTree_,
    /*serverCache=*/
    null,
    NO(t.pendingWriteTree_, dt())
  );
}
function kae(t, e, n, r) {
  if (Be(t.path))
    return Nae(t, e, n, r);
  {
    const s = e.get(dt());
    n == null && s != null && (n = dh(s, dt()));
    let i = [];
    const o = Re(t.path), a = t.operationForChild(o), c = e.children.get(o);
    if (c && a) {
      const l = n ? n.getImmediateChild(o) : null, h = mae(r, o);
      i = i.concat(kae(a, c, l, h));
    }
    return s && (i = i.concat(NH(s, t, r, n))), i;
  }
}
function Nae(t, e, n, r) {
  const s = e.get(dt());
  n == null && s != null && (n = dh(s, dt()));
  let i = [];
  return e.children.inorderTraversal((o, a) => {
    const c = n ? n.getImmediateChild(o) : null, l = mae(r, o), h = t.operationForChild(o);
    h && (i = i.concat(Nae(h, a, c, l)));
  }), s && (i = i.concat(NH(s, t, r, n))), i;
}
function Pae(t, e) {
  const n = e.query, r = HC(t, n);
  return {
    hashFn: () => (x9e(e) || he.EMPTY_NODE).hash(),
    onComplete: (s) => {
      if (s === "ok")
        return r ? Q9e(t, n._path, r) : J9e(t, n._path);
      {
        const i = JKe(s, n);
        return Ek(
          t,
          n,
          /*eventRegistration*/
          null,
          i
        );
      }
    }
  };
}
function HC(t, e) {
  const n = OO(e);
  return t.queryToTagMap.get(n);
}
function OO(t) {
  return t._path.toString() + "$" + t._queryIdentifier;
}
function OH(t, e) {
  return t.tagToQueryMap.get(e);
}
function DH(t) {
  const e = t.indexOf("$");
  return A(e !== -1 && e < t.length - 1, "Bad queryKey."), {
    queryId: t.substr(e + 1),
    path: new Ut(t.substr(0, e))
  };
}
function AH(t, e, n) {
  const r = t.syncPointTree_.get(e);
  A(r, "Missing sync point for query tag that we're tracking");
  const s = NO(t.pendingWriteTree_, e);
  return NH(r, n, s, null);
}
function e8e(t) {
  return t.fold((e, n, r) => {
    if (n && Vh(n))
      return [PO(n)];
    {
      let s = [];
      return n && (s = bae(n)), is(r, (i, o) => {
        s = s.concat(o);
      }), s;
    }
  });
}
function pw(t) {
  return t._queryParams.loadsAllData() && !t._queryParams.isDefault() ? new (z9e())(t._repo, t._path) : t;
}
function t8e(t, e) {
  for (let n = 0; n < e.length; ++n) {
    const r = e[n];
    if (!r._queryParams.loadsAllData()) {
      const s = OO(r), i = t.queryToTagMap.get(s);
      t.queryToTagMap.delete(s), t.tagToQueryMap.delete(i);
    }
  }
}
function n8e() {
  return G9e++;
}
function r8e(t, e, n) {
  const r = e._path, s = HC(t, e), i = Pae(t, n), o = t.listenProvider_.startListening(pw(e), s, i.hashFn, i.onComplete), a = t.syncPointTree_.subtree(r);
  if (s)
    A(!Vh(a.value), "If we're adding a query, it shouldn't be shadowed");
  else {
    const c = a.fold((l, h, u) => {
      if (!Be(l) && h && Vh(h))
        return [PO(h).query];
      {
        let d = [];
        return h && (d = d.concat(bae(h).map((f) => f.query))), is(u, (f, p) => {
          d = d.concat(p);
        }), d;
      }
    });
    for (let l = 0; l < c.length; ++l) {
      const h = c[l];
      t.listenProvider_.stopListening(pw(h), HC(t, h));
    }
  }
  return o;
}
class MH {
  constructor(e) {
    this.node_ = e;
  }
  getImmediateChild(e) {
    const n = this.node_.getImmediateChild(e);
    return new MH(n);
  }
  node() {
    return this.node_;
  }
}
class xH {
  constructor(e, n) {
    this.syncTree_ = e, this.path_ = n;
  }
  getImmediateChild(e) {
    const n = On(this.path_, e);
    return new xH(this.syncTree_, n);
  }
  node() {
    return PH(this.syncTree_, this.path_);
  }
}
const s8e = function(t) {
  return t = t || {}, t.timestamp = t.timestamp || (/* @__PURE__ */ new Date()).getTime(), t;
}, m9 = function(t, e, n) {
  if (!t || typeof t != "object")
    return t;
  if (A(".sv" in t, "Unexpected leaf node or priority contents"), typeof t[".sv"] == "string")
    return i8e(t[".sv"], e, n);
  if (typeof t[".sv"] == "object")
    return o8e(t[".sv"], e);
  A(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
}, i8e = function(t, e, n) {
  switch (t) {
    case "timestamp":
      return n.timestamp;
    default:
      A(!1, "Unexpected server value: " + t);
  }
}, o8e = function(t, e, n) {
  t.hasOwnProperty("increment") || A(!1, "Unexpected server value: " + JSON.stringify(t, null, 2));
  const r = t.increment;
  typeof r != "number" && A(!1, "Unexpected increment value: " + r);
  const s = e.node();
  if (A(s !== null && typeof s < "u", "Expected ChildrenNode.EMPTY_NODE for nulls"), !s.isLeafNode())
    return r;
  const i = s.getValue();
  return typeof i != "number" ? r : i + r;
}, Oae = function(t, e, n, r) {
  return LH(e, new xH(n, t), r);
}, Dae = function(t, e, n) {
  return LH(t, new MH(e), n);
};
function LH(t, e, n) {
  const r = t.getPriority().val(), s = m9(r, e.getImmediateChild(".priority"), n);
  let i;
  if (t.isLeafNode()) {
    const o = t, a = m9(o.getValue(), e, n);
    return a !== o.getValue() || s !== o.getPriority().val() ? new Or(a, hr(s)) : t;
  } else {
    const o = t;
    return i = o, s !== o.getPriority().val() && (i = i.updatePriority(new Or(s))), o.forEachChild(Fn, (a, c) => {
      const l = LH(c, e.getImmediateChild(a), n);
      l !== c && (i = i.updateImmediateChild(a, l));
    }), i;
  }
}
class UH {
  /**
   * @param name - Optional name of the node.
   * @param parent - Optional parent node.
   * @param node - Optional node to wrap.
   */
  constructor(e = "", n = null, r = { children: {}, childCount: 0 }) {
    this.name = e, this.parent = n, this.node = r;
  }
}
function FH(t, e) {
  let n = e instanceof Ut ? e : new Ut(e), r = t, s = Re(n);
  for (; s !== null; ) {
    const i = Bm(r.node.children, s) || {
      children: {},
      childCount: 0
    };
    r = new UH(s, r, i), n = sn(n), s = Re(n);
  }
  return r;
}
function Tg(t) {
  return t.node.value;
}
function Aae(t, e) {
  t.node.value = e, mF(t);
}
function Mae(t) {
  return t.node.childCount > 0;
}
function a8e(t) {
  return Tg(t) === void 0 && !Mae(t);
}
function DO(t, e) {
  is(t.node.children, (n, r) => {
    e(new UH(n, t, r));
  });
}
function xae(t, e, n, r) {
  n && !r && e(t), DO(t, (s) => {
    xae(s, e, !0, r);
  }), n && r && e(t);
}
function c8e(t, e, n) {
  let r = t.parent;
  for (; r !== null; ) {
    if (e(r))
      return !0;
    r = r.parent;
  }
  return !1;
}
function Yb(t) {
  return new Ut(t.parent === null ? t.name : Yb(t.parent) + "/" + t.name);
}
function mF(t) {
  t.parent !== null && l8e(t.parent, t.name, t);
}
function l8e(t, e, n) {
  const r = a8e(n), s = Ka(t.node.children, e);
  r && s ? (delete t.node.children[e], t.node.childCount--, mF(t)) : !r && !s && (t.node.children[e] = n.node, t.node.childCount++, mF(t));
}
const u8e = /[\[\].#$\/\u0000-\u001F\u007F]/, h8e = /[\[\].#$\u0000-\u001F\u007F]/, P1 = 10 * 1024 * 1024, YH = function(t) {
  return typeof t == "string" && t.length !== 0 && !u8e.test(t);
}, Lae = function(t) {
  return typeof t == "string" && t.length !== 0 && !h8e.test(t);
}, d8e = function(t) {
  return t && (t = t.replace(/^\/*\.info(\/|$)/, "/")), Lae(t);
}, f8e = function(t) {
  return t === null || typeof t == "string" || typeof t == "number" && !dH(t) || t && typeof t == "object" && // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Ka(t, ".sv");
}, p8e = function(t, e, n, r) {
  AO(SO(t, "value"), e, n);
}, AO = function(t, e, n) {
  const r = n instanceof Ut ? new P5e(n, t) : n;
  if (e === void 0)
    throw new Error(t + "contains undefined " + Dd(r));
  if (typeof e == "function")
    throw new Error(t + "contains a function " + Dd(r) + " with contents = " + e.toString());
  if (dH(e))
    throw new Error(t + "contains " + e.toString() + " " + Dd(r));
  if (typeof e == "string" && e.length > P1 / 3 && IO(e) > P1)
    throw new Error(t + "contains a string greater than " + P1 + " utf8 bytes " + Dd(r) + " ('" + e.substring(0, 50) + "...')");
  if (e && typeof e == "object") {
    let s = !1, i = !1;
    if (is(e, (o, a) => {
      if (o === ".value")
        s = !0;
      else if (o !== ".priority" && o !== ".sv" && (i = !0, !YH(o)))
        throw new Error(t + " contains an invalid key (" + o + ") " + Dd(r) + `.  Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
      O5e(r, o), AO(t, a, r), D5e(r);
    }), s && i)
      throw new Error(t + ' contains ".value" child ' + Dd(r) + " in addition to actual children.");
  }
}, _8e = function(t, e) {
  let n, r;
  for (n = 0; n < e.length; n++) {
    r = e[n];
    const i = FC(r);
    for (let o = 0; o < i.length; o++)
      if (!(i[o] === ".priority" && o === i.length - 1) && !YH(i[o]))
        throw new Error(t + "contains an invalid key (" + i[o] + ") in path " + r.toString() + `. Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"`);
  }
  e.sort(N5e);
  let s = null;
  for (n = 0; n < e.length; n++) {
    if (r = e[n], s !== null && Qi(s, r))
      throw new Error(t + "contains a path " + s.toString() + " that is ancestor of another path " + r.toString());
    s = r;
  }
}, m8e = function(t, e, n, r) {
  const s = SO(t, "values");
  if (!(e && typeof e == "object") || Array.isArray(e))
    throw new Error(s + " must be an object containing the children to replace.");
  const i = [];
  is(e, (o, a) => {
    const c = new Ut(o);
    if (AO(s, a, On(n, c)), gH(c) === ".priority" && !f8e(a))
      throw new Error(s + "contains an invalid value for '" + c.toString() + "', which must be a valid Firebase priority (a string, finite number, server value, or null).");
    i.push(c);
  }), _8e(s, i);
}, Uae = function(t, e, n, r) {
  if (!Lae(n))
    throw new Error(SO(t, e) + 'was an invalid path = "' + n + `". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"`);
}, g8e = function(t, e, n, r) {
  n && (n = n.replace(/^\/*\.info(\/|$)/, "/")), Uae(t, e, n);
}, y8e = function(t, e) {
  if (Re(e) === ".info")
    throw new Error(t + " failed = Can't modify data under /.info/");
}, v8e = function(t, e) {
  const n = e.path.toString();
  if (typeof e.repoInfo.host != "string" || e.repoInfo.host.length === 0 || !YH(e.repoInfo.namespace) && e.repoInfo.host.split(":")[0] !== "localhost" || n.length !== 0 && !d8e(n))
    throw new Error(SO(t, "url") + `must be a valid firebase URL and the path can't contain ".", "#", "$", "[", or "]".`);
};
class w8e {
  constructor() {
    this.eventLists_ = [], this.recursionDepth_ = 0;
  }
}
function MO(t, e) {
  let n = null;
  for (let r = 0; r < e.length; r++) {
    const s = e[r], i = s.getPath();
    n !== null && !yH(i, n.path) && (t.eventLists_.push(n), n = null), n === null && (n = { events: [], path: i }), n.events.push(s);
  }
  n && t.eventLists_.push(n);
}
function Fae(t, e, n) {
  MO(t, n), Yae(t, (r) => yH(r, e));
}
function so(t, e, n) {
  MO(t, n), Yae(t, (r) => Qi(r, e) || Qi(e, r));
}
function Yae(t, e) {
  t.recursionDepth_++;
  let n = !0;
  for (let r = 0; r < t.eventLists_.length; r++) {
    const s = t.eventLists_[r];
    if (s) {
      const i = s.path;
      e(i) ? (C8e(t.eventLists_[r]), t.eventLists_[r] = null) : n = !1;
    }
  }
  n && (t.eventLists_ = []), t.recursionDepth_--;
}
function C8e(t) {
  for (let e = 0; e < t.events.length; e++) {
    const n = t.events[e];
    if (n !== null) {
      t.events[e] = null;
      const r = n.getEventRunner();
      uw && Zr("event: " + n.toString()), wg(r);
    }
  }
}
const T8e = "repo_interrupt", b8e = 25;
class E8e {
  constructor(e, n, r, s) {
    this.repoInfo_ = e, this.forceRestClient_ = n, this.authTokenProvider_ = r, this.appCheckProvider_ = s, this.dataUpdateCount = 0, this.statsListener_ = null, this.eventQueue_ = new w8e(), this.nextWriteId_ = 1, this.interceptServerDataCallback_ = null, this.onDisconnect_ = mk(), this.transactionQueueTree_ = new UH(), this.persistentConnection_ = null, this.key = this.repoInfo_.toURLString();
  }
  /**
   * @returns The URL corresponding to the root of this Firebase.
   */
  toString() {
    return (this.repoInfo_.secure ? "https://" : "http://") + this.repoInfo_.host;
  }
}
function S8e(t, e, n) {
  if (t.stats_ = _H(t.repoInfo_), t.forceRestClient_ || e5e())
    t.server_ = new _k(t.repoInfo_, (r, s, i, o) => {
      g9(t, r, s, i, o);
    }, t.authTokenProvider_, t.appCheckProvider_), setTimeout(() => y9(
      t,
      /* connectStatus= */
      !0
    ), 0);
  else {
    if (typeof n < "u" && n !== null) {
      if (typeof n != "object")
        throw new Error("Only objects are supported for option databaseAuthVariableOverride");
      try {
        yr(n);
      } catch (r) {
        throw new Error("Invalid authOverride provided: " + r);
      }
    }
    t.persistentConnection_ = new rl(t.repoInfo_, e, (r, s, i, o) => {
      g9(t, r, s, i, o);
    }, (r) => {
      y9(t, r);
    }, (r) => {
      R8e(t, r);
    }, t.authTokenProvider_, t.appCheckProvider_, n), t.server_ = t.persistentConnection_;
  }
  t.authTokenProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAuthToken(r);
  }), t.appCheckProvider_.addTokenChangeListener((r) => {
    t.server_.refreshAppCheckToken(r.token);
  }), t.statsReporter_ = i5e(t.repoInfo_, () => new s9e(t.stats_, t.server_)), t.infoData_ = new X5e(), t.infoSyncTree_ = new _9({
    startListening: (r, s, i, o) => {
      let a = [];
      const c = t.infoData_.getNode(r._path);
      return c.isEmpty() || (a = Fb(t.infoSyncTree_, r._path, c), setTimeout(() => {
        o("ok");
      }, 0)), a;
    },
    stopListening: () => {
    }
  }), WH(t, "connected", !1), t.serverSyncTree_ = new _9({
    startListening: (r, s, i, o) => (t.server_.listen(r, i, s, (a, c) => {
      const l = o(a, c);
      so(t.eventQueue_, r._path, l);
    }), []),
    stopListening: (r, s) => {
      t.server_.unlisten(r, s);
    }
  });
}
function I8e(t) {
  const e = t.infoData_.getNode(new Ut(".info/serverTimeOffset")).val() || 0;
  return (/* @__PURE__ */ new Date()).getTime() + e;
}
function xO(t) {
  return s8e({
    timestamp: I8e(t)
  });
}
function g9(t, e, n, r, s) {
  t.dataUpdateCount++;
  const i = new Ut(e);
  n = t.interceptServerDataCallback_ ? t.interceptServerDataCallback_(e, n) : n;
  let o = [];
  if (s)
    if (r) {
      const c = uk(n, (l) => hr(l));
      o = Z9e(t.serverSyncTree_, i, c, s);
    } else {
      const c = hr(n);
      o = Rae(t.serverSyncTree_, i, c, s);
    }
  else if (r) {
    const c = uk(n, (l) => hr(l));
    o = K9e(t.serverSyncTree_, i, c);
  } else {
    const c = hr(n);
    o = Fb(t.serverSyncTree_, i, c);
  }
  let a = i;
  o.length > 0 && (a = Gm(t, i)), so(t.eventQueue_, a, o);
}
function y9(t, e) {
  WH(t, "connected", e), e === !1 && O8e(t);
}
function R8e(t, e) {
  is(e, (n, r) => {
    WH(t, n, r);
  });
}
function WH(t, e, n) {
  const r = new Ut("/.info/" + e), s = hr(n);
  t.infoData_.updateSnapshot(r, s);
  const i = Fb(t.infoSyncTree_, r, s);
  so(t.eventQueue_, r, i);
}
function BH(t) {
  return t.nextWriteId_++;
}
function k8e(t, e, n) {
  const r = X9e(t.serverSyncTree_, e);
  return r != null ? Promise.resolve(r) : t.server_.get(e).then((s) => {
    const i = hr(s).withIndex(e._queryParams.getIndex());
    _F(t.serverSyncTree_, e, n, !0);
    let o;
    if (e._queryParams.loadsAllData())
      o = Fb(t.serverSyncTree_, e._path, i);
    else {
      const a = HC(t.serverSyncTree_, e);
      o = Rae(t.serverSyncTree_, e._path, i, a);
    }
    return so(t.eventQueue_, e._path, o), Ek(t.serverSyncTree_, e, n, null, !0), i;
  }, (s) => (Wb(t, "get for query " + yr(e) + " failed: " + s), Promise.reject(new Error(s))));
}
function N8e(t, e, n, r, s) {
  Wb(t, "set", {
    path: e.toString(),
    value: n,
    priority: r
  });
  const i = xO(t), o = hr(n, r), a = PH(t.serverSyncTree_, e), c = Dae(o, a, i), l = BH(t), h = Iae(t.serverSyncTree_, e, c, l, !0);
  MO(t.eventQueue_, h), t.server_.put(e.toString(), o.val(
    /*export=*/
    !0
  ), (d, f) => {
    const p = d === "ok";
    p || Ys("set at " + e + " failed: " + d);
    const _ = Iu(t.serverSyncTree_, l, !p);
    so(t.eventQueue_, e, _), gF(t, s, d, f);
  });
  const u = HH(t, e);
  Gm(t, u), so(t.eventQueue_, u, []);
}
function P8e(t, e, n, r) {
  Wb(t, "update", { path: e.toString(), value: n });
  let s = !0;
  const i = xO(t), o = {};
  if (is(n, (a, c) => {
    s = !1, o[a] = Oae(On(e, a), hr(c), t.serverSyncTree_, i);
  }), s)
    Zr("update() called with empty data.  Don't do anything."), gF(t, r, "ok", void 0);
  else {
    const a = BH(t), c = $9e(t.serverSyncTree_, e, o, a);
    MO(t.eventQueue_, c), t.server_.merge(e.toString(), n, (l, h) => {
      const u = l === "ok";
      u || Ys("update at " + e + " failed: " + l);
      const d = Iu(t.serverSyncTree_, a, !u), f = d.length > 0 ? Gm(t, e) : e;
      so(t.eventQueue_, f, d), gF(t, r, l, h);
    }), is(n, (l) => {
      const h = HH(t, On(e, l));
      Gm(t, h);
    }), so(t.eventQueue_, e, []);
  }
}
function O8e(t) {
  Wb(t, "onDisconnectEvents");
  const e = xO(t), n = mk();
  lF(t.onDisconnect_, dt(), (s, i) => {
    const o = Oae(s, i, t.serverSyncTree_, e);
    hae(n, s, o);
  });
  let r = [];
  lF(n, dt(), (s, i) => {
    r = r.concat(Fb(t.serverSyncTree_, s, i));
    const o = HH(t, s);
    Gm(t, o);
  }), t.onDisconnect_ = mk(), so(t.eventQueue_, dt(), r);
}
function D8e(t, e, n) {
  let r;
  Re(e._path) === ".info" ? r = _F(t.infoSyncTree_, e, n) : r = _F(t.serverSyncTree_, e, n), Fae(t.eventQueue_, e._path, r);
}
function v9(t, e, n) {
  let r;
  Re(e._path) === ".info" ? r = Ek(t.infoSyncTree_, e, n) : r = Ek(t.serverSyncTree_, e, n), Fae(t.eventQueue_, e._path, r);
}
function A8e(t) {
  t.persistentConnection_ && t.persistentConnection_.interrupt(T8e);
}
function Wb(t, ...e) {
  let n = "";
  t.persistentConnection_ && (n = t.persistentConnection_.id + ":"), Zr(n, ...e);
}
function gF(t, e, n, r) {
  e && wg(() => {
    if (n === "ok")
      e(null);
    else {
      const s = (n || "error").toUpperCase();
      let i = s;
      r && (i += ": " + r);
      const o = new Error(i);
      o.code = s, e(o);
    }
  });
}
function Wae(t, e, n) {
  return PH(t.serverSyncTree_, e, n) || he.EMPTY_NODE;
}
function qH(t, e = t.transactionQueueTree_) {
  if (e || LO(t, e), Tg(e)) {
    const n = qae(t, e);
    A(n.length > 0, "Sending zero length transaction queue"), n.every(
      (r) => r.status === 0
      /* TransactionStatus.RUN */
    ) && M8e(t, Yb(e), n);
  } else Mae(e) && DO(e, (n) => {
    qH(t, n);
  });
}
function M8e(t, e, n) {
  const r = n.map((l) => l.currentWriteId), s = Wae(t, e, r);
  let i = s;
  const o = s.hash();
  for (let l = 0; l < n.length; l++) {
    const h = n[l];
    A(h.status === 0, "tryToSendTransactionQueue_: items in queue should all be run."), h.status = 1, h.retryCount++;
    const u = Ds(e, h.path);
    i = i.updateChild(u, h.currentOutputSnapshotRaw);
  }
  const a = i.val(!0), c = e;
  t.server_.put(c.toString(), a, (l) => {
    Wb(t, "transaction put response", {
      path: c.toString(),
      status: l
    });
    let h = [];
    if (l === "ok") {
      const u = [];
      for (let d = 0; d < n.length; d++)
        n[d].status = 2, h = h.concat(Iu(t.serverSyncTree_, n[d].currentWriteId)), n[d].onComplete && u.push(() => n[d].onComplete(null, !0, n[d].currentOutputSnapshotResolved)), n[d].unwatcher();
      LO(t, FH(t.transactionQueueTree_, e)), qH(t, t.transactionQueueTree_), so(t.eventQueue_, e, h);
      for (let d = 0; d < u.length; d++)
        wg(u[d]);
    } else {
      if (l === "datastale")
        for (let u = 0; u < n.length; u++)
          n[u].status === 3 ? n[u].status = 4 : n[u].status = 0;
      else {
        Ys("transaction at " + c.toString() + " failed: " + l);
        for (let u = 0; u < n.length; u++)
          n[u].status = 4, n[u].abortReason = l;
      }
      Gm(t, e);
    }
  }, o);
}
function Gm(t, e) {
  const n = Bae(t, e), r = Yb(n), s = qae(t, n);
  return x8e(t, s, r), r;
}
function x8e(t, e, n) {
  if (e.length === 0)
    return;
  const r = [];
  let s = [];
  const i = e.filter((o) => o.status === 0).map((o) => o.currentWriteId);
  for (let o = 0; o < e.length; o++) {
    const a = e[o], c = Ds(n, a.path);
    let l = !1, h;
    if (A(c !== null, "rerunTransactionsUnderNode_: relativePath should not be null."), a.status === 4)
      l = !0, h = a.abortReason, s = s.concat(Iu(t.serverSyncTree_, a.currentWriteId, !0));
    else if (a.status === 0)
      if (a.retryCount >= b8e)
        l = !0, h = "maxretry", s = s.concat(Iu(t.serverSyncTree_, a.currentWriteId, !0));
      else {
        const u = Wae(t, a.path, i);
        a.currentInputSnapshot = u;
        const d = e[o].update(u.val());
        if (d !== void 0) {
          AO("transaction failed: Data returned ", d, a.path);
          let f = hr(d);
          typeof d == "object" && d != null && Ka(d, ".priority") || (f = f.updatePriority(u.getPriority()));
          const p = a.currentWriteId, _ = xO(t), m = Dae(f, u, _);
          a.currentOutputSnapshotRaw = f, a.currentOutputSnapshotResolved = m, a.currentWriteId = BH(t), i.splice(i.indexOf(p), 1), s = s.concat(Iae(t.serverSyncTree_, a.path, m, a.currentWriteId, a.applyLocally)), s = s.concat(Iu(t.serverSyncTree_, p, !0));
        } else
          l = !0, h = "nodata", s = s.concat(Iu(t.serverSyncTree_, a.currentWriteId, !0));
      }
    so(t.eventQueue_, n, s), s = [], l && (e[o].status = 2, function(u) {
      setTimeout(u, Math.floor(0));
    }(e[o].unwatcher), e[o].onComplete && (h === "nodata" ? r.push(() => e[o].onComplete(null, !1, e[o].currentInputSnapshot)) : r.push(() => e[o].onComplete(new Error(h), !1, null))));
  }
  LO(t, t.transactionQueueTree_);
  for (let o = 0; o < r.length; o++)
    wg(r[o]);
  qH(t, t.transactionQueueTree_);
}
function Bae(t, e) {
  let n, r = t.transactionQueueTree_;
  for (n = Re(e); n !== null && Tg(r) === void 0; )
    r = FH(r, n), e = sn(e), n = Re(e);
  return r;
}
function qae(t, e) {
  const n = [];
  return Hae(t, e, n), n.sort((r, s) => r.order - s.order), n;
}
function Hae(t, e, n) {
  const r = Tg(e);
  if (r)
    for (let s = 0; s < r.length; s++)
      n.push(r[s]);
  DO(e, (s) => {
    Hae(t, s, n);
  });
}
function LO(t, e) {
  const n = Tg(e);
  if (n) {
    let r = 0;
    for (let s = 0; s < n.length; s++)
      n[s].status !== 2 && (n[r] = n[s], r++);
    n.length = r, Aae(e, n.length > 0 ? n : void 0);
  }
  DO(e, (r) => {
    LO(t, r);
  });
}
function HH(t, e) {
  const n = Yb(Bae(t, e)), r = FH(t.transactionQueueTree_, e);
  return c8e(r, (s) => {
    O1(t, s);
  }), O1(t, r), xae(r, (s) => {
    O1(t, s);
  }), n;
}
function O1(t, e) {
  const n = Tg(e);
  if (n) {
    const r = [];
    let s = [], i = -1;
    for (let o = 0; o < n.length; o++)
      n[o].status === 3 || (n[o].status === 1 ? (A(i === o - 1, "All SENT items should be at beginning of queue."), i = o, n[o].status = 3, n[o].abortReason = "set") : (A(n[o].status === 0, "Unexpected transaction status in abort"), n[o].unwatcher(), s = s.concat(Iu(t.serverSyncTree_, n[o].currentWriteId, !0)), n[o].onComplete && r.push(n[o].onComplete.bind(null, new Error("set"), !1, null))));
    i === -1 ? Aae(e, void 0) : n.length = i + 1, so(t.eventQueue_, Yb(e), s);
    for (let o = 0; o < r.length; o++)
      wg(r[o]);
  }
}
function L8e(t) {
  let e = "";
  const n = t.split("/");
  for (let r = 0; r < n.length; r++)
    if (n[r].length > 0) {
      let s = n[r];
      try {
        s = decodeURIComponent(s.replace(/\+/g, " "));
      } catch {
      }
      e += "/" + s;
    }
  return e;
}
function U8e(t) {
  const e = {};
  t.charAt(0) === "?" && (t = t.substring(1));
  for (const n of t.split("&")) {
    if (n.length === 0)
      continue;
    const r = n.split("=");
    r.length === 2 ? e[decodeURIComponent(r[0])] = decodeURIComponent(r[1]) : Ys(`Invalid query segment '${n}' in query '${t}'`);
  }
  return e;
}
const w9 = function(t, e) {
  const n = F8e(t), r = n.namespace;
  n.domain === "firebase.com" && Tl(n.host + " is no longer supported. Please use <YOUR FIREBASE>.firebaseio.com instead"), (!r || r === "undefined") && n.domain !== "localhost" && Tl("Cannot parse Firebase url. Please use https://<YOUR FIREBASE>.firebaseio.com"), n.secure || VKe();
  const s = n.scheme === "ws" || n.scheme === "wss";
  return {
    repoInfo: new $oe(
      n.host,
      n.secure,
      r,
      s,
      e,
      /*persistenceKey=*/
      "",
      /*includeNamespaceInQueryParams=*/
      r !== n.subdomain
    ),
    path: new Ut(n.pathString)
  };
}, F8e = function(t) {
  let e = "", n = "", r = "", s = "", i = "", o = !0, a = "https", c = 443;
  if (typeof t == "string") {
    let l = t.indexOf("//");
    l >= 0 && (a = t.substring(0, l - 1), t = t.substring(l + 2));
    let h = t.indexOf("/");
    h === -1 && (h = t.length);
    let u = t.indexOf("?");
    u === -1 && (u = t.length), e = t.substring(0, Math.min(h, u)), h < u && (s = L8e(t.substring(h, u)));
    const d = U8e(t.substring(Math.min(t.length, u)));
    l = e.indexOf(":"), l >= 0 ? (o = a === "https" || a === "wss", c = parseInt(e.substring(l + 1), 10)) : l = e.length;
    const f = e.slice(0, l);
    if (f.toLowerCase() === "localhost")
      n = "localhost";
    else if (f.split(".").length <= 2)
      n = f;
    else {
      const p = e.indexOf(".");
      r = e.substring(0, p).toLowerCase(), n = e.substring(p + 1), i = r;
    }
    "ns" in d && (i = d.ns);
  }
  return {
    host: e,
    port: c,
    domain: n,
    subdomain: r,
    secure: o,
    scheme: a,
    pathString: s,
    namespace: i
  };
};
class Y8e {
  /**
   * @param eventType - One of: value, child_added, child_changed, child_moved, child_removed
   * @param eventRegistration - The function to call to with the event data. User provided
   * @param snapshot - The data backing the event
   * @param prevName - Optional, the name of the previous child for child_* events.
   */
  constructor(e, n, r, s) {
    this.eventType = e, this.eventRegistration = n, this.snapshot = r, this.prevName = s;
  }
  getPath() {
    const e = this.snapshot.ref;
    return this.eventType === "value" ? e._path : e.parent._path;
  }
  getEventType() {
    return this.eventType;
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.getPath().toString() + ":" + this.eventType + ":" + yr(this.snapshot.exportVal());
  }
}
class W8e {
  constructor(e, n, r) {
    this.eventRegistration = e, this.error = n, this.path = r;
  }
  getPath() {
    return this.path;
  }
  getEventType() {
    return "cancel";
  }
  getEventRunner() {
    return this.eventRegistration.getEventRunner(this);
  }
  toString() {
    return this.path.toString() + ":cancel";
  }
}
class jae {
  constructor(e, n) {
    this.snapshotCallback = e, this.cancelCallback = n;
  }
  onValue(e, n) {
    this.snapshotCallback.call(null, e, n);
  }
  onCancel(e) {
    return A(this.hasCancelCallback, "Raising a cancel event on a listener with no cancel callback"), this.cancelCallback.call(null, e);
  }
  get hasCancelCallback() {
    return !!this.cancelCallback;
  }
  matches(e) {
    return this.snapshotCallback === e.snapshotCallback || this.snapshotCallback.userCallback !== void 0 && this.snapshotCallback.userCallback === e.snapshotCallback.userCallback && this.snapshotCallback.context === e.snapshotCallback.context;
  }
}
class jH {
  /**
   * @hideconstructor
   */
  constructor(e, n, r, s) {
    this._repo = e, this._path = n, this._queryParams = r, this._orderByCalled = s;
  }
  get key() {
    return Be(this._path) ? null : gH(this._path);
  }
  get ref() {
    return new Bl(this._repo, this._path);
  }
  get _queryIdentifier() {
    const e = i9(this._queryParams), n = fH(e);
    return n === "{}" ? "default" : n;
  }
  /**
   * An object representation of the query parameters used by this Query.
   */
  get _queryObject() {
    return i9(this._queryParams);
  }
  isEqual(e) {
    if (e = io(e), !(e instanceof jH))
      return !1;
    const n = this._repo === e._repo, r = yH(this._path, e._path), s = this._queryIdentifier === e._queryIdentifier;
    return n && r && s;
  }
  toJSON() {
    return this.toString();
  }
  toString() {
    return this._repo.toString() + k5e(this._path);
  }
}
class Bl extends jH {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n, new TH(), !1);
  }
  get parent() {
    const e = rae(this._path);
    return e === null ? null : new Bl(this._repo, e);
  }
  get root() {
    let e = this;
    for (; e.parent !== null; )
      e = e.parent;
    return e;
  }
}
class jC {
  /**
   * @param _node - A SnapshotNode to wrap.
   * @param ref - The location this snapshot came from.
   * @param _index - The iteration order for this snapshot
   * @hideconstructor
   */
  constructor(e, n, r) {
    this._node = e, this.ref = n, this._index = r;
  }
  /**
   * Gets the priority value of the data in this `DataSnapshot`.
   *
   * Applications need not use priority but can order collections by
   * ordinary properties (see
   * {@link https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data |Sorting and filtering data}
   * ).
   */
  get priority() {
    return this._node.getPriority().val();
  }
  /**
   * The key (last part of the path) of the location of this `DataSnapshot`.
   *
   * The last token in a Database location is considered its key. For example,
   * "ada" is the key for the /users/ada/ node. Accessing the key on any
   * `DataSnapshot` will return the key for the location that generated it.
   * However, accessing the key on the root URL of a Database will return
   * `null`.
   */
  get key() {
    return this.ref.key;
  }
  /** Returns the number of child properties of this `DataSnapshot`. */
  get size() {
    return this._node.numChildren();
  }
  /**
   * Gets another `DataSnapshot` for the location at the specified relative path.
   *
   * Passing a relative path to the `child()` method of a DataSnapshot returns
   * another `DataSnapshot` for the location at the specified relative path. The
   * relative path can either be a simple child name (for example, "ada") or a
   * deeper, slash-separated path (for example, "ada/name/first"). If the child
   * location has no data, an empty `DataSnapshot` (that is, a `DataSnapshot`
   * whose value is `null`) is returned.
   *
   * @param path - A relative path to the location of child data.
   */
  child(e) {
    const n = new Ut(e), r = yF(this.ref, e);
    return new jC(this._node.getChild(n), r, Fn);
  }
  /**
   * Returns true if this `DataSnapshot` contains any data. It is slightly more
   * efficient than using `snapshot.val() !== null`.
   */
  exists() {
    return !this._node.isEmpty();
  }
  /**
   * Exports the entire contents of the DataSnapshot as a JavaScript object.
   *
   * The `exportVal()` method is similar to `val()`, except priority information
   * is included (if available), making it suitable for backing up your data.
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportVal() {
    return this._node.val(!0);
  }
  /**
   * Enumerates the top-level children in the `DataSnapshot`.
   *
   * Because of the way JavaScript objects work, the ordering of data in the
   * JavaScript object returned by `val()` is not guaranteed to match the
   * ordering on the server nor the ordering of `onChildAdded()` events. That is
   * where `forEach()` comes in handy. It guarantees the children of a
   * `DataSnapshot` will be iterated in their query order.
   *
   * If no explicit `orderBy*()` method is used, results are returned
   * ordered by key (unless priorities are used, in which case, results are
   * returned by priority).
   *
   * @param action - A function that will be called for each child DataSnapshot.
   * The callback can return true to cancel further enumeration.
   * @returns true if enumeration was canceled due to your callback returning
   * true.
   */
  forEach(e) {
    return this._node.isLeafNode() ? !1 : !!this._node.forEachChild(this._index, (n, r) => e(new jC(r, yF(this.ref, n), Fn)));
  }
  /**
   * Returns true if the specified child path has (non-null) data.
   *
   * @param path - A relative path to the location of a potential child.
   * @returns `true` if data exists at the specified child path; else
   *  `false`.
   */
  hasChild(e) {
    const n = new Ut(e);
    return !this._node.getChild(n).isEmpty();
  }
  /**
   * Returns whether or not the `DataSnapshot` has any non-`null` child
   * properties.
   *
   * You can use `hasChildren()` to determine if a `DataSnapshot` has any
   * children. If it does, you can enumerate them using `forEach()`. If it
   * doesn't, then either this snapshot contains a primitive value (which can be
   * retrieved with `val()`) or it is empty (in which case, `val()` will return
   * `null`).
   *
   * @returns true if this snapshot has any children; else false.
   */
  hasChildren() {
    return this._node.isLeafNode() ? !1 : !this._node.isEmpty();
  }
  /**
   * Returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return this.exportVal();
  }
  /**
   * Extracts a JavaScript value from a `DataSnapshot`.
   *
   * Depending on the data in a `DataSnapshot`, the `val()` method may return a
   * scalar type (string, number, or boolean), an array, or an object. It may
   * also return null, indicating that the `DataSnapshot` is empty (contains no
   * data).
   *
   * @returns The DataSnapshot's contents as a JavaScript value (Object,
   *   Array, string, number, boolean, or `null`).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val() {
    return this._node.val();
  }
}
function cS(t, e) {
  return t = io(t), t._checkNotDeleted("ref"), e !== void 0 ? yF(t._root, e) : t._root;
}
function yF(t, e) {
  return t = io(t), Re(t._path) === null ? g8e("child", "path", e) : Uae("child", "path", e), new Bl(t._repo, On(t._path, e));
}
function B8e(t, e) {
  t = io(t), y8e("set", t._path), p8e("set", e, t._path);
  const n = new Mb();
  return N8e(
    t._repo,
    t._path,
    e,
    /*priority=*/
    null,
    n.wrapCallback(() => {
    })
  ), n.promise;
}
function q8e(t, e) {
  m8e("update", e, t._path);
  const n = new Mb();
  return P8e(t._repo, t._path, e, n.wrapCallback(() => {
  })), n.promise;
}
function H8e(t) {
  t = io(t);
  const e = new jae(() => {
  }), n = new UO(e);
  return k8e(t._repo, t, n).then((r) => new jC(r, new Bl(t._repo, t._path), t._queryParams.getIndex()));
}
class UO {
  constructor(e) {
    this.callbackContext = e;
  }
  respondsTo(e) {
    return e === "value";
  }
  createEvent(e, n) {
    const r = n._queryParams.getIndex();
    return new Y8e("value", this, new jC(e.snapshotNode, new Bl(n._repo, n._path), r));
  }
  getEventRunner(e) {
    return e.getEventType() === "cancel" ? () => this.callbackContext.onCancel(e.error) : () => this.callbackContext.onValue(e.snapshot, null);
  }
  createCancelEvent(e, n) {
    return this.callbackContext.hasCancelCallback ? new W8e(this, e, n) : null;
  }
  matches(e) {
    return e instanceof UO ? !e.callbackContext || !this.callbackContext ? !0 : e.callbackContext.matches(this.callbackContext) : !1;
  }
  hasAnyCallback() {
    return this.callbackContext !== null;
  }
}
function j8e(t, e, n, r, s) {
  let i;
  if (typeof r == "object" && (i = void 0, s = r), typeof r == "function" && (i = r), s && s.onlyOnce) {
    const c = n, l = (h, u) => {
      v9(t._repo, t, a), c(h, u);
    };
    l.userCallback = n.userCallback, l.context = n.context, n = l;
  }
  const o = new jae(n, i || void 0), a = new UO(o);
  return D8e(t._repo, t, a), () => v9(t._repo, t, a);
}
function V8e(t, e, n, r) {
  return j8e(t, "value", e, n, r);
}
function z8e(t, ...e) {
  let n = io(t);
  for (const r of e)
    n = r._apply(n);
  return n;
}
W9e(Bl);
V9e(Bl);
const G8e = "FIREBASE_DATABASE_EMULATOR_HOST", vF = {};
let $8e = !1;
function K8e(t, e, n, r) {
  t.repoInfo_ = new $oe(
    `${e}:${n}`,
    /* secure= */
    !1,
    t.repoInfo_.namespace,
    t.repoInfo_.webSocketOnly,
    t.repoInfo_.nodeAdmin,
    t.repoInfo_.persistenceKey,
    t.repoInfo_.includeNamespaceInQueryParams,
    /*isUsingEmulator=*/
    !0
  ), r && (t.authTokenProvider_ = r);
}
function J8e(t, e, n, r, s) {
  let i = r || t.options.databaseURL;
  i === void 0 && (t.options.projectId || Tl("Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp()."), Zr("Using default host for project ", t.options.projectId), i = `${t.options.projectId}-default-rtdb.firebaseio.com`);
  let o = w9(i, s), a = o.repoInfo, c;
  typeof process < "u" && process.env && (c = process.env[G8e]), c ? (i = `http://${c}?ns=${a.namespace}`, o = w9(i, s), a = o.repoInfo) : o.repoInfo.secure;
  const l = new n5e(t.name, t.options, e);
  v8e("Invalid Firebase Database URL", o), Be(o.path) || Tl("Database URL must point to the root of a Firebase Database (not including a child path).");
  const h = Z8e(a, t, l, new t5e(t.name, n));
  return new X8e(h, t);
}
function Q8e(t, e) {
  const n = vF[e];
  (!n || n[t.key] !== t) && Tl(`Database ${e}(${t.repoInfo_}) has already been deleted.`), A8e(t), delete n[t.key];
}
function Z8e(t, e, n, r) {
  let s = vF[e.name];
  s || (s = {}, vF[e.name] = s);
  let i = s[t.toURLString()];
  return i && Tl("Database initialized multiple times. Please make sure the format of the database URL matches with each database() call."), i = new E8e(t, $8e, n, r), s[t.toURLString()] = i, i;
}
class X8e {
  /** @hideconstructor */
  constructor(e, n) {
    this._repoInternal = e, this.app = n, this.type = "database", this._instanceStarted = !1;
  }
  get _repo() {
    return this._instanceStarted || (S8e(this._repoInternal, this.app.options.appId, this.app.options.databaseAuthVariableOverride), this._instanceStarted = !0), this._repoInternal;
  }
  get _root() {
    return this._rootInternal || (this._rootInternal = new Bl(this._repo, dt())), this._rootInternal;
  }
  _delete() {
    return this._rootInternal !== null && (Q8e(this._repo, this.app.name), this._repoInternal = null, this._rootInternal = null), Promise.resolve();
  }
  _checkNotDeleted(e) {
    this._rootInternal === null && Tl("Cannot call " + e + " on a deleted database.");
  }
}
function e7e(t = Ooe(), e) {
  const n = Noe(t, "database").getImmediate({
    identifier: e
  });
  if (!n._instanceStarted) {
    const r = woe("database");
    r && t7e(n, ...r);
  }
  return n;
}
function t7e(t, e, n, r = {}) {
  t = io(t), t._checkNotDeleted("useEmulator"), t._instanceStarted && Tl("Cannot call useEmulator() after instance has already been initialized.");
  const s = t._repoInternal;
  let i;
  if (s.repoInfo_.nodeAdmin)
    r.mockUserToken && Tl('mockUserToken is not supported by the Admin SDK. For client access with mock users, please use the "firebase" package instead of "firebase-admin".'), i = new l0(l0.OWNER);
  else if (r.mockUserToken) {
    const o = typeof r.mockUserToken == "string" ? r.mockUserToken : Coe(r.mockUserToken, t.app.options.projectId);
    i = new l0(o);
  }
  K8e(s, e, n, i);
}
function n7e(t) {
  YKe(Poe), xC(new qm(
    "database",
    (e, { instanceIdentifier: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("auth-internal"), i = e.getProvider("app-check-internal");
      return J8e(r, s, i, n);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), sf(q5, H5, t), sf(q5, H5, "esm2017");
}
rl.prototype.simpleListen = function(t, e) {
  this.sendRequest("q", { p: t }, e);
};
rl.prototype.echo = function(t, e) {
  this.sendRequest("echo", { d: t }, e);
};
n7e();
const Vae = "firebasestorage.googleapis.com", zae = "storageBucket", r7e = 2 * 60 * 1e3, s7e = 10 * 60 * 1e3;
class Zn extends vg {
  /**
   * @param code - A `StorageErrorCode` string to be prefixed with 'storage/' and
   *  added to the end of the message.
   * @param message  - Error message.
   * @param status_ - Corresponding HTTP Status Code
   */
  constructor(e, n, r = 0) {
    super(D1(e), `Firebase Storage: ${n} (${D1(e)})`), this.status_ = r, this.customData = { serverResponse: null }, this._baseMessage = this.message, Object.setPrototypeOf(this, Zn.prototype);
  }
  get status() {
    return this.status_;
  }
  set status(e) {
    this.status_ = e;
  }
  /**
   * Compares a `StorageErrorCode` against this error's code, filtering out the prefix.
   */
  _codeEquals(e) {
    return D1(e) === this.code;
  }
  /**
   * Optional response message that was added by the server.
   */
  get serverResponse() {
    return this.customData.serverResponse;
  }
  set serverResponse(e) {
    this.customData.serverResponse = e, this.customData.serverResponse ? this.message = `${this._baseMessage}
${this.customData.serverResponse}` : this.message = this._baseMessage;
  }
}
var Vn;
(function(t) {
  t.UNKNOWN = "unknown", t.OBJECT_NOT_FOUND = "object-not-found", t.BUCKET_NOT_FOUND = "bucket-not-found", t.PROJECT_NOT_FOUND = "project-not-found", t.QUOTA_EXCEEDED = "quota-exceeded", t.UNAUTHENTICATED = "unauthenticated", t.UNAUTHORIZED = "unauthorized", t.UNAUTHORIZED_APP = "unauthorized-app", t.RETRY_LIMIT_EXCEEDED = "retry-limit-exceeded", t.INVALID_CHECKSUM = "invalid-checksum", t.CANCELED = "canceled", t.INVALID_EVENT_NAME = "invalid-event-name", t.INVALID_URL = "invalid-url", t.INVALID_DEFAULT_BUCKET = "invalid-default-bucket", t.NO_DEFAULT_BUCKET = "no-default-bucket", t.CANNOT_SLICE_BLOB = "cannot-slice-blob", t.SERVER_FILE_WRONG_SIZE = "server-file-wrong-size", t.NO_DOWNLOAD_URL = "no-download-url", t.INVALID_ARGUMENT = "invalid-argument", t.INVALID_ARGUMENT_COUNT = "invalid-argument-count", t.APP_DELETED = "app-deleted", t.INVALID_ROOT_OPERATION = "invalid-root-operation", t.INVALID_FORMAT = "invalid-format", t.INTERNAL_ERROR = "internal-error", t.UNSUPPORTED_ENVIRONMENT = "unsupported-environment";
})(Vn || (Vn = {}));
function D1(t) {
  return "storage/" + t;
}
function VH() {
  const t = "An unknown error occurred, please check the error payload for server response.";
  return new Zn(Vn.UNKNOWN, t);
}
function i7e(t) {
  return new Zn(Vn.OBJECT_NOT_FOUND, "Object '" + t + "' does not exist.");
}
function o7e(t) {
  return new Zn(Vn.QUOTA_EXCEEDED, "Quota for bucket '" + t + "' exceeded, please view quota on https://firebase.google.com/pricing/.");
}
function a7e() {
  const t = "User is not authenticated, please authenticate using Firebase Authentication and try again.";
  return new Zn(Vn.UNAUTHENTICATED, t);
}
function c7e() {
  return new Zn(Vn.UNAUTHORIZED_APP, "This app does not have permission to access Firebase Storage on this project.");
}
function l7e(t) {
  return new Zn(Vn.UNAUTHORIZED, "User does not have permission to access '" + t + "'.");
}
function u7e() {
  return new Zn(Vn.RETRY_LIMIT_EXCEEDED, "Max retry time for operation exceeded, please try again.");
}
function h7e() {
  return new Zn(Vn.CANCELED, "User canceled the upload/download.");
}
function d7e(t) {
  return new Zn(Vn.INVALID_URL, "Invalid URL '" + t + "'.");
}
function f7e(t) {
  return new Zn(Vn.INVALID_DEFAULT_BUCKET, "Invalid default bucket '" + t + "'.");
}
function p7e() {
  return new Zn(Vn.NO_DEFAULT_BUCKET, "No default bucket found. Did you set the '" + zae + "' property when initializing the app?");
}
function _7e() {
  return new Zn(Vn.CANNOT_SLICE_BLOB, "Cannot slice blob for upload. Please retry the upload.");
}
function m7e() {
  return new Zn(Vn.NO_DOWNLOAD_URL, "The given file does not have any download URLs.");
}
function g7e(t) {
  return new Zn(Vn.UNSUPPORTED_ENVIRONMENT, `${t} is missing. Make sure to install the required polyfills. See https://firebase.google.com/docs/web/environments-js-sdk#polyfills for more information.`);
}
function wF(t) {
  return new Zn(Vn.INVALID_ARGUMENT, t);
}
function Gae() {
  return new Zn(Vn.APP_DELETED, "The Firebase app was deleted.");
}
function y7e(t) {
  return new Zn(Vn.INVALID_ROOT_OPERATION, "The operation '" + t + "' cannot be performed on a root reference, create a non-root reference using child, such as .child('file.png').");
}
function _w(t, e) {
  return new Zn(Vn.INVALID_FORMAT, "String does not match format '" + t + "': " + e);
}
function Ky(t) {
  throw new Zn(Vn.INTERNAL_ERROR, "Internal error: " + t);
}
class Ii {
  constructor(e, n) {
    this.bucket = e, this.path_ = n;
  }
  get path() {
    return this.path_;
  }
  get isRoot() {
    return this.path.length === 0;
  }
  fullServerUrl() {
    const e = encodeURIComponent;
    return "/b/" + e(this.bucket) + "/o/" + e(this.path);
  }
  bucketOnlyServerUrl() {
    return "/b/" + encodeURIComponent(this.bucket) + "/o";
  }
  static makeFromBucketSpec(e, n) {
    let r;
    try {
      r = Ii.makeFromUrl(e, n);
    } catch {
      return new Ii(e, "");
    }
    if (r.path === "")
      return r;
    throw f7e(e);
  }
  static makeFromUrl(e, n) {
    let r = null;
    const s = "([A-Za-z0-9.\\-_]+)";
    function i(g) {
      g.path.charAt(g.path.length - 1) === "/" && (g.path_ = g.path_.slice(0, -1));
    }
    const o = "(/(.*))?$", a = new RegExp("^gs://" + s + o, "i"), c = { bucket: 1, path: 3 };
    function l(g) {
      g.path_ = decodeURIComponent(g.path);
    }
    const h = "v[A-Za-z0-9_]+", u = n.replace(/[.]/g, "\\."), d = "(/([^?#]*).*)?$", f = new RegExp(`^https?://${u}/${h}/b/${s}/o${d}`, "i"), p = { bucket: 1, path: 3 }, _ = n === Vae ? "(?:storage.googleapis.com|storage.cloud.google.com)" : n, m = "([^?#]*)", v = new RegExp(`^https?://${_}/${s}/${m}`, "i"), y = [
      { regex: a, indices: c, postModify: i },
      {
        regex: f,
        indices: p,
        postModify: l
      },
      {
        regex: v,
        indices: { bucket: 1, path: 2 },
        postModify: l
      }
    ];
    for (let g = 0; g < y.length; g++) {
      const w = y[g], de = w.regex.exec(e);
      if (de) {
        const we = de[w.indices.bucket];
        let fe = de[w.indices.path];
        fe || (fe = ""), r = new Ii(we, fe), w.postModify(r);
        break;
      }
    }
    if (r == null)
      throw d7e(e);
    return r;
  }
}
class v7e {
  constructor(e) {
    this.promise_ = Promise.reject(e);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e = !1) {
  }
}
function w7e(t, e, n) {
  let r = 1, s = null, i = null, o = !1, a = 0;
  function c() {
    return a === 2;
  }
  let l = !1;
  function h(...m) {
    l || (l = !0, e.apply(null, m));
  }
  function u(m) {
    s = setTimeout(() => {
      s = null, t(f, c());
    }, m);
  }
  function d() {
    i && clearTimeout(i);
  }
  function f(m, ...v) {
    if (l) {
      d();
      return;
    }
    if (m) {
      d(), h.call(null, m, ...v);
      return;
    }
    if (c() || o) {
      d(), h.call(null, m, ...v);
      return;
    }
    r < 64 && (r *= 2);
    let y;
    a === 1 ? (a = 2, y = 0) : y = (r + Math.random()) * 1e3, u(y);
  }
  let p = !1;
  function _(m) {
    p || (p = !0, d(), !l && (s !== null ? (m || (a = 2), clearTimeout(s), u(0)) : m || (a = 1)));
  }
  return u(0), i = setTimeout(() => {
    o = !0, _(!0);
  }, n), _;
}
function C7e(t) {
  t(!1);
}
function T7e(t) {
  return t !== void 0;
}
function b7e(t) {
  return typeof t == "object" && !Array.isArray(t);
}
function zH(t) {
  return typeof t == "string" || t instanceof String;
}
function C9(t) {
  return GH() && t instanceof Blob;
}
function GH() {
  return typeof Blob < "u" && !C$e();
}
function T9(t, e, n, r) {
  if (r < e)
    throw wF(`Invalid value for '${t}'. Expected ${e} or greater.`);
  if (r > n)
    throw wF(`Invalid value for '${t}'. Expected ${n} or less.`);
}
function FO(t, e, n) {
  let r = e;
  return n == null && (r = `https://${e}`), `${n}://${r}/v0${t}`;
}
function $ae(t) {
  const e = encodeURIComponent;
  let n = "?";
  for (const r in t)
    if (t.hasOwnProperty(r)) {
      const s = e(r) + "=" + e(t[r]);
      n = n + s + "&";
    }
  return n = n.slice(0, -1), n;
}
var of;
(function(t) {
  t[t.NO_ERROR = 0] = "NO_ERROR", t[t.NETWORK_ERROR = 1] = "NETWORK_ERROR", t[t.ABORT = 2] = "ABORT";
})(of || (of = {}));
function E7e(t, e) {
  const n = t >= 500 && t < 600, r = [
    // Request Timeout: web server didn't receive full request in time.
    408,
    // Too Many Requests: you're getting rate-limited, basically.
    429
  ].indexOf(t) !== -1, s = e.indexOf(t) !== -1;
  return n || r || s;
}
class S7e {
  constructor(e, n, r, s, i, o, a, c, l, h, u, d = !0) {
    this.url_ = e, this.method_ = n, this.headers_ = r, this.body_ = s, this.successCodes_ = i, this.additionalRetryCodes_ = o, this.callback_ = a, this.errorCallback_ = c, this.timeout_ = l, this.progressCallback_ = h, this.connectionFactory_ = u, this.retry = d, this.pendingConnection_ = null, this.backoffId_ = null, this.canceled_ = !1, this.appDelete_ = !1, this.promise_ = new Promise((f, p) => {
      this.resolve_ = f, this.reject_ = p, this.start_();
    });
  }
  /**
   * Actually starts the retry loop.
   */
  start_() {
    const e = (r, s) => {
      if (s) {
        r(!1, new lS(!1, null, !0));
        return;
      }
      const i = this.connectionFactory_();
      this.pendingConnection_ = i;
      const o = (a) => {
        const c = a.loaded, l = a.lengthComputable ? a.total : -1;
        this.progressCallback_ !== null && this.progressCallback_(c, l);
      };
      this.progressCallback_ !== null && i.addUploadProgressListener(o), i.send(this.url_, this.method_, this.body_, this.headers_).then(() => {
        this.progressCallback_ !== null && i.removeUploadProgressListener(o), this.pendingConnection_ = null;
        const a = i.getErrorCode() === of.NO_ERROR, c = i.getStatus();
        if (!a || E7e(c, this.additionalRetryCodes_) && this.retry) {
          const h = i.getErrorCode() === of.ABORT;
          r(!1, new lS(!1, null, h));
          return;
        }
        const l = this.successCodes_.indexOf(c) !== -1;
        r(!0, new lS(l, i));
      });
    }, n = (r, s) => {
      const i = this.resolve_, o = this.reject_, a = s.connection;
      if (s.wasSuccessCode)
        try {
          const c = this.callback_(a, a.getResponse());
          T7e(c) ? i(c) : i();
        } catch (c) {
          o(c);
        }
      else if (a !== null) {
        const c = VH();
        c.serverResponse = a.getErrorText(), this.errorCallback_ ? o(this.errorCallback_(a, c)) : o(c);
      } else if (s.canceled) {
        const c = this.appDelete_ ? Gae() : h7e();
        o(c);
      } else {
        const c = u7e();
        o(c);
      }
    };
    this.canceled_ ? n(!1, new lS(!1, null, !0)) : this.backoffId_ = w7e(e, n, this.timeout_);
  }
  /** @inheritDoc */
  getPromise() {
    return this.promise_;
  }
  /** @inheritDoc */
  cancel(e) {
    this.canceled_ = !0, this.appDelete_ = e || !1, this.backoffId_ !== null && C7e(this.backoffId_), this.pendingConnection_ !== null && this.pendingConnection_.abort();
  }
}
class lS {
  constructor(e, n, r) {
    this.wasSuccessCode = e, this.connection = n, this.canceled = !!r;
  }
}
function I7e(t, e) {
  e !== null && e.length > 0 && (t.Authorization = "Firebase " + e);
}
function R7e(t, e) {
  t["X-Firebase-Storage-Version"] = "webjs/" + (e ?? "AppManager");
}
function k7e(t, e) {
  e && (t["X-Firebase-GMPID"] = e);
}
function N7e(t, e) {
  e !== null && (t["X-Firebase-AppCheck"] = e);
}
function P7e(t, e, n, r, s, i, o = !0) {
  const a = $ae(t.urlParams), c = t.url + a, l = Object.assign({}, t.headers);
  return k7e(l, e), I7e(l, n), R7e(l, i), N7e(l, r), new S7e(c, t.method, l, t.body, t.successCodes, t.additionalRetryCodes, t.handler, t.errorHandler, t.timeout, t.progressCallback, s, o);
}
function O7e() {
  return typeof BlobBuilder < "u" ? BlobBuilder : typeof WebKitBlobBuilder < "u" ? WebKitBlobBuilder : void 0;
}
function D7e(...t) {
  const e = O7e();
  if (e !== void 0) {
    const n = new e();
    for (let r = 0; r < t.length; r++)
      n.append(t[r]);
    return n.getBlob();
  } else {
    if (GH())
      return new Blob(t);
    throw new Zn(Vn.UNSUPPORTED_ENVIRONMENT, "This browser doesn't seem to support creating Blobs");
  }
}
function A7e(t, e, n) {
  return t.webkitSlice ? t.webkitSlice(e, n) : t.mozSlice ? t.mozSlice(e, n) : t.slice ? t.slice(e, n) : null;
}
function M7e(t) {
  if (typeof atob > "u")
    throw g7e("base-64");
  return atob(t);
}
const Pa = {
  /**
   * Indicates the string should be interpreted "raw", that is, as normal text.
   * The string will be interpreted as UTF-16, then uploaded as a UTF-8 byte
   * sequence.
   * Example: The string 'Hello! \\ud83d\\ude0a' becomes the byte sequence
   * 48 65 6c 6c 6f 21 20 f0 9f 98 8a
   */
  RAW: "raw",
  /**
   * Indicates the string should be interpreted as base64-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO++E6t7/rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64: "base64",
  /**
   * Indicates the string should be interpreted as base64url-encoded data.
   * Padding characters (trailing '='s) are optional.
   * Example: The string 'rWmO--E6t7_rlw==' becomes the byte sequence
   * ad 69 8e fb e1 3a b7 bf eb 97
   */
  BASE64URL: "base64url",
  /**
   * Indicates the string is a data URL, such as one obtained from
   * canvas.toDataURL().
   * Example: the string 'data:application/octet-stream;base64,aaaa'
   * becomes the byte sequence
   * 69 a6 9a
   * (the content-type "application/octet-stream" is also applied, but can
   * be overridden in the metadata object).
   */
  DATA_URL: "data_url"
};
class A1 {
  constructor(e, n) {
    this.data = e, this.contentType = n || null;
  }
}
function x7e(t, e) {
  switch (t) {
    case Pa.RAW:
      return new A1(Kae(e));
    case Pa.BASE64:
    case Pa.BASE64URL:
      return new A1(Jae(t, e));
    case Pa.DATA_URL:
      return new A1(U7e(e), F7e(e));
  }
  throw VH();
}
function Kae(t) {
  const e = [];
  for (let n = 0; n < t.length; n++) {
    let r = t.charCodeAt(n);
    if (r <= 127)
      e.push(r);
    else if (r <= 2047)
      e.push(192 | r >> 6, 128 | r & 63);
    else if ((r & 64512) === 55296)
      if (!(n < t.length - 1 && (t.charCodeAt(n + 1) & 64512) === 56320))
        e.push(239, 191, 189);
      else {
        const s = r, i = t.charCodeAt(++n);
        r = 65536 | (s & 1023) << 10 | i & 1023, e.push(240 | r >> 18, 128 | r >> 12 & 63, 128 | r >> 6 & 63, 128 | r & 63);
      }
    else
      (r & 64512) === 56320 ? e.push(239, 191, 189) : e.push(224 | r >> 12, 128 | r >> 6 & 63, 128 | r & 63);
  }
  return new Uint8Array(e);
}
function L7e(t) {
  let e;
  try {
    e = decodeURIComponent(t);
  } catch {
    throw _w(Pa.DATA_URL, "Malformed data URL.");
  }
  return Kae(e);
}
function Jae(t, e) {
  switch (t) {
    case Pa.BASE64: {
      const s = e.indexOf("-") !== -1, i = e.indexOf("_") !== -1;
      if (s || i)
        throw _w(t, "Invalid character '" + (s ? "-" : "_") + "' found: is it base64url encoded?");
      break;
    }
    case Pa.BASE64URL: {
      const s = e.indexOf("+") !== -1, i = e.indexOf("/") !== -1;
      if (s || i)
        throw _w(t, "Invalid character '" + (s ? "+" : "/") + "' found: is it base64 encoded?");
      e = e.replace(/-/g, "+").replace(/_/g, "/");
      break;
    }
  }
  let n;
  try {
    n = M7e(e);
  } catch (s) {
    throw s.message.includes("polyfill") ? s : _w(t, "Invalid character found");
  }
  const r = new Uint8Array(n.length);
  for (let s = 0; s < n.length; s++)
    r[s] = n.charCodeAt(s);
  return r;
}
class Qae {
  constructor(e) {
    this.base64 = !1, this.contentType = null;
    const n = e.match(/^data:([^,]+)?,/);
    if (n === null)
      throw _w(Pa.DATA_URL, "Must be formatted 'data:[<mediatype>][;base64],<data>");
    const r = n[1] || null;
    r != null && (this.base64 = Y7e(r, ";base64"), this.contentType = this.base64 ? r.substring(0, r.length - 7) : r), this.rest = e.substring(e.indexOf(",") + 1);
  }
}
function U7e(t) {
  const e = new Qae(t);
  return e.base64 ? Jae(Pa.BASE64, e.rest) : L7e(e.rest);
}
function F7e(t) {
  return new Qae(t).contentType;
}
function Y7e(t, e) {
  return t.length >= e.length ? t.substring(t.length - e.length) === e : !1;
}
class vu {
  constructor(e, n) {
    let r = 0, s = "";
    C9(e) ? (this.data_ = e, r = e.size, s = e.type) : e instanceof ArrayBuffer ? (n ? this.data_ = new Uint8Array(e) : (this.data_ = new Uint8Array(e.byteLength), this.data_.set(new Uint8Array(e))), r = this.data_.length) : e instanceof Uint8Array && (n ? this.data_ = e : (this.data_ = new Uint8Array(e.length), this.data_.set(e)), r = e.length), this.size_ = r, this.type_ = s;
  }
  size() {
    return this.size_;
  }
  type() {
    return this.type_;
  }
  slice(e, n) {
    if (C9(this.data_)) {
      const r = this.data_, s = A7e(r, e, n);
      return s === null ? null : new vu(s);
    } else {
      const r = new Uint8Array(this.data_.buffer, e, n - e);
      return new vu(r, !0);
    }
  }
  static getBlob(...e) {
    if (GH()) {
      const n = e.map((r) => r instanceof vu ? r.data_ : r);
      return new vu(D7e.apply(null, n));
    } else {
      const n = e.map((o) => zH(o) ? x7e(Pa.RAW, o).data : o.data_);
      let r = 0;
      n.forEach((o) => {
        r += o.byteLength;
      });
      const s = new Uint8Array(r);
      let i = 0;
      return n.forEach((o) => {
        for (let a = 0; a < o.length; a++)
          s[i++] = o[a];
      }), new vu(s, !0);
    }
  }
  uploadData() {
    return this.data_;
  }
}
function Zae(t) {
  let e;
  try {
    e = JSON.parse(t);
  } catch {
    return null;
  }
  return b7e(e) ? e : null;
}
function W7e(t) {
  if (t.length === 0)
    return null;
  const e = t.lastIndexOf("/");
  return e === -1 ? "" : t.slice(0, e);
}
function B7e(t, e) {
  const n = e.split("/").filter((r) => r.length > 0).join("/");
  return t.length === 0 ? n : t + "/" + n;
}
function Xae(t) {
  const e = t.lastIndexOf("/", t.length - 2);
  return e === -1 ? t : t.slice(e + 1);
}
function q7e(t, e) {
  return e;
}
class Ss {
  constructor(e, n, r, s) {
    this.server = e, this.local = n || e, this.writable = !!r, this.xform = s || q7e;
  }
}
let uS = null;
function H7e(t) {
  return !zH(t) || t.length < 2 ? t : Xae(t);
}
function ece() {
  if (uS)
    return uS;
  const t = [];
  t.push(new Ss("bucket")), t.push(new Ss("generation")), t.push(new Ss("metageneration")), t.push(new Ss("name", "fullPath", !0));
  function e(i, o) {
    return H7e(o);
  }
  const n = new Ss("name");
  n.xform = e, t.push(n);
  function r(i, o) {
    return o !== void 0 ? Number(o) : o;
  }
  const s = new Ss("size");
  return s.xform = r, t.push(s), t.push(new Ss("timeCreated")), t.push(new Ss("updated")), t.push(new Ss("md5Hash", null, !0)), t.push(new Ss("cacheControl", null, !0)), t.push(new Ss("contentDisposition", null, !0)), t.push(new Ss("contentEncoding", null, !0)), t.push(new Ss("contentLanguage", null, !0)), t.push(new Ss("contentType", null, !0)), t.push(new Ss("metadata", "customMetadata", !0)), uS = t, uS;
}
function j7e(t, e) {
  function n() {
    const r = t.bucket, s = t.fullPath, i = new Ii(r, s);
    return e._makeStorageReference(i);
  }
  Object.defineProperty(t, "ref", { get: n });
}
function V7e(t, e, n) {
  const r = {};
  r.type = "file";
  const s = n.length;
  for (let i = 0; i < s; i++) {
    const o = n[i];
    r[o.local] = o.xform(r, e[o.server]);
  }
  return j7e(r, t), r;
}
function tce(t, e, n) {
  const r = Zae(e);
  return r === null ? null : V7e(t, r, n);
}
function z7e(t, e, n, r) {
  const s = Zae(e);
  if (s === null || !zH(s.downloadTokens))
    return null;
  const i = s.downloadTokens;
  if (i.length === 0)
    return null;
  const o = encodeURIComponent;
  return i.split(",").map((a) => {
    const c = t.bucket, l = t.fullPath, h = "/b/" + o(c) + "/o/" + o(l), u = FO(h, n, r), d = $ae({
      alt: "media",
      token: a
    });
    return u + d;
  })[0];
}
function G7e(t, e) {
  const n = {}, r = e.length;
  for (let s = 0; s < r; s++) {
    const i = e[s];
    i.writable && (n[i.server] = t[i.local]);
  }
  return JSON.stringify(n);
}
class $H {
  constructor(e, n, r, s) {
    this.url = e, this.method = n, this.handler = r, this.timeout = s, this.urlParams = {}, this.headers = {}, this.body = null, this.errorHandler = null, this.progressCallback = null, this.successCodes = [200], this.additionalRetryCodes = [];
  }
}
function nce(t) {
  if (!t)
    throw VH();
}
function $7e(t, e) {
  function n(r, s) {
    const i = tce(t, s, e);
    return nce(i !== null), i;
  }
  return n;
}
function K7e(t, e) {
  function n(r, s) {
    const i = tce(t, s, e);
    return nce(i !== null), z7e(i, s, t.host, t._protocol);
  }
  return n;
}
function rce(t) {
  function e(n, r) {
    let s;
    return n.getStatus() === 401 ? (
      /* This exact message string is the only consistent part of the */
      /* server's error response that identifies it as an App Check error. */
      n.getErrorText().includes("Firebase App Check token is invalid") ? s = c7e() : s = a7e()
    ) : n.getStatus() === 402 ? s = o7e(t.bucket) : n.getStatus() === 403 ? s = l7e(t.path) : s = r, s.status = n.getStatus(), s.serverResponse = r.serverResponse, s;
  }
  return e;
}
function sce(t) {
  const e = rce(t);
  function n(r, s) {
    let i = e(r, s);
    return r.getStatus() === 404 && (i = i7e(t.path)), i.serverResponse = s.serverResponse, i;
  }
  return n;
}
function J7e(t, e, n) {
  const r = e.fullServerUrl(), s = FO(r, t.host, t._protocol), i = "GET", o = t.maxOperationRetryTime, a = new $H(s, i, K7e(t, n), o);
  return a.errorHandler = sce(e), a;
}
function Q7e(t, e) {
  const n = e.fullServerUrl(), r = FO(n, t.host, t._protocol), s = "DELETE", i = t.maxOperationRetryTime;
  function o(c, l) {
  }
  const a = new $H(r, s, o, i);
  return a.successCodes = [200, 204], a.errorHandler = sce(e), a;
}
function Z7e(t, e) {
  return t && t.contentType || e && e.type() || "application/octet-stream";
}
function X7e(t, e, n) {
  const r = Object.assign({}, n);
  return r.fullPath = t.path, r.size = e.size(), r.contentType || (r.contentType = Z7e(null, e)), r;
}
function eJe(t, e, n, r, s) {
  const i = e.bucketOnlyServerUrl(), o = {
    "X-Goog-Upload-Protocol": "multipart"
  };
  function a() {
    let g = "";
    for (let w = 0; w < 2; w++)
      g = g + Math.random().toString().slice(2);
    return g;
  }
  const c = a();
  o["Content-Type"] = "multipart/related; boundary=" + c;
  const l = X7e(e, r, s), h = G7e(l, n), u = "--" + c + `\r
Content-Type: application/json; charset=utf-8\r
\r
` + h + `\r
--` + c + `\r
Content-Type: ` + l.contentType + `\r
\r
`, d = `\r
--` + c + "--", f = vu.getBlob(u, r, d);
  if (f === null)
    throw _7e();
  const p = { name: l.fullPath }, _ = FO(i, t.host, t._protocol), m = "POST", v = t.maxUploadRetryTime, y = new $H(_, m, $7e(t, n), v);
  return y.urlParams = p, y.headers = o, y.body = f.uploadData(), y.errorHandler = rce(e), y;
}
class tJe {
  constructor() {
    this.sent_ = !1, this.xhr_ = new XMLHttpRequest(), this.initXhr(), this.errorCode_ = of.NO_ERROR, this.sendPromise_ = new Promise((e) => {
      this.xhr_.addEventListener("abort", () => {
        this.errorCode_ = of.ABORT, e();
      }), this.xhr_.addEventListener("error", () => {
        this.errorCode_ = of.NETWORK_ERROR, e();
      }), this.xhr_.addEventListener("load", () => {
        e();
      });
    });
  }
  send(e, n, r, s) {
    if (this.sent_)
      throw Ky("cannot .send() more than once");
    if (this.sent_ = !0, this.xhr_.open(n, e, !0), s !== void 0)
      for (const i in s)
        s.hasOwnProperty(i) && this.xhr_.setRequestHeader(i, s[i].toString());
    return r !== void 0 ? this.xhr_.send(r) : this.xhr_.send(), this.sendPromise_;
  }
  getErrorCode() {
    if (!this.sent_)
      throw Ky("cannot .getErrorCode() before sending");
    return this.errorCode_;
  }
  getStatus() {
    if (!this.sent_)
      throw Ky("cannot .getStatus() before sending");
    try {
      return this.xhr_.status;
    } catch {
      return -1;
    }
  }
  getResponse() {
    if (!this.sent_)
      throw Ky("cannot .getResponse() before sending");
    return this.xhr_.response;
  }
  getErrorText() {
    if (!this.sent_)
      throw Ky("cannot .getErrorText() before sending");
    return this.xhr_.statusText;
  }
  /** Aborts the request. */
  abort() {
    this.xhr_.abort();
  }
  getResponseHeader(e) {
    return this.xhr_.getResponseHeader(e);
  }
  addUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.addEventListener("progress", e);
  }
  removeUploadProgressListener(e) {
    this.xhr_.upload != null && this.xhr_.upload.removeEventListener("progress", e);
  }
}
class nJe extends tJe {
  initXhr() {
    this.xhr_.responseType = "text";
  }
}
function KH() {
  return new nJe();
}
class ip {
  constructor(e, n) {
    this._service = e, n instanceof Ii ? this._location = n : this._location = Ii.makeFromUrl(n, e.host);
  }
  /**
   * Returns the URL for the bucket and path this object references,
   *     in the form gs://<bucket>/<object-path>
   * @override
   */
  toString() {
    return "gs://" + this._location.bucket + "/" + this._location.path;
  }
  _newRef(e, n) {
    return new ip(e, n);
  }
  /**
   * A reference to the root of this object's bucket.
   */
  get root() {
    const e = new Ii(this._location.bucket, "");
    return this._newRef(this._service, e);
  }
  /**
   * The name of the bucket containing this reference's object.
   */
  get bucket() {
    return this._location.bucket;
  }
  /**
   * The full path of this object.
   */
  get fullPath() {
    return this._location.path;
  }
  /**
   * The short name of this object, which is the last component of the full path.
   * For example, if fullPath is 'full/path/image.png', name is 'image.png'.
   */
  get name() {
    return Xae(this._location.path);
  }
  /**
   * The `StorageService` instance this `StorageReference` is associated with.
   */
  get storage() {
    return this._service;
  }
  /**
   * A `StorageReference` pointing to the parent location of this `StorageReference`, or null if
   * this reference is the root.
   */
  get parent() {
    const e = W7e(this._location.path);
    if (e === null)
      return null;
    const n = new Ii(this._location.bucket, e);
    return new ip(this._service, n);
  }
  /**
   * Utility function to throw an error in methods that do not accept a root reference.
   */
  _throwIfRoot(e) {
    if (this._location.path === "")
      throw y7e(e);
  }
}
function rJe(t, e, n) {
  t._throwIfRoot("uploadBytes");
  const r = eJe(t.storage, t._location, ece(), new vu(e, !0), n);
  return t.storage.makeRequestWithTokens(r, KH).then((s) => ({
    metadata: s,
    ref: t
  }));
}
function sJe(t) {
  t._throwIfRoot("getDownloadURL");
  const e = J7e(t.storage, t._location, ece());
  return t.storage.makeRequestWithTokens(e, KH).then((n) => {
    if (n === null)
      throw m7e();
    return n;
  });
}
function iJe(t) {
  t._throwIfRoot("deleteObject");
  const e = Q7e(t.storage, t._location);
  return t.storage.makeRequestWithTokens(e, KH);
}
function oJe(t, e) {
  const n = B7e(t._location.path, e), r = new Ii(t._location.bucket, n);
  return new ip(t.storage, r);
}
function aJe(t) {
  return /^[A-Za-z]+:\/\//.test(t);
}
function cJe(t, e) {
  return new ip(t, e);
}
function ice(t, e) {
  if (t instanceof JH) {
    const n = t;
    if (n._bucket == null)
      throw p7e();
    const r = new ip(n, n._bucket);
    return e != null ? ice(r, e) : r;
  } else
    return e !== void 0 ? oJe(t, e) : t;
}
function lJe(t, e) {
  if (e && aJe(e)) {
    if (t instanceof JH)
      return cJe(t, e);
    throw wF("To use ref(service, url), the first argument must be a Storage instance.");
  } else
    return ice(t, e);
}
function b9(t, e) {
  const n = e == null ? void 0 : e[zae];
  return n == null ? null : Ii.makeFromBucketSpec(n, t);
}
function uJe(t, e, n, r = {}) {
  t.host = `${e}:${n}`, t._protocol = "http";
  const { mockUserToken: s } = r;
  s && (t._overrideAuthToken = typeof s == "string" ? s : Coe(s, t.app.options.projectId));
}
class JH {
  constructor(e, n, r, s, i) {
    this.app = e, this._authProvider = n, this._appCheckProvider = r, this._url = s, this._firebaseVersion = i, this._bucket = null, this._host = Vae, this._protocol = "https", this._appId = null, this._deleted = !1, this._maxOperationRetryTime = r7e, this._maxUploadRetryTime = s7e, this._requests = /* @__PURE__ */ new Set(), s != null ? this._bucket = Ii.makeFromBucketSpec(s, this._host) : this._bucket = b9(this._host, this.app.options);
  }
  /**
   * The host string for this service, in the form of `host` or
   * `host:port`.
   */
  get host() {
    return this._host;
  }
  set host(e) {
    this._host = e, this._url != null ? this._bucket = Ii.makeFromBucketSpec(this._url, e) : this._bucket = b9(e, this.app.options);
  }
  /**
   * The maximum time to retry uploads in milliseconds.
   */
  get maxUploadRetryTime() {
    return this._maxUploadRetryTime;
  }
  set maxUploadRetryTime(e) {
    T9(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxUploadRetryTime = e;
  }
  /**
   * The maximum time to retry operations other than uploads or downloads in
   * milliseconds.
   */
  get maxOperationRetryTime() {
    return this._maxOperationRetryTime;
  }
  set maxOperationRetryTime(e) {
    T9(
      "time",
      /* minValue=*/
      0,
      /* maxValue= */
      Number.POSITIVE_INFINITY,
      e
    ), this._maxOperationRetryTime = e;
  }
  async _getAuthToken() {
    if (this._overrideAuthToken)
      return this._overrideAuthToken;
    const e = this._authProvider.getImmediate({ optional: !0 });
    if (e) {
      const n = await e.getToken();
      if (n !== null)
        return n.accessToken;
    }
    return null;
  }
  async _getAppCheckToken() {
    const e = this._appCheckProvider.getImmediate({ optional: !0 });
    return e ? (await e.getToken()).token : null;
  }
  /**
   * Stop running requests and prevent more from being created.
   */
  _delete() {
    return this._deleted || (this._deleted = !0, this._requests.forEach((e) => e.cancel()), this._requests.clear()), Promise.resolve();
  }
  /**
   * Returns a new firebaseStorage.Reference object referencing this StorageService
   * at the given Location.
   */
  _makeStorageReference(e) {
    return new ip(this, e);
  }
  /**
   * @param requestInfo - HTTP RequestInfo object
   * @param authToken - Firebase auth token
   */
  _makeRequest(e, n, r, s, i = !0) {
    if (this._deleted)
      return new v7e(Gae());
    {
      const o = P7e(e, this._appId, r, s, n, this._firebaseVersion, i);
      return this._requests.add(o), o.getPromise().then(() => this._requests.delete(o), () => this._requests.delete(o)), o;
    }
  }
  async makeRequestWithTokens(e, n) {
    const [r, s] = await Promise.all([
      this._getAuthToken(),
      this._getAppCheckToken()
    ]);
    return this._makeRequest(e, n, r, s).getPromise();
  }
}
const E9 = "@firebase/storage", S9 = "0.11.2", oce = "storage";
function hJe(t, e, n) {
  return t = io(t), rJe(t, e, n);
}
function dJe(t) {
  return t = io(t), sJe(t);
}
function fJe(t) {
  return t = io(t), iJe(t);
}
function M1(t, e) {
  return t = io(t), lJe(t, e);
}
function pJe(t = Ooe(), e) {
  t = io(t);
  const n = Noe(t, oce).getImmediate({
    identifier: e
  }), r = woe("storage");
  return r && _Je(n, ...r), n;
}
function _Je(t, e, n, r = {}) {
  uJe(t, e, n, r);
}
function mJe(t, { instanceIdentifier: e }) {
  const n = t.getProvider("app").getImmediate(), r = t.getProvider("auth-internal"), s = t.getProvider("app-check-internal");
  return new JH(n, r, s, e, Poe);
}
function gJe() {
  xC(new qm(
    oce,
    mJe,
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setMultipleInstances(!0)), sf(E9, S9, ""), sf(E9, S9, "esm2017");
}
gJe();
class yJe {
  constructor() {
    this.db = e7e(), this.storage = pJe();
  }
  async get(e) {
    let n = await H8e(cS(this.db, e));
    return n.exists() ? n.val() : null;
  }
  async update(e, n) {
    return await q8e(cS(this.db, e), n);
  }
  async set(e, n) {
    return await B8e(cS(this.db, e), n);
  }
  async watch(e, n, r) {
    return V8e(z8e(cS(this.db, e)), n, r);
  }
  encodeKey(e) {
    return e.replace(
      /[\/\.\$\[\]#!]/g,
      (n) => `!${n.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }
  decodeKey(e) {
    return e.replace(/!([0-9a-f]{2})/gi, (n, r) => String.fromCharCode(parseInt(r, 16)));
  }
  async uploadObject(e, n, r) {
    return await hJe(M1(this.storage, e), n, r);
  }
  async deleteObject(e) {
    return await fJe(M1(this.storage, e));
  }
  async objectUrl(e) {
    return await dJe(M1(this.storage, e));
  }
}
class vJe extends yJe {
  constructor(e) {
    super(), this.auth = e, this.unwatch = null, this.optin = !1, this.email = null, this.status = null;
  }
  async watchPreferences(e, n, r) {
    return console.log("Watching mailing list preferences"), this.unwatch = await this.watch(Jp.Preferences(e), async (s) => {
      let i = s.val();
      i && (this.optin = i.optin, this.email = i.email, this.status = i.status, n(i));
    }, r), this.unwatchPreferences;
  }
  unwatchPreferences() {
    this.unwatch && (this.unwatch(), this.unwatch = null);
  }
  async setOptIn(e) {
    return await this.set(Jp.OptIn(this.uid), e);
  }
  async setEmail(e) {
    return await this.set(Jp.Email(this.uid), e);
  }
  async getOptIn() {
    return await this.get(Jp.OptIn(this.uid));
  }
  async getEmail() {
    return await this.get(Jp.Email(this.uid));
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
}
var u0 = { exports: {} };
function wJe(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
u0.exports = wJe;
const CJe = (u0.exports == null ? {} : u0.exports).default || u0.exports;
var h0 = { exports: {} };
function TJe(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
h0.exports = TJe;
const bJe = (h0.exports == null ? {} : h0.exports).default || h0.exports;
var d0 = { exports: {} };
const EJe = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
d0.exports = EJe;
const SJe = (d0.exports == null ? {} : d0.exports).default || d0.exports;
var f0 = { exports: {} };
function IJe(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
f0.exports = IJe;
const RJe = (f0.exports == null ? {} : f0.exports).default || f0.exports;
var p0 = { exports: {} };
class kJe {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var NJe = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new kJe(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
p0.exports = NJe;
const PJe = (p0.exports == null ? {} : p0.exports).default || p0.exports, OJe = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Analytics: QGe,
  BillingResources: c$e,
  CatalogFirebaseResources: e$e,
  CatalogHostedProvider: o$e,
  CatalogHostedResources: Fi,
  CatalogPromotions: l$e,
  CatalogPropertyMap: ga,
  DataAccessLayer: _oe,
  Environment: D_,
  MailingListProvider: vJe,
  MailingListResources: Jp,
  PurchaseResources: a$e,
  RequestResources: cH,
  allMap: ZU,
  allSettledMap: ZU,
  cyrb53: CJe,
  empty: bJe,
  isEmpty: SJe,
  isNumeric: RJe,
  resizeImage: PJe,
  splitProduct: a0,
  templ8r: Z
}, Symbol.toStringTag, { value: "Module" }));
var ace;
function M() {
  return ace.apply(null, arguments);
}
function DJe(t) {
  ace = t;
}
function $o(t) {
  return t instanceof Array || Object.prototype.toString.call(t) === "[object Array]";
}
function af(t) {
  return t != null && Object.prototype.toString.call(t) === "[object Object]";
}
function ft(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function QH(t) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(t).length === 0;
  var e;
  for (e in t)
    if (ft(t, e))
      return !1;
  return !0;
}
function ei(t) {
  return t === void 0;
}
function bl(t) {
  return typeof t == "number" || Object.prototype.toString.call(t) === "[object Number]";
}
function Bb(t) {
  return t instanceof Date || Object.prototype.toString.call(t) === "[object Date]";
}
function cce(t, e) {
  var n = [], r, s = t.length;
  for (r = 0; r < s; ++r)
    n.push(e(t[r], r));
  return n;
}
function Ru(t, e) {
  for (var n in e)
    ft(e, n) && (t[n] = e[n]);
  return ft(e, "toString") && (t.toString = e.toString), ft(e, "valueOf") && (t.valueOf = e.valueOf), t;
}
function Ja(t, e, n, r) {
  return Oce(t, e, n, r, !0).utc();
}
function AJe() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function pe(t) {
  return t._pf == null && (t._pf = AJe()), t._pf;
}
var CF;
Array.prototype.some ? CF = Array.prototype.some : CF = function(t) {
  var e = Object(this), n = e.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in e && t.call(this, e[r], r, e))
      return !0;
  return !1;
};
function ZH(t) {
  var e = null, n = !1, r = t._d && !isNaN(t._d.getTime());
  if (r && (e = pe(t), n = CF.call(e.parsedDateParts, function(s) {
    return s != null;
  }), r = e.overflow < 0 && !e.empty && !e.invalidEra && !e.invalidMonth && !e.invalidWeekday && !e.weekdayMismatch && !e.nullInput && !e.invalidFormat && !e.userInvalidated && (!e.meridiem || e.meridiem && n), t._strict && (r = r && e.charsLeftOver === 0 && e.unusedTokens.length === 0 && e.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(t))
    t._isValid = r;
  else
    return r;
  return t._isValid;
}
function YO(t) {
  var e = Ja(NaN);
  return t != null ? Ru(pe(e), t) : pe(e).userInvalidated = !0, e;
}
var I9 = M.momentProperties = [], x1 = !1;
function XH(t, e) {
  var n, r, s, i = I9.length;
  if (ei(e._isAMomentObject) || (t._isAMomentObject = e._isAMomentObject), ei(e._i) || (t._i = e._i), ei(e._f) || (t._f = e._f), ei(e._l) || (t._l = e._l), ei(e._strict) || (t._strict = e._strict), ei(e._tzm) || (t._tzm = e._tzm), ei(e._isUTC) || (t._isUTC = e._isUTC), ei(e._offset) || (t._offset = e._offset), ei(e._pf) || (t._pf = pe(e)), ei(e._locale) || (t._locale = e._locale), i > 0)
    for (n = 0; n < i; n++)
      r = I9[n], s = e[r], ei(s) || (t[r] = s);
  return t;
}
function qb(t) {
  XH(this, t), this._d = new Date(t._d != null ? t._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), x1 === !1 && (x1 = !0, M.updateOffset(this), x1 = !1);
}
function Ko(t) {
  return t instanceof qb || t != null && t._isAMomentObject != null;
}
function lce(t) {
  M.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + t);
}
function oo(t, e) {
  var n = !0;
  return Ru(function() {
    if (M.deprecationHandler != null && M.deprecationHandler(null, t), n) {
      var r = [], s, i, o, a = arguments.length;
      for (i = 0; i < a; i++) {
        if (s = "", typeof arguments[i] == "object") {
          s += `
[` + i + "] ";
          for (o in arguments[0])
            ft(arguments[0], o) && (s += o + ": " + arguments[0][o] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[i];
        r.push(s);
      }
      lce(
        t + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return e.apply(this, arguments);
  }, e);
}
var R9 = {};
function uce(t, e) {
  M.deprecationHandler != null && M.deprecationHandler(t, e), R9[t] || (lce(e), R9[t] = !0);
}
M.suppressDeprecationWarnings = !1;
M.deprecationHandler = null;
function Qa(t) {
  return typeof Function < "u" && t instanceof Function || Object.prototype.toString.call(t) === "[object Function]";
}
function MJe(t) {
  var e, n;
  for (n in t)
    ft(t, n) && (e = t[n], Qa(e) ? this[n] = e : this["_" + n] = e);
  this._config = t, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function TF(t, e) {
  var n = Ru({}, t), r;
  for (r in e)
    ft(e, r) && (af(t[r]) && af(e[r]) ? (n[r] = {}, Ru(n[r], t[r]), Ru(n[r], e[r])) : e[r] != null ? n[r] = e[r] : delete n[r]);
  for (r in t)
    ft(t, r) && !ft(e, r) && af(t[r]) && (n[r] = Ru({}, n[r]));
  return n;
}
function ej(t) {
  t != null && this.set(t);
}
var bF;
Object.keys ? bF = Object.keys : bF = function(t) {
  var e, n = [];
  for (e in t)
    ft(t, e) && n.push(e);
  return n;
};
var xJe = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function LJe(t, e, n) {
  var r = this._calendar[t] || this._calendar.sameElse;
  return Qa(r) ? r.call(e, n) : r;
}
function Ua(t, e, n) {
  var r = "" + Math.abs(t), s = e - r.length, i = t >= 0;
  return (i ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var tj = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, hS = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, L1 = {}, x_ = {};
function X(t, e, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), t && (x_[t] = s), e && (x_[e[0]] = function() {
    return Ua(s.apply(this, arguments), e[1], e[2]);
  }), n && (x_[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      t
    );
  });
}
function UJe(t) {
  return t.match(/\[[\s\S]/) ? t.replace(/^\[|\]$/g, "") : t.replace(/\\/g, "");
}
function FJe(t) {
  var e = t.match(tj), n, r;
  for (n = 0, r = e.length; n < r; n++)
    x_[e[n]] ? e[n] = x_[e[n]] : e[n] = UJe(e[n]);
  return function(s) {
    var i = "", o;
    for (o = 0; o < r; o++)
      i += Qa(e[o]) ? e[o].call(s, t) : e[o];
    return i;
  };
}
function _0(t, e) {
  return t.isValid() ? (e = hce(e, t.localeData()), L1[e] = L1[e] || FJe(e), L1[e](t)) : t.localeData().invalidDate();
}
function hce(t, e) {
  var n = 5;
  function r(s) {
    return e.longDateFormat(s) || s;
  }
  for (hS.lastIndex = 0; n >= 0 && hS.test(t); )
    t = t.replace(
      hS,
      r
    ), hS.lastIndex = 0, n -= 1;
  return t;
}
var YJe = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function WJe(t) {
  var e = this._longDateFormat[t], n = this._longDateFormat[t.toUpperCase()];
  return e || !n ? e : (this._longDateFormat[t] = n.match(tj).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[t]);
}
var BJe = "Invalid date";
function qJe() {
  return this._invalidDate;
}
var HJe = "%d", jJe = /\d{1,2}/;
function VJe(t) {
  return this._ordinal.replace("%d", t);
}
var zJe = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function GJe(t, e, n, r) {
  var s = this._relativeTime[n];
  return Qa(s) ? s(t, e, n, r) : s.replace(/%d/i, t);
}
function $Je(t, e) {
  var n = this._relativeTime[t > 0 ? "future" : "past"];
  return Qa(n) ? n(e) : n.replace(/%s/i, e);
}
var k9 = {
  D: "date",
  dates: "date",
  date: "date",
  d: "day",
  days: "day",
  day: "day",
  e: "weekday",
  weekdays: "weekday",
  weekday: "weekday",
  E: "isoWeekday",
  isoweekdays: "isoWeekday",
  isoweekday: "isoWeekday",
  DDD: "dayOfYear",
  dayofyears: "dayOfYear",
  dayofyear: "dayOfYear",
  h: "hour",
  hours: "hour",
  hour: "hour",
  ms: "millisecond",
  milliseconds: "millisecond",
  millisecond: "millisecond",
  m: "minute",
  minutes: "minute",
  minute: "minute",
  M: "month",
  months: "month",
  month: "month",
  Q: "quarter",
  quarters: "quarter",
  quarter: "quarter",
  s: "second",
  seconds: "second",
  second: "second",
  gg: "weekYear",
  weekyears: "weekYear",
  weekyear: "weekYear",
  GG: "isoWeekYear",
  isoweekyears: "isoWeekYear",
  isoweekyear: "isoWeekYear",
  w: "week",
  weeks: "week",
  week: "week",
  W: "isoWeek",
  isoweeks: "isoWeek",
  isoweek: "isoWeek",
  y: "year",
  years: "year",
  year: "year"
};
function ao(t) {
  return typeof t == "string" ? k9[t] || k9[t.toLowerCase()] : void 0;
}
function nj(t) {
  var e = {}, n, r;
  for (r in t)
    ft(t, r) && (n = ao(r), n && (e[n] = t[r]));
  return e;
}
var KJe = {
  date: 9,
  day: 11,
  weekday: 11,
  isoWeekday: 11,
  dayOfYear: 4,
  hour: 13,
  millisecond: 16,
  minute: 14,
  month: 8,
  quarter: 7,
  second: 15,
  weekYear: 1,
  isoWeekYear: 1,
  week: 5,
  isoWeek: 5,
  year: 1
};
function JJe(t) {
  var e = [], n;
  for (n in t)
    ft(t, n) && e.push({ unit: n, priority: KJe[n] });
  return e.sort(function(r, s) {
    return r.priority - s.priority;
  }), e;
}
var dce = /\d/, Ri = /\d\d/, fce = /\d{3}/, rj = /\d{4}/, WO = /[+-]?\d{6}/, dn = /\d\d?/, pce = /\d\d\d\d?/, _ce = /\d\d\d\d\d\d?/, BO = /\d{1,3}/, sj = /\d{1,4}/, qO = /[+-]?\d{1,6}/, bg = /\d+/, HO = /[+-]?\d+/, QJe = /Z|[+-]\d\d:?\d\d/gi, jO = /Z|[+-]\d\d(?::?\d\d)?/gi, ZJe = /[+-]?\d+(\.\d{1,3})?/, Hb = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, Eg = /^[1-9]\d?/, ij = /^([1-9]\d|\d)/, Sk;
Sk = {};
function W(t, e, n) {
  Sk[t] = Qa(e) ? e : function(r, s) {
    return r && n ? n : e;
  };
}
function XJe(t, e) {
  return ft(Sk, t) ? Sk[t](e._strict, e._locale) : new RegExp(eQe(t));
}
function eQe(t) {
  return sl(
    t.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(e, n, r, s, i) {
        return n || r || s || i;
      }
    )
  );
}
function sl(t) {
  return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
function Yi(t) {
  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
}
function qe(t) {
  var e = +t, n = 0;
  return e !== 0 && isFinite(e) && (n = Yi(e)), n;
}
var EF = {};
function Ft(t, e) {
  var n, r = e, s;
  for (typeof t == "string" && (t = [t]), bl(e) && (r = function(i, o) {
    o[e] = qe(i);
  }), s = t.length, n = 0; n < s; n++)
    EF[t[n]] = r;
}
function jb(t, e) {
  Ft(t, function(n, r, s, i) {
    s._w = s._w || {}, e(n, s._w, s, i);
  });
}
function tQe(t, e, n) {
  e != null && ft(EF, t) && EF[t](e, n._a, n, t);
}
function VO(t) {
  return t % 4 === 0 && t % 100 !== 0 || t % 400 === 0;
}
var ds = 0, kc = 1, ya = 2, vr = 3, xo = 4, Nc = 5, Wd = 6, nQe = 7, rQe = 8;
X("Y", 0, 0, function() {
  var t = this.year();
  return t <= 9999 ? Ua(t, 4) : "+" + t;
});
X(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
X(0, ["YYYY", 4], 0, "year");
X(0, ["YYYYY", 5], 0, "year");
X(0, ["YYYYYY", 6, !0], 0, "year");
W("Y", HO);
W("YY", dn, Ri);
W("YYYY", sj, rj);
W("YYYYY", qO, WO);
W("YYYYYY", qO, WO);
Ft(["YYYYY", "YYYYYY"], ds);
Ft("YYYY", function(t, e) {
  e[ds] = t.length === 2 ? M.parseTwoDigitYear(t) : qe(t);
});
Ft("YY", function(t, e) {
  e[ds] = M.parseTwoDigitYear(t);
});
Ft("Y", function(t, e) {
  e[ds] = parseInt(t, 10);
});
function mw(t) {
  return VO(t) ? 366 : 365;
}
M.parseTwoDigitYear = function(t) {
  return qe(t) + (qe(t) > 68 ? 1900 : 2e3);
};
var mce = Sg("FullYear", !0);
function sQe() {
  return VO(this.year());
}
function Sg(t, e) {
  return function(n) {
    return n != null ? (gce(this, t, n), M.updateOffset(this, e), this) : VC(this, t);
  };
}
function VC(t, e) {
  if (!t.isValid())
    return NaN;
  var n = t._d, r = t._isUTC;
  switch (e) {
    case "Milliseconds":
      return r ? n.getUTCMilliseconds() : n.getMilliseconds();
    case "Seconds":
      return r ? n.getUTCSeconds() : n.getSeconds();
    case "Minutes":
      return r ? n.getUTCMinutes() : n.getMinutes();
    case "Hours":
      return r ? n.getUTCHours() : n.getHours();
    case "Date":
      return r ? n.getUTCDate() : n.getDate();
    case "Day":
      return r ? n.getUTCDay() : n.getDay();
    case "Month":
      return r ? n.getUTCMonth() : n.getMonth();
    case "FullYear":
      return r ? n.getUTCFullYear() : n.getFullYear();
    default:
      return NaN;
  }
}
function gce(t, e, n) {
  var r, s, i, o, a;
  if (!(!t.isValid() || isNaN(n))) {
    switch (r = t._d, s = t._isUTC, e) {
      case "Milliseconds":
        return void (s ? r.setUTCMilliseconds(n) : r.setMilliseconds(n));
      case "Seconds":
        return void (s ? r.setUTCSeconds(n) : r.setSeconds(n));
      case "Minutes":
        return void (s ? r.setUTCMinutes(n) : r.setMinutes(n));
      case "Hours":
        return void (s ? r.setUTCHours(n) : r.setHours(n));
      case "Date":
        return void (s ? r.setUTCDate(n) : r.setDate(n));
      case "FullYear":
        break;
      default:
        return;
    }
    i = n, o = t.month(), a = t.date(), a = a === 29 && o === 1 && !VO(i) ? 28 : a, s ? r.setUTCFullYear(i, o, a) : r.setFullYear(i, o, a);
  }
}
function iQe(t) {
  return t = ao(t), Qa(this[t]) ? this[t]() : this;
}
function oQe(t, e) {
  if (typeof t == "object") {
    t = nj(t);
    var n = JJe(t), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](t[n[r].unit]);
  } else if (t = ao(t), Qa(this[t]))
    return this[t](e);
  return this;
}
function aQe(t, e) {
  return (t % e + e) % e;
}
var Xn;
Array.prototype.indexOf ? Xn = Array.prototype.indexOf : Xn = function(t) {
  var e;
  for (e = 0; e < this.length; ++e)
    if (this[e] === t)
      return e;
  return -1;
};
function oj(t, e) {
  if (isNaN(t) || isNaN(e))
    return NaN;
  var n = aQe(e, 12);
  return t += (e - n) / 12, n === 1 ? VO(t) ? 29 : 28 : 31 - n % 7 % 2;
}
X("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
X("MMM", 0, 0, function(t) {
  return this.localeData().monthsShort(this, t);
});
X("MMMM", 0, 0, function(t) {
  return this.localeData().months(this, t);
});
W("M", dn, Eg);
W("MM", dn, Ri);
W("MMM", function(t, e) {
  return e.monthsShortRegex(t);
});
W("MMMM", function(t, e) {
  return e.monthsRegex(t);
});
Ft(["M", "MM"], function(t, e) {
  e[kc] = qe(t) - 1;
});
Ft(["MMM", "MMMM"], function(t, e, n, r) {
  var s = n._locale.monthsParse(t, r, n._strict);
  s != null ? e[kc] = s : pe(n).invalidMonth = t;
});
var cQe = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), yce = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), vce = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, lQe = Hb, uQe = Hb;
function hQe(t, e) {
  return t ? $o(this._months) ? this._months[t.month()] : this._months[(this._months.isFormat || vce).test(e) ? "format" : "standalone"][t.month()] : $o(this._months) ? this._months : this._months.standalone;
}
function dQe(t, e) {
  return t ? $o(this._monthsShort) ? this._monthsShort[t.month()] : this._monthsShort[vce.test(e) ? "format" : "standalone"][t.month()] : $o(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function fQe(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      i = Ja([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        i,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(i, "").toLocaleLowerCase();
  return n ? e === "MMM" ? (s = Xn.call(this._shortMonthsParse, o), s !== -1 ? s : null) : (s = Xn.call(this._longMonthsParse, o), s !== -1 ? s : null) : e === "MMM" ? (s = Xn.call(this._shortMonthsParse, o), s !== -1 ? s : (s = Xn.call(this._longMonthsParse, o), s !== -1 ? s : null)) : (s = Xn.call(this._longMonthsParse, o), s !== -1 ? s : (s = Xn.call(this._shortMonthsParse, o), s !== -1 ? s : null));
}
function pQe(t, e, n) {
  var r, s, i;
  if (this._monthsParseExact)
    return fQe.call(this, t, e, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++)
    if (s = Ja([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (i = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "MMMM" && this._longMonthsParse[r].test(t) || n && e === "MMM" && this._shortMonthsParse[r].test(t) || !n && this._monthsParse[r].test(t))
      return r;
}
function wce(t, e) {
  if (!t.isValid())
    return t;
  if (typeof e == "string") {
    if (/^\d+$/.test(e))
      e = qe(e);
    else if (e = t.localeData().monthsParse(e), !bl(e))
      return t;
  }
  var n = e, r = t.date();
  return r = r < 29 ? r : Math.min(r, oj(t.year(), n)), t._isUTC ? t._d.setUTCMonth(n, r) : t._d.setMonth(n, r), t;
}
function Cce(t) {
  return t != null ? (wce(this, t), M.updateOffset(this, !0), this) : VC(this, "Month");
}
function _Qe() {
  return oj(this.year(), this.month());
}
function mQe(t) {
  return this._monthsParseExact ? (ft(this, "_monthsRegex") || Tce.call(this), t ? this._monthsShortStrictRegex : this._monthsShortRegex) : (ft(this, "_monthsShortRegex") || (this._monthsShortRegex = lQe), this._monthsShortStrictRegex && t ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function gQe(t) {
  return this._monthsParseExact ? (ft(this, "_monthsRegex") || Tce.call(this), t ? this._monthsStrictRegex : this._monthsRegex) : (ft(this, "_monthsRegex") || (this._monthsRegex = uQe), this._monthsStrictRegex && t ? this._monthsStrictRegex : this._monthsRegex);
}
function Tce() {
  function t(c, l) {
    return l.length - c.length;
  }
  var e = [], n = [], r = [], s, i, o, a;
  for (s = 0; s < 12; s++)
    i = Ja([2e3, s]), o = sl(this.monthsShort(i, "")), a = sl(this.months(i, "")), e.push(o), n.push(a), r.push(a), r.push(o);
  e.sort(t), n.sort(t), r.sort(t), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function yQe(t, e, n, r, s, i, o) {
  var a;
  return t < 100 && t >= 0 ? (a = new Date(t + 400, e, n, r, s, i, o), isFinite(a.getFullYear()) && a.setFullYear(t)) : a = new Date(t, e, n, r, s, i, o), a;
}
function zC(t) {
  var e, n;
  return t < 100 && t >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = t + 400, e = new Date(Date.UTC.apply(null, n)), isFinite(e.getUTCFullYear()) && e.setUTCFullYear(t)) : e = new Date(Date.UTC.apply(null, arguments)), e;
}
function Ik(t, e, n) {
  var r = 7 + e - n, s = (7 + zC(t, 0, r).getUTCDay() - e) % 7;
  return -s + r - 1;
}
function bce(t, e, n, r, s) {
  var i = (7 + n - r) % 7, o = Ik(t, r, s), a = 1 + 7 * (e - 1) + i + o, c, l;
  return a <= 0 ? (c = t - 1, l = mw(c) + a) : a > mw(t) ? (c = t + 1, l = a - mw(t)) : (c = t, l = a), {
    year: c,
    dayOfYear: l
  };
}
function GC(t, e, n) {
  var r = Ik(t.year(), e, n), s = Math.floor((t.dayOfYear() - r - 1) / 7) + 1, i, o;
  return s < 1 ? (o = t.year() - 1, i = s + il(o, e, n)) : s > il(t.year(), e, n) ? (i = s - il(t.year(), e, n), o = t.year() + 1) : (o = t.year(), i = s), {
    week: i,
    year: o
  };
}
function il(t, e, n) {
  var r = Ik(t, e, n), s = Ik(t + 1, e, n);
  return (mw(t) - r + s) / 7;
}
X("w", ["ww", 2], "wo", "week");
X("W", ["WW", 2], "Wo", "isoWeek");
W("w", dn, Eg);
W("ww", dn, Ri);
W("W", dn, Eg);
W("WW", dn, Ri);
jb(
  ["w", "ww", "W", "WW"],
  function(t, e, n, r) {
    e[r.substr(0, 1)] = qe(t);
  }
);
function vQe(t) {
  return GC(t, this._week.dow, this._week.doy).week;
}
var wQe = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function CQe() {
  return this._week.dow;
}
function TQe() {
  return this._week.doy;
}
function bQe(t) {
  var e = this.localeData().week(this);
  return t == null ? e : this.add((t - e) * 7, "d");
}
function EQe(t) {
  var e = GC(this, 1, 4).week;
  return t == null ? e : this.add((t - e) * 7, "d");
}
X("d", 0, "do", "day");
X("dd", 0, 0, function(t) {
  return this.localeData().weekdaysMin(this, t);
});
X("ddd", 0, 0, function(t) {
  return this.localeData().weekdaysShort(this, t);
});
X("dddd", 0, 0, function(t) {
  return this.localeData().weekdays(this, t);
});
X("e", 0, 0, "weekday");
X("E", 0, 0, "isoWeekday");
W("d", dn);
W("e", dn);
W("E", dn);
W("dd", function(t, e) {
  return e.weekdaysMinRegex(t);
});
W("ddd", function(t, e) {
  return e.weekdaysShortRegex(t);
});
W("dddd", function(t, e) {
  return e.weekdaysRegex(t);
});
jb(["dd", "ddd", "dddd"], function(t, e, n, r) {
  var s = n._locale.weekdaysParse(t, r, n._strict);
  s != null ? e.d = s : pe(n).invalidWeekday = t;
});
jb(["d", "e", "E"], function(t, e, n, r) {
  e[r] = qe(t);
});
function SQe(t, e) {
  return typeof t != "string" ? t : isNaN(t) ? (t = e.weekdaysParse(t), typeof t == "number" ? t : null) : parseInt(t, 10);
}
function IQe(t, e) {
  return typeof t == "string" ? e.weekdaysParse(t) % 7 || 7 : isNaN(t) ? null : t;
}
function aj(t, e) {
  return t.slice(e, 7).concat(t.slice(0, e));
}
var RQe = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), Ece = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), kQe = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), NQe = Hb, PQe = Hb, OQe = Hb;
function DQe(t, e) {
  var n = $o(this._weekdays) ? this._weekdays : this._weekdays[t && t !== !0 && this._weekdays.isFormat.test(e) ? "format" : "standalone"];
  return t === !0 ? aj(n, this._week.dow) : t ? n[t.day()] : n;
}
function AQe(t) {
  return t === !0 ? aj(this._weekdaysShort, this._week.dow) : t ? this._weekdaysShort[t.day()] : this._weekdaysShort;
}
function MQe(t) {
  return t === !0 ? aj(this._weekdaysMin, this._week.dow) : t ? this._weekdaysMin[t.day()] : this._weekdaysMin;
}
function xQe(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      i = Ja([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        i,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        i,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(i, "").toLocaleLowerCase();
  return n ? e === "dddd" ? (s = Xn.call(this._weekdaysParse, o), s !== -1 ? s : null) : e === "ddd" ? (s = Xn.call(this._shortWeekdaysParse, o), s !== -1 ? s : null) : (s = Xn.call(this._minWeekdaysParse, o), s !== -1 ? s : null) : e === "dddd" ? (s = Xn.call(this._weekdaysParse, o), s !== -1 || (s = Xn.call(this._shortWeekdaysParse, o), s !== -1) ? s : (s = Xn.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : e === "ddd" ? (s = Xn.call(this._shortWeekdaysParse, o), s !== -1 || (s = Xn.call(this._weekdaysParse, o), s !== -1) ? s : (s = Xn.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : (s = Xn.call(this._minWeekdaysParse, o), s !== -1 || (s = Xn.call(this._weekdaysParse, o), s !== -1) ? s : (s = Xn.call(this._shortWeekdaysParse, o), s !== -1 ? s : null));
}
function LQe(t, e, n) {
  var r, s, i;
  if (this._weekdaysParseExact)
    return xQe.call(this, t, e, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++)
    if (s = Ja([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (i = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "dddd" && this._fullWeekdaysParse[r].test(t) || n && e === "ddd" && this._shortWeekdaysParse[r].test(t) || n && e === "dd" && this._minWeekdaysParse[r].test(t) || !n && this._weekdaysParse[r].test(t))
      return r;
}
function UQe(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = VC(this, "Day");
  return t != null ? (t = SQe(t, this.localeData()), this.add(t - e, "d")) : e;
}
function FQe(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return t == null ? e : this.add(t - e, "d");
}
function YQe(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    var e = IQe(t, this.localeData());
    return this.day(this.day() % 7 ? e : e - 7);
  } else
    return this.day() || 7;
}
function WQe(t) {
  return this._weekdaysParseExact ? (ft(this, "_weekdaysRegex") || cj.call(this), t ? this._weekdaysStrictRegex : this._weekdaysRegex) : (ft(this, "_weekdaysRegex") || (this._weekdaysRegex = NQe), this._weekdaysStrictRegex && t ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function BQe(t) {
  return this._weekdaysParseExact ? (ft(this, "_weekdaysRegex") || cj.call(this), t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (ft(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = PQe), this._weekdaysShortStrictRegex && t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function qQe(t) {
  return this._weekdaysParseExact ? (ft(this, "_weekdaysRegex") || cj.call(this), t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (ft(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = OQe), this._weekdaysMinStrictRegex && t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function cj() {
  function t(h, u) {
    return u.length - h.length;
  }
  var e = [], n = [], r = [], s = [], i, o, a, c, l;
  for (i = 0; i < 7; i++)
    o = Ja([2e3, 1]).day(i), a = sl(this.weekdaysMin(o, "")), c = sl(this.weekdaysShort(o, "")), l = sl(this.weekdays(o, "")), e.push(a), n.push(c), r.push(l), s.push(a), s.push(c), s.push(l);
  e.sort(t), n.sort(t), r.sort(t), s.sort(t), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function lj() {
  return this.hours() % 12 || 12;
}
function HQe() {
  return this.hours() || 24;
}
X("H", ["HH", 2], 0, "hour");
X("h", ["hh", 2], 0, lj);
X("k", ["kk", 2], 0, HQe);
X("hmm", 0, 0, function() {
  return "" + lj.apply(this) + Ua(this.minutes(), 2);
});
X("hmmss", 0, 0, function() {
  return "" + lj.apply(this) + Ua(this.minutes(), 2) + Ua(this.seconds(), 2);
});
X("Hmm", 0, 0, function() {
  return "" + this.hours() + Ua(this.minutes(), 2);
});
X("Hmmss", 0, 0, function() {
  return "" + this.hours() + Ua(this.minutes(), 2) + Ua(this.seconds(), 2);
});
function Sce(t, e) {
  X(t, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      e
    );
  });
}
Sce("a", !0);
Sce("A", !1);
function Ice(t, e) {
  return e._meridiemParse;
}
W("a", Ice);
W("A", Ice);
W("H", dn, ij);
W("h", dn, Eg);
W("k", dn, Eg);
W("HH", dn, Ri);
W("hh", dn, Ri);
W("kk", dn, Ri);
W("hmm", pce);
W("hmmss", _ce);
W("Hmm", pce);
W("Hmmss", _ce);
Ft(["H", "HH"], vr);
Ft(["k", "kk"], function(t, e, n) {
  var r = qe(t);
  e[vr] = r === 24 ? 0 : r;
});
Ft(["a", "A"], function(t, e, n) {
  n._isPm = n._locale.isPM(t), n._meridiem = t;
});
Ft(["h", "hh"], function(t, e, n) {
  e[vr] = qe(t), pe(n).bigHour = !0;
});
Ft("hmm", function(t, e, n) {
  var r = t.length - 2;
  e[vr] = qe(t.substr(0, r)), e[xo] = qe(t.substr(r)), pe(n).bigHour = !0;
});
Ft("hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[vr] = qe(t.substr(0, r)), e[xo] = qe(t.substr(r, 2)), e[Nc] = qe(t.substr(s)), pe(n).bigHour = !0;
});
Ft("Hmm", function(t, e, n) {
  var r = t.length - 2;
  e[vr] = qe(t.substr(0, r)), e[xo] = qe(t.substr(r));
});
Ft("Hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[vr] = qe(t.substr(0, r)), e[xo] = qe(t.substr(r, 2)), e[Nc] = qe(t.substr(s));
});
function jQe(t) {
  return (t + "").toLowerCase().charAt(0) === "p";
}
var VQe = /[ap]\.?m?\.?/i, zQe = Sg("Hours", !0);
function GQe(t, e, n) {
  return t > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var Rce = {
  calendar: xJe,
  longDateFormat: YJe,
  invalidDate: BJe,
  ordinal: HJe,
  dayOfMonthOrdinalParse: jJe,
  relativeTime: zJe,
  months: cQe,
  monthsShort: yce,
  week: wQe,
  weekdays: RQe,
  weekdaysMin: kQe,
  weekdaysShort: Ece,
  meridiemParse: VQe
}, yn = {}, Jy = {}, $C;
function $Qe(t, e) {
  var n, r = Math.min(t.length, e.length);
  for (n = 0; n < r; n += 1)
    if (t[n] !== e[n])
      return n;
  return r;
}
function N9(t) {
  return t && t.toLowerCase().replace("_", "-");
}
function KQe(t) {
  for (var e = 0, n, r, s, i; e < t.length; ) {
    for (i = N9(t[e]).split("-"), n = i.length, r = N9(t[e + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = zO(i.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && $Qe(i, r) >= n - 1)
        break;
      n--;
    }
    e++;
  }
  return $C;
}
function JQe(t) {
  return !!(t && t.match("^[^/\\\\]*$"));
}
function zO(t) {
  var e = null, n;
  if (yn[t] === void 0 && typeof module < "u" && module && module.exports && JQe(t))
    try {
      e = $C._abbr, n = require, n("./locale/" + t), fh(e);
    } catch {
      yn[t] = null;
    }
  return yn[t];
}
function fh(t, e) {
  var n;
  return t && (ei(e) ? n = ql(t) : n = uj(t, e), n ? $C = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + t + " not found. Did you forget to load it?"
  )), $C._abbr;
}
function uj(t, e) {
  if (e !== null) {
    var n, r = Rce;
    if (e.abbr = t, yn[t] != null)
      uce(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = yn[t]._config;
    else if (e.parentLocale != null)
      if (yn[e.parentLocale] != null)
        r = yn[e.parentLocale]._config;
      else if (n = zO(e.parentLocale), n != null)
        r = n._config;
      else
        return Jy[e.parentLocale] || (Jy[e.parentLocale] = []), Jy[e.parentLocale].push({
          name: t,
          config: e
        }), null;
    return yn[t] = new ej(TF(r, e)), Jy[t] && Jy[t].forEach(function(s) {
      uj(s.name, s.config);
    }), fh(t), yn[t];
  } else
    return delete yn[t], null;
}
function QQe(t, e) {
  if (e != null) {
    var n, r, s = Rce;
    yn[t] != null && yn[t].parentLocale != null ? yn[t].set(TF(yn[t]._config, e)) : (r = zO(t), r != null && (s = r._config), e = TF(s, e), r == null && (e.abbr = t), n = new ej(e), n.parentLocale = yn[t], yn[t] = n), fh(t);
  } else
    yn[t] != null && (yn[t].parentLocale != null ? (yn[t] = yn[t].parentLocale, t === fh() && fh(t)) : yn[t] != null && delete yn[t]);
  return yn[t];
}
function ql(t) {
  var e;
  if (t && t._locale && t._locale._abbr && (t = t._locale._abbr), !t)
    return $C;
  if (!$o(t)) {
    if (e = zO(t), e)
      return e;
    t = [t];
  }
  return KQe(t);
}
function ZQe() {
  return bF(yn);
}
function hj(t) {
  var e, n = t._a;
  return n && pe(t).overflow === -2 && (e = n[kc] < 0 || n[kc] > 11 ? kc : n[ya] < 1 || n[ya] > oj(n[ds], n[kc]) ? ya : n[vr] < 0 || n[vr] > 24 || n[vr] === 24 && (n[xo] !== 0 || n[Nc] !== 0 || n[Wd] !== 0) ? vr : n[xo] < 0 || n[xo] > 59 ? xo : n[Nc] < 0 || n[Nc] > 59 ? Nc : n[Wd] < 0 || n[Wd] > 999 ? Wd : -1, pe(t)._overflowDayOfYear && (e < ds || e > ya) && (e = ya), pe(t)._overflowWeeks && e === -1 && (e = nQe), pe(t)._overflowWeekday && e === -1 && (e = rQe), pe(t).overflow = e), t;
}
var XQe = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, eZe = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, tZe = /Z|[+-]\d\d(?::?\d\d)?/, dS = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], U1 = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], nZe = /^\/?Date\((-?\d+)/i, rZe = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, sZe = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function kce(t) {
  var e, n, r = t._i, s = XQe.exec(r) || eZe.exec(r), i, o, a, c, l = dS.length, h = U1.length;
  if (s) {
    for (pe(t).iso = !0, e = 0, n = l; e < n; e++)
      if (dS[e][1].exec(s[1])) {
        o = dS[e][0], i = dS[e][2] !== !1;
        break;
      }
    if (o == null) {
      t._isValid = !1;
      return;
    }
    if (s[3]) {
      for (e = 0, n = h; e < n; e++)
        if (U1[e][1].exec(s[3])) {
          a = (s[2] || " ") + U1[e][0];
          break;
        }
      if (a == null) {
        t._isValid = !1;
        return;
      }
    }
    if (!i && a != null) {
      t._isValid = !1;
      return;
    }
    if (s[4])
      if (tZe.exec(s[4]))
        c = "Z";
      else {
        t._isValid = !1;
        return;
      }
    t._f = o + (a || "") + (c || ""), fj(t);
  } else
    t._isValid = !1;
}
function iZe(t, e, n, r, s, i) {
  var o = [
    oZe(t),
    yce.indexOf(e),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return i && o.push(parseInt(i, 10)), o;
}
function oZe(t) {
  var e = parseInt(t, 10);
  return e <= 49 ? 2e3 + e : e <= 999 ? 1900 + e : e;
}
function aZe(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function cZe(t, e, n) {
  if (t) {
    var r = Ece.indexOf(t), s = new Date(
      e[0],
      e[1],
      e[2]
    ).getDay();
    if (r !== s)
      return pe(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function lZe(t, e, n) {
  if (t)
    return sZe[t];
  if (e)
    return 0;
  var r = parseInt(n, 10), s = r % 100, i = (r - s) / 100;
  return i * 60 + s;
}
function Nce(t) {
  var e = rZe.exec(aZe(t._i)), n;
  if (e) {
    if (n = iZe(
      e[4],
      e[3],
      e[2],
      e[5],
      e[6],
      e[7]
    ), !cZe(e[1], n, t))
      return;
    t._a = n, t._tzm = lZe(e[8], e[9], e[10]), t._d = zC.apply(null, t._a), t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), pe(t).rfc2822 = !0;
  } else
    t._isValid = !1;
}
function uZe(t) {
  var e = nZe.exec(t._i);
  if (e !== null) {
    t._d = /* @__PURE__ */ new Date(+e[1]);
    return;
  }
  if (kce(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  if (Nce(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  t._strict ? t._isValid = !1 : M.createFromInputFallback(t);
}
M.createFromInputFallback = oo(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(t) {
    t._d = /* @__PURE__ */ new Date(t._i + (t._useUTC ? " UTC" : ""));
  }
);
function Qp(t, e, n) {
  return t ?? e ?? n;
}
function hZe(t) {
  var e = new Date(M.now());
  return t._useUTC ? [
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate()
  ] : [e.getFullYear(), e.getMonth(), e.getDate()];
}
function dj(t) {
  var e, n, r = [], s, i, o;
  if (!t._d) {
    for (s = hZe(t), t._w && t._a[ya] == null && t._a[kc] == null && dZe(t), t._dayOfYear != null && (o = Qp(t._a[ds], s[ds]), (t._dayOfYear > mw(o) || t._dayOfYear === 0) && (pe(t)._overflowDayOfYear = !0), n = zC(o, 0, t._dayOfYear), t._a[kc] = n.getUTCMonth(), t._a[ya] = n.getUTCDate()), e = 0; e < 3 && t._a[e] == null; ++e)
      t._a[e] = r[e] = s[e];
    for (; e < 7; e++)
      t._a[e] = r[e] = t._a[e] == null ? e === 2 ? 1 : 0 : t._a[e];
    t._a[vr] === 24 && t._a[xo] === 0 && t._a[Nc] === 0 && t._a[Wd] === 0 && (t._nextDay = !0, t._a[vr] = 0), t._d = (t._useUTC ? zC : yQe).apply(
      null,
      r
    ), i = t._useUTC ? t._d.getUTCDay() : t._d.getDay(), t._tzm != null && t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), t._nextDay && (t._a[vr] = 24), t._w && typeof t._w.d < "u" && t._w.d !== i && (pe(t).weekdayMismatch = !0);
  }
}
function dZe(t) {
  var e, n, r, s, i, o, a, c, l;
  e = t._w, e.GG != null || e.W != null || e.E != null ? (i = 1, o = 4, n = Qp(
    e.GG,
    t._a[ds],
    GC(on(), 1, 4).year
  ), r = Qp(e.W, 1), s = Qp(e.E, 1), (s < 1 || s > 7) && (c = !0)) : (i = t._locale._week.dow, o = t._locale._week.doy, l = GC(on(), i, o), n = Qp(e.gg, t._a[ds], l.year), r = Qp(e.w, l.week), e.d != null ? (s = e.d, (s < 0 || s > 6) && (c = !0)) : e.e != null ? (s = e.e + i, (e.e < 0 || e.e > 6) && (c = !0)) : s = i), r < 1 || r > il(n, i, o) ? pe(t)._overflowWeeks = !0 : c != null ? pe(t)._overflowWeekday = !0 : (a = bce(n, r, s, i, o), t._a[ds] = a.year, t._dayOfYear = a.dayOfYear);
}
M.ISO_8601 = function() {
};
M.RFC_2822 = function() {
};
function fj(t) {
  if (t._f === M.ISO_8601) {
    kce(t);
    return;
  }
  if (t._f === M.RFC_2822) {
    Nce(t);
    return;
  }
  t._a = [], pe(t).empty = !0;
  var e = "" + t._i, n, r, s, i, o, a = e.length, c = 0, l, h;
  for (s = hce(t._f, t._locale).match(tj) || [], h = s.length, n = 0; n < h; n++)
    i = s[n], r = (e.match(XJe(i, t)) || [])[0], r && (o = e.substr(0, e.indexOf(r)), o.length > 0 && pe(t).unusedInput.push(o), e = e.slice(
      e.indexOf(r) + r.length
    ), c += r.length), x_[i] ? (r ? pe(t).empty = !1 : pe(t).unusedTokens.push(i), tQe(i, r, t)) : t._strict && !r && pe(t).unusedTokens.push(i);
  pe(t).charsLeftOver = a - c, e.length > 0 && pe(t).unusedInput.push(e), t._a[vr] <= 12 && pe(t).bigHour === !0 && t._a[vr] > 0 && (pe(t).bigHour = void 0), pe(t).parsedDateParts = t._a.slice(0), pe(t).meridiem = t._meridiem, t._a[vr] = fZe(
    t._locale,
    t._a[vr],
    t._meridiem
  ), l = pe(t).era, l !== null && (t._a[ds] = t._locale.erasConvertYear(l, t._a[ds])), dj(t), hj(t);
}
function fZe(t, e, n) {
  var r;
  return n == null ? e : t.meridiemHour != null ? t.meridiemHour(e, n) : (t.isPM != null && (r = t.isPM(n), r && e < 12 && (e += 12), !r && e === 12 && (e = 0)), e);
}
function pZe(t) {
  var e, n, r, s, i, o, a = !1, c = t._f.length;
  if (c === 0) {
    pe(t).invalidFormat = !0, t._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < c; s++)
    i = 0, o = !1, e = XH({}, t), t._useUTC != null && (e._useUTC = t._useUTC), e._f = t._f[s], fj(e), ZH(e) && (o = !0), i += pe(e).charsLeftOver, i += pe(e).unusedTokens.length * 10, pe(e).score = i, a ? i < r && (r = i, n = e) : (r == null || i < r || o) && (r = i, n = e, o && (a = !0));
  Ru(t, n || e);
}
function _Ze(t) {
  if (!t._d) {
    var e = nj(t._i), n = e.day === void 0 ? e.date : e.day;
    t._a = cce(
      [e.year, e.month, n, e.hour, e.minute, e.second, e.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), dj(t);
  }
}
function mZe(t) {
  var e = new qb(hj(Pce(t)));
  return e._nextDay && (e.add(1, "d"), e._nextDay = void 0), e;
}
function Pce(t) {
  var e = t._i, n = t._f;
  return t._locale = t._locale || ql(t._l), e === null || n === void 0 && e === "" ? YO({ nullInput: !0 }) : (typeof e == "string" && (t._i = e = t._locale.preparse(e)), Ko(e) ? new qb(hj(e)) : (Bb(e) ? t._d = e : $o(n) ? pZe(t) : n ? fj(t) : gZe(t), ZH(t) || (t._d = null), t));
}
function gZe(t) {
  var e = t._i;
  ei(e) ? t._d = new Date(M.now()) : Bb(e) ? t._d = new Date(e.valueOf()) : typeof e == "string" ? uZe(t) : $o(e) ? (t._a = cce(e.slice(0), function(n) {
    return parseInt(n, 10);
  }), dj(t)) : af(e) ? _Ze(t) : bl(e) ? t._d = new Date(e) : M.createFromInputFallback(t);
}
function Oce(t, e, n, r, s) {
  var i = {};
  return (e === !0 || e === !1) && (r = e, e = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (af(t) && QH(t) || $o(t) && t.length === 0) && (t = void 0), i._isAMomentObject = !0, i._useUTC = i._isUTC = s, i._l = n, i._i = t, i._f = e, i._strict = r, mZe(i);
}
function on(t, e, n, r) {
  return Oce(t, e, n, r, !1);
}
var yZe = oo(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = on.apply(null, arguments);
    return this.isValid() && t.isValid() ? t < this ? this : t : YO();
  }
), vZe = oo(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = on.apply(null, arguments);
    return this.isValid() && t.isValid() ? t > this ? this : t : YO();
  }
);
function Dce(t, e) {
  var n, r;
  if (e.length === 1 && $o(e[0]) && (e = e[0]), !e.length)
    return on();
  for (n = e[0], r = 1; r < e.length; ++r)
    (!e[r].isValid() || e[r][t](n)) && (n = e[r]);
  return n;
}
function wZe() {
  var t = [].slice.call(arguments, 0);
  return Dce("isBefore", t);
}
function CZe() {
  var t = [].slice.call(arguments, 0);
  return Dce("isAfter", t);
}
var TZe = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, Qy = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function bZe(t) {
  var e, n = !1, r, s = Qy.length;
  for (e in t)
    if (ft(t, e) && !(Xn.call(Qy, e) !== -1 && (t[e] == null || !isNaN(t[e]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (t[Qy[r]]) {
      if (n)
        return !1;
      parseFloat(t[Qy[r]]) !== qe(t[Qy[r]]) && (n = !0);
    }
  return !0;
}
function EZe() {
  return this._isValid;
}
function SZe() {
  return oa(NaN);
}
function GO(t) {
  var e = nj(t), n = e.year || 0, r = e.quarter || 0, s = e.month || 0, i = e.week || e.isoWeek || 0, o = e.day || 0, a = e.hour || 0, c = e.minute || 0, l = e.second || 0, h = e.millisecond || 0;
  this._isValid = bZe(e), this._milliseconds = +h + l * 1e3 + // 1000
  c * 6e4 + // 1000 * 60
  a * 1e3 * 60 * 60, this._days = +o + i * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = ql(), this._bubble();
}
function m0(t) {
  return t instanceof GO;
}
function SF(t) {
  return t < 0 ? Math.round(-1 * t) * -1 : Math.round(t);
}
function IZe(t, e, n) {
  var r = Math.min(t.length, e.length), s = Math.abs(t.length - e.length), i = 0, o;
  for (o = 0; o < r; o++)
    qe(t[o]) !== qe(e[o]) && i++;
  return i + s;
}
function Ace(t, e) {
  X(t, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + Ua(~~(n / 60), 2) + e + Ua(~~n % 60, 2);
  });
}
Ace("Z", ":");
Ace("ZZ", "");
W("Z", jO);
W("ZZ", jO);
Ft(["Z", "ZZ"], function(t, e, n) {
  n._useUTC = !0, n._tzm = pj(jO, t);
});
var RZe = /([\+\-]|\d\d)/gi;
function pj(t, e) {
  var n = (e || "").match(t), r, s, i;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(RZe) || ["-", 0, 0], i = +(s[1] * 60) + qe(s[2]), i === 0 ? 0 : s[0] === "+" ? i : -i);
}
function _j(t, e) {
  var n, r;
  return e._isUTC ? (n = e.clone(), r = (Ko(t) || Bb(t) ? t.valueOf() : on(t).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), M.updateOffset(n, !1), n) : on(t).local();
}
function IF(t) {
  return -Math.round(t._d.getTimezoneOffset());
}
M.updateOffset = function() {
};
function kZe(t, e, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    if (typeof t == "string") {
      if (t = pj(jO, t), t === null)
        return this;
    } else Math.abs(t) < 16 && !n && (t = t * 60);
    return !this._isUTC && e && (s = IF(this)), this._offset = t, this._isUTC = !0, s != null && this.add(s, "m"), r !== t && (!e || this._changeInProgress ? Lce(
      this,
      oa(t - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, M.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : IF(this);
}
function NZe(t, e) {
  return t != null ? (typeof t != "string" && (t = -t), this.utcOffset(t, e), this) : -this.utcOffset();
}
function PZe(t) {
  return this.utcOffset(0, t);
}
function OZe(t) {
  return this._isUTC && (this.utcOffset(0, t), this._isUTC = !1, t && this.subtract(IF(this), "m")), this;
}
function DZe() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var t = pj(QJe, this._i);
    t != null ? this.utcOffset(t) : this.utcOffset(0, !0);
  }
  return this;
}
function AZe(t) {
  return this.isValid() ? (t = t ? on(t).utcOffset() : 0, (this.utcOffset() - t) % 60 === 0) : !1;
}
function MZe() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function xZe() {
  if (!ei(this._isDSTShifted))
    return this._isDSTShifted;
  var t = {}, e;
  return XH(t, this), t = Pce(t), t._a ? (e = t._isUTC ? Ja(t._a) : on(t._a), this._isDSTShifted = this.isValid() && IZe(t._a, e.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function LZe() {
  return this.isValid() ? !this._isUTC : !1;
}
function UZe() {
  return this.isValid() ? this._isUTC : !1;
}
function Mce() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var FZe = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, YZe = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function oa(t, e) {
  var n = t, r = null, s, i, o;
  return m0(t) ? n = {
    ms: t._milliseconds,
    d: t._days,
    M: t._months
  } : bl(t) || !isNaN(+t) ? (n = {}, e ? n[e] = +t : n.milliseconds = +t) : (r = FZe.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: qe(r[ya]) * s,
    h: qe(r[vr]) * s,
    m: qe(r[xo]) * s,
    s: qe(r[Nc]) * s,
    ms: qe(SF(r[Wd] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = YZe.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: fd(r[2], s),
    M: fd(r[3], s),
    w: fd(r[4], s),
    d: fd(r[5], s),
    h: fd(r[6], s),
    m: fd(r[7], s),
    s: fd(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (o = WZe(
    on(n.from),
    on(n.to)
  ), n = {}, n.ms = o.milliseconds, n.M = o.months), i = new GO(n), m0(t) && ft(t, "_locale") && (i._locale = t._locale), m0(t) && ft(t, "_isValid") && (i._isValid = t._isValid), i;
}
oa.fn = GO.prototype;
oa.invalid = SZe;
function fd(t, e) {
  var n = t && parseFloat(t.replace(",", "."));
  return (isNaN(n) ? 0 : n) * e;
}
function P9(t, e) {
  var n = {};
  return n.months = e.month() - t.month() + (e.year() - t.year()) * 12, t.clone().add(n.months, "M").isAfter(e) && --n.months, n.milliseconds = +e - +t.clone().add(n.months, "M"), n;
}
function WZe(t, e) {
  var n;
  return t.isValid() && e.isValid() ? (e = _j(e, t), t.isBefore(e) ? n = P9(t, e) : (n = P9(e, t), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function xce(t, e) {
  return function(n, r) {
    var s, i;
    return r !== null && !isNaN(+r) && (uce(
      e,
      "moment()." + e + "(period, number) is deprecated. Please use moment()." + e + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), i = n, n = r, r = i), s = oa(n, r), Lce(this, s, t), this;
  };
}
function Lce(t, e, n, r) {
  var s = e._milliseconds, i = SF(e._days), o = SF(e._months);
  t.isValid() && (r = r ?? !0, o && wce(t, VC(t, "Month") + o * n), i && gce(t, "Date", VC(t, "Date") + i * n), s && t._d.setTime(t._d.valueOf() + s * n), r && M.updateOffset(t, i || o));
}
var BZe = xce(1, "add"), qZe = xce(-1, "subtract");
function Uce(t) {
  return typeof t == "string" || t instanceof String;
}
function HZe(t) {
  return Ko(t) || Bb(t) || Uce(t) || bl(t) || VZe(t) || jZe(t) || t === null || t === void 0;
}
function jZe(t) {
  var e = af(t) && !QH(t), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, i, o = r.length;
  for (s = 0; s < o; s += 1)
    i = r[s], n = n || ft(t, i);
  return e && n;
}
function VZe(t) {
  var e = $o(t), n = !1;
  return e && (n = t.filter(function(r) {
    return !bl(r) && Uce(t);
  }).length === 0), e && n;
}
function zZe(t) {
  var e = af(t) && !QH(t), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, i;
  for (s = 0; s < r.length; s += 1)
    i = r[s], n = n || ft(t, i);
  return e && n;
}
function GZe(t, e) {
  var n = t.diff(e, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function $Ze(t, e) {
  arguments.length === 1 && (arguments[0] ? HZe(arguments[0]) ? (t = arguments[0], e = void 0) : zZe(arguments[0]) && (e = arguments[0], t = void 0) : (t = void 0, e = void 0));
  var n = t || on(), r = _j(n, this).startOf("day"), s = M.calendarFormat(this, r) || "sameElse", i = e && (Qa(e[s]) ? e[s].call(this, n) : e[s]);
  return this.format(
    i || this.localeData().calendar(s, this, on(n))
  );
}
function KZe() {
  return new qb(this);
}
function JZe(t, e) {
  var n = Ko(t) ? t : on(t);
  return this.isValid() && n.isValid() ? (e = ao(e) || "millisecond", e === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(e).valueOf()) : !1;
}
function QZe(t, e) {
  var n = Ko(t) ? t : on(t);
  return this.isValid() && n.isValid() ? (e = ao(e) || "millisecond", e === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(e).valueOf() < n.valueOf()) : !1;
}
function ZZe(t, e, n, r) {
  var s = Ko(t) ? t : on(t), i = Ko(e) ? e : on(e);
  return this.isValid() && s.isValid() && i.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(i, n) : !this.isAfter(i, n))) : !1;
}
function XZe(t, e) {
  var n = Ko(t) ? t : on(t), r;
  return this.isValid() && n.isValid() ? (e = ao(e) || "millisecond", e === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(e).valueOf() <= r && r <= this.clone().endOf(e).valueOf())) : !1;
}
function eXe(t, e) {
  return this.isSame(t, e) || this.isAfter(t, e);
}
function tXe(t, e) {
  return this.isSame(t, e) || this.isBefore(t, e);
}
function nXe(t, e, n) {
  var r, s, i;
  if (!this.isValid())
    return NaN;
  if (r = _j(t, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, e = ao(e), e) {
    case "year":
      i = g0(this, r) / 12;
      break;
    case "month":
      i = g0(this, r);
      break;
    case "quarter":
      i = g0(this, r) / 3;
      break;
    case "second":
      i = (this - r) / 1e3;
      break;
    case "minute":
      i = (this - r) / 6e4;
      break;
    case "hour":
      i = (this - r) / 36e5;
      break;
    case "day":
      i = (this - r - s) / 864e5;
      break;
    case "week":
      i = (this - r - s) / 6048e5;
      break;
    default:
      i = this - r;
  }
  return n ? i : Yi(i);
}
function g0(t, e) {
  if (t.date() < e.date())
    return -g0(e, t);
  var n = (e.year() - t.year()) * 12 + (e.month() - t.month()), r = t.clone().add(n, "months"), s, i;
  return e - r < 0 ? (s = t.clone().add(n - 1, "months"), i = (e - r) / (r - s)) : (s = t.clone().add(n + 1, "months"), i = (e - r) / (s - r)), -(n + i) || 0;
}
M.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
M.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function rXe() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function sXe(t) {
  if (!this.isValid())
    return null;
  var e = t !== !0, n = e ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? _0(
    n,
    e ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : Qa(Date.prototype.toISOString) ? e ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", _0(n, "Z")) : _0(
    n,
    e ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function iXe() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var t = "moment", e = "", n, r, s, i;
  return this.isLocal() || (t = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", e = "Z"), n = "[" + t + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", i = e + '[")]', this.format(n + r + s + i);
}
function oXe(t) {
  t || (t = this.isUtc() ? M.defaultFormatUtc : M.defaultFormat);
  var e = _0(this, t);
  return this.localeData().postformat(e);
}
function aXe(t, e) {
  return this.isValid() && (Ko(t) && t.isValid() || on(t).isValid()) ? oa({ to: this, from: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function cXe(t) {
  return this.from(on(), t);
}
function lXe(t, e) {
  return this.isValid() && (Ko(t) && t.isValid() || on(t).isValid()) ? oa({ from: this, to: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function uXe(t) {
  return this.to(on(), t);
}
function Fce(t) {
  var e;
  return t === void 0 ? this._locale._abbr : (e = ql(t), e != null && (this._locale = e), this);
}
var Yce = oo(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(t) {
    return t === void 0 ? this.localeData() : this.locale(t);
  }
);
function Wce() {
  return this._locale;
}
var Rk = 1e3, L_ = 60 * Rk, kk = 60 * L_, Bce = (365 * 400 + 97) * 24 * kk;
function U_(t, e) {
  return (t % e + e) % e;
}
function qce(t, e, n) {
  return t < 100 && t >= 0 ? new Date(t + 400, e, n) - Bce : new Date(t, e, n).valueOf();
}
function Hce(t, e, n) {
  return t < 100 && t >= 0 ? Date.UTC(t + 400, e, n) - Bce : Date.UTC(t, e, n);
}
function hXe(t) {
  var e, n;
  if (t = ao(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? Hce : qce, t) {
    case "year":
      e = n(this.year(), 0, 1);
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      e = n(this.year(), this.month(), 1);
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      e = this._d.valueOf(), e -= U_(
        e + (this._isUTC ? 0 : this.utcOffset() * L_),
        kk
      );
      break;
    case "minute":
      e = this._d.valueOf(), e -= U_(e, L_);
      break;
    case "second":
      e = this._d.valueOf(), e -= U_(e, Rk);
      break;
  }
  return this._d.setTime(e), M.updateOffset(this, !0), this;
}
function dXe(t) {
  var e, n;
  if (t = ao(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? Hce : qce, t) {
    case "year":
      e = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      e = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      e = this._d.valueOf(), e += kk - U_(
        e + (this._isUTC ? 0 : this.utcOffset() * L_),
        kk
      ) - 1;
      break;
    case "minute":
      e = this._d.valueOf(), e += L_ - U_(e, L_) - 1;
      break;
    case "second":
      e = this._d.valueOf(), e += Rk - U_(e, Rk) - 1;
      break;
  }
  return this._d.setTime(e), M.updateOffset(this, !0), this;
}
function fXe() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function pXe() {
  return Math.floor(this.valueOf() / 1e3);
}
function _Xe() {
  return new Date(this.valueOf());
}
function mXe() {
  var t = this;
  return [
    t.year(),
    t.month(),
    t.date(),
    t.hour(),
    t.minute(),
    t.second(),
    t.millisecond()
  ];
}
function gXe() {
  var t = this;
  return {
    years: t.year(),
    months: t.month(),
    date: t.date(),
    hours: t.hours(),
    minutes: t.minutes(),
    seconds: t.seconds(),
    milliseconds: t.milliseconds()
  };
}
function yXe() {
  return this.isValid() ? this.toISOString() : null;
}
function vXe() {
  return ZH(this);
}
function wXe() {
  return Ru({}, pe(this));
}
function CXe() {
  return pe(this).overflow;
}
function TXe() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
X("N", 0, 0, "eraAbbr");
X("NN", 0, 0, "eraAbbr");
X("NNN", 0, 0, "eraAbbr");
X("NNNN", 0, 0, "eraName");
X("NNNNN", 0, 0, "eraNarrow");
X("y", ["y", 1], "yo", "eraYear");
X("y", ["yy", 2], 0, "eraYear");
X("y", ["yyy", 3], 0, "eraYear");
X("y", ["yyyy", 4], 0, "eraYear");
W("N", mj);
W("NN", mj);
W("NNN", mj);
W("NNNN", AXe);
W("NNNNN", MXe);
Ft(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(t, e, n, r) {
    var s = n._locale.erasParse(t, r, n._strict);
    s ? pe(n).era = s : pe(n).invalidEra = t;
  }
);
W("y", bg);
W("yy", bg);
W("yyy", bg);
W("yyyy", bg);
W("yo", xXe);
Ft(["y", "yy", "yyy", "yyyy"], ds);
Ft(["yo"], function(t, e, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = t.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? e[ds] = n._locale.eraYearOrdinalParse(t, s) : e[ds] = parseInt(t, 10);
});
function bXe(t, e) {
  var n, r, s, i = this._eras || ql("en")._eras;
  for (n = 0, r = i.length; n < r; ++n) {
    switch (typeof i[n].since) {
      case "string":
        s = M(i[n].since).startOf("day"), i[n].since = s.valueOf();
        break;
    }
    switch (typeof i[n].until) {
      case "undefined":
        i[n].until = 1 / 0;
        break;
      case "string":
        s = M(i[n].until).startOf("day").valueOf(), i[n].until = s.valueOf();
        break;
    }
  }
  return i;
}
function EXe(t, e, n) {
  var r, s, i = this.eras(), o, a, c;
  for (t = t.toUpperCase(), r = 0, s = i.length; r < s; ++r)
    if (o = i[r].name.toUpperCase(), a = i[r].abbr.toUpperCase(), c = i[r].narrow.toUpperCase(), n)
      switch (e) {
        case "N":
        case "NN":
        case "NNN":
          if (a === t)
            return i[r];
          break;
        case "NNNN":
          if (o === t)
            return i[r];
          break;
        case "NNNNN":
          if (c === t)
            return i[r];
          break;
      }
    else if ([o, a, c].indexOf(t) >= 0)
      return i[r];
}
function SXe(t, e) {
  var n = t.since <= t.until ? 1 : -1;
  return e === void 0 ? M(t.since).year() : M(t.since).year() + (e - t.offset) * n;
}
function IXe() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].name;
  return "";
}
function RXe() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].narrow;
  return "";
}
function kXe() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].abbr;
  return "";
}
function NXe() {
  var t, e, n, r, s = this.localeData().eras();
  for (t = 0, e = s.length; t < e; ++t)
    if (n = s[t].since <= s[t].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[t].since <= r && r <= s[t].until || s[t].until <= r && r <= s[t].since)
      return (this.year() - M(s[t].since).year()) * n + s[t].offset;
  return this.year();
}
function PXe(t) {
  return ft(this, "_erasNameRegex") || gj.call(this), t ? this._erasNameRegex : this._erasRegex;
}
function OXe(t) {
  return ft(this, "_erasAbbrRegex") || gj.call(this), t ? this._erasAbbrRegex : this._erasRegex;
}
function DXe(t) {
  return ft(this, "_erasNarrowRegex") || gj.call(this), t ? this._erasNarrowRegex : this._erasRegex;
}
function mj(t, e) {
  return e.erasAbbrRegex(t);
}
function AXe(t, e) {
  return e.erasNameRegex(t);
}
function MXe(t, e) {
  return e.erasNarrowRegex(t);
}
function xXe(t, e) {
  return e._eraYearOrdinalRegex || bg;
}
function gj() {
  var t = [], e = [], n = [], r = [], s, i, o, a, c, l = this.eras();
  for (s = 0, i = l.length; s < i; ++s)
    o = sl(l[s].name), a = sl(l[s].abbr), c = sl(l[s].narrow), e.push(o), t.push(a), n.push(c), r.push(o), r.push(a), r.push(c);
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
X(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
X(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function $O(t, e) {
  X(0, [t, t.length], 0, e);
}
$O("gggg", "weekYear");
$O("ggggg", "weekYear");
$O("GGGG", "isoWeekYear");
$O("GGGGG", "isoWeekYear");
W("G", HO);
W("g", HO);
W("GG", dn, Ri);
W("gg", dn, Ri);
W("GGGG", sj, rj);
W("gggg", sj, rj);
W("GGGGG", qO, WO);
W("ggggg", qO, WO);
jb(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(t, e, n, r) {
    e[r.substr(0, 2)] = qe(t);
  }
);
jb(["gg", "GG"], function(t, e, n, r) {
  e[r] = M.parseTwoDigitYear(t);
});
function LXe(t) {
  return jce.call(
    this,
    t,
    this.week(),
    this.weekday() + this.localeData()._week.dow,
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function UXe(t) {
  return jce.call(
    this,
    t,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function FXe() {
  return il(this.year(), 1, 4);
}
function YXe() {
  return il(this.isoWeekYear(), 1, 4);
}
function WXe() {
  var t = this.localeData()._week;
  return il(this.year(), t.dow, t.doy);
}
function BXe() {
  var t = this.localeData()._week;
  return il(this.weekYear(), t.dow, t.doy);
}
function jce(t, e, n, r, s) {
  var i;
  return t == null ? GC(this, r, s).year : (i = il(t, r, s), e > i && (e = i), qXe.call(this, t, e, n, r, s));
}
function qXe(t, e, n, r, s) {
  var i = bce(t, e, n, r, s), o = zC(i.year, 0, i.dayOfYear);
  return this.year(o.getUTCFullYear()), this.month(o.getUTCMonth()), this.date(o.getUTCDate()), this;
}
X("Q", 0, "Qo", "quarter");
W("Q", dce);
Ft("Q", function(t, e) {
  e[kc] = (qe(t) - 1) * 3;
});
function HXe(t) {
  return t == null ? Math.ceil((this.month() + 1) / 3) : this.month((t - 1) * 3 + this.month() % 3);
}
X("D", ["DD", 2], "Do", "date");
W("D", dn, Eg);
W("DD", dn, Ri);
W("Do", function(t, e) {
  return t ? e._dayOfMonthOrdinalParse || e._ordinalParse : e._dayOfMonthOrdinalParseLenient;
});
Ft(["D", "DD"], ya);
Ft("Do", function(t, e) {
  e[ya] = qe(t.match(dn)[0]);
});
var Vce = Sg("Date", !0);
X("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
W("DDD", BO);
W("DDDD", fce);
Ft(["DDD", "DDDD"], function(t, e, n) {
  n._dayOfYear = qe(t);
});
function jXe(t) {
  var e = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return t == null ? e : this.add(t - e, "d");
}
X("m", ["mm", 2], 0, "minute");
W("m", dn, ij);
W("mm", dn, Ri);
Ft(["m", "mm"], xo);
var VXe = Sg("Minutes", !1);
X("s", ["ss", 2], 0, "second");
W("s", dn, ij);
W("ss", dn, Ri);
Ft(["s", "ss"], Nc);
var zXe = Sg("Seconds", !1);
X("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
X(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
X(0, ["SSS", 3], 0, "millisecond");
X(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
X(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
X(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
X(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
X(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
X(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
W("S", BO, dce);
W("SS", BO, Ri);
W("SSS", BO, fce);
var ku, zce;
for (ku = "SSSS"; ku.length <= 9; ku += "S")
  W(ku, bg);
function GXe(t, e) {
  e[Wd] = qe(("0." + t) * 1e3);
}
for (ku = "S"; ku.length <= 9; ku += "S")
  Ft(ku, GXe);
zce = Sg("Milliseconds", !1);
X("z", 0, 0, "zoneAbbr");
X("zz", 0, 0, "zoneName");
function $Xe() {
  return this._isUTC ? "UTC" : "";
}
function KXe() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var C = qb.prototype;
C.add = BZe;
C.calendar = $Ze;
C.clone = KZe;
C.diff = nXe;
C.endOf = dXe;
C.format = oXe;
C.from = aXe;
C.fromNow = cXe;
C.to = lXe;
C.toNow = uXe;
C.get = iQe;
C.invalidAt = CXe;
C.isAfter = JZe;
C.isBefore = QZe;
C.isBetween = ZZe;
C.isSame = XZe;
C.isSameOrAfter = eXe;
C.isSameOrBefore = tXe;
C.isValid = vXe;
C.lang = Yce;
C.locale = Fce;
C.localeData = Wce;
C.max = vZe;
C.min = yZe;
C.parsingFlags = wXe;
C.set = oQe;
C.startOf = hXe;
C.subtract = qZe;
C.toArray = mXe;
C.toObject = gXe;
C.toDate = _Xe;
C.toISOString = sXe;
C.inspect = iXe;
typeof Symbol < "u" && Symbol.for != null && (C[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
C.toJSON = yXe;
C.toString = rXe;
C.unix = pXe;
C.valueOf = fXe;
C.creationData = TXe;
C.eraName = IXe;
C.eraNarrow = RXe;
C.eraAbbr = kXe;
C.eraYear = NXe;
C.year = mce;
C.isLeapYear = sQe;
C.weekYear = LXe;
C.isoWeekYear = UXe;
C.quarter = C.quarters = HXe;
C.month = Cce;
C.daysInMonth = _Qe;
C.week = C.weeks = bQe;
C.isoWeek = C.isoWeeks = EQe;
C.weeksInYear = WXe;
C.weeksInWeekYear = BXe;
C.isoWeeksInYear = FXe;
C.isoWeeksInISOWeekYear = YXe;
C.date = Vce;
C.day = C.days = UQe;
C.weekday = FQe;
C.isoWeekday = YQe;
C.dayOfYear = jXe;
C.hour = C.hours = zQe;
C.minute = C.minutes = VXe;
C.second = C.seconds = zXe;
C.millisecond = C.milliseconds = zce;
C.utcOffset = kZe;
C.utc = PZe;
C.local = OZe;
C.parseZone = DZe;
C.hasAlignedHourOffset = AZe;
C.isDST = MZe;
C.isLocal = LZe;
C.isUtcOffset = UZe;
C.isUtc = Mce;
C.isUTC = Mce;
C.zoneAbbr = $Xe;
C.zoneName = KXe;
C.dates = oo(
  "dates accessor is deprecated. Use date instead.",
  Vce
);
C.months = oo(
  "months accessor is deprecated. Use month instead",
  Cce
);
C.years = oo(
  "years accessor is deprecated. Use year instead",
  mce
);
C.zone = oo(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  NZe
);
C.isDSTShifted = oo(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  xZe
);
function JXe(t) {
  return on(t * 1e3);
}
function QXe() {
  return on.apply(null, arguments).parseZone();
}
function Gce(t) {
  return t;
}
var vt = ej.prototype;
vt.calendar = LJe;
vt.longDateFormat = WJe;
vt.invalidDate = qJe;
vt.ordinal = VJe;
vt.preparse = Gce;
vt.postformat = Gce;
vt.relativeTime = GJe;
vt.pastFuture = $Je;
vt.set = MJe;
vt.eras = bXe;
vt.erasParse = EXe;
vt.erasConvertYear = SXe;
vt.erasAbbrRegex = OXe;
vt.erasNameRegex = PXe;
vt.erasNarrowRegex = DXe;
vt.months = hQe;
vt.monthsShort = dQe;
vt.monthsParse = pQe;
vt.monthsRegex = gQe;
vt.monthsShortRegex = mQe;
vt.week = vQe;
vt.firstDayOfYear = TQe;
vt.firstDayOfWeek = CQe;
vt.weekdays = DQe;
vt.weekdaysMin = MQe;
vt.weekdaysShort = AQe;
vt.weekdaysParse = LQe;
vt.weekdaysRegex = WQe;
vt.weekdaysShortRegex = BQe;
vt.weekdaysMinRegex = qQe;
vt.isPM = jQe;
vt.meridiem = GQe;
function Nk(t, e, n, r) {
  var s = ql(), i = Ja().set(r, e);
  return s[n](i, t);
}
function $ce(t, e, n) {
  if (bl(t) && (e = t, t = void 0), t = t || "", e != null)
    return Nk(t, e, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = Nk(t, r, n, "month");
  return s;
}
function yj(t, e, n, r) {
  typeof t == "boolean" ? (bl(e) && (n = e, e = void 0), e = e || "") : (e = t, n = e, t = !1, bl(e) && (n = e, e = void 0), e = e || "");
  var s = ql(), i = t ? s._week.dow : 0, o, a = [];
  if (n != null)
    return Nk(e, (n + i) % 7, r, "day");
  for (o = 0; o < 7; o++)
    a[o] = Nk(e, (o + i) % 7, r, "day");
  return a;
}
function ZXe(t, e) {
  return $ce(t, e, "months");
}
function XXe(t, e) {
  return $ce(t, e, "monthsShort");
}
function eet(t, e, n) {
  return yj(t, e, n, "weekdays");
}
function tet(t, e, n) {
  return yj(t, e, n, "weekdaysShort");
}
function net(t, e, n) {
  return yj(t, e, n, "weekdaysMin");
}
fh("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(t) {
    var e = t % 10, n = qe(t % 100 / 10) === 1 ? "th" : e === 1 ? "st" : e === 2 ? "nd" : e === 3 ? "rd" : "th";
    return t + n;
  }
});
M.lang = oo(
  "moment.lang is deprecated. Use moment.locale instead.",
  fh
);
M.langData = oo(
  "moment.langData is deprecated. Use moment.localeData instead.",
  ql
);
var lc = Math.abs;
function ret() {
  var t = this._data;
  return this._milliseconds = lc(this._milliseconds), this._days = lc(this._days), this._months = lc(this._months), t.milliseconds = lc(t.milliseconds), t.seconds = lc(t.seconds), t.minutes = lc(t.minutes), t.hours = lc(t.hours), t.months = lc(t.months), t.years = lc(t.years), this;
}
function Kce(t, e, n, r) {
  var s = oa(e, n);
  return t._milliseconds += r * s._milliseconds, t._days += r * s._days, t._months += r * s._months, t._bubble();
}
function set(t, e) {
  return Kce(this, t, e, 1);
}
function iet(t, e) {
  return Kce(this, t, e, -1);
}
function O9(t) {
  return t < 0 ? Math.floor(t) : Math.ceil(t);
}
function oet() {
  var t = this._milliseconds, e = this._days, n = this._months, r = this._data, s, i, o, a, c;
  return t >= 0 && e >= 0 && n >= 0 || t <= 0 && e <= 0 && n <= 0 || (t += O9(RF(n) + e) * 864e5, e = 0, n = 0), r.milliseconds = t % 1e3, s = Yi(t / 1e3), r.seconds = s % 60, i = Yi(s / 60), r.minutes = i % 60, o = Yi(i / 60), r.hours = o % 24, e += Yi(o / 24), c = Yi(Jce(e)), n += c, e -= O9(RF(c)), a = Yi(n / 12), n %= 12, r.days = e, r.months = n, r.years = a, this;
}
function Jce(t) {
  return t * 4800 / 146097;
}
function RF(t) {
  return t * 146097 / 4800;
}
function aet(t) {
  if (!this.isValid())
    return NaN;
  var e, n, r = this._milliseconds;
  if (t = ao(t), t === "month" || t === "quarter" || t === "year")
    switch (e = this._days + r / 864e5, n = this._months + Jce(e), t) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (e = this._days + Math.round(RF(this._months)), t) {
      case "week":
        return e / 7 + r / 6048e5;
      case "day":
        return e + r / 864e5;
      case "hour":
        return e * 24 + r / 36e5;
      case "minute":
        return e * 1440 + r / 6e4;
      case "second":
        return e * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(e * 864e5) + r;
      default:
        throw new Error("Unknown unit " + t);
    }
}
function Hl(t) {
  return function() {
    return this.as(t);
  };
}
var Qce = Hl("ms"), cet = Hl("s"), uet = Hl("m"), het = Hl("h"), det = Hl("d"), fet = Hl("w"), pet = Hl("M"), _et = Hl("Q"), met = Hl("y"), get = Qce;
function yet() {
  return oa(this);
}
function vet(t) {
  return t = ao(t), this.isValid() ? this[t + "s"]() : NaN;
}
function wp(t) {
  return function() {
    return this.isValid() ? this._data[t] : NaN;
  };
}
var wet = wp("milliseconds"), Cet = wp("seconds"), Tet = wp("minutes"), bet = wp("hours"), Eet = wp("days"), Iet = wp("months"), Ret = wp("years");
function ket() {
  return Yi(this.days() / 7);
}
var _c = Math.round, l_ = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function Net(t, e, n, r, s) {
  return s.relativeTime(e || 1, !!n, t, r);
}
function Pet(t, e, n, r) {
  var s = oa(t).abs(), i = _c(s.as("s")), o = _c(s.as("m")), a = _c(s.as("h")), c = _c(s.as("d")), l = _c(s.as("M")), h = _c(s.as("w")), u = _c(s.as("y")), d = i <= n.ss && ["s", i] || i < n.s && ["ss", i] || o <= 1 && ["m"] || o < n.m && ["mm", o] || a <= 1 && ["h"] || a < n.h && ["hh", a] || c <= 1 && ["d"] || c < n.d && ["dd", c];
  return n.w != null && (d = d || h <= 1 && ["w"] || h < n.w && ["ww", h]), d = d || l <= 1 && ["M"] || l < n.M && ["MM", l] || u <= 1 && ["y"] || ["yy", u], d[2] = e, d[3] = +t > 0, d[4] = r, Net.apply(null, d);
}
function Oet(t) {
  return t === void 0 ? _c : typeof t == "function" ? (_c = t, !0) : !1;
}
function Det(t, e) {
  return l_[t] === void 0 ? !1 : e === void 0 ? l_[t] : (l_[t] = e, t === "s" && (l_.ss = e - 1), !0);
}
function Aet(t, e) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = l_, s, i;
  return typeof t == "object" && (e = t, t = !1), typeof t == "boolean" && (n = t), typeof e == "object" && (r = Object.assign({}, l_, e), e.s != null && e.ss == null && (r.ss = e.s - 1)), s = this.localeData(), i = Pet(this, !n, r, s), n && (i = s.pastFuture(+this, i)), s.postformat(i);
}
var F1 = Math.abs;
function Yp(t) {
  return (t > 0) - (t < 0) || +t;
}
function KO() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var t = F1(this._milliseconds) / 1e3, e = F1(this._days), n = F1(this._months), r, s, i, o, a = this.asSeconds(), c, l, h, u;
  return a ? (r = Yi(t / 60), s = Yi(r / 60), t %= 60, r %= 60, i = Yi(n / 12), n %= 12, o = t ? t.toFixed(3).replace(/\.?0+$/, "") : "", c = a < 0 ? "-" : "", l = Yp(this._months) !== Yp(a) ? "-" : "", h = Yp(this._days) !== Yp(a) ? "-" : "", u = Yp(this._milliseconds) !== Yp(a) ? "-" : "", c + "P" + (i ? l + i + "Y" : "") + (n ? l + n + "M" : "") + (e ? h + e + "D" : "") + (s || r || t ? "T" : "") + (s ? u + s + "H" : "") + (r ? u + r + "M" : "") + (t ? u + o + "S" : "")) : "P0D";
}
var $e = GO.prototype;
$e.isValid = EZe;
$e.abs = ret;
$e.add = set;
$e.subtract = iet;
$e.as = aet;
$e.asMilliseconds = Qce;
$e.asSeconds = cet;
$e.asMinutes = uet;
$e.asHours = het;
$e.asDays = det;
$e.asWeeks = fet;
$e.asMonths = pet;
$e.asQuarters = _et;
$e.asYears = met;
$e.valueOf = get;
$e._bubble = oet;
$e.clone = yet;
$e.get = vet;
$e.milliseconds = wet;
$e.seconds = Cet;
$e.minutes = Tet;
$e.hours = bet;
$e.days = Eet;
$e.weeks = ket;
$e.months = Iet;
$e.years = Ret;
$e.humanize = Aet;
$e.toISOString = KO;
$e.toString = KO;
$e.toJSON = KO;
$e.locale = Fce;
$e.localeData = Wce;
$e.toIsoString = oo(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  KO
);
$e.lang = Yce;
X("X", 0, 0, "unix");
X("x", 0, 0, "valueOf");
W("x", HO);
W("X", ZJe);
Ft("X", function(t, e, n) {
  n._d = new Date(parseFloat(t) * 1e3);
});
Ft("x", function(t, e, n) {
  n._d = new Date(qe(t));
});
M.version = "2.30.1";
DJe(on);
M.fn = C;
M.min = wZe;
M.max = CZe;
M.now = TZe;
M.utc = Ja;
M.unix = JXe;
M.months = ZXe;
M.isDate = Bb;
M.locale = fh;
M.invalid = YO;
M.duration = oa;
M.isMoment = Ko;
M.weekdays = eet;
M.parseZone = QXe;
M.localeData = ql;
M.isDuration = m0;
M.monthsShort = XXe;
M.weekdaysMin = net;
M.defineLocale = uj;
M.updateLocale = QQe;
M.locales = ZQe;
M.weekdaysShort = tet;
M.normalizeUnits = ao;
M.relativeTimeRounding = Oet;
M.relativeTimeThreshold = Det;
M.calendarFormat = GZe;
M.prototype = C;
M.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
function Zce(t, e) {
  var n = {};
  for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function Xce() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const Met = Xce, ele = new Ib("auth", "Firebase", Xce()), Pk = new Cq("@firebase/auth");
function xet(t, ...e) {
  Pk.logLevel <= Pt.WARN && Pk.warn(`Auth (${Rb}): ${t}`, ...e);
}
function y0(t, ...e) {
  Pk.logLevel <= Pt.ERROR && Pk.error(`Auth (${Rb}): ${t}`, ...e);
}
function KC(t, ...e) {
  throw wj(t, ...e);
}
function vj(t, ...e) {
  return wj(t, ...e);
}
function Let(t, e, n) {
  const r = Object.assign(Object.assign({}, Met()), { [e]: n });
  return new Ib("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function wj(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return ele.create(t, ...e);
}
function et(t, e, ...n) {
  if (!t)
    throw wj(e, ...n);
}
function Pc(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw y0(e), new Error(e);
}
function Ok(t, e) {
  t || Pc(e);
}
function Uet() {
  return D9() === "http:" || D9() === "https:";
}
function D9() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
function Fet() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (Uet() || Vje() || "connection" in navigator) ? navigator.onLine : !0;
}
function Yet() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
class Vb {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, Ok(n > e, "Short delay should be less than long delay!"), this.isMobile = vq() || Dse();
  }
  get() {
    return Fet() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
function Wet(t, e) {
  Ok(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
class tle {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    Pc("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    Pc("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    Pc("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
const Bet = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
}, qet = new Vb(3e4, 6e4);
function zb(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Cp(t, e, n, r, s = {}) {
  return nle(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = wq(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), tle.fetch()(rle(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function nle(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, Bet), e);
  try {
    const s = new Het(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw fS(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, l] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw fS(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw fS(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw fS(t, "user-disabled", o);
      const h = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (l)
        throw Let(t, h, l);
      KC(t, h);
    }
  } catch (s) {
    if (s instanceof Yl)
      throw s;
    KC(t, "network-request-failed", { message: String(s) });
  }
}
async function Cj(t, e, n, r, s = {}) {
  const i = await Cp(t, e, n, r, s);
  return "mfaPendingCredential" in i && KC(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function rle(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? Wet(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class Het {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(vj(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), qet.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function fS(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = vj(t, e, r);
  return s.customData._tokenResponse = n, s;
}
async function jet(t, e) {
  return Cp(t, "POST", "/v1/accounts:delete", e);
}
async function Vet(t, e) {
  return Cp(t, "POST", "/v1/accounts:lookup", e);
}
function gw(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function zet(t, e = !1) {
  const n = hs(t), r = await n.getIdToken(e), s = sle(r);
  et(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: gw(Y1(s.auth_time)),
    issuedAtTime: gw(Y1(s.iat)),
    expirationTime: gw(Y1(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function Y1(t) {
  return Number(t) * 1e3;
}
function sle(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return y0("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = GR(n);
    return s ? JSON.parse(s) : (y0("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return y0("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function Get(t) {
  const e = sle(t);
  return et(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), et(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), et(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
async function Dk(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof Yl && $et(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function $et({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
class Ket {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const r = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, r);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
class ile {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = gw(this.lastLoginAt), this.creationTime = gw(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
async function Ak(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await Dk(t, Vet(n, { idToken: r }));
  et(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? Zet(i.providerUserInfo) : [], a = Qet(t.providerData, o), c = t.isAnonymous, l = !(t.email && i.passwordHash) && !(a != null && a.length), h = c ? l : !1, u = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new ile(i.createdAt, i.lastLoginAt),
    isAnonymous: h
  };
  Object.assign(t, u);
}
async function Jet(t) {
  const e = hs(t);
  await Ak(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function Qet(t, e) {
  return [...t.filter((n) => !e.some((r) => r.providerId === n.providerId)), ...e];
}
function Zet(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = Zce(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
async function Xet(t, e) {
  const n = await nle(t, {}, async () => {
    const r = wq({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = rle(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", tle.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
class JC {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    et(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), et(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), et(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : Get(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return et(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await Xet(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new JC();
    return r && (et(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (et(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (et(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new JC(), this.toJSON());
  }
  _performRefresh() {
    return Pc("not implemented");
  }
}
function au(t, e) {
  et(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class cf {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = Zce(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new Ket(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new ile(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await Dk(this, this.stsTokenManager.getToken(this.auth, e));
    return et(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return zet(this, e);
  }
  reload() {
    return Jet(this);
  }
  _assign(e) {
    this !== e && (et(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new cf(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    et(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await Ak(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await Dk(this, jet(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, l, h;
    const u = (r = n.displayName) !== null && r !== void 0 ? r : void 0, d = (s = n.email) !== null && s !== void 0 ? s : void 0, f = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, p = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, _ = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, m = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, v = (l = n.createdAt) !== null && l !== void 0 ? l : void 0, y = (h = n.lastLoginAt) !== null && h !== void 0 ? h : void 0, { uid: g, emailVerified: w, isAnonymous: de, providerData: we, stsTokenManager: fe } = n;
    et(
      g && fe,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const ys = JC.fromJSON(this.name, fe);
    et(
      typeof g == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), au(u, e.name), au(d, e.name), et(
      typeof w == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), et(
      typeof de == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), au(f, e.name), au(p, e.name), au(_, e.name), au(m, e.name), au(v, e.name), au(y, e.name);
    const En = new cf({
      uid: g,
      auth: e,
      email: d,
      emailVerified: w,
      displayName: u,
      isAnonymous: de,
      photoURL: p,
      phoneNumber: f,
      tenantId: _,
      stsTokenManager: ys,
      createdAt: v,
      lastLoginAt: y
    });
    return we && Array.isArray(we) && (En.providerData = we.map((vo) => Object.assign({}, vo))), m && (En._redirectEventId = m), En;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new JC();
    s.updateFromServerResponse(n);
    const i = new cf({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await Ak(i), i;
  }
}
const A9 = /* @__PURE__ */ new Map();
function Bd(t) {
  Ok(t instanceof Function, "Expected a class definition");
  let e = A9.get(t);
  return e ? (Ok(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), A9.set(t, e), e);
}
class ole {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
ole.type = "NONE";
const M9 = ole;
function W1(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class F_ {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = W1(this.userKey, s.apiKey, i), this.fullPersistenceKey = W1("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? cf._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new F_(Bd(M9), e, r);
    const s = (await Promise.all(n.map(async (l) => {
      if (await l._isAvailable())
        return l;
    }))).filter((l) => l);
    let i = s[0] || Bd(M9);
    const o = W1(r, e.config.apiKey, e.name);
    let a = null;
    for (const l of n)
      try {
        const h = await l._get(o);
        if (h) {
          const u = cf._fromJSON(e, h);
          l !== i && (a = u), i = l;
          break;
        }
      } catch {
      }
    const c = s.filter((l) => l._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new F_(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (l) => {
      if (l !== i)
        try {
          await l._remove(o);
        } catch {
        }
    })), new F_(i, e, r));
  }
}
function x9(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (rtt(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (ett(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (itt(e))
    return "Blackberry";
  if (ott(e))
    return "Webos";
  if (ttt(e))
    return "Safari";
  if ((e.includes("chrome/") || ntt(e)) && !e.includes("edge/"))
    return "Chrome";
  if (stt(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function ett(t = La()) {
  return /firefox\//i.test(t);
}
function ttt(t = La()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function ntt(t = La()) {
  return /crios\//i.test(t);
}
function rtt(t = La()) {
  return /iemobile/i.test(t);
}
function stt(t = La()) {
  return /android/i.test(t);
}
function itt(t = La()) {
  return /blackberry/i.test(t);
}
function ott(t = La()) {
  return /webos/i.test(t);
}
function ale(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = x9(La());
      break;
    case "Worker":
      n = `${x9(La())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${Rb}/${r}`;
}
async function cle(t, e) {
  return Cp(t, "GET", "/v2/recaptchaConfig", zb(t, e));
}
function L9(t) {
  return t !== void 0 && t.enterprise !== void 0;
}
class lle {
  constructor(e) {
    if (this.siteKey = "", this.emailPasswordEnabled = !1, e.recaptchaKey === void 0)
      throw new Error("recaptchaKey undefined");
    this.siteKey = e.recaptchaKey.split("/")[3], this.emailPasswordEnabled = e.recaptchaEnforcementState.some((n) => n.provider === "EMAIL_PASSWORD_PROVIDER" && n.enforcementState !== "OFF");
  }
}
function att() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function ctt(t) {
  return new Promise((e, n) => {
    const r = document.createElement("script");
    r.setAttribute("src", t), r.onload = e, r.onerror = (s) => {
      const i = vj(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = s, n(i);
    }, r.type = "text/javascript", r.charset = "UTF-8", att().appendChild(r);
  });
}
const ltt = "https://www.google.com/recaptcha/enterprise.js?render=", utt = "recaptcha-enterprise";
class ule {
  /**
   *
   * @param authExtern - The corresponding Firebase {@link Auth} instance.
   *
   */
  constructor(e) {
    this.type = utt, this.auth = JO(e);
  }
  /**
   * Executes the verification process.
   *
   * @returns A Promise for a token that can be used to assert the validity of a request.
   */
  async verify(e = "verify", n = !1) {
    async function r(i) {
      if (!n) {
        if (i.tenantId == null && i._agentRecaptchaConfig != null)
          return i._agentRecaptchaConfig.siteKey;
        if (i.tenantId != null && i._tenantRecaptchaConfigs[i.tenantId] !== void 0)
          return i._tenantRecaptchaConfigs[i.tenantId].siteKey;
      }
      return new Promise(async (o, a) => {
        cle(i, {
          clientType: "CLIENT_TYPE_WEB",
          version: "RECAPTCHA_ENTERPRISE"
          /* RecaptchaVersion.ENTERPRISE */
        }).then((c) => {
          if (c.recaptchaKey === void 0)
            a(new Error("recaptcha Enterprise site key undefined"));
          else {
            const l = new lle(c);
            return i.tenantId == null ? i._agentRecaptchaConfig = l : i._tenantRecaptchaConfigs[i.tenantId] = l, o(l.siteKey);
          }
        }).catch((c) => {
          a(c);
        });
      });
    }
    function s(i, o, a) {
      const c = window.grecaptcha;
      L9(c) ? c.enterprise.ready(() => {
        try {
          c.enterprise.execute(i, { action: e }).then((l) => {
            o(l);
          }).catch((l) => {
            a(l);
          });
        } catch (l) {
          a(l);
        }
      }) : a(Error("No reCAPTCHA enterprise script loaded."));
    }
    return new Promise((i, o) => {
      r(this.auth).then((a) => {
        if (!n && L9(window.grecaptcha))
          s(a, i, o);
        else {
          if (typeof window > "u") {
            o(new Error("RecaptchaVerifier is only supported in browser"));
            return;
          }
          ctt(ltt + a).then(() => {
            s(a, i, o);
          }).catch((c) => {
            o(c);
          });
        }
      }).catch((a) => {
        o(a);
      });
    });
  }
}
async function Mk(t, e, n, r = !1) {
  const s = new ule(t);
  let i;
  try {
    i = await s.verify(n);
  } catch {
    i = await s.verify(n, !0);
  }
  const o = Object.assign({}, e);
  return r ? Object.assign(o, { captchaResp: i }) : Object.assign(o, { captchaResponse: i }), Object.assign(o, {
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  }), Object.assign(o, {
    recaptchaVersion: "RECAPTCHA_ENTERPRISE"
    /* RecaptchaVersion.ENTERPRISE */
  }), o;
}
class htt {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
class dtt {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new U9(this), this.idTokenSubscription = new U9(this), this.beforeStateQueue = new htt(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = ele, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = Bd(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await F_.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && c != null && c.user && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return et(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await Ak(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = Yet();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? hs(e) : null;
    return n && et(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && et(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(Bd(e));
    });
  }
  async initializeRecaptchaConfig() {
    const e = await cle(this, {
      clientType: "CLIENT_TYPE_WEB",
      version: "RECAPTCHA_ENTERPRISE"
      /* RecaptchaVersion.ENTERPRISE */
    }), n = new lle(e);
    this.tenantId == null ? this._agentRecaptchaConfig = n : this._tenantRecaptchaConfigs[this.tenantId] = n, n.emailPasswordEnabled && new ule(this).verify();
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new Ib("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && Bd(e) || this._popupRedirectResolver;
      et(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await F_.create(
        this,
        [Bd(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return et(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, r, s) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return et(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = ale(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && xet(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function JO(t) {
  return hs(t);
}
class U9 {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = eVe((n) => this.observer = n);
  }
  get next() {
    return et(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function ftt(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(Bd);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
class ptt {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return Pc("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return Pc("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return Pc("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return Pc("not implemented");
  }
}
async function _tt(t, e) {
  return Cp(t, "POST", "/v1/accounts:update", e);
}
async function B1(t, e) {
  return Cj(t, "POST", "/v1/accounts:signInWithPassword", zb(t, e));
}
async function mtt(t, e) {
  return Cp(t, "POST", "/v1/accounts:sendOobCode", zb(t, e));
}
async function q1(t, e) {
  return mtt(t, e);
}
async function gtt(t, e) {
  return Cj(t, "POST", "/v1/accounts:signInWithEmailLink", zb(t, e));
}
async function ytt(t, e) {
  return Cj(t, "POST", "/v1/accounts:signInWithEmailLink", zb(t, e));
}
class QC extends ptt {
  /** @internal */
  constructor(e, n, r, s = null) {
    super("password", r), this._email = e, this._password = n, this._tenantId = s;
  }
  /** @internal */
  static _fromEmailAndPassword(e, n) {
    return new QC(
      e,
      n,
      "password"
      /* SignInMethod.EMAIL_PASSWORD */
    );
  }
  /** @internal */
  static _fromEmailAndCode(e, n, r = null) {
    return new QC(e, n, "emailLink", r);
  }
  /** {@inheritdoc AuthCredential.toJSON} */
  toJSON() {
    return {
      email: this._email,
      password: this._password,
      signInMethod: this.signInMethod,
      tenantId: this._tenantId
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an {@link  AuthCredential}.
   *
   * @param json - Either `object` or the stringified representation of the object. When string is
   * provided, `JSON.parse` would be called first.
   *
   * @returns If the JSON input does not represent an {@link AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e;
    if (n != null && n.email && n != null && n.password) {
      if (n.signInMethod === "password")
        return this._fromEmailAndPassword(n.email, n.password);
      if (n.signInMethod === "emailLink")
        return this._fromEmailAndCode(n.email, n.password, n.tenantId);
    }
    return null;
  }
  /** @internal */
  async _getIdTokenResponse(e) {
    var n;
    switch (this.signInMethod) {
      case "password":
        const r = {
          returnSecureToken: !0,
          email: this._email,
          password: this._password,
          clientType: "CLIENT_TYPE_WEB"
          /* RecaptchaClientType.WEB */
        };
        if (!((n = e._getRecaptchaConfig()) === null || n === void 0) && n.emailPasswordEnabled) {
          const s = await Mk(
            e,
            r,
            "signInWithPassword"
            /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
          );
          return B1(e, s);
        } else
          return B1(e, r).catch(async (s) => {
            if (s.code === "auth/missing-recaptcha-token") {
              console.log("Sign-in with email address and password is protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the sign-in flow.");
              const i = await Mk(
                e,
                r,
                "signInWithPassword"
                /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
              );
              return B1(e, i);
            } else
              return Promise.reject(s);
          });
      case "emailLink":
        return gtt(e, {
          email: this._email,
          oobCode: this._password
        });
      default:
        KC(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  async _linkToIdToken(e, n) {
    switch (this.signInMethod) {
      case "password":
        return _tt(e, {
          idToken: n,
          returnSecureToken: !0,
          email: this._email,
          password: this._password
        });
      case "emailLink":
        return ytt(e, {
          idToken: n,
          email: this._email,
          oobCode: this._password
        });
      default:
        KC(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return this._getIdTokenResponse(e);
  }
}
function vtt(t) {
  switch (t) {
    case "recoverEmail":
      return "RECOVER_EMAIL";
    case "resetPassword":
      return "PASSWORD_RESET";
    case "signIn":
      return "EMAIL_SIGNIN";
    case "verifyEmail":
      return "VERIFY_EMAIL";
    case "verifyAndChangeEmail":
      return "VERIFY_AND_CHANGE_EMAIL";
    case "revertSecondFactorAddition":
      return "REVERT_SECOND_FACTOR_ADDITION";
    default:
      return null;
  }
}
function wtt(t) {
  const e = Ev(Sv(t)).link, n = e ? Ev(Sv(e)).deep_link_id : null, r = Ev(Sv(t)).deep_link_id;
  return (r ? Ev(Sv(r)).link : null) || r || n || e || t;
}
class Tj {
  /**
   * @param actionLink - The link from which to extract the URL.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @internal
   */
  constructor(e) {
    var n, r, s, i, o, a;
    const c = Ev(Sv(e)), l = (n = c.apiKey) !== null && n !== void 0 ? n : null, h = (r = c.oobCode) !== null && r !== void 0 ? r : null, u = vtt((s = c.mode) !== null && s !== void 0 ? s : null);
    et(
      l && h && u,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), this.apiKey = l, this.operation = u, this.code = h, this.continueUrl = (i = c.continueUrl) !== null && i !== void 0 ? i : null, this.languageCode = (o = c.languageCode) !== null && o !== void 0 ? o : null, this.tenantId = (a = c.tenantId) !== null && a !== void 0 ? a : null;
  }
  /**
   * Parses the email action link string and returns an {@link ActionCodeURL} if the link is valid,
   * otherwise returns null.
   *
   * @param link  - The email action link string.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @public
   */
  static parseLink(e) {
    const n = wtt(e);
    try {
      return new Tj(n);
    } catch {
      return null;
    }
  }
}
class Ig {
  constructor() {
    this.providerId = Ig.PROVIDER_ID;
  }
  /**
   * Initialize an {@link AuthCredential} using an email and password.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credential(email, password);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * const userCredential = await signInWithEmailAndPassword(auth, email, password);
   * ```
   *
   * @param email - Email address.
   * @param password - User account password.
   * @returns The auth provider credential.
   */
  static credential(e, n) {
    return QC._fromEmailAndPassword(e, n);
  }
  /**
   * Initialize an {@link AuthCredential} using an email and an email link after a sign in with
   * email link operation.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credentialWithLink(auth, email, emailLink);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * await sendSignInLinkToEmail(auth, email);
   * // Obtain emailLink from user.
   * const userCredential = await signInWithEmailLink(auth, email, emailLink);
   * ```
   *
   * @param auth - The {@link Auth} instance used to verify the link.
   * @param email - Email address.
   * @param emailLink - Sign-in email link.
   * @returns - The auth provider credential.
   */
  static credentialWithLink(e, n) {
    const r = Tj.parseLink(n);
    return et(
      r,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), QC._fromEmailAndCode(e, r.code, r.tenantId);
  }
}
Ig.PROVIDER_ID = "password";
Ig.EMAIL_PASSWORD_SIGN_IN_METHOD = "password";
Ig.EMAIL_LINK_SIGN_IN_METHOD = "emailLink";
class xk {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, r, s = !1) {
    const i = await cf._fromIdTokenResponse(e, r, s), o = F9(r);
    return new xk({
      user: i,
      providerId: o,
      _tokenResponse: r,
      operationType: n
    });
  }
  static async _forOperation(e, n, r) {
    await e._updateTokensIfNecessary(
      r,
      /* reload */
      !0
    );
    const s = F9(r);
    return new xk({
      user: e,
      providerId: s,
      _tokenResponse: r,
      operationType: n
    });
  }
}
function F9(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
class Lk extends Yl {
  constructor(e, n, r, s) {
    var i;
    super(n.code, n.message), this.operationType = r, this.user = s, Object.setPrototypeOf(this, Lk.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: r
    };
  }
  static _fromErrorAndOperation(e, n, r, s) {
    return new Lk(e, n, r, s);
  }
}
function Ctt(t, e, n, r) {
  return n._getIdTokenResponse(t).catch((s) => {
    throw s.code === "auth/multi-factor-auth-required" ? Lk._fromErrorAndOperation(t, s, e, r) : s;
  });
}
async function Ttt(t, e, n = !1) {
  const r = "signIn", s = await Ctt(t, r, e), i = await xk._fromIdTokenResponse(t, r, s);
  return n || await t._updateCurrentUser(i.user), i;
}
async function btt(t, e) {
  return Ttt(JO(t), e);
}
async function Ett(t, e, n) {
  var r;
  const s = JO(t), i = {
    requestType: "PASSWORD_RESET",
    email: e,
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  };
  if (!((r = s._getRecaptchaConfig()) === null || r === void 0) && r.emailPasswordEnabled) {
    const o = await Mk(s, i, "getOobCode", !0);
    await q1(s, o);
  } else
    await q1(s, i).catch(async (o) => {
      if (o.code === "auth/missing-recaptcha-token") {
        console.log("Password resets are protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the password reset flow.");
        const a = await Mk(s, i, "getOobCode", !0);
        await q1(s, a);
      } else
        return Promise.reject(o);
    });
}
async function Stt(t, e) {
  return Cp(t, "POST", "/v1/accounts:update", e);
}
async function Y9(t, { displayName: e, photoURL: n }) {
  if (e === void 0 && n === void 0)
    return;
  const r = hs(t), s = {
    idToken: await r.getIdToken(),
    displayName: e,
    photoUrl: n,
    returnSecureToken: !0
  }, i = await Dk(r, Stt(r.auth, s));
  r.displayName = i.displayName || null, r.photoURL = i.photoUrl || null;
  const o = r.providerData.find(
    ({ providerId: a }) => a === "password"
    /* ProviderId.PASSWORD */
  );
  o && (o.displayName = r.displayName, o.photoURL = r.photoURL), await r._updateTokensIfNecessary(i);
}
new Vb(3e4, 6e4);
new Vb(2e3, 1e4);
new Vb(3e4, 6e4);
new Vb(5e3, 15e3);
var W9 = "@firebase/auth", B9 = "0.23.0";
class Itt {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    et(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
function Rtt(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function ktt(t) {
  Jf(new Yh(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      et(o && !o.includes(":"), "invalid-api-key", { appName: r.name }), et(!(a != null && a.includes(":")), "argument-error", {
        appName: r.name
      });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: ale(t)
      }, l = new dtt(r, s, i, c);
      return ftt(l, n), l;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), Jf(new Yh(
    "auth-internal",
    (e) => {
      const n = JO(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new Itt(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), tl(W9, B9, Rtt(t)), tl(W9, B9, "esm2017");
}
Hje("authIdTokenMaxAge");
ktt(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const q9 = {
  PICTURE_STORAGE: "/users/{uid}/UserProfilePic.jpg",
  PICTURE_DATE: "/users/{uid}/profilePicDate"
  // YYYY-MM-DD HH:mm:ss
};
class Zy {
  static PictureStorage(e) {
    return J(q9.PICTURE_STORAGE, { uid: e });
  }
  static PictureDate(e) {
    return J(q9.PICTURE_DATE, { uid: e });
  }
}
const { resizeImage: Ntt } = OJe;
class Ptt extends poe {
  constructor(e) {
    super(), this.auth = e;
  }
  async uploadProfilePicture(e) {
    try {
      let n = await Ntt({
        file: e,
        maxSize: 750
        //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
      }), r = { contentType: e.type };
      await this.uploadObject(Zy.PictureStorage(this.uid), n.blob, r);
      let s = await this.objectUrl(Zy.PictureStorage(this.uid));
      return console.log(`Profile photo updated: ${s}`), await this.updatePhoto(s), await this.updatePictureDate(), s;
    } catch (n) {
      throw console.error("Upload profile: " + n.message), n;
    }
  }
  async deleteProfilePicture() {
    try {
      const e = Zy.PictureStorage(this.uid);
      await this.deleteObject(e), await this.updatePhoto(""), await this.updatePictureDate();
    } catch (e) {
      throw console.error("Delete profile: " + e.message), e;
    }
  }
  async updatePictureDate() {
    return await this.set(Zy.PictureDate(this.uid), this.currentTime);
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
  get currentTime() {
    return M().format("YYYY-MM-DD HH:mm:ss");
  }
  async getPhotoUrl() {
    const e = Zy.PictureStorage(this.uid);
    return await this.objectUrl(e);
  }
  // get profilePictureExists() {
  //     (async () => {
  //         try {
  //             const path = ProfileResources.PictureStorage(this.uid);
  //             await this.objectUrl(path)
  //             return true;
  //         } catch(e) {
  //             return false;
  //         }
  //         })();
  // }
  async sendPasswordResetEmail() {
    try {
      return await Ett(this.auth, this.auth.currentUser.email);
    } catch (e) {
      throw console.error("Password reset: " + e.message), e;
    }
  }
  async updateEmail(e) {
    try {
      return await updateEmail(this.auth.currentUser, e);
    } catch (n) {
      throw console.error("Updating email address: " + n.message), n;
    }
  }
  async updateUserProfile(e) {
    try {
      return await Y9(this.auth.currentUser, {
        displayName: e.trim()
      });
    } catch (n) {
      throw console.error("Updating profile name: " + n.message), n;
    }
  }
  async updatePhoto(e) {
    try {
      return await Y9(this.auth.currentUser, {
        photoURL: e
      });
    } catch (n) {
      throw console.error("Updating profile photo: " + n.message), n;
    }
  }
  async reauthenticateWithCredential(e) {
    try {
      const n = Ig.credential(this.auth.currentUser.email, e);
      return await btt(this.auth, n);
    } catch (n) {
      throw console.error("Verifying credentials: " + n.message), n;
    }
  }
}
var v0 = { exports: {} };
function Ott(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
v0.exports = Ott;
const Dtt = (v0.exports == null ? {} : v0.exports).default || v0.exports;
var w0 = { exports: {} };
function Att(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
w0.exports = Att;
const Mtt = (w0.exports == null ? {} : w0.exports).default || w0.exports;
var C0 = { exports: {} };
const xtt = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
C0.exports = xtt;
const Ltt = (C0.exports == null ? {} : C0.exports).default || C0.exports;
var T0 = { exports: {} };
function Utt(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
T0.exports = Utt;
const Ftt = (T0.exports == null ? {} : T0.exports).default || T0.exports;
var b0 = { exports: {} };
class Ytt {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var Wtt = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new Ytt(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
b0.exports = Wtt;
const Btt = (b0.exports == null ? {} : b0.exports).default || b0.exports, qtt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Analytics: wje,
  BillingResources: Oje,
  CatalogFirebaseResources: bje,
  CatalogHostedProvider: Nje,
  CatalogHostedResources: Ui,
  CatalogPromotions: Dje,
  CatalogPropertyMap: ma,
  DataAccessLayer: Sse,
  Environment: N_,
  MailingListProvider: jGe,
  MailingListResources: Kp,
  ProfileProvider: Ptt,
  PurchaseResources: Pje,
  RequestResources: gq,
  allMap: PU,
  allSettledMap: PU,
  cyrb53: Dtt,
  empty: Mtt,
  isEmpty: Ltt,
  isNumeric: Ftt,
  resizeImage: Btt,
  splitProduct: n0,
  templ8r: J
}, Symbol.toStringTag, { value: "Module" }));
var hle;
function x() {
  return hle.apply(null, arguments);
}
function Htt(t) {
  hle = t;
}
function Jo(t) {
  return t instanceof Array || Object.prototype.toString.call(t) === "[object Array]";
}
function lf(t) {
  return t != null && Object.prototype.toString.call(t) === "[object Object]";
}
function pt(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function bj(t) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(t).length === 0;
  var e;
  for (e in t)
    if (pt(t, e))
      return !1;
  return !0;
}
function ti(t) {
  return t === void 0;
}
function El(t) {
  return typeof t == "number" || Object.prototype.toString.call(t) === "[object Number]";
}
function Gb(t) {
  return t instanceof Date || Object.prototype.toString.call(t) === "[object Date]";
}
function dle(t, e) {
  var n = [], r, s = t.length;
  for (r = 0; r < s; ++r)
    n.push(e(t[r], r));
  return n;
}
function Nu(t, e) {
  for (var n in e)
    pt(e, n) && (t[n] = e[n]);
  return pt(e, "toString") && (t.toString = e.toString), pt(e, "valueOf") && (t.valueOf = e.valueOf), t;
}
function Za(t, e, n, r) {
  return xle(t, e, n, r, !0).utc();
}
function jtt() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function _e(t) {
  return t._pf == null && (t._pf = jtt()), t._pf;
}
var kF;
Array.prototype.some ? kF = Array.prototype.some : kF = function(t) {
  var e = Object(this), n = e.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in e && t.call(this, e[r], r, e))
      return !0;
  return !1;
};
function Ej(t) {
  var e = null, n = !1, r = t._d && !isNaN(t._d.getTime());
  if (r && (e = _e(t), n = kF.call(e.parsedDateParts, function(s) {
    return s != null;
  }), r = e.overflow < 0 && !e.empty && !e.invalidEra && !e.invalidMonth && !e.invalidWeekday && !e.weekdayMismatch && !e.nullInput && !e.invalidFormat && !e.userInvalidated && (!e.meridiem || e.meridiem && n), t._strict && (r = r && e.charsLeftOver === 0 && e.unusedTokens.length === 0 && e.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(t))
    t._isValid = r;
  else
    return r;
  return t._isValid;
}
function QO(t) {
  var e = Za(NaN);
  return t != null ? Nu(_e(e), t) : _e(e).userInvalidated = !0, e;
}
var H9 = x.momentProperties = [], H1 = !1;
function Sj(t, e) {
  var n, r, s, i = H9.length;
  if (ti(e._isAMomentObject) || (t._isAMomentObject = e._isAMomentObject), ti(e._i) || (t._i = e._i), ti(e._f) || (t._f = e._f), ti(e._l) || (t._l = e._l), ti(e._strict) || (t._strict = e._strict), ti(e._tzm) || (t._tzm = e._tzm), ti(e._isUTC) || (t._isUTC = e._isUTC), ti(e._offset) || (t._offset = e._offset), ti(e._pf) || (t._pf = _e(e)), ti(e._locale) || (t._locale = e._locale), i > 0)
    for (n = 0; n < i; n++)
      r = H9[n], s = e[r], ti(s) || (t[r] = s);
  return t;
}
function $b(t) {
  Sj(this, t), this._d = new Date(t._d != null ? t._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), H1 === !1 && (H1 = !0, x.updateOffset(this), H1 = !1);
}
function Qo(t) {
  return t instanceof $b || t != null && t._isAMomentObject != null;
}
function fle(t) {
  x.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + t);
}
function co(t, e) {
  var n = !0;
  return Nu(function() {
    if (x.deprecationHandler != null && x.deprecationHandler(null, t), n) {
      var r = [], s, i, o, a = arguments.length;
      for (i = 0; i < a; i++) {
        if (s = "", typeof arguments[i] == "object") {
          s += `
[` + i + "] ";
          for (o in arguments[0])
            pt(arguments[0], o) && (s += o + ": " + arguments[0][o] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[i];
        r.push(s);
      }
      fle(
        t + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return e.apply(this, arguments);
  }, e);
}
var j9 = {};
function ple(t, e) {
  x.deprecationHandler != null && x.deprecationHandler(t, e), j9[t] || (fle(e), j9[t] = !0);
}
x.suppressDeprecationWarnings = !1;
x.deprecationHandler = null;
function Xa(t) {
  return typeof Function < "u" && t instanceof Function || Object.prototype.toString.call(t) === "[object Function]";
}
function Vtt(t) {
  var e, n;
  for (n in t)
    pt(t, n) && (e = t[n], Xa(e) ? this[n] = e : this["_" + n] = e);
  this._config = t, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function NF(t, e) {
  var n = Nu({}, t), r;
  for (r in e)
    pt(e, r) && (lf(t[r]) && lf(e[r]) ? (n[r] = {}, Nu(n[r], t[r]), Nu(n[r], e[r])) : e[r] != null ? n[r] = e[r] : delete n[r]);
  for (r in t)
    pt(t, r) && !pt(e, r) && lf(t[r]) && (n[r] = Nu({}, n[r]));
  return n;
}
function Ij(t) {
  t != null && this.set(t);
}
var PF;
Object.keys ? PF = Object.keys : PF = function(t) {
  var e, n = [];
  for (e in t)
    pt(t, e) && n.push(e);
  return n;
};
var ztt = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function Gtt(t, e, n) {
  var r = this._calendar[t] || this._calendar.sameElse;
  return Xa(r) ? r.call(e, n) : r;
}
function Fa(t, e, n) {
  var r = "" + Math.abs(t), s = e - r.length, i = t >= 0;
  return (i ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var Rj = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, pS = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, j1 = {}, Y_ = {};
function ee(t, e, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), t && (Y_[t] = s), e && (Y_[e[0]] = function() {
    return Fa(s.apply(this, arguments), e[1], e[2]);
  }), n && (Y_[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      t
    );
  });
}
function $tt(t) {
  return t.match(/\[[\s\S]/) ? t.replace(/^\[|\]$/g, "") : t.replace(/\\/g, "");
}
function Ktt(t) {
  var e = t.match(Rj), n, r;
  for (n = 0, r = e.length; n < r; n++)
    Y_[e[n]] ? e[n] = Y_[e[n]] : e[n] = $tt(e[n]);
  return function(s) {
    var i = "", o;
    for (o = 0; o < r; o++)
      i += Xa(e[o]) ? e[o].call(s, t) : e[o];
    return i;
  };
}
function E0(t, e) {
  return t.isValid() ? (e = _le(e, t.localeData()), j1[e] = j1[e] || Ktt(e), j1[e](t)) : t.localeData().invalidDate();
}
function _le(t, e) {
  var n = 5;
  function r(s) {
    return e.longDateFormat(s) || s;
  }
  for (pS.lastIndex = 0; n >= 0 && pS.test(t); )
    t = t.replace(
      pS,
      r
    ), pS.lastIndex = 0, n -= 1;
  return t;
}
var Jtt = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function Qtt(t) {
  var e = this._longDateFormat[t], n = this._longDateFormat[t.toUpperCase()];
  return e || !n ? e : (this._longDateFormat[t] = n.match(Rj).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[t]);
}
var Ztt = "Invalid date";
function Xtt() {
  return this._invalidDate;
}
var ent = "%d", tnt = /\d{1,2}/;
function nnt(t) {
  return this._ordinal.replace("%d", t);
}
var rnt = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function snt(t, e, n, r) {
  var s = this._relativeTime[n];
  return Xa(s) ? s(t, e, n, r) : s.replace(/%d/i, t);
}
function int(t, e) {
  var n = this._relativeTime[t > 0 ? "future" : "past"];
  return Xa(n) ? n(e) : n.replace(/%s/i, e);
}
var V9 = {
  D: "date",
  dates: "date",
  date: "date",
  d: "day",
  days: "day",
  day: "day",
  e: "weekday",
  weekdays: "weekday",
  weekday: "weekday",
  E: "isoWeekday",
  isoweekdays: "isoWeekday",
  isoweekday: "isoWeekday",
  DDD: "dayOfYear",
  dayofyears: "dayOfYear",
  dayofyear: "dayOfYear",
  h: "hour",
  hours: "hour",
  hour: "hour",
  ms: "millisecond",
  milliseconds: "millisecond",
  millisecond: "millisecond",
  m: "minute",
  minutes: "minute",
  minute: "minute",
  M: "month",
  months: "month",
  month: "month",
  Q: "quarter",
  quarters: "quarter",
  quarter: "quarter",
  s: "second",
  seconds: "second",
  second: "second",
  gg: "weekYear",
  weekyears: "weekYear",
  weekyear: "weekYear",
  GG: "isoWeekYear",
  isoweekyears: "isoWeekYear",
  isoweekyear: "isoWeekYear",
  w: "week",
  weeks: "week",
  week: "week",
  W: "isoWeek",
  isoweeks: "isoWeek",
  isoweek: "isoWeek",
  y: "year",
  years: "year",
  year: "year"
};
function lo(t) {
  return typeof t == "string" ? V9[t] || V9[t.toLowerCase()] : void 0;
}
function kj(t) {
  var e = {}, n, r;
  for (r in t)
    pt(t, r) && (n = lo(r), n && (e[n] = t[r]));
  return e;
}
var ont = {
  date: 9,
  day: 11,
  weekday: 11,
  isoWeekday: 11,
  dayOfYear: 4,
  hour: 13,
  millisecond: 16,
  minute: 14,
  month: 8,
  quarter: 7,
  second: 15,
  weekYear: 1,
  isoWeekYear: 1,
  week: 5,
  isoWeek: 5,
  year: 1
};
function ant(t) {
  var e = [], n;
  for (n in t)
    pt(t, n) && e.push({ unit: n, priority: ont[n] });
  return e.sort(function(r, s) {
    return r.priority - s.priority;
  }), e;
}
var mle = /\d/, ki = /\d\d/, gle = /\d{3}/, Nj = /\d{4}/, ZO = /[+-]?\d{6}/, fn = /\d\d?/, yle = /\d\d\d\d?/, vle = /\d\d\d\d\d\d?/, XO = /\d{1,3}/, Pj = /\d{1,4}/, eD = /[+-]?\d{1,6}/, Rg = /\d+/, tD = /[+-]?\d+/, cnt = /Z|[+-]\d\d:?\d\d/gi, nD = /Z|[+-]\d\d(?::?\d\d)?/gi, lnt = /[+-]?\d+(\.\d{1,3})?/, Kb = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, kg = /^[1-9]\d?/, Oj = /^([1-9]\d|\d)/, Uk;
Uk = {};
function B(t, e, n) {
  Uk[t] = Xa(e) ? e : function(r, s) {
    return r && n ? n : e;
  };
}
function unt(t, e) {
  return pt(Uk, t) ? Uk[t](e._strict, e._locale) : new RegExp(hnt(t));
}
function hnt(t) {
  return ol(
    t.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(e, n, r, s, i) {
        return n || r || s || i;
      }
    )
  );
}
function ol(t) {
  return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
function Wi(t) {
  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
}
function He(t) {
  var e = +t, n = 0;
  return e !== 0 && isFinite(e) && (n = Wi(e)), n;
}
var OF = {};
function Yt(t, e) {
  var n, r = e, s;
  for (typeof t == "string" && (t = [t]), El(e) && (r = function(i, o) {
    o[e] = He(i);
  }), s = t.length, n = 0; n < s; n++)
    OF[t[n]] = r;
}
function Jb(t, e) {
  Yt(t, function(n, r, s, i) {
    s._w = s._w || {}, e(n, s._w, s, i);
  });
}
function dnt(t, e, n) {
  e != null && pt(OF, t) && OF[t](e, n._a, n, t);
}
function rD(t) {
  return t % 4 === 0 && t % 100 !== 0 || t % 400 === 0;
}
var fs = 0, Oc = 1, va = 2, wr = 3, Lo = 4, Dc = 5, qd = 6, fnt = 7, pnt = 8;
ee("Y", 0, 0, function() {
  var t = this.year();
  return t <= 9999 ? Fa(t, 4) : "+" + t;
});
ee(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
ee(0, ["YYYY", 4], 0, "year");
ee(0, ["YYYYY", 5], 0, "year");
ee(0, ["YYYYYY", 6, !0], 0, "year");
B("Y", tD);
B("YY", fn, ki);
B("YYYY", Pj, Nj);
B("YYYYY", eD, ZO);
B("YYYYYY", eD, ZO);
Yt(["YYYYY", "YYYYYY"], fs);
Yt("YYYY", function(t, e) {
  e[fs] = t.length === 2 ? x.parseTwoDigitYear(t) : He(t);
});
Yt("YY", function(t, e) {
  e[fs] = x.parseTwoDigitYear(t);
});
Yt("Y", function(t, e) {
  e[fs] = parseInt(t, 10);
});
function yw(t) {
  return rD(t) ? 366 : 365;
}
x.parseTwoDigitYear = function(t) {
  return He(t) + (He(t) > 68 ? 1900 : 2e3);
};
var wle = Ng("FullYear", !0);
function _nt() {
  return rD(this.year());
}
function Ng(t, e) {
  return function(n) {
    return n != null ? (Cle(this, t, n), x.updateOffset(this, e), this) : ZC(this, t);
  };
}
function ZC(t, e) {
  if (!t.isValid())
    return NaN;
  var n = t._d, r = t._isUTC;
  switch (e) {
    case "Milliseconds":
      return r ? n.getUTCMilliseconds() : n.getMilliseconds();
    case "Seconds":
      return r ? n.getUTCSeconds() : n.getSeconds();
    case "Minutes":
      return r ? n.getUTCMinutes() : n.getMinutes();
    case "Hours":
      return r ? n.getUTCHours() : n.getHours();
    case "Date":
      return r ? n.getUTCDate() : n.getDate();
    case "Day":
      return r ? n.getUTCDay() : n.getDay();
    case "Month":
      return r ? n.getUTCMonth() : n.getMonth();
    case "FullYear":
      return r ? n.getUTCFullYear() : n.getFullYear();
    default:
      return NaN;
  }
}
function Cle(t, e, n) {
  var r, s, i, o, a;
  if (!(!t.isValid() || isNaN(n))) {
    switch (r = t._d, s = t._isUTC, e) {
      case "Milliseconds":
        return void (s ? r.setUTCMilliseconds(n) : r.setMilliseconds(n));
      case "Seconds":
        return void (s ? r.setUTCSeconds(n) : r.setSeconds(n));
      case "Minutes":
        return void (s ? r.setUTCMinutes(n) : r.setMinutes(n));
      case "Hours":
        return void (s ? r.setUTCHours(n) : r.setHours(n));
      case "Date":
        return void (s ? r.setUTCDate(n) : r.setDate(n));
      case "FullYear":
        break;
      default:
        return;
    }
    i = n, o = t.month(), a = t.date(), a = a === 29 && o === 1 && !rD(i) ? 28 : a, s ? r.setUTCFullYear(i, o, a) : r.setFullYear(i, o, a);
  }
}
function mnt(t) {
  return t = lo(t), Xa(this[t]) ? this[t]() : this;
}
function gnt(t, e) {
  if (typeof t == "object") {
    t = kj(t);
    var n = ant(t), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](t[n[r].unit]);
  } else if (t = lo(t), Xa(this[t]))
    return this[t](e);
  return this;
}
function ynt(t, e) {
  return (t % e + e) % e;
}
var er;
Array.prototype.indexOf ? er = Array.prototype.indexOf : er = function(t) {
  var e;
  for (e = 0; e < this.length; ++e)
    if (this[e] === t)
      return e;
  return -1;
};
function Dj(t, e) {
  if (isNaN(t) || isNaN(e))
    return NaN;
  var n = ynt(e, 12);
  return t += (e - n) / 12, n === 1 ? rD(t) ? 29 : 28 : 31 - n % 7 % 2;
}
ee("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
ee("MMM", 0, 0, function(t) {
  return this.localeData().monthsShort(this, t);
});
ee("MMMM", 0, 0, function(t) {
  return this.localeData().months(this, t);
});
B("M", fn, kg);
B("MM", fn, ki);
B("MMM", function(t, e) {
  return e.monthsShortRegex(t);
});
B("MMMM", function(t, e) {
  return e.monthsRegex(t);
});
Yt(["M", "MM"], function(t, e) {
  e[Oc] = He(t) - 1;
});
Yt(["MMM", "MMMM"], function(t, e, n, r) {
  var s = n._locale.monthsParse(t, r, n._strict);
  s != null ? e[Oc] = s : _e(n).invalidMonth = t;
});
var vnt = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), Tle = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), ble = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, wnt = Kb, Cnt = Kb;
function Tnt(t, e) {
  return t ? Jo(this._months) ? this._months[t.month()] : this._months[(this._months.isFormat || ble).test(e) ? "format" : "standalone"][t.month()] : Jo(this._months) ? this._months : this._months.standalone;
}
function bnt(t, e) {
  return t ? Jo(this._monthsShort) ? this._monthsShort[t.month()] : this._monthsShort[ble.test(e) ? "format" : "standalone"][t.month()] : Jo(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function Ent(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      i = Za([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        i,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(i, "").toLocaleLowerCase();
  return n ? e === "MMM" ? (s = er.call(this._shortMonthsParse, o), s !== -1 ? s : null) : (s = er.call(this._longMonthsParse, o), s !== -1 ? s : null) : e === "MMM" ? (s = er.call(this._shortMonthsParse, o), s !== -1 ? s : (s = er.call(this._longMonthsParse, o), s !== -1 ? s : null)) : (s = er.call(this._longMonthsParse, o), s !== -1 ? s : (s = er.call(this._shortMonthsParse, o), s !== -1 ? s : null));
}
function Snt(t, e, n) {
  var r, s, i;
  if (this._monthsParseExact)
    return Ent.call(this, t, e, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++)
    if (s = Za([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (i = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "MMMM" && this._longMonthsParse[r].test(t) || n && e === "MMM" && this._shortMonthsParse[r].test(t) || !n && this._monthsParse[r].test(t))
      return r;
}
function Ele(t, e) {
  if (!t.isValid())
    return t;
  if (typeof e == "string") {
    if (/^\d+$/.test(e))
      e = He(e);
    else if (e = t.localeData().monthsParse(e), !El(e))
      return t;
  }
  var n = e, r = t.date();
  return r = r < 29 ? r : Math.min(r, Dj(t.year(), n)), t._isUTC ? t._d.setUTCMonth(n, r) : t._d.setMonth(n, r), t;
}
function Sle(t) {
  return t != null ? (Ele(this, t), x.updateOffset(this, !0), this) : ZC(this, "Month");
}
function Int() {
  return Dj(this.year(), this.month());
}
function Rnt(t) {
  return this._monthsParseExact ? (pt(this, "_monthsRegex") || Ile.call(this), t ? this._monthsShortStrictRegex : this._monthsShortRegex) : (pt(this, "_monthsShortRegex") || (this._monthsShortRegex = wnt), this._monthsShortStrictRegex && t ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function knt(t) {
  return this._monthsParseExact ? (pt(this, "_monthsRegex") || Ile.call(this), t ? this._monthsStrictRegex : this._monthsRegex) : (pt(this, "_monthsRegex") || (this._monthsRegex = Cnt), this._monthsStrictRegex && t ? this._monthsStrictRegex : this._monthsRegex);
}
function Ile() {
  function t(c, l) {
    return l.length - c.length;
  }
  var e = [], n = [], r = [], s, i, o, a;
  for (s = 0; s < 12; s++)
    i = Za([2e3, s]), o = ol(this.monthsShort(i, "")), a = ol(this.months(i, "")), e.push(o), n.push(a), r.push(a), r.push(o);
  e.sort(t), n.sort(t), r.sort(t), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function Nnt(t, e, n, r, s, i, o) {
  var a;
  return t < 100 && t >= 0 ? (a = new Date(t + 400, e, n, r, s, i, o), isFinite(a.getFullYear()) && a.setFullYear(t)) : a = new Date(t, e, n, r, s, i, o), a;
}
function XC(t) {
  var e, n;
  return t < 100 && t >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = t + 400, e = new Date(Date.UTC.apply(null, n)), isFinite(e.getUTCFullYear()) && e.setUTCFullYear(t)) : e = new Date(Date.UTC.apply(null, arguments)), e;
}
function Fk(t, e, n) {
  var r = 7 + e - n, s = (7 + XC(t, 0, r).getUTCDay() - e) % 7;
  return -s + r - 1;
}
function Rle(t, e, n, r, s) {
  var i = (7 + n - r) % 7, o = Fk(t, r, s), a = 1 + 7 * (e - 1) + i + o, c, l;
  return a <= 0 ? (c = t - 1, l = yw(c) + a) : a > yw(t) ? (c = t + 1, l = a - yw(t)) : (c = t, l = a), {
    year: c,
    dayOfYear: l
  };
}
function eT(t, e, n) {
  var r = Fk(t.year(), e, n), s = Math.floor((t.dayOfYear() - r - 1) / 7) + 1, i, o;
  return s < 1 ? (o = t.year() - 1, i = s + al(o, e, n)) : s > al(t.year(), e, n) ? (i = s - al(t.year(), e, n), o = t.year() + 1) : (o = t.year(), i = s), {
    week: i,
    year: o
  };
}
function al(t, e, n) {
  var r = Fk(t, e, n), s = Fk(t + 1, e, n);
  return (yw(t) - r + s) / 7;
}
ee("w", ["ww", 2], "wo", "week");
ee("W", ["WW", 2], "Wo", "isoWeek");
B("w", fn, kg);
B("ww", fn, ki);
B("W", fn, kg);
B("WW", fn, ki);
Jb(
  ["w", "ww", "W", "WW"],
  function(t, e, n, r) {
    e[r.substr(0, 1)] = He(t);
  }
);
function Pnt(t) {
  return eT(t, this._week.dow, this._week.doy).week;
}
var Ont = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function Dnt() {
  return this._week.dow;
}
function Ant() {
  return this._week.doy;
}
function Mnt(t) {
  var e = this.localeData().week(this);
  return t == null ? e : this.add((t - e) * 7, "d");
}
function xnt(t) {
  var e = eT(this, 1, 4).week;
  return t == null ? e : this.add((t - e) * 7, "d");
}
ee("d", 0, "do", "day");
ee("dd", 0, 0, function(t) {
  return this.localeData().weekdaysMin(this, t);
});
ee("ddd", 0, 0, function(t) {
  return this.localeData().weekdaysShort(this, t);
});
ee("dddd", 0, 0, function(t) {
  return this.localeData().weekdays(this, t);
});
ee("e", 0, 0, "weekday");
ee("E", 0, 0, "isoWeekday");
B("d", fn);
B("e", fn);
B("E", fn);
B("dd", function(t, e) {
  return e.weekdaysMinRegex(t);
});
B("ddd", function(t, e) {
  return e.weekdaysShortRegex(t);
});
B("dddd", function(t, e) {
  return e.weekdaysRegex(t);
});
Jb(["dd", "ddd", "dddd"], function(t, e, n, r) {
  var s = n._locale.weekdaysParse(t, r, n._strict);
  s != null ? e.d = s : _e(n).invalidWeekday = t;
});
Jb(["d", "e", "E"], function(t, e, n, r) {
  e[r] = He(t);
});
function Lnt(t, e) {
  return typeof t != "string" ? t : isNaN(t) ? (t = e.weekdaysParse(t), typeof t == "number" ? t : null) : parseInt(t, 10);
}
function Unt(t, e) {
  return typeof t == "string" ? e.weekdaysParse(t) % 7 || 7 : isNaN(t) ? null : t;
}
function Aj(t, e) {
  return t.slice(e, 7).concat(t.slice(0, e));
}
var Fnt = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), kle = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), Ynt = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), Wnt = Kb, Bnt = Kb, qnt = Kb;
function Hnt(t, e) {
  var n = Jo(this._weekdays) ? this._weekdays : this._weekdays[t && t !== !0 && this._weekdays.isFormat.test(e) ? "format" : "standalone"];
  return t === !0 ? Aj(n, this._week.dow) : t ? n[t.day()] : n;
}
function jnt(t) {
  return t === !0 ? Aj(this._weekdaysShort, this._week.dow) : t ? this._weekdaysShort[t.day()] : this._weekdaysShort;
}
function Vnt(t) {
  return t === !0 ? Aj(this._weekdaysMin, this._week.dow) : t ? this._weekdaysMin[t.day()] : this._weekdaysMin;
}
function znt(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      i = Za([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        i,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        i,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(i, "").toLocaleLowerCase();
  return n ? e === "dddd" ? (s = er.call(this._weekdaysParse, o), s !== -1 ? s : null) : e === "ddd" ? (s = er.call(this._shortWeekdaysParse, o), s !== -1 ? s : null) : (s = er.call(this._minWeekdaysParse, o), s !== -1 ? s : null) : e === "dddd" ? (s = er.call(this._weekdaysParse, o), s !== -1 || (s = er.call(this._shortWeekdaysParse, o), s !== -1) ? s : (s = er.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : e === "ddd" ? (s = er.call(this._shortWeekdaysParse, o), s !== -1 || (s = er.call(this._weekdaysParse, o), s !== -1) ? s : (s = er.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : (s = er.call(this._minWeekdaysParse, o), s !== -1 || (s = er.call(this._weekdaysParse, o), s !== -1) ? s : (s = er.call(this._shortWeekdaysParse, o), s !== -1 ? s : null));
}
function Gnt(t, e, n) {
  var r, s, i;
  if (this._weekdaysParseExact)
    return znt.call(this, t, e, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++)
    if (s = Za([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (i = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "dddd" && this._fullWeekdaysParse[r].test(t) || n && e === "ddd" && this._shortWeekdaysParse[r].test(t) || n && e === "dd" && this._minWeekdaysParse[r].test(t) || !n && this._weekdaysParse[r].test(t))
      return r;
}
function $nt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = ZC(this, "Day");
  return t != null ? (t = Lnt(t, this.localeData()), this.add(t - e, "d")) : e;
}
function Knt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return t == null ? e : this.add(t - e, "d");
}
function Jnt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    var e = Unt(t, this.localeData());
    return this.day(this.day() % 7 ? e : e - 7);
  } else
    return this.day() || 7;
}
function Qnt(t) {
  return this._weekdaysParseExact ? (pt(this, "_weekdaysRegex") || Mj.call(this), t ? this._weekdaysStrictRegex : this._weekdaysRegex) : (pt(this, "_weekdaysRegex") || (this._weekdaysRegex = Wnt), this._weekdaysStrictRegex && t ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function Znt(t) {
  return this._weekdaysParseExact ? (pt(this, "_weekdaysRegex") || Mj.call(this), t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (pt(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = Bnt), this._weekdaysShortStrictRegex && t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function Xnt(t) {
  return this._weekdaysParseExact ? (pt(this, "_weekdaysRegex") || Mj.call(this), t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (pt(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = qnt), this._weekdaysMinStrictRegex && t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function Mj() {
  function t(h, u) {
    return u.length - h.length;
  }
  var e = [], n = [], r = [], s = [], i, o, a, c, l;
  for (i = 0; i < 7; i++)
    o = Za([2e3, 1]).day(i), a = ol(this.weekdaysMin(o, "")), c = ol(this.weekdaysShort(o, "")), l = ol(this.weekdays(o, "")), e.push(a), n.push(c), r.push(l), s.push(a), s.push(c), s.push(l);
  e.sort(t), n.sort(t), r.sort(t), s.sort(t), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function xj() {
  return this.hours() % 12 || 12;
}
function ert() {
  return this.hours() || 24;
}
ee("H", ["HH", 2], 0, "hour");
ee("h", ["hh", 2], 0, xj);
ee("k", ["kk", 2], 0, ert);
ee("hmm", 0, 0, function() {
  return "" + xj.apply(this) + Fa(this.minutes(), 2);
});
ee("hmmss", 0, 0, function() {
  return "" + xj.apply(this) + Fa(this.minutes(), 2) + Fa(this.seconds(), 2);
});
ee("Hmm", 0, 0, function() {
  return "" + this.hours() + Fa(this.minutes(), 2);
});
ee("Hmmss", 0, 0, function() {
  return "" + this.hours() + Fa(this.minutes(), 2) + Fa(this.seconds(), 2);
});
function Nle(t, e) {
  ee(t, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      e
    );
  });
}
Nle("a", !0);
Nle("A", !1);
function Ple(t, e) {
  return e._meridiemParse;
}
B("a", Ple);
B("A", Ple);
B("H", fn, Oj);
B("h", fn, kg);
B("k", fn, kg);
B("HH", fn, ki);
B("hh", fn, ki);
B("kk", fn, ki);
B("hmm", yle);
B("hmmss", vle);
B("Hmm", yle);
B("Hmmss", vle);
Yt(["H", "HH"], wr);
Yt(["k", "kk"], function(t, e, n) {
  var r = He(t);
  e[wr] = r === 24 ? 0 : r;
});
Yt(["a", "A"], function(t, e, n) {
  n._isPm = n._locale.isPM(t), n._meridiem = t;
});
Yt(["h", "hh"], function(t, e, n) {
  e[wr] = He(t), _e(n).bigHour = !0;
});
Yt("hmm", function(t, e, n) {
  var r = t.length - 2;
  e[wr] = He(t.substr(0, r)), e[Lo] = He(t.substr(r)), _e(n).bigHour = !0;
});
Yt("hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[wr] = He(t.substr(0, r)), e[Lo] = He(t.substr(r, 2)), e[Dc] = He(t.substr(s)), _e(n).bigHour = !0;
});
Yt("Hmm", function(t, e, n) {
  var r = t.length - 2;
  e[wr] = He(t.substr(0, r)), e[Lo] = He(t.substr(r));
});
Yt("Hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[wr] = He(t.substr(0, r)), e[Lo] = He(t.substr(r, 2)), e[Dc] = He(t.substr(s));
});
function trt(t) {
  return (t + "").toLowerCase().charAt(0) === "p";
}
var nrt = /[ap]\.?m?\.?/i, rrt = Ng("Hours", !0);
function srt(t, e, n) {
  return t > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var Ole = {
  calendar: ztt,
  longDateFormat: Jtt,
  invalidDate: Ztt,
  ordinal: ent,
  dayOfMonthOrdinalParse: tnt,
  relativeTime: rnt,
  months: vnt,
  monthsShort: Tle,
  week: Ont,
  weekdays: Fnt,
  weekdaysMin: Ynt,
  weekdaysShort: kle,
  meridiemParse: nrt
}, vn = {}, Xy = {}, tT;
function irt(t, e) {
  var n, r = Math.min(t.length, e.length);
  for (n = 0; n < r; n += 1)
    if (t[n] !== e[n])
      return n;
  return r;
}
function z9(t) {
  return t && t.toLowerCase().replace("_", "-");
}
function ort(t) {
  for (var e = 0, n, r, s, i; e < t.length; ) {
    for (i = z9(t[e]).split("-"), n = i.length, r = z9(t[e + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = sD(i.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && irt(i, r) >= n - 1)
        break;
      n--;
    }
    e++;
  }
  return tT;
}
function art(t) {
  return !!(t && t.match("^[^/\\\\]*$"));
}
function sD(t) {
  var e = null, n;
  if (vn[t] === void 0 && typeof module < "u" && module && module.exports && art(t))
    try {
      e = tT._abbr, n = require, n("./locale/" + t), ph(e);
    } catch {
      vn[t] = null;
    }
  return vn[t];
}
function ph(t, e) {
  var n;
  return t && (ti(e) ? n = jl(t) : n = Lj(t, e), n ? tT = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + t + " not found. Did you forget to load it?"
  )), tT._abbr;
}
function Lj(t, e) {
  if (e !== null) {
    var n, r = Ole;
    if (e.abbr = t, vn[t] != null)
      ple(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = vn[t]._config;
    else if (e.parentLocale != null)
      if (vn[e.parentLocale] != null)
        r = vn[e.parentLocale]._config;
      else if (n = sD(e.parentLocale), n != null)
        r = n._config;
      else
        return Xy[e.parentLocale] || (Xy[e.parentLocale] = []), Xy[e.parentLocale].push({
          name: t,
          config: e
        }), null;
    return vn[t] = new Ij(NF(r, e)), Xy[t] && Xy[t].forEach(function(s) {
      Lj(s.name, s.config);
    }), ph(t), vn[t];
  } else
    return delete vn[t], null;
}
function crt(t, e) {
  if (e != null) {
    var n, r, s = Ole;
    vn[t] != null && vn[t].parentLocale != null ? vn[t].set(NF(vn[t]._config, e)) : (r = sD(t), r != null && (s = r._config), e = NF(s, e), r == null && (e.abbr = t), n = new Ij(e), n.parentLocale = vn[t], vn[t] = n), ph(t);
  } else
    vn[t] != null && (vn[t].parentLocale != null ? (vn[t] = vn[t].parentLocale, t === ph() && ph(t)) : vn[t] != null && delete vn[t]);
  return vn[t];
}
function jl(t) {
  var e;
  if (t && t._locale && t._locale._abbr && (t = t._locale._abbr), !t)
    return tT;
  if (!Jo(t)) {
    if (e = sD(t), e)
      return e;
    t = [t];
  }
  return ort(t);
}
function lrt() {
  return PF(vn);
}
function Uj(t) {
  var e, n = t._a;
  return n && _e(t).overflow === -2 && (e = n[Oc] < 0 || n[Oc] > 11 ? Oc : n[va] < 1 || n[va] > Dj(n[fs], n[Oc]) ? va : n[wr] < 0 || n[wr] > 24 || n[wr] === 24 && (n[Lo] !== 0 || n[Dc] !== 0 || n[qd] !== 0) ? wr : n[Lo] < 0 || n[Lo] > 59 ? Lo : n[Dc] < 0 || n[Dc] > 59 ? Dc : n[qd] < 0 || n[qd] > 999 ? qd : -1, _e(t)._overflowDayOfYear && (e < fs || e > va) && (e = va), _e(t)._overflowWeeks && e === -1 && (e = fnt), _e(t)._overflowWeekday && e === -1 && (e = pnt), _e(t).overflow = e), t;
}
var urt = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, hrt = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, drt = /Z|[+-]\d\d(?::?\d\d)?/, _S = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], V1 = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], frt = /^\/?Date\((-?\d+)/i, prt = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, _rt = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function Dle(t) {
  var e, n, r = t._i, s = urt.exec(r) || hrt.exec(r), i, o, a, c, l = _S.length, h = V1.length;
  if (s) {
    for (_e(t).iso = !0, e = 0, n = l; e < n; e++)
      if (_S[e][1].exec(s[1])) {
        o = _S[e][0], i = _S[e][2] !== !1;
        break;
      }
    if (o == null) {
      t._isValid = !1;
      return;
    }
    if (s[3]) {
      for (e = 0, n = h; e < n; e++)
        if (V1[e][1].exec(s[3])) {
          a = (s[2] || " ") + V1[e][0];
          break;
        }
      if (a == null) {
        t._isValid = !1;
        return;
      }
    }
    if (!i && a != null) {
      t._isValid = !1;
      return;
    }
    if (s[4])
      if (drt.exec(s[4]))
        c = "Z";
      else {
        t._isValid = !1;
        return;
      }
    t._f = o + (a || "") + (c || ""), Yj(t);
  } else
    t._isValid = !1;
}
function mrt(t, e, n, r, s, i) {
  var o = [
    grt(t),
    Tle.indexOf(e),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return i && o.push(parseInt(i, 10)), o;
}
function grt(t) {
  var e = parseInt(t, 10);
  return e <= 49 ? 2e3 + e : e <= 999 ? 1900 + e : e;
}
function yrt(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function vrt(t, e, n) {
  if (t) {
    var r = kle.indexOf(t), s = new Date(
      e[0],
      e[1],
      e[2]
    ).getDay();
    if (r !== s)
      return _e(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function wrt(t, e, n) {
  if (t)
    return _rt[t];
  if (e)
    return 0;
  var r = parseInt(n, 10), s = r % 100, i = (r - s) / 100;
  return i * 60 + s;
}
function Ale(t) {
  var e = prt.exec(yrt(t._i)), n;
  if (e) {
    if (n = mrt(
      e[4],
      e[3],
      e[2],
      e[5],
      e[6],
      e[7]
    ), !vrt(e[1], n, t))
      return;
    t._a = n, t._tzm = wrt(e[8], e[9], e[10]), t._d = XC.apply(null, t._a), t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), _e(t).rfc2822 = !0;
  } else
    t._isValid = !1;
}
function Crt(t) {
  var e = frt.exec(t._i);
  if (e !== null) {
    t._d = /* @__PURE__ */ new Date(+e[1]);
    return;
  }
  if (Dle(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  if (Ale(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  t._strict ? t._isValid = !1 : x.createFromInputFallback(t);
}
x.createFromInputFallback = co(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(t) {
    t._d = /* @__PURE__ */ new Date(t._i + (t._useUTC ? " UTC" : ""));
  }
);
function Zp(t, e, n) {
  return t ?? e ?? n;
}
function Trt(t) {
  var e = new Date(x.now());
  return t._useUTC ? [
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate()
  ] : [e.getFullYear(), e.getMonth(), e.getDate()];
}
function Fj(t) {
  var e, n, r = [], s, i, o;
  if (!t._d) {
    for (s = Trt(t), t._w && t._a[va] == null && t._a[Oc] == null && brt(t), t._dayOfYear != null && (o = Zp(t._a[fs], s[fs]), (t._dayOfYear > yw(o) || t._dayOfYear === 0) && (_e(t)._overflowDayOfYear = !0), n = XC(o, 0, t._dayOfYear), t._a[Oc] = n.getUTCMonth(), t._a[va] = n.getUTCDate()), e = 0; e < 3 && t._a[e] == null; ++e)
      t._a[e] = r[e] = s[e];
    for (; e < 7; e++)
      t._a[e] = r[e] = t._a[e] == null ? e === 2 ? 1 : 0 : t._a[e];
    t._a[wr] === 24 && t._a[Lo] === 0 && t._a[Dc] === 0 && t._a[qd] === 0 && (t._nextDay = !0, t._a[wr] = 0), t._d = (t._useUTC ? XC : Nnt).apply(
      null,
      r
    ), i = t._useUTC ? t._d.getUTCDay() : t._d.getDay(), t._tzm != null && t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), t._nextDay && (t._a[wr] = 24), t._w && typeof t._w.d < "u" && t._w.d !== i && (_e(t).weekdayMismatch = !0);
  }
}
function brt(t) {
  var e, n, r, s, i, o, a, c, l;
  e = t._w, e.GG != null || e.W != null || e.E != null ? (i = 1, o = 4, n = Zp(
    e.GG,
    t._a[fs],
    eT(an(), 1, 4).year
  ), r = Zp(e.W, 1), s = Zp(e.E, 1), (s < 1 || s > 7) && (c = !0)) : (i = t._locale._week.dow, o = t._locale._week.doy, l = eT(an(), i, o), n = Zp(e.gg, t._a[fs], l.year), r = Zp(e.w, l.week), e.d != null ? (s = e.d, (s < 0 || s > 6) && (c = !0)) : e.e != null ? (s = e.e + i, (e.e < 0 || e.e > 6) && (c = !0)) : s = i), r < 1 || r > al(n, i, o) ? _e(t)._overflowWeeks = !0 : c != null ? _e(t)._overflowWeekday = !0 : (a = Rle(n, r, s, i, o), t._a[fs] = a.year, t._dayOfYear = a.dayOfYear);
}
x.ISO_8601 = function() {
};
x.RFC_2822 = function() {
};
function Yj(t) {
  if (t._f === x.ISO_8601) {
    Dle(t);
    return;
  }
  if (t._f === x.RFC_2822) {
    Ale(t);
    return;
  }
  t._a = [], _e(t).empty = !0;
  var e = "" + t._i, n, r, s, i, o, a = e.length, c = 0, l, h;
  for (s = _le(t._f, t._locale).match(Rj) || [], h = s.length, n = 0; n < h; n++)
    i = s[n], r = (e.match(unt(i, t)) || [])[0], r && (o = e.substr(0, e.indexOf(r)), o.length > 0 && _e(t).unusedInput.push(o), e = e.slice(
      e.indexOf(r) + r.length
    ), c += r.length), Y_[i] ? (r ? _e(t).empty = !1 : _e(t).unusedTokens.push(i), dnt(i, r, t)) : t._strict && !r && _e(t).unusedTokens.push(i);
  _e(t).charsLeftOver = a - c, e.length > 0 && _e(t).unusedInput.push(e), t._a[wr] <= 12 && _e(t).bigHour === !0 && t._a[wr] > 0 && (_e(t).bigHour = void 0), _e(t).parsedDateParts = t._a.slice(0), _e(t).meridiem = t._meridiem, t._a[wr] = Ert(
    t._locale,
    t._a[wr],
    t._meridiem
  ), l = _e(t).era, l !== null && (t._a[fs] = t._locale.erasConvertYear(l, t._a[fs])), Fj(t), Uj(t);
}
function Ert(t, e, n) {
  var r;
  return n == null ? e : t.meridiemHour != null ? t.meridiemHour(e, n) : (t.isPM != null && (r = t.isPM(n), r && e < 12 && (e += 12), !r && e === 12 && (e = 0)), e);
}
function Srt(t) {
  var e, n, r, s, i, o, a = !1, c = t._f.length;
  if (c === 0) {
    _e(t).invalidFormat = !0, t._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < c; s++)
    i = 0, o = !1, e = Sj({}, t), t._useUTC != null && (e._useUTC = t._useUTC), e._f = t._f[s], Yj(e), Ej(e) && (o = !0), i += _e(e).charsLeftOver, i += _e(e).unusedTokens.length * 10, _e(e).score = i, a ? i < r && (r = i, n = e) : (r == null || i < r || o) && (r = i, n = e, o && (a = !0));
  Nu(t, n || e);
}
function Irt(t) {
  if (!t._d) {
    var e = kj(t._i), n = e.day === void 0 ? e.date : e.day;
    t._a = dle(
      [e.year, e.month, n, e.hour, e.minute, e.second, e.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), Fj(t);
  }
}
function Rrt(t) {
  var e = new $b(Uj(Mle(t)));
  return e._nextDay && (e.add(1, "d"), e._nextDay = void 0), e;
}
function Mle(t) {
  var e = t._i, n = t._f;
  return t._locale = t._locale || jl(t._l), e === null || n === void 0 && e === "" ? QO({ nullInput: !0 }) : (typeof e == "string" && (t._i = e = t._locale.preparse(e)), Qo(e) ? new $b(Uj(e)) : (Gb(e) ? t._d = e : Jo(n) ? Srt(t) : n ? Yj(t) : krt(t), Ej(t) || (t._d = null), t));
}
function krt(t) {
  var e = t._i;
  ti(e) ? t._d = new Date(x.now()) : Gb(e) ? t._d = new Date(e.valueOf()) : typeof e == "string" ? Crt(t) : Jo(e) ? (t._a = dle(e.slice(0), function(n) {
    return parseInt(n, 10);
  }), Fj(t)) : lf(e) ? Irt(t) : El(e) ? t._d = new Date(e) : x.createFromInputFallback(t);
}
function xle(t, e, n, r, s) {
  var i = {};
  return (e === !0 || e === !1) && (r = e, e = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (lf(t) && bj(t) || Jo(t) && t.length === 0) && (t = void 0), i._isAMomentObject = !0, i._useUTC = i._isUTC = s, i._l = n, i._i = t, i._f = e, i._strict = r, Rrt(i);
}
function an(t, e, n, r) {
  return xle(t, e, n, r, !1);
}
var Nrt = co(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = an.apply(null, arguments);
    return this.isValid() && t.isValid() ? t < this ? this : t : QO();
  }
), Prt = co(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = an.apply(null, arguments);
    return this.isValid() && t.isValid() ? t > this ? this : t : QO();
  }
);
function Lle(t, e) {
  var n, r;
  if (e.length === 1 && Jo(e[0]) && (e = e[0]), !e.length)
    return an();
  for (n = e[0], r = 1; r < e.length; ++r)
    (!e[r].isValid() || e[r][t](n)) && (n = e[r]);
  return n;
}
function Ort() {
  var t = [].slice.call(arguments, 0);
  return Lle("isBefore", t);
}
function Drt() {
  var t = [].slice.call(arguments, 0);
  return Lle("isAfter", t);
}
var Art = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, ev = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function Mrt(t) {
  var e, n = !1, r, s = ev.length;
  for (e in t)
    if (pt(t, e) && !(er.call(ev, e) !== -1 && (t[e] == null || !isNaN(t[e]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (t[ev[r]]) {
      if (n)
        return !1;
      parseFloat(t[ev[r]]) !== He(t[ev[r]]) && (n = !0);
    }
  return !0;
}
function xrt() {
  return this._isValid;
}
function Lrt() {
  return aa(NaN);
}
function iD(t) {
  var e = kj(t), n = e.year || 0, r = e.quarter || 0, s = e.month || 0, i = e.week || e.isoWeek || 0, o = e.day || 0, a = e.hour || 0, c = e.minute || 0, l = e.second || 0, h = e.millisecond || 0;
  this._isValid = Mrt(e), this._milliseconds = +h + l * 1e3 + // 1000
  c * 6e4 + // 1000 * 60
  a * 1e3 * 60 * 60, this._days = +o + i * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = jl(), this._bubble();
}
function S0(t) {
  return t instanceof iD;
}
function DF(t) {
  return t < 0 ? Math.round(-1 * t) * -1 : Math.round(t);
}
function Urt(t, e, n) {
  var r = Math.min(t.length, e.length), s = Math.abs(t.length - e.length), i = 0, o;
  for (o = 0; o < r; o++)
    He(t[o]) !== He(e[o]) && i++;
  return i + s;
}
function Ule(t, e) {
  ee(t, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + Fa(~~(n / 60), 2) + e + Fa(~~n % 60, 2);
  });
}
Ule("Z", ":");
Ule("ZZ", "");
B("Z", nD);
B("ZZ", nD);
Yt(["Z", "ZZ"], function(t, e, n) {
  n._useUTC = !0, n._tzm = Wj(nD, t);
});
var Frt = /([\+\-]|\d\d)/gi;
function Wj(t, e) {
  var n = (e || "").match(t), r, s, i;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(Frt) || ["-", 0, 0], i = +(s[1] * 60) + He(s[2]), i === 0 ? 0 : s[0] === "+" ? i : -i);
}
function Bj(t, e) {
  var n, r;
  return e._isUTC ? (n = e.clone(), r = (Qo(t) || Gb(t) ? t.valueOf() : an(t).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), x.updateOffset(n, !1), n) : an(t).local();
}
function AF(t) {
  return -Math.round(t._d.getTimezoneOffset());
}
x.updateOffset = function() {
};
function Yrt(t, e, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    if (typeof t == "string") {
      if (t = Wj(nD, t), t === null)
        return this;
    } else Math.abs(t) < 16 && !n && (t = t * 60);
    return !this._isUTC && e && (s = AF(this)), this._offset = t, this._isUTC = !0, s != null && this.add(s, "m"), r !== t && (!e || this._changeInProgress ? Wle(
      this,
      aa(t - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, x.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : AF(this);
}
function Wrt(t, e) {
  return t != null ? (typeof t != "string" && (t = -t), this.utcOffset(t, e), this) : -this.utcOffset();
}
function Brt(t) {
  return this.utcOffset(0, t);
}
function qrt(t) {
  return this._isUTC && (this.utcOffset(0, t), this._isUTC = !1, t && this.subtract(AF(this), "m")), this;
}
function Hrt() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var t = Wj(cnt, this._i);
    t != null ? this.utcOffset(t) : this.utcOffset(0, !0);
  }
  return this;
}
function jrt(t) {
  return this.isValid() ? (t = t ? an(t).utcOffset() : 0, (this.utcOffset() - t) % 60 === 0) : !1;
}
function Vrt() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function zrt() {
  if (!ti(this._isDSTShifted))
    return this._isDSTShifted;
  var t = {}, e;
  return Sj(t, this), t = Mle(t), t._a ? (e = t._isUTC ? Za(t._a) : an(t._a), this._isDSTShifted = this.isValid() && Urt(t._a, e.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function Grt() {
  return this.isValid() ? !this._isUTC : !1;
}
function $rt() {
  return this.isValid() ? this._isUTC : !1;
}
function Fle() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var Krt = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, Jrt = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function aa(t, e) {
  var n = t, r = null, s, i, o;
  return S0(t) ? n = {
    ms: t._milliseconds,
    d: t._days,
    M: t._months
  } : El(t) || !isNaN(+t) ? (n = {}, e ? n[e] = +t : n.milliseconds = +t) : (r = Krt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: He(r[va]) * s,
    h: He(r[wr]) * s,
    m: He(r[Lo]) * s,
    s: He(r[Dc]) * s,
    ms: He(DF(r[qd] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = Jrt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: pd(r[2], s),
    M: pd(r[3], s),
    w: pd(r[4], s),
    d: pd(r[5], s),
    h: pd(r[6], s),
    m: pd(r[7], s),
    s: pd(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (o = Qrt(
    an(n.from),
    an(n.to)
  ), n = {}, n.ms = o.milliseconds, n.M = o.months), i = new iD(n), S0(t) && pt(t, "_locale") && (i._locale = t._locale), S0(t) && pt(t, "_isValid") && (i._isValid = t._isValid), i;
}
aa.fn = iD.prototype;
aa.invalid = Lrt;
function pd(t, e) {
  var n = t && parseFloat(t.replace(",", "."));
  return (isNaN(n) ? 0 : n) * e;
}
function G9(t, e) {
  var n = {};
  return n.months = e.month() - t.month() + (e.year() - t.year()) * 12, t.clone().add(n.months, "M").isAfter(e) && --n.months, n.milliseconds = +e - +t.clone().add(n.months, "M"), n;
}
function Qrt(t, e) {
  var n;
  return t.isValid() && e.isValid() ? (e = Bj(e, t), t.isBefore(e) ? n = G9(t, e) : (n = G9(e, t), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function Yle(t, e) {
  return function(n, r) {
    var s, i;
    return r !== null && !isNaN(+r) && (ple(
      e,
      "moment()." + e + "(period, number) is deprecated. Please use moment()." + e + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), i = n, n = r, r = i), s = aa(n, r), Wle(this, s, t), this;
  };
}
function Wle(t, e, n, r) {
  var s = e._milliseconds, i = DF(e._days), o = DF(e._months);
  t.isValid() && (r = r ?? !0, o && Ele(t, ZC(t, "Month") + o * n), i && Cle(t, "Date", ZC(t, "Date") + i * n), s && t._d.setTime(t._d.valueOf() + s * n), r && x.updateOffset(t, i || o));
}
var Zrt = Yle(1, "add"), Xrt = Yle(-1, "subtract");
function Ble(t) {
  return typeof t == "string" || t instanceof String;
}
function est(t) {
  return Qo(t) || Gb(t) || Ble(t) || El(t) || nst(t) || tst(t) || t === null || t === void 0;
}
function tst(t) {
  var e = lf(t) && !bj(t), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, i, o = r.length;
  for (s = 0; s < o; s += 1)
    i = r[s], n = n || pt(t, i);
  return e && n;
}
function nst(t) {
  var e = Jo(t), n = !1;
  return e && (n = t.filter(function(r) {
    return !El(r) && Ble(t);
  }).length === 0), e && n;
}
function rst(t) {
  var e = lf(t) && !bj(t), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, i;
  for (s = 0; s < r.length; s += 1)
    i = r[s], n = n || pt(t, i);
  return e && n;
}
function sst(t, e) {
  var n = t.diff(e, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function ist(t, e) {
  arguments.length === 1 && (arguments[0] ? est(arguments[0]) ? (t = arguments[0], e = void 0) : rst(arguments[0]) && (e = arguments[0], t = void 0) : (t = void 0, e = void 0));
  var n = t || an(), r = Bj(n, this).startOf("day"), s = x.calendarFormat(this, r) || "sameElse", i = e && (Xa(e[s]) ? e[s].call(this, n) : e[s]);
  return this.format(
    i || this.localeData().calendar(s, this, an(n))
  );
}
function ost() {
  return new $b(this);
}
function ast(t, e) {
  var n = Qo(t) ? t : an(t);
  return this.isValid() && n.isValid() ? (e = lo(e) || "millisecond", e === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(e).valueOf()) : !1;
}
function cst(t, e) {
  var n = Qo(t) ? t : an(t);
  return this.isValid() && n.isValid() ? (e = lo(e) || "millisecond", e === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(e).valueOf() < n.valueOf()) : !1;
}
function lst(t, e, n, r) {
  var s = Qo(t) ? t : an(t), i = Qo(e) ? e : an(e);
  return this.isValid() && s.isValid() && i.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(i, n) : !this.isAfter(i, n))) : !1;
}
function ust(t, e) {
  var n = Qo(t) ? t : an(t), r;
  return this.isValid() && n.isValid() ? (e = lo(e) || "millisecond", e === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(e).valueOf() <= r && r <= this.clone().endOf(e).valueOf())) : !1;
}
function hst(t, e) {
  return this.isSame(t, e) || this.isAfter(t, e);
}
function dst(t, e) {
  return this.isSame(t, e) || this.isBefore(t, e);
}
function fst(t, e, n) {
  var r, s, i;
  if (!this.isValid())
    return NaN;
  if (r = Bj(t, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, e = lo(e), e) {
    case "year":
      i = I0(this, r) / 12;
      break;
    case "month":
      i = I0(this, r);
      break;
    case "quarter":
      i = I0(this, r) / 3;
      break;
    case "second":
      i = (this - r) / 1e3;
      break;
    case "minute":
      i = (this - r) / 6e4;
      break;
    case "hour":
      i = (this - r) / 36e5;
      break;
    case "day":
      i = (this - r - s) / 864e5;
      break;
    case "week":
      i = (this - r - s) / 6048e5;
      break;
    default:
      i = this - r;
  }
  return n ? i : Wi(i);
}
function I0(t, e) {
  if (t.date() < e.date())
    return -I0(e, t);
  var n = (e.year() - t.year()) * 12 + (e.month() - t.month()), r = t.clone().add(n, "months"), s, i;
  return e - r < 0 ? (s = t.clone().add(n - 1, "months"), i = (e - r) / (r - s)) : (s = t.clone().add(n + 1, "months"), i = (e - r) / (s - r)), -(n + i) || 0;
}
x.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
x.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function pst() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function _st(t) {
  if (!this.isValid())
    return null;
  var e = t !== !0, n = e ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? E0(
    n,
    e ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : Xa(Date.prototype.toISOString) ? e ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", E0(n, "Z")) : E0(
    n,
    e ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function mst() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var t = "moment", e = "", n, r, s, i;
  return this.isLocal() || (t = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", e = "Z"), n = "[" + t + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", i = e + '[")]', this.format(n + r + s + i);
}
function gst(t) {
  t || (t = this.isUtc() ? x.defaultFormatUtc : x.defaultFormat);
  var e = E0(this, t);
  return this.localeData().postformat(e);
}
function yst(t, e) {
  return this.isValid() && (Qo(t) && t.isValid() || an(t).isValid()) ? aa({ to: this, from: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function vst(t) {
  return this.from(an(), t);
}
function wst(t, e) {
  return this.isValid() && (Qo(t) && t.isValid() || an(t).isValid()) ? aa({ from: this, to: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function Cst(t) {
  return this.to(an(), t);
}
function qle(t) {
  var e;
  return t === void 0 ? this._locale._abbr : (e = jl(t), e != null && (this._locale = e), this);
}
var Hle = co(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(t) {
    return t === void 0 ? this.localeData() : this.locale(t);
  }
);
function jle() {
  return this._locale;
}
var Yk = 1e3, W_ = 60 * Yk, Wk = 60 * W_, Vle = (365 * 400 + 97) * 24 * Wk;
function B_(t, e) {
  return (t % e + e) % e;
}
function zle(t, e, n) {
  return t < 100 && t >= 0 ? new Date(t + 400, e, n) - Vle : new Date(t, e, n).valueOf();
}
function Gle(t, e, n) {
  return t < 100 && t >= 0 ? Date.UTC(t + 400, e, n) - Vle : Date.UTC(t, e, n);
}
function Tst(t) {
  var e, n;
  if (t = lo(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? Gle : zle, t) {
    case "year":
      e = n(this.year(), 0, 1);
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      e = n(this.year(), this.month(), 1);
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      e = this._d.valueOf(), e -= B_(
        e + (this._isUTC ? 0 : this.utcOffset() * W_),
        Wk
      );
      break;
    case "minute":
      e = this._d.valueOf(), e -= B_(e, W_);
      break;
    case "second":
      e = this._d.valueOf(), e -= B_(e, Yk);
      break;
  }
  return this._d.setTime(e), x.updateOffset(this, !0), this;
}
function bst(t) {
  var e, n;
  if (t = lo(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? Gle : zle, t) {
    case "year":
      e = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      e = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      e = this._d.valueOf(), e += Wk - B_(
        e + (this._isUTC ? 0 : this.utcOffset() * W_),
        Wk
      ) - 1;
      break;
    case "minute":
      e = this._d.valueOf(), e += W_ - B_(e, W_) - 1;
      break;
    case "second":
      e = this._d.valueOf(), e += Yk - B_(e, Yk) - 1;
      break;
  }
  return this._d.setTime(e), x.updateOffset(this, !0), this;
}
function Est() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function Sst() {
  return Math.floor(this.valueOf() / 1e3);
}
function Ist() {
  return new Date(this.valueOf());
}
function Rst() {
  var t = this;
  return [
    t.year(),
    t.month(),
    t.date(),
    t.hour(),
    t.minute(),
    t.second(),
    t.millisecond()
  ];
}
function kst() {
  var t = this;
  return {
    years: t.year(),
    months: t.month(),
    date: t.date(),
    hours: t.hours(),
    minutes: t.minutes(),
    seconds: t.seconds(),
    milliseconds: t.milliseconds()
  };
}
function Nst() {
  return this.isValid() ? this.toISOString() : null;
}
function Pst() {
  return Ej(this);
}
function Ost() {
  return Nu({}, _e(this));
}
function Dst() {
  return _e(this).overflow;
}
function Ast() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
ee("N", 0, 0, "eraAbbr");
ee("NN", 0, 0, "eraAbbr");
ee("NNN", 0, 0, "eraAbbr");
ee("NNNN", 0, 0, "eraName");
ee("NNNNN", 0, 0, "eraNarrow");
ee("y", ["y", 1], "yo", "eraYear");
ee("y", ["yy", 2], 0, "eraYear");
ee("y", ["yyy", 3], 0, "eraYear");
ee("y", ["yyyy", 4], 0, "eraYear");
B("N", qj);
B("NN", qj);
B("NNN", qj);
B("NNNN", jst);
B("NNNNN", Vst);
Yt(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(t, e, n, r) {
    var s = n._locale.erasParse(t, r, n._strict);
    s ? _e(n).era = s : _e(n).invalidEra = t;
  }
);
B("y", Rg);
B("yy", Rg);
B("yyy", Rg);
B("yyyy", Rg);
B("yo", zst);
Yt(["y", "yy", "yyy", "yyyy"], fs);
Yt(["yo"], function(t, e, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = t.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? e[fs] = n._locale.eraYearOrdinalParse(t, s) : e[fs] = parseInt(t, 10);
});
function Mst(t, e) {
  var n, r, s, i = this._eras || jl("en")._eras;
  for (n = 0, r = i.length; n < r; ++n) {
    switch (typeof i[n].since) {
      case "string":
        s = x(i[n].since).startOf("day"), i[n].since = s.valueOf();
        break;
    }
    switch (typeof i[n].until) {
      case "undefined":
        i[n].until = 1 / 0;
        break;
      case "string":
        s = x(i[n].until).startOf("day").valueOf(), i[n].until = s.valueOf();
        break;
    }
  }
  return i;
}
function xst(t, e, n) {
  var r, s, i = this.eras(), o, a, c;
  for (t = t.toUpperCase(), r = 0, s = i.length; r < s; ++r)
    if (o = i[r].name.toUpperCase(), a = i[r].abbr.toUpperCase(), c = i[r].narrow.toUpperCase(), n)
      switch (e) {
        case "N":
        case "NN":
        case "NNN":
          if (a === t)
            return i[r];
          break;
        case "NNNN":
          if (o === t)
            return i[r];
          break;
        case "NNNNN":
          if (c === t)
            return i[r];
          break;
      }
    else if ([o, a, c].indexOf(t) >= 0)
      return i[r];
}
function Lst(t, e) {
  var n = t.since <= t.until ? 1 : -1;
  return e === void 0 ? x(t.since).year() : x(t.since).year() + (e - t.offset) * n;
}
function Ust() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].name;
  return "";
}
function Fst() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].narrow;
  return "";
}
function Yst() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].abbr;
  return "";
}
function Wst() {
  var t, e, n, r, s = this.localeData().eras();
  for (t = 0, e = s.length; t < e; ++t)
    if (n = s[t].since <= s[t].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[t].since <= r && r <= s[t].until || s[t].until <= r && r <= s[t].since)
      return (this.year() - x(s[t].since).year()) * n + s[t].offset;
  return this.year();
}
function Bst(t) {
  return pt(this, "_erasNameRegex") || Hj.call(this), t ? this._erasNameRegex : this._erasRegex;
}
function qst(t) {
  return pt(this, "_erasAbbrRegex") || Hj.call(this), t ? this._erasAbbrRegex : this._erasRegex;
}
function Hst(t) {
  return pt(this, "_erasNarrowRegex") || Hj.call(this), t ? this._erasNarrowRegex : this._erasRegex;
}
function qj(t, e) {
  return e.erasAbbrRegex(t);
}
function jst(t, e) {
  return e.erasNameRegex(t);
}
function Vst(t, e) {
  return e.erasNarrowRegex(t);
}
function zst(t, e) {
  return e._eraYearOrdinalRegex || Rg;
}
function Hj() {
  var t = [], e = [], n = [], r = [], s, i, o, a, c, l = this.eras();
  for (s = 0, i = l.length; s < i; ++s)
    o = ol(l[s].name), a = ol(l[s].abbr), c = ol(l[s].narrow), e.push(o), t.push(a), n.push(c), r.push(o), r.push(a), r.push(c);
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
ee(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
ee(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function oD(t, e) {
  ee(0, [t, t.length], 0, e);
}
oD("gggg", "weekYear");
oD("ggggg", "weekYear");
oD("GGGG", "isoWeekYear");
oD("GGGGG", "isoWeekYear");
B("G", tD);
B("g", tD);
B("GG", fn, ki);
B("gg", fn, ki);
B("GGGG", Pj, Nj);
B("gggg", Pj, Nj);
B("GGGGG", eD, ZO);
B("ggggg", eD, ZO);
Jb(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(t, e, n, r) {
    e[r.substr(0, 2)] = He(t);
  }
);
Jb(["gg", "GG"], function(t, e, n, r) {
  e[r] = x.parseTwoDigitYear(t);
});
function Gst(t) {
  return $le.call(
    this,
    t,
    this.week(),
    this.weekday() + this.localeData()._week.dow,
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function $st(t) {
  return $le.call(
    this,
    t,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function Kst() {
  return al(this.year(), 1, 4);
}
function Jst() {
  return al(this.isoWeekYear(), 1, 4);
}
function Qst() {
  var t = this.localeData()._week;
  return al(this.year(), t.dow, t.doy);
}
function Zst() {
  var t = this.localeData()._week;
  return al(this.weekYear(), t.dow, t.doy);
}
function $le(t, e, n, r, s) {
  var i;
  return t == null ? eT(this, r, s).year : (i = al(t, r, s), e > i && (e = i), Xst.call(this, t, e, n, r, s));
}
function Xst(t, e, n, r, s) {
  var i = Rle(t, e, n, r, s), o = XC(i.year, 0, i.dayOfYear);
  return this.year(o.getUTCFullYear()), this.month(o.getUTCMonth()), this.date(o.getUTCDate()), this;
}
ee("Q", 0, "Qo", "quarter");
B("Q", mle);
Yt("Q", function(t, e) {
  e[Oc] = (He(t) - 1) * 3;
});
function eit(t) {
  return t == null ? Math.ceil((this.month() + 1) / 3) : this.month((t - 1) * 3 + this.month() % 3);
}
ee("D", ["DD", 2], "Do", "date");
B("D", fn, kg);
B("DD", fn, ki);
B("Do", function(t, e) {
  return t ? e._dayOfMonthOrdinalParse || e._ordinalParse : e._dayOfMonthOrdinalParseLenient;
});
Yt(["D", "DD"], va);
Yt("Do", function(t, e) {
  e[va] = He(t.match(fn)[0]);
});
var Kle = Ng("Date", !0);
ee("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
B("DDD", XO);
B("DDDD", gle);
Yt(["DDD", "DDDD"], function(t, e, n) {
  n._dayOfYear = He(t);
});
function tit(t) {
  var e = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return t == null ? e : this.add(t - e, "d");
}
ee("m", ["mm", 2], 0, "minute");
B("m", fn, Oj);
B("mm", fn, ki);
Yt(["m", "mm"], Lo);
var nit = Ng("Minutes", !1);
ee("s", ["ss", 2], 0, "second");
B("s", fn, Oj);
B("ss", fn, ki);
Yt(["s", "ss"], Dc);
var rit = Ng("Seconds", !1);
ee("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
ee(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
ee(0, ["SSS", 3], 0, "millisecond");
ee(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
ee(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
ee(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
ee(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
ee(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
ee(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
B("S", XO, mle);
B("SS", XO, ki);
B("SSS", XO, gle);
var Pu, Jle;
for (Pu = "SSSS"; Pu.length <= 9; Pu += "S")
  B(Pu, Rg);
function sit(t, e) {
  e[qd] = He(("0." + t) * 1e3);
}
for (Pu = "S"; Pu.length <= 9; Pu += "S")
  Yt(Pu, sit);
Jle = Ng("Milliseconds", !1);
ee("z", 0, 0, "zoneAbbr");
ee("zz", 0, 0, "zoneName");
function iit() {
  return this._isUTC ? "UTC" : "";
}
function oit() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var T = $b.prototype;
T.add = Zrt;
T.calendar = ist;
T.clone = ost;
T.diff = fst;
T.endOf = bst;
T.format = gst;
T.from = yst;
T.fromNow = vst;
T.to = wst;
T.toNow = Cst;
T.get = mnt;
T.invalidAt = Dst;
T.isAfter = ast;
T.isBefore = cst;
T.isBetween = lst;
T.isSame = ust;
T.isSameOrAfter = hst;
T.isSameOrBefore = dst;
T.isValid = Pst;
T.lang = Hle;
T.locale = qle;
T.localeData = jle;
T.max = Prt;
T.min = Nrt;
T.parsingFlags = Ost;
T.set = gnt;
T.startOf = Tst;
T.subtract = Xrt;
T.toArray = Rst;
T.toObject = kst;
T.toDate = Ist;
T.toISOString = _st;
T.inspect = mst;
typeof Symbol < "u" && Symbol.for != null && (T[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
T.toJSON = Nst;
T.toString = pst;
T.unix = Sst;
T.valueOf = Est;
T.creationData = Ast;
T.eraName = Ust;
T.eraNarrow = Fst;
T.eraAbbr = Yst;
T.eraYear = Wst;
T.year = wle;
T.isLeapYear = _nt;
T.weekYear = Gst;
T.isoWeekYear = $st;
T.quarter = T.quarters = eit;
T.month = Sle;
T.daysInMonth = Int;
T.week = T.weeks = Mnt;
T.isoWeek = T.isoWeeks = xnt;
T.weeksInYear = Qst;
T.weeksInWeekYear = Zst;
T.isoWeeksInYear = Kst;
T.isoWeeksInISOWeekYear = Jst;
T.date = Kle;
T.day = T.days = $nt;
T.weekday = Knt;
T.isoWeekday = Jnt;
T.dayOfYear = tit;
T.hour = T.hours = rrt;
T.minute = T.minutes = nit;
T.second = T.seconds = rit;
T.millisecond = T.milliseconds = Jle;
T.utcOffset = Yrt;
T.utc = Brt;
T.local = qrt;
T.parseZone = Hrt;
T.hasAlignedHourOffset = jrt;
T.isDST = Vrt;
T.isLocal = Grt;
T.isUtcOffset = $rt;
T.isUtc = Fle;
T.isUTC = Fle;
T.zoneAbbr = iit;
T.zoneName = oit;
T.dates = co(
  "dates accessor is deprecated. Use date instead.",
  Kle
);
T.months = co(
  "months accessor is deprecated. Use month instead",
  Sle
);
T.years = co(
  "years accessor is deprecated. Use year instead",
  wle
);
T.zone = co(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  Wrt
);
T.isDSTShifted = co(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  zrt
);
function ait(t) {
  return an(t * 1e3);
}
function cit() {
  return an.apply(null, arguments).parseZone();
}
function Qle(t) {
  return t;
}
var wt = Ij.prototype;
wt.calendar = Gtt;
wt.longDateFormat = Qtt;
wt.invalidDate = Xtt;
wt.ordinal = nnt;
wt.preparse = Qle;
wt.postformat = Qle;
wt.relativeTime = snt;
wt.pastFuture = int;
wt.set = Vtt;
wt.eras = Mst;
wt.erasParse = xst;
wt.erasConvertYear = Lst;
wt.erasAbbrRegex = qst;
wt.erasNameRegex = Bst;
wt.erasNarrowRegex = Hst;
wt.months = Tnt;
wt.monthsShort = bnt;
wt.monthsParse = Snt;
wt.monthsRegex = knt;
wt.monthsShortRegex = Rnt;
wt.week = Pnt;
wt.firstDayOfYear = Ant;
wt.firstDayOfWeek = Dnt;
wt.weekdays = Hnt;
wt.weekdaysMin = Vnt;
wt.weekdaysShort = jnt;
wt.weekdaysParse = Gnt;
wt.weekdaysRegex = Qnt;
wt.weekdaysShortRegex = Znt;
wt.weekdaysMinRegex = Xnt;
wt.isPM = trt;
wt.meridiem = srt;
function Bk(t, e, n, r) {
  var s = jl(), i = Za().set(r, e);
  return s[n](i, t);
}
function Zle(t, e, n) {
  if (El(t) && (e = t, t = void 0), t = t || "", e != null)
    return Bk(t, e, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = Bk(t, r, n, "month");
  return s;
}
function jj(t, e, n, r) {
  typeof t == "boolean" ? (El(e) && (n = e, e = void 0), e = e || "") : (e = t, n = e, t = !1, El(e) && (n = e, e = void 0), e = e || "");
  var s = jl(), i = t ? s._week.dow : 0, o, a = [];
  if (n != null)
    return Bk(e, (n + i) % 7, r, "day");
  for (o = 0; o < 7; o++)
    a[o] = Bk(e, (o + i) % 7, r, "day");
  return a;
}
function lit(t, e) {
  return Zle(t, e, "months");
}
function uit(t, e) {
  return Zle(t, e, "monthsShort");
}
function hit(t, e, n) {
  return jj(t, e, n, "weekdays");
}
function dit(t, e, n) {
  return jj(t, e, n, "weekdaysShort");
}
function fit(t, e, n) {
  return jj(t, e, n, "weekdaysMin");
}
ph("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(t) {
    var e = t % 10, n = He(t % 100 / 10) === 1 ? "th" : e === 1 ? "st" : e === 2 ? "nd" : e === 3 ? "rd" : "th";
    return t + n;
  }
});
x.lang = co(
  "moment.lang is deprecated. Use moment.locale instead.",
  ph
);
x.langData = co(
  "moment.langData is deprecated. Use moment.localeData instead.",
  jl
);
var uc = Math.abs;
function pit() {
  var t = this._data;
  return this._milliseconds = uc(this._milliseconds), this._days = uc(this._days), this._months = uc(this._months), t.milliseconds = uc(t.milliseconds), t.seconds = uc(t.seconds), t.minutes = uc(t.minutes), t.hours = uc(t.hours), t.months = uc(t.months), t.years = uc(t.years), this;
}
function Xle(t, e, n, r) {
  var s = aa(e, n);
  return t._milliseconds += r * s._milliseconds, t._days += r * s._days, t._months += r * s._months, t._bubble();
}
function _it(t, e) {
  return Xle(this, t, e, 1);
}
function mit(t, e) {
  return Xle(this, t, e, -1);
}
function $9(t) {
  return t < 0 ? Math.floor(t) : Math.ceil(t);
}
function git() {
  var t = this._milliseconds, e = this._days, n = this._months, r = this._data, s, i, o, a, c;
  return t >= 0 && e >= 0 && n >= 0 || t <= 0 && e <= 0 && n <= 0 || (t += $9(MF(n) + e) * 864e5, e = 0, n = 0), r.milliseconds = t % 1e3, s = Wi(t / 1e3), r.seconds = s % 60, i = Wi(s / 60), r.minutes = i % 60, o = Wi(i / 60), r.hours = o % 24, e += Wi(o / 24), c = Wi(eue(e)), n += c, e -= $9(MF(c)), a = Wi(n / 12), n %= 12, r.days = e, r.months = n, r.years = a, this;
}
function eue(t) {
  return t * 4800 / 146097;
}
function MF(t) {
  return t * 146097 / 4800;
}
function yit(t) {
  if (!this.isValid())
    return NaN;
  var e, n, r = this._milliseconds;
  if (t = lo(t), t === "month" || t === "quarter" || t === "year")
    switch (e = this._days + r / 864e5, n = this._months + eue(e), t) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (e = this._days + Math.round(MF(this._months)), t) {
      case "week":
        return e / 7 + r / 6048e5;
      case "day":
        return e + r / 864e5;
      case "hour":
        return e * 24 + r / 36e5;
      case "minute":
        return e * 1440 + r / 6e4;
      case "second":
        return e * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(e * 864e5) + r;
      default:
        throw new Error("Unknown unit " + t);
    }
}
function Vl(t) {
  return function() {
    return this.as(t);
  };
}
var tue = Vl("ms"), vit = Vl("s"), wit = Vl("m"), Cit = Vl("h"), Tit = Vl("d"), bit = Vl("w"), Eit = Vl("M"), Sit = Vl("Q"), Iit = Vl("y"), Rit = tue;
function kit() {
  return aa(this);
}
function Nit(t) {
  return t = lo(t), this.isValid() ? this[t + "s"]() : NaN;
}
function Tp(t) {
  return function() {
    return this.isValid() ? this._data[t] : NaN;
  };
}
var Pit = Tp("milliseconds"), Oit = Tp("seconds"), Dit = Tp("minutes"), Ait = Tp("hours"), Mit = Tp("days"), xit = Tp("months"), Lit = Tp("years");
function Uit() {
  return Wi(this.days() / 7);
}
var mc = Math.round, u_ = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function Fit(t, e, n, r, s) {
  return s.relativeTime(e || 1, !!n, t, r);
}
function Yit(t, e, n, r) {
  var s = aa(t).abs(), i = mc(s.as("s")), o = mc(s.as("m")), a = mc(s.as("h")), c = mc(s.as("d")), l = mc(s.as("M")), h = mc(s.as("w")), u = mc(s.as("y")), d = i <= n.ss && ["s", i] || i < n.s && ["ss", i] || o <= 1 && ["m"] || o < n.m && ["mm", o] || a <= 1 && ["h"] || a < n.h && ["hh", a] || c <= 1 && ["d"] || c < n.d && ["dd", c];
  return n.w != null && (d = d || h <= 1 && ["w"] || h < n.w && ["ww", h]), d = d || l <= 1 && ["M"] || l < n.M && ["MM", l] || u <= 1 && ["y"] || ["yy", u], d[2] = e, d[3] = +t > 0, d[4] = r, Fit.apply(null, d);
}
function Wit(t) {
  return t === void 0 ? mc : typeof t == "function" ? (mc = t, !0) : !1;
}
function Bit(t, e) {
  return u_[t] === void 0 ? !1 : e === void 0 ? u_[t] : (u_[t] = e, t === "s" && (u_.ss = e - 1), !0);
}
function qit(t, e) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = u_, s, i;
  return typeof t == "object" && (e = t, t = !1), typeof t == "boolean" && (n = t), typeof e == "object" && (r = Object.assign({}, u_, e), e.s != null && e.ss == null && (r.ss = e.s - 1)), s = this.localeData(), i = Yit(this, !n, r, s), n && (i = s.pastFuture(+this, i)), s.postformat(i);
}
var z1 = Math.abs;
function Wp(t) {
  return (t > 0) - (t < 0) || +t;
}
function aD() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var t = z1(this._milliseconds) / 1e3, e = z1(this._days), n = z1(this._months), r, s, i, o, a = this.asSeconds(), c, l, h, u;
  return a ? (r = Wi(t / 60), s = Wi(r / 60), t %= 60, r %= 60, i = Wi(n / 12), n %= 12, o = t ? t.toFixed(3).replace(/\.?0+$/, "") : "", c = a < 0 ? "-" : "", l = Wp(this._months) !== Wp(a) ? "-" : "", h = Wp(this._days) !== Wp(a) ? "-" : "", u = Wp(this._milliseconds) !== Wp(a) ? "-" : "", c + "P" + (i ? l + i + "Y" : "") + (n ? l + n + "M" : "") + (e ? h + e + "D" : "") + (s || r || t ? "T" : "") + (s ? u + s + "H" : "") + (r ? u + r + "M" : "") + (t ? u + o + "S" : "")) : "P0D";
}
var Ke = iD.prototype;
Ke.isValid = xrt;
Ke.abs = pit;
Ke.add = _it;
Ke.subtract = mit;
Ke.as = yit;
Ke.asMilliseconds = tue;
Ke.asSeconds = vit;
Ke.asMinutes = wit;
Ke.asHours = Cit;
Ke.asDays = Tit;
Ke.asWeeks = bit;
Ke.asMonths = Eit;
Ke.asQuarters = Sit;
Ke.asYears = Iit;
Ke.valueOf = Rit;
Ke._bubble = git;
Ke.clone = kit;
Ke.get = Nit;
Ke.milliseconds = Pit;
Ke.seconds = Oit;
Ke.minutes = Dit;
Ke.hours = Ait;
Ke.days = Mit;
Ke.weeks = Uit;
Ke.months = xit;
Ke.years = Lit;
Ke.humanize = qit;
Ke.toISOString = aD;
Ke.toString = aD;
Ke.toJSON = aD;
Ke.locale = qle;
Ke.localeData = jle;
Ke.toIsoString = co(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  aD
);
Ke.lang = Hle;
ee("X", 0, 0, "unix");
ee("x", 0, 0, "valueOf");
B("x", tD);
B("X", lnt);
Yt("X", function(t, e, n) {
  n._d = new Date(parseFloat(t) * 1e3);
});
Yt("x", function(t, e, n) {
  n._d = new Date(He(t));
});
x.version = "2.30.1";
Htt(an);
x.fn = T;
x.min = Ort;
x.max = Drt;
x.now = Art;
x.utc = Za;
x.unix = ait;
x.months = lit;
x.isDate = Gb;
x.locale = ph;
x.invalid = QO;
x.duration = aa;
x.isMoment = Qo;
x.weekdays = hit;
x.parseZone = cit;
x.localeData = jl;
x.isDuration = S0;
x.monthsShort = uit;
x.weekdaysMin = fit;
x.defineLocale = Lj;
x.updateLocale = crt;
x.locales = lrt;
x.weekdaysShort = dit;
x.normalizeUnits = lo;
x.relativeTimeRounding = Wit;
x.relativeTimeThreshold = Bit;
x.calendarFormat = sst;
x.prototype = T;
x.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
function nue(t, e) {
  var n = {};
  for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function rue() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const Hit = rue, sue = new gb("auth", "Firebase", rue()), qk = new PB("@firebase/auth");
function jit(t, ...e) {
  qk.logLevel <= Nt.WARN && qk.warn(`Auth (${yb}): ${t}`, ...e);
}
function R0(t, ...e) {
  qk.logLevel <= Nt.ERROR && qk.error(`Auth (${yb}): ${t}`, ...e);
}
function nT(t, ...e) {
  throw zj(t, ...e);
}
function Vj(t, ...e) {
  return zj(t, ...e);
}
function Vit(t, e, n) {
  const r = Object.assign(Object.assign({}, Hit()), { [e]: n });
  return new gb("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function zj(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return sue.create(t, ...e);
}
function tt(t, e, ...n) {
  if (!t)
    throw zj(e, ...n);
}
function Ac(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw R0(e), new Error(e);
}
function Hk(t, e) {
  t || Ac(e);
}
function zit() {
  return K9() === "http:" || K9() === "https:";
}
function K9() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
function Git() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (zit() || _Ye() || "connection" in navigator) ? navigator.onLine : !0;
}
function $it() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
class Qb {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, Hk(n > e, "Short delay should be less than long delay!"), this.isMobile = kB() || qne();
  }
  get() {
    return Git() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
function Kit(t, e) {
  Hk(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
class iue {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    Ac("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    Ac("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    Ac("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
const Jit = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
}, Qit = new Qb(3e4, 6e4);
function Zb(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function bp(t, e, n, r, s = {}) {
  return oue(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = NB(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), iue.fetch()(aue(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function oue(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, Jit), e);
  try {
    const s = new Zit(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw mS(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, l] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw mS(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw mS(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw mS(t, "user-disabled", o);
      const h = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (l)
        throw Vit(t, h, l);
      nT(t, h);
    }
  } catch (s) {
    if (s instanceof Ul)
      throw s;
    nT(t, "network-request-failed", { message: String(s) });
  }
}
async function Gj(t, e, n, r, s = {}) {
  const i = await bp(t, e, n, r, s);
  return "mfaPendingCredential" in i && nT(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function aue(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? Kit(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class Zit {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(Vj(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), Qit.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function mS(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = Vj(t, e, r);
  return s.customData._tokenResponse = n, s;
}
async function Xit(t, e) {
  return bp(t, "POST", "/v1/accounts:delete", e);
}
async function eot(t, e) {
  return bp(t, "POST", "/v1/accounts:lookup", e);
}
function vw(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function tot(t, e = !1) {
  const n = us(t), r = await n.getIdToken(e), s = cue(r);
  tt(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: vw(G1(s.auth_time)),
    issuedAtTime: vw(G1(s.iat)),
    expirationTime: vw(G1(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function G1(t) {
  return Number(t) * 1e3;
}
function cue(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return R0("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = PR(n);
    return s ? JSON.parse(s) : (R0("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return R0("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function not(t) {
  const e = cue(t);
  return tt(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), tt(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), tt(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
async function jk(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof Ul && rot(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function rot({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
class sot {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const r = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, r);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
class lue {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = vw(this.lastLoginAt), this.creationTime = vw(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
async function Vk(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await jk(t, eot(n, { idToken: r }));
  tt(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? aot(i.providerUserInfo) : [], a = oot(t.providerData, o), c = t.isAnonymous, l = !(t.email && i.passwordHash) && !(a != null && a.length), h = c ? l : !1, u = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new lue(i.createdAt, i.lastLoginAt),
    isAnonymous: h
  };
  Object.assign(t, u);
}
async function iot(t) {
  const e = us(t);
  await Vk(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function oot(t, e) {
  return [...t.filter((n) => !e.some((r) => r.providerId === n.providerId)), ...e];
}
function aot(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = nue(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
async function cot(t, e) {
  const n = await oue(t, {}, async () => {
    const r = NB({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = aue(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", iue.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
class rT {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    tt(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), tt(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), tt(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : not(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return tt(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await cot(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new rT();
    return r && (tt(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (tt(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (tt(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new rT(), this.toJSON());
  }
  _performRefresh() {
    return Ac("not implemented");
  }
}
function cu(t, e) {
  tt(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class uf {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = nue(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new sot(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new lue(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await jk(this, this.stsTokenManager.getToken(this.auth, e));
    return tt(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return tot(this, e);
  }
  reload() {
    return iot(this);
  }
  _assign(e) {
    this !== e && (tt(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new uf(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    tt(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await Vk(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await jk(this, Xit(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, l, h;
    const u = (r = n.displayName) !== null && r !== void 0 ? r : void 0, d = (s = n.email) !== null && s !== void 0 ? s : void 0, f = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, p = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, _ = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, m = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, v = (l = n.createdAt) !== null && l !== void 0 ? l : void 0, y = (h = n.lastLoginAt) !== null && h !== void 0 ? h : void 0, { uid: g, emailVerified: w, isAnonymous: de, providerData: we, stsTokenManager: fe } = n;
    tt(
      g && fe,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const ys = rT.fromJSON(this.name, fe);
    tt(
      typeof g == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), cu(u, e.name), cu(d, e.name), tt(
      typeof w == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), tt(
      typeof de == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), cu(f, e.name), cu(p, e.name), cu(_, e.name), cu(m, e.name), cu(v, e.name), cu(y, e.name);
    const En = new uf({
      uid: g,
      auth: e,
      email: d,
      emailVerified: w,
      displayName: u,
      isAnonymous: de,
      photoURL: p,
      phoneNumber: f,
      tenantId: _,
      stsTokenManager: ys,
      createdAt: v,
      lastLoginAt: y
    });
    return we && Array.isArray(we) && (En.providerData = we.map((vo) => Object.assign({}, vo))), m && (En._redirectEventId = m), En;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new rT();
    s.updateFromServerResponse(n);
    const i = new uf({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await Vk(i), i;
  }
}
const J9 = /* @__PURE__ */ new Map();
function Hd(t) {
  Hk(t instanceof Function, "Expected a class definition");
  let e = J9.get(t);
  return e ? (Hk(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), J9.set(t, e), e);
}
class uue {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
uue.type = "NONE";
const Q9 = uue;
function $1(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class q_ {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = $1(this.userKey, s.apiKey, i), this.fullPersistenceKey = $1("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? uf._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new q_(Hd(Q9), e, r);
    const s = (await Promise.all(n.map(async (l) => {
      if (await l._isAvailable())
        return l;
    }))).filter((l) => l);
    let i = s[0] || Hd(Q9);
    const o = $1(r, e.config.apiKey, e.name);
    let a = null;
    for (const l of n)
      try {
        const h = await l._get(o);
        if (h) {
          const u = uf._fromJSON(e, h);
          l !== i && (a = u), i = l;
          break;
        }
      } catch {
      }
    const c = s.filter((l) => l._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new q_(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (l) => {
      if (l !== i)
        try {
          await l._remove(o);
        } catch {
        }
    })), new q_(i, e, r));
  }
}
function Z9(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (dot(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (lot(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (pot(e))
    return "Blackberry";
  if (_ot(e))
    return "Webos";
  if (uot(e))
    return "Safari";
  if ((e.includes("chrome/") || hot(e)) && !e.includes("edge/"))
    return "Chrome";
  if (fot(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function lot(t = xa()) {
  return /firefox\//i.test(t);
}
function uot(t = xa()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function hot(t = xa()) {
  return /crios\//i.test(t);
}
function dot(t = xa()) {
  return /iemobile/i.test(t);
}
function fot(t = xa()) {
  return /android/i.test(t);
}
function pot(t = xa()) {
  return /blackberry/i.test(t);
}
function _ot(t = xa()) {
  return /webos/i.test(t);
}
function hue(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = Z9(xa());
      break;
    case "Worker":
      n = `${Z9(xa())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${yb}/${r}`;
}
async function due(t, e) {
  return bp(t, "GET", "/v2/recaptchaConfig", Zb(t, e));
}
function X9(t) {
  return t !== void 0 && t.enterprise !== void 0;
}
class fue {
  constructor(e) {
    if (this.siteKey = "", this.emailPasswordEnabled = !1, e.recaptchaKey === void 0)
      throw new Error("recaptchaKey undefined");
    this.siteKey = e.recaptchaKey.split("/")[3], this.emailPasswordEnabled = e.recaptchaEnforcementState.some((n) => n.provider === "EMAIL_PASSWORD_PROVIDER" && n.enforcementState !== "OFF");
  }
}
function mot() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function got(t) {
  return new Promise((e, n) => {
    const r = document.createElement("script");
    r.setAttribute("src", t), r.onload = e, r.onerror = (s) => {
      const i = Vj(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = s, n(i);
    }, r.type = "text/javascript", r.charset = "UTF-8", mot().appendChild(r);
  });
}
const yot = "https://www.google.com/recaptcha/enterprise.js?render=", vot = "recaptcha-enterprise";
class pue {
  /**
   *
   * @param authExtern - The corresponding Firebase {@link Auth} instance.
   *
   */
  constructor(e) {
    this.type = vot, this.auth = cD(e);
  }
  /**
   * Executes the verification process.
   *
   * @returns A Promise for a token that can be used to assert the validity of a request.
   */
  async verify(e = "verify", n = !1) {
    async function r(i) {
      if (!n) {
        if (i.tenantId == null && i._agentRecaptchaConfig != null)
          return i._agentRecaptchaConfig.siteKey;
        if (i.tenantId != null && i._tenantRecaptchaConfigs[i.tenantId] !== void 0)
          return i._tenantRecaptchaConfigs[i.tenantId].siteKey;
      }
      return new Promise(async (o, a) => {
        due(i, {
          clientType: "CLIENT_TYPE_WEB",
          version: "RECAPTCHA_ENTERPRISE"
          /* RecaptchaVersion.ENTERPRISE */
        }).then((c) => {
          if (c.recaptchaKey === void 0)
            a(new Error("recaptcha Enterprise site key undefined"));
          else {
            const l = new fue(c);
            return i.tenantId == null ? i._agentRecaptchaConfig = l : i._tenantRecaptchaConfigs[i.tenantId] = l, o(l.siteKey);
          }
        }).catch((c) => {
          a(c);
        });
      });
    }
    function s(i, o, a) {
      const c = window.grecaptcha;
      X9(c) ? c.enterprise.ready(() => {
        try {
          c.enterprise.execute(i, { action: e }).then((l) => {
            o(l);
          }).catch((l) => {
            a(l);
          });
        } catch (l) {
          a(l);
        }
      }) : a(Error("No reCAPTCHA enterprise script loaded."));
    }
    return new Promise((i, o) => {
      r(this.auth).then((a) => {
        if (!n && X9(window.grecaptcha))
          s(a, i, o);
        else {
          if (typeof window > "u") {
            o(new Error("RecaptchaVerifier is only supported in browser"));
            return;
          }
          got(yot + a).then(() => {
            s(a, i, o);
          }).catch((c) => {
            o(c);
          });
        }
      }).catch((a) => {
        o(a);
      });
    });
  }
}
async function zk(t, e, n, r = !1) {
  const s = new pue(t);
  let i;
  try {
    i = await s.verify(n);
  } catch {
    i = await s.verify(n, !0);
  }
  const o = Object.assign({}, e);
  return r ? Object.assign(o, { captchaResp: i }) : Object.assign(o, { captchaResponse: i }), Object.assign(o, {
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  }), Object.assign(o, {
    recaptchaVersion: "RECAPTCHA_ENTERPRISE"
    /* RecaptchaVersion.ENTERPRISE */
  }), o;
}
class wot {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
class Cot {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new e8(this), this.idTokenSubscription = new e8(this), this.beforeStateQueue = new wot(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = sue, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = Hd(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await q_.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && c != null && c.user && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return tt(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await Vk(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = $it();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? us(e) : null;
    return n && tt(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && tt(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(Hd(e));
    });
  }
  async initializeRecaptchaConfig() {
    const e = await due(this, {
      clientType: "CLIENT_TYPE_WEB",
      version: "RECAPTCHA_ENTERPRISE"
      /* RecaptchaVersion.ENTERPRISE */
    }), n = new fue(e);
    this.tenantId == null ? this._agentRecaptchaConfig = n : this._tenantRecaptchaConfigs[this.tenantId] = n, n.emailPasswordEnabled && new pue(this).verify();
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new gb("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && Hd(e) || this._popupRedirectResolver;
      tt(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await q_.create(
        this,
        [Hd(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return tt(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, r, s) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return tt(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = hue(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && jit(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function cD(t) {
  return us(t);
}
class e8 {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = EYe((n) => this.observer = n);
  }
  get next() {
    return tt(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function Tot(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(Hd);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
class bot {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return Ac("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return Ac("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return Ac("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return Ac("not implemented");
  }
}
async function Eot(t, e) {
  return bp(t, "POST", "/v1/accounts:update", e);
}
async function K1(t, e) {
  return Gj(t, "POST", "/v1/accounts:signInWithPassword", Zb(t, e));
}
async function Sot(t, e) {
  return bp(t, "POST", "/v1/accounts:sendOobCode", Zb(t, e));
}
async function J1(t, e) {
  return Sot(t, e);
}
async function Iot(t, e) {
  return Gj(t, "POST", "/v1/accounts:signInWithEmailLink", Zb(t, e));
}
async function Rot(t, e) {
  return Gj(t, "POST", "/v1/accounts:signInWithEmailLink", Zb(t, e));
}
class sT extends bot {
  /** @internal */
  constructor(e, n, r, s = null) {
    super("password", r), this._email = e, this._password = n, this._tenantId = s;
  }
  /** @internal */
  static _fromEmailAndPassword(e, n) {
    return new sT(
      e,
      n,
      "password"
      /* SignInMethod.EMAIL_PASSWORD */
    );
  }
  /** @internal */
  static _fromEmailAndCode(e, n, r = null) {
    return new sT(e, n, "emailLink", r);
  }
  /** {@inheritdoc AuthCredential.toJSON} */
  toJSON() {
    return {
      email: this._email,
      password: this._password,
      signInMethod: this.signInMethod,
      tenantId: this._tenantId
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an {@link  AuthCredential}.
   *
   * @param json - Either `object` or the stringified representation of the object. When string is
   * provided, `JSON.parse` would be called first.
   *
   * @returns If the JSON input does not represent an {@link AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e;
    if (n != null && n.email && n != null && n.password) {
      if (n.signInMethod === "password")
        return this._fromEmailAndPassword(n.email, n.password);
      if (n.signInMethod === "emailLink")
        return this._fromEmailAndCode(n.email, n.password, n.tenantId);
    }
    return null;
  }
  /** @internal */
  async _getIdTokenResponse(e) {
    var n;
    switch (this.signInMethod) {
      case "password":
        const r = {
          returnSecureToken: !0,
          email: this._email,
          password: this._password,
          clientType: "CLIENT_TYPE_WEB"
          /* RecaptchaClientType.WEB */
        };
        if (!((n = e._getRecaptchaConfig()) === null || n === void 0) && n.emailPasswordEnabled) {
          const s = await zk(
            e,
            r,
            "signInWithPassword"
            /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
          );
          return K1(e, s);
        } else
          return K1(e, r).catch(async (s) => {
            if (s.code === "auth/missing-recaptcha-token") {
              console.log("Sign-in with email address and password is protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the sign-in flow.");
              const i = await zk(
                e,
                r,
                "signInWithPassword"
                /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
              );
              return K1(e, i);
            } else
              return Promise.reject(s);
          });
      case "emailLink":
        return Iot(e, {
          email: this._email,
          oobCode: this._password
        });
      default:
        nT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  async _linkToIdToken(e, n) {
    switch (this.signInMethod) {
      case "password":
        return Eot(e, {
          idToken: n,
          returnSecureToken: !0,
          email: this._email,
          password: this._password
        });
      case "emailLink":
        return Rot(e, {
          idToken: n,
          email: this._email,
          oobCode: this._password
        });
      default:
        nT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return this._getIdTokenResponse(e);
  }
}
function kot(t) {
  switch (t) {
    case "recoverEmail":
      return "RECOVER_EMAIL";
    case "resetPassword":
      return "PASSWORD_RESET";
    case "signIn":
      return "EMAIL_SIGNIN";
    case "verifyEmail":
      return "VERIFY_EMAIL";
    case "verifyAndChangeEmail":
      return "VERIFY_AND_CHANGE_EMAIL";
    case "revertSecondFactorAddition":
      return "REVERT_SECOND_FACTOR_ADDITION";
    default:
      return null;
  }
}
function Not(t) {
  const e = Tv(bv(t)).link, n = e ? Tv(bv(e)).deep_link_id : null, r = Tv(bv(t)).deep_link_id;
  return (r ? Tv(bv(r)).link : null) || r || n || e || t;
}
class $j {
  /**
   * @param actionLink - The link from which to extract the URL.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @internal
   */
  constructor(e) {
    var n, r, s, i, o, a;
    const c = Tv(bv(e)), l = (n = c.apiKey) !== null && n !== void 0 ? n : null, h = (r = c.oobCode) !== null && r !== void 0 ? r : null, u = kot((s = c.mode) !== null && s !== void 0 ? s : null);
    tt(
      l && h && u,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), this.apiKey = l, this.operation = u, this.code = h, this.continueUrl = (i = c.continueUrl) !== null && i !== void 0 ? i : null, this.languageCode = (o = c.languageCode) !== null && o !== void 0 ? o : null, this.tenantId = (a = c.tenantId) !== null && a !== void 0 ? a : null;
  }
  /**
   * Parses the email action link string and returns an {@link ActionCodeURL} if the link is valid,
   * otherwise returns null.
   *
   * @param link  - The email action link string.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @public
   */
  static parseLink(e) {
    const n = Not(e);
    try {
      return new $j(n);
    } catch {
      return null;
    }
  }
}
class Pg {
  constructor() {
    this.providerId = Pg.PROVIDER_ID;
  }
  /**
   * Initialize an {@link AuthCredential} using an email and password.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credential(email, password);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * const userCredential = await signInWithEmailAndPassword(auth, email, password);
   * ```
   *
   * @param email - Email address.
   * @param password - User account password.
   * @returns The auth provider credential.
   */
  static credential(e, n) {
    return sT._fromEmailAndPassword(e, n);
  }
  /**
   * Initialize an {@link AuthCredential} using an email and an email link after a sign in with
   * email link operation.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credentialWithLink(auth, email, emailLink);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * await sendSignInLinkToEmail(auth, email);
   * // Obtain emailLink from user.
   * const userCredential = await signInWithEmailLink(auth, email, emailLink);
   * ```
   *
   * @param auth - The {@link Auth} instance used to verify the link.
   * @param email - Email address.
   * @param emailLink - Sign-in email link.
   * @returns - The auth provider credential.
   */
  static credentialWithLink(e, n) {
    const r = $j.parseLink(n);
    return tt(
      r,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), sT._fromEmailAndCode(e, r.code, r.tenantId);
  }
}
Pg.PROVIDER_ID = "password";
Pg.EMAIL_PASSWORD_SIGN_IN_METHOD = "password";
Pg.EMAIL_LINK_SIGN_IN_METHOD = "emailLink";
class Gk {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, r, s = !1) {
    const i = await uf._fromIdTokenResponse(e, r, s), o = t8(r);
    return new Gk({
      user: i,
      providerId: o,
      _tokenResponse: r,
      operationType: n
    });
  }
  static async _forOperation(e, n, r) {
    await e._updateTokensIfNecessary(
      r,
      /* reload */
      !0
    );
    const s = t8(r);
    return new Gk({
      user: e,
      providerId: s,
      _tokenResponse: r,
      operationType: n
    });
  }
}
function t8(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
class $k extends Ul {
  constructor(e, n, r, s) {
    var i;
    super(n.code, n.message), this.operationType = r, this.user = s, Object.setPrototypeOf(this, $k.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: r
    };
  }
  static _fromErrorAndOperation(e, n, r, s) {
    return new $k(e, n, r, s);
  }
}
function Pot(t, e, n, r) {
  return n._getIdTokenResponse(t).catch((s) => {
    throw s.code === "auth/multi-factor-auth-required" ? $k._fromErrorAndOperation(t, s, e, r) : s;
  });
}
async function Oot(t, e, n = !1) {
  const r = "signIn", s = await Pot(t, r, e), i = await Gk._fromIdTokenResponse(t, r, s);
  return n || await t._updateCurrentUser(i.user), i;
}
async function Dot(t, e) {
  return Oot(cD(t), e);
}
async function Aot(t, e, n) {
  var r;
  const s = cD(t), i = {
    requestType: "PASSWORD_RESET",
    email: e,
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  };
  if (!((r = s._getRecaptchaConfig()) === null || r === void 0) && r.emailPasswordEnabled) {
    const o = await zk(s, i, "getOobCode", !0);
    await J1(s, o);
  } else
    await J1(s, i).catch(async (o) => {
      if (o.code === "auth/missing-recaptcha-token") {
        console.log("Password resets are protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the password reset flow.");
        const a = await zk(s, i, "getOobCode", !0);
        await J1(s, a);
      } else
        return Promise.reject(o);
    });
}
async function Mot(t, e) {
  return bp(t, "POST", "/v1/accounts:update", e);
}
async function n8(t, { displayName: e, photoURL: n }) {
  if (e === void 0 && n === void 0)
    return;
  const r = us(t), s = {
    idToken: await r.getIdToken(),
    displayName: e,
    photoUrl: n,
    returnSecureToken: !0
  }, i = await jk(r, Mot(r.auth, s));
  r.displayName = i.displayName || null, r.photoURL = i.photoUrl || null;
  const o = r.providerData.find(
    ({ providerId: a }) => a === "password"
    /* ProviderId.PASSWORD */
  );
  o && (o.displayName = r.displayName, o.photoURL = r.photoURL), await r._updateTokensIfNecessary(i);
}
new Qb(3e4, 6e4);
new Qb(2e3, 1e4);
new Qb(3e4, 6e4);
new Qb(5e3, 15e3);
var r8 = "@firebase/auth", s8 = "0.23.0";
class xot {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    tt(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
function Lot(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function Uot(t) {
  jf(new xh(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      tt(o && !o.includes(":"), "invalid-api-key", { appName: r.name }), tt(!(a != null && a.includes(":")), "argument-error", {
        appName: r.name
      });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: hue(t)
      }, l = new Cot(r, s, i, c);
      return Tot(l, n), l;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), jf(new xh(
    "auth-internal",
    (e) => {
      const n = cD(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new xot(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), Xc(r8, s8, Lot(t)), Xc(r8, s8, "esm2017");
}
fYe("authIdTokenMaxAge");
Uot(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const i8 = {
  PICTURE_STORAGE: "/users/{uid}/UserProfilePic.jpg",
  PICTURE_DATE: "/users/{uid}/profilePicDate"
  // YYYY-MM-DD HH:mm:ss
};
class tv {
  static PictureStorage(e) {
    return K(i8.PICTURE_STORAGE, { uid: e });
  }
  static PictureDate(e) {
    return K(i8.PICTURE_DATE, { uid: e });
  }
}
const { resizeImage: Fot } = qtt;
class Yot extends Ese {
  constructor(e) {
    super(), this.auth = e;
  }
  async uploadProfilePicture(e) {
    try {
      let n = await Fot({
        file: e,
        maxSize: 750
        //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
      }), r = { contentType: e.type };
      await this.uploadObject(tv.PictureStorage(this.uid), n.blob, r);
      let s = await this.objectUrl(tv.PictureStorage(this.uid));
      return console.log(`Profile photo updated: ${s}`), await this.updatePhoto(s), await this.updatePictureDate(), s;
    } catch (n) {
      throw console.error("Upload profile: " + n.message), n;
    }
  }
  async deleteProfilePicture() {
    try {
      const e = tv.PictureStorage(this.uid);
      await this.deleteObject(e), await this.updatePhoto(""), await this.updatePictureDate();
    } catch (e) {
      throw console.error("Delete profile: " + e.message), e;
    }
  }
  async updatePictureDate() {
    return await this.set(tv.PictureDate(this.uid), this.currentTime);
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
  get currentTime() {
    return x().format("YYYY-MM-DD HH:mm:ss");
  }
  async getPhotoUrl() {
    const e = tv.PictureStorage(this.uid);
    return await this.objectUrl(e);
  }
  // get profilePictureExists() {
  //     (async () => {
  //         try {
  //             const path = ProfileResources.PictureStorage(this.uid);
  //             await this.objectUrl(path)
  //             return true;
  //         } catch(e) {
  //             return false;
  //         }
  //         })();
  // }
  async sendPasswordResetEmail() {
    try {
      return await Aot(this.auth, this.auth.currentUser.email);
    } catch (e) {
      throw console.error("Password reset: " + e.message), e;
    }
  }
  async updateEmail(e) {
    try {
      return await updateEmail(this.auth.currentUser, e);
    } catch (n) {
      throw console.error("Updating email address: " + n.message), n;
    }
  }
  async updateUserProfile(e) {
    try {
      return await n8(this.auth.currentUser, {
        displayName: e.trim()
      });
    } catch (n) {
      throw console.error("Updating profile name: " + n.message), n;
    }
  }
  async updatePhoto(e) {
    try {
      return await n8(this.auth.currentUser, {
        photoURL: e
      });
    } catch (n) {
      throw console.error("Updating profile photo: " + n.message), n;
    }
  }
  async reauthenticateWithCredential(e) {
    try {
      const n = Pg.credential(this.auth.currentUser.email, e);
      return await Dot(this.auth, n);
    } catch (n) {
      throw console.error("Verifying credentials: " + n.message), n;
    }
  }
}
var k0 = { exports: {} };
function Wot(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
k0.exports = Wot;
const Bot = (k0.exports == null ? {} : k0.exports).default || k0.exports;
var N0 = { exports: {} };
function qot(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
N0.exports = qot;
const Hot = (N0.exports == null ? {} : N0.exports).default || N0.exports;
var P0 = { exports: {} };
const jot = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
P0.exports = jot;
const Vot = (P0.exports == null ? {} : P0.exports).default || P0.exports;
var O0 = { exports: {} };
function zot(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
O0.exports = zot;
const Got = (O0.exports == null ? {} : O0.exports).default || O0.exports;
var D0 = { exports: {} };
class $ot {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var Kot = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new $ot(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
D0.exports = Kot;
const Jot = (D0.exports == null ? {} : D0.exports).default || D0.exports, Qot = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Analytics: jFe,
  BillingResources: tYe,
  CatalogFirebaseResources: GFe,
  CatalogHostedProvider: XFe,
  CatalogHostedResources: Li,
  CatalogPromotions: nYe,
  CatalogPropertyMap: _a,
  DataAccessLayer: xne,
  Environment: I_,
  MailingListProvider: fje,
  MailingListResources: $p,
  ProfileProvider: Yot,
  PurchaseResources: eYe,
  RequestResources: IB,
  allMap: cU,
  allSettledMap: cU,
  cyrb53: Bot,
  empty: Hot,
  isEmpty: Vot,
  isNumeric: Got,
  resizeImage: Jot,
  splitProduct: QS,
  templ8r: K
}, Symbol.toStringTag, { value: "Module" }));
var _ue;
function L() {
  return _ue.apply(null, arguments);
}
function Zot(t) {
  _ue = t;
}
function Zo(t) {
  return t instanceof Array || Object.prototype.toString.call(t) === "[object Array]";
}
function hf(t) {
  return t != null && Object.prototype.toString.call(t) === "[object Object]";
}
function _t(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function Kj(t) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(t).length === 0;
  var e;
  for (e in t)
    if (_t(t, e))
      return !1;
  return !0;
}
function ni(t) {
  return t === void 0;
}
function Sl(t) {
  return typeof t == "number" || Object.prototype.toString.call(t) === "[object Number]";
}
function Xb(t) {
  return t instanceof Date || Object.prototype.toString.call(t) === "[object Date]";
}
function mue(t, e) {
  var n = [], r, s = t.length;
  for (r = 0; r < s; ++r)
    n.push(e(t[r], r));
  return n;
}
function Ou(t, e) {
  for (var n in e)
    _t(e, n) && (t[n] = e[n]);
  return _t(e, "toString") && (t.toString = e.toString), _t(e, "valueOf") && (t.valueOf = e.valueOf), t;
}
function ec(t, e, n, r) {
  return Yue(t, e, n, r, !0).utc();
}
function Xot() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function me(t) {
  return t._pf == null && (t._pf = Xot()), t._pf;
}
var xF;
Array.prototype.some ? xF = Array.prototype.some : xF = function(t) {
  var e = Object(this), n = e.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in e && t.call(this, e[r], r, e))
      return !0;
  return !1;
};
function Jj(t) {
  var e = null, n = !1, r = t._d && !isNaN(t._d.getTime());
  if (r && (e = me(t), n = xF.call(e.parsedDateParts, function(s) {
    return s != null;
  }), r = e.overflow < 0 && !e.empty && !e.invalidEra && !e.invalidMonth && !e.invalidWeekday && !e.weekdayMismatch && !e.nullInput && !e.invalidFormat && !e.userInvalidated && (!e.meridiem || e.meridiem && n), t._strict && (r = r && e.charsLeftOver === 0 && e.unusedTokens.length === 0 && e.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(t))
    t._isValid = r;
  else
    return r;
  return t._isValid;
}
function lD(t) {
  var e = ec(NaN);
  return t != null ? Ou(me(e), t) : me(e).userInvalidated = !0, e;
}
var o8 = L.momentProperties = [], Q1 = !1;
function Qj(t, e) {
  var n, r, s, i = o8.length;
  if (ni(e._isAMomentObject) || (t._isAMomentObject = e._isAMomentObject), ni(e._i) || (t._i = e._i), ni(e._f) || (t._f = e._f), ni(e._l) || (t._l = e._l), ni(e._strict) || (t._strict = e._strict), ni(e._tzm) || (t._tzm = e._tzm), ni(e._isUTC) || (t._isUTC = e._isUTC), ni(e._offset) || (t._offset = e._offset), ni(e._pf) || (t._pf = me(e)), ni(e._locale) || (t._locale = e._locale), i > 0)
    for (n = 0; n < i; n++)
      r = o8[n], s = e[r], ni(s) || (t[r] = s);
  return t;
}
function eE(t) {
  Qj(this, t), this._d = new Date(t._d != null ? t._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), Q1 === !1 && (Q1 = !0, L.updateOffset(this), Q1 = !1);
}
function Xo(t) {
  return t instanceof eE || t != null && t._isAMomentObject != null;
}
function gue(t) {
  L.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + t);
}
function uo(t, e) {
  var n = !0;
  return Ou(function() {
    if (L.deprecationHandler != null && L.deprecationHandler(null, t), n) {
      var r = [], s, i, o, a = arguments.length;
      for (i = 0; i < a; i++) {
        if (s = "", typeof arguments[i] == "object") {
          s += `
[` + i + "] ";
          for (o in arguments[0])
            _t(arguments[0], o) && (s += o + ": " + arguments[0][o] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[i];
        r.push(s);
      }
      gue(
        t + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return e.apply(this, arguments);
  }, e);
}
var a8 = {};
function yue(t, e) {
  L.deprecationHandler != null && L.deprecationHandler(t, e), a8[t] || (gue(e), a8[t] = !0);
}
L.suppressDeprecationWarnings = !1;
L.deprecationHandler = null;
function tc(t) {
  return typeof Function < "u" && t instanceof Function || Object.prototype.toString.call(t) === "[object Function]";
}
function eat(t) {
  var e, n;
  for (n in t)
    _t(t, n) && (e = t[n], tc(e) ? this[n] = e : this["_" + n] = e);
  this._config = t, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function LF(t, e) {
  var n = Ou({}, t), r;
  for (r in e)
    _t(e, r) && (hf(t[r]) && hf(e[r]) ? (n[r] = {}, Ou(n[r], t[r]), Ou(n[r], e[r])) : e[r] != null ? n[r] = e[r] : delete n[r]);
  for (r in t)
    _t(t, r) && !_t(e, r) && hf(t[r]) && (n[r] = Ou({}, n[r]));
  return n;
}
function Zj(t) {
  t != null && this.set(t);
}
var UF;
Object.keys ? UF = Object.keys : UF = function(t) {
  var e, n = [];
  for (e in t)
    _t(t, e) && n.push(e);
  return n;
};
var tat = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function nat(t, e, n) {
  var r = this._calendar[t] || this._calendar.sameElse;
  return tc(r) ? r.call(e, n) : r;
}
function Ya(t, e, n) {
  var r = "" + Math.abs(t), s = e - r.length, i = t >= 0;
  return (i ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var Xj = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, gS = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, Z1 = {}, H_ = {};
function te(t, e, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), t && (H_[t] = s), e && (H_[e[0]] = function() {
    return Ya(s.apply(this, arguments), e[1], e[2]);
  }), n && (H_[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      t
    );
  });
}
function rat(t) {
  return t.match(/\[[\s\S]/) ? t.replace(/^\[|\]$/g, "") : t.replace(/\\/g, "");
}
function sat(t) {
  var e = t.match(Xj), n, r;
  for (n = 0, r = e.length; n < r; n++)
    H_[e[n]] ? e[n] = H_[e[n]] : e[n] = rat(e[n]);
  return function(s) {
    var i = "", o;
    for (o = 0; o < r; o++)
      i += tc(e[o]) ? e[o].call(s, t) : e[o];
    return i;
  };
}
function A0(t, e) {
  return t.isValid() ? (e = vue(e, t.localeData()), Z1[e] = Z1[e] || sat(e), Z1[e](t)) : t.localeData().invalidDate();
}
function vue(t, e) {
  var n = 5;
  function r(s) {
    return e.longDateFormat(s) || s;
  }
  for (gS.lastIndex = 0; n >= 0 && gS.test(t); )
    t = t.replace(
      gS,
      r
    ), gS.lastIndex = 0, n -= 1;
  return t;
}
var iat = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function oat(t) {
  var e = this._longDateFormat[t], n = this._longDateFormat[t.toUpperCase()];
  return e || !n ? e : (this._longDateFormat[t] = n.match(Xj).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[t]);
}
var aat = "Invalid date";
function cat() {
  return this._invalidDate;
}
var lat = "%d", uat = /\d{1,2}/;
function hat(t) {
  return this._ordinal.replace("%d", t);
}
var dat = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function fat(t, e, n, r) {
  var s = this._relativeTime[n];
  return tc(s) ? s(t, e, n, r) : s.replace(/%d/i, t);
}
function pat(t, e) {
  var n = this._relativeTime[t > 0 ? "future" : "past"];
  return tc(n) ? n(e) : n.replace(/%s/i, e);
}
var c8 = {
  D: "date",
  dates: "date",
  date: "date",
  d: "day",
  days: "day",
  day: "day",
  e: "weekday",
  weekdays: "weekday",
  weekday: "weekday",
  E: "isoWeekday",
  isoweekdays: "isoWeekday",
  isoweekday: "isoWeekday",
  DDD: "dayOfYear",
  dayofyears: "dayOfYear",
  dayofyear: "dayOfYear",
  h: "hour",
  hours: "hour",
  hour: "hour",
  ms: "millisecond",
  milliseconds: "millisecond",
  millisecond: "millisecond",
  m: "minute",
  minutes: "minute",
  minute: "minute",
  M: "month",
  months: "month",
  month: "month",
  Q: "quarter",
  quarters: "quarter",
  quarter: "quarter",
  s: "second",
  seconds: "second",
  second: "second",
  gg: "weekYear",
  weekyears: "weekYear",
  weekyear: "weekYear",
  GG: "isoWeekYear",
  isoweekyears: "isoWeekYear",
  isoweekyear: "isoWeekYear",
  w: "week",
  weeks: "week",
  week: "week",
  W: "isoWeek",
  isoweeks: "isoWeek",
  isoweek: "isoWeek",
  y: "year",
  years: "year",
  year: "year"
};
function ho(t) {
  return typeof t == "string" ? c8[t] || c8[t.toLowerCase()] : void 0;
}
function eV(t) {
  var e = {}, n, r;
  for (r in t)
    _t(t, r) && (n = ho(r), n && (e[n] = t[r]));
  return e;
}
var _at = {
  date: 9,
  day: 11,
  weekday: 11,
  isoWeekday: 11,
  dayOfYear: 4,
  hour: 13,
  millisecond: 16,
  minute: 14,
  month: 8,
  quarter: 7,
  second: 15,
  weekYear: 1,
  isoWeekYear: 1,
  week: 5,
  isoWeek: 5,
  year: 1
};
function mat(t) {
  var e = [], n;
  for (n in t)
    _t(t, n) && e.push({ unit: n, priority: _at[n] });
  return e.sort(function(r, s) {
    return r.priority - s.priority;
  }), e;
}
var wue = /\d/, Ni = /\d\d/, Cue = /\d{3}/, tV = /\d{4}/, uD = /[+-]?\d{6}/, pn = /\d\d?/, Tue = /\d\d\d\d?/, bue = /\d\d\d\d\d\d?/, hD = /\d{1,3}/, nV = /\d{1,4}/, dD = /[+-]?\d{1,6}/, Og = /\d+/, fD = /[+-]?\d+/, gat = /Z|[+-]\d\d:?\d\d/gi, pD = /Z|[+-]\d\d(?::?\d\d)?/gi, yat = /[+-]?\d+(\.\d{1,3})?/, tE = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, Dg = /^[1-9]\d?/, rV = /^([1-9]\d|\d)/, Kk;
Kk = {};
function q(t, e, n) {
  Kk[t] = tc(e) ? e : function(r, s) {
    return r && n ? n : e;
  };
}
function vat(t, e) {
  return _t(Kk, t) ? Kk[t](e._strict, e._locale) : new RegExp(wat(t));
}
function wat(t) {
  return cl(
    t.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(e, n, r, s, i) {
        return n || r || s || i;
      }
    )
  );
}
function cl(t) {
  return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
function Bi(t) {
  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
}
function je(t) {
  var e = +t, n = 0;
  return e !== 0 && isFinite(e) && (n = Bi(e)), n;
}
var FF = {};
function Wt(t, e) {
  var n, r = e, s;
  for (typeof t == "string" && (t = [t]), Sl(e) && (r = function(i, o) {
    o[e] = je(i);
  }), s = t.length, n = 0; n < s; n++)
    FF[t[n]] = r;
}
function nE(t, e) {
  Wt(t, function(n, r, s, i) {
    s._w = s._w || {}, e(n, s._w, s, i);
  });
}
function Cat(t, e, n) {
  e != null && _t(FF, t) && FF[t](e, n._a, n, t);
}
function _D(t) {
  return t % 4 === 0 && t % 100 !== 0 || t % 400 === 0;
}
var ps = 0, Mc = 1, wa = 2, Cr = 3, Uo = 4, xc = 5, jd = 6, Tat = 7, bat = 8;
te("Y", 0, 0, function() {
  var t = this.year();
  return t <= 9999 ? Ya(t, 4) : "+" + t;
});
te(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
te(0, ["YYYY", 4], 0, "year");
te(0, ["YYYYY", 5], 0, "year");
te(0, ["YYYYYY", 6, !0], 0, "year");
q("Y", fD);
q("YY", pn, Ni);
q("YYYY", nV, tV);
q("YYYYY", dD, uD);
q("YYYYYY", dD, uD);
Wt(["YYYYY", "YYYYYY"], ps);
Wt("YYYY", function(t, e) {
  e[ps] = t.length === 2 ? L.parseTwoDigitYear(t) : je(t);
});
Wt("YY", function(t, e) {
  e[ps] = L.parseTwoDigitYear(t);
});
Wt("Y", function(t, e) {
  e[ps] = parseInt(t, 10);
});
function ww(t) {
  return _D(t) ? 366 : 365;
}
L.parseTwoDigitYear = function(t) {
  return je(t) + (je(t) > 68 ? 1900 : 2e3);
};
var Eue = Ag("FullYear", !0);
function Eat() {
  return _D(this.year());
}
function Ag(t, e) {
  return function(n) {
    return n != null ? (Sue(this, t, n), L.updateOffset(this, e), this) : iT(this, t);
  };
}
function iT(t, e) {
  if (!t.isValid())
    return NaN;
  var n = t._d, r = t._isUTC;
  switch (e) {
    case "Milliseconds":
      return r ? n.getUTCMilliseconds() : n.getMilliseconds();
    case "Seconds":
      return r ? n.getUTCSeconds() : n.getSeconds();
    case "Minutes":
      return r ? n.getUTCMinutes() : n.getMinutes();
    case "Hours":
      return r ? n.getUTCHours() : n.getHours();
    case "Date":
      return r ? n.getUTCDate() : n.getDate();
    case "Day":
      return r ? n.getUTCDay() : n.getDay();
    case "Month":
      return r ? n.getUTCMonth() : n.getMonth();
    case "FullYear":
      return r ? n.getUTCFullYear() : n.getFullYear();
    default:
      return NaN;
  }
}
function Sue(t, e, n) {
  var r, s, i, o, a;
  if (!(!t.isValid() || isNaN(n))) {
    switch (r = t._d, s = t._isUTC, e) {
      case "Milliseconds":
        return void (s ? r.setUTCMilliseconds(n) : r.setMilliseconds(n));
      case "Seconds":
        return void (s ? r.setUTCSeconds(n) : r.setSeconds(n));
      case "Minutes":
        return void (s ? r.setUTCMinutes(n) : r.setMinutes(n));
      case "Hours":
        return void (s ? r.setUTCHours(n) : r.setHours(n));
      case "Date":
        return void (s ? r.setUTCDate(n) : r.setDate(n));
      case "FullYear":
        break;
      default:
        return;
    }
    i = n, o = t.month(), a = t.date(), a = a === 29 && o === 1 && !_D(i) ? 28 : a, s ? r.setUTCFullYear(i, o, a) : r.setFullYear(i, o, a);
  }
}
function Sat(t) {
  return t = ho(t), tc(this[t]) ? this[t]() : this;
}
function Iat(t, e) {
  if (typeof t == "object") {
    t = eV(t);
    var n = mat(t), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](t[n[r].unit]);
  } else if (t = ho(t), tc(this[t]))
    return this[t](e);
  return this;
}
function Rat(t, e) {
  return (t % e + e) % e;
}
var tr;
Array.prototype.indexOf ? tr = Array.prototype.indexOf : tr = function(t) {
  var e;
  for (e = 0; e < this.length; ++e)
    if (this[e] === t)
      return e;
  return -1;
};
function sV(t, e) {
  if (isNaN(t) || isNaN(e))
    return NaN;
  var n = Rat(e, 12);
  return t += (e - n) / 12, n === 1 ? _D(t) ? 29 : 28 : 31 - n % 7 % 2;
}
te("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
te("MMM", 0, 0, function(t) {
  return this.localeData().monthsShort(this, t);
});
te("MMMM", 0, 0, function(t) {
  return this.localeData().months(this, t);
});
q("M", pn, Dg);
q("MM", pn, Ni);
q("MMM", function(t, e) {
  return e.monthsShortRegex(t);
});
q("MMMM", function(t, e) {
  return e.monthsRegex(t);
});
Wt(["M", "MM"], function(t, e) {
  e[Mc] = je(t) - 1;
});
Wt(["MMM", "MMMM"], function(t, e, n, r) {
  var s = n._locale.monthsParse(t, r, n._strict);
  s != null ? e[Mc] = s : me(n).invalidMonth = t;
});
var kat = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), Iue = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), Rue = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, Nat = tE, Pat = tE;
function Oat(t, e) {
  return t ? Zo(this._months) ? this._months[t.month()] : this._months[(this._months.isFormat || Rue).test(e) ? "format" : "standalone"][t.month()] : Zo(this._months) ? this._months : this._months.standalone;
}
function Dat(t, e) {
  return t ? Zo(this._monthsShort) ? this._monthsShort[t.month()] : this._monthsShort[Rue.test(e) ? "format" : "standalone"][t.month()] : Zo(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function Aat(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      i = ec([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        i,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(i, "").toLocaleLowerCase();
  return n ? e === "MMM" ? (s = tr.call(this._shortMonthsParse, o), s !== -1 ? s : null) : (s = tr.call(this._longMonthsParse, o), s !== -1 ? s : null) : e === "MMM" ? (s = tr.call(this._shortMonthsParse, o), s !== -1 ? s : (s = tr.call(this._longMonthsParse, o), s !== -1 ? s : null)) : (s = tr.call(this._longMonthsParse, o), s !== -1 ? s : (s = tr.call(this._shortMonthsParse, o), s !== -1 ? s : null));
}
function Mat(t, e, n) {
  var r, s, i;
  if (this._monthsParseExact)
    return Aat.call(this, t, e, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++)
    if (s = ec([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (i = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "MMMM" && this._longMonthsParse[r].test(t) || n && e === "MMM" && this._shortMonthsParse[r].test(t) || !n && this._monthsParse[r].test(t))
      return r;
}
function kue(t, e) {
  if (!t.isValid())
    return t;
  if (typeof e == "string") {
    if (/^\d+$/.test(e))
      e = je(e);
    else if (e = t.localeData().monthsParse(e), !Sl(e))
      return t;
  }
  var n = e, r = t.date();
  return r = r < 29 ? r : Math.min(r, sV(t.year(), n)), t._isUTC ? t._d.setUTCMonth(n, r) : t._d.setMonth(n, r), t;
}
function Nue(t) {
  return t != null ? (kue(this, t), L.updateOffset(this, !0), this) : iT(this, "Month");
}
function xat() {
  return sV(this.year(), this.month());
}
function Lat(t) {
  return this._monthsParseExact ? (_t(this, "_monthsRegex") || Pue.call(this), t ? this._monthsShortStrictRegex : this._monthsShortRegex) : (_t(this, "_monthsShortRegex") || (this._monthsShortRegex = Nat), this._monthsShortStrictRegex && t ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function Uat(t) {
  return this._monthsParseExact ? (_t(this, "_monthsRegex") || Pue.call(this), t ? this._monthsStrictRegex : this._monthsRegex) : (_t(this, "_monthsRegex") || (this._monthsRegex = Pat), this._monthsStrictRegex && t ? this._monthsStrictRegex : this._monthsRegex);
}
function Pue() {
  function t(c, l) {
    return l.length - c.length;
  }
  var e = [], n = [], r = [], s, i, o, a;
  for (s = 0; s < 12; s++)
    i = ec([2e3, s]), o = cl(this.monthsShort(i, "")), a = cl(this.months(i, "")), e.push(o), n.push(a), r.push(a), r.push(o);
  e.sort(t), n.sort(t), r.sort(t), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function Fat(t, e, n, r, s, i, o) {
  var a;
  return t < 100 && t >= 0 ? (a = new Date(t + 400, e, n, r, s, i, o), isFinite(a.getFullYear()) && a.setFullYear(t)) : a = new Date(t, e, n, r, s, i, o), a;
}
function oT(t) {
  var e, n;
  return t < 100 && t >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = t + 400, e = new Date(Date.UTC.apply(null, n)), isFinite(e.getUTCFullYear()) && e.setUTCFullYear(t)) : e = new Date(Date.UTC.apply(null, arguments)), e;
}
function Jk(t, e, n) {
  var r = 7 + e - n, s = (7 + oT(t, 0, r).getUTCDay() - e) % 7;
  return -s + r - 1;
}
function Oue(t, e, n, r, s) {
  var i = (7 + n - r) % 7, o = Jk(t, r, s), a = 1 + 7 * (e - 1) + i + o, c, l;
  return a <= 0 ? (c = t - 1, l = ww(c) + a) : a > ww(t) ? (c = t + 1, l = a - ww(t)) : (c = t, l = a), {
    year: c,
    dayOfYear: l
  };
}
function aT(t, e, n) {
  var r = Jk(t.year(), e, n), s = Math.floor((t.dayOfYear() - r - 1) / 7) + 1, i, o;
  return s < 1 ? (o = t.year() - 1, i = s + ll(o, e, n)) : s > ll(t.year(), e, n) ? (i = s - ll(t.year(), e, n), o = t.year() + 1) : (o = t.year(), i = s), {
    week: i,
    year: o
  };
}
function ll(t, e, n) {
  var r = Jk(t, e, n), s = Jk(t + 1, e, n);
  return (ww(t) - r + s) / 7;
}
te("w", ["ww", 2], "wo", "week");
te("W", ["WW", 2], "Wo", "isoWeek");
q("w", pn, Dg);
q("ww", pn, Ni);
q("W", pn, Dg);
q("WW", pn, Ni);
nE(
  ["w", "ww", "W", "WW"],
  function(t, e, n, r) {
    e[r.substr(0, 1)] = je(t);
  }
);
function Yat(t) {
  return aT(t, this._week.dow, this._week.doy).week;
}
var Wat = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function Bat() {
  return this._week.dow;
}
function qat() {
  return this._week.doy;
}
function Hat(t) {
  var e = this.localeData().week(this);
  return t == null ? e : this.add((t - e) * 7, "d");
}
function jat(t) {
  var e = aT(this, 1, 4).week;
  return t == null ? e : this.add((t - e) * 7, "d");
}
te("d", 0, "do", "day");
te("dd", 0, 0, function(t) {
  return this.localeData().weekdaysMin(this, t);
});
te("ddd", 0, 0, function(t) {
  return this.localeData().weekdaysShort(this, t);
});
te("dddd", 0, 0, function(t) {
  return this.localeData().weekdays(this, t);
});
te("e", 0, 0, "weekday");
te("E", 0, 0, "isoWeekday");
q("d", pn);
q("e", pn);
q("E", pn);
q("dd", function(t, e) {
  return e.weekdaysMinRegex(t);
});
q("ddd", function(t, e) {
  return e.weekdaysShortRegex(t);
});
q("dddd", function(t, e) {
  return e.weekdaysRegex(t);
});
nE(["dd", "ddd", "dddd"], function(t, e, n, r) {
  var s = n._locale.weekdaysParse(t, r, n._strict);
  s != null ? e.d = s : me(n).invalidWeekday = t;
});
nE(["d", "e", "E"], function(t, e, n, r) {
  e[r] = je(t);
});
function Vat(t, e) {
  return typeof t != "string" ? t : isNaN(t) ? (t = e.weekdaysParse(t), typeof t == "number" ? t : null) : parseInt(t, 10);
}
function zat(t, e) {
  return typeof t == "string" ? e.weekdaysParse(t) % 7 || 7 : isNaN(t) ? null : t;
}
function iV(t, e) {
  return t.slice(e, 7).concat(t.slice(0, e));
}
var Gat = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), Due = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), $at = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), Kat = tE, Jat = tE, Qat = tE;
function Zat(t, e) {
  var n = Zo(this._weekdays) ? this._weekdays : this._weekdays[t && t !== !0 && this._weekdays.isFormat.test(e) ? "format" : "standalone"];
  return t === !0 ? iV(n, this._week.dow) : t ? n[t.day()] : n;
}
function Xat(t) {
  return t === !0 ? iV(this._weekdaysShort, this._week.dow) : t ? this._weekdaysShort[t.day()] : this._weekdaysShort;
}
function ect(t) {
  return t === !0 ? iV(this._weekdaysMin, this._week.dow) : t ? this._weekdaysMin[t.day()] : this._weekdaysMin;
}
function tct(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      i = ec([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        i,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        i,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(i, "").toLocaleLowerCase();
  return n ? e === "dddd" ? (s = tr.call(this._weekdaysParse, o), s !== -1 ? s : null) : e === "ddd" ? (s = tr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null) : (s = tr.call(this._minWeekdaysParse, o), s !== -1 ? s : null) : e === "dddd" ? (s = tr.call(this._weekdaysParse, o), s !== -1 || (s = tr.call(this._shortWeekdaysParse, o), s !== -1) ? s : (s = tr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : e === "ddd" ? (s = tr.call(this._shortWeekdaysParse, o), s !== -1 || (s = tr.call(this._weekdaysParse, o), s !== -1) ? s : (s = tr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : (s = tr.call(this._minWeekdaysParse, o), s !== -1 || (s = tr.call(this._weekdaysParse, o), s !== -1) ? s : (s = tr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null));
}
function nct(t, e, n) {
  var r, s, i;
  if (this._weekdaysParseExact)
    return tct.call(this, t, e, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++)
    if (s = ec([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (i = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "dddd" && this._fullWeekdaysParse[r].test(t) || n && e === "ddd" && this._shortWeekdaysParse[r].test(t) || n && e === "dd" && this._minWeekdaysParse[r].test(t) || !n && this._weekdaysParse[r].test(t))
      return r;
}
function rct(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = iT(this, "Day");
  return t != null ? (t = Vat(t, this.localeData()), this.add(t - e, "d")) : e;
}
function sct(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return t == null ? e : this.add(t - e, "d");
}
function ict(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    var e = zat(t, this.localeData());
    return this.day(this.day() % 7 ? e : e - 7);
  } else
    return this.day() || 7;
}
function oct(t) {
  return this._weekdaysParseExact ? (_t(this, "_weekdaysRegex") || oV.call(this), t ? this._weekdaysStrictRegex : this._weekdaysRegex) : (_t(this, "_weekdaysRegex") || (this._weekdaysRegex = Kat), this._weekdaysStrictRegex && t ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function act(t) {
  return this._weekdaysParseExact ? (_t(this, "_weekdaysRegex") || oV.call(this), t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (_t(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = Jat), this._weekdaysShortStrictRegex && t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function cct(t) {
  return this._weekdaysParseExact ? (_t(this, "_weekdaysRegex") || oV.call(this), t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (_t(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = Qat), this._weekdaysMinStrictRegex && t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function oV() {
  function t(h, u) {
    return u.length - h.length;
  }
  var e = [], n = [], r = [], s = [], i, o, a, c, l;
  for (i = 0; i < 7; i++)
    o = ec([2e3, 1]).day(i), a = cl(this.weekdaysMin(o, "")), c = cl(this.weekdaysShort(o, "")), l = cl(this.weekdays(o, "")), e.push(a), n.push(c), r.push(l), s.push(a), s.push(c), s.push(l);
  e.sort(t), n.sort(t), r.sort(t), s.sort(t), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function aV() {
  return this.hours() % 12 || 12;
}
function lct() {
  return this.hours() || 24;
}
te("H", ["HH", 2], 0, "hour");
te("h", ["hh", 2], 0, aV);
te("k", ["kk", 2], 0, lct);
te("hmm", 0, 0, function() {
  return "" + aV.apply(this) + Ya(this.minutes(), 2);
});
te("hmmss", 0, 0, function() {
  return "" + aV.apply(this) + Ya(this.minutes(), 2) + Ya(this.seconds(), 2);
});
te("Hmm", 0, 0, function() {
  return "" + this.hours() + Ya(this.minutes(), 2);
});
te("Hmmss", 0, 0, function() {
  return "" + this.hours() + Ya(this.minutes(), 2) + Ya(this.seconds(), 2);
});
function Aue(t, e) {
  te(t, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      e
    );
  });
}
Aue("a", !0);
Aue("A", !1);
function Mue(t, e) {
  return e._meridiemParse;
}
q("a", Mue);
q("A", Mue);
q("H", pn, rV);
q("h", pn, Dg);
q("k", pn, Dg);
q("HH", pn, Ni);
q("hh", pn, Ni);
q("kk", pn, Ni);
q("hmm", Tue);
q("hmmss", bue);
q("Hmm", Tue);
q("Hmmss", bue);
Wt(["H", "HH"], Cr);
Wt(["k", "kk"], function(t, e, n) {
  var r = je(t);
  e[Cr] = r === 24 ? 0 : r;
});
Wt(["a", "A"], function(t, e, n) {
  n._isPm = n._locale.isPM(t), n._meridiem = t;
});
Wt(["h", "hh"], function(t, e, n) {
  e[Cr] = je(t), me(n).bigHour = !0;
});
Wt("hmm", function(t, e, n) {
  var r = t.length - 2;
  e[Cr] = je(t.substr(0, r)), e[Uo] = je(t.substr(r)), me(n).bigHour = !0;
});
Wt("hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[Cr] = je(t.substr(0, r)), e[Uo] = je(t.substr(r, 2)), e[xc] = je(t.substr(s)), me(n).bigHour = !0;
});
Wt("Hmm", function(t, e, n) {
  var r = t.length - 2;
  e[Cr] = je(t.substr(0, r)), e[Uo] = je(t.substr(r));
});
Wt("Hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[Cr] = je(t.substr(0, r)), e[Uo] = je(t.substr(r, 2)), e[xc] = je(t.substr(s));
});
function uct(t) {
  return (t + "").toLowerCase().charAt(0) === "p";
}
var hct = /[ap]\.?m?\.?/i, dct = Ag("Hours", !0);
function fct(t, e, n) {
  return t > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var xue = {
  calendar: tat,
  longDateFormat: iat,
  invalidDate: aat,
  ordinal: lat,
  dayOfMonthOrdinalParse: uat,
  relativeTime: dat,
  months: kat,
  monthsShort: Iue,
  week: Wat,
  weekdays: Gat,
  weekdaysMin: $at,
  weekdaysShort: Due,
  meridiemParse: hct
}, wn = {}, nv = {}, cT;
function pct(t, e) {
  var n, r = Math.min(t.length, e.length);
  for (n = 0; n < r; n += 1)
    if (t[n] !== e[n])
      return n;
  return r;
}
function l8(t) {
  return t && t.toLowerCase().replace("_", "-");
}
function _ct(t) {
  for (var e = 0, n, r, s, i; e < t.length; ) {
    for (i = l8(t[e]).split("-"), n = i.length, r = l8(t[e + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = mD(i.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && pct(i, r) >= n - 1)
        break;
      n--;
    }
    e++;
  }
  return cT;
}
function mct(t) {
  return !!(t && t.match("^[^/\\\\]*$"));
}
function mD(t) {
  var e = null, n;
  if (wn[t] === void 0 && typeof module < "u" && module && module.exports && mct(t))
    try {
      e = cT._abbr, n = require, n("./locale/" + t), _h(e);
    } catch {
      wn[t] = null;
    }
  return wn[t];
}
function _h(t, e) {
  var n;
  return t && (ni(e) ? n = zl(t) : n = cV(t, e), n ? cT = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + t + " not found. Did you forget to load it?"
  )), cT._abbr;
}
function cV(t, e) {
  if (e !== null) {
    var n, r = xue;
    if (e.abbr = t, wn[t] != null)
      yue(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = wn[t]._config;
    else if (e.parentLocale != null)
      if (wn[e.parentLocale] != null)
        r = wn[e.parentLocale]._config;
      else if (n = mD(e.parentLocale), n != null)
        r = n._config;
      else
        return nv[e.parentLocale] || (nv[e.parentLocale] = []), nv[e.parentLocale].push({
          name: t,
          config: e
        }), null;
    return wn[t] = new Zj(LF(r, e)), nv[t] && nv[t].forEach(function(s) {
      cV(s.name, s.config);
    }), _h(t), wn[t];
  } else
    return delete wn[t], null;
}
function gct(t, e) {
  if (e != null) {
    var n, r, s = xue;
    wn[t] != null && wn[t].parentLocale != null ? wn[t].set(LF(wn[t]._config, e)) : (r = mD(t), r != null && (s = r._config), e = LF(s, e), r == null && (e.abbr = t), n = new Zj(e), n.parentLocale = wn[t], wn[t] = n), _h(t);
  } else
    wn[t] != null && (wn[t].parentLocale != null ? (wn[t] = wn[t].parentLocale, t === _h() && _h(t)) : wn[t] != null && delete wn[t]);
  return wn[t];
}
function zl(t) {
  var e;
  if (t && t._locale && t._locale._abbr && (t = t._locale._abbr), !t)
    return cT;
  if (!Zo(t)) {
    if (e = mD(t), e)
      return e;
    t = [t];
  }
  return _ct(t);
}
function yct() {
  return UF(wn);
}
function lV(t) {
  var e, n = t._a;
  return n && me(t).overflow === -2 && (e = n[Mc] < 0 || n[Mc] > 11 ? Mc : n[wa] < 1 || n[wa] > sV(n[ps], n[Mc]) ? wa : n[Cr] < 0 || n[Cr] > 24 || n[Cr] === 24 && (n[Uo] !== 0 || n[xc] !== 0 || n[jd] !== 0) ? Cr : n[Uo] < 0 || n[Uo] > 59 ? Uo : n[xc] < 0 || n[xc] > 59 ? xc : n[jd] < 0 || n[jd] > 999 ? jd : -1, me(t)._overflowDayOfYear && (e < ps || e > wa) && (e = wa), me(t)._overflowWeeks && e === -1 && (e = Tat), me(t)._overflowWeekday && e === -1 && (e = bat), me(t).overflow = e), t;
}
var vct = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, wct = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Cct = /Z|[+-]\d\d(?::?\d\d)?/, yS = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], X1 = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], Tct = /^\/?Date\((-?\d+)/i, bct = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, Ect = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function Lue(t) {
  var e, n, r = t._i, s = vct.exec(r) || wct.exec(r), i, o, a, c, l = yS.length, h = X1.length;
  if (s) {
    for (me(t).iso = !0, e = 0, n = l; e < n; e++)
      if (yS[e][1].exec(s[1])) {
        o = yS[e][0], i = yS[e][2] !== !1;
        break;
      }
    if (o == null) {
      t._isValid = !1;
      return;
    }
    if (s[3]) {
      for (e = 0, n = h; e < n; e++)
        if (X1[e][1].exec(s[3])) {
          a = (s[2] || " ") + X1[e][0];
          break;
        }
      if (a == null) {
        t._isValid = !1;
        return;
      }
    }
    if (!i && a != null) {
      t._isValid = !1;
      return;
    }
    if (s[4])
      if (Cct.exec(s[4]))
        c = "Z";
      else {
        t._isValid = !1;
        return;
      }
    t._f = o + (a || "") + (c || ""), hV(t);
  } else
    t._isValid = !1;
}
function Sct(t, e, n, r, s, i) {
  var o = [
    Ict(t),
    Iue.indexOf(e),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return i && o.push(parseInt(i, 10)), o;
}
function Ict(t) {
  var e = parseInt(t, 10);
  return e <= 49 ? 2e3 + e : e <= 999 ? 1900 + e : e;
}
function Rct(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function kct(t, e, n) {
  if (t) {
    var r = Due.indexOf(t), s = new Date(
      e[0],
      e[1],
      e[2]
    ).getDay();
    if (r !== s)
      return me(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function Nct(t, e, n) {
  if (t)
    return Ect[t];
  if (e)
    return 0;
  var r = parseInt(n, 10), s = r % 100, i = (r - s) / 100;
  return i * 60 + s;
}
function Uue(t) {
  var e = bct.exec(Rct(t._i)), n;
  if (e) {
    if (n = Sct(
      e[4],
      e[3],
      e[2],
      e[5],
      e[6],
      e[7]
    ), !kct(e[1], n, t))
      return;
    t._a = n, t._tzm = Nct(e[8], e[9], e[10]), t._d = oT.apply(null, t._a), t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), me(t).rfc2822 = !0;
  } else
    t._isValid = !1;
}
function Pct(t) {
  var e = Tct.exec(t._i);
  if (e !== null) {
    t._d = /* @__PURE__ */ new Date(+e[1]);
    return;
  }
  if (Lue(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  if (Uue(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  t._strict ? t._isValid = !1 : L.createFromInputFallback(t);
}
L.createFromInputFallback = uo(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(t) {
    t._d = /* @__PURE__ */ new Date(t._i + (t._useUTC ? " UTC" : ""));
  }
);
function Xp(t, e, n) {
  return t ?? e ?? n;
}
function Oct(t) {
  var e = new Date(L.now());
  return t._useUTC ? [
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate()
  ] : [e.getFullYear(), e.getMonth(), e.getDate()];
}
function uV(t) {
  var e, n, r = [], s, i, o;
  if (!t._d) {
    for (s = Oct(t), t._w && t._a[wa] == null && t._a[Mc] == null && Dct(t), t._dayOfYear != null && (o = Xp(t._a[ps], s[ps]), (t._dayOfYear > ww(o) || t._dayOfYear === 0) && (me(t)._overflowDayOfYear = !0), n = oT(o, 0, t._dayOfYear), t._a[Mc] = n.getUTCMonth(), t._a[wa] = n.getUTCDate()), e = 0; e < 3 && t._a[e] == null; ++e)
      t._a[e] = r[e] = s[e];
    for (; e < 7; e++)
      t._a[e] = r[e] = t._a[e] == null ? e === 2 ? 1 : 0 : t._a[e];
    t._a[Cr] === 24 && t._a[Uo] === 0 && t._a[xc] === 0 && t._a[jd] === 0 && (t._nextDay = !0, t._a[Cr] = 0), t._d = (t._useUTC ? oT : Fat).apply(
      null,
      r
    ), i = t._useUTC ? t._d.getUTCDay() : t._d.getDay(), t._tzm != null && t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), t._nextDay && (t._a[Cr] = 24), t._w && typeof t._w.d < "u" && t._w.d !== i && (me(t).weekdayMismatch = !0);
  }
}
function Dct(t) {
  var e, n, r, s, i, o, a, c, l;
  e = t._w, e.GG != null || e.W != null || e.E != null ? (i = 1, o = 4, n = Xp(
    e.GG,
    t._a[ps],
    aT(cn(), 1, 4).year
  ), r = Xp(e.W, 1), s = Xp(e.E, 1), (s < 1 || s > 7) && (c = !0)) : (i = t._locale._week.dow, o = t._locale._week.doy, l = aT(cn(), i, o), n = Xp(e.gg, t._a[ps], l.year), r = Xp(e.w, l.week), e.d != null ? (s = e.d, (s < 0 || s > 6) && (c = !0)) : e.e != null ? (s = e.e + i, (e.e < 0 || e.e > 6) && (c = !0)) : s = i), r < 1 || r > ll(n, i, o) ? me(t)._overflowWeeks = !0 : c != null ? me(t)._overflowWeekday = !0 : (a = Oue(n, r, s, i, o), t._a[ps] = a.year, t._dayOfYear = a.dayOfYear);
}
L.ISO_8601 = function() {
};
L.RFC_2822 = function() {
};
function hV(t) {
  if (t._f === L.ISO_8601) {
    Lue(t);
    return;
  }
  if (t._f === L.RFC_2822) {
    Uue(t);
    return;
  }
  t._a = [], me(t).empty = !0;
  var e = "" + t._i, n, r, s, i, o, a = e.length, c = 0, l, h;
  for (s = vue(t._f, t._locale).match(Xj) || [], h = s.length, n = 0; n < h; n++)
    i = s[n], r = (e.match(vat(i, t)) || [])[0], r && (o = e.substr(0, e.indexOf(r)), o.length > 0 && me(t).unusedInput.push(o), e = e.slice(
      e.indexOf(r) + r.length
    ), c += r.length), H_[i] ? (r ? me(t).empty = !1 : me(t).unusedTokens.push(i), Cat(i, r, t)) : t._strict && !r && me(t).unusedTokens.push(i);
  me(t).charsLeftOver = a - c, e.length > 0 && me(t).unusedInput.push(e), t._a[Cr] <= 12 && me(t).bigHour === !0 && t._a[Cr] > 0 && (me(t).bigHour = void 0), me(t).parsedDateParts = t._a.slice(0), me(t).meridiem = t._meridiem, t._a[Cr] = Act(
    t._locale,
    t._a[Cr],
    t._meridiem
  ), l = me(t).era, l !== null && (t._a[ps] = t._locale.erasConvertYear(l, t._a[ps])), uV(t), lV(t);
}
function Act(t, e, n) {
  var r;
  return n == null ? e : t.meridiemHour != null ? t.meridiemHour(e, n) : (t.isPM != null && (r = t.isPM(n), r && e < 12 && (e += 12), !r && e === 12 && (e = 0)), e);
}
function Mct(t) {
  var e, n, r, s, i, o, a = !1, c = t._f.length;
  if (c === 0) {
    me(t).invalidFormat = !0, t._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < c; s++)
    i = 0, o = !1, e = Qj({}, t), t._useUTC != null && (e._useUTC = t._useUTC), e._f = t._f[s], hV(e), Jj(e) && (o = !0), i += me(e).charsLeftOver, i += me(e).unusedTokens.length * 10, me(e).score = i, a ? i < r && (r = i, n = e) : (r == null || i < r || o) && (r = i, n = e, o && (a = !0));
  Ou(t, n || e);
}
function xct(t) {
  if (!t._d) {
    var e = eV(t._i), n = e.day === void 0 ? e.date : e.day;
    t._a = mue(
      [e.year, e.month, n, e.hour, e.minute, e.second, e.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), uV(t);
  }
}
function Lct(t) {
  var e = new eE(lV(Fue(t)));
  return e._nextDay && (e.add(1, "d"), e._nextDay = void 0), e;
}
function Fue(t) {
  var e = t._i, n = t._f;
  return t._locale = t._locale || zl(t._l), e === null || n === void 0 && e === "" ? lD({ nullInput: !0 }) : (typeof e == "string" && (t._i = e = t._locale.preparse(e)), Xo(e) ? new eE(lV(e)) : (Xb(e) ? t._d = e : Zo(n) ? Mct(t) : n ? hV(t) : Uct(t), Jj(t) || (t._d = null), t));
}
function Uct(t) {
  var e = t._i;
  ni(e) ? t._d = new Date(L.now()) : Xb(e) ? t._d = new Date(e.valueOf()) : typeof e == "string" ? Pct(t) : Zo(e) ? (t._a = mue(e.slice(0), function(n) {
    return parseInt(n, 10);
  }), uV(t)) : hf(e) ? xct(t) : Sl(e) ? t._d = new Date(e) : L.createFromInputFallback(t);
}
function Yue(t, e, n, r, s) {
  var i = {};
  return (e === !0 || e === !1) && (r = e, e = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (hf(t) && Kj(t) || Zo(t) && t.length === 0) && (t = void 0), i._isAMomentObject = !0, i._useUTC = i._isUTC = s, i._l = n, i._i = t, i._f = e, i._strict = r, Lct(i);
}
function cn(t, e, n, r) {
  return Yue(t, e, n, r, !1);
}
var Fct = uo(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = cn.apply(null, arguments);
    return this.isValid() && t.isValid() ? t < this ? this : t : lD();
  }
), Yct = uo(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = cn.apply(null, arguments);
    return this.isValid() && t.isValid() ? t > this ? this : t : lD();
  }
);
function Wue(t, e) {
  var n, r;
  if (e.length === 1 && Zo(e[0]) && (e = e[0]), !e.length)
    return cn();
  for (n = e[0], r = 1; r < e.length; ++r)
    (!e[r].isValid() || e[r][t](n)) && (n = e[r]);
  return n;
}
function Wct() {
  var t = [].slice.call(arguments, 0);
  return Wue("isBefore", t);
}
function Bct() {
  var t = [].slice.call(arguments, 0);
  return Wue("isAfter", t);
}
var qct = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, rv = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function Hct(t) {
  var e, n = !1, r, s = rv.length;
  for (e in t)
    if (_t(t, e) && !(tr.call(rv, e) !== -1 && (t[e] == null || !isNaN(t[e]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (t[rv[r]]) {
      if (n)
        return !1;
      parseFloat(t[rv[r]]) !== je(t[rv[r]]) && (n = !0);
    }
  return !0;
}
function jct() {
  return this._isValid;
}
function Vct() {
  return ca(NaN);
}
function gD(t) {
  var e = eV(t), n = e.year || 0, r = e.quarter || 0, s = e.month || 0, i = e.week || e.isoWeek || 0, o = e.day || 0, a = e.hour || 0, c = e.minute || 0, l = e.second || 0, h = e.millisecond || 0;
  this._isValid = Hct(e), this._milliseconds = +h + l * 1e3 + // 1000
  c * 6e4 + // 1000 * 60
  a * 1e3 * 60 * 60, this._days = +o + i * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = zl(), this._bubble();
}
function M0(t) {
  return t instanceof gD;
}
function YF(t) {
  return t < 0 ? Math.round(-1 * t) * -1 : Math.round(t);
}
function zct(t, e, n) {
  var r = Math.min(t.length, e.length), s = Math.abs(t.length - e.length), i = 0, o;
  for (o = 0; o < r; o++)
    je(t[o]) !== je(e[o]) && i++;
  return i + s;
}
function Bue(t, e) {
  te(t, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + Ya(~~(n / 60), 2) + e + Ya(~~n % 60, 2);
  });
}
Bue("Z", ":");
Bue("ZZ", "");
q("Z", pD);
q("ZZ", pD);
Wt(["Z", "ZZ"], function(t, e, n) {
  n._useUTC = !0, n._tzm = dV(pD, t);
});
var Gct = /([\+\-]|\d\d)/gi;
function dV(t, e) {
  var n = (e || "").match(t), r, s, i;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(Gct) || ["-", 0, 0], i = +(s[1] * 60) + je(s[2]), i === 0 ? 0 : s[0] === "+" ? i : -i);
}
function fV(t, e) {
  var n, r;
  return e._isUTC ? (n = e.clone(), r = (Xo(t) || Xb(t) ? t.valueOf() : cn(t).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), L.updateOffset(n, !1), n) : cn(t).local();
}
function WF(t) {
  return -Math.round(t._d.getTimezoneOffset());
}
L.updateOffset = function() {
};
function $ct(t, e, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    if (typeof t == "string") {
      if (t = dV(pD, t), t === null)
        return this;
    } else Math.abs(t) < 16 && !n && (t = t * 60);
    return !this._isUTC && e && (s = WF(this)), this._offset = t, this._isUTC = !0, s != null && this.add(s, "m"), r !== t && (!e || this._changeInProgress ? jue(
      this,
      ca(t - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, L.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : WF(this);
}
function Kct(t, e) {
  return t != null ? (typeof t != "string" && (t = -t), this.utcOffset(t, e), this) : -this.utcOffset();
}
function Jct(t) {
  return this.utcOffset(0, t);
}
function Qct(t) {
  return this._isUTC && (this.utcOffset(0, t), this._isUTC = !1, t && this.subtract(WF(this), "m")), this;
}
function Zct() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var t = dV(gat, this._i);
    t != null ? this.utcOffset(t) : this.utcOffset(0, !0);
  }
  return this;
}
function Xct(t) {
  return this.isValid() ? (t = t ? cn(t).utcOffset() : 0, (this.utcOffset() - t) % 60 === 0) : !1;
}
function elt() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function tlt() {
  if (!ni(this._isDSTShifted))
    return this._isDSTShifted;
  var t = {}, e;
  return Qj(t, this), t = Fue(t), t._a ? (e = t._isUTC ? ec(t._a) : cn(t._a), this._isDSTShifted = this.isValid() && zct(t._a, e.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function nlt() {
  return this.isValid() ? !this._isUTC : !1;
}
function rlt() {
  return this.isValid() ? this._isUTC : !1;
}
function que() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var slt = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, ilt = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function ca(t, e) {
  var n = t, r = null, s, i, o;
  return M0(t) ? n = {
    ms: t._milliseconds,
    d: t._days,
    M: t._months
  } : Sl(t) || !isNaN(+t) ? (n = {}, e ? n[e] = +t : n.milliseconds = +t) : (r = slt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: je(r[wa]) * s,
    h: je(r[Cr]) * s,
    m: je(r[Uo]) * s,
    s: je(r[xc]) * s,
    ms: je(YF(r[jd] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = ilt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: _d(r[2], s),
    M: _d(r[3], s),
    w: _d(r[4], s),
    d: _d(r[5], s),
    h: _d(r[6], s),
    m: _d(r[7], s),
    s: _d(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (o = olt(
    cn(n.from),
    cn(n.to)
  ), n = {}, n.ms = o.milliseconds, n.M = o.months), i = new gD(n), M0(t) && _t(t, "_locale") && (i._locale = t._locale), M0(t) && _t(t, "_isValid") && (i._isValid = t._isValid), i;
}
ca.fn = gD.prototype;
ca.invalid = Vct;
function _d(t, e) {
  var n = t && parseFloat(t.replace(",", "."));
  return (isNaN(n) ? 0 : n) * e;
}
function u8(t, e) {
  var n = {};
  return n.months = e.month() - t.month() + (e.year() - t.year()) * 12, t.clone().add(n.months, "M").isAfter(e) && --n.months, n.milliseconds = +e - +t.clone().add(n.months, "M"), n;
}
function olt(t, e) {
  var n;
  return t.isValid() && e.isValid() ? (e = fV(e, t), t.isBefore(e) ? n = u8(t, e) : (n = u8(e, t), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function Hue(t, e) {
  return function(n, r) {
    var s, i;
    return r !== null && !isNaN(+r) && (yue(
      e,
      "moment()." + e + "(period, number) is deprecated. Please use moment()." + e + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), i = n, n = r, r = i), s = ca(n, r), jue(this, s, t), this;
  };
}
function jue(t, e, n, r) {
  var s = e._milliseconds, i = YF(e._days), o = YF(e._months);
  t.isValid() && (r = r ?? !0, o && kue(t, iT(t, "Month") + o * n), i && Sue(t, "Date", iT(t, "Date") + i * n), s && t._d.setTime(t._d.valueOf() + s * n), r && L.updateOffset(t, i || o));
}
var alt = Hue(1, "add"), clt = Hue(-1, "subtract");
function Vue(t) {
  return typeof t == "string" || t instanceof String;
}
function llt(t) {
  return Xo(t) || Xb(t) || Vue(t) || Sl(t) || hlt(t) || ult(t) || t === null || t === void 0;
}
function ult(t) {
  var e = hf(t) && !Kj(t), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, i, o = r.length;
  for (s = 0; s < o; s += 1)
    i = r[s], n = n || _t(t, i);
  return e && n;
}
function hlt(t) {
  var e = Zo(t), n = !1;
  return e && (n = t.filter(function(r) {
    return !Sl(r) && Vue(t);
  }).length === 0), e && n;
}
function dlt(t) {
  var e = hf(t) && !Kj(t), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, i;
  for (s = 0; s < r.length; s += 1)
    i = r[s], n = n || _t(t, i);
  return e && n;
}
function flt(t, e) {
  var n = t.diff(e, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function plt(t, e) {
  arguments.length === 1 && (arguments[0] ? llt(arguments[0]) ? (t = arguments[0], e = void 0) : dlt(arguments[0]) && (e = arguments[0], t = void 0) : (t = void 0, e = void 0));
  var n = t || cn(), r = fV(n, this).startOf("day"), s = L.calendarFormat(this, r) || "sameElse", i = e && (tc(e[s]) ? e[s].call(this, n) : e[s]);
  return this.format(
    i || this.localeData().calendar(s, this, cn(n))
  );
}
function _lt() {
  return new eE(this);
}
function mlt(t, e) {
  var n = Xo(t) ? t : cn(t);
  return this.isValid() && n.isValid() ? (e = ho(e) || "millisecond", e === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(e).valueOf()) : !1;
}
function glt(t, e) {
  var n = Xo(t) ? t : cn(t);
  return this.isValid() && n.isValid() ? (e = ho(e) || "millisecond", e === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(e).valueOf() < n.valueOf()) : !1;
}
function ylt(t, e, n, r) {
  var s = Xo(t) ? t : cn(t), i = Xo(e) ? e : cn(e);
  return this.isValid() && s.isValid() && i.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(i, n) : !this.isAfter(i, n))) : !1;
}
function vlt(t, e) {
  var n = Xo(t) ? t : cn(t), r;
  return this.isValid() && n.isValid() ? (e = ho(e) || "millisecond", e === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(e).valueOf() <= r && r <= this.clone().endOf(e).valueOf())) : !1;
}
function wlt(t, e) {
  return this.isSame(t, e) || this.isAfter(t, e);
}
function Clt(t, e) {
  return this.isSame(t, e) || this.isBefore(t, e);
}
function Tlt(t, e, n) {
  var r, s, i;
  if (!this.isValid())
    return NaN;
  if (r = fV(t, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, e = ho(e), e) {
    case "year":
      i = x0(this, r) / 12;
      break;
    case "month":
      i = x0(this, r);
      break;
    case "quarter":
      i = x0(this, r) / 3;
      break;
    case "second":
      i = (this - r) / 1e3;
      break;
    case "minute":
      i = (this - r) / 6e4;
      break;
    case "hour":
      i = (this - r) / 36e5;
      break;
    case "day":
      i = (this - r - s) / 864e5;
      break;
    case "week":
      i = (this - r - s) / 6048e5;
      break;
    default:
      i = this - r;
  }
  return n ? i : Bi(i);
}
function x0(t, e) {
  if (t.date() < e.date())
    return -x0(e, t);
  var n = (e.year() - t.year()) * 12 + (e.month() - t.month()), r = t.clone().add(n, "months"), s, i;
  return e - r < 0 ? (s = t.clone().add(n - 1, "months"), i = (e - r) / (r - s)) : (s = t.clone().add(n + 1, "months"), i = (e - r) / (s - r)), -(n + i) || 0;
}
L.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
L.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function blt() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function Elt(t) {
  if (!this.isValid())
    return null;
  var e = t !== !0, n = e ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? A0(
    n,
    e ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : tc(Date.prototype.toISOString) ? e ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", A0(n, "Z")) : A0(
    n,
    e ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function Slt() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var t = "moment", e = "", n, r, s, i;
  return this.isLocal() || (t = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", e = "Z"), n = "[" + t + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", i = e + '[")]', this.format(n + r + s + i);
}
function Ilt(t) {
  t || (t = this.isUtc() ? L.defaultFormatUtc : L.defaultFormat);
  var e = A0(this, t);
  return this.localeData().postformat(e);
}
function Rlt(t, e) {
  return this.isValid() && (Xo(t) && t.isValid() || cn(t).isValid()) ? ca({ to: this, from: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function klt(t) {
  return this.from(cn(), t);
}
function Nlt(t, e) {
  return this.isValid() && (Xo(t) && t.isValid() || cn(t).isValid()) ? ca({ from: this, to: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function Plt(t) {
  return this.to(cn(), t);
}
function zue(t) {
  var e;
  return t === void 0 ? this._locale._abbr : (e = zl(t), e != null && (this._locale = e), this);
}
var Gue = uo(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(t) {
    return t === void 0 ? this.localeData() : this.locale(t);
  }
);
function $ue() {
  return this._locale;
}
var Qk = 1e3, j_ = 60 * Qk, Zk = 60 * j_, Kue = (365 * 400 + 97) * 24 * Zk;
function V_(t, e) {
  return (t % e + e) % e;
}
function Jue(t, e, n) {
  return t < 100 && t >= 0 ? new Date(t + 400, e, n) - Kue : new Date(t, e, n).valueOf();
}
function Que(t, e, n) {
  return t < 100 && t >= 0 ? Date.UTC(t + 400, e, n) - Kue : Date.UTC(t, e, n);
}
function Olt(t) {
  var e, n;
  if (t = ho(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? Que : Jue, t) {
    case "year":
      e = n(this.year(), 0, 1);
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      e = n(this.year(), this.month(), 1);
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      e = this._d.valueOf(), e -= V_(
        e + (this._isUTC ? 0 : this.utcOffset() * j_),
        Zk
      );
      break;
    case "minute":
      e = this._d.valueOf(), e -= V_(e, j_);
      break;
    case "second":
      e = this._d.valueOf(), e -= V_(e, Qk);
      break;
  }
  return this._d.setTime(e), L.updateOffset(this, !0), this;
}
function Dlt(t) {
  var e, n;
  if (t = ho(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? Que : Jue, t) {
    case "year":
      e = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      e = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      e = this._d.valueOf(), e += Zk - V_(
        e + (this._isUTC ? 0 : this.utcOffset() * j_),
        Zk
      ) - 1;
      break;
    case "minute":
      e = this._d.valueOf(), e += j_ - V_(e, j_) - 1;
      break;
    case "second":
      e = this._d.valueOf(), e += Qk - V_(e, Qk) - 1;
      break;
  }
  return this._d.setTime(e), L.updateOffset(this, !0), this;
}
function Alt() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function Mlt() {
  return Math.floor(this.valueOf() / 1e3);
}
function xlt() {
  return new Date(this.valueOf());
}
function Llt() {
  var t = this;
  return [
    t.year(),
    t.month(),
    t.date(),
    t.hour(),
    t.minute(),
    t.second(),
    t.millisecond()
  ];
}
function Ult() {
  var t = this;
  return {
    years: t.year(),
    months: t.month(),
    date: t.date(),
    hours: t.hours(),
    minutes: t.minutes(),
    seconds: t.seconds(),
    milliseconds: t.milliseconds()
  };
}
function Flt() {
  return this.isValid() ? this.toISOString() : null;
}
function Ylt() {
  return Jj(this);
}
function Wlt() {
  return Ou({}, me(this));
}
function Blt() {
  return me(this).overflow;
}
function qlt() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
te("N", 0, 0, "eraAbbr");
te("NN", 0, 0, "eraAbbr");
te("NNN", 0, 0, "eraAbbr");
te("NNNN", 0, 0, "eraName");
te("NNNNN", 0, 0, "eraNarrow");
te("y", ["y", 1], "yo", "eraYear");
te("y", ["yy", 2], 0, "eraYear");
te("y", ["yyy", 3], 0, "eraYear");
te("y", ["yyyy", 4], 0, "eraYear");
q("N", pV);
q("NN", pV);
q("NNN", pV);
q("NNNN", Xlt);
q("NNNNN", eut);
Wt(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(t, e, n, r) {
    var s = n._locale.erasParse(t, r, n._strict);
    s ? me(n).era = s : me(n).invalidEra = t;
  }
);
q("y", Og);
q("yy", Og);
q("yyy", Og);
q("yyyy", Og);
q("yo", tut);
Wt(["y", "yy", "yyy", "yyyy"], ps);
Wt(["yo"], function(t, e, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = t.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? e[ps] = n._locale.eraYearOrdinalParse(t, s) : e[ps] = parseInt(t, 10);
});
function Hlt(t, e) {
  var n, r, s, i = this._eras || zl("en")._eras;
  for (n = 0, r = i.length; n < r; ++n) {
    switch (typeof i[n].since) {
      case "string":
        s = L(i[n].since).startOf("day"), i[n].since = s.valueOf();
        break;
    }
    switch (typeof i[n].until) {
      case "undefined":
        i[n].until = 1 / 0;
        break;
      case "string":
        s = L(i[n].until).startOf("day").valueOf(), i[n].until = s.valueOf();
        break;
    }
  }
  return i;
}
function jlt(t, e, n) {
  var r, s, i = this.eras(), o, a, c;
  for (t = t.toUpperCase(), r = 0, s = i.length; r < s; ++r)
    if (o = i[r].name.toUpperCase(), a = i[r].abbr.toUpperCase(), c = i[r].narrow.toUpperCase(), n)
      switch (e) {
        case "N":
        case "NN":
        case "NNN":
          if (a === t)
            return i[r];
          break;
        case "NNNN":
          if (o === t)
            return i[r];
          break;
        case "NNNNN":
          if (c === t)
            return i[r];
          break;
      }
    else if ([o, a, c].indexOf(t) >= 0)
      return i[r];
}
function Vlt(t, e) {
  var n = t.since <= t.until ? 1 : -1;
  return e === void 0 ? L(t.since).year() : L(t.since).year() + (e - t.offset) * n;
}
function zlt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].name;
  return "";
}
function Glt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].narrow;
  return "";
}
function $lt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].abbr;
  return "";
}
function Klt() {
  var t, e, n, r, s = this.localeData().eras();
  for (t = 0, e = s.length; t < e; ++t)
    if (n = s[t].since <= s[t].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[t].since <= r && r <= s[t].until || s[t].until <= r && r <= s[t].since)
      return (this.year() - L(s[t].since).year()) * n + s[t].offset;
  return this.year();
}
function Jlt(t) {
  return _t(this, "_erasNameRegex") || _V.call(this), t ? this._erasNameRegex : this._erasRegex;
}
function Qlt(t) {
  return _t(this, "_erasAbbrRegex") || _V.call(this), t ? this._erasAbbrRegex : this._erasRegex;
}
function Zlt(t) {
  return _t(this, "_erasNarrowRegex") || _V.call(this), t ? this._erasNarrowRegex : this._erasRegex;
}
function pV(t, e) {
  return e.erasAbbrRegex(t);
}
function Xlt(t, e) {
  return e.erasNameRegex(t);
}
function eut(t, e) {
  return e.erasNarrowRegex(t);
}
function tut(t, e) {
  return e._eraYearOrdinalRegex || Og;
}
function _V() {
  var t = [], e = [], n = [], r = [], s, i, o, a, c, l = this.eras();
  for (s = 0, i = l.length; s < i; ++s)
    o = cl(l[s].name), a = cl(l[s].abbr), c = cl(l[s].narrow), e.push(o), t.push(a), n.push(c), r.push(o), r.push(a), r.push(c);
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
te(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
te(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function yD(t, e) {
  te(0, [t, t.length], 0, e);
}
yD("gggg", "weekYear");
yD("ggggg", "weekYear");
yD("GGGG", "isoWeekYear");
yD("GGGGG", "isoWeekYear");
q("G", fD);
q("g", fD);
q("GG", pn, Ni);
q("gg", pn, Ni);
q("GGGG", nV, tV);
q("gggg", nV, tV);
q("GGGGG", dD, uD);
q("ggggg", dD, uD);
nE(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(t, e, n, r) {
    e[r.substr(0, 2)] = je(t);
  }
);
nE(["gg", "GG"], function(t, e, n, r) {
  e[r] = L.parseTwoDigitYear(t);
});
function nut(t) {
  return Zue.call(
    this,
    t,
    this.week(),
    this.weekday() + this.localeData()._week.dow,
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function rut(t) {
  return Zue.call(
    this,
    t,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function sut() {
  return ll(this.year(), 1, 4);
}
function iut() {
  return ll(this.isoWeekYear(), 1, 4);
}
function out() {
  var t = this.localeData()._week;
  return ll(this.year(), t.dow, t.doy);
}
function aut() {
  var t = this.localeData()._week;
  return ll(this.weekYear(), t.dow, t.doy);
}
function Zue(t, e, n, r, s) {
  var i;
  return t == null ? aT(this, r, s).year : (i = ll(t, r, s), e > i && (e = i), cut.call(this, t, e, n, r, s));
}
function cut(t, e, n, r, s) {
  var i = Oue(t, e, n, r, s), o = oT(i.year, 0, i.dayOfYear);
  return this.year(o.getUTCFullYear()), this.month(o.getUTCMonth()), this.date(o.getUTCDate()), this;
}
te("Q", 0, "Qo", "quarter");
q("Q", wue);
Wt("Q", function(t, e) {
  e[Mc] = (je(t) - 1) * 3;
});
function lut(t) {
  return t == null ? Math.ceil((this.month() + 1) / 3) : this.month((t - 1) * 3 + this.month() % 3);
}
te("D", ["DD", 2], "Do", "date");
q("D", pn, Dg);
q("DD", pn, Ni);
q("Do", function(t, e) {
  return t ? e._dayOfMonthOrdinalParse || e._ordinalParse : e._dayOfMonthOrdinalParseLenient;
});
Wt(["D", "DD"], wa);
Wt("Do", function(t, e) {
  e[wa] = je(t.match(pn)[0]);
});
var Xue = Ag("Date", !0);
te("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
q("DDD", hD);
q("DDDD", Cue);
Wt(["DDD", "DDDD"], function(t, e, n) {
  n._dayOfYear = je(t);
});
function uut(t) {
  var e = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return t == null ? e : this.add(t - e, "d");
}
te("m", ["mm", 2], 0, "minute");
q("m", pn, rV);
q("mm", pn, Ni);
Wt(["m", "mm"], Uo);
var hut = Ag("Minutes", !1);
te("s", ["ss", 2], 0, "second");
q("s", pn, rV);
q("ss", pn, Ni);
Wt(["s", "ss"], xc);
var dut = Ag("Seconds", !1);
te("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
te(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
te(0, ["SSS", 3], 0, "millisecond");
te(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
te(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
te(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
te(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
te(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
te(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
q("S", hD, wue);
q("SS", hD, Ni);
q("SSS", hD, Cue);
var Du, ehe;
for (Du = "SSSS"; Du.length <= 9; Du += "S")
  q(Du, Og);
function fut(t, e) {
  e[jd] = je(("0." + t) * 1e3);
}
for (Du = "S"; Du.length <= 9; Du += "S")
  Wt(Du, fut);
ehe = Ag("Milliseconds", !1);
te("z", 0, 0, "zoneAbbr");
te("zz", 0, 0, "zoneName");
function put() {
  return this._isUTC ? "UTC" : "";
}
function _ut() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var b = eE.prototype;
b.add = alt;
b.calendar = plt;
b.clone = _lt;
b.diff = Tlt;
b.endOf = Dlt;
b.format = Ilt;
b.from = Rlt;
b.fromNow = klt;
b.to = Nlt;
b.toNow = Plt;
b.get = Sat;
b.invalidAt = Blt;
b.isAfter = mlt;
b.isBefore = glt;
b.isBetween = ylt;
b.isSame = vlt;
b.isSameOrAfter = wlt;
b.isSameOrBefore = Clt;
b.isValid = Ylt;
b.lang = Gue;
b.locale = zue;
b.localeData = $ue;
b.max = Yct;
b.min = Fct;
b.parsingFlags = Wlt;
b.set = Iat;
b.startOf = Olt;
b.subtract = clt;
b.toArray = Llt;
b.toObject = Ult;
b.toDate = xlt;
b.toISOString = Elt;
b.inspect = Slt;
typeof Symbol < "u" && Symbol.for != null && (b[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
b.toJSON = Flt;
b.toString = blt;
b.unix = Mlt;
b.valueOf = Alt;
b.creationData = qlt;
b.eraName = zlt;
b.eraNarrow = Glt;
b.eraAbbr = $lt;
b.eraYear = Klt;
b.year = Eue;
b.isLeapYear = Eat;
b.weekYear = nut;
b.isoWeekYear = rut;
b.quarter = b.quarters = lut;
b.month = Nue;
b.daysInMonth = xat;
b.week = b.weeks = Hat;
b.isoWeek = b.isoWeeks = jat;
b.weeksInYear = out;
b.weeksInWeekYear = aut;
b.isoWeeksInYear = sut;
b.isoWeeksInISOWeekYear = iut;
b.date = Xue;
b.day = b.days = rct;
b.weekday = sct;
b.isoWeekday = ict;
b.dayOfYear = uut;
b.hour = b.hours = dct;
b.minute = b.minutes = hut;
b.second = b.seconds = dut;
b.millisecond = b.milliseconds = ehe;
b.utcOffset = $ct;
b.utc = Jct;
b.local = Qct;
b.parseZone = Zct;
b.hasAlignedHourOffset = Xct;
b.isDST = elt;
b.isLocal = nlt;
b.isUtcOffset = rlt;
b.isUtc = que;
b.isUTC = que;
b.zoneAbbr = put;
b.zoneName = _ut;
b.dates = uo(
  "dates accessor is deprecated. Use date instead.",
  Xue
);
b.months = uo(
  "months accessor is deprecated. Use month instead",
  Nue
);
b.years = uo(
  "years accessor is deprecated. Use year instead",
  Eue
);
b.zone = uo(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  Kct
);
b.isDSTShifted = uo(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  tlt
);
function mut(t) {
  return cn(t * 1e3);
}
function gut() {
  return cn.apply(null, arguments).parseZone();
}
function the(t) {
  return t;
}
var Ct = Zj.prototype;
Ct.calendar = nat;
Ct.longDateFormat = oat;
Ct.invalidDate = cat;
Ct.ordinal = hat;
Ct.preparse = the;
Ct.postformat = the;
Ct.relativeTime = fat;
Ct.pastFuture = pat;
Ct.set = eat;
Ct.eras = Hlt;
Ct.erasParse = jlt;
Ct.erasConvertYear = Vlt;
Ct.erasAbbrRegex = Qlt;
Ct.erasNameRegex = Jlt;
Ct.erasNarrowRegex = Zlt;
Ct.months = Oat;
Ct.monthsShort = Dat;
Ct.monthsParse = Mat;
Ct.monthsRegex = Uat;
Ct.monthsShortRegex = Lat;
Ct.week = Yat;
Ct.firstDayOfYear = qat;
Ct.firstDayOfWeek = Bat;
Ct.weekdays = Zat;
Ct.weekdaysMin = ect;
Ct.weekdaysShort = Xat;
Ct.weekdaysParse = nct;
Ct.weekdaysRegex = oct;
Ct.weekdaysShortRegex = act;
Ct.weekdaysMinRegex = cct;
Ct.isPM = uct;
Ct.meridiem = fct;
function Xk(t, e, n, r) {
  var s = zl(), i = ec().set(r, e);
  return s[n](i, t);
}
function nhe(t, e, n) {
  if (Sl(t) && (e = t, t = void 0), t = t || "", e != null)
    return Xk(t, e, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = Xk(t, r, n, "month");
  return s;
}
function mV(t, e, n, r) {
  typeof t == "boolean" ? (Sl(e) && (n = e, e = void 0), e = e || "") : (e = t, n = e, t = !1, Sl(e) && (n = e, e = void 0), e = e || "");
  var s = zl(), i = t ? s._week.dow : 0, o, a = [];
  if (n != null)
    return Xk(e, (n + i) % 7, r, "day");
  for (o = 0; o < 7; o++)
    a[o] = Xk(e, (o + i) % 7, r, "day");
  return a;
}
function yut(t, e) {
  return nhe(t, e, "months");
}
function vut(t, e) {
  return nhe(t, e, "monthsShort");
}
function wut(t, e, n) {
  return mV(t, e, n, "weekdays");
}
function Cut(t, e, n) {
  return mV(t, e, n, "weekdaysShort");
}
function Tut(t, e, n) {
  return mV(t, e, n, "weekdaysMin");
}
_h("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(t) {
    var e = t % 10, n = je(t % 100 / 10) === 1 ? "th" : e === 1 ? "st" : e === 2 ? "nd" : e === 3 ? "rd" : "th";
    return t + n;
  }
});
L.lang = uo(
  "moment.lang is deprecated. Use moment.locale instead.",
  _h
);
L.langData = uo(
  "moment.langData is deprecated. Use moment.localeData instead.",
  zl
);
var hc = Math.abs;
function but() {
  var t = this._data;
  return this._milliseconds = hc(this._milliseconds), this._days = hc(this._days), this._months = hc(this._months), t.milliseconds = hc(t.milliseconds), t.seconds = hc(t.seconds), t.minutes = hc(t.minutes), t.hours = hc(t.hours), t.months = hc(t.months), t.years = hc(t.years), this;
}
function rhe(t, e, n, r) {
  var s = ca(e, n);
  return t._milliseconds += r * s._milliseconds, t._days += r * s._days, t._months += r * s._months, t._bubble();
}
function Eut(t, e) {
  return rhe(this, t, e, 1);
}
function Sut(t, e) {
  return rhe(this, t, e, -1);
}
function h8(t) {
  return t < 0 ? Math.floor(t) : Math.ceil(t);
}
function Iut() {
  var t = this._milliseconds, e = this._days, n = this._months, r = this._data, s, i, o, a, c;
  return t >= 0 && e >= 0 && n >= 0 || t <= 0 && e <= 0 && n <= 0 || (t += h8(BF(n) + e) * 864e5, e = 0, n = 0), r.milliseconds = t % 1e3, s = Bi(t / 1e3), r.seconds = s % 60, i = Bi(s / 60), r.minutes = i % 60, o = Bi(i / 60), r.hours = o % 24, e += Bi(o / 24), c = Bi(she(e)), n += c, e -= h8(BF(c)), a = Bi(n / 12), n %= 12, r.days = e, r.months = n, r.years = a, this;
}
function she(t) {
  return t * 4800 / 146097;
}
function BF(t) {
  return t * 146097 / 4800;
}
function Rut(t) {
  if (!this.isValid())
    return NaN;
  var e, n, r = this._milliseconds;
  if (t = ho(t), t === "month" || t === "quarter" || t === "year")
    switch (e = this._days + r / 864e5, n = this._months + she(e), t) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (e = this._days + Math.round(BF(this._months)), t) {
      case "week":
        return e / 7 + r / 6048e5;
      case "day":
        return e + r / 864e5;
      case "hour":
        return e * 24 + r / 36e5;
      case "minute":
        return e * 1440 + r / 6e4;
      case "second":
        return e * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(e * 864e5) + r;
      default:
        throw new Error("Unknown unit " + t);
    }
}
function Gl(t) {
  return function() {
    return this.as(t);
  };
}
var ihe = Gl("ms"), kut = Gl("s"), Nut = Gl("m"), Put = Gl("h"), Out = Gl("d"), Dut = Gl("w"), Aut = Gl("M"), Mut = Gl("Q"), xut = Gl("y"), Lut = ihe;
function Uut() {
  return ca(this);
}
function Fut(t) {
  return t = ho(t), this.isValid() ? this[t + "s"]() : NaN;
}
function Ep(t) {
  return function() {
    return this.isValid() ? this._data[t] : NaN;
  };
}
var Yut = Ep("milliseconds"), Wut = Ep("seconds"), But = Ep("minutes"), qut = Ep("hours"), Hut = Ep("days"), jut = Ep("months"), Vut = Ep("years");
function zut() {
  return Bi(this.days() / 7);
}
var gc = Math.round, h_ = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function Gut(t, e, n, r, s) {
  return s.relativeTime(e || 1, !!n, t, r);
}
function $ut(t, e, n, r) {
  var s = ca(t).abs(), i = gc(s.as("s")), o = gc(s.as("m")), a = gc(s.as("h")), c = gc(s.as("d")), l = gc(s.as("M")), h = gc(s.as("w")), u = gc(s.as("y")), d = i <= n.ss && ["s", i] || i < n.s && ["ss", i] || o <= 1 && ["m"] || o < n.m && ["mm", o] || a <= 1 && ["h"] || a < n.h && ["hh", a] || c <= 1 && ["d"] || c < n.d && ["dd", c];
  return n.w != null && (d = d || h <= 1 && ["w"] || h < n.w && ["ww", h]), d = d || l <= 1 && ["M"] || l < n.M && ["MM", l] || u <= 1 && ["y"] || ["yy", u], d[2] = e, d[3] = +t > 0, d[4] = r, Gut.apply(null, d);
}
function Kut(t) {
  return t === void 0 ? gc : typeof t == "function" ? (gc = t, !0) : !1;
}
function Jut(t, e) {
  return h_[t] === void 0 ? !1 : e === void 0 ? h_[t] : (h_[t] = e, t === "s" && (h_.ss = e - 1), !0);
}
function Qut(t, e) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = h_, s, i;
  return typeof t == "object" && (e = t, t = !1), typeof t == "boolean" && (n = t), typeof e == "object" && (r = Object.assign({}, h_, e), e.s != null && e.ss == null && (r.ss = e.s - 1)), s = this.localeData(), i = $ut(this, !n, r, s), n && (i = s.pastFuture(+this, i)), s.postformat(i);
}
var ex = Math.abs;
function Bp(t) {
  return (t > 0) - (t < 0) || +t;
}
function vD() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var t = ex(this._milliseconds) / 1e3, e = ex(this._days), n = ex(this._months), r, s, i, o, a = this.asSeconds(), c, l, h, u;
  return a ? (r = Bi(t / 60), s = Bi(r / 60), t %= 60, r %= 60, i = Bi(n / 12), n %= 12, o = t ? t.toFixed(3).replace(/\.?0+$/, "") : "", c = a < 0 ? "-" : "", l = Bp(this._months) !== Bp(a) ? "-" : "", h = Bp(this._days) !== Bp(a) ? "-" : "", u = Bp(this._milliseconds) !== Bp(a) ? "-" : "", c + "P" + (i ? l + i + "Y" : "") + (n ? l + n + "M" : "") + (e ? h + e + "D" : "") + (s || r || t ? "T" : "") + (s ? u + s + "H" : "") + (r ? u + r + "M" : "") + (t ? u + o + "S" : "")) : "P0D";
}
var Je = gD.prototype;
Je.isValid = jct;
Je.abs = but;
Je.add = Eut;
Je.subtract = Sut;
Je.as = Rut;
Je.asMilliseconds = ihe;
Je.asSeconds = kut;
Je.asMinutes = Nut;
Je.asHours = Put;
Je.asDays = Out;
Je.asWeeks = Dut;
Je.asMonths = Aut;
Je.asQuarters = Mut;
Je.asYears = xut;
Je.valueOf = Lut;
Je._bubble = Iut;
Je.clone = Uut;
Je.get = Fut;
Je.milliseconds = Yut;
Je.seconds = Wut;
Je.minutes = But;
Je.hours = qut;
Je.days = Hut;
Je.weeks = zut;
Je.months = jut;
Je.years = Vut;
Je.humanize = Qut;
Je.toISOString = vD;
Je.toString = vD;
Je.toJSON = vD;
Je.locale = zue;
Je.localeData = $ue;
Je.toIsoString = uo(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  vD
);
Je.lang = Gue;
te("X", 0, 0, "unix");
te("x", 0, 0, "valueOf");
q("x", fD);
q("X", yat);
Wt("X", function(t, e, n) {
  n._d = new Date(parseFloat(t) * 1e3);
});
Wt("x", function(t, e, n) {
  n._d = new Date(je(t));
});
L.version = "2.30.1";
Zot(cn);
L.fn = b;
L.min = Wct;
L.max = Bct;
L.now = qct;
L.utc = ec;
L.unix = mut;
L.months = yut;
L.isDate = Xb;
L.locale = _h;
L.invalid = lD;
L.duration = ca;
L.isMoment = Xo;
L.weekdays = wut;
L.parseZone = gut;
L.localeData = zl;
L.isDuration = M0;
L.monthsShort = vut;
L.weekdaysMin = Tut;
L.defineLocale = cV;
L.updateLocale = gct;
L.locales = yct;
L.weekdaysShort = Cut;
L.normalizeUnits = ho;
L.relativeTimeRounding = Kut;
L.relativeTimeThreshold = Jut;
L.calendarFormat = flt;
L.prototype = b;
L.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
function ohe(t, e) {
  var n = {};
  for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function ahe() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const Zut = ahe, che = new ab("auth", "Firebase", ahe()), eN = new YW("@firebase/auth");
function Xut(t, ...e) {
  eN.logLevel <= kt.WARN && eN.warn(`Auth (${cb}): ${t}`, ...e);
}
function L0(t, ...e) {
  eN.logLevel <= kt.ERROR && eN.error(`Auth (${cb}): ${t}`, ...e);
}
function lT(t, ...e) {
  throw yV(t, ...e);
}
function gV(t, ...e) {
  return yV(t, ...e);
}
function eht(t, e, n) {
  const r = Object.assign(Object.assign({}, Zut()), { [e]: n });
  return new ab("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function yV(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return che.create(t, ...e);
}
function nt(t, e, ...n) {
  if (!t)
    throw yV(e, ...n);
}
function Lc(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw L0(e), new Error(e);
}
function tN(t, e) {
  t || Lc(e);
}
function tht() {
  return d8() === "http:" || d8() === "https:";
}
function d8() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
function nht() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (tht() || YAe() || "connection" in navigator) ? navigator.onLine : !0;
}
function rht() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
class rE {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, tN(n > e, "Short delay should be less than long delay!"), this.isMobile = UW() || Zee();
  }
  get() {
    return nht() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
function sht(t, e) {
  tN(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
class lhe {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    Lc("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    Lc("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    Lc("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
const iht = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
}, oht = new rE(3e4, 6e4);
function sE(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Sp(t, e, n, r, s = {}) {
  return uhe(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = FW(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), lhe.fetch()(hhe(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function uhe(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, iht), e);
  try {
    const s = new aht(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw vS(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, l] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw vS(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw vS(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw vS(t, "user-disabled", o);
      const h = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (l)
        throw eht(t, h, l);
      lT(t, h);
    }
  } catch (s) {
    if (s instanceof xl)
      throw s;
    lT(t, "network-request-failed", { message: String(s) });
  }
}
async function vV(t, e, n, r, s = {}) {
  const i = await Sp(t, e, n, r, s);
  return "mfaPendingCredential" in i && lT(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function hhe(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? sht(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class aht {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(gV(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), oht.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function vS(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = gV(t, e, r);
  return s.customData._tokenResponse = n, s;
}
async function cht(t, e) {
  return Sp(t, "POST", "/v1/accounts:delete", e);
}
async function lht(t, e) {
  return Sp(t, "POST", "/v1/accounts:lookup", e);
}
function Cw(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function uht(t, e = !1) {
  const n = ls(t), r = await n.getIdToken(e), s = dhe(r);
  nt(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: Cw(tx(s.auth_time)),
    issuedAtTime: Cw(tx(s.iat)),
    expirationTime: Cw(tx(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function tx(t) {
  return Number(t) * 1e3;
}
function dhe(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return L0("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = fR(n);
    return s ? JSON.parse(s) : (L0("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return L0("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function hht(t) {
  const e = dhe(t);
  return nt(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), nt(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), nt(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
async function nN(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof xl && dht(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function dht({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
class fht {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const r = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, r);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
class fhe {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = Cw(this.lastLoginAt), this.creationTime = Cw(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
async function rN(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await nN(t, lht(n, { idToken: r }));
  nt(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? mht(i.providerUserInfo) : [], a = _ht(t.providerData, o), c = t.isAnonymous, l = !(t.email && i.passwordHash) && !(a != null && a.length), h = c ? l : !1, u = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new fhe(i.createdAt, i.lastLoginAt),
    isAnonymous: h
  };
  Object.assign(t, u);
}
async function pht(t) {
  const e = ls(t);
  await rN(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function _ht(t, e) {
  return [...t.filter((n) => !e.some((r) => r.providerId === n.providerId)), ...e];
}
function mht(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = ohe(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
async function ght(t, e) {
  const n = await uhe(t, {}, async () => {
    const r = FW({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = hhe(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", lhe.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
class uT {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    nt(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), nt(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), nt(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : hht(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return nt(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await ght(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new uT();
    return r && (nt(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (nt(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (nt(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new uT(), this.toJSON());
  }
  _performRefresh() {
    return Lc("not implemented");
  }
}
function lu(t, e) {
  nt(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class df {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = ohe(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new fht(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new fhe(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await nN(this, this.stsTokenManager.getToken(this.auth, e));
    return nt(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return uht(this, e);
  }
  reload() {
    return pht(this);
  }
  _assign(e) {
    this !== e && (nt(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new df(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    nt(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await rN(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await nN(this, cht(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, l, h;
    const u = (r = n.displayName) !== null && r !== void 0 ? r : void 0, d = (s = n.email) !== null && s !== void 0 ? s : void 0, f = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, p = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, _ = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, m = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, v = (l = n.createdAt) !== null && l !== void 0 ? l : void 0, y = (h = n.lastLoginAt) !== null && h !== void 0 ? h : void 0, { uid: g, emailVerified: w, isAnonymous: de, providerData: we, stsTokenManager: fe } = n;
    nt(
      g && fe,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const ys = uT.fromJSON(this.name, fe);
    nt(
      typeof g == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), lu(u, e.name), lu(d, e.name), nt(
      typeof w == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), nt(
      typeof de == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), lu(f, e.name), lu(p, e.name), lu(_, e.name), lu(m, e.name), lu(v, e.name), lu(y, e.name);
    const En = new df({
      uid: g,
      auth: e,
      email: d,
      emailVerified: w,
      displayName: u,
      isAnonymous: de,
      photoURL: p,
      phoneNumber: f,
      tenantId: _,
      stsTokenManager: ys,
      createdAt: v,
      lastLoginAt: y
    });
    return we && Array.isArray(we) && (En.providerData = we.map((vo) => Object.assign({}, vo))), m && (En._redirectEventId = m), En;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new uT();
    s.updateFromServerResponse(n);
    const i = new df({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await rN(i), i;
  }
}
const f8 = /* @__PURE__ */ new Map();
function Vd(t) {
  tN(t instanceof Function, "Expected a class definition");
  let e = f8.get(t);
  return e ? (tN(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), f8.set(t, e), e);
}
class phe {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
phe.type = "NONE";
const p8 = phe;
function nx(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class z_ {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = nx(this.userKey, s.apiKey, i), this.fullPersistenceKey = nx("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? df._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new z_(Vd(p8), e, r);
    const s = (await Promise.all(n.map(async (l) => {
      if (await l._isAvailable())
        return l;
    }))).filter((l) => l);
    let i = s[0] || Vd(p8);
    const o = nx(r, e.config.apiKey, e.name);
    let a = null;
    for (const l of n)
      try {
        const h = await l._get(o);
        if (h) {
          const u = df._fromJSON(e, h);
          l !== i && (a = u), i = l;
          break;
        }
      } catch {
      }
    const c = s.filter((l) => l._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new z_(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (l) => {
      if (l !== i)
        try {
          await l._remove(o);
        } catch {
        }
    })), new z_(i, e, r));
  }
}
function _8(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (Cht(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (yht(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (bht(e))
    return "Blackberry";
  if (Eht(e))
    return "Webos";
  if (vht(e))
    return "Safari";
  if ((e.includes("chrome/") || wht(e)) && !e.includes("edge/"))
    return "Chrome";
  if (Tht(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function yht(t = Ma()) {
  return /firefox\//i.test(t);
}
function vht(t = Ma()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function wht(t = Ma()) {
  return /crios\//i.test(t);
}
function Cht(t = Ma()) {
  return /iemobile/i.test(t);
}
function Tht(t = Ma()) {
  return /android/i.test(t);
}
function bht(t = Ma()) {
  return /blackberry/i.test(t);
}
function Eht(t = Ma()) {
  return /webos/i.test(t);
}
function _he(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = _8(Ma());
      break;
    case "Worker":
      n = `${_8(Ma())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${cb}/${r}`;
}
async function mhe(t, e) {
  return Sp(t, "GET", "/v2/recaptchaConfig", sE(t, e));
}
function m8(t) {
  return t !== void 0 && t.enterprise !== void 0;
}
class ghe {
  constructor(e) {
    if (this.siteKey = "", this.emailPasswordEnabled = !1, e.recaptchaKey === void 0)
      throw new Error("recaptchaKey undefined");
    this.siteKey = e.recaptchaKey.split("/")[3], this.emailPasswordEnabled = e.recaptchaEnforcementState.some((n) => n.provider === "EMAIL_PASSWORD_PROVIDER" && n.enforcementState !== "OFF");
  }
}
function Sht() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function Iht(t) {
  return new Promise((e, n) => {
    const r = document.createElement("script");
    r.setAttribute("src", t), r.onload = e, r.onerror = (s) => {
      const i = gV(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = s, n(i);
    }, r.type = "text/javascript", r.charset = "UTF-8", Sht().appendChild(r);
  });
}
const Rht = "https://www.google.com/recaptcha/enterprise.js?render=", kht = "recaptcha-enterprise";
class yhe {
  /**
   *
   * @param authExtern - The corresponding Firebase {@link Auth} instance.
   *
   */
  constructor(e) {
    this.type = kht, this.auth = wD(e);
  }
  /**
   * Executes the verification process.
   *
   * @returns A Promise for a token that can be used to assert the validity of a request.
   */
  async verify(e = "verify", n = !1) {
    async function r(i) {
      if (!n) {
        if (i.tenantId == null && i._agentRecaptchaConfig != null)
          return i._agentRecaptchaConfig.siteKey;
        if (i.tenantId != null && i._tenantRecaptchaConfigs[i.tenantId] !== void 0)
          return i._tenantRecaptchaConfigs[i.tenantId].siteKey;
      }
      return new Promise(async (o, a) => {
        mhe(i, {
          clientType: "CLIENT_TYPE_WEB",
          version: "RECAPTCHA_ENTERPRISE"
          /* RecaptchaVersion.ENTERPRISE */
        }).then((c) => {
          if (c.recaptchaKey === void 0)
            a(new Error("recaptcha Enterprise site key undefined"));
          else {
            const l = new ghe(c);
            return i.tenantId == null ? i._agentRecaptchaConfig = l : i._tenantRecaptchaConfigs[i.tenantId] = l, o(l.siteKey);
          }
        }).catch((c) => {
          a(c);
        });
      });
    }
    function s(i, o, a) {
      const c = window.grecaptcha;
      m8(c) ? c.enterprise.ready(() => {
        try {
          c.enterprise.execute(i, { action: e }).then((l) => {
            o(l);
          }).catch((l) => {
            a(l);
          });
        } catch (l) {
          a(l);
        }
      }) : a(Error("No reCAPTCHA enterprise script loaded."));
    }
    return new Promise((i, o) => {
      r(this.auth).then((a) => {
        if (!n && m8(window.grecaptcha))
          s(a, i, o);
        else {
          if (typeof window > "u") {
            o(new Error("RecaptchaVerifier is only supported in browser"));
            return;
          }
          Iht(Rht + a).then(() => {
            s(a, i, o);
          }).catch((c) => {
            o(c);
          });
        }
      }).catch((a) => {
        o(a);
      });
    });
  }
}
async function sN(t, e, n, r = !1) {
  const s = new yhe(t);
  let i;
  try {
    i = await s.verify(n);
  } catch {
    i = await s.verify(n, !0);
  }
  const o = Object.assign({}, e);
  return r ? Object.assign(o, { captchaResp: i }) : Object.assign(o, { captchaResponse: i }), Object.assign(o, {
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  }), Object.assign(o, {
    recaptchaVersion: "RECAPTCHA_ENTERPRISE"
    /* RecaptchaVersion.ENTERPRISE */
  }), o;
}
class Nht {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
class Pht {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new g8(this), this.idTokenSubscription = new g8(this), this.beforeStateQueue = new Nht(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = che, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = Vd(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await z_.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && c != null && c.user && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return nt(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await rN(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = rht();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? ls(e) : null;
    return n && nt(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && nt(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(Vd(e));
    });
  }
  async initializeRecaptchaConfig() {
    const e = await mhe(this, {
      clientType: "CLIENT_TYPE_WEB",
      version: "RECAPTCHA_ENTERPRISE"
      /* RecaptchaVersion.ENTERPRISE */
    }), n = new ghe(e);
    this.tenantId == null ? this._agentRecaptchaConfig = n : this._tenantRecaptchaConfigs[this.tenantId] = n, n.emailPasswordEnabled && new yhe(this).verify();
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new ab("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && Vd(e) || this._popupRedirectResolver;
      nt(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await z_.create(
        this,
        [Vd(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return nt(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, r, s) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return nt(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = _he(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && Xut(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function wD(t) {
  return ls(t);
}
class g8 {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = $Ae((n) => this.observer = n);
  }
  get next() {
    return nt(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function Oht(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(Vd);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
class Dht {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return Lc("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return Lc("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return Lc("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return Lc("not implemented");
  }
}
async function Aht(t, e) {
  return Sp(t, "POST", "/v1/accounts:update", e);
}
async function rx(t, e) {
  return vV(t, "POST", "/v1/accounts:signInWithPassword", sE(t, e));
}
async function Mht(t, e) {
  return Sp(t, "POST", "/v1/accounts:sendOobCode", sE(t, e));
}
async function sx(t, e) {
  return Mht(t, e);
}
async function xht(t, e) {
  return vV(t, "POST", "/v1/accounts:signInWithEmailLink", sE(t, e));
}
async function Lht(t, e) {
  return vV(t, "POST", "/v1/accounts:signInWithEmailLink", sE(t, e));
}
class hT extends Dht {
  /** @internal */
  constructor(e, n, r, s = null) {
    super("password", r), this._email = e, this._password = n, this._tenantId = s;
  }
  /** @internal */
  static _fromEmailAndPassword(e, n) {
    return new hT(
      e,
      n,
      "password"
      /* SignInMethod.EMAIL_PASSWORD */
    );
  }
  /** @internal */
  static _fromEmailAndCode(e, n, r = null) {
    return new hT(e, n, "emailLink", r);
  }
  /** {@inheritdoc AuthCredential.toJSON} */
  toJSON() {
    return {
      email: this._email,
      password: this._password,
      signInMethod: this.signInMethod,
      tenantId: this._tenantId
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an {@link  AuthCredential}.
   *
   * @param json - Either `object` or the stringified representation of the object. When string is
   * provided, `JSON.parse` would be called first.
   *
   * @returns If the JSON input does not represent an {@link AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e;
    if (n != null && n.email && n != null && n.password) {
      if (n.signInMethod === "password")
        return this._fromEmailAndPassword(n.email, n.password);
      if (n.signInMethod === "emailLink")
        return this._fromEmailAndCode(n.email, n.password, n.tenantId);
    }
    return null;
  }
  /** @internal */
  async _getIdTokenResponse(e) {
    var n;
    switch (this.signInMethod) {
      case "password":
        const r = {
          returnSecureToken: !0,
          email: this._email,
          password: this._password,
          clientType: "CLIENT_TYPE_WEB"
          /* RecaptchaClientType.WEB */
        };
        if (!((n = e._getRecaptchaConfig()) === null || n === void 0) && n.emailPasswordEnabled) {
          const s = await sN(
            e,
            r,
            "signInWithPassword"
            /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
          );
          return rx(e, s);
        } else
          return rx(e, r).catch(async (s) => {
            if (s.code === "auth/missing-recaptcha-token") {
              console.log("Sign-in with email address and password is protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the sign-in flow.");
              const i = await sN(
                e,
                r,
                "signInWithPassword"
                /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
              );
              return rx(e, i);
            } else
              return Promise.reject(s);
          });
      case "emailLink":
        return xht(e, {
          email: this._email,
          oobCode: this._password
        });
      default:
        lT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  async _linkToIdToken(e, n) {
    switch (this.signInMethod) {
      case "password":
        return Aht(e, {
          idToken: n,
          returnSecureToken: !0,
          email: this._email,
          password: this._password
        });
      case "emailLink":
        return Lht(e, {
          idToken: n,
          email: this._email,
          oobCode: this._password
        });
      default:
        lT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return this._getIdTokenResponse(e);
  }
}
function Uht(t) {
  switch (t) {
    case "recoverEmail":
      return "RECOVER_EMAIL";
    case "resetPassword":
      return "PASSWORD_RESET";
    case "signIn":
      return "EMAIL_SIGNIN";
    case "verifyEmail":
      return "VERIFY_EMAIL";
    case "verifyAndChangeEmail":
      return "VERIFY_AND_CHANGE_EMAIL";
    case "revertSecondFactorAddition":
      return "REVERT_SECOND_FACTOR_ADDITION";
    default:
      return null;
  }
}
function Fht(t) {
  const e = wv(Cv(t)).link, n = e ? wv(Cv(e)).deep_link_id : null, r = wv(Cv(t)).deep_link_id;
  return (r ? wv(Cv(r)).link : null) || r || n || e || t;
}
class wV {
  /**
   * @param actionLink - The link from which to extract the URL.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @internal
   */
  constructor(e) {
    var n, r, s, i, o, a;
    const c = wv(Cv(e)), l = (n = c.apiKey) !== null && n !== void 0 ? n : null, h = (r = c.oobCode) !== null && r !== void 0 ? r : null, u = Uht((s = c.mode) !== null && s !== void 0 ? s : null);
    nt(
      l && h && u,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), this.apiKey = l, this.operation = u, this.code = h, this.continueUrl = (i = c.continueUrl) !== null && i !== void 0 ? i : null, this.languageCode = (o = c.languageCode) !== null && o !== void 0 ? o : null, this.tenantId = (a = c.tenantId) !== null && a !== void 0 ? a : null;
  }
  /**
   * Parses the email action link string and returns an {@link ActionCodeURL} if the link is valid,
   * otherwise returns null.
   *
   * @param link  - The email action link string.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @public
   */
  static parseLink(e) {
    const n = Fht(e);
    try {
      return new wV(n);
    } catch {
      return null;
    }
  }
}
class Mg {
  constructor() {
    this.providerId = Mg.PROVIDER_ID;
  }
  /**
   * Initialize an {@link AuthCredential} using an email and password.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credential(email, password);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * const userCredential = await signInWithEmailAndPassword(auth, email, password);
   * ```
   *
   * @param email - Email address.
   * @param password - User account password.
   * @returns The auth provider credential.
   */
  static credential(e, n) {
    return hT._fromEmailAndPassword(e, n);
  }
  /**
   * Initialize an {@link AuthCredential} using an email and an email link after a sign in with
   * email link operation.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credentialWithLink(auth, email, emailLink);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * await sendSignInLinkToEmail(auth, email);
   * // Obtain emailLink from user.
   * const userCredential = await signInWithEmailLink(auth, email, emailLink);
   * ```
   *
   * @param auth - The {@link Auth} instance used to verify the link.
   * @param email - Email address.
   * @param emailLink - Sign-in email link.
   * @returns - The auth provider credential.
   */
  static credentialWithLink(e, n) {
    const r = wV.parseLink(n);
    return nt(
      r,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), hT._fromEmailAndCode(e, r.code, r.tenantId);
  }
}
Mg.PROVIDER_ID = "password";
Mg.EMAIL_PASSWORD_SIGN_IN_METHOD = "password";
Mg.EMAIL_LINK_SIGN_IN_METHOD = "emailLink";
class iN {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, r, s = !1) {
    const i = await df._fromIdTokenResponse(e, r, s), o = y8(r);
    return new iN({
      user: i,
      providerId: o,
      _tokenResponse: r,
      operationType: n
    });
  }
  static async _forOperation(e, n, r) {
    await e._updateTokensIfNecessary(
      r,
      /* reload */
      !0
    );
    const s = y8(r);
    return new iN({
      user: e,
      providerId: s,
      _tokenResponse: r,
      operationType: n
    });
  }
}
function y8(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
class oN extends xl {
  constructor(e, n, r, s) {
    var i;
    super(n.code, n.message), this.operationType = r, this.user = s, Object.setPrototypeOf(this, oN.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: r
    };
  }
  static _fromErrorAndOperation(e, n, r, s) {
    return new oN(e, n, r, s);
  }
}
function Yht(t, e, n, r) {
  return n._getIdTokenResponse(t).catch((s) => {
    throw s.code === "auth/multi-factor-auth-required" ? oN._fromErrorAndOperation(t, s, e, r) : s;
  });
}
async function Wht(t, e, n = !1) {
  const r = "signIn", s = await Yht(t, r, e), i = await iN._fromIdTokenResponse(t, r, s);
  return n || await t._updateCurrentUser(i.user), i;
}
async function Bht(t, e) {
  return Wht(wD(t), e);
}
async function qht(t, e, n) {
  var r;
  const s = wD(t), i = {
    requestType: "PASSWORD_RESET",
    email: e,
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  };
  if (!((r = s._getRecaptchaConfig()) === null || r === void 0) && r.emailPasswordEnabled) {
    const o = await sN(s, i, "getOobCode", !0);
    await sx(s, o);
  } else
    await sx(s, i).catch(async (o) => {
      if (o.code === "auth/missing-recaptcha-token") {
        console.log("Password resets are protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the password reset flow.");
        const a = await sN(s, i, "getOobCode", !0);
        await sx(s, a);
      } else
        return Promise.reject(o);
    });
}
async function Hht(t, e) {
  return Sp(t, "POST", "/v1/accounts:update", e);
}
async function v8(t, { displayName: e, photoURL: n }) {
  if (e === void 0 && n === void 0)
    return;
  const r = ls(t), s = {
    idToken: await r.getIdToken(),
    displayName: e,
    photoUrl: n,
    returnSecureToken: !0
  }, i = await nN(r, Hht(r.auth, s));
  r.displayName = i.displayName || null, r.photoURL = i.photoUrl || null;
  const o = r.providerData.find(
    ({ providerId: a }) => a === "password"
    /* ProviderId.PASSWORD */
  );
  o && (o.displayName = r.displayName, o.photoURL = r.photoURL), await r._updateTokensIfNecessary(i);
}
new rE(3e4, 6e4);
new rE(2e3, 1e4);
new rE(3e4, 6e4);
new rE(5e3, 15e3);
var w8 = "@firebase/auth", C8 = "0.23.0";
class jht {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    nt(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
function Vht(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function zht(t) {
  Ff(new Oh(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      nt(o && !o.includes(":"), "invalid-api-key", { appName: r.name }), nt(!(a != null && a.includes(":")), "argument-error", {
        appName: r.name
      });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: _he(t)
      }, l = new Pht(r, s, i, c);
      return Oht(l, n), l;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), Ff(new Oh(
    "auth-internal",
    (e) => {
      const n = wD(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new jht(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), Zc(w8, C8, Vht(t)), Zc(w8, C8, "esm2017");
}
UAe("authIdTokenMaxAge");
zht(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const T8 = {
  PICTURE_STORAGE: "/users/{uid}/UserProfilePic.jpg",
  PICTURE_DATE: "/users/{uid}/profilePicDate"
  // YYYY-MM-DD HH:mm:ss
};
class sv {
  static PictureStorage(e) {
    return $(T8.PICTURE_STORAGE, { uid: e });
  }
  static PictureDate(e) {
    return $(T8.PICTURE_DATE, { uid: e });
  }
}
const { resizeImage: Ght } = Qot;
class $ht extends Mne {
  constructor(e) {
    super(), this.auth = e;
  }
  async uploadProfilePicture(e) {
    try {
      let n = await Ght({
        file: e,
        maxSize: 750
        //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
      }), r = { contentType: e.type };
      await this.uploadObject(sv.PictureStorage(this.uid), n.blob, r);
      let s = await this.objectUrl(sv.PictureStorage(this.uid));
      return console.log(`Profile photo updated: ${s}`), await this.updatePhoto(s), await this.updatePictureDate(), s;
    } catch (n) {
      throw console.error("Upload profile: " + n.message), n;
    }
  }
  async deleteProfilePicture() {
    try {
      const e = sv.PictureStorage(this.uid);
      await this.deleteObject(e), await this.updatePhoto(""), await this.updatePictureDate();
    } catch (e) {
      throw console.error("Delete profile: " + e.message), e;
    }
  }
  async updatePictureDate() {
    return await this.set(sv.PictureDate(this.uid), this.currentTime);
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
  get currentTime() {
    return L().format("YYYY-MM-DD HH:mm:ss");
  }
  async getPhotoUrl() {
    const e = sv.PictureStorage(this.uid);
    return await this.objectUrl(e);
  }
  // get profilePictureExists() {
  //     (async () => {
  //         try {
  //             const path = ProfileResources.PictureStorage(this.uid);
  //             await this.objectUrl(path)
  //             return true;
  //         } catch(e) {
  //             return false;
  //         }
  //         })();
  // }
  async sendPasswordResetEmail() {
    try {
      return await qht(this.auth, this.auth.currentUser.email);
    } catch (e) {
      throw console.error("Password reset: " + e.message), e;
    }
  }
  async updateEmail(e) {
    try {
      return await updateEmail(this.auth.currentUser, e);
    } catch (n) {
      throw console.error("Updating email address: " + n.message), n;
    }
  }
  async updateUserProfile(e) {
    try {
      return await v8(this.auth.currentUser, {
        displayName: e.trim()
      });
    } catch (n) {
      throw console.error("Updating profile name: " + n.message), n;
    }
  }
  async updatePhoto(e) {
    try {
      return await v8(this.auth.currentUser, {
        photoURL: e
      });
    } catch (n) {
      throw console.error("Updating profile photo: " + n.message), n;
    }
  }
  async reauthenticateWithCredential(e) {
    try {
      const n = Mg.credential(this.auth.currentUser.email, e);
      return await Bht(this.auth, n);
    } catch (n) {
      throw console.error("Verifying credentials: " + n.message), n;
    }
  }
}
var U0 = { exports: {} };
function Kht(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
U0.exports = Kht;
const Jht = (U0.exports == null ? {} : U0.exports).default || U0.exports;
var F0 = { exports: {} };
function Qht(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
F0.exports = Qht;
const Zht = (F0.exports == null ? {} : F0.exports).default || F0.exports;
var Y0 = { exports: {} };
const Xht = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
Y0.exports = Xht;
const edt = (Y0.exports == null ? {} : Y0.exports).default || Y0.exports;
var W0 = { exports: {} };
function tdt(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
W0.exports = tdt;
const ndt = (W0.exports == null ? {} : W0.exports).default || W0.exports;
var B0 = { exports: {} };
class rdt {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var sdt = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new rdt(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
B0.exports = sdt;
const idt = (B0.exports == null ? {} : B0.exports).default || B0.exports, odt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Analytics: pAe,
  BillingResources: SAe,
  CatalogFirebaseResources: gAe,
  CatalogHostedProvider: bAe,
  CatalogHostedResources: xi,
  CatalogPromotions: IAe,
  CatalogPropertyMap: pa,
  DataAccessLayer: Vee,
  Environment: b_,
  MailingListProvider: UFe,
  MailingListResources: Gp,
  ProfileProvider: $ht,
  PurchaseResources: EAe,
  RequestResources: xW,
  allMap: WL,
  allSettledMap: WL,
  cyrb53: Jht,
  empty: Zht,
  isEmpty: edt,
  isNumeric: ndt,
  resizeImage: idt,
  splitProduct: VS,
  templ8r: $
}, Symbol.toStringTag, { value: "Module" }));
var vhe;
function U() {
  return vhe.apply(null, arguments);
}
function adt(t) {
  vhe = t;
}
function ea(t) {
  return t instanceof Array || Object.prototype.toString.call(t) === "[object Array]";
}
function ff(t) {
  return t != null && Object.prototype.toString.call(t) === "[object Object]";
}
function mt(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function CV(t) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(t).length === 0;
  var e;
  for (e in t)
    if (mt(t, e))
      return !1;
  return !0;
}
function ri(t) {
  return t === void 0;
}
function Il(t) {
  return typeof t == "number" || Object.prototype.toString.call(t) === "[object Number]";
}
function iE(t) {
  return t instanceof Date || Object.prototype.toString.call(t) === "[object Date]";
}
function whe(t, e) {
  var n = [], r, s = t.length;
  for (r = 0; r < s; ++r)
    n.push(e(t[r], r));
  return n;
}
function Au(t, e) {
  for (var n in e)
    mt(e, n) && (t[n] = e[n]);
  return mt(e, "toString") && (t.toString = e.toString), mt(e, "valueOf") && (t.valueOf = e.valueOf), t;
}
function nc(t, e, n, r) {
  return Hhe(t, e, n, r, !0).utc();
}
function cdt() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function ge(t) {
  return t._pf == null && (t._pf = cdt()), t._pf;
}
var qF;
Array.prototype.some ? qF = Array.prototype.some : qF = function(t) {
  var e = Object(this), n = e.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in e && t.call(this, e[r], r, e))
      return !0;
  return !1;
};
function TV(t) {
  var e = null, n = !1, r = t._d && !isNaN(t._d.getTime());
  if (r && (e = ge(t), n = qF.call(e.parsedDateParts, function(s) {
    return s != null;
  }), r = e.overflow < 0 && !e.empty && !e.invalidEra && !e.invalidMonth && !e.invalidWeekday && !e.weekdayMismatch && !e.nullInput && !e.invalidFormat && !e.userInvalidated && (!e.meridiem || e.meridiem && n), t._strict && (r = r && e.charsLeftOver === 0 && e.unusedTokens.length === 0 && e.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(t))
    t._isValid = r;
  else
    return r;
  return t._isValid;
}
function CD(t) {
  var e = nc(NaN);
  return t != null ? Au(ge(e), t) : ge(e).userInvalidated = !0, e;
}
var b8 = U.momentProperties = [], ix = !1;
function bV(t, e) {
  var n, r, s, i = b8.length;
  if (ri(e._isAMomentObject) || (t._isAMomentObject = e._isAMomentObject), ri(e._i) || (t._i = e._i), ri(e._f) || (t._f = e._f), ri(e._l) || (t._l = e._l), ri(e._strict) || (t._strict = e._strict), ri(e._tzm) || (t._tzm = e._tzm), ri(e._isUTC) || (t._isUTC = e._isUTC), ri(e._offset) || (t._offset = e._offset), ri(e._pf) || (t._pf = ge(e)), ri(e._locale) || (t._locale = e._locale), i > 0)
    for (n = 0; n < i; n++)
      r = b8[n], s = e[r], ri(s) || (t[r] = s);
  return t;
}
function oE(t) {
  bV(this, t), this._d = new Date(t._d != null ? t._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), ix === !1 && (ix = !0, U.updateOffset(this), ix = !1);
}
function ta(t) {
  return t instanceof oE || t != null && t._isAMomentObject != null;
}
function Che(t) {
  U.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + t);
}
function fo(t, e) {
  var n = !0;
  return Au(function() {
    if (U.deprecationHandler != null && U.deprecationHandler(null, t), n) {
      var r = [], s, i, o, a = arguments.length;
      for (i = 0; i < a; i++) {
        if (s = "", typeof arguments[i] == "object") {
          s += `
[` + i + "] ";
          for (o in arguments[0])
            mt(arguments[0], o) && (s += o + ": " + arguments[0][o] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[i];
        r.push(s);
      }
      Che(
        t + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return e.apply(this, arguments);
  }, e);
}
var E8 = {};
function The(t, e) {
  U.deprecationHandler != null && U.deprecationHandler(t, e), E8[t] || (Che(e), E8[t] = !0);
}
U.suppressDeprecationWarnings = !1;
U.deprecationHandler = null;
function rc(t) {
  return typeof Function < "u" && t instanceof Function || Object.prototype.toString.call(t) === "[object Function]";
}
function ldt(t) {
  var e, n;
  for (n in t)
    mt(t, n) && (e = t[n], rc(e) ? this[n] = e : this["_" + n] = e);
  this._config = t, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function HF(t, e) {
  var n = Au({}, t), r;
  for (r in e)
    mt(e, r) && (ff(t[r]) && ff(e[r]) ? (n[r] = {}, Au(n[r], t[r]), Au(n[r], e[r])) : e[r] != null ? n[r] = e[r] : delete n[r]);
  for (r in t)
    mt(t, r) && !mt(e, r) && ff(t[r]) && (n[r] = Au({}, n[r]));
  return n;
}
function EV(t) {
  t != null && this.set(t);
}
var jF;
Object.keys ? jF = Object.keys : jF = function(t) {
  var e, n = [];
  for (e in t)
    mt(t, e) && n.push(e);
  return n;
};
var udt = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function hdt(t, e, n) {
  var r = this._calendar[t] || this._calendar.sameElse;
  return rc(r) ? r.call(e, n) : r;
}
function Wa(t, e, n) {
  var r = "" + Math.abs(t), s = e - r.length, i = t >= 0;
  return (i ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var SV = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, wS = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, ox = {}, G_ = {};
function ne(t, e, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), t && (G_[t] = s), e && (G_[e[0]] = function() {
    return Wa(s.apply(this, arguments), e[1], e[2]);
  }), n && (G_[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      t
    );
  });
}
function ddt(t) {
  return t.match(/\[[\s\S]/) ? t.replace(/^\[|\]$/g, "") : t.replace(/\\/g, "");
}
function fdt(t) {
  var e = t.match(SV), n, r;
  for (n = 0, r = e.length; n < r; n++)
    G_[e[n]] ? e[n] = G_[e[n]] : e[n] = ddt(e[n]);
  return function(s) {
    var i = "", o;
    for (o = 0; o < r; o++)
      i += rc(e[o]) ? e[o].call(s, t) : e[o];
    return i;
  };
}
function q0(t, e) {
  return t.isValid() ? (e = bhe(e, t.localeData()), ox[e] = ox[e] || fdt(e), ox[e](t)) : t.localeData().invalidDate();
}
function bhe(t, e) {
  var n = 5;
  function r(s) {
    return e.longDateFormat(s) || s;
  }
  for (wS.lastIndex = 0; n >= 0 && wS.test(t); )
    t = t.replace(
      wS,
      r
    ), wS.lastIndex = 0, n -= 1;
  return t;
}
var pdt = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function _dt(t) {
  var e = this._longDateFormat[t], n = this._longDateFormat[t.toUpperCase()];
  return e || !n ? e : (this._longDateFormat[t] = n.match(SV).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[t]);
}
var mdt = "Invalid date";
function gdt() {
  return this._invalidDate;
}
var ydt = "%d", vdt = /\d{1,2}/;
function wdt(t) {
  return this._ordinal.replace("%d", t);
}
var Cdt = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function Tdt(t, e, n, r) {
  var s = this._relativeTime[n];
  return rc(s) ? s(t, e, n, r) : s.replace(/%d/i, t);
}
function bdt(t, e) {
  var n = this._relativeTime[t > 0 ? "future" : "past"];
  return rc(n) ? n(e) : n.replace(/%s/i, e);
}
var S8 = {
  D: "date",
  dates: "date",
  date: "date",
  d: "day",
  days: "day",
  day: "day",
  e: "weekday",
  weekdays: "weekday",
  weekday: "weekday",
  E: "isoWeekday",
  isoweekdays: "isoWeekday",
  isoweekday: "isoWeekday",
  DDD: "dayOfYear",
  dayofyears: "dayOfYear",
  dayofyear: "dayOfYear",
  h: "hour",
  hours: "hour",
  hour: "hour",
  ms: "millisecond",
  milliseconds: "millisecond",
  millisecond: "millisecond",
  m: "minute",
  minutes: "minute",
  minute: "minute",
  M: "month",
  months: "month",
  month: "month",
  Q: "quarter",
  quarters: "quarter",
  quarter: "quarter",
  s: "second",
  seconds: "second",
  second: "second",
  gg: "weekYear",
  weekyears: "weekYear",
  weekyear: "weekYear",
  GG: "isoWeekYear",
  isoweekyears: "isoWeekYear",
  isoweekyear: "isoWeekYear",
  w: "week",
  weeks: "week",
  week: "week",
  W: "isoWeek",
  isoweeks: "isoWeek",
  isoweek: "isoWeek",
  y: "year",
  years: "year",
  year: "year"
};
function po(t) {
  return typeof t == "string" ? S8[t] || S8[t.toLowerCase()] : void 0;
}
function IV(t) {
  var e = {}, n, r;
  for (r in t)
    mt(t, r) && (n = po(r), n && (e[n] = t[r]));
  return e;
}
var Edt = {
  date: 9,
  day: 11,
  weekday: 11,
  isoWeekday: 11,
  dayOfYear: 4,
  hour: 13,
  millisecond: 16,
  minute: 14,
  month: 8,
  quarter: 7,
  second: 15,
  weekYear: 1,
  isoWeekYear: 1,
  week: 5,
  isoWeek: 5,
  year: 1
};
function Sdt(t) {
  var e = [], n;
  for (n in t)
    mt(t, n) && e.push({ unit: n, priority: Edt[n] });
  return e.sort(function(r, s) {
    return r.priority - s.priority;
  }), e;
}
var Ehe = /\d/, Pi = /\d\d/, She = /\d{3}/, RV = /\d{4}/, TD = /[+-]?\d{6}/, _n = /\d\d?/, Ihe = /\d\d\d\d?/, Rhe = /\d\d\d\d\d\d?/, bD = /\d{1,3}/, kV = /\d{1,4}/, ED = /[+-]?\d{1,6}/, xg = /\d+/, SD = /[+-]?\d+/, Idt = /Z|[+-]\d\d:?\d\d/gi, ID = /Z|[+-]\d\d(?::?\d\d)?/gi, Rdt = /[+-]?\d+(\.\d{1,3})?/, aE = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, Lg = /^[1-9]\d?/, NV = /^([1-9]\d|\d)/, aN;
aN = {};
function H(t, e, n) {
  aN[t] = rc(e) ? e : function(r, s) {
    return r && n ? n : e;
  };
}
function kdt(t, e) {
  return mt(aN, t) ? aN[t](e._strict, e._locale) : new RegExp(Ndt(t));
}
function Ndt(t) {
  return ul(
    t.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(e, n, r, s, i) {
        return n || r || s || i;
      }
    )
  );
}
function ul(t) {
  return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
function qi(t) {
  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
}
function Ve(t) {
  var e = +t, n = 0;
  return e !== 0 && isFinite(e) && (n = qi(e)), n;
}
var VF = {};
function Bt(t, e) {
  var n, r = e, s;
  for (typeof t == "string" && (t = [t]), Il(e) && (r = function(i, o) {
    o[e] = Ve(i);
  }), s = t.length, n = 0; n < s; n++)
    VF[t[n]] = r;
}
function cE(t, e) {
  Bt(t, function(n, r, s, i) {
    s._w = s._w || {}, e(n, s._w, s, i);
  });
}
function Pdt(t, e, n) {
  e != null && mt(VF, t) && VF[t](e, n._a, n, t);
}
function RD(t) {
  return t % 4 === 0 && t % 100 !== 0 || t % 400 === 0;
}
var _s = 0, Uc = 1, Ca = 2, Tr = 3, Fo = 4, Fc = 5, zd = 6, Odt = 7, Ddt = 8;
ne("Y", 0, 0, function() {
  var t = this.year();
  return t <= 9999 ? Wa(t, 4) : "+" + t;
});
ne(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
ne(0, ["YYYY", 4], 0, "year");
ne(0, ["YYYYY", 5], 0, "year");
ne(0, ["YYYYYY", 6, !0], 0, "year");
H("Y", SD);
H("YY", _n, Pi);
H("YYYY", kV, RV);
H("YYYYY", ED, TD);
H("YYYYYY", ED, TD);
Bt(["YYYYY", "YYYYYY"], _s);
Bt("YYYY", function(t, e) {
  e[_s] = t.length === 2 ? U.parseTwoDigitYear(t) : Ve(t);
});
Bt("YY", function(t, e) {
  e[_s] = U.parseTwoDigitYear(t);
});
Bt("Y", function(t, e) {
  e[_s] = parseInt(t, 10);
});
function Tw(t) {
  return RD(t) ? 366 : 365;
}
U.parseTwoDigitYear = function(t) {
  return Ve(t) + (Ve(t) > 68 ? 1900 : 2e3);
};
var khe = Ug("FullYear", !0);
function Adt() {
  return RD(this.year());
}
function Ug(t, e) {
  return function(n) {
    return n != null ? (Nhe(this, t, n), U.updateOffset(this, e), this) : dT(this, t);
  };
}
function dT(t, e) {
  if (!t.isValid())
    return NaN;
  var n = t._d, r = t._isUTC;
  switch (e) {
    case "Milliseconds":
      return r ? n.getUTCMilliseconds() : n.getMilliseconds();
    case "Seconds":
      return r ? n.getUTCSeconds() : n.getSeconds();
    case "Minutes":
      return r ? n.getUTCMinutes() : n.getMinutes();
    case "Hours":
      return r ? n.getUTCHours() : n.getHours();
    case "Date":
      return r ? n.getUTCDate() : n.getDate();
    case "Day":
      return r ? n.getUTCDay() : n.getDay();
    case "Month":
      return r ? n.getUTCMonth() : n.getMonth();
    case "FullYear":
      return r ? n.getUTCFullYear() : n.getFullYear();
    default:
      return NaN;
  }
}
function Nhe(t, e, n) {
  var r, s, i, o, a;
  if (!(!t.isValid() || isNaN(n))) {
    switch (r = t._d, s = t._isUTC, e) {
      case "Milliseconds":
        return void (s ? r.setUTCMilliseconds(n) : r.setMilliseconds(n));
      case "Seconds":
        return void (s ? r.setUTCSeconds(n) : r.setSeconds(n));
      case "Minutes":
        return void (s ? r.setUTCMinutes(n) : r.setMinutes(n));
      case "Hours":
        return void (s ? r.setUTCHours(n) : r.setHours(n));
      case "Date":
        return void (s ? r.setUTCDate(n) : r.setDate(n));
      case "FullYear":
        break;
      default:
        return;
    }
    i = n, o = t.month(), a = t.date(), a = a === 29 && o === 1 && !RD(i) ? 28 : a, s ? r.setUTCFullYear(i, o, a) : r.setFullYear(i, o, a);
  }
}
function Mdt(t) {
  return t = po(t), rc(this[t]) ? this[t]() : this;
}
function xdt(t, e) {
  if (typeof t == "object") {
    t = IV(t);
    var n = Sdt(t), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](t[n[r].unit]);
  } else if (t = po(t), rc(this[t]))
    return this[t](e);
  return this;
}
function Ldt(t, e) {
  return (t % e + e) % e;
}
var nr;
Array.prototype.indexOf ? nr = Array.prototype.indexOf : nr = function(t) {
  var e;
  for (e = 0; e < this.length; ++e)
    if (this[e] === t)
      return e;
  return -1;
};
function PV(t, e) {
  if (isNaN(t) || isNaN(e))
    return NaN;
  var n = Ldt(e, 12);
  return t += (e - n) / 12, n === 1 ? RD(t) ? 29 : 28 : 31 - n % 7 % 2;
}
ne("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
ne("MMM", 0, 0, function(t) {
  return this.localeData().monthsShort(this, t);
});
ne("MMMM", 0, 0, function(t) {
  return this.localeData().months(this, t);
});
H("M", _n, Lg);
H("MM", _n, Pi);
H("MMM", function(t, e) {
  return e.monthsShortRegex(t);
});
H("MMMM", function(t, e) {
  return e.monthsRegex(t);
});
Bt(["M", "MM"], function(t, e) {
  e[Uc] = Ve(t) - 1;
});
Bt(["MMM", "MMMM"], function(t, e, n, r) {
  var s = n._locale.monthsParse(t, r, n._strict);
  s != null ? e[Uc] = s : ge(n).invalidMonth = t;
});
var Udt = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), Phe = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), Ohe = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, Fdt = aE, Ydt = aE;
function Wdt(t, e) {
  return t ? ea(this._months) ? this._months[t.month()] : this._months[(this._months.isFormat || Ohe).test(e) ? "format" : "standalone"][t.month()] : ea(this._months) ? this._months : this._months.standalone;
}
function Bdt(t, e) {
  return t ? ea(this._monthsShort) ? this._monthsShort[t.month()] : this._monthsShort[Ohe.test(e) ? "format" : "standalone"][t.month()] : ea(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function qdt(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      i = nc([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        i,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(i, "").toLocaleLowerCase();
  return n ? e === "MMM" ? (s = nr.call(this._shortMonthsParse, o), s !== -1 ? s : null) : (s = nr.call(this._longMonthsParse, o), s !== -1 ? s : null) : e === "MMM" ? (s = nr.call(this._shortMonthsParse, o), s !== -1 ? s : (s = nr.call(this._longMonthsParse, o), s !== -1 ? s : null)) : (s = nr.call(this._longMonthsParse, o), s !== -1 ? s : (s = nr.call(this._shortMonthsParse, o), s !== -1 ? s : null));
}
function Hdt(t, e, n) {
  var r, s, i;
  if (this._monthsParseExact)
    return qdt.call(this, t, e, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++)
    if (s = nc([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (i = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "MMMM" && this._longMonthsParse[r].test(t) || n && e === "MMM" && this._shortMonthsParse[r].test(t) || !n && this._monthsParse[r].test(t))
      return r;
}
function Dhe(t, e) {
  if (!t.isValid())
    return t;
  if (typeof e == "string") {
    if (/^\d+$/.test(e))
      e = Ve(e);
    else if (e = t.localeData().monthsParse(e), !Il(e))
      return t;
  }
  var n = e, r = t.date();
  return r = r < 29 ? r : Math.min(r, PV(t.year(), n)), t._isUTC ? t._d.setUTCMonth(n, r) : t._d.setMonth(n, r), t;
}
function Ahe(t) {
  return t != null ? (Dhe(this, t), U.updateOffset(this, !0), this) : dT(this, "Month");
}
function jdt() {
  return PV(this.year(), this.month());
}
function Vdt(t) {
  return this._monthsParseExact ? (mt(this, "_monthsRegex") || Mhe.call(this), t ? this._monthsShortStrictRegex : this._monthsShortRegex) : (mt(this, "_monthsShortRegex") || (this._monthsShortRegex = Fdt), this._monthsShortStrictRegex && t ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function zdt(t) {
  return this._monthsParseExact ? (mt(this, "_monthsRegex") || Mhe.call(this), t ? this._monthsStrictRegex : this._monthsRegex) : (mt(this, "_monthsRegex") || (this._monthsRegex = Ydt), this._monthsStrictRegex && t ? this._monthsStrictRegex : this._monthsRegex);
}
function Mhe() {
  function t(c, l) {
    return l.length - c.length;
  }
  var e = [], n = [], r = [], s, i, o, a;
  for (s = 0; s < 12; s++)
    i = nc([2e3, s]), o = ul(this.monthsShort(i, "")), a = ul(this.months(i, "")), e.push(o), n.push(a), r.push(a), r.push(o);
  e.sort(t), n.sort(t), r.sort(t), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function Gdt(t, e, n, r, s, i, o) {
  var a;
  return t < 100 && t >= 0 ? (a = new Date(t + 400, e, n, r, s, i, o), isFinite(a.getFullYear()) && a.setFullYear(t)) : a = new Date(t, e, n, r, s, i, o), a;
}
function fT(t) {
  var e, n;
  return t < 100 && t >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = t + 400, e = new Date(Date.UTC.apply(null, n)), isFinite(e.getUTCFullYear()) && e.setUTCFullYear(t)) : e = new Date(Date.UTC.apply(null, arguments)), e;
}
function cN(t, e, n) {
  var r = 7 + e - n, s = (7 + fT(t, 0, r).getUTCDay() - e) % 7;
  return -s + r - 1;
}
function xhe(t, e, n, r, s) {
  var i = (7 + n - r) % 7, o = cN(t, r, s), a = 1 + 7 * (e - 1) + i + o, c, l;
  return a <= 0 ? (c = t - 1, l = Tw(c) + a) : a > Tw(t) ? (c = t + 1, l = a - Tw(t)) : (c = t, l = a), {
    year: c,
    dayOfYear: l
  };
}
function pT(t, e, n) {
  var r = cN(t.year(), e, n), s = Math.floor((t.dayOfYear() - r - 1) / 7) + 1, i, o;
  return s < 1 ? (o = t.year() - 1, i = s + hl(o, e, n)) : s > hl(t.year(), e, n) ? (i = s - hl(t.year(), e, n), o = t.year() + 1) : (o = t.year(), i = s), {
    week: i,
    year: o
  };
}
function hl(t, e, n) {
  var r = cN(t, e, n), s = cN(t + 1, e, n);
  return (Tw(t) - r + s) / 7;
}
ne("w", ["ww", 2], "wo", "week");
ne("W", ["WW", 2], "Wo", "isoWeek");
H("w", _n, Lg);
H("ww", _n, Pi);
H("W", _n, Lg);
H("WW", _n, Pi);
cE(
  ["w", "ww", "W", "WW"],
  function(t, e, n, r) {
    e[r.substr(0, 1)] = Ve(t);
  }
);
function $dt(t) {
  return pT(t, this._week.dow, this._week.doy).week;
}
var Kdt = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function Jdt() {
  return this._week.dow;
}
function Qdt() {
  return this._week.doy;
}
function Zdt(t) {
  var e = this.localeData().week(this);
  return t == null ? e : this.add((t - e) * 7, "d");
}
function Xdt(t) {
  var e = pT(this, 1, 4).week;
  return t == null ? e : this.add((t - e) * 7, "d");
}
ne("d", 0, "do", "day");
ne("dd", 0, 0, function(t) {
  return this.localeData().weekdaysMin(this, t);
});
ne("ddd", 0, 0, function(t) {
  return this.localeData().weekdaysShort(this, t);
});
ne("dddd", 0, 0, function(t) {
  return this.localeData().weekdays(this, t);
});
ne("e", 0, 0, "weekday");
ne("E", 0, 0, "isoWeekday");
H("d", _n);
H("e", _n);
H("E", _n);
H("dd", function(t, e) {
  return e.weekdaysMinRegex(t);
});
H("ddd", function(t, e) {
  return e.weekdaysShortRegex(t);
});
H("dddd", function(t, e) {
  return e.weekdaysRegex(t);
});
cE(["dd", "ddd", "dddd"], function(t, e, n, r) {
  var s = n._locale.weekdaysParse(t, r, n._strict);
  s != null ? e.d = s : ge(n).invalidWeekday = t;
});
cE(["d", "e", "E"], function(t, e, n, r) {
  e[r] = Ve(t);
});
function eft(t, e) {
  return typeof t != "string" ? t : isNaN(t) ? (t = e.weekdaysParse(t), typeof t == "number" ? t : null) : parseInt(t, 10);
}
function tft(t, e) {
  return typeof t == "string" ? e.weekdaysParse(t) % 7 || 7 : isNaN(t) ? null : t;
}
function OV(t, e) {
  return t.slice(e, 7).concat(t.slice(0, e));
}
var nft = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), Lhe = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), rft = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), sft = aE, ift = aE, oft = aE;
function aft(t, e) {
  var n = ea(this._weekdays) ? this._weekdays : this._weekdays[t && t !== !0 && this._weekdays.isFormat.test(e) ? "format" : "standalone"];
  return t === !0 ? OV(n, this._week.dow) : t ? n[t.day()] : n;
}
function cft(t) {
  return t === !0 ? OV(this._weekdaysShort, this._week.dow) : t ? this._weekdaysShort[t.day()] : this._weekdaysShort;
}
function lft(t) {
  return t === !0 ? OV(this._weekdaysMin, this._week.dow) : t ? this._weekdaysMin[t.day()] : this._weekdaysMin;
}
function uft(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      i = nc([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        i,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        i,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(i, "").toLocaleLowerCase();
  return n ? e === "dddd" ? (s = nr.call(this._weekdaysParse, o), s !== -1 ? s : null) : e === "ddd" ? (s = nr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null) : (s = nr.call(this._minWeekdaysParse, o), s !== -1 ? s : null) : e === "dddd" ? (s = nr.call(this._weekdaysParse, o), s !== -1 || (s = nr.call(this._shortWeekdaysParse, o), s !== -1) ? s : (s = nr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : e === "ddd" ? (s = nr.call(this._shortWeekdaysParse, o), s !== -1 || (s = nr.call(this._weekdaysParse, o), s !== -1) ? s : (s = nr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : (s = nr.call(this._minWeekdaysParse, o), s !== -1 || (s = nr.call(this._weekdaysParse, o), s !== -1) ? s : (s = nr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null));
}
function hft(t, e, n) {
  var r, s, i;
  if (this._weekdaysParseExact)
    return uft.call(this, t, e, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++)
    if (s = nc([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (i = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "dddd" && this._fullWeekdaysParse[r].test(t) || n && e === "ddd" && this._shortWeekdaysParse[r].test(t) || n && e === "dd" && this._minWeekdaysParse[r].test(t) || !n && this._weekdaysParse[r].test(t))
      return r;
}
function dft(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = dT(this, "Day");
  return t != null ? (t = eft(t, this.localeData()), this.add(t - e, "d")) : e;
}
function fft(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return t == null ? e : this.add(t - e, "d");
}
function pft(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    var e = tft(t, this.localeData());
    return this.day(this.day() % 7 ? e : e - 7);
  } else
    return this.day() || 7;
}
function _ft(t) {
  return this._weekdaysParseExact ? (mt(this, "_weekdaysRegex") || DV.call(this), t ? this._weekdaysStrictRegex : this._weekdaysRegex) : (mt(this, "_weekdaysRegex") || (this._weekdaysRegex = sft), this._weekdaysStrictRegex && t ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function mft(t) {
  return this._weekdaysParseExact ? (mt(this, "_weekdaysRegex") || DV.call(this), t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (mt(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = ift), this._weekdaysShortStrictRegex && t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function gft(t) {
  return this._weekdaysParseExact ? (mt(this, "_weekdaysRegex") || DV.call(this), t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (mt(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = oft), this._weekdaysMinStrictRegex && t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function DV() {
  function t(h, u) {
    return u.length - h.length;
  }
  var e = [], n = [], r = [], s = [], i, o, a, c, l;
  for (i = 0; i < 7; i++)
    o = nc([2e3, 1]).day(i), a = ul(this.weekdaysMin(o, "")), c = ul(this.weekdaysShort(o, "")), l = ul(this.weekdays(o, "")), e.push(a), n.push(c), r.push(l), s.push(a), s.push(c), s.push(l);
  e.sort(t), n.sort(t), r.sort(t), s.sort(t), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function AV() {
  return this.hours() % 12 || 12;
}
function yft() {
  return this.hours() || 24;
}
ne("H", ["HH", 2], 0, "hour");
ne("h", ["hh", 2], 0, AV);
ne("k", ["kk", 2], 0, yft);
ne("hmm", 0, 0, function() {
  return "" + AV.apply(this) + Wa(this.minutes(), 2);
});
ne("hmmss", 0, 0, function() {
  return "" + AV.apply(this) + Wa(this.minutes(), 2) + Wa(this.seconds(), 2);
});
ne("Hmm", 0, 0, function() {
  return "" + this.hours() + Wa(this.minutes(), 2);
});
ne("Hmmss", 0, 0, function() {
  return "" + this.hours() + Wa(this.minutes(), 2) + Wa(this.seconds(), 2);
});
function Uhe(t, e) {
  ne(t, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      e
    );
  });
}
Uhe("a", !0);
Uhe("A", !1);
function Fhe(t, e) {
  return e._meridiemParse;
}
H("a", Fhe);
H("A", Fhe);
H("H", _n, NV);
H("h", _n, Lg);
H("k", _n, Lg);
H("HH", _n, Pi);
H("hh", _n, Pi);
H("kk", _n, Pi);
H("hmm", Ihe);
H("hmmss", Rhe);
H("Hmm", Ihe);
H("Hmmss", Rhe);
Bt(["H", "HH"], Tr);
Bt(["k", "kk"], function(t, e, n) {
  var r = Ve(t);
  e[Tr] = r === 24 ? 0 : r;
});
Bt(["a", "A"], function(t, e, n) {
  n._isPm = n._locale.isPM(t), n._meridiem = t;
});
Bt(["h", "hh"], function(t, e, n) {
  e[Tr] = Ve(t), ge(n).bigHour = !0;
});
Bt("hmm", function(t, e, n) {
  var r = t.length - 2;
  e[Tr] = Ve(t.substr(0, r)), e[Fo] = Ve(t.substr(r)), ge(n).bigHour = !0;
});
Bt("hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[Tr] = Ve(t.substr(0, r)), e[Fo] = Ve(t.substr(r, 2)), e[Fc] = Ve(t.substr(s)), ge(n).bigHour = !0;
});
Bt("Hmm", function(t, e, n) {
  var r = t.length - 2;
  e[Tr] = Ve(t.substr(0, r)), e[Fo] = Ve(t.substr(r));
});
Bt("Hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[Tr] = Ve(t.substr(0, r)), e[Fo] = Ve(t.substr(r, 2)), e[Fc] = Ve(t.substr(s));
});
function vft(t) {
  return (t + "").toLowerCase().charAt(0) === "p";
}
var wft = /[ap]\.?m?\.?/i, Cft = Ug("Hours", !0);
function Tft(t, e, n) {
  return t > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var Yhe = {
  calendar: udt,
  longDateFormat: pdt,
  invalidDate: mdt,
  ordinal: ydt,
  dayOfMonthOrdinalParse: vdt,
  relativeTime: Cdt,
  months: Udt,
  monthsShort: Phe,
  week: Kdt,
  weekdays: nft,
  weekdaysMin: rft,
  weekdaysShort: Lhe,
  meridiemParse: wft
}, Cn = {}, iv = {}, _T;
function bft(t, e) {
  var n, r = Math.min(t.length, e.length);
  for (n = 0; n < r; n += 1)
    if (t[n] !== e[n])
      return n;
  return r;
}
function I8(t) {
  return t && t.toLowerCase().replace("_", "-");
}
function Eft(t) {
  for (var e = 0, n, r, s, i; e < t.length; ) {
    for (i = I8(t[e]).split("-"), n = i.length, r = I8(t[e + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = kD(i.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && bft(i, r) >= n - 1)
        break;
      n--;
    }
    e++;
  }
  return _T;
}
function Sft(t) {
  return !!(t && t.match("^[^/\\\\]*$"));
}
function kD(t) {
  var e = null, n;
  if (Cn[t] === void 0 && typeof module < "u" && module && module.exports && Sft(t))
    try {
      e = _T._abbr, n = require, n("./locale/" + t), mh(e);
    } catch {
      Cn[t] = null;
    }
  return Cn[t];
}
function mh(t, e) {
  var n;
  return t && (ri(e) ? n = $l(t) : n = MV(t, e), n ? _T = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + t + " not found. Did you forget to load it?"
  )), _T._abbr;
}
function MV(t, e) {
  if (e !== null) {
    var n, r = Yhe;
    if (e.abbr = t, Cn[t] != null)
      The(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = Cn[t]._config;
    else if (e.parentLocale != null)
      if (Cn[e.parentLocale] != null)
        r = Cn[e.parentLocale]._config;
      else if (n = kD(e.parentLocale), n != null)
        r = n._config;
      else
        return iv[e.parentLocale] || (iv[e.parentLocale] = []), iv[e.parentLocale].push({
          name: t,
          config: e
        }), null;
    return Cn[t] = new EV(HF(r, e)), iv[t] && iv[t].forEach(function(s) {
      MV(s.name, s.config);
    }), mh(t), Cn[t];
  } else
    return delete Cn[t], null;
}
function Ift(t, e) {
  if (e != null) {
    var n, r, s = Yhe;
    Cn[t] != null && Cn[t].parentLocale != null ? Cn[t].set(HF(Cn[t]._config, e)) : (r = kD(t), r != null && (s = r._config), e = HF(s, e), r == null && (e.abbr = t), n = new EV(e), n.parentLocale = Cn[t], Cn[t] = n), mh(t);
  } else
    Cn[t] != null && (Cn[t].parentLocale != null ? (Cn[t] = Cn[t].parentLocale, t === mh() && mh(t)) : Cn[t] != null && delete Cn[t]);
  return Cn[t];
}
function $l(t) {
  var e;
  if (t && t._locale && t._locale._abbr && (t = t._locale._abbr), !t)
    return _T;
  if (!ea(t)) {
    if (e = kD(t), e)
      return e;
    t = [t];
  }
  return Eft(t);
}
function Rft() {
  return jF(Cn);
}
function xV(t) {
  var e, n = t._a;
  return n && ge(t).overflow === -2 && (e = n[Uc] < 0 || n[Uc] > 11 ? Uc : n[Ca] < 1 || n[Ca] > PV(n[_s], n[Uc]) ? Ca : n[Tr] < 0 || n[Tr] > 24 || n[Tr] === 24 && (n[Fo] !== 0 || n[Fc] !== 0 || n[zd] !== 0) ? Tr : n[Fo] < 0 || n[Fo] > 59 ? Fo : n[Fc] < 0 || n[Fc] > 59 ? Fc : n[zd] < 0 || n[zd] > 999 ? zd : -1, ge(t)._overflowDayOfYear && (e < _s || e > Ca) && (e = Ca), ge(t)._overflowWeeks && e === -1 && (e = Odt), ge(t)._overflowWeekday && e === -1 && (e = Ddt), ge(t).overflow = e), t;
}
var kft = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Nft = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Pft = /Z|[+-]\d\d(?::?\d\d)?/, CS = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], ax = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], Oft = /^\/?Date\((-?\d+)/i, Dft = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, Aft = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function Whe(t) {
  var e, n, r = t._i, s = kft.exec(r) || Nft.exec(r), i, o, a, c, l = CS.length, h = ax.length;
  if (s) {
    for (ge(t).iso = !0, e = 0, n = l; e < n; e++)
      if (CS[e][1].exec(s[1])) {
        o = CS[e][0], i = CS[e][2] !== !1;
        break;
      }
    if (o == null) {
      t._isValid = !1;
      return;
    }
    if (s[3]) {
      for (e = 0, n = h; e < n; e++)
        if (ax[e][1].exec(s[3])) {
          a = (s[2] || " ") + ax[e][0];
          break;
        }
      if (a == null) {
        t._isValid = !1;
        return;
      }
    }
    if (!i && a != null) {
      t._isValid = !1;
      return;
    }
    if (s[4])
      if (Pft.exec(s[4]))
        c = "Z";
      else {
        t._isValid = !1;
        return;
      }
    t._f = o + (a || "") + (c || ""), UV(t);
  } else
    t._isValid = !1;
}
function Mft(t, e, n, r, s, i) {
  var o = [
    xft(t),
    Phe.indexOf(e),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return i && o.push(parseInt(i, 10)), o;
}
function xft(t) {
  var e = parseInt(t, 10);
  return e <= 49 ? 2e3 + e : e <= 999 ? 1900 + e : e;
}
function Lft(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function Uft(t, e, n) {
  if (t) {
    var r = Lhe.indexOf(t), s = new Date(
      e[0],
      e[1],
      e[2]
    ).getDay();
    if (r !== s)
      return ge(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function Fft(t, e, n) {
  if (t)
    return Aft[t];
  if (e)
    return 0;
  var r = parseInt(n, 10), s = r % 100, i = (r - s) / 100;
  return i * 60 + s;
}
function Bhe(t) {
  var e = Dft.exec(Lft(t._i)), n;
  if (e) {
    if (n = Mft(
      e[4],
      e[3],
      e[2],
      e[5],
      e[6],
      e[7]
    ), !Uft(e[1], n, t))
      return;
    t._a = n, t._tzm = Fft(e[8], e[9], e[10]), t._d = fT.apply(null, t._a), t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), ge(t).rfc2822 = !0;
  } else
    t._isValid = !1;
}
function Yft(t) {
  var e = Oft.exec(t._i);
  if (e !== null) {
    t._d = /* @__PURE__ */ new Date(+e[1]);
    return;
  }
  if (Whe(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  if (Bhe(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  t._strict ? t._isValid = !1 : U.createFromInputFallback(t);
}
U.createFromInputFallback = fo(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(t) {
    t._d = /* @__PURE__ */ new Date(t._i + (t._useUTC ? " UTC" : ""));
  }
);
function e_(t, e, n) {
  return t ?? e ?? n;
}
function Wft(t) {
  var e = new Date(U.now());
  return t._useUTC ? [
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate()
  ] : [e.getFullYear(), e.getMonth(), e.getDate()];
}
function LV(t) {
  var e, n, r = [], s, i, o;
  if (!t._d) {
    for (s = Wft(t), t._w && t._a[Ca] == null && t._a[Uc] == null && Bft(t), t._dayOfYear != null && (o = e_(t._a[_s], s[_s]), (t._dayOfYear > Tw(o) || t._dayOfYear === 0) && (ge(t)._overflowDayOfYear = !0), n = fT(o, 0, t._dayOfYear), t._a[Uc] = n.getUTCMonth(), t._a[Ca] = n.getUTCDate()), e = 0; e < 3 && t._a[e] == null; ++e)
      t._a[e] = r[e] = s[e];
    for (; e < 7; e++)
      t._a[e] = r[e] = t._a[e] == null ? e === 2 ? 1 : 0 : t._a[e];
    t._a[Tr] === 24 && t._a[Fo] === 0 && t._a[Fc] === 0 && t._a[zd] === 0 && (t._nextDay = !0, t._a[Tr] = 0), t._d = (t._useUTC ? fT : Gdt).apply(
      null,
      r
    ), i = t._useUTC ? t._d.getUTCDay() : t._d.getDay(), t._tzm != null && t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), t._nextDay && (t._a[Tr] = 24), t._w && typeof t._w.d < "u" && t._w.d !== i && (ge(t).weekdayMismatch = !0);
  }
}
function Bft(t) {
  var e, n, r, s, i, o, a, c, l;
  e = t._w, e.GG != null || e.W != null || e.E != null ? (i = 1, o = 4, n = e_(
    e.GG,
    t._a[_s],
    pT(ln(), 1, 4).year
  ), r = e_(e.W, 1), s = e_(e.E, 1), (s < 1 || s > 7) && (c = !0)) : (i = t._locale._week.dow, o = t._locale._week.doy, l = pT(ln(), i, o), n = e_(e.gg, t._a[_s], l.year), r = e_(e.w, l.week), e.d != null ? (s = e.d, (s < 0 || s > 6) && (c = !0)) : e.e != null ? (s = e.e + i, (e.e < 0 || e.e > 6) && (c = !0)) : s = i), r < 1 || r > hl(n, i, o) ? ge(t)._overflowWeeks = !0 : c != null ? ge(t)._overflowWeekday = !0 : (a = xhe(n, r, s, i, o), t._a[_s] = a.year, t._dayOfYear = a.dayOfYear);
}
U.ISO_8601 = function() {
};
U.RFC_2822 = function() {
};
function UV(t) {
  if (t._f === U.ISO_8601) {
    Whe(t);
    return;
  }
  if (t._f === U.RFC_2822) {
    Bhe(t);
    return;
  }
  t._a = [], ge(t).empty = !0;
  var e = "" + t._i, n, r, s, i, o, a = e.length, c = 0, l, h;
  for (s = bhe(t._f, t._locale).match(SV) || [], h = s.length, n = 0; n < h; n++)
    i = s[n], r = (e.match(kdt(i, t)) || [])[0], r && (o = e.substr(0, e.indexOf(r)), o.length > 0 && ge(t).unusedInput.push(o), e = e.slice(
      e.indexOf(r) + r.length
    ), c += r.length), G_[i] ? (r ? ge(t).empty = !1 : ge(t).unusedTokens.push(i), Pdt(i, r, t)) : t._strict && !r && ge(t).unusedTokens.push(i);
  ge(t).charsLeftOver = a - c, e.length > 0 && ge(t).unusedInput.push(e), t._a[Tr] <= 12 && ge(t).bigHour === !0 && t._a[Tr] > 0 && (ge(t).bigHour = void 0), ge(t).parsedDateParts = t._a.slice(0), ge(t).meridiem = t._meridiem, t._a[Tr] = qft(
    t._locale,
    t._a[Tr],
    t._meridiem
  ), l = ge(t).era, l !== null && (t._a[_s] = t._locale.erasConvertYear(l, t._a[_s])), LV(t), xV(t);
}
function qft(t, e, n) {
  var r;
  return n == null ? e : t.meridiemHour != null ? t.meridiemHour(e, n) : (t.isPM != null && (r = t.isPM(n), r && e < 12 && (e += 12), !r && e === 12 && (e = 0)), e);
}
function Hft(t) {
  var e, n, r, s, i, o, a = !1, c = t._f.length;
  if (c === 0) {
    ge(t).invalidFormat = !0, t._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < c; s++)
    i = 0, o = !1, e = bV({}, t), t._useUTC != null && (e._useUTC = t._useUTC), e._f = t._f[s], UV(e), TV(e) && (o = !0), i += ge(e).charsLeftOver, i += ge(e).unusedTokens.length * 10, ge(e).score = i, a ? i < r && (r = i, n = e) : (r == null || i < r || o) && (r = i, n = e, o && (a = !0));
  Au(t, n || e);
}
function jft(t) {
  if (!t._d) {
    var e = IV(t._i), n = e.day === void 0 ? e.date : e.day;
    t._a = whe(
      [e.year, e.month, n, e.hour, e.minute, e.second, e.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), LV(t);
  }
}
function Vft(t) {
  var e = new oE(xV(qhe(t)));
  return e._nextDay && (e.add(1, "d"), e._nextDay = void 0), e;
}
function qhe(t) {
  var e = t._i, n = t._f;
  return t._locale = t._locale || $l(t._l), e === null || n === void 0 && e === "" ? CD({ nullInput: !0 }) : (typeof e == "string" && (t._i = e = t._locale.preparse(e)), ta(e) ? new oE(xV(e)) : (iE(e) ? t._d = e : ea(n) ? Hft(t) : n ? UV(t) : zft(t), TV(t) || (t._d = null), t));
}
function zft(t) {
  var e = t._i;
  ri(e) ? t._d = new Date(U.now()) : iE(e) ? t._d = new Date(e.valueOf()) : typeof e == "string" ? Yft(t) : ea(e) ? (t._a = whe(e.slice(0), function(n) {
    return parseInt(n, 10);
  }), LV(t)) : ff(e) ? jft(t) : Il(e) ? t._d = new Date(e) : U.createFromInputFallback(t);
}
function Hhe(t, e, n, r, s) {
  var i = {};
  return (e === !0 || e === !1) && (r = e, e = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (ff(t) && CV(t) || ea(t) && t.length === 0) && (t = void 0), i._isAMomentObject = !0, i._useUTC = i._isUTC = s, i._l = n, i._i = t, i._f = e, i._strict = r, Vft(i);
}
function ln(t, e, n, r) {
  return Hhe(t, e, n, r, !1);
}
var Gft = fo(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = ln.apply(null, arguments);
    return this.isValid() && t.isValid() ? t < this ? this : t : CD();
  }
), $ft = fo(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = ln.apply(null, arguments);
    return this.isValid() && t.isValid() ? t > this ? this : t : CD();
  }
);
function jhe(t, e) {
  var n, r;
  if (e.length === 1 && ea(e[0]) && (e = e[0]), !e.length)
    return ln();
  for (n = e[0], r = 1; r < e.length; ++r)
    (!e[r].isValid() || e[r][t](n)) && (n = e[r]);
  return n;
}
function Kft() {
  var t = [].slice.call(arguments, 0);
  return jhe("isBefore", t);
}
function Jft() {
  var t = [].slice.call(arguments, 0);
  return jhe("isAfter", t);
}
var Qft = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, ov = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function Zft(t) {
  var e, n = !1, r, s = ov.length;
  for (e in t)
    if (mt(t, e) && !(nr.call(ov, e) !== -1 && (t[e] == null || !isNaN(t[e]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (t[ov[r]]) {
      if (n)
        return !1;
      parseFloat(t[ov[r]]) !== Ve(t[ov[r]]) && (n = !0);
    }
  return !0;
}
function Xft() {
  return this._isValid;
}
function ept() {
  return la(NaN);
}
function ND(t) {
  var e = IV(t), n = e.year || 0, r = e.quarter || 0, s = e.month || 0, i = e.week || e.isoWeek || 0, o = e.day || 0, a = e.hour || 0, c = e.minute || 0, l = e.second || 0, h = e.millisecond || 0;
  this._isValid = Zft(e), this._milliseconds = +h + l * 1e3 + // 1000
  c * 6e4 + // 1000 * 60
  a * 1e3 * 60 * 60, this._days = +o + i * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = $l(), this._bubble();
}
function H0(t) {
  return t instanceof ND;
}
function zF(t) {
  return t < 0 ? Math.round(-1 * t) * -1 : Math.round(t);
}
function tpt(t, e, n) {
  var r = Math.min(t.length, e.length), s = Math.abs(t.length - e.length), i = 0, o;
  for (o = 0; o < r; o++)
    Ve(t[o]) !== Ve(e[o]) && i++;
  return i + s;
}
function Vhe(t, e) {
  ne(t, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + Wa(~~(n / 60), 2) + e + Wa(~~n % 60, 2);
  });
}
Vhe("Z", ":");
Vhe("ZZ", "");
H("Z", ID);
H("ZZ", ID);
Bt(["Z", "ZZ"], function(t, e, n) {
  n._useUTC = !0, n._tzm = FV(ID, t);
});
var npt = /([\+\-]|\d\d)/gi;
function FV(t, e) {
  var n = (e || "").match(t), r, s, i;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(npt) || ["-", 0, 0], i = +(s[1] * 60) + Ve(s[2]), i === 0 ? 0 : s[0] === "+" ? i : -i);
}
function YV(t, e) {
  var n, r;
  return e._isUTC ? (n = e.clone(), r = (ta(t) || iE(t) ? t.valueOf() : ln(t).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), U.updateOffset(n, !1), n) : ln(t).local();
}
function GF(t) {
  return -Math.round(t._d.getTimezoneOffset());
}
U.updateOffset = function() {
};
function rpt(t, e, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    if (typeof t == "string") {
      if (t = FV(ID, t), t === null)
        return this;
    } else Math.abs(t) < 16 && !n && (t = t * 60);
    return !this._isUTC && e && (s = GF(this)), this._offset = t, this._isUTC = !0, s != null && this.add(s, "m"), r !== t && (!e || this._changeInProgress ? $he(
      this,
      la(t - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, U.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : GF(this);
}
function spt(t, e) {
  return t != null ? (typeof t != "string" && (t = -t), this.utcOffset(t, e), this) : -this.utcOffset();
}
function ipt(t) {
  return this.utcOffset(0, t);
}
function opt(t) {
  return this._isUTC && (this.utcOffset(0, t), this._isUTC = !1, t && this.subtract(GF(this), "m")), this;
}
function apt() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var t = FV(Idt, this._i);
    t != null ? this.utcOffset(t) : this.utcOffset(0, !0);
  }
  return this;
}
function cpt(t) {
  return this.isValid() ? (t = t ? ln(t).utcOffset() : 0, (this.utcOffset() - t) % 60 === 0) : !1;
}
function lpt() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function upt() {
  if (!ri(this._isDSTShifted))
    return this._isDSTShifted;
  var t = {}, e;
  return bV(t, this), t = qhe(t), t._a ? (e = t._isUTC ? nc(t._a) : ln(t._a), this._isDSTShifted = this.isValid() && tpt(t._a, e.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function hpt() {
  return this.isValid() ? !this._isUTC : !1;
}
function dpt() {
  return this.isValid() ? this._isUTC : !1;
}
function zhe() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var fpt = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, ppt = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function la(t, e) {
  var n = t, r = null, s, i, o;
  return H0(t) ? n = {
    ms: t._milliseconds,
    d: t._days,
    M: t._months
  } : Il(t) || !isNaN(+t) ? (n = {}, e ? n[e] = +t : n.milliseconds = +t) : (r = fpt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: Ve(r[Ca]) * s,
    h: Ve(r[Tr]) * s,
    m: Ve(r[Fo]) * s,
    s: Ve(r[Fc]) * s,
    ms: Ve(zF(r[zd] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = ppt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: md(r[2], s),
    M: md(r[3], s),
    w: md(r[4], s),
    d: md(r[5], s),
    h: md(r[6], s),
    m: md(r[7], s),
    s: md(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (o = _pt(
    ln(n.from),
    ln(n.to)
  ), n = {}, n.ms = o.milliseconds, n.M = o.months), i = new ND(n), H0(t) && mt(t, "_locale") && (i._locale = t._locale), H0(t) && mt(t, "_isValid") && (i._isValid = t._isValid), i;
}
la.fn = ND.prototype;
la.invalid = ept;
function md(t, e) {
  var n = t && parseFloat(t.replace(",", "."));
  return (isNaN(n) ? 0 : n) * e;
}
function R8(t, e) {
  var n = {};
  return n.months = e.month() - t.month() + (e.year() - t.year()) * 12, t.clone().add(n.months, "M").isAfter(e) && --n.months, n.milliseconds = +e - +t.clone().add(n.months, "M"), n;
}
function _pt(t, e) {
  var n;
  return t.isValid() && e.isValid() ? (e = YV(e, t), t.isBefore(e) ? n = R8(t, e) : (n = R8(e, t), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function Ghe(t, e) {
  return function(n, r) {
    var s, i;
    return r !== null && !isNaN(+r) && (The(
      e,
      "moment()." + e + "(period, number) is deprecated. Please use moment()." + e + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), i = n, n = r, r = i), s = la(n, r), $he(this, s, t), this;
  };
}
function $he(t, e, n, r) {
  var s = e._milliseconds, i = zF(e._days), o = zF(e._months);
  t.isValid() && (r = r ?? !0, o && Dhe(t, dT(t, "Month") + o * n), i && Nhe(t, "Date", dT(t, "Date") + i * n), s && t._d.setTime(t._d.valueOf() + s * n), r && U.updateOffset(t, i || o));
}
var mpt = Ghe(1, "add"), gpt = Ghe(-1, "subtract");
function Khe(t) {
  return typeof t == "string" || t instanceof String;
}
function ypt(t) {
  return ta(t) || iE(t) || Khe(t) || Il(t) || wpt(t) || vpt(t) || t === null || t === void 0;
}
function vpt(t) {
  var e = ff(t) && !CV(t), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, i, o = r.length;
  for (s = 0; s < o; s += 1)
    i = r[s], n = n || mt(t, i);
  return e && n;
}
function wpt(t) {
  var e = ea(t), n = !1;
  return e && (n = t.filter(function(r) {
    return !Il(r) && Khe(t);
  }).length === 0), e && n;
}
function Cpt(t) {
  var e = ff(t) && !CV(t), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, i;
  for (s = 0; s < r.length; s += 1)
    i = r[s], n = n || mt(t, i);
  return e && n;
}
function Tpt(t, e) {
  var n = t.diff(e, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function bpt(t, e) {
  arguments.length === 1 && (arguments[0] ? ypt(arguments[0]) ? (t = arguments[0], e = void 0) : Cpt(arguments[0]) && (e = arguments[0], t = void 0) : (t = void 0, e = void 0));
  var n = t || ln(), r = YV(n, this).startOf("day"), s = U.calendarFormat(this, r) || "sameElse", i = e && (rc(e[s]) ? e[s].call(this, n) : e[s]);
  return this.format(
    i || this.localeData().calendar(s, this, ln(n))
  );
}
function Ept() {
  return new oE(this);
}
function Spt(t, e) {
  var n = ta(t) ? t : ln(t);
  return this.isValid() && n.isValid() ? (e = po(e) || "millisecond", e === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(e).valueOf()) : !1;
}
function Ipt(t, e) {
  var n = ta(t) ? t : ln(t);
  return this.isValid() && n.isValid() ? (e = po(e) || "millisecond", e === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(e).valueOf() < n.valueOf()) : !1;
}
function Rpt(t, e, n, r) {
  var s = ta(t) ? t : ln(t), i = ta(e) ? e : ln(e);
  return this.isValid() && s.isValid() && i.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(i, n) : !this.isAfter(i, n))) : !1;
}
function kpt(t, e) {
  var n = ta(t) ? t : ln(t), r;
  return this.isValid() && n.isValid() ? (e = po(e) || "millisecond", e === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(e).valueOf() <= r && r <= this.clone().endOf(e).valueOf())) : !1;
}
function Npt(t, e) {
  return this.isSame(t, e) || this.isAfter(t, e);
}
function Ppt(t, e) {
  return this.isSame(t, e) || this.isBefore(t, e);
}
function Opt(t, e, n) {
  var r, s, i;
  if (!this.isValid())
    return NaN;
  if (r = YV(t, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, e = po(e), e) {
    case "year":
      i = j0(this, r) / 12;
      break;
    case "month":
      i = j0(this, r);
      break;
    case "quarter":
      i = j0(this, r) / 3;
      break;
    case "second":
      i = (this - r) / 1e3;
      break;
    case "minute":
      i = (this - r) / 6e4;
      break;
    case "hour":
      i = (this - r) / 36e5;
      break;
    case "day":
      i = (this - r - s) / 864e5;
      break;
    case "week":
      i = (this - r - s) / 6048e5;
      break;
    default:
      i = this - r;
  }
  return n ? i : qi(i);
}
function j0(t, e) {
  if (t.date() < e.date())
    return -j0(e, t);
  var n = (e.year() - t.year()) * 12 + (e.month() - t.month()), r = t.clone().add(n, "months"), s, i;
  return e - r < 0 ? (s = t.clone().add(n - 1, "months"), i = (e - r) / (r - s)) : (s = t.clone().add(n + 1, "months"), i = (e - r) / (s - r)), -(n + i) || 0;
}
U.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
U.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function Dpt() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function Apt(t) {
  if (!this.isValid())
    return null;
  var e = t !== !0, n = e ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? q0(
    n,
    e ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : rc(Date.prototype.toISOString) ? e ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", q0(n, "Z")) : q0(
    n,
    e ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function Mpt() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var t = "moment", e = "", n, r, s, i;
  return this.isLocal() || (t = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", e = "Z"), n = "[" + t + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", i = e + '[")]', this.format(n + r + s + i);
}
function xpt(t) {
  t || (t = this.isUtc() ? U.defaultFormatUtc : U.defaultFormat);
  var e = q0(this, t);
  return this.localeData().postformat(e);
}
function Lpt(t, e) {
  return this.isValid() && (ta(t) && t.isValid() || ln(t).isValid()) ? la({ to: this, from: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function Upt(t) {
  return this.from(ln(), t);
}
function Fpt(t, e) {
  return this.isValid() && (ta(t) && t.isValid() || ln(t).isValid()) ? la({ from: this, to: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function Ypt(t) {
  return this.to(ln(), t);
}
function Jhe(t) {
  var e;
  return t === void 0 ? this._locale._abbr : (e = $l(t), e != null && (this._locale = e), this);
}
var Qhe = fo(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(t) {
    return t === void 0 ? this.localeData() : this.locale(t);
  }
);
function Zhe() {
  return this._locale;
}
var lN = 1e3, $_ = 60 * lN, uN = 60 * $_, Xhe = (365 * 400 + 97) * 24 * uN;
function K_(t, e) {
  return (t % e + e) % e;
}
function ede(t, e, n) {
  return t < 100 && t >= 0 ? new Date(t + 400, e, n) - Xhe : new Date(t, e, n).valueOf();
}
function tde(t, e, n) {
  return t < 100 && t >= 0 ? Date.UTC(t + 400, e, n) - Xhe : Date.UTC(t, e, n);
}
function Wpt(t) {
  var e, n;
  if (t = po(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? tde : ede, t) {
    case "year":
      e = n(this.year(), 0, 1);
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      e = n(this.year(), this.month(), 1);
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      e = this._d.valueOf(), e -= K_(
        e + (this._isUTC ? 0 : this.utcOffset() * $_),
        uN
      );
      break;
    case "minute":
      e = this._d.valueOf(), e -= K_(e, $_);
      break;
    case "second":
      e = this._d.valueOf(), e -= K_(e, lN);
      break;
  }
  return this._d.setTime(e), U.updateOffset(this, !0), this;
}
function Bpt(t) {
  var e, n;
  if (t = po(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? tde : ede, t) {
    case "year":
      e = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      e = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      e = this._d.valueOf(), e += uN - K_(
        e + (this._isUTC ? 0 : this.utcOffset() * $_),
        uN
      ) - 1;
      break;
    case "minute":
      e = this._d.valueOf(), e += $_ - K_(e, $_) - 1;
      break;
    case "second":
      e = this._d.valueOf(), e += lN - K_(e, lN) - 1;
      break;
  }
  return this._d.setTime(e), U.updateOffset(this, !0), this;
}
function qpt() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function Hpt() {
  return Math.floor(this.valueOf() / 1e3);
}
function jpt() {
  return new Date(this.valueOf());
}
function Vpt() {
  var t = this;
  return [
    t.year(),
    t.month(),
    t.date(),
    t.hour(),
    t.minute(),
    t.second(),
    t.millisecond()
  ];
}
function zpt() {
  var t = this;
  return {
    years: t.year(),
    months: t.month(),
    date: t.date(),
    hours: t.hours(),
    minutes: t.minutes(),
    seconds: t.seconds(),
    milliseconds: t.milliseconds()
  };
}
function Gpt() {
  return this.isValid() ? this.toISOString() : null;
}
function $pt() {
  return TV(this);
}
function Kpt() {
  return Au({}, ge(this));
}
function Jpt() {
  return ge(this).overflow;
}
function Qpt() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
ne("N", 0, 0, "eraAbbr");
ne("NN", 0, 0, "eraAbbr");
ne("NNN", 0, 0, "eraAbbr");
ne("NNNN", 0, 0, "eraName");
ne("NNNNN", 0, 0, "eraNarrow");
ne("y", ["y", 1], "yo", "eraYear");
ne("y", ["yy", 2], 0, "eraYear");
ne("y", ["yyy", 3], 0, "eraYear");
ne("y", ["yyyy", 4], 0, "eraYear");
H("N", WV);
H("NN", WV);
H("NNN", WV);
H("NNNN", c_t);
H("NNNNN", l_t);
Bt(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(t, e, n, r) {
    var s = n._locale.erasParse(t, r, n._strict);
    s ? ge(n).era = s : ge(n).invalidEra = t;
  }
);
H("y", xg);
H("yy", xg);
H("yyy", xg);
H("yyyy", xg);
H("yo", u_t);
Bt(["y", "yy", "yyy", "yyyy"], _s);
Bt(["yo"], function(t, e, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = t.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? e[_s] = n._locale.eraYearOrdinalParse(t, s) : e[_s] = parseInt(t, 10);
});
function Zpt(t, e) {
  var n, r, s, i = this._eras || $l("en")._eras;
  for (n = 0, r = i.length; n < r; ++n) {
    switch (typeof i[n].since) {
      case "string":
        s = U(i[n].since).startOf("day"), i[n].since = s.valueOf();
        break;
    }
    switch (typeof i[n].until) {
      case "undefined":
        i[n].until = 1 / 0;
        break;
      case "string":
        s = U(i[n].until).startOf("day").valueOf(), i[n].until = s.valueOf();
        break;
    }
  }
  return i;
}
function Xpt(t, e, n) {
  var r, s, i = this.eras(), o, a, c;
  for (t = t.toUpperCase(), r = 0, s = i.length; r < s; ++r)
    if (o = i[r].name.toUpperCase(), a = i[r].abbr.toUpperCase(), c = i[r].narrow.toUpperCase(), n)
      switch (e) {
        case "N":
        case "NN":
        case "NNN":
          if (a === t)
            return i[r];
          break;
        case "NNNN":
          if (o === t)
            return i[r];
          break;
        case "NNNNN":
          if (c === t)
            return i[r];
          break;
      }
    else if ([o, a, c].indexOf(t) >= 0)
      return i[r];
}
function e_t(t, e) {
  var n = t.since <= t.until ? 1 : -1;
  return e === void 0 ? U(t.since).year() : U(t.since).year() + (e - t.offset) * n;
}
function t_t() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].name;
  return "";
}
function n_t() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].narrow;
  return "";
}
function r_t() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].abbr;
  return "";
}
function s_t() {
  var t, e, n, r, s = this.localeData().eras();
  for (t = 0, e = s.length; t < e; ++t)
    if (n = s[t].since <= s[t].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[t].since <= r && r <= s[t].until || s[t].until <= r && r <= s[t].since)
      return (this.year() - U(s[t].since).year()) * n + s[t].offset;
  return this.year();
}
function i_t(t) {
  return mt(this, "_erasNameRegex") || BV.call(this), t ? this._erasNameRegex : this._erasRegex;
}
function o_t(t) {
  return mt(this, "_erasAbbrRegex") || BV.call(this), t ? this._erasAbbrRegex : this._erasRegex;
}
function a_t(t) {
  return mt(this, "_erasNarrowRegex") || BV.call(this), t ? this._erasNarrowRegex : this._erasRegex;
}
function WV(t, e) {
  return e.erasAbbrRegex(t);
}
function c_t(t, e) {
  return e.erasNameRegex(t);
}
function l_t(t, e) {
  return e.erasNarrowRegex(t);
}
function u_t(t, e) {
  return e._eraYearOrdinalRegex || xg;
}
function BV() {
  var t = [], e = [], n = [], r = [], s, i, o, a, c, l = this.eras();
  for (s = 0, i = l.length; s < i; ++s)
    o = ul(l[s].name), a = ul(l[s].abbr), c = ul(l[s].narrow), e.push(o), t.push(a), n.push(c), r.push(o), r.push(a), r.push(c);
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
ne(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
ne(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function PD(t, e) {
  ne(0, [t, t.length], 0, e);
}
PD("gggg", "weekYear");
PD("ggggg", "weekYear");
PD("GGGG", "isoWeekYear");
PD("GGGGG", "isoWeekYear");
H("G", SD);
H("g", SD);
H("GG", _n, Pi);
H("gg", _n, Pi);
H("GGGG", kV, RV);
H("gggg", kV, RV);
H("GGGGG", ED, TD);
H("ggggg", ED, TD);
cE(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(t, e, n, r) {
    e[r.substr(0, 2)] = Ve(t);
  }
);
cE(["gg", "GG"], function(t, e, n, r) {
  e[r] = U.parseTwoDigitYear(t);
});
function h_t(t) {
  return nde.call(
    this,
    t,
    this.week(),
    this.weekday() + this.localeData()._week.dow,
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function d_t(t) {
  return nde.call(
    this,
    t,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function f_t() {
  return hl(this.year(), 1, 4);
}
function p_t() {
  return hl(this.isoWeekYear(), 1, 4);
}
function __t() {
  var t = this.localeData()._week;
  return hl(this.year(), t.dow, t.doy);
}
function m_t() {
  var t = this.localeData()._week;
  return hl(this.weekYear(), t.dow, t.doy);
}
function nde(t, e, n, r, s) {
  var i;
  return t == null ? pT(this, r, s).year : (i = hl(t, r, s), e > i && (e = i), g_t.call(this, t, e, n, r, s));
}
function g_t(t, e, n, r, s) {
  var i = xhe(t, e, n, r, s), o = fT(i.year, 0, i.dayOfYear);
  return this.year(o.getUTCFullYear()), this.month(o.getUTCMonth()), this.date(o.getUTCDate()), this;
}
ne("Q", 0, "Qo", "quarter");
H("Q", Ehe);
Bt("Q", function(t, e) {
  e[Uc] = (Ve(t) - 1) * 3;
});
function y_t(t) {
  return t == null ? Math.ceil((this.month() + 1) / 3) : this.month((t - 1) * 3 + this.month() % 3);
}
ne("D", ["DD", 2], "Do", "date");
H("D", _n, Lg);
H("DD", _n, Pi);
H("Do", function(t, e) {
  return t ? e._dayOfMonthOrdinalParse || e._ordinalParse : e._dayOfMonthOrdinalParseLenient;
});
Bt(["D", "DD"], Ca);
Bt("Do", function(t, e) {
  e[Ca] = Ve(t.match(_n)[0]);
});
var rde = Ug("Date", !0);
ne("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
H("DDD", bD);
H("DDDD", She);
Bt(["DDD", "DDDD"], function(t, e, n) {
  n._dayOfYear = Ve(t);
});
function v_t(t) {
  var e = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return t == null ? e : this.add(t - e, "d");
}
ne("m", ["mm", 2], 0, "minute");
H("m", _n, NV);
H("mm", _n, Pi);
Bt(["m", "mm"], Fo);
var w_t = Ug("Minutes", !1);
ne("s", ["ss", 2], 0, "second");
H("s", _n, NV);
H("ss", _n, Pi);
Bt(["s", "ss"], Fc);
var C_t = Ug("Seconds", !1);
ne("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
ne(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
ne(0, ["SSS", 3], 0, "millisecond");
ne(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
ne(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
ne(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
ne(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
ne(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
ne(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
H("S", bD, Ehe);
H("SS", bD, Pi);
H("SSS", bD, She);
var Mu, sde;
for (Mu = "SSSS"; Mu.length <= 9; Mu += "S")
  H(Mu, xg);
function T_t(t, e) {
  e[zd] = Ve(("0." + t) * 1e3);
}
for (Mu = "S"; Mu.length <= 9; Mu += "S")
  Bt(Mu, T_t);
sde = Ug("Milliseconds", !1);
ne("z", 0, 0, "zoneAbbr");
ne("zz", 0, 0, "zoneName");
function b_t() {
  return this._isUTC ? "UTC" : "";
}
function E_t() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var E = oE.prototype;
E.add = mpt;
E.calendar = bpt;
E.clone = Ept;
E.diff = Opt;
E.endOf = Bpt;
E.format = xpt;
E.from = Lpt;
E.fromNow = Upt;
E.to = Fpt;
E.toNow = Ypt;
E.get = Mdt;
E.invalidAt = Jpt;
E.isAfter = Spt;
E.isBefore = Ipt;
E.isBetween = Rpt;
E.isSame = kpt;
E.isSameOrAfter = Npt;
E.isSameOrBefore = Ppt;
E.isValid = $pt;
E.lang = Qhe;
E.locale = Jhe;
E.localeData = Zhe;
E.max = $ft;
E.min = Gft;
E.parsingFlags = Kpt;
E.set = xdt;
E.startOf = Wpt;
E.subtract = gpt;
E.toArray = Vpt;
E.toObject = zpt;
E.toDate = jpt;
E.toISOString = Apt;
E.inspect = Mpt;
typeof Symbol < "u" && Symbol.for != null && (E[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
E.toJSON = Gpt;
E.toString = Dpt;
E.unix = Hpt;
E.valueOf = qpt;
E.creationData = Qpt;
E.eraName = t_t;
E.eraNarrow = n_t;
E.eraAbbr = r_t;
E.eraYear = s_t;
E.year = khe;
E.isLeapYear = Adt;
E.weekYear = h_t;
E.isoWeekYear = d_t;
E.quarter = E.quarters = y_t;
E.month = Ahe;
E.daysInMonth = jdt;
E.week = E.weeks = Zdt;
E.isoWeek = E.isoWeeks = Xdt;
E.weeksInYear = __t;
E.weeksInWeekYear = m_t;
E.isoWeeksInYear = f_t;
E.isoWeeksInISOWeekYear = p_t;
E.date = rde;
E.day = E.days = dft;
E.weekday = fft;
E.isoWeekday = pft;
E.dayOfYear = v_t;
E.hour = E.hours = Cft;
E.minute = E.minutes = w_t;
E.second = E.seconds = C_t;
E.millisecond = E.milliseconds = sde;
E.utcOffset = rpt;
E.utc = ipt;
E.local = opt;
E.parseZone = apt;
E.hasAlignedHourOffset = cpt;
E.isDST = lpt;
E.isLocal = hpt;
E.isUtcOffset = dpt;
E.isUtc = zhe;
E.isUTC = zhe;
E.zoneAbbr = b_t;
E.zoneName = E_t;
E.dates = fo(
  "dates accessor is deprecated. Use date instead.",
  rde
);
E.months = fo(
  "months accessor is deprecated. Use month instead",
  Ahe
);
E.years = fo(
  "years accessor is deprecated. Use year instead",
  khe
);
E.zone = fo(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  spt
);
E.isDSTShifted = fo(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  upt
);
function S_t(t) {
  return ln(t * 1e3);
}
function I_t() {
  return ln.apply(null, arguments).parseZone();
}
function ide(t) {
  return t;
}
var Tt = EV.prototype;
Tt.calendar = hdt;
Tt.longDateFormat = _dt;
Tt.invalidDate = gdt;
Tt.ordinal = wdt;
Tt.preparse = ide;
Tt.postformat = ide;
Tt.relativeTime = Tdt;
Tt.pastFuture = bdt;
Tt.set = ldt;
Tt.eras = Zpt;
Tt.erasParse = Xpt;
Tt.erasConvertYear = e_t;
Tt.erasAbbrRegex = o_t;
Tt.erasNameRegex = i_t;
Tt.erasNarrowRegex = a_t;
Tt.months = Wdt;
Tt.monthsShort = Bdt;
Tt.monthsParse = Hdt;
Tt.monthsRegex = zdt;
Tt.monthsShortRegex = Vdt;
Tt.week = $dt;
Tt.firstDayOfYear = Qdt;
Tt.firstDayOfWeek = Jdt;
Tt.weekdays = aft;
Tt.weekdaysMin = lft;
Tt.weekdaysShort = cft;
Tt.weekdaysParse = hft;
Tt.weekdaysRegex = _ft;
Tt.weekdaysShortRegex = mft;
Tt.weekdaysMinRegex = gft;
Tt.isPM = vft;
Tt.meridiem = Tft;
function hN(t, e, n, r) {
  var s = $l(), i = nc().set(r, e);
  return s[n](i, t);
}
function ode(t, e, n) {
  if (Il(t) && (e = t, t = void 0), t = t || "", e != null)
    return hN(t, e, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = hN(t, r, n, "month");
  return s;
}
function qV(t, e, n, r) {
  typeof t == "boolean" ? (Il(e) && (n = e, e = void 0), e = e || "") : (e = t, n = e, t = !1, Il(e) && (n = e, e = void 0), e = e || "");
  var s = $l(), i = t ? s._week.dow : 0, o, a = [];
  if (n != null)
    return hN(e, (n + i) % 7, r, "day");
  for (o = 0; o < 7; o++)
    a[o] = hN(e, (o + i) % 7, r, "day");
  return a;
}
function R_t(t, e) {
  return ode(t, e, "months");
}
function k_t(t, e) {
  return ode(t, e, "monthsShort");
}
function N_t(t, e, n) {
  return qV(t, e, n, "weekdays");
}
function P_t(t, e, n) {
  return qV(t, e, n, "weekdaysShort");
}
function O_t(t, e, n) {
  return qV(t, e, n, "weekdaysMin");
}
mh("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(t) {
    var e = t % 10, n = Ve(t % 100 / 10) === 1 ? "th" : e === 1 ? "st" : e === 2 ? "nd" : e === 3 ? "rd" : "th";
    return t + n;
  }
});
U.lang = fo(
  "moment.lang is deprecated. Use moment.locale instead.",
  mh
);
U.langData = fo(
  "moment.langData is deprecated. Use moment.localeData instead.",
  $l
);
var dc = Math.abs;
function D_t() {
  var t = this._data;
  return this._milliseconds = dc(this._milliseconds), this._days = dc(this._days), this._months = dc(this._months), t.milliseconds = dc(t.milliseconds), t.seconds = dc(t.seconds), t.minutes = dc(t.minutes), t.hours = dc(t.hours), t.months = dc(t.months), t.years = dc(t.years), this;
}
function ade(t, e, n, r) {
  var s = la(e, n);
  return t._milliseconds += r * s._milliseconds, t._days += r * s._days, t._months += r * s._months, t._bubble();
}
function A_t(t, e) {
  return ade(this, t, e, 1);
}
function M_t(t, e) {
  return ade(this, t, e, -1);
}
function k8(t) {
  return t < 0 ? Math.floor(t) : Math.ceil(t);
}
function x_t() {
  var t = this._milliseconds, e = this._days, n = this._months, r = this._data, s, i, o, a, c;
  return t >= 0 && e >= 0 && n >= 0 || t <= 0 && e <= 0 && n <= 0 || (t += k8($F(n) + e) * 864e5, e = 0, n = 0), r.milliseconds = t % 1e3, s = qi(t / 1e3), r.seconds = s % 60, i = qi(s / 60), r.minutes = i % 60, o = qi(i / 60), r.hours = o % 24, e += qi(o / 24), c = qi(cde(e)), n += c, e -= k8($F(c)), a = qi(n / 12), n %= 12, r.days = e, r.months = n, r.years = a, this;
}
function cde(t) {
  return t * 4800 / 146097;
}
function $F(t) {
  return t * 146097 / 4800;
}
function L_t(t) {
  if (!this.isValid())
    return NaN;
  var e, n, r = this._milliseconds;
  if (t = po(t), t === "month" || t === "quarter" || t === "year")
    switch (e = this._days + r / 864e5, n = this._months + cde(e), t) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (e = this._days + Math.round($F(this._months)), t) {
      case "week":
        return e / 7 + r / 6048e5;
      case "day":
        return e + r / 864e5;
      case "hour":
        return e * 24 + r / 36e5;
      case "minute":
        return e * 1440 + r / 6e4;
      case "second":
        return e * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(e * 864e5) + r;
      default:
        throw new Error("Unknown unit " + t);
    }
}
function Kl(t) {
  return function() {
    return this.as(t);
  };
}
var lde = Kl("ms"), U_t = Kl("s"), F_t = Kl("m"), Y_t = Kl("h"), W_t = Kl("d"), B_t = Kl("w"), q_t = Kl("M"), H_t = Kl("Q"), j_t = Kl("y"), V_t = lde;
function z_t() {
  return la(this);
}
function G_t(t) {
  return t = po(t), this.isValid() ? this[t + "s"]() : NaN;
}
function Ip(t) {
  return function() {
    return this.isValid() ? this._data[t] : NaN;
  };
}
var $_t = Ip("milliseconds"), K_t = Ip("seconds"), J_t = Ip("minutes"), Q_t = Ip("hours"), Z_t = Ip("days"), X_t = Ip("months"), emt = Ip("years");
function tmt() {
  return qi(this.days() / 7);
}
var yc = Math.round, d_ = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function nmt(t, e, n, r, s) {
  return s.relativeTime(e || 1, !!n, t, r);
}
function rmt(t, e, n, r) {
  var s = la(t).abs(), i = yc(s.as("s")), o = yc(s.as("m")), a = yc(s.as("h")), c = yc(s.as("d")), l = yc(s.as("M")), h = yc(s.as("w")), u = yc(s.as("y")), d = i <= n.ss && ["s", i] || i < n.s && ["ss", i] || o <= 1 && ["m"] || o < n.m && ["mm", o] || a <= 1 && ["h"] || a < n.h && ["hh", a] || c <= 1 && ["d"] || c < n.d && ["dd", c];
  return n.w != null && (d = d || h <= 1 && ["w"] || h < n.w && ["ww", h]), d = d || l <= 1 && ["M"] || l < n.M && ["MM", l] || u <= 1 && ["y"] || ["yy", u], d[2] = e, d[3] = +t > 0, d[4] = r, nmt.apply(null, d);
}
function smt(t) {
  return t === void 0 ? yc : typeof t == "function" ? (yc = t, !0) : !1;
}
function imt(t, e) {
  return d_[t] === void 0 ? !1 : e === void 0 ? d_[t] : (d_[t] = e, t === "s" && (d_.ss = e - 1), !0);
}
function omt(t, e) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = d_, s, i;
  return typeof t == "object" && (e = t, t = !1), typeof t == "boolean" && (n = t), typeof e == "object" && (r = Object.assign({}, d_, e), e.s != null && e.ss == null && (r.ss = e.s - 1)), s = this.localeData(), i = rmt(this, !n, r, s), n && (i = s.pastFuture(+this, i)), s.postformat(i);
}
var cx = Math.abs;
function qp(t) {
  return (t > 0) - (t < 0) || +t;
}
function OD() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var t = cx(this._milliseconds) / 1e3, e = cx(this._days), n = cx(this._months), r, s, i, o, a = this.asSeconds(), c, l, h, u;
  return a ? (r = qi(t / 60), s = qi(r / 60), t %= 60, r %= 60, i = qi(n / 12), n %= 12, o = t ? t.toFixed(3).replace(/\.?0+$/, "") : "", c = a < 0 ? "-" : "", l = qp(this._months) !== qp(a) ? "-" : "", h = qp(this._days) !== qp(a) ? "-" : "", u = qp(this._milliseconds) !== qp(a) ? "-" : "", c + "P" + (i ? l + i + "Y" : "") + (n ? l + n + "M" : "") + (e ? h + e + "D" : "") + (s || r || t ? "T" : "") + (s ? u + s + "H" : "") + (r ? u + r + "M" : "") + (t ? u + o + "S" : "")) : "P0D";
}
var Qe = ND.prototype;
Qe.isValid = Xft;
Qe.abs = D_t;
Qe.add = A_t;
Qe.subtract = M_t;
Qe.as = L_t;
Qe.asMilliseconds = lde;
Qe.asSeconds = U_t;
Qe.asMinutes = F_t;
Qe.asHours = Y_t;
Qe.asDays = W_t;
Qe.asWeeks = B_t;
Qe.asMonths = q_t;
Qe.asQuarters = H_t;
Qe.asYears = j_t;
Qe.valueOf = V_t;
Qe._bubble = x_t;
Qe.clone = z_t;
Qe.get = G_t;
Qe.milliseconds = $_t;
Qe.seconds = K_t;
Qe.minutes = J_t;
Qe.hours = Q_t;
Qe.days = Z_t;
Qe.weeks = tmt;
Qe.months = X_t;
Qe.years = emt;
Qe.humanize = omt;
Qe.toISOString = OD;
Qe.toString = OD;
Qe.toJSON = OD;
Qe.locale = Jhe;
Qe.localeData = Zhe;
Qe.toIsoString = fo(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  OD
);
Qe.lang = Qhe;
ne("X", 0, 0, "unix");
ne("x", 0, 0, "valueOf");
H("x", SD);
H("X", Rdt);
Bt("X", function(t, e, n) {
  n._d = new Date(parseFloat(t) * 1e3);
});
Bt("x", function(t, e, n) {
  n._d = new Date(Ve(t));
});
U.version = "2.30.1";
adt(ln);
U.fn = E;
U.min = Kft;
U.max = Jft;
U.now = Qft;
U.utc = nc;
U.unix = S_t;
U.months = R_t;
U.isDate = iE;
U.locale = mh;
U.invalid = CD;
U.duration = la;
U.isMoment = ta;
U.weekdays = N_t;
U.parseZone = I_t;
U.localeData = $l;
U.isDuration = H0;
U.monthsShort = k_t;
U.weekdaysMin = O_t;
U.defineLocale = MV;
U.updateLocale = Ift;
U.locales = Rft;
U.weekdaysShort = P_t;
U.normalizeUnits = po;
U.relativeTimeRounding = smt;
U.relativeTimeThreshold = imt;
U.calendarFormat = Tpt;
U.prototype = E;
U.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
function ude(t, e) {
  var n = {};
  for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function hde() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const amt = hde, dde = new ZT("auth", "Firebase", hde()), dN = new $2("@firebase/auth");
function cmt(t, ...e) {
  dN.logLevel <= Rt.WARN && dN.warn(`Auth (${XT}): ${t}`, ...e);
}
function V0(t, ...e) {
  dN.logLevel <= Rt.ERROR && dN.error(`Auth (${XT}): ${t}`, ...e);
}
function mT(t, ...e) {
  throw jV(t, ...e);
}
function HV(t, ...e) {
  return jV(t, ...e);
}
function lmt(t, e, n) {
  const r = Object.assign(Object.assign({}, amt()), { [e]: n });
  return new ZT("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function jV(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return dde.create(t, ...e);
}
function rt(t, e, ...n) {
  if (!t)
    throw jV(e, ...n);
}
function Yc(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw V0(e), new Error(e);
}
function fN(t, e) {
  t || Yc(e);
}
function umt() {
  return N8() === "http:" || N8() === "https:";
}
function N8() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
function hmt() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (umt() || lRe() || "connection" in navigator) ? navigator.onLine : !0;
}
function dmt() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
class lE {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, fN(n > e, "Short delay should be less than long delay!"), this.isMobile = z2() || cX();
  }
  get() {
    return hmt() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
function fmt(t, e) {
  fN(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
class fde {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    Yc("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    Yc("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    Yc("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
const pmt = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
}, _mt = new lE(3e4, 6e4);
function uE(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Rp(t, e, n, r, s = {}) {
  return pde(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = G2(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), fde.fetch()(_de(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function pde(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, pmt), e);
  try {
    const s = new mmt(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw TS(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, l] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw TS(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw TS(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw TS(t, "user-disabled", o);
      const h = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (l)
        throw lmt(t, h, l);
      mT(t, h);
    }
  } catch (s) {
    if (s instanceof Al)
      throw s;
    mT(t, "network-request-failed", { message: String(s) });
  }
}
async function VV(t, e, n, r, s = {}) {
  const i = await Rp(t, e, n, r, s);
  return "mfaPendingCredential" in i && mT(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function _de(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? fmt(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class mmt {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(HV(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), _mt.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function TS(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = HV(t, e, r);
  return s.customData._tokenResponse = n, s;
}
async function gmt(t, e) {
  return Rp(t, "POST", "/v1/accounts:delete", e);
}
async function ymt(t, e) {
  return Rp(t, "POST", "/v1/accounts:lookup", e);
}
function bw(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function vmt(t, e = !1) {
  const n = cs(t), r = await n.getIdToken(e), s = mde(r);
  rt(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: bw(lx(s.auth_time)),
    issuedAtTime: bw(lx(s.iat)),
    expirationTime: bw(lx(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function lx(t) {
  return Number(t) * 1e3;
}
function mde(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return V0("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = JI(n);
    return s ? JSON.parse(s) : (V0("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return V0("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function wmt(t) {
  const e = mde(t);
  return rt(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), rt(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), rt(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
async function pN(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof Al && Cmt(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function Cmt({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
class Tmt {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const r = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, r);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
class gde {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = bw(this.lastLoginAt), this.creationTime = bw(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
async function _N(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await pN(t, ymt(n, { idToken: r }));
  rt(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? Smt(i.providerUserInfo) : [], a = Emt(t.providerData, o), c = t.isAnonymous, l = !(t.email && i.passwordHash) && !(a != null && a.length), h = c ? l : !1, u = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new gde(i.createdAt, i.lastLoginAt),
    isAnonymous: h
  };
  Object.assign(t, u);
}
async function bmt(t) {
  const e = cs(t);
  await _N(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function Emt(t, e) {
  return [...t.filter((n) => !e.some((r) => r.providerId === n.providerId)), ...e];
}
function Smt(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = ude(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
async function Imt(t, e) {
  const n = await pde(t, {}, async () => {
    const r = G2({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = _de(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", fde.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
class gT {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    rt(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), rt(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), rt(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : wmt(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return rt(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await Imt(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new gT();
    return r && (rt(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (rt(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (rt(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new gT(), this.toJSON());
  }
  _performRefresh() {
    return Yc("not implemented");
  }
}
function uu(t, e) {
  rt(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class pf {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = ude(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new Tmt(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new gde(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await pN(this, this.stsTokenManager.getToken(this.auth, e));
    return rt(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return vmt(this, e);
  }
  reload() {
    return bmt(this);
  }
  _assign(e) {
    this !== e && (rt(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new pf(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    rt(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await _N(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await pN(this, gmt(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, l, h;
    const u = (r = n.displayName) !== null && r !== void 0 ? r : void 0, d = (s = n.email) !== null && s !== void 0 ? s : void 0, f = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, p = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, _ = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, m = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, v = (l = n.createdAt) !== null && l !== void 0 ? l : void 0, y = (h = n.lastLoginAt) !== null && h !== void 0 ? h : void 0, { uid: g, emailVerified: w, isAnonymous: de, providerData: we, stsTokenManager: fe } = n;
    rt(
      g && fe,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const ys = gT.fromJSON(this.name, fe);
    rt(
      typeof g == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), uu(u, e.name), uu(d, e.name), rt(
      typeof w == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), rt(
      typeof de == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), uu(f, e.name), uu(p, e.name), uu(_, e.name), uu(m, e.name), uu(v, e.name), uu(y, e.name);
    const En = new pf({
      uid: g,
      auth: e,
      email: d,
      emailVerified: w,
      displayName: u,
      isAnonymous: de,
      photoURL: p,
      phoneNumber: f,
      tenantId: _,
      stsTokenManager: ys,
      createdAt: v,
      lastLoginAt: y
    });
    return we && Array.isArray(we) && (En.providerData = we.map((vo) => Object.assign({}, vo))), m && (En._redirectEventId = m), En;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new gT();
    s.updateFromServerResponse(n);
    const i = new pf({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await _N(i), i;
  }
}
const P8 = /* @__PURE__ */ new Map();
function Gd(t) {
  fN(t instanceof Function, "Expected a class definition");
  let e = P8.get(t);
  return e ? (fN(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), P8.set(t, e), e);
}
class yde {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
yde.type = "NONE";
const O8 = yde;
function ux(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class J_ {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = ux(this.userKey, s.apiKey, i), this.fullPersistenceKey = ux("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? pf._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new J_(Gd(O8), e, r);
    const s = (await Promise.all(n.map(async (l) => {
      if (await l._isAvailable())
        return l;
    }))).filter((l) => l);
    let i = s[0] || Gd(O8);
    const o = ux(r, e.config.apiKey, e.name);
    let a = null;
    for (const l of n)
      try {
        const h = await l._get(o);
        if (h) {
          const u = pf._fromJSON(e, h);
          l !== i && (a = u), i = l;
          break;
        }
      } catch {
      }
    const c = s.filter((l) => l._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new J_(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (l) => {
      if (l !== i)
        try {
          await l._remove(o);
        } catch {
        }
    })), new J_(i, e, r));
  }
}
function D8(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (Pmt(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (Rmt(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (Dmt(e))
    return "Blackberry";
  if (Amt(e))
    return "Webos";
  if (kmt(e))
    return "Safari";
  if ((e.includes("chrome/") || Nmt(e)) && !e.includes("edge/"))
    return "Chrome";
  if (Omt(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function Rmt(t = Aa()) {
  return /firefox\//i.test(t);
}
function kmt(t = Aa()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function Nmt(t = Aa()) {
  return /crios\//i.test(t);
}
function Pmt(t = Aa()) {
  return /iemobile/i.test(t);
}
function Omt(t = Aa()) {
  return /android/i.test(t);
}
function Dmt(t = Aa()) {
  return /blackberry/i.test(t);
}
function Amt(t = Aa()) {
  return /webos/i.test(t);
}
function vde(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = D8(Aa());
      break;
    case "Worker":
      n = `${D8(Aa())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${XT}/${r}`;
}
async function wde(t, e) {
  return Rp(t, "GET", "/v2/recaptchaConfig", uE(t, e));
}
function A8(t) {
  return t !== void 0 && t.enterprise !== void 0;
}
class Cde {
  constructor(e) {
    if (this.siteKey = "", this.emailPasswordEnabled = !1, e.recaptchaKey === void 0)
      throw new Error("recaptchaKey undefined");
    this.siteKey = e.recaptchaKey.split("/")[3], this.emailPasswordEnabled = e.recaptchaEnforcementState.some((n) => n.provider === "EMAIL_PASSWORD_PROVIDER" && n.enforcementState !== "OFF");
  }
}
function Mmt() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function xmt(t) {
  return new Promise((e, n) => {
    const r = document.createElement("script");
    r.setAttribute("src", t), r.onload = e, r.onerror = (s) => {
      const i = HV(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = s, n(i);
    }, r.type = "text/javascript", r.charset = "UTF-8", Mmt().appendChild(r);
  });
}
const Lmt = "https://www.google.com/recaptcha/enterprise.js?render=", Umt = "recaptcha-enterprise";
class Tde {
  /**
   *
   * @param authExtern - The corresponding Firebase {@link Auth} instance.
   *
   */
  constructor(e) {
    this.type = Umt, this.auth = DD(e);
  }
  /**
   * Executes the verification process.
   *
   * @returns A Promise for a token that can be used to assert the validity of a request.
   */
  async verify(e = "verify", n = !1) {
    async function r(i) {
      if (!n) {
        if (i.tenantId == null && i._agentRecaptchaConfig != null)
          return i._agentRecaptchaConfig.siteKey;
        if (i.tenantId != null && i._tenantRecaptchaConfigs[i.tenantId] !== void 0)
          return i._tenantRecaptchaConfigs[i.tenantId].siteKey;
      }
      return new Promise(async (o, a) => {
        wde(i, {
          clientType: "CLIENT_TYPE_WEB",
          version: "RECAPTCHA_ENTERPRISE"
          /* RecaptchaVersion.ENTERPRISE */
        }).then((c) => {
          if (c.recaptchaKey === void 0)
            a(new Error("recaptcha Enterprise site key undefined"));
          else {
            const l = new Cde(c);
            return i.tenantId == null ? i._agentRecaptchaConfig = l : i._tenantRecaptchaConfigs[i.tenantId] = l, o(l.siteKey);
          }
        }).catch((c) => {
          a(c);
        });
      });
    }
    function s(i, o, a) {
      const c = window.grecaptcha;
      A8(c) ? c.enterprise.ready(() => {
        try {
          c.enterprise.execute(i, { action: e }).then((l) => {
            o(l);
          }).catch((l) => {
            a(l);
          });
        } catch (l) {
          a(l);
        }
      }) : a(Error("No reCAPTCHA enterprise script loaded."));
    }
    return new Promise((i, o) => {
      r(this.auth).then((a) => {
        if (!n && A8(window.grecaptcha))
          s(a, i, o);
        else {
          if (typeof window > "u") {
            o(new Error("RecaptchaVerifier is only supported in browser"));
            return;
          }
          xmt(Lmt + a).then(() => {
            s(a, i, o);
          }).catch((c) => {
            o(c);
          });
        }
      }).catch((a) => {
        o(a);
      });
    });
  }
}
async function mN(t, e, n, r = !1) {
  const s = new Tde(t);
  let i;
  try {
    i = await s.verify(n);
  } catch {
    i = await s.verify(n, !0);
  }
  const o = Object.assign({}, e);
  return r ? Object.assign(o, { captchaResp: i }) : Object.assign(o, { captchaResponse: i }), Object.assign(o, {
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  }), Object.assign(o, {
    recaptchaVersion: "RECAPTCHA_ENTERPRISE"
    /* RecaptchaVersion.ENTERPRISE */
  }), o;
}
class Fmt {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
class Ymt {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new M8(this), this.idTokenSubscription = new M8(this), this.beforeStateQueue = new Fmt(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = dde, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = Gd(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await J_.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && c != null && c.user && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return rt(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await _N(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = dmt();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? cs(e) : null;
    return n && rt(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && rt(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(Gd(e));
    });
  }
  async initializeRecaptchaConfig() {
    const e = await wde(this, {
      clientType: "CLIENT_TYPE_WEB",
      version: "RECAPTCHA_ENTERPRISE"
      /* RecaptchaVersion.ENTERPRISE */
    }), n = new Cde(e);
    this.tenantId == null ? this._agentRecaptchaConfig = n : this._tenantRecaptchaConfigs[this.tenantId] = n, n.emailPasswordEnabled && new Tde(this).verify();
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new ZT("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && Gd(e) || this._popupRedirectResolver;
      rt(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await J_.create(
        this,
        [Gd(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return rt(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, r, s) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return rt(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = vde(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && cmt(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function DD(t) {
  return cs(t);
}
class M8 {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = yRe((n) => this.observer = n);
  }
  get next() {
    return rt(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function Wmt(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(Gd);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
class Bmt {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return Yc("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return Yc("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return Yc("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return Yc("not implemented");
  }
}
async function qmt(t, e) {
  return Rp(t, "POST", "/v1/accounts:update", e);
}
async function hx(t, e) {
  return VV(t, "POST", "/v1/accounts:signInWithPassword", uE(t, e));
}
async function Hmt(t, e) {
  return Rp(t, "POST", "/v1/accounts:sendOobCode", uE(t, e));
}
async function dx(t, e) {
  return Hmt(t, e);
}
async function jmt(t, e) {
  return VV(t, "POST", "/v1/accounts:signInWithEmailLink", uE(t, e));
}
async function Vmt(t, e) {
  return VV(t, "POST", "/v1/accounts:signInWithEmailLink", uE(t, e));
}
class yT extends Bmt {
  /** @internal */
  constructor(e, n, r, s = null) {
    super("password", r), this._email = e, this._password = n, this._tenantId = s;
  }
  /** @internal */
  static _fromEmailAndPassword(e, n) {
    return new yT(
      e,
      n,
      "password"
      /* SignInMethod.EMAIL_PASSWORD */
    );
  }
  /** @internal */
  static _fromEmailAndCode(e, n, r = null) {
    return new yT(e, n, "emailLink", r);
  }
  /** {@inheritdoc AuthCredential.toJSON} */
  toJSON() {
    return {
      email: this._email,
      password: this._password,
      signInMethod: this.signInMethod,
      tenantId: this._tenantId
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an {@link  AuthCredential}.
   *
   * @param json - Either `object` or the stringified representation of the object. When string is
   * provided, `JSON.parse` would be called first.
   *
   * @returns If the JSON input does not represent an {@link AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e;
    if (n != null && n.email && n != null && n.password) {
      if (n.signInMethod === "password")
        return this._fromEmailAndPassword(n.email, n.password);
      if (n.signInMethod === "emailLink")
        return this._fromEmailAndCode(n.email, n.password, n.tenantId);
    }
    return null;
  }
  /** @internal */
  async _getIdTokenResponse(e) {
    var n;
    switch (this.signInMethod) {
      case "password":
        const r = {
          returnSecureToken: !0,
          email: this._email,
          password: this._password,
          clientType: "CLIENT_TYPE_WEB"
          /* RecaptchaClientType.WEB */
        };
        if (!((n = e._getRecaptchaConfig()) === null || n === void 0) && n.emailPasswordEnabled) {
          const s = await mN(
            e,
            r,
            "signInWithPassword"
            /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
          );
          return hx(e, s);
        } else
          return hx(e, r).catch(async (s) => {
            if (s.code === "auth/missing-recaptcha-token") {
              console.log("Sign-in with email address and password is protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the sign-in flow.");
              const i = await mN(
                e,
                r,
                "signInWithPassword"
                /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
              );
              return hx(e, i);
            } else
              return Promise.reject(s);
          });
      case "emailLink":
        return jmt(e, {
          email: this._email,
          oobCode: this._password
        });
      default:
        mT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  async _linkToIdToken(e, n) {
    switch (this.signInMethod) {
      case "password":
        return qmt(e, {
          idToken: n,
          returnSecureToken: !0,
          email: this._email,
          password: this._password
        });
      case "emailLink":
        return Vmt(e, {
          idToken: n,
          email: this._email,
          oobCode: this._password
        });
      default:
        mT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return this._getIdTokenResponse(e);
  }
}
function zmt(t) {
  switch (t) {
    case "recoverEmail":
      return "RECOVER_EMAIL";
    case "resetPassword":
      return "PASSWORD_RESET";
    case "signIn":
      return "EMAIL_SIGNIN";
    case "verifyEmail":
      return "VERIFY_EMAIL";
    case "verifyAndChangeEmail":
      return "VERIFY_AND_CHANGE_EMAIL";
    case "revertSecondFactorAddition":
      return "REVERT_SECOND_FACTOR_ADDITION";
    default:
      return null;
  }
}
function Gmt(t) {
  const e = yv(vv(t)).link, n = e ? yv(vv(e)).deep_link_id : null, r = yv(vv(t)).deep_link_id;
  return (r ? yv(vv(r)).link : null) || r || n || e || t;
}
class zV {
  /**
   * @param actionLink - The link from which to extract the URL.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @internal
   */
  constructor(e) {
    var n, r, s, i, o, a;
    const c = yv(vv(e)), l = (n = c.apiKey) !== null && n !== void 0 ? n : null, h = (r = c.oobCode) !== null && r !== void 0 ? r : null, u = zmt((s = c.mode) !== null && s !== void 0 ? s : null);
    rt(
      l && h && u,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), this.apiKey = l, this.operation = u, this.code = h, this.continueUrl = (i = c.continueUrl) !== null && i !== void 0 ? i : null, this.languageCode = (o = c.languageCode) !== null && o !== void 0 ? o : null, this.tenantId = (a = c.tenantId) !== null && a !== void 0 ? a : null;
  }
  /**
   * Parses the email action link string and returns an {@link ActionCodeURL} if the link is valid,
   * otherwise returns null.
   *
   * @param link  - The email action link string.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @public
   */
  static parseLink(e) {
    const n = Gmt(e);
    try {
      return new zV(n);
    } catch {
      return null;
    }
  }
}
class Fg {
  constructor() {
    this.providerId = Fg.PROVIDER_ID;
  }
  /**
   * Initialize an {@link AuthCredential} using an email and password.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credential(email, password);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * const userCredential = await signInWithEmailAndPassword(auth, email, password);
   * ```
   *
   * @param email - Email address.
   * @param password - User account password.
   * @returns The auth provider credential.
   */
  static credential(e, n) {
    return yT._fromEmailAndPassword(e, n);
  }
  /**
   * Initialize an {@link AuthCredential} using an email and an email link after a sign in with
   * email link operation.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credentialWithLink(auth, email, emailLink);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * await sendSignInLinkToEmail(auth, email);
   * // Obtain emailLink from user.
   * const userCredential = await signInWithEmailLink(auth, email, emailLink);
   * ```
   *
   * @param auth - The {@link Auth} instance used to verify the link.
   * @param email - Email address.
   * @param emailLink - Sign-in email link.
   * @returns - The auth provider credential.
   */
  static credentialWithLink(e, n) {
    const r = zV.parseLink(n);
    return rt(
      r,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), yT._fromEmailAndCode(e, r.code, r.tenantId);
  }
}
Fg.PROVIDER_ID = "password";
Fg.EMAIL_PASSWORD_SIGN_IN_METHOD = "password";
Fg.EMAIL_LINK_SIGN_IN_METHOD = "emailLink";
class gN {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, r, s = !1) {
    const i = await pf._fromIdTokenResponse(e, r, s), o = x8(r);
    return new gN({
      user: i,
      providerId: o,
      _tokenResponse: r,
      operationType: n
    });
  }
  static async _forOperation(e, n, r) {
    await e._updateTokensIfNecessary(
      r,
      /* reload */
      !0
    );
    const s = x8(r);
    return new gN({
      user: e,
      providerId: s,
      _tokenResponse: r,
      operationType: n
    });
  }
}
function x8(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
class yN extends Al {
  constructor(e, n, r, s) {
    var i;
    super(n.code, n.message), this.operationType = r, this.user = s, Object.setPrototypeOf(this, yN.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: r
    };
  }
  static _fromErrorAndOperation(e, n, r, s) {
    return new yN(e, n, r, s);
  }
}
function $mt(t, e, n, r) {
  return n._getIdTokenResponse(t).catch((s) => {
    throw s.code === "auth/multi-factor-auth-required" ? yN._fromErrorAndOperation(t, s, e, r) : s;
  });
}
async function Kmt(t, e, n = !1) {
  const r = "signIn", s = await $mt(t, r, e), i = await gN._fromIdTokenResponse(t, r, s);
  return n || await t._updateCurrentUser(i.user), i;
}
async function Jmt(t, e) {
  return Kmt(DD(t), e);
}
async function Qmt(t, e, n) {
  var r;
  const s = DD(t), i = {
    requestType: "PASSWORD_RESET",
    email: e,
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  };
  if (!((r = s._getRecaptchaConfig()) === null || r === void 0) && r.emailPasswordEnabled) {
    const o = await mN(s, i, "getOobCode", !0);
    await dx(s, o);
  } else
    await dx(s, i).catch(async (o) => {
      if (o.code === "auth/missing-recaptcha-token") {
        console.log("Password resets are protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the password reset flow.");
        const a = await mN(s, i, "getOobCode", !0);
        await dx(s, a);
      } else
        return Promise.reject(o);
    });
}
async function Zmt(t, e) {
  return Rp(t, "POST", "/v1/accounts:update", e);
}
async function L8(t, { displayName: e, photoURL: n }) {
  if (e === void 0 && n === void 0)
    return;
  const r = cs(t), s = {
    idToken: await r.getIdToken(),
    displayName: e,
    photoUrl: n,
    returnSecureToken: !0
  }, i = await pN(r, Zmt(r.auth, s));
  r.displayName = i.displayName || null, r.photoURL = i.photoUrl || null;
  const o = r.providerData.find(
    ({ providerId: a }) => a === "password"
    /* ProviderId.PASSWORD */
  );
  o && (o.displayName = r.displayName, o.photoURL = r.photoURL), await r._updateTokensIfNecessary(i);
}
new lE(3e4, 6e4);
new lE(2e3, 1e4);
new lE(3e4, 6e4);
new lE(5e3, 15e3);
var U8 = "@firebase/auth", F8 = "0.23.0";
class Xmt {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    rt(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
function egt(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function tgt(t) {
  Df(new Rh(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      rt(o && !o.includes(":"), "invalid-api-key", { appName: r.name }), rt(!(a != null && a.includes(":")), "argument-error", {
        appName: r.name
      });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: vde(t)
      }, l = new Ymt(r, s, i, c);
      return Wmt(l, n), l;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), Df(new Rh(
    "auth-internal",
    (e) => {
      const n = DD(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new Xmt(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), Jc(U8, F8, egt(t)), Jc(U8, F8, "esm2017");
}
aRe("authIdTokenMaxAge");
tgt(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const Y8 = {
  PICTURE_STORAGE: "/users/{uid}/UserProfilePic.jpg",
  PICTURE_DATE: "/users/{uid}/profilePicDate"
  // YYYY-MM-DD HH:mm:ss
};
class av {
  static PictureStorage(e) {
    return G(Y8.PICTURE_STORAGE, { uid: e });
  }
  static PictureDate(e) {
    return G(Y8.PICTURE_DATE, { uid: e });
  }
}
const { resizeImage: ngt } = odt;
class rgt extends jee {
  constructor(e) {
    super(), this.auth = e;
  }
  async uploadProfilePicture(e) {
    try {
      let n = await ngt({
        file: e,
        maxSize: 750
        //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
      }), r = { contentType: e.type };
      await this.uploadObject(av.PictureStorage(this.uid), n.blob, r);
      let s = await this.objectUrl(av.PictureStorage(this.uid));
      return console.log(`Profile photo updated: ${s}`), await this.updatePhoto(s), await this.updatePictureDate(), s;
    } catch (n) {
      throw console.error("Upload profile: " + n.message), n;
    }
  }
  async deleteProfilePicture() {
    try {
      const e = av.PictureStorage(this.uid);
      await this.deleteObject(e), await this.updatePhoto(""), await this.updatePictureDate();
    } catch (e) {
      throw console.error("Delete profile: " + e.message), e;
    }
  }
  async updatePictureDate() {
    return await this.set(av.PictureDate(this.uid), this.currentTime);
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
  get currentTime() {
    return U().format("YYYY-MM-DD HH:mm:ss");
  }
  async getPhotoUrl() {
    const e = av.PictureStorage(this.uid);
    return await this.objectUrl(e);
  }
  // get profilePictureExists() {
  //     (async () => {
  //         try {
  //             const path = ProfileResources.PictureStorage(this.uid);
  //             await this.objectUrl(path)
  //             return true;
  //         } catch(e) {
  //             return false;
  //         }
  //         })();
  // }
  async sendPasswordResetEmail() {
    try {
      return await Qmt(this.auth, this.auth.currentUser.email);
    } catch (e) {
      throw console.error("Password reset: " + e.message), e;
    }
  }
  async updateEmail(e) {
    try {
      return await updateEmail(this.auth.currentUser, e);
    } catch (n) {
      throw console.error("Updating email address: " + n.message), n;
    }
  }
  async updateUserProfile(e) {
    try {
      return await L8(this.auth.currentUser, {
        displayName: e.trim()
      });
    } catch (n) {
      throw console.error("Updating profile name: " + n.message), n;
    }
  }
  async updatePhoto(e) {
    try {
      return await L8(this.auth.currentUser, {
        photoURL: e
      });
    } catch (n) {
      throw console.error("Updating profile photo: " + n.message), n;
    }
  }
  async reauthenticateWithCredential(e) {
    try {
      const n = Fg.credential(this.auth.currentUser.email, e);
      return await Jmt(this.auth, n);
    } catch (n) {
      throw console.error("Verifying credentials: " + n.message), n;
    }
  }
}
var z0 = { exports: {} };
function sgt(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
z0.exports = sgt;
const igt = (z0.exports == null ? {} : z0.exports).default || z0.exports;
var G0 = { exports: {} };
function ogt(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
G0.exports = ogt;
const agt = (G0.exports == null ? {} : G0.exports).default || G0.exports;
var $0 = { exports: {} };
const cgt = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
$0.exports = cgt;
const lgt = ($0.exports == null ? {} : $0.exports).default || $0.exports;
var K0 = { exports: {} };
function ugt(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
K0.exports = ugt;
const hgt = (K0.exports == null ? {} : K0.exports).default || K0.exports;
var J0 = { exports: {} };
class dgt {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var fgt = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new dgt(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
J0.exports = fgt;
const pgt = (J0.exports == null ? {} : J0.exports).default || J0.exports, _gt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Analytics: FIe,
  BillingResources: KIe,
  CatalogFirebaseResources: BIe,
  CatalogHostedProvider: GIe,
  CatalogHostedResources: Mi,
  CatalogPromotions: JIe,
  CatalogPropertyMap: fa,
  DataAccessLayer: tX,
  Environment: w_,
  MailingListProvider: aAe,
  MailingListResources: zp,
  ProfileProvider: rgt,
  PurchaseResources: $Ie,
  RequestResources: j2,
  allMap: yL,
  allSettledMap: yL,
  cyrb53: igt,
  empty: agt,
  isEmpty: lgt,
  isNumeric: hgt,
  resizeImage: pgt,
  splitProduct: WS,
  templ8r: G
}, Symbol.toStringTag, { value: "Module" }));
var bde;
function F() {
  return bde.apply(null, arguments);
}
function mgt(t) {
  bde = t;
}
function na(t) {
  return t instanceof Array || Object.prototype.toString.call(t) === "[object Array]";
}
function _f(t) {
  return t != null && Object.prototype.toString.call(t) === "[object Object]";
}
function gt(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function GV(t) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(t).length === 0;
  var e;
  for (e in t)
    if (gt(t, e))
      return !1;
  return !0;
}
function si(t) {
  return t === void 0;
}
function Rl(t) {
  return typeof t == "number" || Object.prototype.toString.call(t) === "[object Number]";
}
function hE(t) {
  return t instanceof Date || Object.prototype.toString.call(t) === "[object Date]";
}
function Ede(t, e) {
  var n = [], r, s = t.length;
  for (r = 0; r < s; ++r)
    n.push(e(t[r], r));
  return n;
}
function xu(t, e) {
  for (var n in e)
    gt(e, n) && (t[n] = e[n]);
  return gt(e, "toString") && (t.toString = e.toString), gt(e, "valueOf") && (t.valueOf = e.valueOf), t;
}
function sc(t, e, n, r) {
  return Gde(t, e, n, r, !0).utc();
}
function ggt() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function ye(t) {
  return t._pf == null && (t._pf = ggt()), t._pf;
}
var KF;
Array.prototype.some ? KF = Array.prototype.some : KF = function(t) {
  var e = Object(this), n = e.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in e && t.call(this, e[r], r, e))
      return !0;
  return !1;
};
function $V(t) {
  var e = null, n = !1, r = t._d && !isNaN(t._d.getTime());
  if (r && (e = ye(t), n = KF.call(e.parsedDateParts, function(s) {
    return s != null;
  }), r = e.overflow < 0 && !e.empty && !e.invalidEra && !e.invalidMonth && !e.invalidWeekday && !e.weekdayMismatch && !e.nullInput && !e.invalidFormat && !e.userInvalidated && (!e.meridiem || e.meridiem && n), t._strict && (r = r && e.charsLeftOver === 0 && e.unusedTokens.length === 0 && e.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(t))
    t._isValid = r;
  else
    return r;
  return t._isValid;
}
function AD(t) {
  var e = sc(NaN);
  return t != null ? xu(ye(e), t) : ye(e).userInvalidated = !0, e;
}
var W8 = F.momentProperties = [], fx = !1;
function KV(t, e) {
  var n, r, s, i = W8.length;
  if (si(e._isAMomentObject) || (t._isAMomentObject = e._isAMomentObject), si(e._i) || (t._i = e._i), si(e._f) || (t._f = e._f), si(e._l) || (t._l = e._l), si(e._strict) || (t._strict = e._strict), si(e._tzm) || (t._tzm = e._tzm), si(e._isUTC) || (t._isUTC = e._isUTC), si(e._offset) || (t._offset = e._offset), si(e._pf) || (t._pf = ye(e)), si(e._locale) || (t._locale = e._locale), i > 0)
    for (n = 0; n < i; n++)
      r = W8[n], s = e[r], si(s) || (t[r] = s);
  return t;
}
function dE(t) {
  KV(this, t), this._d = new Date(t._d != null ? t._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), fx === !1 && (fx = !0, F.updateOffset(this), fx = !1);
}
function ra(t) {
  return t instanceof dE || t != null && t._isAMomentObject != null;
}
function Sde(t) {
  F.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + t);
}
function _o(t, e) {
  var n = !0;
  return xu(function() {
    if (F.deprecationHandler != null && F.deprecationHandler(null, t), n) {
      var r = [], s, i, o, a = arguments.length;
      for (i = 0; i < a; i++) {
        if (s = "", typeof arguments[i] == "object") {
          s += `
[` + i + "] ";
          for (o in arguments[0])
            gt(arguments[0], o) && (s += o + ": " + arguments[0][o] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[i];
        r.push(s);
      }
      Sde(
        t + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return e.apply(this, arguments);
  }, e);
}
var B8 = {};
function Ide(t, e) {
  F.deprecationHandler != null && F.deprecationHandler(t, e), B8[t] || (Sde(e), B8[t] = !0);
}
F.suppressDeprecationWarnings = !1;
F.deprecationHandler = null;
function ic(t) {
  return typeof Function < "u" && t instanceof Function || Object.prototype.toString.call(t) === "[object Function]";
}
function ygt(t) {
  var e, n;
  for (n in t)
    gt(t, n) && (e = t[n], ic(e) ? this[n] = e : this["_" + n] = e);
  this._config = t, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function JF(t, e) {
  var n = xu({}, t), r;
  for (r in e)
    gt(e, r) && (_f(t[r]) && _f(e[r]) ? (n[r] = {}, xu(n[r], t[r]), xu(n[r], e[r])) : e[r] != null ? n[r] = e[r] : delete n[r]);
  for (r in t)
    gt(t, r) && !gt(e, r) && _f(t[r]) && (n[r] = xu({}, n[r]));
  return n;
}
function JV(t) {
  t != null && this.set(t);
}
var QF;
Object.keys ? QF = Object.keys : QF = function(t) {
  var e, n = [];
  for (e in t)
    gt(t, e) && n.push(e);
  return n;
};
var vgt = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function wgt(t, e, n) {
  var r = this._calendar[t] || this._calendar.sameElse;
  return ic(r) ? r.call(e, n) : r;
}
function Ba(t, e, n) {
  var r = "" + Math.abs(t), s = e - r.length, i = t >= 0;
  return (i ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var QV = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, bS = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, px = {}, Q_ = {};
function re(t, e, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), t && (Q_[t] = s), e && (Q_[e[0]] = function() {
    return Ba(s.apply(this, arguments), e[1], e[2]);
  }), n && (Q_[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      t
    );
  });
}
function Cgt(t) {
  return t.match(/\[[\s\S]/) ? t.replace(/^\[|\]$/g, "") : t.replace(/\\/g, "");
}
function Tgt(t) {
  var e = t.match(QV), n, r;
  for (n = 0, r = e.length; n < r; n++)
    Q_[e[n]] ? e[n] = Q_[e[n]] : e[n] = Cgt(e[n]);
  return function(s) {
    var i = "", o;
    for (o = 0; o < r; o++)
      i += ic(e[o]) ? e[o].call(s, t) : e[o];
    return i;
  };
}
function Q0(t, e) {
  return t.isValid() ? (e = Rde(e, t.localeData()), px[e] = px[e] || Tgt(e), px[e](t)) : t.localeData().invalidDate();
}
function Rde(t, e) {
  var n = 5;
  function r(s) {
    return e.longDateFormat(s) || s;
  }
  for (bS.lastIndex = 0; n >= 0 && bS.test(t); )
    t = t.replace(
      bS,
      r
    ), bS.lastIndex = 0, n -= 1;
  return t;
}
var bgt = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function Egt(t) {
  var e = this._longDateFormat[t], n = this._longDateFormat[t.toUpperCase()];
  return e || !n ? e : (this._longDateFormat[t] = n.match(QV).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[t]);
}
var Sgt = "Invalid date";
function Igt() {
  return this._invalidDate;
}
var Rgt = "%d", kgt = /\d{1,2}/;
function Ngt(t) {
  return this._ordinal.replace("%d", t);
}
var Pgt = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function Ogt(t, e, n, r) {
  var s = this._relativeTime[n];
  return ic(s) ? s(t, e, n, r) : s.replace(/%d/i, t);
}
function Dgt(t, e) {
  var n = this._relativeTime[t > 0 ? "future" : "past"];
  return ic(n) ? n(e) : n.replace(/%s/i, e);
}
var q8 = {
  D: "date",
  dates: "date",
  date: "date",
  d: "day",
  days: "day",
  day: "day",
  e: "weekday",
  weekdays: "weekday",
  weekday: "weekday",
  E: "isoWeekday",
  isoweekdays: "isoWeekday",
  isoweekday: "isoWeekday",
  DDD: "dayOfYear",
  dayofyears: "dayOfYear",
  dayofyear: "dayOfYear",
  h: "hour",
  hours: "hour",
  hour: "hour",
  ms: "millisecond",
  milliseconds: "millisecond",
  millisecond: "millisecond",
  m: "minute",
  minutes: "minute",
  minute: "minute",
  M: "month",
  months: "month",
  month: "month",
  Q: "quarter",
  quarters: "quarter",
  quarter: "quarter",
  s: "second",
  seconds: "second",
  second: "second",
  gg: "weekYear",
  weekyears: "weekYear",
  weekyear: "weekYear",
  GG: "isoWeekYear",
  isoweekyears: "isoWeekYear",
  isoweekyear: "isoWeekYear",
  w: "week",
  weeks: "week",
  week: "week",
  W: "isoWeek",
  isoweeks: "isoWeek",
  isoweek: "isoWeek",
  y: "year",
  years: "year",
  year: "year"
};
function mo(t) {
  return typeof t == "string" ? q8[t] || q8[t.toLowerCase()] : void 0;
}
function ZV(t) {
  var e = {}, n, r;
  for (r in t)
    gt(t, r) && (n = mo(r), n && (e[n] = t[r]));
  return e;
}
var Agt = {
  date: 9,
  day: 11,
  weekday: 11,
  isoWeekday: 11,
  dayOfYear: 4,
  hour: 13,
  millisecond: 16,
  minute: 14,
  month: 8,
  quarter: 7,
  second: 15,
  weekYear: 1,
  isoWeekYear: 1,
  week: 5,
  isoWeek: 5,
  year: 1
};
function Mgt(t) {
  var e = [], n;
  for (n in t)
    gt(t, n) && e.push({ unit: n, priority: Agt[n] });
  return e.sort(function(r, s) {
    return r.priority - s.priority;
  }), e;
}
var kde = /\d/, Oi = /\d\d/, Nde = /\d{3}/, XV = /\d{4}/, MD = /[+-]?\d{6}/, mn = /\d\d?/, Pde = /\d\d\d\d?/, Ode = /\d\d\d\d\d\d?/, xD = /\d{1,3}/, e6 = /\d{1,4}/, LD = /[+-]?\d{1,6}/, Yg = /\d+/, UD = /[+-]?\d+/, xgt = /Z|[+-]\d\d:?\d\d/gi, FD = /Z|[+-]\d\d(?::?\d\d)?/gi, Lgt = /[+-]?\d+(\.\d{1,3})?/, fE = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, Wg = /^[1-9]\d?/, t6 = /^([1-9]\d|\d)/, vN;
vN = {};
function j(t, e, n) {
  vN[t] = ic(e) ? e : function(r, s) {
    return r && n ? n : e;
  };
}
function Ugt(t, e) {
  return gt(vN, t) ? vN[t](e._strict, e._locale) : new RegExp(Fgt(t));
}
function Fgt(t) {
  return dl(
    t.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(e, n, r, s, i) {
        return n || r || s || i;
      }
    )
  );
}
function dl(t) {
  return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
function Hi(t) {
  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
}
function ze(t) {
  var e = +t, n = 0;
  return e !== 0 && isFinite(e) && (n = Hi(e)), n;
}
var ZF = {};
function qt(t, e) {
  var n, r = e, s;
  for (typeof t == "string" && (t = [t]), Rl(e) && (r = function(i, o) {
    o[e] = ze(i);
  }), s = t.length, n = 0; n < s; n++)
    ZF[t[n]] = r;
}
function pE(t, e) {
  qt(t, function(n, r, s, i) {
    s._w = s._w || {}, e(n, s._w, s, i);
  });
}
function Ygt(t, e, n) {
  e != null && gt(ZF, t) && ZF[t](e, n._a, n, t);
}
function YD(t) {
  return t % 4 === 0 && t % 100 !== 0 || t % 400 === 0;
}
var ms = 0, Wc = 1, Ta = 2, br = 3, Yo = 4, Bc = 5, $d = 6, Wgt = 7, Bgt = 8;
re("Y", 0, 0, function() {
  var t = this.year();
  return t <= 9999 ? Ba(t, 4) : "+" + t;
});
re(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
re(0, ["YYYY", 4], 0, "year");
re(0, ["YYYYY", 5], 0, "year");
re(0, ["YYYYYY", 6, !0], 0, "year");
j("Y", UD);
j("YY", mn, Oi);
j("YYYY", e6, XV);
j("YYYYY", LD, MD);
j("YYYYYY", LD, MD);
qt(["YYYYY", "YYYYYY"], ms);
qt("YYYY", function(t, e) {
  e[ms] = t.length === 2 ? F.parseTwoDigitYear(t) : ze(t);
});
qt("YY", function(t, e) {
  e[ms] = F.parseTwoDigitYear(t);
});
qt("Y", function(t, e) {
  e[ms] = parseInt(t, 10);
});
function Ew(t) {
  return YD(t) ? 366 : 365;
}
F.parseTwoDigitYear = function(t) {
  return ze(t) + (ze(t) > 68 ? 1900 : 2e3);
};
var Dde = Bg("FullYear", !0);
function qgt() {
  return YD(this.year());
}
function Bg(t, e) {
  return function(n) {
    return n != null ? (Ade(this, t, n), F.updateOffset(this, e), this) : vT(this, t);
  };
}
function vT(t, e) {
  if (!t.isValid())
    return NaN;
  var n = t._d, r = t._isUTC;
  switch (e) {
    case "Milliseconds":
      return r ? n.getUTCMilliseconds() : n.getMilliseconds();
    case "Seconds":
      return r ? n.getUTCSeconds() : n.getSeconds();
    case "Minutes":
      return r ? n.getUTCMinutes() : n.getMinutes();
    case "Hours":
      return r ? n.getUTCHours() : n.getHours();
    case "Date":
      return r ? n.getUTCDate() : n.getDate();
    case "Day":
      return r ? n.getUTCDay() : n.getDay();
    case "Month":
      return r ? n.getUTCMonth() : n.getMonth();
    case "FullYear":
      return r ? n.getUTCFullYear() : n.getFullYear();
    default:
      return NaN;
  }
}
function Ade(t, e, n) {
  var r, s, i, o, a;
  if (!(!t.isValid() || isNaN(n))) {
    switch (r = t._d, s = t._isUTC, e) {
      case "Milliseconds":
        return void (s ? r.setUTCMilliseconds(n) : r.setMilliseconds(n));
      case "Seconds":
        return void (s ? r.setUTCSeconds(n) : r.setSeconds(n));
      case "Minutes":
        return void (s ? r.setUTCMinutes(n) : r.setMinutes(n));
      case "Hours":
        return void (s ? r.setUTCHours(n) : r.setHours(n));
      case "Date":
        return void (s ? r.setUTCDate(n) : r.setDate(n));
      case "FullYear":
        break;
      default:
        return;
    }
    i = n, o = t.month(), a = t.date(), a = a === 29 && o === 1 && !YD(i) ? 28 : a, s ? r.setUTCFullYear(i, o, a) : r.setFullYear(i, o, a);
  }
}
function Hgt(t) {
  return t = mo(t), ic(this[t]) ? this[t]() : this;
}
function jgt(t, e) {
  if (typeof t == "object") {
    t = ZV(t);
    var n = Mgt(t), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](t[n[r].unit]);
  } else if (t = mo(t), ic(this[t]))
    return this[t](e);
  return this;
}
function Vgt(t, e) {
  return (t % e + e) % e;
}
var rr;
Array.prototype.indexOf ? rr = Array.prototype.indexOf : rr = function(t) {
  var e;
  for (e = 0; e < this.length; ++e)
    if (this[e] === t)
      return e;
  return -1;
};
function n6(t, e) {
  if (isNaN(t) || isNaN(e))
    return NaN;
  var n = Vgt(e, 12);
  return t += (e - n) / 12, n === 1 ? YD(t) ? 29 : 28 : 31 - n % 7 % 2;
}
re("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
re("MMM", 0, 0, function(t) {
  return this.localeData().monthsShort(this, t);
});
re("MMMM", 0, 0, function(t) {
  return this.localeData().months(this, t);
});
j("M", mn, Wg);
j("MM", mn, Oi);
j("MMM", function(t, e) {
  return e.monthsShortRegex(t);
});
j("MMMM", function(t, e) {
  return e.monthsRegex(t);
});
qt(["M", "MM"], function(t, e) {
  e[Wc] = ze(t) - 1;
});
qt(["MMM", "MMMM"], function(t, e, n, r) {
  var s = n._locale.monthsParse(t, r, n._strict);
  s != null ? e[Wc] = s : ye(n).invalidMonth = t;
});
var zgt = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), Mde = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), xde = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, Ggt = fE, $gt = fE;
function Kgt(t, e) {
  return t ? na(this._months) ? this._months[t.month()] : this._months[(this._months.isFormat || xde).test(e) ? "format" : "standalone"][t.month()] : na(this._months) ? this._months : this._months.standalone;
}
function Jgt(t, e) {
  return t ? na(this._monthsShort) ? this._monthsShort[t.month()] : this._monthsShort[xde.test(e) ? "format" : "standalone"][t.month()] : na(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function Qgt(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      i = sc([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        i,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(i, "").toLocaleLowerCase();
  return n ? e === "MMM" ? (s = rr.call(this._shortMonthsParse, o), s !== -1 ? s : null) : (s = rr.call(this._longMonthsParse, o), s !== -1 ? s : null) : e === "MMM" ? (s = rr.call(this._shortMonthsParse, o), s !== -1 ? s : (s = rr.call(this._longMonthsParse, o), s !== -1 ? s : null)) : (s = rr.call(this._longMonthsParse, o), s !== -1 ? s : (s = rr.call(this._shortMonthsParse, o), s !== -1 ? s : null));
}
function Zgt(t, e, n) {
  var r, s, i;
  if (this._monthsParseExact)
    return Qgt.call(this, t, e, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++)
    if (s = sc([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (i = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "MMMM" && this._longMonthsParse[r].test(t) || n && e === "MMM" && this._shortMonthsParse[r].test(t) || !n && this._monthsParse[r].test(t))
      return r;
}
function Lde(t, e) {
  if (!t.isValid())
    return t;
  if (typeof e == "string") {
    if (/^\d+$/.test(e))
      e = ze(e);
    else if (e = t.localeData().monthsParse(e), !Rl(e))
      return t;
  }
  var n = e, r = t.date();
  return r = r < 29 ? r : Math.min(r, n6(t.year(), n)), t._isUTC ? t._d.setUTCMonth(n, r) : t._d.setMonth(n, r), t;
}
function Ude(t) {
  return t != null ? (Lde(this, t), F.updateOffset(this, !0), this) : vT(this, "Month");
}
function Xgt() {
  return n6(this.year(), this.month());
}
function eyt(t) {
  return this._monthsParseExact ? (gt(this, "_monthsRegex") || Fde.call(this), t ? this._monthsShortStrictRegex : this._monthsShortRegex) : (gt(this, "_monthsShortRegex") || (this._monthsShortRegex = Ggt), this._monthsShortStrictRegex && t ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function tyt(t) {
  return this._monthsParseExact ? (gt(this, "_monthsRegex") || Fde.call(this), t ? this._monthsStrictRegex : this._monthsRegex) : (gt(this, "_monthsRegex") || (this._monthsRegex = $gt), this._monthsStrictRegex && t ? this._monthsStrictRegex : this._monthsRegex);
}
function Fde() {
  function t(c, l) {
    return l.length - c.length;
  }
  var e = [], n = [], r = [], s, i, o, a;
  for (s = 0; s < 12; s++)
    i = sc([2e3, s]), o = dl(this.monthsShort(i, "")), a = dl(this.months(i, "")), e.push(o), n.push(a), r.push(a), r.push(o);
  e.sort(t), n.sort(t), r.sort(t), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function nyt(t, e, n, r, s, i, o) {
  var a;
  return t < 100 && t >= 0 ? (a = new Date(t + 400, e, n, r, s, i, o), isFinite(a.getFullYear()) && a.setFullYear(t)) : a = new Date(t, e, n, r, s, i, o), a;
}
function wT(t) {
  var e, n;
  return t < 100 && t >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = t + 400, e = new Date(Date.UTC.apply(null, n)), isFinite(e.getUTCFullYear()) && e.setUTCFullYear(t)) : e = new Date(Date.UTC.apply(null, arguments)), e;
}
function wN(t, e, n) {
  var r = 7 + e - n, s = (7 + wT(t, 0, r).getUTCDay() - e) % 7;
  return -s + r - 1;
}
function Yde(t, e, n, r, s) {
  var i = (7 + n - r) % 7, o = wN(t, r, s), a = 1 + 7 * (e - 1) + i + o, c, l;
  return a <= 0 ? (c = t - 1, l = Ew(c) + a) : a > Ew(t) ? (c = t + 1, l = a - Ew(t)) : (c = t, l = a), {
    year: c,
    dayOfYear: l
  };
}
function CT(t, e, n) {
  var r = wN(t.year(), e, n), s = Math.floor((t.dayOfYear() - r - 1) / 7) + 1, i, o;
  return s < 1 ? (o = t.year() - 1, i = s + fl(o, e, n)) : s > fl(t.year(), e, n) ? (i = s - fl(t.year(), e, n), o = t.year() + 1) : (o = t.year(), i = s), {
    week: i,
    year: o
  };
}
function fl(t, e, n) {
  var r = wN(t, e, n), s = wN(t + 1, e, n);
  return (Ew(t) - r + s) / 7;
}
re("w", ["ww", 2], "wo", "week");
re("W", ["WW", 2], "Wo", "isoWeek");
j("w", mn, Wg);
j("ww", mn, Oi);
j("W", mn, Wg);
j("WW", mn, Oi);
pE(
  ["w", "ww", "W", "WW"],
  function(t, e, n, r) {
    e[r.substr(0, 1)] = ze(t);
  }
);
function ryt(t) {
  return CT(t, this._week.dow, this._week.doy).week;
}
var syt = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function iyt() {
  return this._week.dow;
}
function oyt() {
  return this._week.doy;
}
function ayt(t) {
  var e = this.localeData().week(this);
  return t == null ? e : this.add((t - e) * 7, "d");
}
function cyt(t) {
  var e = CT(this, 1, 4).week;
  return t == null ? e : this.add((t - e) * 7, "d");
}
re("d", 0, "do", "day");
re("dd", 0, 0, function(t) {
  return this.localeData().weekdaysMin(this, t);
});
re("ddd", 0, 0, function(t) {
  return this.localeData().weekdaysShort(this, t);
});
re("dddd", 0, 0, function(t) {
  return this.localeData().weekdays(this, t);
});
re("e", 0, 0, "weekday");
re("E", 0, 0, "isoWeekday");
j("d", mn);
j("e", mn);
j("E", mn);
j("dd", function(t, e) {
  return e.weekdaysMinRegex(t);
});
j("ddd", function(t, e) {
  return e.weekdaysShortRegex(t);
});
j("dddd", function(t, e) {
  return e.weekdaysRegex(t);
});
pE(["dd", "ddd", "dddd"], function(t, e, n, r) {
  var s = n._locale.weekdaysParse(t, r, n._strict);
  s != null ? e.d = s : ye(n).invalidWeekday = t;
});
pE(["d", "e", "E"], function(t, e, n, r) {
  e[r] = ze(t);
});
function lyt(t, e) {
  return typeof t != "string" ? t : isNaN(t) ? (t = e.weekdaysParse(t), typeof t == "number" ? t : null) : parseInt(t, 10);
}
function uyt(t, e) {
  return typeof t == "string" ? e.weekdaysParse(t) % 7 || 7 : isNaN(t) ? null : t;
}
function r6(t, e) {
  return t.slice(e, 7).concat(t.slice(0, e));
}
var hyt = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), Wde = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), dyt = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), fyt = fE, pyt = fE, _yt = fE;
function myt(t, e) {
  var n = na(this._weekdays) ? this._weekdays : this._weekdays[t && t !== !0 && this._weekdays.isFormat.test(e) ? "format" : "standalone"];
  return t === !0 ? r6(n, this._week.dow) : t ? n[t.day()] : n;
}
function gyt(t) {
  return t === !0 ? r6(this._weekdaysShort, this._week.dow) : t ? this._weekdaysShort[t.day()] : this._weekdaysShort;
}
function yyt(t) {
  return t === !0 ? r6(this._weekdaysMin, this._week.dow) : t ? this._weekdaysMin[t.day()] : this._weekdaysMin;
}
function vyt(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      i = sc([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        i,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        i,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(i, "").toLocaleLowerCase();
  return n ? e === "dddd" ? (s = rr.call(this._weekdaysParse, o), s !== -1 ? s : null) : e === "ddd" ? (s = rr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null) : (s = rr.call(this._minWeekdaysParse, o), s !== -1 ? s : null) : e === "dddd" ? (s = rr.call(this._weekdaysParse, o), s !== -1 || (s = rr.call(this._shortWeekdaysParse, o), s !== -1) ? s : (s = rr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : e === "ddd" ? (s = rr.call(this._shortWeekdaysParse, o), s !== -1 || (s = rr.call(this._weekdaysParse, o), s !== -1) ? s : (s = rr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : (s = rr.call(this._minWeekdaysParse, o), s !== -1 || (s = rr.call(this._weekdaysParse, o), s !== -1) ? s : (s = rr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null));
}
function wyt(t, e, n) {
  var r, s, i;
  if (this._weekdaysParseExact)
    return vyt.call(this, t, e, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++)
    if (s = sc([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (i = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "dddd" && this._fullWeekdaysParse[r].test(t) || n && e === "ddd" && this._shortWeekdaysParse[r].test(t) || n && e === "dd" && this._minWeekdaysParse[r].test(t) || !n && this._weekdaysParse[r].test(t))
      return r;
}
function Cyt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = vT(this, "Day");
  return t != null ? (t = lyt(t, this.localeData()), this.add(t - e, "d")) : e;
}
function Tyt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return t == null ? e : this.add(t - e, "d");
}
function byt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    var e = uyt(t, this.localeData());
    return this.day(this.day() % 7 ? e : e - 7);
  } else
    return this.day() || 7;
}
function Eyt(t) {
  return this._weekdaysParseExact ? (gt(this, "_weekdaysRegex") || s6.call(this), t ? this._weekdaysStrictRegex : this._weekdaysRegex) : (gt(this, "_weekdaysRegex") || (this._weekdaysRegex = fyt), this._weekdaysStrictRegex && t ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function Syt(t) {
  return this._weekdaysParseExact ? (gt(this, "_weekdaysRegex") || s6.call(this), t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (gt(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = pyt), this._weekdaysShortStrictRegex && t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function Iyt(t) {
  return this._weekdaysParseExact ? (gt(this, "_weekdaysRegex") || s6.call(this), t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (gt(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = _yt), this._weekdaysMinStrictRegex && t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function s6() {
  function t(h, u) {
    return u.length - h.length;
  }
  var e = [], n = [], r = [], s = [], i, o, a, c, l;
  for (i = 0; i < 7; i++)
    o = sc([2e3, 1]).day(i), a = dl(this.weekdaysMin(o, "")), c = dl(this.weekdaysShort(o, "")), l = dl(this.weekdays(o, "")), e.push(a), n.push(c), r.push(l), s.push(a), s.push(c), s.push(l);
  e.sort(t), n.sort(t), r.sort(t), s.sort(t), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function i6() {
  return this.hours() % 12 || 12;
}
function Ryt() {
  return this.hours() || 24;
}
re("H", ["HH", 2], 0, "hour");
re("h", ["hh", 2], 0, i6);
re("k", ["kk", 2], 0, Ryt);
re("hmm", 0, 0, function() {
  return "" + i6.apply(this) + Ba(this.minutes(), 2);
});
re("hmmss", 0, 0, function() {
  return "" + i6.apply(this) + Ba(this.minutes(), 2) + Ba(this.seconds(), 2);
});
re("Hmm", 0, 0, function() {
  return "" + this.hours() + Ba(this.minutes(), 2);
});
re("Hmmss", 0, 0, function() {
  return "" + this.hours() + Ba(this.minutes(), 2) + Ba(this.seconds(), 2);
});
function Bde(t, e) {
  re(t, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      e
    );
  });
}
Bde("a", !0);
Bde("A", !1);
function qde(t, e) {
  return e._meridiemParse;
}
j("a", qde);
j("A", qde);
j("H", mn, t6);
j("h", mn, Wg);
j("k", mn, Wg);
j("HH", mn, Oi);
j("hh", mn, Oi);
j("kk", mn, Oi);
j("hmm", Pde);
j("hmmss", Ode);
j("Hmm", Pde);
j("Hmmss", Ode);
qt(["H", "HH"], br);
qt(["k", "kk"], function(t, e, n) {
  var r = ze(t);
  e[br] = r === 24 ? 0 : r;
});
qt(["a", "A"], function(t, e, n) {
  n._isPm = n._locale.isPM(t), n._meridiem = t;
});
qt(["h", "hh"], function(t, e, n) {
  e[br] = ze(t), ye(n).bigHour = !0;
});
qt("hmm", function(t, e, n) {
  var r = t.length - 2;
  e[br] = ze(t.substr(0, r)), e[Yo] = ze(t.substr(r)), ye(n).bigHour = !0;
});
qt("hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[br] = ze(t.substr(0, r)), e[Yo] = ze(t.substr(r, 2)), e[Bc] = ze(t.substr(s)), ye(n).bigHour = !0;
});
qt("Hmm", function(t, e, n) {
  var r = t.length - 2;
  e[br] = ze(t.substr(0, r)), e[Yo] = ze(t.substr(r));
});
qt("Hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[br] = ze(t.substr(0, r)), e[Yo] = ze(t.substr(r, 2)), e[Bc] = ze(t.substr(s));
});
function kyt(t) {
  return (t + "").toLowerCase().charAt(0) === "p";
}
var Nyt = /[ap]\.?m?\.?/i, Pyt = Bg("Hours", !0);
function Oyt(t, e, n) {
  return t > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var Hde = {
  calendar: vgt,
  longDateFormat: bgt,
  invalidDate: Sgt,
  ordinal: Rgt,
  dayOfMonthOrdinalParse: kgt,
  relativeTime: Pgt,
  months: zgt,
  monthsShort: Mde,
  week: syt,
  weekdays: hyt,
  weekdaysMin: dyt,
  weekdaysShort: Wde,
  meridiemParse: Nyt
}, Tn = {}, cv = {}, TT;
function Dyt(t, e) {
  var n, r = Math.min(t.length, e.length);
  for (n = 0; n < r; n += 1)
    if (t[n] !== e[n])
      return n;
  return r;
}
function H8(t) {
  return t && t.toLowerCase().replace("_", "-");
}
function Ayt(t) {
  for (var e = 0, n, r, s, i; e < t.length; ) {
    for (i = H8(t[e]).split("-"), n = i.length, r = H8(t[e + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = WD(i.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && Dyt(i, r) >= n - 1)
        break;
      n--;
    }
    e++;
  }
  return TT;
}
function Myt(t) {
  return !!(t && t.match("^[^/\\\\]*$"));
}
function WD(t) {
  var e = null, n;
  if (Tn[t] === void 0 && typeof module < "u" && module && module.exports && Myt(t))
    try {
      e = TT._abbr, n = require, n("./locale/" + t), gh(e);
    } catch {
      Tn[t] = null;
    }
  return Tn[t];
}
function gh(t, e) {
  var n;
  return t && (si(e) ? n = Jl(t) : n = o6(t, e), n ? TT = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + t + " not found. Did you forget to load it?"
  )), TT._abbr;
}
function o6(t, e) {
  if (e !== null) {
    var n, r = Hde;
    if (e.abbr = t, Tn[t] != null)
      Ide(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = Tn[t]._config;
    else if (e.parentLocale != null)
      if (Tn[e.parentLocale] != null)
        r = Tn[e.parentLocale]._config;
      else if (n = WD(e.parentLocale), n != null)
        r = n._config;
      else
        return cv[e.parentLocale] || (cv[e.parentLocale] = []), cv[e.parentLocale].push({
          name: t,
          config: e
        }), null;
    return Tn[t] = new JV(JF(r, e)), cv[t] && cv[t].forEach(function(s) {
      o6(s.name, s.config);
    }), gh(t), Tn[t];
  } else
    return delete Tn[t], null;
}
function xyt(t, e) {
  if (e != null) {
    var n, r, s = Hde;
    Tn[t] != null && Tn[t].parentLocale != null ? Tn[t].set(JF(Tn[t]._config, e)) : (r = WD(t), r != null && (s = r._config), e = JF(s, e), r == null && (e.abbr = t), n = new JV(e), n.parentLocale = Tn[t], Tn[t] = n), gh(t);
  } else
    Tn[t] != null && (Tn[t].parentLocale != null ? (Tn[t] = Tn[t].parentLocale, t === gh() && gh(t)) : Tn[t] != null && delete Tn[t]);
  return Tn[t];
}
function Jl(t) {
  var e;
  if (t && t._locale && t._locale._abbr && (t = t._locale._abbr), !t)
    return TT;
  if (!na(t)) {
    if (e = WD(t), e)
      return e;
    t = [t];
  }
  return Ayt(t);
}
function Lyt() {
  return QF(Tn);
}
function a6(t) {
  var e, n = t._a;
  return n && ye(t).overflow === -2 && (e = n[Wc] < 0 || n[Wc] > 11 ? Wc : n[Ta] < 1 || n[Ta] > n6(n[ms], n[Wc]) ? Ta : n[br] < 0 || n[br] > 24 || n[br] === 24 && (n[Yo] !== 0 || n[Bc] !== 0 || n[$d] !== 0) ? br : n[Yo] < 0 || n[Yo] > 59 ? Yo : n[Bc] < 0 || n[Bc] > 59 ? Bc : n[$d] < 0 || n[$d] > 999 ? $d : -1, ye(t)._overflowDayOfYear && (e < ms || e > Ta) && (e = Ta), ye(t)._overflowWeeks && e === -1 && (e = Wgt), ye(t)._overflowWeekday && e === -1 && (e = Bgt), ye(t).overflow = e), t;
}
var Uyt = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Fyt = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Yyt = /Z|[+-]\d\d(?::?\d\d)?/, ES = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], _x = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], Wyt = /^\/?Date\((-?\d+)/i, Byt = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, qyt = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function jde(t) {
  var e, n, r = t._i, s = Uyt.exec(r) || Fyt.exec(r), i, o, a, c, l = ES.length, h = _x.length;
  if (s) {
    for (ye(t).iso = !0, e = 0, n = l; e < n; e++)
      if (ES[e][1].exec(s[1])) {
        o = ES[e][0], i = ES[e][2] !== !1;
        break;
      }
    if (o == null) {
      t._isValid = !1;
      return;
    }
    if (s[3]) {
      for (e = 0, n = h; e < n; e++)
        if (_x[e][1].exec(s[3])) {
          a = (s[2] || " ") + _x[e][0];
          break;
        }
      if (a == null) {
        t._isValid = !1;
        return;
      }
    }
    if (!i && a != null) {
      t._isValid = !1;
      return;
    }
    if (s[4])
      if (Yyt.exec(s[4]))
        c = "Z";
      else {
        t._isValid = !1;
        return;
      }
    t._f = o + (a || "") + (c || ""), l6(t);
  } else
    t._isValid = !1;
}
function Hyt(t, e, n, r, s, i) {
  var o = [
    jyt(t),
    Mde.indexOf(e),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return i && o.push(parseInt(i, 10)), o;
}
function jyt(t) {
  var e = parseInt(t, 10);
  return e <= 49 ? 2e3 + e : e <= 999 ? 1900 + e : e;
}
function Vyt(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function zyt(t, e, n) {
  if (t) {
    var r = Wde.indexOf(t), s = new Date(
      e[0],
      e[1],
      e[2]
    ).getDay();
    if (r !== s)
      return ye(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function Gyt(t, e, n) {
  if (t)
    return qyt[t];
  if (e)
    return 0;
  var r = parseInt(n, 10), s = r % 100, i = (r - s) / 100;
  return i * 60 + s;
}
function Vde(t) {
  var e = Byt.exec(Vyt(t._i)), n;
  if (e) {
    if (n = Hyt(
      e[4],
      e[3],
      e[2],
      e[5],
      e[6],
      e[7]
    ), !zyt(e[1], n, t))
      return;
    t._a = n, t._tzm = Gyt(e[8], e[9], e[10]), t._d = wT.apply(null, t._a), t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), ye(t).rfc2822 = !0;
  } else
    t._isValid = !1;
}
function $yt(t) {
  var e = Wyt.exec(t._i);
  if (e !== null) {
    t._d = /* @__PURE__ */ new Date(+e[1]);
    return;
  }
  if (jde(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  if (Vde(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  t._strict ? t._isValid = !1 : F.createFromInputFallback(t);
}
F.createFromInputFallback = _o(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(t) {
    t._d = /* @__PURE__ */ new Date(t._i + (t._useUTC ? " UTC" : ""));
  }
);
function t_(t, e, n) {
  return t ?? e ?? n;
}
function Kyt(t) {
  var e = new Date(F.now());
  return t._useUTC ? [
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate()
  ] : [e.getFullYear(), e.getMonth(), e.getDate()];
}
function c6(t) {
  var e, n, r = [], s, i, o;
  if (!t._d) {
    for (s = Kyt(t), t._w && t._a[Ta] == null && t._a[Wc] == null && Jyt(t), t._dayOfYear != null && (o = t_(t._a[ms], s[ms]), (t._dayOfYear > Ew(o) || t._dayOfYear === 0) && (ye(t)._overflowDayOfYear = !0), n = wT(o, 0, t._dayOfYear), t._a[Wc] = n.getUTCMonth(), t._a[Ta] = n.getUTCDate()), e = 0; e < 3 && t._a[e] == null; ++e)
      t._a[e] = r[e] = s[e];
    for (; e < 7; e++)
      t._a[e] = r[e] = t._a[e] == null ? e === 2 ? 1 : 0 : t._a[e];
    t._a[br] === 24 && t._a[Yo] === 0 && t._a[Bc] === 0 && t._a[$d] === 0 && (t._nextDay = !0, t._a[br] = 0), t._d = (t._useUTC ? wT : nyt).apply(
      null,
      r
    ), i = t._useUTC ? t._d.getUTCDay() : t._d.getDay(), t._tzm != null && t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), t._nextDay && (t._a[br] = 24), t._w && typeof t._w.d < "u" && t._w.d !== i && (ye(t).weekdayMismatch = !0);
  }
}
function Jyt(t) {
  var e, n, r, s, i, o, a, c, l;
  e = t._w, e.GG != null || e.W != null || e.E != null ? (i = 1, o = 4, n = t_(
    e.GG,
    t._a[ms],
    CT(un(), 1, 4).year
  ), r = t_(e.W, 1), s = t_(e.E, 1), (s < 1 || s > 7) && (c = !0)) : (i = t._locale._week.dow, o = t._locale._week.doy, l = CT(un(), i, o), n = t_(e.gg, t._a[ms], l.year), r = t_(e.w, l.week), e.d != null ? (s = e.d, (s < 0 || s > 6) && (c = !0)) : e.e != null ? (s = e.e + i, (e.e < 0 || e.e > 6) && (c = !0)) : s = i), r < 1 || r > fl(n, i, o) ? ye(t)._overflowWeeks = !0 : c != null ? ye(t)._overflowWeekday = !0 : (a = Yde(n, r, s, i, o), t._a[ms] = a.year, t._dayOfYear = a.dayOfYear);
}
F.ISO_8601 = function() {
};
F.RFC_2822 = function() {
};
function l6(t) {
  if (t._f === F.ISO_8601) {
    jde(t);
    return;
  }
  if (t._f === F.RFC_2822) {
    Vde(t);
    return;
  }
  t._a = [], ye(t).empty = !0;
  var e = "" + t._i, n, r, s, i, o, a = e.length, c = 0, l, h;
  for (s = Rde(t._f, t._locale).match(QV) || [], h = s.length, n = 0; n < h; n++)
    i = s[n], r = (e.match(Ugt(i, t)) || [])[0], r && (o = e.substr(0, e.indexOf(r)), o.length > 0 && ye(t).unusedInput.push(o), e = e.slice(
      e.indexOf(r) + r.length
    ), c += r.length), Q_[i] ? (r ? ye(t).empty = !1 : ye(t).unusedTokens.push(i), Ygt(i, r, t)) : t._strict && !r && ye(t).unusedTokens.push(i);
  ye(t).charsLeftOver = a - c, e.length > 0 && ye(t).unusedInput.push(e), t._a[br] <= 12 && ye(t).bigHour === !0 && t._a[br] > 0 && (ye(t).bigHour = void 0), ye(t).parsedDateParts = t._a.slice(0), ye(t).meridiem = t._meridiem, t._a[br] = Qyt(
    t._locale,
    t._a[br],
    t._meridiem
  ), l = ye(t).era, l !== null && (t._a[ms] = t._locale.erasConvertYear(l, t._a[ms])), c6(t), a6(t);
}
function Qyt(t, e, n) {
  var r;
  return n == null ? e : t.meridiemHour != null ? t.meridiemHour(e, n) : (t.isPM != null && (r = t.isPM(n), r && e < 12 && (e += 12), !r && e === 12 && (e = 0)), e);
}
function Zyt(t) {
  var e, n, r, s, i, o, a = !1, c = t._f.length;
  if (c === 0) {
    ye(t).invalidFormat = !0, t._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < c; s++)
    i = 0, o = !1, e = KV({}, t), t._useUTC != null && (e._useUTC = t._useUTC), e._f = t._f[s], l6(e), $V(e) && (o = !0), i += ye(e).charsLeftOver, i += ye(e).unusedTokens.length * 10, ye(e).score = i, a ? i < r && (r = i, n = e) : (r == null || i < r || o) && (r = i, n = e, o && (a = !0));
  xu(t, n || e);
}
function Xyt(t) {
  if (!t._d) {
    var e = ZV(t._i), n = e.day === void 0 ? e.date : e.day;
    t._a = Ede(
      [e.year, e.month, n, e.hour, e.minute, e.second, e.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), c6(t);
  }
}
function evt(t) {
  var e = new dE(a6(zde(t)));
  return e._nextDay && (e.add(1, "d"), e._nextDay = void 0), e;
}
function zde(t) {
  var e = t._i, n = t._f;
  return t._locale = t._locale || Jl(t._l), e === null || n === void 0 && e === "" ? AD({ nullInput: !0 }) : (typeof e == "string" && (t._i = e = t._locale.preparse(e)), ra(e) ? new dE(a6(e)) : (hE(e) ? t._d = e : na(n) ? Zyt(t) : n ? l6(t) : tvt(t), $V(t) || (t._d = null), t));
}
function tvt(t) {
  var e = t._i;
  si(e) ? t._d = new Date(F.now()) : hE(e) ? t._d = new Date(e.valueOf()) : typeof e == "string" ? $yt(t) : na(e) ? (t._a = Ede(e.slice(0), function(n) {
    return parseInt(n, 10);
  }), c6(t)) : _f(e) ? Xyt(t) : Rl(e) ? t._d = new Date(e) : F.createFromInputFallback(t);
}
function Gde(t, e, n, r, s) {
  var i = {};
  return (e === !0 || e === !1) && (r = e, e = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (_f(t) && GV(t) || na(t) && t.length === 0) && (t = void 0), i._isAMomentObject = !0, i._useUTC = i._isUTC = s, i._l = n, i._i = t, i._f = e, i._strict = r, evt(i);
}
function un(t, e, n, r) {
  return Gde(t, e, n, r, !1);
}
var nvt = _o(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = un.apply(null, arguments);
    return this.isValid() && t.isValid() ? t < this ? this : t : AD();
  }
), rvt = _o(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = un.apply(null, arguments);
    return this.isValid() && t.isValid() ? t > this ? this : t : AD();
  }
);
function $de(t, e) {
  var n, r;
  if (e.length === 1 && na(e[0]) && (e = e[0]), !e.length)
    return un();
  for (n = e[0], r = 1; r < e.length; ++r)
    (!e[r].isValid() || e[r][t](n)) && (n = e[r]);
  return n;
}
function svt() {
  var t = [].slice.call(arguments, 0);
  return $de("isBefore", t);
}
function ivt() {
  var t = [].slice.call(arguments, 0);
  return $de("isAfter", t);
}
var ovt = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, lv = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function avt(t) {
  var e, n = !1, r, s = lv.length;
  for (e in t)
    if (gt(t, e) && !(rr.call(lv, e) !== -1 && (t[e] == null || !isNaN(t[e]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (t[lv[r]]) {
      if (n)
        return !1;
      parseFloat(t[lv[r]]) !== ze(t[lv[r]]) && (n = !0);
    }
  return !0;
}
function cvt() {
  return this._isValid;
}
function lvt() {
  return ua(NaN);
}
function BD(t) {
  var e = ZV(t), n = e.year || 0, r = e.quarter || 0, s = e.month || 0, i = e.week || e.isoWeek || 0, o = e.day || 0, a = e.hour || 0, c = e.minute || 0, l = e.second || 0, h = e.millisecond || 0;
  this._isValid = avt(e), this._milliseconds = +h + l * 1e3 + // 1000
  c * 6e4 + // 1000 * 60
  a * 1e3 * 60 * 60, this._days = +o + i * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = Jl(), this._bubble();
}
function Z0(t) {
  return t instanceof BD;
}
function XF(t) {
  return t < 0 ? Math.round(-1 * t) * -1 : Math.round(t);
}
function uvt(t, e, n) {
  var r = Math.min(t.length, e.length), s = Math.abs(t.length - e.length), i = 0, o;
  for (o = 0; o < r; o++)
    ze(t[o]) !== ze(e[o]) && i++;
  return i + s;
}
function Kde(t, e) {
  re(t, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + Ba(~~(n / 60), 2) + e + Ba(~~n % 60, 2);
  });
}
Kde("Z", ":");
Kde("ZZ", "");
j("Z", FD);
j("ZZ", FD);
qt(["Z", "ZZ"], function(t, e, n) {
  n._useUTC = !0, n._tzm = u6(FD, t);
});
var hvt = /([\+\-]|\d\d)/gi;
function u6(t, e) {
  var n = (e || "").match(t), r, s, i;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(hvt) || ["-", 0, 0], i = +(s[1] * 60) + ze(s[2]), i === 0 ? 0 : s[0] === "+" ? i : -i);
}
function h6(t, e) {
  var n, r;
  return e._isUTC ? (n = e.clone(), r = (ra(t) || hE(t) ? t.valueOf() : un(t).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), F.updateOffset(n, !1), n) : un(t).local();
}
function eY(t) {
  return -Math.round(t._d.getTimezoneOffset());
}
F.updateOffset = function() {
};
function dvt(t, e, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    if (typeof t == "string") {
      if (t = u6(FD, t), t === null)
        return this;
    } else Math.abs(t) < 16 && !n && (t = t * 60);
    return !this._isUTC && e && (s = eY(this)), this._offset = t, this._isUTC = !0, s != null && this.add(s, "m"), r !== t && (!e || this._changeInProgress ? Zde(
      this,
      ua(t - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, F.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : eY(this);
}
function fvt(t, e) {
  return t != null ? (typeof t != "string" && (t = -t), this.utcOffset(t, e), this) : -this.utcOffset();
}
function pvt(t) {
  return this.utcOffset(0, t);
}
function _vt(t) {
  return this._isUTC && (this.utcOffset(0, t), this._isUTC = !1, t && this.subtract(eY(this), "m")), this;
}
function mvt() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var t = u6(xgt, this._i);
    t != null ? this.utcOffset(t) : this.utcOffset(0, !0);
  }
  return this;
}
function gvt(t) {
  return this.isValid() ? (t = t ? un(t).utcOffset() : 0, (this.utcOffset() - t) % 60 === 0) : !1;
}
function yvt() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function vvt() {
  if (!si(this._isDSTShifted))
    return this._isDSTShifted;
  var t = {}, e;
  return KV(t, this), t = zde(t), t._a ? (e = t._isUTC ? sc(t._a) : un(t._a), this._isDSTShifted = this.isValid() && uvt(t._a, e.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function wvt() {
  return this.isValid() ? !this._isUTC : !1;
}
function Cvt() {
  return this.isValid() ? this._isUTC : !1;
}
function Jde() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var Tvt = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, bvt = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function ua(t, e) {
  var n = t, r = null, s, i, o;
  return Z0(t) ? n = {
    ms: t._milliseconds,
    d: t._days,
    M: t._months
  } : Rl(t) || !isNaN(+t) ? (n = {}, e ? n[e] = +t : n.milliseconds = +t) : (r = Tvt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: ze(r[Ta]) * s,
    h: ze(r[br]) * s,
    m: ze(r[Yo]) * s,
    s: ze(r[Bc]) * s,
    ms: ze(XF(r[$d] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = bvt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: gd(r[2], s),
    M: gd(r[3], s),
    w: gd(r[4], s),
    d: gd(r[5], s),
    h: gd(r[6], s),
    m: gd(r[7], s),
    s: gd(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (o = Evt(
    un(n.from),
    un(n.to)
  ), n = {}, n.ms = o.milliseconds, n.M = o.months), i = new BD(n), Z0(t) && gt(t, "_locale") && (i._locale = t._locale), Z0(t) && gt(t, "_isValid") && (i._isValid = t._isValid), i;
}
ua.fn = BD.prototype;
ua.invalid = lvt;
function gd(t, e) {
  var n = t && parseFloat(t.replace(",", "."));
  return (isNaN(n) ? 0 : n) * e;
}
function j8(t, e) {
  var n = {};
  return n.months = e.month() - t.month() + (e.year() - t.year()) * 12, t.clone().add(n.months, "M").isAfter(e) && --n.months, n.milliseconds = +e - +t.clone().add(n.months, "M"), n;
}
function Evt(t, e) {
  var n;
  return t.isValid() && e.isValid() ? (e = h6(e, t), t.isBefore(e) ? n = j8(t, e) : (n = j8(e, t), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function Qde(t, e) {
  return function(n, r) {
    var s, i;
    return r !== null && !isNaN(+r) && (Ide(
      e,
      "moment()." + e + "(period, number) is deprecated. Please use moment()." + e + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), i = n, n = r, r = i), s = ua(n, r), Zde(this, s, t), this;
  };
}
function Zde(t, e, n, r) {
  var s = e._milliseconds, i = XF(e._days), o = XF(e._months);
  t.isValid() && (r = r ?? !0, o && Lde(t, vT(t, "Month") + o * n), i && Ade(t, "Date", vT(t, "Date") + i * n), s && t._d.setTime(t._d.valueOf() + s * n), r && F.updateOffset(t, i || o));
}
var Svt = Qde(1, "add"), Ivt = Qde(-1, "subtract");
function Xde(t) {
  return typeof t == "string" || t instanceof String;
}
function Rvt(t) {
  return ra(t) || hE(t) || Xde(t) || Rl(t) || Nvt(t) || kvt(t) || t === null || t === void 0;
}
function kvt(t) {
  var e = _f(t) && !GV(t), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, i, o = r.length;
  for (s = 0; s < o; s += 1)
    i = r[s], n = n || gt(t, i);
  return e && n;
}
function Nvt(t) {
  var e = na(t), n = !1;
  return e && (n = t.filter(function(r) {
    return !Rl(r) && Xde(t);
  }).length === 0), e && n;
}
function Pvt(t) {
  var e = _f(t) && !GV(t), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, i;
  for (s = 0; s < r.length; s += 1)
    i = r[s], n = n || gt(t, i);
  return e && n;
}
function Ovt(t, e) {
  var n = t.diff(e, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function Dvt(t, e) {
  arguments.length === 1 && (arguments[0] ? Rvt(arguments[0]) ? (t = arguments[0], e = void 0) : Pvt(arguments[0]) && (e = arguments[0], t = void 0) : (t = void 0, e = void 0));
  var n = t || un(), r = h6(n, this).startOf("day"), s = F.calendarFormat(this, r) || "sameElse", i = e && (ic(e[s]) ? e[s].call(this, n) : e[s]);
  return this.format(
    i || this.localeData().calendar(s, this, un(n))
  );
}
function Avt() {
  return new dE(this);
}
function Mvt(t, e) {
  var n = ra(t) ? t : un(t);
  return this.isValid() && n.isValid() ? (e = mo(e) || "millisecond", e === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(e).valueOf()) : !1;
}
function xvt(t, e) {
  var n = ra(t) ? t : un(t);
  return this.isValid() && n.isValid() ? (e = mo(e) || "millisecond", e === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(e).valueOf() < n.valueOf()) : !1;
}
function Lvt(t, e, n, r) {
  var s = ra(t) ? t : un(t), i = ra(e) ? e : un(e);
  return this.isValid() && s.isValid() && i.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(i, n) : !this.isAfter(i, n))) : !1;
}
function Uvt(t, e) {
  var n = ra(t) ? t : un(t), r;
  return this.isValid() && n.isValid() ? (e = mo(e) || "millisecond", e === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(e).valueOf() <= r && r <= this.clone().endOf(e).valueOf())) : !1;
}
function Fvt(t, e) {
  return this.isSame(t, e) || this.isAfter(t, e);
}
function Yvt(t, e) {
  return this.isSame(t, e) || this.isBefore(t, e);
}
function Wvt(t, e, n) {
  var r, s, i;
  if (!this.isValid())
    return NaN;
  if (r = h6(t, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, e = mo(e), e) {
    case "year":
      i = X0(this, r) / 12;
      break;
    case "month":
      i = X0(this, r);
      break;
    case "quarter":
      i = X0(this, r) / 3;
      break;
    case "second":
      i = (this - r) / 1e3;
      break;
    case "minute":
      i = (this - r) / 6e4;
      break;
    case "hour":
      i = (this - r) / 36e5;
      break;
    case "day":
      i = (this - r - s) / 864e5;
      break;
    case "week":
      i = (this - r - s) / 6048e5;
      break;
    default:
      i = this - r;
  }
  return n ? i : Hi(i);
}
function X0(t, e) {
  if (t.date() < e.date())
    return -X0(e, t);
  var n = (e.year() - t.year()) * 12 + (e.month() - t.month()), r = t.clone().add(n, "months"), s, i;
  return e - r < 0 ? (s = t.clone().add(n - 1, "months"), i = (e - r) / (r - s)) : (s = t.clone().add(n + 1, "months"), i = (e - r) / (s - r)), -(n + i) || 0;
}
F.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
F.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function Bvt() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function qvt(t) {
  if (!this.isValid())
    return null;
  var e = t !== !0, n = e ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? Q0(
    n,
    e ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : ic(Date.prototype.toISOString) ? e ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", Q0(n, "Z")) : Q0(
    n,
    e ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function Hvt() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var t = "moment", e = "", n, r, s, i;
  return this.isLocal() || (t = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", e = "Z"), n = "[" + t + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", i = e + '[")]', this.format(n + r + s + i);
}
function jvt(t) {
  t || (t = this.isUtc() ? F.defaultFormatUtc : F.defaultFormat);
  var e = Q0(this, t);
  return this.localeData().postformat(e);
}
function Vvt(t, e) {
  return this.isValid() && (ra(t) && t.isValid() || un(t).isValid()) ? ua({ to: this, from: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function zvt(t) {
  return this.from(un(), t);
}
function Gvt(t, e) {
  return this.isValid() && (ra(t) && t.isValid() || un(t).isValid()) ? ua({ from: this, to: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function $vt(t) {
  return this.to(un(), t);
}
function efe(t) {
  var e;
  return t === void 0 ? this._locale._abbr : (e = Jl(t), e != null && (this._locale = e), this);
}
var tfe = _o(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(t) {
    return t === void 0 ? this.localeData() : this.locale(t);
  }
);
function nfe() {
  return this._locale;
}
var CN = 1e3, Z_ = 60 * CN, TN = 60 * Z_, rfe = (365 * 400 + 97) * 24 * TN;
function X_(t, e) {
  return (t % e + e) % e;
}
function sfe(t, e, n) {
  return t < 100 && t >= 0 ? new Date(t + 400, e, n) - rfe : new Date(t, e, n).valueOf();
}
function ife(t, e, n) {
  return t < 100 && t >= 0 ? Date.UTC(t + 400, e, n) - rfe : Date.UTC(t, e, n);
}
function Kvt(t) {
  var e, n;
  if (t = mo(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? ife : sfe, t) {
    case "year":
      e = n(this.year(), 0, 1);
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      e = n(this.year(), this.month(), 1);
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      e = this._d.valueOf(), e -= X_(
        e + (this._isUTC ? 0 : this.utcOffset() * Z_),
        TN
      );
      break;
    case "minute":
      e = this._d.valueOf(), e -= X_(e, Z_);
      break;
    case "second":
      e = this._d.valueOf(), e -= X_(e, CN);
      break;
  }
  return this._d.setTime(e), F.updateOffset(this, !0), this;
}
function Jvt(t) {
  var e, n;
  if (t = mo(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? ife : sfe, t) {
    case "year":
      e = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      e = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      e = this._d.valueOf(), e += TN - X_(
        e + (this._isUTC ? 0 : this.utcOffset() * Z_),
        TN
      ) - 1;
      break;
    case "minute":
      e = this._d.valueOf(), e += Z_ - X_(e, Z_) - 1;
      break;
    case "second":
      e = this._d.valueOf(), e += CN - X_(e, CN) - 1;
      break;
  }
  return this._d.setTime(e), F.updateOffset(this, !0), this;
}
function Qvt() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function Zvt() {
  return Math.floor(this.valueOf() / 1e3);
}
function Xvt() {
  return new Date(this.valueOf());
}
function ewt() {
  var t = this;
  return [
    t.year(),
    t.month(),
    t.date(),
    t.hour(),
    t.minute(),
    t.second(),
    t.millisecond()
  ];
}
function twt() {
  var t = this;
  return {
    years: t.year(),
    months: t.month(),
    date: t.date(),
    hours: t.hours(),
    minutes: t.minutes(),
    seconds: t.seconds(),
    milliseconds: t.milliseconds()
  };
}
function nwt() {
  return this.isValid() ? this.toISOString() : null;
}
function rwt() {
  return $V(this);
}
function swt() {
  return xu({}, ye(this));
}
function iwt() {
  return ye(this).overflow;
}
function owt() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
re("N", 0, 0, "eraAbbr");
re("NN", 0, 0, "eraAbbr");
re("NNN", 0, 0, "eraAbbr");
re("NNNN", 0, 0, "eraName");
re("NNNNN", 0, 0, "eraNarrow");
re("y", ["y", 1], "yo", "eraYear");
re("y", ["yy", 2], 0, "eraYear");
re("y", ["yyy", 3], 0, "eraYear");
re("y", ["yyyy", 4], 0, "eraYear");
j("N", d6);
j("NN", d6);
j("NNN", d6);
j("NNNN", gwt);
j("NNNNN", ywt);
qt(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(t, e, n, r) {
    var s = n._locale.erasParse(t, r, n._strict);
    s ? ye(n).era = s : ye(n).invalidEra = t;
  }
);
j("y", Yg);
j("yy", Yg);
j("yyy", Yg);
j("yyyy", Yg);
j("yo", vwt);
qt(["y", "yy", "yyy", "yyyy"], ms);
qt(["yo"], function(t, e, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = t.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? e[ms] = n._locale.eraYearOrdinalParse(t, s) : e[ms] = parseInt(t, 10);
});
function awt(t, e) {
  var n, r, s, i = this._eras || Jl("en")._eras;
  for (n = 0, r = i.length; n < r; ++n) {
    switch (typeof i[n].since) {
      case "string":
        s = F(i[n].since).startOf("day"), i[n].since = s.valueOf();
        break;
    }
    switch (typeof i[n].until) {
      case "undefined":
        i[n].until = 1 / 0;
        break;
      case "string":
        s = F(i[n].until).startOf("day").valueOf(), i[n].until = s.valueOf();
        break;
    }
  }
  return i;
}
function cwt(t, e, n) {
  var r, s, i = this.eras(), o, a, c;
  for (t = t.toUpperCase(), r = 0, s = i.length; r < s; ++r)
    if (o = i[r].name.toUpperCase(), a = i[r].abbr.toUpperCase(), c = i[r].narrow.toUpperCase(), n)
      switch (e) {
        case "N":
        case "NN":
        case "NNN":
          if (a === t)
            return i[r];
          break;
        case "NNNN":
          if (o === t)
            return i[r];
          break;
        case "NNNNN":
          if (c === t)
            return i[r];
          break;
      }
    else if ([o, a, c].indexOf(t) >= 0)
      return i[r];
}
function lwt(t, e) {
  var n = t.since <= t.until ? 1 : -1;
  return e === void 0 ? F(t.since).year() : F(t.since).year() + (e - t.offset) * n;
}
function uwt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].name;
  return "";
}
function hwt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].narrow;
  return "";
}
function dwt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].abbr;
  return "";
}
function fwt() {
  var t, e, n, r, s = this.localeData().eras();
  for (t = 0, e = s.length; t < e; ++t)
    if (n = s[t].since <= s[t].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[t].since <= r && r <= s[t].until || s[t].until <= r && r <= s[t].since)
      return (this.year() - F(s[t].since).year()) * n + s[t].offset;
  return this.year();
}
function pwt(t) {
  return gt(this, "_erasNameRegex") || f6.call(this), t ? this._erasNameRegex : this._erasRegex;
}
function _wt(t) {
  return gt(this, "_erasAbbrRegex") || f6.call(this), t ? this._erasAbbrRegex : this._erasRegex;
}
function mwt(t) {
  return gt(this, "_erasNarrowRegex") || f6.call(this), t ? this._erasNarrowRegex : this._erasRegex;
}
function d6(t, e) {
  return e.erasAbbrRegex(t);
}
function gwt(t, e) {
  return e.erasNameRegex(t);
}
function ywt(t, e) {
  return e.erasNarrowRegex(t);
}
function vwt(t, e) {
  return e._eraYearOrdinalRegex || Yg;
}
function f6() {
  var t = [], e = [], n = [], r = [], s, i, o, a, c, l = this.eras();
  for (s = 0, i = l.length; s < i; ++s)
    o = dl(l[s].name), a = dl(l[s].abbr), c = dl(l[s].narrow), e.push(o), t.push(a), n.push(c), r.push(o), r.push(a), r.push(c);
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
re(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
re(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function qD(t, e) {
  re(0, [t, t.length], 0, e);
}
qD("gggg", "weekYear");
qD("ggggg", "weekYear");
qD("GGGG", "isoWeekYear");
qD("GGGGG", "isoWeekYear");
j("G", UD);
j("g", UD);
j("GG", mn, Oi);
j("gg", mn, Oi);
j("GGGG", e6, XV);
j("gggg", e6, XV);
j("GGGGG", LD, MD);
j("ggggg", LD, MD);
pE(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(t, e, n, r) {
    e[r.substr(0, 2)] = ze(t);
  }
);
pE(["gg", "GG"], function(t, e, n, r) {
  e[r] = F.parseTwoDigitYear(t);
});
function wwt(t) {
  return ofe.call(
    this,
    t,
    this.week(),
    this.weekday() + this.localeData()._week.dow,
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function Cwt(t) {
  return ofe.call(
    this,
    t,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function Twt() {
  return fl(this.year(), 1, 4);
}
function bwt() {
  return fl(this.isoWeekYear(), 1, 4);
}
function Ewt() {
  var t = this.localeData()._week;
  return fl(this.year(), t.dow, t.doy);
}
function Swt() {
  var t = this.localeData()._week;
  return fl(this.weekYear(), t.dow, t.doy);
}
function ofe(t, e, n, r, s) {
  var i;
  return t == null ? CT(this, r, s).year : (i = fl(t, r, s), e > i && (e = i), Iwt.call(this, t, e, n, r, s));
}
function Iwt(t, e, n, r, s) {
  var i = Yde(t, e, n, r, s), o = wT(i.year, 0, i.dayOfYear);
  return this.year(o.getUTCFullYear()), this.month(o.getUTCMonth()), this.date(o.getUTCDate()), this;
}
re("Q", 0, "Qo", "quarter");
j("Q", kde);
qt("Q", function(t, e) {
  e[Wc] = (ze(t) - 1) * 3;
});
function Rwt(t) {
  return t == null ? Math.ceil((this.month() + 1) / 3) : this.month((t - 1) * 3 + this.month() % 3);
}
re("D", ["DD", 2], "Do", "date");
j("D", mn, Wg);
j("DD", mn, Oi);
j("Do", function(t, e) {
  return t ? e._dayOfMonthOrdinalParse || e._ordinalParse : e._dayOfMonthOrdinalParseLenient;
});
qt(["D", "DD"], Ta);
qt("Do", function(t, e) {
  e[Ta] = ze(t.match(mn)[0]);
});
var afe = Bg("Date", !0);
re("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
j("DDD", xD);
j("DDDD", Nde);
qt(["DDD", "DDDD"], function(t, e, n) {
  n._dayOfYear = ze(t);
});
function kwt(t) {
  var e = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return t == null ? e : this.add(t - e, "d");
}
re("m", ["mm", 2], 0, "minute");
j("m", mn, t6);
j("mm", mn, Oi);
qt(["m", "mm"], Yo);
var Nwt = Bg("Minutes", !1);
re("s", ["ss", 2], 0, "second");
j("s", mn, t6);
j("ss", mn, Oi);
qt(["s", "ss"], Bc);
var Pwt = Bg("Seconds", !1);
re("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
re(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
re(0, ["SSS", 3], 0, "millisecond");
re(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
re(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
re(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
re(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
re(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
re(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
j("S", xD, kde);
j("SS", xD, Oi);
j("SSS", xD, Nde);
var Lu, cfe;
for (Lu = "SSSS"; Lu.length <= 9; Lu += "S")
  j(Lu, Yg);
function Owt(t, e) {
  e[$d] = ze(("0." + t) * 1e3);
}
for (Lu = "S"; Lu.length <= 9; Lu += "S")
  qt(Lu, Owt);
cfe = Bg("Milliseconds", !1);
re("z", 0, 0, "zoneAbbr");
re("zz", 0, 0, "zoneName");
function Dwt() {
  return this._isUTC ? "UTC" : "";
}
function Awt() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var S = dE.prototype;
S.add = Svt;
S.calendar = Dvt;
S.clone = Avt;
S.diff = Wvt;
S.endOf = Jvt;
S.format = jvt;
S.from = Vvt;
S.fromNow = zvt;
S.to = Gvt;
S.toNow = $vt;
S.get = Hgt;
S.invalidAt = iwt;
S.isAfter = Mvt;
S.isBefore = xvt;
S.isBetween = Lvt;
S.isSame = Uvt;
S.isSameOrAfter = Fvt;
S.isSameOrBefore = Yvt;
S.isValid = rwt;
S.lang = tfe;
S.locale = efe;
S.localeData = nfe;
S.max = rvt;
S.min = nvt;
S.parsingFlags = swt;
S.set = jgt;
S.startOf = Kvt;
S.subtract = Ivt;
S.toArray = ewt;
S.toObject = twt;
S.toDate = Xvt;
S.toISOString = qvt;
S.inspect = Hvt;
typeof Symbol < "u" && Symbol.for != null && (S[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
S.toJSON = nwt;
S.toString = Bvt;
S.unix = Zvt;
S.valueOf = Qvt;
S.creationData = owt;
S.eraName = uwt;
S.eraNarrow = hwt;
S.eraAbbr = dwt;
S.eraYear = fwt;
S.year = Dde;
S.isLeapYear = qgt;
S.weekYear = wwt;
S.isoWeekYear = Cwt;
S.quarter = S.quarters = Rwt;
S.month = Ude;
S.daysInMonth = Xgt;
S.week = S.weeks = ayt;
S.isoWeek = S.isoWeeks = cyt;
S.weeksInYear = Ewt;
S.weeksInWeekYear = Swt;
S.isoWeeksInYear = Twt;
S.isoWeeksInISOWeekYear = bwt;
S.date = afe;
S.day = S.days = Cyt;
S.weekday = Tyt;
S.isoWeekday = byt;
S.dayOfYear = kwt;
S.hour = S.hours = Pyt;
S.minute = S.minutes = Nwt;
S.second = S.seconds = Pwt;
S.millisecond = S.milliseconds = cfe;
S.utcOffset = dvt;
S.utc = pvt;
S.local = _vt;
S.parseZone = mvt;
S.hasAlignedHourOffset = gvt;
S.isDST = yvt;
S.isLocal = wvt;
S.isUtcOffset = Cvt;
S.isUtc = Jde;
S.isUTC = Jde;
S.zoneAbbr = Dwt;
S.zoneName = Awt;
S.dates = _o(
  "dates accessor is deprecated. Use date instead.",
  afe
);
S.months = _o(
  "months accessor is deprecated. Use month instead",
  Ude
);
S.years = _o(
  "years accessor is deprecated. Use year instead",
  Dde
);
S.zone = _o(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  fvt
);
S.isDSTShifted = _o(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  vvt
);
function Mwt(t) {
  return un(t * 1e3);
}
function xwt() {
  return un.apply(null, arguments).parseZone();
}
function lfe(t) {
  return t;
}
var bt = JV.prototype;
bt.calendar = wgt;
bt.longDateFormat = Egt;
bt.invalidDate = Igt;
bt.ordinal = Ngt;
bt.preparse = lfe;
bt.postformat = lfe;
bt.relativeTime = Ogt;
bt.pastFuture = Dgt;
bt.set = ygt;
bt.eras = awt;
bt.erasParse = cwt;
bt.erasConvertYear = lwt;
bt.erasAbbrRegex = _wt;
bt.erasNameRegex = pwt;
bt.erasNarrowRegex = mwt;
bt.months = Kgt;
bt.monthsShort = Jgt;
bt.monthsParse = Zgt;
bt.monthsRegex = tyt;
bt.monthsShortRegex = eyt;
bt.week = ryt;
bt.firstDayOfYear = oyt;
bt.firstDayOfWeek = iyt;
bt.weekdays = myt;
bt.weekdaysMin = yyt;
bt.weekdaysShort = gyt;
bt.weekdaysParse = wyt;
bt.weekdaysRegex = Eyt;
bt.weekdaysShortRegex = Syt;
bt.weekdaysMinRegex = Iyt;
bt.isPM = kyt;
bt.meridiem = Oyt;
function bN(t, e, n, r) {
  var s = Jl(), i = sc().set(r, e);
  return s[n](i, t);
}
function ufe(t, e, n) {
  if (Rl(t) && (e = t, t = void 0), t = t || "", e != null)
    return bN(t, e, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = bN(t, r, n, "month");
  return s;
}
function p6(t, e, n, r) {
  typeof t == "boolean" ? (Rl(e) && (n = e, e = void 0), e = e || "") : (e = t, n = e, t = !1, Rl(e) && (n = e, e = void 0), e = e || "");
  var s = Jl(), i = t ? s._week.dow : 0, o, a = [];
  if (n != null)
    return bN(e, (n + i) % 7, r, "day");
  for (o = 0; o < 7; o++)
    a[o] = bN(e, (o + i) % 7, r, "day");
  return a;
}
function Lwt(t, e) {
  return ufe(t, e, "months");
}
function Uwt(t, e) {
  return ufe(t, e, "monthsShort");
}
function Fwt(t, e, n) {
  return p6(t, e, n, "weekdays");
}
function Ywt(t, e, n) {
  return p6(t, e, n, "weekdaysShort");
}
function Wwt(t, e, n) {
  return p6(t, e, n, "weekdaysMin");
}
gh("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(t) {
    var e = t % 10, n = ze(t % 100 / 10) === 1 ? "th" : e === 1 ? "st" : e === 2 ? "nd" : e === 3 ? "rd" : "th";
    return t + n;
  }
});
F.lang = _o(
  "moment.lang is deprecated. Use moment.locale instead.",
  gh
);
F.langData = _o(
  "moment.langData is deprecated. Use moment.localeData instead.",
  Jl
);
var fc = Math.abs;
function Bwt() {
  var t = this._data;
  return this._milliseconds = fc(this._milliseconds), this._days = fc(this._days), this._months = fc(this._months), t.milliseconds = fc(t.milliseconds), t.seconds = fc(t.seconds), t.minutes = fc(t.minutes), t.hours = fc(t.hours), t.months = fc(t.months), t.years = fc(t.years), this;
}
function hfe(t, e, n, r) {
  var s = ua(e, n);
  return t._milliseconds += r * s._milliseconds, t._days += r * s._days, t._months += r * s._months, t._bubble();
}
function qwt(t, e) {
  return hfe(this, t, e, 1);
}
function Hwt(t, e) {
  return hfe(this, t, e, -1);
}
function V8(t) {
  return t < 0 ? Math.floor(t) : Math.ceil(t);
}
function jwt() {
  var t = this._milliseconds, e = this._days, n = this._months, r = this._data, s, i, o, a, c;
  return t >= 0 && e >= 0 && n >= 0 || t <= 0 && e <= 0 && n <= 0 || (t += V8(tY(n) + e) * 864e5, e = 0, n = 0), r.milliseconds = t % 1e3, s = Hi(t / 1e3), r.seconds = s % 60, i = Hi(s / 60), r.minutes = i % 60, o = Hi(i / 60), r.hours = o % 24, e += Hi(o / 24), c = Hi(dfe(e)), n += c, e -= V8(tY(c)), a = Hi(n / 12), n %= 12, r.days = e, r.months = n, r.years = a, this;
}
function dfe(t) {
  return t * 4800 / 146097;
}
function tY(t) {
  return t * 146097 / 4800;
}
function Vwt(t) {
  if (!this.isValid())
    return NaN;
  var e, n, r = this._milliseconds;
  if (t = mo(t), t === "month" || t === "quarter" || t === "year")
    switch (e = this._days + r / 864e5, n = this._months + dfe(e), t) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (e = this._days + Math.round(tY(this._months)), t) {
      case "week":
        return e / 7 + r / 6048e5;
      case "day":
        return e + r / 864e5;
      case "hour":
        return e * 24 + r / 36e5;
      case "minute":
        return e * 1440 + r / 6e4;
      case "second":
        return e * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(e * 864e5) + r;
      default:
        throw new Error("Unknown unit " + t);
    }
}
function Ql(t) {
  return function() {
    return this.as(t);
  };
}
var ffe = Ql("ms"), zwt = Ql("s"), Gwt = Ql("m"), $wt = Ql("h"), Kwt = Ql("d"), Jwt = Ql("w"), Qwt = Ql("M"), Zwt = Ql("Q"), Xwt = Ql("y"), eCt = ffe;
function tCt() {
  return ua(this);
}
function nCt(t) {
  return t = mo(t), this.isValid() ? this[t + "s"]() : NaN;
}
function kp(t) {
  return function() {
    return this.isValid() ? this._data[t] : NaN;
  };
}
var rCt = kp("milliseconds"), sCt = kp("seconds"), iCt = kp("minutes"), oCt = kp("hours"), aCt = kp("days"), cCt = kp("months"), lCt = kp("years");
function uCt() {
  return Hi(this.days() / 7);
}
var vc = Math.round, f_ = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function hCt(t, e, n, r, s) {
  return s.relativeTime(e || 1, !!n, t, r);
}
function dCt(t, e, n, r) {
  var s = ua(t).abs(), i = vc(s.as("s")), o = vc(s.as("m")), a = vc(s.as("h")), c = vc(s.as("d")), l = vc(s.as("M")), h = vc(s.as("w")), u = vc(s.as("y")), d = i <= n.ss && ["s", i] || i < n.s && ["ss", i] || o <= 1 && ["m"] || o < n.m && ["mm", o] || a <= 1 && ["h"] || a < n.h && ["hh", a] || c <= 1 && ["d"] || c < n.d && ["dd", c];
  return n.w != null && (d = d || h <= 1 && ["w"] || h < n.w && ["ww", h]), d = d || l <= 1 && ["M"] || l < n.M && ["MM", l] || u <= 1 && ["y"] || ["yy", u], d[2] = e, d[3] = +t > 0, d[4] = r, hCt.apply(null, d);
}
function fCt(t) {
  return t === void 0 ? vc : typeof t == "function" ? (vc = t, !0) : !1;
}
function pCt(t, e) {
  return f_[t] === void 0 ? !1 : e === void 0 ? f_[t] : (f_[t] = e, t === "s" && (f_.ss = e - 1), !0);
}
function _Ct(t, e) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = f_, s, i;
  return typeof t == "object" && (e = t, t = !1), typeof t == "boolean" && (n = t), typeof e == "object" && (r = Object.assign({}, f_, e), e.s != null && e.ss == null && (r.ss = e.s - 1)), s = this.localeData(), i = dCt(this, !n, r, s), n && (i = s.pastFuture(+this, i)), s.postformat(i);
}
var mx = Math.abs;
function Hp(t) {
  return (t > 0) - (t < 0) || +t;
}
function HD() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var t = mx(this._milliseconds) / 1e3, e = mx(this._days), n = mx(this._months), r, s, i, o, a = this.asSeconds(), c, l, h, u;
  return a ? (r = Hi(t / 60), s = Hi(r / 60), t %= 60, r %= 60, i = Hi(n / 12), n %= 12, o = t ? t.toFixed(3).replace(/\.?0+$/, "") : "", c = a < 0 ? "-" : "", l = Hp(this._months) !== Hp(a) ? "-" : "", h = Hp(this._days) !== Hp(a) ? "-" : "", u = Hp(this._milliseconds) !== Hp(a) ? "-" : "", c + "P" + (i ? l + i + "Y" : "") + (n ? l + n + "M" : "") + (e ? h + e + "D" : "") + (s || r || t ? "T" : "") + (s ? u + s + "H" : "") + (r ? u + r + "M" : "") + (t ? u + o + "S" : "")) : "P0D";
}
var Ze = BD.prototype;
Ze.isValid = cvt;
Ze.abs = Bwt;
Ze.add = qwt;
Ze.subtract = Hwt;
Ze.as = Vwt;
Ze.asMilliseconds = ffe;
Ze.asSeconds = zwt;
Ze.asMinutes = Gwt;
Ze.asHours = $wt;
Ze.asDays = Kwt;
Ze.asWeeks = Jwt;
Ze.asMonths = Qwt;
Ze.asQuarters = Zwt;
Ze.asYears = Xwt;
Ze.valueOf = eCt;
Ze._bubble = jwt;
Ze.clone = tCt;
Ze.get = nCt;
Ze.milliseconds = rCt;
Ze.seconds = sCt;
Ze.minutes = iCt;
Ze.hours = oCt;
Ze.days = aCt;
Ze.weeks = uCt;
Ze.months = cCt;
Ze.years = lCt;
Ze.humanize = _Ct;
Ze.toISOString = HD;
Ze.toString = HD;
Ze.toJSON = HD;
Ze.locale = efe;
Ze.localeData = nfe;
Ze.toIsoString = _o(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  HD
);
Ze.lang = tfe;
re("X", 0, 0, "unix");
re("x", 0, 0, "valueOf");
j("x", UD);
j("X", Lgt);
qt("X", function(t, e, n) {
  n._d = new Date(parseFloat(t) * 1e3);
});
qt("x", function(t, e, n) {
  n._d = new Date(ze(t));
});
F.version = "2.30.1";
mgt(un);
F.fn = S;
F.min = svt;
F.max = ivt;
F.now = ovt;
F.utc = sc;
F.unix = Mwt;
F.months = Lwt;
F.isDate = hE;
F.locale = gh;
F.invalid = AD;
F.duration = ua;
F.isMoment = ra;
F.weekdays = Fwt;
F.parseZone = xwt;
F.localeData = Jl;
F.isDuration = Z0;
F.monthsShort = Uwt;
F.weekdaysMin = Wwt;
F.defineLocale = o6;
F.updateLocale = xyt;
F.locales = Lyt;
F.weekdaysShort = Ywt;
F.normalizeUnits = mo;
F.relativeTimeRounding = fCt;
F.relativeTimeThreshold = pCt;
F.calendarFormat = Ovt;
F.prototype = S;
F.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
function pfe(t, e) {
  var n = {};
  for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function _fe() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const mCt = _fe, mfe = new HT("auth", "Firebase", _fe()), EN = new r2("@firebase/auth");
function gCt(t, ...e) {
  EN.logLevel <= It.WARN && EN.warn(`Auth (${jT}): ${t}`, ...e);
}
function eI(t, ...e) {
  EN.logLevel <= It.ERROR && EN.error(`Auth (${jT}): ${t}`, ...e);
}
function bT(t, ...e) {
  throw m6(t, ...e);
}
function _6(t, ...e) {
  return m6(t, ...e);
}
function yCt(t, e, n) {
  const r = Object.assign(Object.assign({}, mCt()), { [e]: n });
  return new HT("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function m6(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return mfe.create(t, ...e);
}
function st(t, e, ...n) {
  if (!t)
    throw m6(e, ...n);
}
function qc(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw eI(e), new Error(e);
}
function SN(t, e) {
  t || qc(e);
}
function vCt() {
  return z8() === "http:" || z8() === "https:";
}
function z8() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
function wCt() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (vCt() || ACe() || "connection" in navigator) ? navigator.onLine : !0;
}
function CCt() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
class _E {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, SN(n > e, "Short delay should be less than long delay!"), this.isMobile = t2() || yQ();
  }
  get() {
    return wCt() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
function TCt(t, e) {
  SN(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
class gfe {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    qc("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    qc("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    qc("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
const bCt = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
}, ECt = new _E(3e4, 6e4);
function mE(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Np(t, e, n, r, s = {}) {
  return yfe(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = n2(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), gfe.fetch()(vfe(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function yfe(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, bCt), e);
  try {
    const s = new SCt(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw SS(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, l] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw SS(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw SS(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw SS(t, "user-disabled", o);
      const h = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (l)
        throw yCt(t, h, l);
      bT(t, h);
    }
  } catch (s) {
    if (s instanceof Ol)
      throw s;
    bT(t, "network-request-failed", { message: String(s) });
  }
}
async function g6(t, e, n, r, s = {}) {
  const i = await Np(t, e, n, r, s);
  return "mfaPendingCredential" in i && bT(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function vfe(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? TCt(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class SCt {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(_6(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), ECt.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function SS(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = _6(t, e, r);
  return s.customData._tokenResponse = n, s;
}
async function ICt(t, e) {
  return Np(t, "POST", "/v1/accounts:delete", e);
}
async function RCt(t, e) {
  return Np(t, "POST", "/v1/accounts:lookup", e);
}
function Sw(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function kCt(t, e = !1) {
  const n = as(t), r = await n.getIdToken(e), s = wfe(r);
  st(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: Sw(gx(s.auth_time)),
    issuedAtTime: Sw(gx(s.iat)),
    expirationTime: Sw(gx(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function gx(t) {
  return Number(t) * 1e3;
}
function wfe(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return eI("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = AI(n);
    return s ? JSON.parse(s) : (eI("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return eI("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function NCt(t) {
  const e = wfe(t);
  return st(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), st(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), st(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
async function IN(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof Ol && PCt(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function PCt({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
class OCt {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const r = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, r);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
class Cfe {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = Sw(this.lastLoginAt), this.creationTime = Sw(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
async function RN(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await IN(t, RCt(n, { idToken: r }));
  st(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? MCt(i.providerUserInfo) : [], a = ACt(t.providerData, o), c = t.isAnonymous, l = !(t.email && i.passwordHash) && !(a != null && a.length), h = c ? l : !1, u = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new Cfe(i.createdAt, i.lastLoginAt),
    isAnonymous: h
  };
  Object.assign(t, u);
}
async function DCt(t) {
  const e = as(t);
  await RN(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function ACt(t, e) {
  return [...t.filter((n) => !e.some((r) => r.providerId === n.providerId)), ...e];
}
function MCt(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = pfe(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
async function xCt(t, e) {
  const n = await yfe(t, {}, async () => {
    const r = n2({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = vfe(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", gfe.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
class ET {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    st(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), st(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), st(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : NCt(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return st(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await xCt(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new ET();
    return r && (st(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (st(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (st(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new ET(), this.toJSON());
  }
  _performRefresh() {
    return qc("not implemented");
  }
}
function hu(t, e) {
  st(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class mf {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = pfe(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new OCt(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new Cfe(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await IN(this, this.stsTokenManager.getToken(this.auth, e));
    return st(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return kCt(this, e);
  }
  reload() {
    return DCt(this);
  }
  _assign(e) {
    this !== e && (st(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new mf(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    st(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await RN(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await IN(this, ICt(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, l, h;
    const u = (r = n.displayName) !== null && r !== void 0 ? r : void 0, d = (s = n.email) !== null && s !== void 0 ? s : void 0, f = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, p = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, _ = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, m = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, v = (l = n.createdAt) !== null && l !== void 0 ? l : void 0, y = (h = n.lastLoginAt) !== null && h !== void 0 ? h : void 0, { uid: g, emailVerified: w, isAnonymous: de, providerData: we, stsTokenManager: fe } = n;
    st(
      g && fe,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const ys = ET.fromJSON(this.name, fe);
    st(
      typeof g == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), hu(u, e.name), hu(d, e.name), st(
      typeof w == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), st(
      typeof de == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), hu(f, e.name), hu(p, e.name), hu(_, e.name), hu(m, e.name), hu(v, e.name), hu(y, e.name);
    const En = new mf({
      uid: g,
      auth: e,
      email: d,
      emailVerified: w,
      displayName: u,
      isAnonymous: de,
      photoURL: p,
      phoneNumber: f,
      tenantId: _,
      stsTokenManager: ys,
      createdAt: v,
      lastLoginAt: y
    });
    return we && Array.isArray(we) && (En.providerData = we.map((vo) => Object.assign({}, vo))), m && (En._redirectEventId = m), En;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new ET();
    s.updateFromServerResponse(n);
    const i = new mf({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await RN(i), i;
  }
}
const G8 = /* @__PURE__ */ new Map();
function Kd(t) {
  SN(t instanceof Function, "Expected a class definition");
  let e = G8.get(t);
  return e ? (SN(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), G8.set(t, e), e);
}
class Tfe {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
Tfe.type = "NONE";
const $8 = Tfe;
function yx(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class em {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = yx(this.userKey, s.apiKey, i), this.fullPersistenceKey = yx("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? mf._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new em(Kd($8), e, r);
    const s = (await Promise.all(n.map(async (l) => {
      if (await l._isAvailable())
        return l;
    }))).filter((l) => l);
    let i = s[0] || Kd($8);
    const o = yx(r, e.config.apiKey, e.name);
    let a = null;
    for (const l of n)
      try {
        const h = await l._get(o);
        if (h) {
          const u = mf._fromJSON(e, h);
          l !== i && (a = u), i = l;
          break;
        }
      } catch {
      }
    const c = s.filter((l) => l._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new em(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (l) => {
      if (l !== i)
        try {
          await l._remove(o);
        } catch {
        }
    })), new em(i, e, r));
  }
}
function K8(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (YCt(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (LCt(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (BCt(e))
    return "Blackberry";
  if (qCt(e))
    return "Webos";
  if (UCt(e))
    return "Safari";
  if ((e.includes("chrome/") || FCt(e)) && !e.includes("edge/"))
    return "Chrome";
  if (WCt(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function LCt(t = Da()) {
  return /firefox\//i.test(t);
}
function UCt(t = Da()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function FCt(t = Da()) {
  return /crios\//i.test(t);
}
function YCt(t = Da()) {
  return /iemobile/i.test(t);
}
function WCt(t = Da()) {
  return /android/i.test(t);
}
function BCt(t = Da()) {
  return /blackberry/i.test(t);
}
function qCt(t = Da()) {
  return /webos/i.test(t);
}
function bfe(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = K8(Da());
      break;
    case "Worker":
      n = `${K8(Da())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${jT}/${r}`;
}
async function Efe(t, e) {
  return Np(t, "GET", "/v2/recaptchaConfig", mE(t, e));
}
function J8(t) {
  return t !== void 0 && t.enterprise !== void 0;
}
class Sfe {
  constructor(e) {
    if (this.siteKey = "", this.emailPasswordEnabled = !1, e.recaptchaKey === void 0)
      throw new Error("recaptchaKey undefined");
    this.siteKey = e.recaptchaKey.split("/")[3], this.emailPasswordEnabled = e.recaptchaEnforcementState.some((n) => n.provider === "EMAIL_PASSWORD_PROVIDER" && n.enforcementState !== "OFF");
  }
}
function HCt() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function jCt(t) {
  return new Promise((e, n) => {
    const r = document.createElement("script");
    r.setAttribute("src", t), r.onload = e, r.onerror = (s) => {
      const i = _6(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = s, n(i);
    }, r.type = "text/javascript", r.charset = "UTF-8", HCt().appendChild(r);
  });
}
const VCt = "https://www.google.com/recaptcha/enterprise.js?render=", zCt = "recaptcha-enterprise";
class Ife {
  /**
   *
   * @param authExtern - The corresponding Firebase {@link Auth} instance.
   *
   */
  constructor(e) {
    this.type = zCt, this.auth = jD(e);
  }
  /**
   * Executes the verification process.
   *
   * @returns A Promise for a token that can be used to assert the validity of a request.
   */
  async verify(e = "verify", n = !1) {
    async function r(i) {
      if (!n) {
        if (i.tenantId == null && i._agentRecaptchaConfig != null)
          return i._agentRecaptchaConfig.siteKey;
        if (i.tenantId != null && i._tenantRecaptchaConfigs[i.tenantId] !== void 0)
          return i._tenantRecaptchaConfigs[i.tenantId].siteKey;
      }
      return new Promise(async (o, a) => {
        Efe(i, {
          clientType: "CLIENT_TYPE_WEB",
          version: "RECAPTCHA_ENTERPRISE"
          /* RecaptchaVersion.ENTERPRISE */
        }).then((c) => {
          if (c.recaptchaKey === void 0)
            a(new Error("recaptcha Enterprise site key undefined"));
          else {
            const l = new Sfe(c);
            return i.tenantId == null ? i._agentRecaptchaConfig = l : i._tenantRecaptchaConfigs[i.tenantId] = l, o(l.siteKey);
          }
        }).catch((c) => {
          a(c);
        });
      });
    }
    function s(i, o, a) {
      const c = window.grecaptcha;
      J8(c) ? c.enterprise.ready(() => {
        try {
          c.enterprise.execute(i, { action: e }).then((l) => {
            o(l);
          }).catch((l) => {
            a(l);
          });
        } catch (l) {
          a(l);
        }
      }) : a(Error("No reCAPTCHA enterprise script loaded."));
    }
    return new Promise((i, o) => {
      r(this.auth).then((a) => {
        if (!n && J8(window.grecaptcha))
          s(a, i, o);
        else {
          if (typeof window > "u") {
            o(new Error("RecaptchaVerifier is only supported in browser"));
            return;
          }
          jCt(VCt + a).then(() => {
            s(a, i, o);
          }).catch((c) => {
            o(c);
          });
        }
      }).catch((a) => {
        o(a);
      });
    });
  }
}
async function kN(t, e, n, r = !1) {
  const s = new Ife(t);
  let i;
  try {
    i = await s.verify(n);
  } catch {
    i = await s.verify(n, !0);
  }
  const o = Object.assign({}, e);
  return r ? Object.assign(o, { captchaResp: i }) : Object.assign(o, { captchaResponse: i }), Object.assign(o, {
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  }), Object.assign(o, {
    recaptchaVersion: "RECAPTCHA_ENTERPRISE"
    /* RecaptchaVersion.ENTERPRISE */
  }), o;
}
class GCt {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
class $Ct {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new Q8(this), this.idTokenSubscription = new Q8(this), this.beforeStateQueue = new GCt(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = mfe, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = Kd(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await em.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && c != null && c.user && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return st(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await RN(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = CCt();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? as(e) : null;
    return n && st(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && st(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(Kd(e));
    });
  }
  async initializeRecaptchaConfig() {
    const e = await Efe(this, {
      clientType: "CLIENT_TYPE_WEB",
      version: "RECAPTCHA_ENTERPRISE"
      /* RecaptchaVersion.ENTERPRISE */
    }), n = new Sfe(e);
    this.tenantId == null ? this._agentRecaptchaConfig = n : this._tenantRecaptchaConfigs[this.tenantId] = n, n.emailPasswordEnabled && new Ife(this).verify();
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new HT("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && Kd(e) || this._popupRedirectResolver;
      st(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await em.create(
        this,
        [Kd(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return st(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, r, s) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return st(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = bfe(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && gCt(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function jD(t) {
  return as(t);
}
class Q8 {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = qCe((n) => this.observer = n);
  }
  get next() {
    return st(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function KCt(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(Kd);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
class JCt {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return qc("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return qc("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return qc("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return qc("not implemented");
  }
}
async function QCt(t, e) {
  return Np(t, "POST", "/v1/accounts:update", e);
}
async function vx(t, e) {
  return g6(t, "POST", "/v1/accounts:signInWithPassword", mE(t, e));
}
async function ZCt(t, e) {
  return Np(t, "POST", "/v1/accounts:sendOobCode", mE(t, e));
}
async function wx(t, e) {
  return ZCt(t, e);
}
async function XCt(t, e) {
  return g6(t, "POST", "/v1/accounts:signInWithEmailLink", mE(t, e));
}
async function eTt(t, e) {
  return g6(t, "POST", "/v1/accounts:signInWithEmailLink", mE(t, e));
}
class ST extends JCt {
  /** @internal */
  constructor(e, n, r, s = null) {
    super("password", r), this._email = e, this._password = n, this._tenantId = s;
  }
  /** @internal */
  static _fromEmailAndPassword(e, n) {
    return new ST(
      e,
      n,
      "password"
      /* SignInMethod.EMAIL_PASSWORD */
    );
  }
  /** @internal */
  static _fromEmailAndCode(e, n, r = null) {
    return new ST(e, n, "emailLink", r);
  }
  /** {@inheritdoc AuthCredential.toJSON} */
  toJSON() {
    return {
      email: this._email,
      password: this._password,
      signInMethod: this.signInMethod,
      tenantId: this._tenantId
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an {@link  AuthCredential}.
   *
   * @param json - Either `object` or the stringified representation of the object. When string is
   * provided, `JSON.parse` would be called first.
   *
   * @returns If the JSON input does not represent an {@link AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e;
    if (n != null && n.email && n != null && n.password) {
      if (n.signInMethod === "password")
        return this._fromEmailAndPassword(n.email, n.password);
      if (n.signInMethod === "emailLink")
        return this._fromEmailAndCode(n.email, n.password, n.tenantId);
    }
    return null;
  }
  /** @internal */
  async _getIdTokenResponse(e) {
    var n;
    switch (this.signInMethod) {
      case "password":
        const r = {
          returnSecureToken: !0,
          email: this._email,
          password: this._password,
          clientType: "CLIENT_TYPE_WEB"
          /* RecaptchaClientType.WEB */
        };
        if (!((n = e._getRecaptchaConfig()) === null || n === void 0) && n.emailPasswordEnabled) {
          const s = await kN(
            e,
            r,
            "signInWithPassword"
            /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
          );
          return vx(e, s);
        } else
          return vx(e, r).catch(async (s) => {
            if (s.code === "auth/missing-recaptcha-token") {
              console.log("Sign-in with email address and password is protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the sign-in flow.");
              const i = await kN(
                e,
                r,
                "signInWithPassword"
                /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
              );
              return vx(e, i);
            } else
              return Promise.reject(s);
          });
      case "emailLink":
        return XCt(e, {
          email: this._email,
          oobCode: this._password
        });
      default:
        bT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  async _linkToIdToken(e, n) {
    switch (this.signInMethod) {
      case "password":
        return QCt(e, {
          idToken: n,
          returnSecureToken: !0,
          email: this._email,
          password: this._password
        });
      case "emailLink":
        return eTt(e, {
          idToken: n,
          email: this._email,
          oobCode: this._password
        });
      default:
        bT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return this._getIdTokenResponse(e);
  }
}
function tTt(t) {
  switch (t) {
    case "recoverEmail":
      return "RECOVER_EMAIL";
    case "resetPassword":
      return "PASSWORD_RESET";
    case "signIn":
      return "EMAIL_SIGNIN";
    case "verifyEmail":
      return "VERIFY_EMAIL";
    case "verifyAndChangeEmail":
      return "VERIFY_AND_CHANGE_EMAIL";
    case "revertSecondFactorAddition":
      return "REVERT_SECOND_FACTOR_ADDITION";
    default:
      return null;
  }
}
function nTt(t) {
  const e = mv(gv(t)).link, n = e ? mv(gv(e)).deep_link_id : null, r = mv(gv(t)).deep_link_id;
  return (r ? mv(gv(r)).link : null) || r || n || e || t;
}
class y6 {
  /**
   * @param actionLink - The link from which to extract the URL.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @internal
   */
  constructor(e) {
    var n, r, s, i, o, a;
    const c = mv(gv(e)), l = (n = c.apiKey) !== null && n !== void 0 ? n : null, h = (r = c.oobCode) !== null && r !== void 0 ? r : null, u = tTt((s = c.mode) !== null && s !== void 0 ? s : null);
    st(
      l && h && u,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), this.apiKey = l, this.operation = u, this.code = h, this.continueUrl = (i = c.continueUrl) !== null && i !== void 0 ? i : null, this.languageCode = (o = c.languageCode) !== null && o !== void 0 ? o : null, this.tenantId = (a = c.tenantId) !== null && a !== void 0 ? a : null;
  }
  /**
   * Parses the email action link string and returns an {@link ActionCodeURL} if the link is valid,
   * otherwise returns null.
   *
   * @param link  - The email action link string.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @public
   */
  static parseLink(e) {
    const n = nTt(e);
    try {
      return new y6(n);
    } catch {
      return null;
    }
  }
}
class qg {
  constructor() {
    this.providerId = qg.PROVIDER_ID;
  }
  /**
   * Initialize an {@link AuthCredential} using an email and password.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credential(email, password);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * const userCredential = await signInWithEmailAndPassword(auth, email, password);
   * ```
   *
   * @param email - Email address.
   * @param password - User account password.
   * @returns The auth provider credential.
   */
  static credential(e, n) {
    return ST._fromEmailAndPassword(e, n);
  }
  /**
   * Initialize an {@link AuthCredential} using an email and an email link after a sign in with
   * email link operation.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credentialWithLink(auth, email, emailLink);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * await sendSignInLinkToEmail(auth, email);
   * // Obtain emailLink from user.
   * const userCredential = await signInWithEmailLink(auth, email, emailLink);
   * ```
   *
   * @param auth - The {@link Auth} instance used to verify the link.
   * @param email - Email address.
   * @param emailLink - Sign-in email link.
   * @returns - The auth provider credential.
   */
  static credentialWithLink(e, n) {
    const r = y6.parseLink(n);
    return st(
      r,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), ST._fromEmailAndCode(e, r.code, r.tenantId);
  }
}
qg.PROVIDER_ID = "password";
qg.EMAIL_PASSWORD_SIGN_IN_METHOD = "password";
qg.EMAIL_LINK_SIGN_IN_METHOD = "emailLink";
class NN {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, r, s = !1) {
    const i = await mf._fromIdTokenResponse(e, r, s), o = Z8(r);
    return new NN({
      user: i,
      providerId: o,
      _tokenResponse: r,
      operationType: n
    });
  }
  static async _forOperation(e, n, r) {
    await e._updateTokensIfNecessary(
      r,
      /* reload */
      !0
    );
    const s = Z8(r);
    return new NN({
      user: e,
      providerId: s,
      _tokenResponse: r,
      operationType: n
    });
  }
}
function Z8(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
class PN extends Ol {
  constructor(e, n, r, s) {
    var i;
    super(n.code, n.message), this.operationType = r, this.user = s, Object.setPrototypeOf(this, PN.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: r
    };
  }
  static _fromErrorAndOperation(e, n, r, s) {
    return new PN(e, n, r, s);
  }
}
function rTt(t, e, n, r) {
  return n._getIdTokenResponse(t).catch((s) => {
    throw s.code === "auth/multi-factor-auth-required" ? PN._fromErrorAndOperation(t, s, e, r) : s;
  });
}
async function sTt(t, e, n = !1) {
  const r = "signIn", s = await rTt(t, r, e), i = await NN._fromIdTokenResponse(t, r, s);
  return n || await t._updateCurrentUser(i.user), i;
}
async function iTt(t, e) {
  return sTt(jD(t), e);
}
async function oTt(t, e, n) {
  var r;
  const s = jD(t), i = {
    requestType: "PASSWORD_RESET",
    email: e,
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  };
  if (!((r = s._getRecaptchaConfig()) === null || r === void 0) && r.emailPasswordEnabled) {
    const o = await kN(s, i, "getOobCode", !0);
    await wx(s, o);
  } else
    await wx(s, i).catch(async (o) => {
      if (o.code === "auth/missing-recaptcha-token") {
        console.log("Password resets are protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the password reset flow.");
        const a = await kN(s, i, "getOobCode", !0);
        await wx(s, a);
      } else
        return Promise.reject(o);
    });
}
async function aTt(t, e) {
  return Np(t, "POST", "/v1/accounts:update", e);
}
async function X8(t, { displayName: e, photoURL: n }) {
  if (e === void 0 && n === void 0)
    return;
  const r = as(t), s = {
    idToken: await r.getIdToken(),
    displayName: e,
    photoUrl: n,
    returnSecureToken: !0
  }, i = await IN(r, aTt(r.auth, s));
  r.displayName = i.displayName || null, r.photoURL = i.photoUrl || null;
  const o = r.providerData.find(
    ({ providerId: a }) => a === "password"
    /* ProviderId.PASSWORD */
  );
  o && (o.displayName = r.displayName, o.photoURL = r.photoURL), await r._updateTokensIfNecessary(i);
}
new _E(3e4, 6e4);
new _E(2e3, 1e4);
new _E(3e4, 6e4);
new _E(5e3, 15e3);
var e7 = "@firebase/auth", t7 = "0.23.0";
class cTt {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    st(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
function lTt(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function uTt(t) {
  If(new bh(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      st(o && !o.includes(":"), "invalid-api-key", { appName: r.name }), st(!(a != null && a.includes(":")), "argument-error", {
        appName: r.name
      });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: bfe(t)
      }, l = new $Ct(r, s, i, c);
      return KCt(l, n), l;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), If(new bh(
    "auth-internal",
    (e) => {
      const n = jD(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new cTt(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), $c(e7, t7, lTt(t)), $c(e7, t7, "esm2017");
}
OCe("authIdTokenMaxAge");
uTt(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const n7 = {
  PICTURE_STORAGE: "/users/{uid}/UserProfilePic.jpg",
  PICTURE_DATE: "/users/{uid}/profilePicDate"
  // YYYY-MM-DD HH:mm:ss
};
class uv {
  static PictureStorage(e) {
    return z(n7.PICTURE_STORAGE, { uid: e });
  }
  static PictureDate(e) {
    return z(n7.PICTURE_DATE, { uid: e });
  }
}
const { resizeImage: hTt } = _gt;
class r7 extends eX {
  constructor(e) {
    super(), this.auth = e;
  }
  async uploadProfilePicture(e) {
    try {
      let n = await hTt({
        file: e,
        maxSize: 750
        //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
      }), r = { contentType: e.type };
      await this.uploadObject(uv.PictureStorage(this.uid), n.blob, r);
      let s = await this.objectUrl(uv.PictureStorage(this.uid));
      return console.log(`Profile photo updated: ${s}`), await this.updatePhoto(s), await this.updatePictureDate(), s;
    } catch (n) {
      throw console.error("Upload profile: " + n.message), n;
    }
  }
  async deleteProfilePicture() {
    try {
      const e = uv.PictureStorage(this.uid);
      await this.deleteObject(e), await this.updatePhoto(""), await this.updatePictureDate();
    } catch (e) {
      throw console.error("Delete profile: " + e.message), e;
    }
  }
  async updatePictureDate() {
    return await this.set(uv.PictureDate(this.uid), this.currentTime);
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
  get currentTime() {
    return F().format("YYYY-MM-DD HH:mm:ss");
  }
  async getPhotoUrl() {
    const e = uv.PictureStorage(this.uid);
    return await this.objectUrl(e);
  }
  // get profilePictureExists() {
  //     (async () => {
  //         try {
  //             const path = ProfileResources.PictureStorage(this.uid);
  //             await this.objectUrl(path)
  //             return true;
  //         } catch(e) {
  //             return false;
  //         }
  //         })();
  // }
  async sendPasswordResetEmail() {
    try {
      return await oTt(this.auth, this.auth.currentUser.email);
    } catch (e) {
      throw console.error("Password reset: " + e.message), e;
    }
  }
  async updateEmail(e) {
    try {
      return await updateEmail(this.auth.currentUser, e);
    } catch (n) {
      throw console.error("Updating email address: " + n.message), n;
    }
  }
  async updateUserProfile(e) {
    try {
      return await X8(this.auth.currentUser, {
        displayName: e.trim()
      });
    } catch (n) {
      throw console.error("Updating profile name: " + n.message), n;
    }
  }
  async updatePhoto(e) {
    try {
      return await X8(this.auth.currentUser, {
        photoURL: e
      });
    } catch (n) {
      throw console.error("Updating profile photo: " + n.message), n;
    }
  }
  async reauthenticateWithCredential(e) {
    try {
      const n = qg.credential(this.auth.currentUser.email, e);
      return await iTt(this.auth, n);
    } catch (n) {
      throw console.error("Verifying credentials: " + n.message), n;
    }
  }
}
var tI = { exports: {} };
function dTt(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
tI.exports = dTt;
const fTt = (tI.exports == null ? {} : tI.exports).default || tI.exports;
var nI = { exports: {} };
function pTt(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
nI.exports = pTt;
const _Tt = (nI.exports == null ? {} : nI.exports).default || nI.exports;
var rI = { exports: {} };
const mTt = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
rI.exports = mTt;
const gTt = (rI.exports == null ? {} : rI.exports).default || rI.exports;
var sI = { exports: {} };
function yTt(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
sI.exports = yTt;
const vTt = (sI.exports == null ? {} : sI.exports).default || sI.exports;
var iI = { exports: {} };
class wTt {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var CTt = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new wTt(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
iI.exports = CTt;
const TTt = (iI.exports == null ? {} : iI.exports).default || iI.exports, bTt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Analytics: cCe,
  BillingResources: vCe,
  CatalogFirebaseProvider: r7,
  CatalogFirebaseResources: hCe,
  CatalogHostedProvider: gCe,
  CatalogHostedResources: Ai,
  CatalogPromotions: wCe,
  CatalogPropertyMap: da,
  DataAccessLayer: hQ,
  Environment: g_,
  MailingListProvider: OIe,
  MailingListResources: Vp,
  ProfileProvider: r7,
  PurchaseResources: yCe,
  RequestResources: XY,
  allMap: Jx,
  allSettledMap: Jx,
  cyrb53: fTt,
  empty: _Tt,
  isEmpty: gTt,
  isNumeric: vTt,
  resizeImage: TTt,
  splitProduct: xS,
  templ8r: z
}, Symbol.toStringTag, { value: "Module" }));
var Rfe;
function Y() {
  return Rfe.apply(null, arguments);
}
function ETt(t) {
  Rfe = t;
}
function sa(t) {
  return t instanceof Array || Object.prototype.toString.call(t) === "[object Array]";
}
function gf(t) {
  return t != null && Object.prototype.toString.call(t) === "[object Object]";
}
function yt(t, e) {
  return Object.prototype.hasOwnProperty.call(t, e);
}
function v6(t) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(t).length === 0;
  var e;
  for (e in t)
    if (yt(t, e))
      return !1;
  return !0;
}
function ii(t) {
  return t === void 0;
}
function kl(t) {
  return typeof t == "number" || Object.prototype.toString.call(t) === "[object Number]";
}
function gE(t) {
  return t instanceof Date || Object.prototype.toString.call(t) === "[object Date]";
}
function kfe(t, e) {
  var n = [], r, s = t.length;
  for (r = 0; r < s; ++r)
    n.push(e(t[r], r));
  return n;
}
function Uu(t, e) {
  for (var n in e)
    yt(e, n) && (t[n] = e[n]);
  return yt(e, "toString") && (t.toString = e.toString), yt(e, "valueOf") && (t.valueOf = e.valueOf), t;
}
function oc(t, e, n, r) {
  return Qfe(t, e, n, r, !0).utc();
}
function STt() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function ve(t) {
  return t._pf == null && (t._pf = STt()), t._pf;
}
var nY;
Array.prototype.some ? nY = Array.prototype.some : nY = function(t) {
  var e = Object(this), n = e.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in e && t.call(this, e[r], r, e))
      return !0;
  return !1;
};
function w6(t) {
  var e = null, n = !1, r = t._d && !isNaN(t._d.getTime());
  if (r && (e = ve(t), n = nY.call(e.parsedDateParts, function(s) {
    return s != null;
  }), r = e.overflow < 0 && !e.empty && !e.invalidEra && !e.invalidMonth && !e.invalidWeekday && !e.weekdayMismatch && !e.nullInput && !e.invalidFormat && !e.userInvalidated && (!e.meridiem || e.meridiem && n), t._strict && (r = r && e.charsLeftOver === 0 && e.unusedTokens.length === 0 && e.bigHour === void 0)), Object.isFrozen == null || !Object.isFrozen(t))
    t._isValid = r;
  else
    return r;
  return t._isValid;
}
function VD(t) {
  var e = oc(NaN);
  return t != null ? Uu(ve(e), t) : ve(e).userInvalidated = !0, e;
}
var s7 = Y.momentProperties = [], Cx = !1;
function C6(t, e) {
  var n, r, s, i = s7.length;
  if (ii(e._isAMomentObject) || (t._isAMomentObject = e._isAMomentObject), ii(e._i) || (t._i = e._i), ii(e._f) || (t._f = e._f), ii(e._l) || (t._l = e._l), ii(e._strict) || (t._strict = e._strict), ii(e._tzm) || (t._tzm = e._tzm), ii(e._isUTC) || (t._isUTC = e._isUTC), ii(e._offset) || (t._offset = e._offset), ii(e._pf) || (t._pf = ve(e)), ii(e._locale) || (t._locale = e._locale), i > 0)
    for (n = 0; n < i; n++)
      r = s7[n], s = e[r], ii(s) || (t[r] = s);
  return t;
}
function yE(t) {
  C6(this, t), this._d = new Date(t._d != null ? t._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), Cx === !1 && (Cx = !0, Y.updateOffset(this), Cx = !1);
}
function ia(t) {
  return t instanceof yE || t != null && t._isAMomentObject != null;
}
function Nfe(t) {
  Y.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + t);
}
function go(t, e) {
  var n = !0;
  return Uu(function() {
    if (Y.deprecationHandler != null && Y.deprecationHandler(null, t), n) {
      var r = [], s, i, o, a = arguments.length;
      for (i = 0; i < a; i++) {
        if (s = "", typeof arguments[i] == "object") {
          s += `
[` + i + "] ";
          for (o in arguments[0])
            yt(arguments[0], o) && (s += o + ": " + arguments[0][o] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[i];
        r.push(s);
      }
      Nfe(
        t + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return e.apply(this, arguments);
  }, e);
}
var i7 = {};
function Pfe(t, e) {
  Y.deprecationHandler != null && Y.deprecationHandler(t, e), i7[t] || (Nfe(e), i7[t] = !0);
}
Y.suppressDeprecationWarnings = !1;
Y.deprecationHandler = null;
function ac(t) {
  return typeof Function < "u" && t instanceof Function || Object.prototype.toString.call(t) === "[object Function]";
}
function ITt(t) {
  var e, n;
  for (n in t)
    yt(t, n) && (e = t[n], ac(e) ? this[n] = e : this["_" + n] = e);
  this._config = t, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function rY(t, e) {
  var n = Uu({}, t), r;
  for (r in e)
    yt(e, r) && (gf(t[r]) && gf(e[r]) ? (n[r] = {}, Uu(n[r], t[r]), Uu(n[r], e[r])) : e[r] != null ? n[r] = e[r] : delete n[r]);
  for (r in t)
    yt(t, r) && !yt(e, r) && gf(t[r]) && (n[r] = Uu({}, n[r]));
  return n;
}
function T6(t) {
  t != null && this.set(t);
}
var sY;
Object.keys ? sY = Object.keys : sY = function(t) {
  var e, n = [];
  for (e in t)
    yt(t, e) && n.push(e);
  return n;
};
var RTt = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function kTt(t, e, n) {
  var r = this._calendar[t] || this._calendar.sameElse;
  return ac(r) ? r.call(e, n) : r;
}
function qa(t, e, n) {
  var r = "" + Math.abs(t), s = e - r.length, i = t >= 0;
  return (i ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var b6 = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, IS = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, Tx = {}, tm = {};
function se(t, e, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), t && (tm[t] = s), e && (tm[e[0]] = function() {
    return qa(s.apply(this, arguments), e[1], e[2]);
  }), n && (tm[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      t
    );
  });
}
function NTt(t) {
  return t.match(/\[[\s\S]/) ? t.replace(/^\[|\]$/g, "") : t.replace(/\\/g, "");
}
function PTt(t) {
  var e = t.match(b6), n, r;
  for (n = 0, r = e.length; n < r; n++)
    tm[e[n]] ? e[n] = tm[e[n]] : e[n] = NTt(e[n]);
  return function(s) {
    var i = "", o;
    for (o = 0; o < r; o++)
      i += ac(e[o]) ? e[o].call(s, t) : e[o];
    return i;
  };
}
function oI(t, e) {
  return t.isValid() ? (e = Ofe(e, t.localeData()), Tx[e] = Tx[e] || PTt(e), Tx[e](t)) : t.localeData().invalidDate();
}
function Ofe(t, e) {
  var n = 5;
  function r(s) {
    return e.longDateFormat(s) || s;
  }
  for (IS.lastIndex = 0; n >= 0 && IS.test(t); )
    t = t.replace(
      IS,
      r
    ), IS.lastIndex = 0, n -= 1;
  return t;
}
var OTt = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function DTt(t) {
  var e = this._longDateFormat[t], n = this._longDateFormat[t.toUpperCase()];
  return e || !n ? e : (this._longDateFormat[t] = n.match(b6).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[t]);
}
var ATt = "Invalid date";
function MTt() {
  return this._invalidDate;
}
var xTt = "%d", LTt = /\d{1,2}/;
function UTt(t) {
  return this._ordinal.replace("%d", t);
}
var FTt = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function YTt(t, e, n, r) {
  var s = this._relativeTime[n];
  return ac(s) ? s(t, e, n, r) : s.replace(/%d/i, t);
}
function WTt(t, e) {
  var n = this._relativeTime[t > 0 ? "future" : "past"];
  return ac(n) ? n(e) : n.replace(/%s/i, e);
}
var o7 = {
  D: "date",
  dates: "date",
  date: "date",
  d: "day",
  days: "day",
  day: "day",
  e: "weekday",
  weekdays: "weekday",
  weekday: "weekday",
  E: "isoWeekday",
  isoweekdays: "isoWeekday",
  isoweekday: "isoWeekday",
  DDD: "dayOfYear",
  dayofyears: "dayOfYear",
  dayofyear: "dayOfYear",
  h: "hour",
  hours: "hour",
  hour: "hour",
  ms: "millisecond",
  milliseconds: "millisecond",
  millisecond: "millisecond",
  m: "minute",
  minutes: "minute",
  minute: "minute",
  M: "month",
  months: "month",
  month: "month",
  Q: "quarter",
  quarters: "quarter",
  quarter: "quarter",
  s: "second",
  seconds: "second",
  second: "second",
  gg: "weekYear",
  weekyears: "weekYear",
  weekyear: "weekYear",
  GG: "isoWeekYear",
  isoweekyears: "isoWeekYear",
  isoweekyear: "isoWeekYear",
  w: "week",
  weeks: "week",
  week: "week",
  W: "isoWeek",
  isoweeks: "isoWeek",
  isoweek: "isoWeek",
  y: "year",
  years: "year",
  year: "year"
};
function yo(t) {
  return typeof t == "string" ? o7[t] || o7[t.toLowerCase()] : void 0;
}
function E6(t) {
  var e = {}, n, r;
  for (r in t)
    yt(t, r) && (n = yo(r), n && (e[n] = t[r]));
  return e;
}
var BTt = {
  date: 9,
  day: 11,
  weekday: 11,
  isoWeekday: 11,
  dayOfYear: 4,
  hour: 13,
  millisecond: 16,
  minute: 14,
  month: 8,
  quarter: 7,
  second: 15,
  weekYear: 1,
  isoWeekYear: 1,
  week: 5,
  isoWeek: 5,
  year: 1
};
function qTt(t) {
  var e = [], n;
  for (n in t)
    yt(t, n) && e.push({ unit: n, priority: BTt[n] });
  return e.sort(function(r, s) {
    return r.priority - s.priority;
  }), e;
}
var Dfe = /\d/, Di = /\d\d/, Afe = /\d{3}/, S6 = /\d{4}/, zD = /[+-]?\d{6}/, gn = /\d\d?/, Mfe = /\d\d\d\d?/, xfe = /\d\d\d\d\d\d?/, GD = /\d{1,3}/, I6 = /\d{1,4}/, $D = /[+-]?\d{1,6}/, Hg = /\d+/, KD = /[+-]?\d+/, HTt = /Z|[+-]\d\d:?\d\d/gi, JD = /Z|[+-]\d\d(?::?\d\d)?/gi, jTt = /[+-]?\d+(\.\d{1,3})?/, vE = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, jg = /^[1-9]\d?/, R6 = /^([1-9]\d|\d)/, ON;
ON = {};
function V(t, e, n) {
  ON[t] = ac(e) ? e : function(r, s) {
    return r && n ? n : e;
  };
}
function VTt(t, e) {
  return yt(ON, t) ? ON[t](e._strict, e._locale) : new RegExp(zTt(t));
}
function zTt(t) {
  return pl(
    t.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(e, n, r, s, i) {
        return n || r || s || i;
      }
    )
  );
}
function pl(t) {
  return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
function ji(t) {
  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
}
function Ge(t) {
  var e = +t, n = 0;
  return e !== 0 && isFinite(e) && (n = ji(e)), n;
}
var iY = {};
function Ht(t, e) {
  var n, r = e, s;
  for (typeof t == "string" && (t = [t]), kl(e) && (r = function(i, o) {
    o[e] = Ge(i);
  }), s = t.length, n = 0; n < s; n++)
    iY[t[n]] = r;
}
function wE(t, e) {
  Ht(t, function(n, r, s, i) {
    s._w = s._w || {}, e(n, s._w, s, i);
  });
}
function GTt(t, e, n) {
  e != null && yt(iY, t) && iY[t](e, n._a, n, t);
}
function QD(t) {
  return t % 4 === 0 && t % 100 !== 0 || t % 400 === 0;
}
var gs = 0, Hc = 1, ba = 2, Er = 3, Wo = 4, jc = 5, Jd = 6, $Tt = 7, KTt = 8;
se("Y", 0, 0, function() {
  var t = this.year();
  return t <= 9999 ? qa(t, 4) : "+" + t;
});
se(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
se(0, ["YYYY", 4], 0, "year");
se(0, ["YYYYY", 5], 0, "year");
se(0, ["YYYYYY", 6, !0], 0, "year");
V("Y", KD);
V("YY", gn, Di);
V("YYYY", I6, S6);
V("YYYYY", $D, zD);
V("YYYYYY", $D, zD);
Ht(["YYYYY", "YYYYYY"], gs);
Ht("YYYY", function(t, e) {
  e[gs] = t.length === 2 ? Y.parseTwoDigitYear(t) : Ge(t);
});
Ht("YY", function(t, e) {
  e[gs] = Y.parseTwoDigitYear(t);
});
Ht("Y", function(t, e) {
  e[gs] = parseInt(t, 10);
});
function Iw(t) {
  return QD(t) ? 366 : 365;
}
Y.parseTwoDigitYear = function(t) {
  return Ge(t) + (Ge(t) > 68 ? 1900 : 2e3);
};
var Lfe = Vg("FullYear", !0);
function JTt() {
  return QD(this.year());
}
function Vg(t, e) {
  return function(n) {
    return n != null ? (Ufe(this, t, n), Y.updateOffset(this, e), this) : IT(this, t);
  };
}
function IT(t, e) {
  if (!t.isValid())
    return NaN;
  var n = t._d, r = t._isUTC;
  switch (e) {
    case "Milliseconds":
      return r ? n.getUTCMilliseconds() : n.getMilliseconds();
    case "Seconds":
      return r ? n.getUTCSeconds() : n.getSeconds();
    case "Minutes":
      return r ? n.getUTCMinutes() : n.getMinutes();
    case "Hours":
      return r ? n.getUTCHours() : n.getHours();
    case "Date":
      return r ? n.getUTCDate() : n.getDate();
    case "Day":
      return r ? n.getUTCDay() : n.getDay();
    case "Month":
      return r ? n.getUTCMonth() : n.getMonth();
    case "FullYear":
      return r ? n.getUTCFullYear() : n.getFullYear();
    default:
      return NaN;
  }
}
function Ufe(t, e, n) {
  var r, s, i, o, a;
  if (!(!t.isValid() || isNaN(n))) {
    switch (r = t._d, s = t._isUTC, e) {
      case "Milliseconds":
        return void (s ? r.setUTCMilliseconds(n) : r.setMilliseconds(n));
      case "Seconds":
        return void (s ? r.setUTCSeconds(n) : r.setSeconds(n));
      case "Minutes":
        return void (s ? r.setUTCMinutes(n) : r.setMinutes(n));
      case "Hours":
        return void (s ? r.setUTCHours(n) : r.setHours(n));
      case "Date":
        return void (s ? r.setUTCDate(n) : r.setDate(n));
      case "FullYear":
        break;
      default:
        return;
    }
    i = n, o = t.month(), a = t.date(), a = a === 29 && o === 1 && !QD(i) ? 28 : a, s ? r.setUTCFullYear(i, o, a) : r.setFullYear(i, o, a);
  }
}
function QTt(t) {
  return t = yo(t), ac(this[t]) ? this[t]() : this;
}
function ZTt(t, e) {
  if (typeof t == "object") {
    t = E6(t);
    var n = qTt(t), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](t[n[r].unit]);
  } else if (t = yo(t), ac(this[t]))
    return this[t](e);
  return this;
}
function XTt(t, e) {
  return (t % e + e) % e;
}
var sr;
Array.prototype.indexOf ? sr = Array.prototype.indexOf : sr = function(t) {
  var e;
  for (e = 0; e < this.length; ++e)
    if (this[e] === t)
      return e;
  return -1;
};
function k6(t, e) {
  if (isNaN(t) || isNaN(e))
    return NaN;
  var n = XTt(e, 12);
  return t += (e - n) / 12, n === 1 ? QD(t) ? 29 : 28 : 31 - n % 7 % 2;
}
se("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
se("MMM", 0, 0, function(t) {
  return this.localeData().monthsShort(this, t);
});
se("MMMM", 0, 0, function(t) {
  return this.localeData().months(this, t);
});
V("M", gn, jg);
V("MM", gn, Di);
V("MMM", function(t, e) {
  return e.monthsShortRegex(t);
});
V("MMMM", function(t, e) {
  return e.monthsRegex(t);
});
Ht(["M", "MM"], function(t, e) {
  e[Hc] = Ge(t) - 1;
});
Ht(["MMM", "MMMM"], function(t, e, n, r) {
  var s = n._locale.monthsParse(t, r, n._strict);
  s != null ? e[Hc] = s : ve(n).invalidMonth = t;
});
var ebt = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), Ffe = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), Yfe = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, tbt = vE, nbt = vE;
function rbt(t, e) {
  return t ? sa(this._months) ? this._months[t.month()] : this._months[(this._months.isFormat || Yfe).test(e) ? "format" : "standalone"][t.month()] : sa(this._months) ? this._months : this._months.standalone;
}
function sbt(t, e) {
  return t ? sa(this._monthsShort) ? this._monthsShort[t.month()] : this._monthsShort[Yfe.test(e) ? "format" : "standalone"][t.month()] : sa(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function ibt(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      i = oc([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        i,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(i, "").toLocaleLowerCase();
  return n ? e === "MMM" ? (s = sr.call(this._shortMonthsParse, o), s !== -1 ? s : null) : (s = sr.call(this._longMonthsParse, o), s !== -1 ? s : null) : e === "MMM" ? (s = sr.call(this._shortMonthsParse, o), s !== -1 ? s : (s = sr.call(this._longMonthsParse, o), s !== -1 ? s : null)) : (s = sr.call(this._longMonthsParse, o), s !== -1 ? s : (s = sr.call(this._shortMonthsParse, o), s !== -1 ? s : null));
}
function obt(t, e, n) {
  var r, s, i;
  if (this._monthsParseExact)
    return ibt.call(this, t, e, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++) {
    if (s = oc([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (i = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "MMMM" && this._longMonthsParse[r].test(t))
      return r;
    if (n && e === "MMM" && this._shortMonthsParse[r].test(t))
      return r;
    if (!n && this._monthsParse[r].test(t))
      return r;
  }
}
function Wfe(t, e) {
  if (!t.isValid())
    return t;
  if (typeof e == "string") {
    if (/^\d+$/.test(e))
      e = Ge(e);
    else if (e = t.localeData().monthsParse(e), !kl(e))
      return t;
  }
  var n = e, r = t.date();
  return r = r < 29 ? r : Math.min(r, k6(t.year(), n)), t._isUTC ? t._d.setUTCMonth(n, r) : t._d.setMonth(n, r), t;
}
function Bfe(t) {
  return t != null ? (Wfe(this, t), Y.updateOffset(this, !0), this) : IT(this, "Month");
}
function abt() {
  return k6(this.year(), this.month());
}
function cbt(t) {
  return this._monthsParseExact ? (yt(this, "_monthsRegex") || qfe.call(this), t ? this._monthsShortStrictRegex : this._monthsShortRegex) : (yt(this, "_monthsShortRegex") || (this._monthsShortRegex = tbt), this._monthsShortStrictRegex && t ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function lbt(t) {
  return this._monthsParseExact ? (yt(this, "_monthsRegex") || qfe.call(this), t ? this._monthsStrictRegex : this._monthsRegex) : (yt(this, "_monthsRegex") || (this._monthsRegex = nbt), this._monthsStrictRegex && t ? this._monthsStrictRegex : this._monthsRegex);
}
function qfe() {
  function t(c, l) {
    return l.length - c.length;
  }
  var e = [], n = [], r = [], s, i, o, a;
  for (s = 0; s < 12; s++)
    i = oc([2e3, s]), o = pl(this.monthsShort(i, "")), a = pl(this.months(i, "")), e.push(o), n.push(a), r.push(a), r.push(o);
  e.sort(t), n.sort(t), r.sort(t), this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function ubt(t, e, n, r, s, i, o) {
  var a;
  return t < 100 && t >= 0 ? (a = new Date(t + 400, e, n, r, s, i, o), isFinite(a.getFullYear()) && a.setFullYear(t)) : a = new Date(t, e, n, r, s, i, o), a;
}
function RT(t) {
  var e, n;
  return t < 100 && t >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = t + 400, e = new Date(Date.UTC.apply(null, n)), isFinite(e.getUTCFullYear()) && e.setUTCFullYear(t)) : e = new Date(Date.UTC.apply(null, arguments)), e;
}
function DN(t, e, n) {
  var r = 7 + e - n, s = (7 + RT(t, 0, r).getUTCDay() - e) % 7;
  return -s + r - 1;
}
function Hfe(t, e, n, r, s) {
  var i = (7 + n - r) % 7, o = DN(t, r, s), a = 1 + 7 * (e - 1) + i + o, c, l;
  return a <= 0 ? (c = t - 1, l = Iw(c) + a) : a > Iw(t) ? (c = t + 1, l = a - Iw(t)) : (c = t, l = a), {
    year: c,
    dayOfYear: l
  };
}
function kT(t, e, n) {
  var r = DN(t.year(), e, n), s = Math.floor((t.dayOfYear() - r - 1) / 7) + 1, i, o;
  return s < 1 ? (o = t.year() - 1, i = s + _l(o, e, n)) : s > _l(t.year(), e, n) ? (i = s - _l(t.year(), e, n), o = t.year() + 1) : (o = t.year(), i = s), {
    week: i,
    year: o
  };
}
function _l(t, e, n) {
  var r = DN(t, e, n), s = DN(t + 1, e, n);
  return (Iw(t) - r + s) / 7;
}
se("w", ["ww", 2], "wo", "week");
se("W", ["WW", 2], "Wo", "isoWeek");
V("w", gn, jg);
V("ww", gn, Di);
V("W", gn, jg);
V("WW", gn, Di);
wE(
  ["w", "ww", "W", "WW"],
  function(t, e, n, r) {
    e[r.substr(0, 1)] = Ge(t);
  }
);
function hbt(t) {
  return kT(t, this._week.dow, this._week.doy).week;
}
var dbt = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function fbt() {
  return this._week.dow;
}
function pbt() {
  return this._week.doy;
}
function _bt(t) {
  var e = this.localeData().week(this);
  return t == null ? e : this.add((t - e) * 7, "d");
}
function mbt(t) {
  var e = kT(this, 1, 4).week;
  return t == null ? e : this.add((t - e) * 7, "d");
}
se("d", 0, "do", "day");
se("dd", 0, 0, function(t) {
  return this.localeData().weekdaysMin(this, t);
});
se("ddd", 0, 0, function(t) {
  return this.localeData().weekdaysShort(this, t);
});
se("dddd", 0, 0, function(t) {
  return this.localeData().weekdays(this, t);
});
se("e", 0, 0, "weekday");
se("E", 0, 0, "isoWeekday");
V("d", gn);
V("e", gn);
V("E", gn);
V("dd", function(t, e) {
  return e.weekdaysMinRegex(t);
});
V("ddd", function(t, e) {
  return e.weekdaysShortRegex(t);
});
V("dddd", function(t, e) {
  return e.weekdaysRegex(t);
});
wE(["dd", "ddd", "dddd"], function(t, e, n, r) {
  var s = n._locale.weekdaysParse(t, r, n._strict);
  s != null ? e.d = s : ve(n).invalidWeekday = t;
});
wE(["d", "e", "E"], function(t, e, n, r) {
  e[r] = Ge(t);
});
function gbt(t, e) {
  return typeof t != "string" ? t : isNaN(t) ? (t = e.weekdaysParse(t), typeof t == "number" ? t : null) : parseInt(t, 10);
}
function ybt(t, e) {
  return typeof t == "string" ? e.weekdaysParse(t) % 7 || 7 : isNaN(t) ? null : t;
}
function N6(t, e) {
  return t.slice(e, 7).concat(t.slice(0, e));
}
var vbt = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), jfe = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), wbt = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), Cbt = vE, Tbt = vE, bbt = vE;
function Ebt(t, e) {
  var n = sa(this._weekdays) ? this._weekdays : this._weekdays[t && t !== !0 && this._weekdays.isFormat.test(e) ? "format" : "standalone"];
  return t === !0 ? N6(n, this._week.dow) : t ? n[t.day()] : n;
}
function Sbt(t) {
  return t === !0 ? N6(this._weekdaysShort, this._week.dow) : t ? this._weekdaysShort[t.day()] : this._weekdaysShort;
}
function Ibt(t) {
  return t === !0 ? N6(this._weekdaysMin, this._week.dow) : t ? this._weekdaysMin[t.day()] : this._weekdaysMin;
}
function Rbt(t, e, n) {
  var r, s, i, o = t.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      i = oc([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        i,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        i,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(i, "").toLocaleLowerCase();
  return n ? e === "dddd" ? (s = sr.call(this._weekdaysParse, o), s !== -1 ? s : null) : e === "ddd" ? (s = sr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null) : (s = sr.call(this._minWeekdaysParse, o), s !== -1 ? s : null) : e === "dddd" ? (s = sr.call(this._weekdaysParse, o), s !== -1 || (s = sr.call(this._shortWeekdaysParse, o), s !== -1) ? s : (s = sr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : e === "ddd" ? (s = sr.call(this._shortWeekdaysParse, o), s !== -1 || (s = sr.call(this._weekdaysParse, o), s !== -1) ? s : (s = sr.call(this._minWeekdaysParse, o), s !== -1 ? s : null)) : (s = sr.call(this._minWeekdaysParse, o), s !== -1 || (s = sr.call(this._weekdaysParse, o), s !== -1) ? s : (s = sr.call(this._shortWeekdaysParse, o), s !== -1 ? s : null));
}
function kbt(t, e, n) {
  var r, s, i;
  if (this._weekdaysParseExact)
    return Rbt.call(this, t, e, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++) {
    if (s = oc([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (i = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(i.replace(".", ""), "i")), n && e === "dddd" && this._fullWeekdaysParse[r].test(t))
      return r;
    if (n && e === "ddd" && this._shortWeekdaysParse[r].test(t))
      return r;
    if (n && e === "dd" && this._minWeekdaysParse[r].test(t))
      return r;
    if (!n && this._weekdaysParse[r].test(t))
      return r;
  }
}
function Nbt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = IT(this, "Day");
  return t != null ? (t = gbt(t, this.localeData()), this.add(t - e, "d")) : e;
}
function Pbt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  var e = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return t == null ? e : this.add(t - e, "d");
}
function Obt(t) {
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    var e = ybt(t, this.localeData());
    return this.day(this.day() % 7 ? e : e - 7);
  } else
    return this.day() || 7;
}
function Dbt(t) {
  return this._weekdaysParseExact ? (yt(this, "_weekdaysRegex") || P6.call(this), t ? this._weekdaysStrictRegex : this._weekdaysRegex) : (yt(this, "_weekdaysRegex") || (this._weekdaysRegex = Cbt), this._weekdaysStrictRegex && t ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function Abt(t) {
  return this._weekdaysParseExact ? (yt(this, "_weekdaysRegex") || P6.call(this), t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (yt(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = Tbt), this._weekdaysShortStrictRegex && t ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function Mbt(t) {
  return this._weekdaysParseExact ? (yt(this, "_weekdaysRegex") || P6.call(this), t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (yt(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = bbt), this._weekdaysMinStrictRegex && t ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function P6() {
  function t(h, u) {
    return u.length - h.length;
  }
  var e = [], n = [], r = [], s = [], i, o, a, c, l;
  for (i = 0; i < 7; i++)
    o = oc([2e3, 1]).day(i), a = pl(this.weekdaysMin(o, "")), c = pl(this.weekdaysShort(o, "")), l = pl(this.weekdays(o, "")), e.push(a), n.push(c), r.push(l), s.push(a), s.push(c), s.push(l);
  e.sort(t), n.sort(t), r.sort(t), s.sort(t), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + e.join("|") + ")",
    "i"
  );
}
function O6() {
  return this.hours() % 12 || 12;
}
function xbt() {
  return this.hours() || 24;
}
se("H", ["HH", 2], 0, "hour");
se("h", ["hh", 2], 0, O6);
se("k", ["kk", 2], 0, xbt);
se("hmm", 0, 0, function() {
  return "" + O6.apply(this) + qa(this.minutes(), 2);
});
se("hmmss", 0, 0, function() {
  return "" + O6.apply(this) + qa(this.minutes(), 2) + qa(this.seconds(), 2);
});
se("Hmm", 0, 0, function() {
  return "" + this.hours() + qa(this.minutes(), 2);
});
se("Hmmss", 0, 0, function() {
  return "" + this.hours() + qa(this.minutes(), 2) + qa(this.seconds(), 2);
});
function Vfe(t, e) {
  se(t, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      e
    );
  });
}
Vfe("a", !0);
Vfe("A", !1);
function zfe(t, e) {
  return e._meridiemParse;
}
V("a", zfe);
V("A", zfe);
V("H", gn, R6);
V("h", gn, jg);
V("k", gn, jg);
V("HH", gn, Di);
V("hh", gn, Di);
V("kk", gn, Di);
V("hmm", Mfe);
V("hmmss", xfe);
V("Hmm", Mfe);
V("Hmmss", xfe);
Ht(["H", "HH"], Er);
Ht(["k", "kk"], function(t, e, n) {
  var r = Ge(t);
  e[Er] = r === 24 ? 0 : r;
});
Ht(["a", "A"], function(t, e, n) {
  n._isPm = n._locale.isPM(t), n._meridiem = t;
});
Ht(["h", "hh"], function(t, e, n) {
  e[Er] = Ge(t), ve(n).bigHour = !0;
});
Ht("hmm", function(t, e, n) {
  var r = t.length - 2;
  e[Er] = Ge(t.substr(0, r)), e[Wo] = Ge(t.substr(r)), ve(n).bigHour = !0;
});
Ht("hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[Er] = Ge(t.substr(0, r)), e[Wo] = Ge(t.substr(r, 2)), e[jc] = Ge(t.substr(s)), ve(n).bigHour = !0;
});
Ht("Hmm", function(t, e, n) {
  var r = t.length - 2;
  e[Er] = Ge(t.substr(0, r)), e[Wo] = Ge(t.substr(r));
});
Ht("Hmmss", function(t, e, n) {
  var r = t.length - 4, s = t.length - 2;
  e[Er] = Ge(t.substr(0, r)), e[Wo] = Ge(t.substr(r, 2)), e[jc] = Ge(t.substr(s));
});
function Lbt(t) {
  return (t + "").toLowerCase().charAt(0) === "p";
}
var Ubt = /[ap]\.?m?\.?/i, Fbt = Vg("Hours", !0);
function Ybt(t, e, n) {
  return t > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var Gfe = {
  calendar: RTt,
  longDateFormat: OTt,
  invalidDate: ATt,
  ordinal: xTt,
  dayOfMonthOrdinalParse: LTt,
  relativeTime: FTt,
  months: ebt,
  monthsShort: Ffe,
  week: dbt,
  weekdays: vbt,
  weekdaysMin: wbt,
  weekdaysShort: jfe,
  meridiemParse: Ubt
}, bn = {}, hv = {}, NT;
function Wbt(t, e) {
  var n, r = Math.min(t.length, e.length);
  for (n = 0; n < r; n += 1)
    if (t[n] !== e[n])
      return n;
  return r;
}
function a7(t) {
  return t && t.toLowerCase().replace("_", "-");
}
function Bbt(t) {
  for (var e = 0, n, r, s, i; e < t.length; ) {
    for (i = a7(t[e]).split("-"), n = i.length, r = a7(t[e + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = ZD(i.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && Wbt(i, r) >= n - 1)
        break;
      n--;
    }
    e++;
  }
  return NT;
}
function qbt(t) {
  return !!(t && t.match("^[^/\\\\]*$"));
}
function ZD(t) {
  var e = null, n;
  if (bn[t] === void 0 && typeof module < "u" && module && module.exports && qbt(t))
    try {
      e = NT._abbr, n = require, n("./locale/" + t), yh(e);
    } catch {
      bn[t] = null;
    }
  return bn[t];
}
function yh(t, e) {
  var n;
  return t && (ii(e) ? n = Zl(t) : n = D6(t, e), n ? NT = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + t + " not found. Did you forget to load it?"
  )), NT._abbr;
}
function D6(t, e) {
  if (e !== null) {
    var n, r = Gfe;
    if (e.abbr = t, bn[t] != null)
      Pfe(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = bn[t]._config;
    else if (e.parentLocale != null)
      if (bn[e.parentLocale] != null)
        r = bn[e.parentLocale]._config;
      else if (n = ZD(e.parentLocale), n != null)
        r = n._config;
      else
        return hv[e.parentLocale] || (hv[e.parentLocale] = []), hv[e.parentLocale].push({
          name: t,
          config: e
        }), null;
    return bn[t] = new T6(rY(r, e)), hv[t] && hv[t].forEach(function(s) {
      D6(s.name, s.config);
    }), yh(t), bn[t];
  } else
    return delete bn[t], null;
}
function Hbt(t, e) {
  if (e != null) {
    var n, r, s = Gfe;
    bn[t] != null && bn[t].parentLocale != null ? bn[t].set(rY(bn[t]._config, e)) : (r = ZD(t), r != null && (s = r._config), e = rY(s, e), r == null && (e.abbr = t), n = new T6(e), n.parentLocale = bn[t], bn[t] = n), yh(t);
  } else
    bn[t] != null && (bn[t].parentLocale != null ? (bn[t] = bn[t].parentLocale, t === yh() && yh(t)) : bn[t] != null && delete bn[t]);
  return bn[t];
}
function Zl(t) {
  var e;
  if (t && t._locale && t._locale._abbr && (t = t._locale._abbr), !t)
    return NT;
  if (!sa(t)) {
    if (e = ZD(t), e)
      return e;
    t = [t];
  }
  return Bbt(t);
}
function jbt() {
  return sY(bn);
}
function A6(t) {
  var e, n = t._a;
  return n && ve(t).overflow === -2 && (e = n[Hc] < 0 || n[Hc] > 11 ? Hc : n[ba] < 1 || n[ba] > k6(n[gs], n[Hc]) ? ba : n[Er] < 0 || n[Er] > 24 || n[Er] === 24 && (n[Wo] !== 0 || n[jc] !== 0 || n[Jd] !== 0) ? Er : n[Wo] < 0 || n[Wo] > 59 ? Wo : n[jc] < 0 || n[jc] > 59 ? jc : n[Jd] < 0 || n[Jd] > 999 ? Jd : -1, ve(t)._overflowDayOfYear && (e < gs || e > ba) && (e = ba), ve(t)._overflowWeeks && e === -1 && (e = $Tt), ve(t)._overflowWeekday && e === -1 && (e = KTt), ve(t).overflow = e), t;
}
var Vbt = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, zbt = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Gbt = /Z|[+-]\d\d(?::?\d\d)?/, RS = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], bx = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], $bt = /^\/?Date\((-?\d+)/i, Kbt = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, Jbt = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function $fe(t) {
  var e, n, r = t._i, s = Vbt.exec(r) || zbt.exec(r), i, o, a, c, l = RS.length, h = bx.length;
  if (s) {
    for (ve(t).iso = !0, e = 0, n = l; e < n; e++)
      if (RS[e][1].exec(s[1])) {
        o = RS[e][0], i = RS[e][2] !== !1;
        break;
      }
    if (o == null) {
      t._isValid = !1;
      return;
    }
    if (s[3]) {
      for (e = 0, n = h; e < n; e++)
        if (bx[e][1].exec(s[3])) {
          a = (s[2] || " ") + bx[e][0];
          break;
        }
      if (a == null) {
        t._isValid = !1;
        return;
      }
    }
    if (!i && a != null) {
      t._isValid = !1;
      return;
    }
    if (s[4])
      if (Gbt.exec(s[4]))
        c = "Z";
      else {
        t._isValid = !1;
        return;
      }
    t._f = o + (a || "") + (c || ""), x6(t);
  } else
    t._isValid = !1;
}
function Qbt(t, e, n, r, s, i) {
  var o = [
    Zbt(t),
    Ffe.indexOf(e),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return i && o.push(parseInt(i, 10)), o;
}
function Zbt(t) {
  var e = parseInt(t, 10);
  return e <= 49 ? 2e3 + e : e <= 999 ? 1900 + e : e;
}
function Xbt(t) {
  return t.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function eEt(t, e, n) {
  if (t) {
    var r = jfe.indexOf(t), s = new Date(
      e[0],
      e[1],
      e[2]
    ).getDay();
    if (r !== s)
      return ve(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function tEt(t, e, n) {
  if (t)
    return Jbt[t];
  if (e)
    return 0;
  var r = parseInt(n, 10), s = r % 100, i = (r - s) / 100;
  return i * 60 + s;
}
function Kfe(t) {
  var e = Kbt.exec(Xbt(t._i)), n;
  if (e) {
    if (n = Qbt(
      e[4],
      e[3],
      e[2],
      e[5],
      e[6],
      e[7]
    ), !eEt(e[1], n, t))
      return;
    t._a = n, t._tzm = tEt(e[8], e[9], e[10]), t._d = RT.apply(null, t._a), t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), ve(t).rfc2822 = !0;
  } else
    t._isValid = !1;
}
function nEt(t) {
  var e = $bt.exec(t._i);
  if (e !== null) {
    t._d = /* @__PURE__ */ new Date(+e[1]);
    return;
  }
  if ($fe(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  if (Kfe(t), t._isValid === !1)
    delete t._isValid;
  else
    return;
  t._strict ? t._isValid = !1 : Y.createFromInputFallback(t);
}
Y.createFromInputFallback = go(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(t) {
    t._d = /* @__PURE__ */ new Date(t._i + (t._useUTC ? " UTC" : ""));
  }
);
function n_(t, e, n) {
  return t ?? e ?? n;
}
function rEt(t) {
  var e = new Date(Y.now());
  return t._useUTC ? [
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate()
  ] : [e.getFullYear(), e.getMonth(), e.getDate()];
}
function M6(t) {
  var e, n, r = [], s, i, o;
  if (!t._d) {
    for (s = rEt(t), t._w && t._a[ba] == null && t._a[Hc] == null && sEt(t), t._dayOfYear != null && (o = n_(t._a[gs], s[gs]), (t._dayOfYear > Iw(o) || t._dayOfYear === 0) && (ve(t)._overflowDayOfYear = !0), n = RT(o, 0, t._dayOfYear), t._a[Hc] = n.getUTCMonth(), t._a[ba] = n.getUTCDate()), e = 0; e < 3 && t._a[e] == null; ++e)
      t._a[e] = r[e] = s[e];
    for (; e < 7; e++)
      t._a[e] = r[e] = t._a[e] == null ? e === 2 ? 1 : 0 : t._a[e];
    t._a[Er] === 24 && t._a[Wo] === 0 && t._a[jc] === 0 && t._a[Jd] === 0 && (t._nextDay = !0, t._a[Er] = 0), t._d = (t._useUTC ? RT : ubt).apply(
      null,
      r
    ), i = t._useUTC ? t._d.getUTCDay() : t._d.getDay(), t._tzm != null && t._d.setUTCMinutes(t._d.getUTCMinutes() - t._tzm), t._nextDay && (t._a[Er] = 24), t._w && typeof t._w.d < "u" && t._w.d !== i && (ve(t).weekdayMismatch = !0);
  }
}
function sEt(t) {
  var e, n, r, s, i, o, a, c, l;
  e = t._w, e.GG != null || e.W != null || e.E != null ? (i = 1, o = 4, n = n_(
    e.GG,
    t._a[gs],
    kT(hn(), 1, 4).year
  ), r = n_(e.W, 1), s = n_(e.E, 1), (s < 1 || s > 7) && (c = !0)) : (i = t._locale._week.dow, o = t._locale._week.doy, l = kT(hn(), i, o), n = n_(e.gg, t._a[gs], l.year), r = n_(e.w, l.week), e.d != null ? (s = e.d, (s < 0 || s > 6) && (c = !0)) : e.e != null ? (s = e.e + i, (e.e < 0 || e.e > 6) && (c = !0)) : s = i), r < 1 || r > _l(n, i, o) ? ve(t)._overflowWeeks = !0 : c != null ? ve(t)._overflowWeekday = !0 : (a = Hfe(n, r, s, i, o), t._a[gs] = a.year, t._dayOfYear = a.dayOfYear);
}
Y.ISO_8601 = function() {
};
Y.RFC_2822 = function() {
};
function x6(t) {
  if (t._f === Y.ISO_8601) {
    $fe(t);
    return;
  }
  if (t._f === Y.RFC_2822) {
    Kfe(t);
    return;
  }
  t._a = [], ve(t).empty = !0;
  var e = "" + t._i, n, r, s, i, o, a = e.length, c = 0, l, h;
  for (s = Ofe(t._f, t._locale).match(b6) || [], h = s.length, n = 0; n < h; n++)
    i = s[n], r = (e.match(VTt(i, t)) || [])[0], r && (o = e.substr(0, e.indexOf(r)), o.length > 0 && ve(t).unusedInput.push(o), e = e.slice(
      e.indexOf(r) + r.length
    ), c += r.length), tm[i] ? (r ? ve(t).empty = !1 : ve(t).unusedTokens.push(i), GTt(i, r, t)) : t._strict && !r && ve(t).unusedTokens.push(i);
  ve(t).charsLeftOver = a - c, e.length > 0 && ve(t).unusedInput.push(e), t._a[Er] <= 12 && ve(t).bigHour === !0 && t._a[Er] > 0 && (ve(t).bigHour = void 0), ve(t).parsedDateParts = t._a.slice(0), ve(t).meridiem = t._meridiem, t._a[Er] = iEt(
    t._locale,
    t._a[Er],
    t._meridiem
  ), l = ve(t).era, l !== null && (t._a[gs] = t._locale.erasConvertYear(l, t._a[gs])), M6(t), A6(t);
}
function iEt(t, e, n) {
  var r;
  return n == null ? e : t.meridiemHour != null ? t.meridiemHour(e, n) : (t.isPM != null && (r = t.isPM(n), r && e < 12 && (e += 12), !r && e === 12 && (e = 0)), e);
}
function oEt(t) {
  var e, n, r, s, i, o, a = !1, c = t._f.length;
  if (c === 0) {
    ve(t).invalidFormat = !0, t._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < c; s++)
    i = 0, o = !1, e = C6({}, t), t._useUTC != null && (e._useUTC = t._useUTC), e._f = t._f[s], x6(e), w6(e) && (o = !0), i += ve(e).charsLeftOver, i += ve(e).unusedTokens.length * 10, ve(e).score = i, a ? i < r && (r = i, n = e) : (r == null || i < r || o) && (r = i, n = e, o && (a = !0));
  Uu(t, n || e);
}
function aEt(t) {
  if (!t._d) {
    var e = E6(t._i), n = e.day === void 0 ? e.date : e.day;
    t._a = kfe(
      [e.year, e.month, n, e.hour, e.minute, e.second, e.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), M6(t);
  }
}
function cEt(t) {
  var e = new yE(A6(Jfe(t)));
  return e._nextDay && (e.add(1, "d"), e._nextDay = void 0), e;
}
function Jfe(t) {
  var e = t._i, n = t._f;
  return t._locale = t._locale || Zl(t._l), e === null || n === void 0 && e === "" ? VD({ nullInput: !0 }) : (typeof e == "string" && (t._i = e = t._locale.preparse(e)), ia(e) ? new yE(A6(e)) : (gE(e) ? t._d = e : sa(n) ? oEt(t) : n ? x6(t) : lEt(t), w6(t) || (t._d = null), t));
}
function lEt(t) {
  var e = t._i;
  ii(e) ? t._d = new Date(Y.now()) : gE(e) ? t._d = new Date(e.valueOf()) : typeof e == "string" ? nEt(t) : sa(e) ? (t._a = kfe(e.slice(0), function(n) {
    return parseInt(n, 10);
  }), M6(t)) : gf(e) ? aEt(t) : kl(e) ? t._d = new Date(e) : Y.createFromInputFallback(t);
}
function Qfe(t, e, n, r, s) {
  var i = {};
  return (e === !0 || e === !1) && (r = e, e = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (gf(t) && v6(t) || sa(t) && t.length === 0) && (t = void 0), i._isAMomentObject = !0, i._useUTC = i._isUTC = s, i._l = n, i._i = t, i._f = e, i._strict = r, cEt(i);
}
function hn(t, e, n, r) {
  return Qfe(t, e, n, r, !1);
}
var uEt = go(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = hn.apply(null, arguments);
    return this.isValid() && t.isValid() ? t < this ? this : t : VD();
  }
), hEt = go(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var t = hn.apply(null, arguments);
    return this.isValid() && t.isValid() ? t > this ? this : t : VD();
  }
);
function Zfe(t, e) {
  var n, r;
  if (e.length === 1 && sa(e[0]) && (e = e[0]), !e.length)
    return hn();
  for (n = e[0], r = 1; r < e.length; ++r)
    (!e[r].isValid() || e[r][t](n)) && (n = e[r]);
  return n;
}
function dEt() {
  var t = [].slice.call(arguments, 0);
  return Zfe("isBefore", t);
}
function fEt() {
  var t = [].slice.call(arguments, 0);
  return Zfe("isAfter", t);
}
var pEt = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, dv = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function _Et(t) {
  var e, n = !1, r, s = dv.length;
  for (e in t)
    if (yt(t, e) && !(sr.call(dv, e) !== -1 && (t[e] == null || !isNaN(t[e]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (t[dv[r]]) {
      if (n)
        return !1;
      parseFloat(t[dv[r]]) !== Ge(t[dv[r]]) && (n = !0);
    }
  return !0;
}
function mEt() {
  return this._isValid;
}
function gEt() {
  return ha(NaN);
}
function XD(t) {
  var e = E6(t), n = e.year || 0, r = e.quarter || 0, s = e.month || 0, i = e.week || e.isoWeek || 0, o = e.day || 0, a = e.hour || 0, c = e.minute || 0, l = e.second || 0, h = e.millisecond || 0;
  this._isValid = _Et(e), this._milliseconds = +h + l * 1e3 + // 1000
  c * 6e4 + // 1000 * 60
  a * 1e3 * 60 * 60, this._days = +o + i * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = Zl(), this._bubble();
}
function aI(t) {
  return t instanceof XD;
}
function oY(t) {
  return t < 0 ? Math.round(-1 * t) * -1 : Math.round(t);
}
function yEt(t, e, n) {
  var r = Math.min(t.length, e.length), s = Math.abs(t.length - e.length), i = 0, o;
  for (o = 0; o < r; o++)
    Ge(t[o]) !== Ge(e[o]) && i++;
  return i + s;
}
function Xfe(t, e) {
  se(t, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + qa(~~(n / 60), 2) + e + qa(~~n % 60, 2);
  });
}
Xfe("Z", ":");
Xfe("ZZ", "");
V("Z", JD);
V("ZZ", JD);
Ht(["Z", "ZZ"], function(t, e, n) {
  n._useUTC = !0, n._tzm = L6(JD, t);
});
var vEt = /([\+\-]|\d\d)/gi;
function L6(t, e) {
  var n = (e || "").match(t), r, s, i;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(vEt) || ["-", 0, 0], i = +(s[1] * 60) + Ge(s[2]), i === 0 ? 0 : s[0] === "+" ? i : -i);
}
function U6(t, e) {
  var n, r;
  return e._isUTC ? (n = e.clone(), r = (ia(t) || gE(t) ? t.valueOf() : hn(t).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), Y.updateOffset(n, !1), n) : hn(t).local();
}
function aY(t) {
  return -Math.round(t._d.getTimezoneOffset());
}
Y.updateOffset = function() {
};
function wEt(t, e, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return t != null ? this : NaN;
  if (t != null) {
    if (typeof t == "string") {
      if (t = L6(JD, t), t === null)
        return this;
    } else Math.abs(t) < 16 && !n && (t = t * 60);
    return !this._isUTC && e && (s = aY(this)), this._offset = t, this._isUTC = !0, s != null && this.add(s, "m"), r !== t && (!e || this._changeInProgress ? npe(
      this,
      ha(t - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, Y.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : aY(this);
}
function CEt(t, e) {
  return t != null ? (typeof t != "string" && (t = -t), this.utcOffset(t, e), this) : -this.utcOffset();
}
function TEt(t) {
  return this.utcOffset(0, t);
}
function bEt(t) {
  return this._isUTC && (this.utcOffset(0, t), this._isUTC = !1, t && this.subtract(aY(this), "m")), this;
}
function EEt() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var t = L6(HTt, this._i);
    t != null ? this.utcOffset(t) : this.utcOffset(0, !0);
  }
  return this;
}
function SEt(t) {
  return this.isValid() ? (t = t ? hn(t).utcOffset() : 0, (this.utcOffset() - t) % 60 === 0) : !1;
}
function IEt() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function REt() {
  if (!ii(this._isDSTShifted))
    return this._isDSTShifted;
  var t = {}, e;
  return C6(t, this), t = Jfe(t), t._a ? (e = t._isUTC ? oc(t._a) : hn(t._a), this._isDSTShifted = this.isValid() && yEt(t._a, e.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function kEt() {
  return this.isValid() ? !this._isUTC : !1;
}
function NEt() {
  return this.isValid() ? this._isUTC : !1;
}
function epe() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var PEt = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, OEt = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function ha(t, e) {
  var n = t, r = null, s, i, o;
  return aI(t) ? n = {
    ms: t._milliseconds,
    d: t._days,
    M: t._months
  } : kl(t) || !isNaN(+t) ? (n = {}, e ? n[e] = +t : n.milliseconds = +t) : (r = PEt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: Ge(r[ba]) * s,
    h: Ge(r[Er]) * s,
    m: Ge(r[Wo]) * s,
    s: Ge(r[jc]) * s,
    ms: Ge(oY(r[Jd] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = OEt.exec(t)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: yd(r[2], s),
    M: yd(r[3], s),
    w: yd(r[4], s),
    d: yd(r[5], s),
    h: yd(r[6], s),
    m: yd(r[7], s),
    s: yd(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (o = DEt(
    hn(n.from),
    hn(n.to)
  ), n = {}, n.ms = o.milliseconds, n.M = o.months), i = new XD(n), aI(t) && yt(t, "_locale") && (i._locale = t._locale), aI(t) && yt(t, "_isValid") && (i._isValid = t._isValid), i;
}
ha.fn = XD.prototype;
ha.invalid = gEt;
function yd(t, e) {
  var n = t && parseFloat(t.replace(",", "."));
  return (isNaN(n) ? 0 : n) * e;
}
function c7(t, e) {
  var n = {};
  return n.months = e.month() - t.month() + (e.year() - t.year()) * 12, t.clone().add(n.months, "M").isAfter(e) && --n.months, n.milliseconds = +e - +t.clone().add(n.months, "M"), n;
}
function DEt(t, e) {
  var n;
  return t.isValid() && e.isValid() ? (e = U6(e, t), t.isBefore(e) ? n = c7(t, e) : (n = c7(e, t), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function tpe(t, e) {
  return function(n, r) {
    var s, i;
    return r !== null && !isNaN(+r) && (Pfe(
      e,
      "moment()." + e + "(period, number) is deprecated. Please use moment()." + e + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), i = n, n = r, r = i), s = ha(n, r), npe(this, s, t), this;
  };
}
function npe(t, e, n, r) {
  var s = e._milliseconds, i = oY(e._days), o = oY(e._months);
  t.isValid() && (r = r ?? !0, o && Wfe(t, IT(t, "Month") + o * n), i && Ufe(t, "Date", IT(t, "Date") + i * n), s && t._d.setTime(t._d.valueOf() + s * n), r && Y.updateOffset(t, i || o));
}
var AEt = tpe(1, "add"), MEt = tpe(-1, "subtract");
function rpe(t) {
  return typeof t == "string" || t instanceof String;
}
function xEt(t) {
  return ia(t) || gE(t) || rpe(t) || kl(t) || UEt(t) || LEt(t) || t === null || t === void 0;
}
function LEt(t) {
  var e = gf(t) && !v6(t), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, i, o = r.length;
  for (s = 0; s < o; s += 1)
    i = r[s], n = n || yt(t, i);
  return e && n;
}
function UEt(t) {
  var e = sa(t), n = !1;
  return e && (n = t.filter(function(r) {
    return !kl(r) && rpe(t);
  }).length === 0), e && n;
}
function FEt(t) {
  var e = gf(t) && !v6(t), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, i;
  for (s = 0; s < r.length; s += 1)
    i = r[s], n = n || yt(t, i);
  return e && n;
}
function YEt(t, e) {
  var n = t.diff(e, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function WEt(t, e) {
  arguments.length === 1 && (arguments[0] ? xEt(arguments[0]) ? (t = arguments[0], e = void 0) : FEt(arguments[0]) && (e = arguments[0], t = void 0) : (t = void 0, e = void 0));
  var n = t || hn(), r = U6(n, this).startOf("day"), s = Y.calendarFormat(this, r) || "sameElse", i = e && (ac(e[s]) ? e[s].call(this, n) : e[s]);
  return this.format(
    i || this.localeData().calendar(s, this, hn(n))
  );
}
function BEt() {
  return new yE(this);
}
function qEt(t, e) {
  var n = ia(t) ? t : hn(t);
  return this.isValid() && n.isValid() ? (e = yo(e) || "millisecond", e === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(e).valueOf()) : !1;
}
function HEt(t, e) {
  var n = ia(t) ? t : hn(t);
  return this.isValid() && n.isValid() ? (e = yo(e) || "millisecond", e === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(e).valueOf() < n.valueOf()) : !1;
}
function jEt(t, e, n, r) {
  var s = ia(t) ? t : hn(t), i = ia(e) ? e : hn(e);
  return this.isValid() && s.isValid() && i.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(i, n) : !this.isAfter(i, n))) : !1;
}
function VEt(t, e) {
  var n = ia(t) ? t : hn(t), r;
  return this.isValid() && n.isValid() ? (e = yo(e) || "millisecond", e === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(e).valueOf() <= r && r <= this.clone().endOf(e).valueOf())) : !1;
}
function zEt(t, e) {
  return this.isSame(t, e) || this.isAfter(t, e);
}
function GEt(t, e) {
  return this.isSame(t, e) || this.isBefore(t, e);
}
function $Et(t, e, n) {
  var r, s, i;
  if (!this.isValid())
    return NaN;
  if (r = U6(t, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, e = yo(e), e) {
    case "year":
      i = cI(this, r) / 12;
      break;
    case "month":
      i = cI(this, r);
      break;
    case "quarter":
      i = cI(this, r) / 3;
      break;
    case "second":
      i = (this - r) / 1e3;
      break;
    case "minute":
      i = (this - r) / 6e4;
      break;
    case "hour":
      i = (this - r) / 36e5;
      break;
    case "day":
      i = (this - r - s) / 864e5;
      break;
    case "week":
      i = (this - r - s) / 6048e5;
      break;
    default:
      i = this - r;
  }
  return n ? i : ji(i);
}
function cI(t, e) {
  if (t.date() < e.date())
    return -cI(e, t);
  var n = (e.year() - t.year()) * 12 + (e.month() - t.month()), r = t.clone().add(n, "months"), s, i;
  return e - r < 0 ? (s = t.clone().add(n - 1, "months"), i = (e - r) / (r - s)) : (s = t.clone().add(n + 1, "months"), i = (e - r) / (s - r)), -(n + i) || 0;
}
Y.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
Y.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function KEt() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function JEt(t) {
  if (!this.isValid())
    return null;
  var e = t !== !0, n = e ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? oI(
    n,
    e ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : ac(Date.prototype.toISOString) ? e ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", oI(n, "Z")) : oI(
    n,
    e ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function QEt() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var t = "moment", e = "", n, r, s, i;
  return this.isLocal() || (t = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", e = "Z"), n = "[" + t + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", i = e + '[")]', this.format(n + r + s + i);
}
function ZEt(t) {
  t || (t = this.isUtc() ? Y.defaultFormatUtc : Y.defaultFormat);
  var e = oI(this, t);
  return this.localeData().postformat(e);
}
function XEt(t, e) {
  return this.isValid() && (ia(t) && t.isValid() || hn(t).isValid()) ? ha({ to: this, from: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function eSt(t) {
  return this.from(hn(), t);
}
function tSt(t, e) {
  return this.isValid() && (ia(t) && t.isValid() || hn(t).isValid()) ? ha({ from: this, to: t }).locale(this.locale()).humanize(!e) : this.localeData().invalidDate();
}
function nSt(t) {
  return this.to(hn(), t);
}
function spe(t) {
  var e;
  return t === void 0 ? this._locale._abbr : (e = Zl(t), e != null && (this._locale = e), this);
}
var ipe = go(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(t) {
    return t === void 0 ? this.localeData() : this.locale(t);
  }
);
function ope() {
  return this._locale;
}
var AN = 1e3, nm = 60 * AN, MN = 60 * nm, ape = (365 * 400 + 97) * 24 * MN;
function rm(t, e) {
  return (t % e + e) % e;
}
function cpe(t, e, n) {
  return t < 100 && t >= 0 ? new Date(t + 400, e, n) - ape : new Date(t, e, n).valueOf();
}
function lpe(t, e, n) {
  return t < 100 && t >= 0 ? Date.UTC(t + 400, e, n) - ape : Date.UTC(t, e, n);
}
function rSt(t) {
  var e, n;
  if (t = yo(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? lpe : cpe, t) {
    case "year":
      e = n(this.year(), 0, 1);
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      e = n(this.year(), this.month(), 1);
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      e = this._d.valueOf(), e -= rm(
        e + (this._isUTC ? 0 : this.utcOffset() * nm),
        MN
      );
      break;
    case "minute":
      e = this._d.valueOf(), e -= rm(e, nm);
      break;
    case "second":
      e = this._d.valueOf(), e -= rm(e, AN);
      break;
  }
  return this._d.setTime(e), Y.updateOffset(this, !0), this;
}
function sSt(t) {
  var e, n;
  if (t = yo(t), t === void 0 || t === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? lpe : cpe, t) {
    case "year":
      e = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      e = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      e = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      e = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      e = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      e = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      e = this._d.valueOf(), e += MN - rm(
        e + (this._isUTC ? 0 : this.utcOffset() * nm),
        MN
      ) - 1;
      break;
    case "minute":
      e = this._d.valueOf(), e += nm - rm(e, nm) - 1;
      break;
    case "second":
      e = this._d.valueOf(), e += AN - rm(e, AN) - 1;
      break;
  }
  return this._d.setTime(e), Y.updateOffset(this, !0), this;
}
function iSt() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function oSt() {
  return Math.floor(this.valueOf() / 1e3);
}
function aSt() {
  return new Date(this.valueOf());
}
function cSt() {
  var t = this;
  return [
    t.year(),
    t.month(),
    t.date(),
    t.hour(),
    t.minute(),
    t.second(),
    t.millisecond()
  ];
}
function lSt() {
  var t = this;
  return {
    years: t.year(),
    months: t.month(),
    date: t.date(),
    hours: t.hours(),
    minutes: t.minutes(),
    seconds: t.seconds(),
    milliseconds: t.milliseconds()
  };
}
function uSt() {
  return this.isValid() ? this.toISOString() : null;
}
function hSt() {
  return w6(this);
}
function dSt() {
  return Uu({}, ve(this));
}
function fSt() {
  return ve(this).overflow;
}
function pSt() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
se("N", 0, 0, "eraAbbr");
se("NN", 0, 0, "eraAbbr");
se("NNN", 0, 0, "eraAbbr");
se("NNNN", 0, 0, "eraName");
se("NNNNN", 0, 0, "eraNarrow");
se("y", ["y", 1], "yo", "eraYear");
se("y", ["yy", 2], 0, "eraYear");
se("y", ["yyy", 3], 0, "eraYear");
se("y", ["yyyy", 4], 0, "eraYear");
V("N", F6);
V("NN", F6);
V("NNN", F6);
V("NNNN", SSt);
V("NNNNN", ISt);
Ht(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(t, e, n, r) {
    var s = n._locale.erasParse(t, r, n._strict);
    s ? ve(n).era = s : ve(n).invalidEra = t;
  }
);
V("y", Hg);
V("yy", Hg);
V("yyy", Hg);
V("yyyy", Hg);
V("yo", RSt);
Ht(["y", "yy", "yyy", "yyyy"], gs);
Ht(["yo"], function(t, e, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = t.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? e[gs] = n._locale.eraYearOrdinalParse(t, s) : e[gs] = parseInt(t, 10);
});
function _St(t, e) {
  var n, r, s, i = this._eras || Zl("en")._eras;
  for (n = 0, r = i.length; n < r; ++n) {
    switch (typeof i[n].since) {
      case "string":
        s = Y(i[n].since).startOf("day"), i[n].since = s.valueOf();
        break;
    }
    switch (typeof i[n].until) {
      case "undefined":
        i[n].until = 1 / 0;
        break;
      case "string":
        s = Y(i[n].until).startOf("day").valueOf(), i[n].until = s.valueOf();
        break;
    }
  }
  return i;
}
function mSt(t, e, n) {
  var r, s, i = this.eras(), o, a, c;
  for (t = t.toUpperCase(), r = 0, s = i.length; r < s; ++r)
    if (o = i[r].name.toUpperCase(), a = i[r].abbr.toUpperCase(), c = i[r].narrow.toUpperCase(), n)
      switch (e) {
        case "N":
        case "NN":
        case "NNN":
          if (a === t)
            return i[r];
          break;
        case "NNNN":
          if (o === t)
            return i[r];
          break;
        case "NNNNN":
          if (c === t)
            return i[r];
          break;
      }
    else if ([o, a, c].indexOf(t) >= 0)
      return i[r];
}
function gSt(t, e) {
  var n = t.since <= t.until ? 1 : -1;
  return e === void 0 ? Y(t.since).year() : Y(t.since).year() + (e - t.offset) * n;
}
function ySt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].name;
  return "";
}
function vSt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].narrow;
  return "";
}
function wSt() {
  var t, e, n, r = this.localeData().eras();
  for (t = 0, e = r.length; t < e; ++t)
    if (n = this.clone().startOf("day").valueOf(), r[t].since <= n && n <= r[t].until || r[t].until <= n && n <= r[t].since)
      return r[t].abbr;
  return "";
}
function CSt() {
  var t, e, n, r, s = this.localeData().eras();
  for (t = 0, e = s.length; t < e; ++t)
    if (n = s[t].since <= s[t].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[t].since <= r && r <= s[t].until || s[t].until <= r && r <= s[t].since)
      return (this.year() - Y(s[t].since).year()) * n + s[t].offset;
  return this.year();
}
function TSt(t) {
  return yt(this, "_erasNameRegex") || Y6.call(this), t ? this._erasNameRegex : this._erasRegex;
}
function bSt(t) {
  return yt(this, "_erasAbbrRegex") || Y6.call(this), t ? this._erasAbbrRegex : this._erasRegex;
}
function ESt(t) {
  return yt(this, "_erasNarrowRegex") || Y6.call(this), t ? this._erasNarrowRegex : this._erasRegex;
}
function F6(t, e) {
  return e.erasAbbrRegex(t);
}
function SSt(t, e) {
  return e.erasNameRegex(t);
}
function ISt(t, e) {
  return e.erasNarrowRegex(t);
}
function RSt(t, e) {
  return e._eraYearOrdinalRegex || Hg;
}
function Y6() {
  var t = [], e = [], n = [], r = [], s, i, o, a, c, l = this.eras();
  for (s = 0, i = l.length; s < i; ++s)
    o = pl(l[s].name), a = pl(l[s].abbr), c = pl(l[s].narrow), e.push(o), t.push(a), n.push(c), r.push(o), r.push(a), r.push(c);
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
se(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
se(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function eA(t, e) {
  se(0, [t, t.length], 0, e);
}
eA("gggg", "weekYear");
eA("ggggg", "weekYear");
eA("GGGG", "isoWeekYear");
eA("GGGGG", "isoWeekYear");
V("G", KD);
V("g", KD);
V("GG", gn, Di);
V("gg", gn, Di);
V("GGGG", I6, S6);
V("gggg", I6, S6);
V("GGGGG", $D, zD);
V("ggggg", $D, zD);
wE(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(t, e, n, r) {
    e[r.substr(0, 2)] = Ge(t);
  }
);
wE(["gg", "GG"], function(t, e, n, r) {
  e[r] = Y.parseTwoDigitYear(t);
});
function kSt(t) {
  return upe.call(
    this,
    t,
    this.week(),
    this.weekday() + this.localeData()._week.dow,
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function NSt(t) {
  return upe.call(
    this,
    t,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function PSt() {
  return _l(this.year(), 1, 4);
}
function OSt() {
  return _l(this.isoWeekYear(), 1, 4);
}
function DSt() {
  var t = this.localeData()._week;
  return _l(this.year(), t.dow, t.doy);
}
function ASt() {
  var t = this.localeData()._week;
  return _l(this.weekYear(), t.dow, t.doy);
}
function upe(t, e, n, r, s) {
  var i;
  return t == null ? kT(this, r, s).year : (i = _l(t, r, s), e > i && (e = i), MSt.call(this, t, e, n, r, s));
}
function MSt(t, e, n, r, s) {
  var i = Hfe(t, e, n, r, s), o = RT(i.year, 0, i.dayOfYear);
  return this.year(o.getUTCFullYear()), this.month(o.getUTCMonth()), this.date(o.getUTCDate()), this;
}
se("Q", 0, "Qo", "quarter");
V("Q", Dfe);
Ht("Q", function(t, e) {
  e[Hc] = (Ge(t) - 1) * 3;
});
function xSt(t) {
  return t == null ? Math.ceil((this.month() + 1) / 3) : this.month((t - 1) * 3 + this.month() % 3);
}
se("D", ["DD", 2], "Do", "date");
V("D", gn, jg);
V("DD", gn, Di);
V("Do", function(t, e) {
  return t ? e._dayOfMonthOrdinalParse || e._ordinalParse : e._dayOfMonthOrdinalParseLenient;
});
Ht(["D", "DD"], ba);
Ht("Do", function(t, e) {
  e[ba] = Ge(t.match(gn)[0]);
});
var hpe = Vg("Date", !0);
se("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
V("DDD", GD);
V("DDDD", Afe);
Ht(["DDD", "DDDD"], function(t, e, n) {
  n._dayOfYear = Ge(t);
});
function LSt(t) {
  var e = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return t == null ? e : this.add(t - e, "d");
}
se("m", ["mm", 2], 0, "minute");
V("m", gn, R6);
V("mm", gn, Di);
Ht(["m", "mm"], Wo);
var USt = Vg("Minutes", !1);
se("s", ["ss", 2], 0, "second");
V("s", gn, R6);
V("ss", gn, Di);
Ht(["s", "ss"], jc);
var FSt = Vg("Seconds", !1);
se("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
se(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
se(0, ["SSS", 3], 0, "millisecond");
se(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
se(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
se(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
se(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
se(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
se(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
V("S", GD, Dfe);
V("SS", GD, Di);
V("SSS", GD, Afe);
var Fu, dpe;
for (Fu = "SSSS"; Fu.length <= 9; Fu += "S")
  V(Fu, Hg);
function YSt(t, e) {
  e[Jd] = Ge(("0." + t) * 1e3);
}
for (Fu = "S"; Fu.length <= 9; Fu += "S")
  Ht(Fu, YSt);
dpe = Vg("Milliseconds", !1);
se("z", 0, 0, "zoneAbbr");
se("zz", 0, 0, "zoneName");
function WSt() {
  return this._isUTC ? "UTC" : "";
}
function BSt() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var I = yE.prototype;
I.add = AEt;
I.calendar = WEt;
I.clone = BEt;
I.diff = $Et;
I.endOf = sSt;
I.format = ZEt;
I.from = XEt;
I.fromNow = eSt;
I.to = tSt;
I.toNow = nSt;
I.get = QTt;
I.invalidAt = fSt;
I.isAfter = qEt;
I.isBefore = HEt;
I.isBetween = jEt;
I.isSame = VEt;
I.isSameOrAfter = zEt;
I.isSameOrBefore = GEt;
I.isValid = hSt;
I.lang = ipe;
I.locale = spe;
I.localeData = ope;
I.max = hEt;
I.min = uEt;
I.parsingFlags = dSt;
I.set = ZTt;
I.startOf = rSt;
I.subtract = MEt;
I.toArray = cSt;
I.toObject = lSt;
I.toDate = aSt;
I.toISOString = JEt;
I.inspect = QEt;
typeof Symbol < "u" && Symbol.for != null && (I[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
I.toJSON = uSt;
I.toString = KEt;
I.unix = oSt;
I.valueOf = iSt;
I.creationData = pSt;
I.eraName = ySt;
I.eraNarrow = vSt;
I.eraAbbr = wSt;
I.eraYear = CSt;
I.year = Lfe;
I.isLeapYear = JTt;
I.weekYear = kSt;
I.isoWeekYear = NSt;
I.quarter = I.quarters = xSt;
I.month = Bfe;
I.daysInMonth = abt;
I.week = I.weeks = _bt;
I.isoWeek = I.isoWeeks = mbt;
I.weeksInYear = DSt;
I.weeksInWeekYear = ASt;
I.isoWeeksInYear = PSt;
I.isoWeeksInISOWeekYear = OSt;
I.date = hpe;
I.day = I.days = Nbt;
I.weekday = Pbt;
I.isoWeekday = Obt;
I.dayOfYear = LSt;
I.hour = I.hours = Fbt;
I.minute = I.minutes = USt;
I.second = I.seconds = FSt;
I.millisecond = I.milliseconds = dpe;
I.utcOffset = wEt;
I.utc = TEt;
I.local = bEt;
I.parseZone = EEt;
I.hasAlignedHourOffset = SEt;
I.isDST = IEt;
I.isLocal = kEt;
I.isUtcOffset = NEt;
I.isUtc = epe;
I.isUTC = epe;
I.zoneAbbr = WSt;
I.zoneName = BSt;
I.dates = go(
  "dates accessor is deprecated. Use date instead.",
  hpe
);
I.months = go(
  "months accessor is deprecated. Use month instead",
  Bfe
);
I.years = go(
  "years accessor is deprecated. Use year instead",
  Lfe
);
I.zone = go(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  CEt
);
I.isDSTShifted = go(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  REt
);
function qSt(t) {
  return hn(t * 1e3);
}
function HSt() {
  return hn.apply(null, arguments).parseZone();
}
function fpe(t) {
  return t;
}
var Et = T6.prototype;
Et.calendar = kTt;
Et.longDateFormat = DTt;
Et.invalidDate = MTt;
Et.ordinal = UTt;
Et.preparse = fpe;
Et.postformat = fpe;
Et.relativeTime = YTt;
Et.pastFuture = WTt;
Et.set = ITt;
Et.eras = _St;
Et.erasParse = mSt;
Et.erasConvertYear = gSt;
Et.erasAbbrRegex = bSt;
Et.erasNameRegex = TSt;
Et.erasNarrowRegex = ESt;
Et.months = rbt;
Et.monthsShort = sbt;
Et.monthsParse = obt;
Et.monthsRegex = lbt;
Et.monthsShortRegex = cbt;
Et.week = hbt;
Et.firstDayOfYear = pbt;
Et.firstDayOfWeek = fbt;
Et.weekdays = Ebt;
Et.weekdaysMin = Ibt;
Et.weekdaysShort = Sbt;
Et.weekdaysParse = kbt;
Et.weekdaysRegex = Dbt;
Et.weekdaysShortRegex = Abt;
Et.weekdaysMinRegex = Mbt;
Et.isPM = Lbt;
Et.meridiem = Ybt;
function xN(t, e, n, r) {
  var s = Zl(), i = oc().set(r, e);
  return s[n](i, t);
}
function ppe(t, e, n) {
  if (kl(t) && (e = t, t = void 0), t = t || "", e != null)
    return xN(t, e, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = xN(t, r, n, "month");
  return s;
}
function W6(t, e, n, r) {
  typeof t == "boolean" ? (kl(e) && (n = e, e = void 0), e = e || "") : (e = t, n = e, t = !1, kl(e) && (n = e, e = void 0), e = e || "");
  var s = Zl(), i = t ? s._week.dow : 0, o, a = [];
  if (n != null)
    return xN(e, (n + i) % 7, r, "day");
  for (o = 0; o < 7; o++)
    a[o] = xN(e, (o + i) % 7, r, "day");
  return a;
}
function jSt(t, e) {
  return ppe(t, e, "months");
}
function VSt(t, e) {
  return ppe(t, e, "monthsShort");
}
function zSt(t, e, n) {
  return W6(t, e, n, "weekdays");
}
function GSt(t, e, n) {
  return W6(t, e, n, "weekdaysShort");
}
function $St(t, e, n) {
  return W6(t, e, n, "weekdaysMin");
}
yh("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(t) {
    var e = t % 10, n = Ge(t % 100 / 10) === 1 ? "th" : e === 1 ? "st" : e === 2 ? "nd" : e === 3 ? "rd" : "th";
    return t + n;
  }
});
Y.lang = go(
  "moment.lang is deprecated. Use moment.locale instead.",
  yh
);
Y.langData = go(
  "moment.langData is deprecated. Use moment.localeData instead.",
  Zl
);
var pc = Math.abs;
function KSt() {
  var t = this._data;
  return this._milliseconds = pc(this._milliseconds), this._days = pc(this._days), this._months = pc(this._months), t.milliseconds = pc(t.milliseconds), t.seconds = pc(t.seconds), t.minutes = pc(t.minutes), t.hours = pc(t.hours), t.months = pc(t.months), t.years = pc(t.years), this;
}
function _pe(t, e, n, r) {
  var s = ha(e, n);
  return t._milliseconds += r * s._milliseconds, t._days += r * s._days, t._months += r * s._months, t._bubble();
}
function JSt(t, e) {
  return _pe(this, t, e, 1);
}
function QSt(t, e) {
  return _pe(this, t, e, -1);
}
function l7(t) {
  return t < 0 ? Math.floor(t) : Math.ceil(t);
}
function ZSt() {
  var t = this._milliseconds, e = this._days, n = this._months, r = this._data, s, i, o, a, c;
  return t >= 0 && e >= 0 && n >= 0 || t <= 0 && e <= 0 && n <= 0 || (t += l7(cY(n) + e) * 864e5, e = 0, n = 0), r.milliseconds = t % 1e3, s = ji(t / 1e3), r.seconds = s % 60, i = ji(s / 60), r.minutes = i % 60, o = ji(i / 60), r.hours = o % 24, e += ji(o / 24), c = ji(mpe(e)), n += c, e -= l7(cY(c)), a = ji(n / 12), n %= 12, r.days = e, r.months = n, r.years = a, this;
}
function mpe(t) {
  return t * 4800 / 146097;
}
function cY(t) {
  return t * 146097 / 4800;
}
function XSt(t) {
  if (!this.isValid())
    return NaN;
  var e, n, r = this._milliseconds;
  if (t = yo(t), t === "month" || t === "quarter" || t === "year")
    switch (e = this._days + r / 864e5, n = this._months + mpe(e), t) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (e = this._days + Math.round(cY(this._months)), t) {
      case "week":
        return e / 7 + r / 6048e5;
      case "day":
        return e + r / 864e5;
      case "hour":
        return e * 24 + r / 36e5;
      case "minute":
        return e * 1440 + r / 6e4;
      case "second":
        return e * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(e * 864e5) + r;
      default:
        throw new Error("Unknown unit " + t);
    }
}
function Xl(t) {
  return function() {
    return this.as(t);
  };
}
var gpe = Xl("ms"), e0t = Xl("s"), t0t = Xl("m"), n0t = Xl("h"), r0t = Xl("d"), s0t = Xl("w"), i0t = Xl("M"), o0t = Xl("Q"), a0t = Xl("y"), c0t = gpe;
function l0t() {
  return ha(this);
}
function u0t(t) {
  return t = yo(t), this.isValid() ? this[t + "s"]() : NaN;
}
function Pp(t) {
  return function() {
    return this.isValid() ? this._data[t] : NaN;
  };
}
var h0t = Pp("milliseconds"), d0t = Pp("seconds"), f0t = Pp("minutes"), p0t = Pp("hours"), _0t = Pp("days"), m0t = Pp("months"), g0t = Pp("years");
function y0t() {
  return ji(this.days() / 7);
}
var wc = Math.round, p_ = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function v0t(t, e, n, r, s) {
  return s.relativeTime(e || 1, !!n, t, r);
}
function w0t(t, e, n, r) {
  var s = ha(t).abs(), i = wc(s.as("s")), o = wc(s.as("m")), a = wc(s.as("h")), c = wc(s.as("d")), l = wc(s.as("M")), h = wc(s.as("w")), u = wc(s.as("y")), d = i <= n.ss && ["s", i] || i < n.s && ["ss", i] || o <= 1 && ["m"] || o < n.m && ["mm", o] || a <= 1 && ["h"] || a < n.h && ["hh", a] || c <= 1 && ["d"] || c < n.d && ["dd", c];
  return n.w != null && (d = d || h <= 1 && ["w"] || h < n.w && ["ww", h]), d = d || l <= 1 && ["M"] || l < n.M && ["MM", l] || u <= 1 && ["y"] || ["yy", u], d[2] = e, d[3] = +t > 0, d[4] = r, v0t.apply(null, d);
}
function C0t(t) {
  return t === void 0 ? wc : typeof t == "function" ? (wc = t, !0) : !1;
}
function T0t(t, e) {
  return p_[t] === void 0 ? !1 : e === void 0 ? p_[t] : (p_[t] = e, t === "s" && (p_.ss = e - 1), !0);
}
function b0t(t, e) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = p_, s, i;
  return typeof t == "object" && (e = t, t = !1), typeof t == "boolean" && (n = t), typeof e == "object" && (r = Object.assign({}, p_, e), e.s != null && e.ss == null && (r.ss = e.s - 1)), s = this.localeData(), i = w0t(this, !n, r, s), n && (i = s.pastFuture(+this, i)), s.postformat(i);
}
var Ex = Math.abs;
function jp(t) {
  return (t > 0) - (t < 0) || +t;
}
function tA() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var t = Ex(this._milliseconds) / 1e3, e = Ex(this._days), n = Ex(this._months), r, s, i, o, a = this.asSeconds(), c, l, h, u;
  return a ? (r = ji(t / 60), s = ji(r / 60), t %= 60, r %= 60, i = ji(n / 12), n %= 12, o = t ? t.toFixed(3).replace(/\.?0+$/, "") : "", c = a < 0 ? "-" : "", l = jp(this._months) !== jp(a) ? "-" : "", h = jp(this._days) !== jp(a) ? "-" : "", u = jp(this._milliseconds) !== jp(a) ? "-" : "", c + "P" + (i ? l + i + "Y" : "") + (n ? l + n + "M" : "") + (e ? h + e + "D" : "") + (s || r || t ? "T" : "") + (s ? u + s + "H" : "") + (r ? u + r + "M" : "") + (t ? u + o + "S" : "")) : "P0D";
}
var Xe = XD.prototype;
Xe.isValid = mEt;
Xe.abs = KSt;
Xe.add = JSt;
Xe.subtract = QSt;
Xe.as = XSt;
Xe.asMilliseconds = gpe;
Xe.asSeconds = e0t;
Xe.asMinutes = t0t;
Xe.asHours = n0t;
Xe.asDays = r0t;
Xe.asWeeks = s0t;
Xe.asMonths = i0t;
Xe.asQuarters = o0t;
Xe.asYears = a0t;
Xe.valueOf = c0t;
Xe._bubble = ZSt;
Xe.clone = l0t;
Xe.get = u0t;
Xe.milliseconds = h0t;
Xe.seconds = d0t;
Xe.minutes = f0t;
Xe.hours = p0t;
Xe.days = _0t;
Xe.weeks = y0t;
Xe.months = m0t;
Xe.years = g0t;
Xe.humanize = b0t;
Xe.toISOString = tA;
Xe.toString = tA;
Xe.toJSON = tA;
Xe.locale = spe;
Xe.localeData = ope;
Xe.toIsoString = go(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  tA
);
Xe.lang = ipe;
se("X", 0, 0, "unix");
se("x", 0, 0, "valueOf");
V("x", KD);
V("X", jTt);
Ht("X", function(t, e, n) {
  n._d = new Date(parseFloat(t) * 1e3);
});
Ht("x", function(t, e, n) {
  n._d = new Date(Ge(t));
});
Y.version = "2.30.1";
ETt(hn);
Y.fn = I;
Y.min = dEt;
Y.max = fEt;
Y.now = pEt;
Y.utc = oc;
Y.unix = qSt;
Y.months = jSt;
Y.isDate = gE;
Y.locale = yh;
Y.invalid = VD;
Y.duration = ha;
Y.isMoment = ia;
Y.weekdays = zSt;
Y.parseZone = HSt;
Y.localeData = Zl;
Y.isDuration = aI;
Y.monthsShort = VSt;
Y.weekdaysMin = $St;
Y.defineLocale = D6;
Y.updateLocale = Hbt;
Y.locales = jbt;
Y.weekdaysShort = GSt;
Y.normalizeUnits = yo;
Y.relativeTimeRounding = C0t;
Y.relativeTimeThreshold = T0t;
Y.calendarFormat = YEt;
Y.prototype = I;
Y.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
function ype(t, e) {
  var n = {};
  for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function vpe() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const E0t = vpe, wpe = new MT("auth", "Firebase", vpe());
const LN = new dY("@firebase/auth");
function S0t(t, ...e) {
  LN.logLevel <= St.WARN && LN.warn(`Auth (${xT}): ${t}`, ...e);
}
function lI(t, ...e) {
  LN.logLevel <= St.ERROR && LN.error(`Auth (${xT}): ${t}`, ...e);
}
function PT(t, ...e) {
  throw q6(t, ...e);
}
function B6(t, ...e) {
  return q6(t, ...e);
}
function I0t(t, e, n) {
  const r = Object.assign(Object.assign({}, E0t()), { [e]: n });
  return new MT("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function q6(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return wpe.create(t, ...e);
}
function it(t, e, ...n) {
  if (!t)
    throw q6(e, ...n);
}
function Vc(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw lI(e), new Error(e);
}
function UN(t, e) {
  t || Vc(e);
}
function R0t() {
  return u7() === "http:" || u7() === "https:";
}
function u7() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
function k0t() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (R0t() || s_e() || "connection" in navigator) ? navigator.onLine : !0;
}
function N0t() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
class CE {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, UN(n > e, "Short delay should be less than long delay!"), this.isMobile = uY() || k7();
  }
  get() {
    return k0t() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
function P0t(t, e) {
  UN(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
class Cpe {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    Vc("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    Vc("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    Vc("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
const O0t = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
};
const D0t = new CE(3e4, 6e4);
function TE(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Op(t, e, n, r, s = {}) {
  return Tpe(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = hY(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), Cpe.fetch()(bpe(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function Tpe(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, O0t), e);
  try {
    const s = new A0t(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw kS(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, l] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw kS(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw kS(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw kS(t, "user-disabled", o);
      const h = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (l)
        throw I0t(t, h, l);
      PT(t, h);
    }
  } catch (s) {
    if (s instanceof Nl)
      throw s;
    PT(t, "network-request-failed", { message: String(s) });
  }
}
async function H6(t, e, n, r, s = {}) {
  const i = await Op(t, e, n, r, s);
  return "mfaPendingCredential" in i && PT(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function bpe(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? P0t(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class A0t {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(B6(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), D0t.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function kS(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = B6(t, e, r);
  return s.customData._tokenResponse = n, s;
}
async function M0t(t, e) {
  return Op(t, "POST", "/v1/accounts:delete", e);
}
async function x0t(t, e) {
  return Op(t, "POST", "/v1/accounts:lookup", e);
}
function Rw(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function L0t(t, e = !1) {
  const n = os(t), r = await n.getIdToken(e), s = Epe(r);
  it(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: Rw(Sx(s.auth_time)),
    issuedAtTime: Rw(Sx(s.iat)),
    expirationTime: Rw(Sx(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function Sx(t) {
  return Number(t) * 1e3;
}
function Epe(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return lI("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = mI(n);
    return s ? JSON.parse(s) : (lI("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return lI("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function U0t(t) {
  const e = Epe(t);
  return it(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), it(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), it(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
async function FN(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof Nl && F0t(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function F0t({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
class Y0t {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const s = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, s);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
class Spe {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = Rw(this.lastLoginAt), this.creationTime = Rw(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
async function YN(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await FN(t, x0t(n, { idToken: r }));
  it(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? q0t(i.providerUserInfo) : [], a = B0t(t.providerData, o), c = t.isAnonymous, l = !(t.email && i.passwordHash) && !(a != null && a.length), h = c ? l : !1, u = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new Spe(i.createdAt, i.lastLoginAt),
    isAnonymous: h
  };
  Object.assign(t, u);
}
async function W0t(t) {
  const e = os(t);
  await YN(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function B0t(t, e) {
  return [...t.filter((r) => !e.some((s) => s.providerId === r.providerId)), ...e];
}
function q0t(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = ype(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
async function H0t(t, e) {
  const n = await Tpe(t, {}, async () => {
    const r = hY({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = bpe(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", Cpe.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
class OT {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    it(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), it(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), it(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : U0t(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return it(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await H0t(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new OT();
    return r && (it(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (it(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (it(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new OT(), this.toJSON());
  }
  _performRefresh() {
    return Vc("not implemented");
  }
}
function du(t, e) {
  it(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class yf {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = ype(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new Y0t(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new Spe(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await FN(this, this.stsTokenManager.getToken(this.auth, e));
    return it(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return L0t(this, e);
  }
  reload() {
    return W0t(this);
  }
  _assign(e) {
    this !== e && (it(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new yf(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    it(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await YN(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await FN(this, M0t(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, l, h;
    const u = (r = n.displayName) !== null && r !== void 0 ? r : void 0, d = (s = n.email) !== null && s !== void 0 ? s : void 0, f = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, p = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, _ = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, m = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, v = (l = n.createdAt) !== null && l !== void 0 ? l : void 0, y = (h = n.lastLoginAt) !== null && h !== void 0 ? h : void 0, { uid: g, emailVerified: w, isAnonymous: de, providerData: we, stsTokenManager: fe } = n;
    it(
      g && fe,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const ys = OT.fromJSON(this.name, fe);
    it(
      typeof g == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), du(u, e.name), du(d, e.name), it(
      typeof w == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), it(
      typeof de == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), du(f, e.name), du(p, e.name), du(_, e.name), du(m, e.name), du(v, e.name), du(y, e.name);
    const En = new yf({
      uid: g,
      auth: e,
      email: d,
      emailVerified: w,
      displayName: u,
      isAnonymous: de,
      photoURL: p,
      phoneNumber: f,
      tenantId: _,
      stsTokenManager: ys,
      createdAt: v,
      lastLoginAt: y
    });
    return we && Array.isArray(we) && (En.providerData = we.map((vo) => Object.assign({}, vo))), m && (En._redirectEventId = m), En;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new OT();
    s.updateFromServerResponse(n);
    const i = new yf({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await YN(i), i;
  }
}
const h7 = /* @__PURE__ */ new Map();
function Qd(t) {
  UN(t instanceof Function, "Expected a class definition");
  let e = h7.get(t);
  return e ? (UN(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), h7.set(t, e), e);
}
class Ipe {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
Ipe.type = "NONE";
const d7 = Ipe;
function Ix(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class sm {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = Ix(this.userKey, s.apiKey, i), this.fullPersistenceKey = Ix("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? yf._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new sm(Qd(d7), e, r);
    const s = (await Promise.all(n.map(async (l) => {
      if (await l._isAvailable())
        return l;
    }))).filter((l) => l);
    let i = s[0] || Qd(d7);
    const o = Ix(r, e.config.apiKey, e.name);
    let a = null;
    for (const l of n)
      try {
        const h = await l._get(o);
        if (h) {
          const u = yf._fromJSON(e, h);
          l !== i && (a = u), i = l;
          break;
        }
      } catch {
      }
    const c = s.filter((l) => l._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new sm(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (l) => {
      if (l !== i)
        try {
          await l._remove(o);
        } catch {
        }
    })), new sm(i, e, r));
  }
}
function f7(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (G0t(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (j0t(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (K0t(e))
    return "Blackberry";
  if (J0t(e))
    return "Webos";
  if (V0t(e))
    return "Safari";
  if ((e.includes("chrome/") || z0t(e)) && !e.includes("edge/"))
    return "Chrome";
  if ($0t(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function j0t(t = Oa()) {
  return /firefox\//i.test(t);
}
function V0t(t = Oa()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function z0t(t = Oa()) {
  return /crios\//i.test(t);
}
function G0t(t = Oa()) {
  return /iemobile/i.test(t);
}
function $0t(t = Oa()) {
  return /android/i.test(t);
}
function K0t(t = Oa()) {
  return /blackberry/i.test(t);
}
function J0t(t = Oa()) {
  return /webos/i.test(t);
}
function Rpe(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = f7(Oa());
      break;
    case "Worker":
      n = `${f7(Oa())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${xT}/${r}`;
}
async function kpe(t, e) {
  return Op(t, "GET", "/v2/recaptchaConfig", TE(t, e));
}
function p7(t) {
  return t !== void 0 && t.enterprise !== void 0;
}
class Npe {
  constructor(e) {
    if (this.siteKey = "", this.emailPasswordEnabled = !1, e.recaptchaKey === void 0)
      throw new Error("recaptchaKey undefined");
    this.siteKey = e.recaptchaKey.split("/")[3], this.emailPasswordEnabled = e.recaptchaEnforcementState.some((n) => n.provider === "EMAIL_PASSWORD_PROVIDER" && n.enforcementState !== "OFF");
  }
}
function Q0t() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function Z0t(t) {
  return new Promise((e, n) => {
    const r = document.createElement("script");
    r.setAttribute("src", t), r.onload = e, r.onerror = (s) => {
      const i = B6(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = s, n(i);
    }, r.type = "text/javascript", r.charset = "UTF-8", Q0t().appendChild(r);
  });
}
const X0t = "https://www.google.com/recaptcha/enterprise.js?render=", eIt = "recaptcha-enterprise";
class Ppe {
  /**
   *
   * @param authExtern - The corresponding Firebase {@link Auth} instance.
   *
   */
  constructor(e) {
    this.type = eIt, this.auth = nA(e);
  }
  /**
   * Executes the verification process.
   *
   * @returns A Promise for a token that can be used to assert the validity of a request.
   */
  async verify(e = "verify", n = !1) {
    async function r(i) {
      if (!n) {
        if (i.tenantId == null && i._agentRecaptchaConfig != null)
          return i._agentRecaptchaConfig.siteKey;
        if (i.tenantId != null && i._tenantRecaptchaConfigs[i.tenantId] !== void 0)
          return i._tenantRecaptchaConfigs[i.tenantId].siteKey;
      }
      return new Promise(async (o, a) => {
        kpe(i, {
          clientType: "CLIENT_TYPE_WEB",
          version: "RECAPTCHA_ENTERPRISE"
          /* RecaptchaVersion.ENTERPRISE */
        }).then((c) => {
          if (c.recaptchaKey === void 0)
            a(new Error("recaptcha Enterprise site key undefined"));
          else {
            const l = new Npe(c);
            return i.tenantId == null ? i._agentRecaptchaConfig = l : i._tenantRecaptchaConfigs[i.tenantId] = l, o(l.siteKey);
          }
        }).catch((c) => {
          a(c);
        });
      });
    }
    function s(i, o, a) {
      const c = window.grecaptcha;
      p7(c) ? c.enterprise.ready(() => {
        try {
          c.enterprise.execute(i, { action: e }).then((l) => {
            o(l);
          }).catch((l) => {
            a(l);
          });
        } catch (l) {
          a(l);
        }
      }) : a(Error("No reCAPTCHA enterprise script loaded."));
    }
    return new Promise((i, o) => {
      r(this.auth).then((a) => {
        if (!n && p7(window.grecaptcha))
          s(a, i, o);
        else {
          if (typeof window > "u") {
            o(new Error("RecaptchaVerifier is only supported in browser"));
            return;
          }
          Z0t(X0t + a).then(() => {
            s(a, i, o);
          }).catch((c) => {
            o(c);
          });
        }
      }).catch((a) => {
        o(a);
      });
    });
  }
}
async function WN(t, e, n, r = !1) {
  const s = new Ppe(t);
  let i;
  try {
    i = await s.verify(n);
  } catch {
    i = await s.verify(n, !0);
  }
  const o = Object.assign({}, e);
  return r ? Object.assign(o, { captchaResp: i }) : Object.assign(o, { captchaResponse: i }), Object.assign(o, {
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  }), Object.assign(o, {
    recaptchaVersion: "RECAPTCHA_ENTERPRISE"
    /* RecaptchaVersion.ENTERPRISE */
  }), o;
}
class tIt {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
class nIt {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new _7(this), this.idTokenSubscription = new _7(this), this.beforeStateQueue = new tIt(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = wpe, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = Qd(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await sm.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && (c != null && c.user) && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return it(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await YN(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = N0t();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? os(e) : null;
    return n && it(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && it(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(Qd(e));
    });
  }
  async initializeRecaptchaConfig() {
    const e = await kpe(this, {
      clientType: "CLIENT_TYPE_WEB",
      version: "RECAPTCHA_ENTERPRISE"
      /* RecaptchaVersion.ENTERPRISE */
    }), n = new Npe(e);
    this.tenantId == null ? this._agentRecaptchaConfig = n : this._tenantRecaptchaConfigs[this.tenantId] = n, n.emailPasswordEnabled && new Ppe(this).verify();
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new MT("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && Qd(e) || this._popupRedirectResolver;
      it(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await sm.create(
        this,
        [Qd(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return it(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, r, s) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return it(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = Rpe(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && S0t(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function nA(t) {
  return os(t);
}
class _7 {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = f_e((n) => this.observer = n);
  }
  get next() {
    return it(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function rIt(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(Qd);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
class sIt {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return Vc("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return Vc("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return Vc("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return Vc("not implemented");
  }
}
async function iIt(t, e) {
  return Op(t, "POST", "/v1/accounts:update", e);
}
async function Rx(t, e) {
  return H6(t, "POST", "/v1/accounts:signInWithPassword", TE(t, e));
}
async function oIt(t, e) {
  return Op(t, "POST", "/v1/accounts:sendOobCode", TE(t, e));
}
async function kx(t, e) {
  return oIt(t, e);
}
async function aIt(t, e) {
  return H6(t, "POST", "/v1/accounts:signInWithEmailLink", TE(t, e));
}
async function cIt(t, e) {
  return H6(t, "POST", "/v1/accounts:signInWithEmailLink", TE(t, e));
}
class DT extends sIt {
  /** @internal */
  constructor(e, n, r, s = null) {
    super("password", r), this._email = e, this._password = n, this._tenantId = s;
  }
  /** @internal */
  static _fromEmailAndPassword(e, n) {
    return new DT(
      e,
      n,
      "password"
      /* SignInMethod.EMAIL_PASSWORD */
    );
  }
  /** @internal */
  static _fromEmailAndCode(e, n, r = null) {
    return new DT(e, n, "emailLink", r);
  }
  /** {@inheritdoc AuthCredential.toJSON} */
  toJSON() {
    return {
      email: this._email,
      password: this._password,
      signInMethod: this.signInMethod,
      tenantId: this._tenantId
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an {@link  AuthCredential}.
   *
   * @param json - Either `object` or the stringified representation of the object. When string is
   * provided, `JSON.parse` would be called first.
   *
   * @returns If the JSON input does not represent an {@link AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e;
    if (n != null && n.email && (n != null && n.password)) {
      if (n.signInMethod === "password")
        return this._fromEmailAndPassword(n.email, n.password);
      if (n.signInMethod === "emailLink")
        return this._fromEmailAndCode(n.email, n.password, n.tenantId);
    }
    return null;
  }
  /** @internal */
  async _getIdTokenResponse(e) {
    var n;
    switch (this.signInMethod) {
      case "password":
        const r = {
          returnSecureToken: !0,
          email: this._email,
          password: this._password,
          clientType: "CLIENT_TYPE_WEB"
          /* RecaptchaClientType.WEB */
        };
        if (!((n = e._getRecaptchaConfig()) === null || n === void 0) && n.emailPasswordEnabled) {
          const s = await WN(
            e,
            r,
            "signInWithPassword"
            /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
          );
          return Rx(e, s);
        } else
          return Rx(e, r).catch(async (s) => {
            if (s.code === "auth/missing-recaptcha-token") {
              console.log("Sign-in with email address and password is protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the sign-in flow.");
              const i = await WN(
                e,
                r,
                "signInWithPassword"
                /* RecaptchaActionName.SIGN_IN_WITH_PASSWORD */
              );
              return Rx(e, i);
            } else
              return Promise.reject(s);
          });
      case "emailLink":
        return aIt(e, {
          email: this._email,
          oobCode: this._password
        });
      default:
        PT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  async _linkToIdToken(e, n) {
    switch (this.signInMethod) {
      case "password":
        return iIt(e, {
          idToken: n,
          returnSecureToken: !0,
          email: this._email,
          password: this._password
        });
      case "emailLink":
        return cIt(e, {
          idToken: n,
          email: this._email,
          oobCode: this._password
        });
      default:
        PT(
          e,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return this._getIdTokenResponse(e);
  }
}
function lIt(t) {
  switch (t) {
    case "recoverEmail":
      return "RECOVER_EMAIL";
    case "resetPassword":
      return "PASSWORD_RESET";
    case "signIn":
      return "EMAIL_SIGNIN";
    case "verifyEmail":
      return "VERIFY_EMAIL";
    case "verifyAndChangeEmail":
      return "VERIFY_AND_CHANGE_EMAIL";
    case "revertSecondFactorAddition":
      return "REVERT_SECOND_FACTOR_ADDITION";
    default:
      return null;
  }
}
function uIt(t) {
  const e = pv(_v(t)).link, n = e ? pv(_v(e)).deep_link_id : null, r = pv(_v(t)).deep_link_id;
  return (r ? pv(_v(r)).link : null) || r || n || e || t;
}
class j6 {
  /**
   * @param actionLink - The link from which to extract the URL.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @internal
   */
  constructor(e) {
    var n, r, s, i, o, a;
    const c = pv(_v(e)), l = (n = c.apiKey) !== null && n !== void 0 ? n : null, h = (r = c.oobCode) !== null && r !== void 0 ? r : null, u = lIt((s = c.mode) !== null && s !== void 0 ? s : null);
    it(
      l && h && u,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), this.apiKey = l, this.operation = u, this.code = h, this.continueUrl = (i = c.continueUrl) !== null && i !== void 0 ? i : null, this.languageCode = (o = c.languageCode) !== null && o !== void 0 ? o : null, this.tenantId = (a = c.tenantId) !== null && a !== void 0 ? a : null;
  }
  /**
   * Parses the email action link string and returns an {@link ActionCodeURL} if the link is valid,
   * otherwise returns null.
   *
   * @param link  - The email action link string.
   * @returns The {@link ActionCodeURL} object, or null if the link is invalid.
   *
   * @public
   */
  static parseLink(e) {
    const n = uIt(e);
    try {
      return new j6(n);
    } catch {
      return null;
    }
  }
}
class zg {
  constructor() {
    this.providerId = zg.PROVIDER_ID;
  }
  /**
   * Initialize an {@link AuthCredential} using an email and password.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credential(email, password);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * const userCredential = await signInWithEmailAndPassword(auth, email, password);
   * ```
   *
   * @param email - Email address.
   * @param password - User account password.
   * @returns The auth provider credential.
   */
  static credential(e, n) {
    return DT._fromEmailAndPassword(e, n);
  }
  /**
   * Initialize an {@link AuthCredential} using an email and an email link after a sign in with
   * email link operation.
   *
   * @example
   * ```javascript
   * const authCredential = EmailAuthProvider.credentialWithLink(auth, email, emailLink);
   * const userCredential = await signInWithCredential(auth, authCredential);
   * ```
   *
   * @example
   * ```javascript
   * await sendSignInLinkToEmail(auth, email);
   * // Obtain emailLink from user.
   * const userCredential = await signInWithEmailLink(auth, email, emailLink);
   * ```
   *
   * @param auth - The {@link Auth} instance used to verify the link.
   * @param email - Email address.
   * @param emailLink - Sign-in email link.
   * @returns - The auth provider credential.
   */
  static credentialWithLink(e, n) {
    const r = j6.parseLink(n);
    return it(
      r,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), DT._fromEmailAndCode(e, r.code, r.tenantId);
  }
}
zg.PROVIDER_ID = "password";
zg.EMAIL_PASSWORD_SIGN_IN_METHOD = "password";
zg.EMAIL_LINK_SIGN_IN_METHOD = "emailLink";
class BN {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, r, s = !1) {
    const i = await yf._fromIdTokenResponse(e, r, s), o = m7(r);
    return new BN({
      user: i,
      providerId: o,
      _tokenResponse: r,
      operationType: n
    });
  }
  static async _forOperation(e, n, r) {
    await e._updateTokensIfNecessary(
      r,
      /* reload */
      !0
    );
    const s = m7(r);
    return new BN({
      user: e,
      providerId: s,
      _tokenResponse: r,
      operationType: n
    });
  }
}
function m7(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
class qN extends Nl {
  constructor(e, n, r, s) {
    var i;
    super(n.code, n.message), this.operationType = r, this.user = s, Object.setPrototypeOf(this, qN.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: r
    };
  }
  static _fromErrorAndOperation(e, n, r, s) {
    return new qN(e, n, r, s);
  }
}
function hIt(t, e, n, r) {
  return n._getIdTokenResponse(t).catch((i) => {
    throw i.code === "auth/multi-factor-auth-required" ? qN._fromErrorAndOperation(t, i, e, r) : i;
  });
}
async function dIt(t, e, n = !1) {
  const r = "signIn", s = await hIt(t, r, e), i = await BN._fromIdTokenResponse(t, r, s);
  return n || await t._updateCurrentUser(i.user), i;
}
async function fIt(t, e) {
  return dIt(nA(t), e);
}
async function pIt(t, e, n) {
  var r;
  const s = nA(t), i = {
    requestType: "PASSWORD_RESET",
    email: e,
    clientType: "CLIENT_TYPE_WEB"
    /* RecaptchaClientType.WEB */
  };
  if (!((r = s._getRecaptchaConfig()) === null || r === void 0) && r.emailPasswordEnabled) {
    const o = await WN(s, i, "getOobCode", !0);
    await kx(s, o);
  } else
    await kx(s, i).catch(async (o) => {
      if (o.code === "auth/missing-recaptcha-token") {
        console.log("Password resets are protected by reCAPTCHA for this project. Automatically triggering the reCAPTCHA flow and restarting the password reset flow.");
        const a = await WN(s, i, "getOobCode", !0);
        await kx(s, a);
      } else
        return Promise.reject(o);
    });
}
async function _It(t, e) {
  return Op(t, "POST", "/v1/accounts:update", e);
}
async function g7(t, { displayName: e, photoURL: n }) {
  if (e === void 0 && n === void 0)
    return;
  const r = os(t), i = {
    idToken: await r.getIdToken(),
    displayName: e,
    photoUrl: n,
    returnSecureToken: !0
  }, o = await FN(r, _It(r.auth, i));
  r.displayName = o.displayName || null, r.photoURL = o.photoUrl || null;
  const a = r.providerData.find(
    ({ providerId: c }) => c === "password"
    /* ProviderId.PASSWORD */
  );
  a && (a.displayName = r.displayName, a.photoURL = r.photoURL), await r._updateTokensIfNecessary(o);
}
new CE(3e4, 6e4);
new CE(2e3, 1e4);
new CE(3e4, 6e4);
new CE(5e3, 15e3);
var y7 = "@firebase/auth", v7 = "0.23.0";
class mIt {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    it(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
function gIt(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function yIt(t) {
  wf(new vh(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      it(o && !o.includes(":"), "invalid-api-key", { appName: r.name }), it(!(a != null && a.includes(":")), "argument-error", {
        appName: r.name
      });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: Rpe(t)
      }, l = new nIt(r, s, i, c);
      return rIt(l, n), l;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), wf(new vh(
    "auth-internal",
    (e) => {
      const n = nA(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new mIt(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), zc(y7, v7, gIt(t)), zc(y7, v7, "esm2017");
}
const vIt = 5 * 60;
n_e("authIdTokenMaxAge");
yIt(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const w7 = {
  PICTURE_STORAGE: "/users/{uid}/UserProfilePic.jpg",
  PICTURE_DATE: "/users/{uid}/profilePicDate"
  // YYYY-MM-DD HH:mm:ss
};
class fv {
  static PictureStorage(e) {
    return Q(w7.PICTURE_STORAGE, { uid: e });
  }
  static PictureDate(e) {
    return Q(w7.PICTURE_DATE, { uid: e });
  }
}
const { resizeImage: wIt } = bTt;
class xIt extends uQ {
  constructor(e) {
    super(), this.auth = e;
  }
  async uploadProfilePicture(e) {
    try {
      let n = await wIt({
        file: e,
        maxSize: 750
        //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
      }), r = { contentType: e.type };
      await this.uploadObject(fv.PictureStorage(this.uid), n.blob, r);
      let s = await this.objectUrl(fv.PictureStorage(this.uid));
      return console.log(`Profile photo updated: ${s}`), await this.updatePhoto(s), await this.updatePictureDate(), s;
    } catch (n) {
      throw console.error("Upload profile: " + n.message), n;
    }
  }
  async deleteProfilePicture() {
    try {
      const e = fv.PictureStorage(this.uid);
      await this.deleteObject(e), await this.updatePhoto(""), await this.updatePictureDate();
    } catch (e) {
      throw console.error("Delete profile: " + e.message), e;
    }
  }
  async updatePictureDate() {
    return await this.set(fv.PictureDate(this.uid), this.currentTime);
  }
  get uid() {
    return this.auth.currentUser.uid;
  }
  get currentTime() {
    return Y().format("YYYY-MM-DD HH:mm:ss");
  }
  async getPhotoUrl() {
    const e = fv.PictureStorage(this.uid);
    return await this.objectUrl(e);
  }
  // get profilePictureExists() {
  //     (async () => {
  //         try {
  //             const path = ProfileResources.PictureStorage(this.uid);
  //             await this.objectUrl(path)
  //             return true;
  //         } catch(e) {
  //             return false;
  //         }
  //         })();
  // }
  async sendPasswordResetEmail() {
    try {
      return await pIt(this.auth, this.auth.currentUser.email);
    } catch (e) {
      throw console.error("Password reset: " + e.message), e;
    }
  }
  async updateEmail(e) {
    try {
      return await updateEmail(this.auth.currentUser, e);
    } catch (n) {
      throw console.error("Updating email address: " + n.message), n;
    }
  }
  async updateUserProfile(e) {
    try {
      return await g7(this.auth.currentUser, {
        displayName: e.trim()
      });
    } catch (n) {
      throw console.error("Updating profile name: " + n.message), n;
    }
  }
  async updatePhoto(e) {
    try {
      return await g7(this.auth.currentUser, {
        photoURL: e
      });
    } catch (n) {
      throw console.error("Updating profile photo: " + n.message), n;
    }
  }
  async reauthenticateWithCredential(e) {
    try {
      const n = zg.credential(this.auth.currentUser.email, e);
      return await fIt(this.auth, n);
    } catch (n) {
      throw console.error("Verifying credentials: " + n.message), n;
    }
  }
}
var uI = { exports: {} };
function CIt(t, e = 0) {
  let n = 3735928559 ^ e, r = 1103547991 ^ e;
  for (let s = 0, i; s < t.length; s++)
    i = t.charCodeAt(s), n = Math.imul(n ^ i, 2654435761), r = Math.imul(r ^ i, 1597334677);
  return n = Math.imul(n ^ n >>> 16, 2246822507) ^ Math.imul(r ^ r >>> 13, 3266489909), r = Math.imul(r ^ r >>> 16, 2246822507) ^ Math.imul(n ^ n >>> 13, 3266489909), (r >>> 0).toString(16).padStart(8, 0) + (n >>> 0).toString(16).padStart(8, 0);
}
uI.exports = CIt;
const LIt = (uI.exports == null ? {} : uI.exports).default || uI.exports;
var hI = { exports: {} };
function TIt(t) {
  if (!t)
    return !0;
  for (var e in t)
    return !1;
  return !0;
}
hI.exports = TIt;
const UIt = (hI.exports == null ? {} : hI.exports).default || hI.exports;
var dI = { exports: {} };
const bIt = (t) => t && Object.keys(t).length === 0 && t.constructor === Object;
dI.exports = bIt;
const FIt = (dI.exports == null ? {} : dI.exports).default || dI.exports;
var fI = { exports: {} };
function EIt(t) {
  return !isNaN(parseFloat(t)) && isFinite(t);
}
fI.exports = EIt;
const YIt = (fI.exports == null ? {} : fI.exports).default || fI.exports;
var pI = { exports: {} };
class SIt {
  constructor(e, n) {
    this.blob = e, this.filename = n;
  }
}
var IIt = function(t) {
  var e = t.file, n = t.maxSize, r = new FileReader(), s = new Image(), i = document.createElement("canvas"), o = function(c) {
    for (var l = c.split(",")[0].indexOf("base64") >= 0 ? atob(c.split(",")[1]) : unescape(c.split(",")[1]), h = c.split(",")[0].split(":")[1].split(";")[0], u = l.length, d = new Uint8Array(u), f = 0; f < u; f++)
      d[f] = l.charCodeAt(f);
    return new SIt(new Blob([d], { type: h }), e.name);
  }, a = function() {
    var c = s.width, l = s.height;
    c > l ? c > n && (l *= n / c, c = n) : l > n && (c *= n / l, l = n), i.width = c, i.height = l, i.getContext("2d").drawImage(s, 0, 0, c, l);
    var h = i.toDataURL("image/jpeg");
    return o(h);
  };
  return new Promise(function(c, l) {
    if (!e.type.match(/image.*/)) {
      l(new Error("Not an image"));
      return;
    }
    r.onload = function(h) {
      s.onload = function() {
        return c(a());
      }, s.src = h.target.result;
    }, r.readAsDataURL(e);
  });
};
pI.exports = IIt;
const WIt = (pI.exports == null ? {} : pI.exports).default || pI.exports;
export {
  RIt as Analytics,
  OIt as BillingResources,
  xIt as CatalogFirebaseProvider,
  kIt as CatalogFirebaseResources,
  NIt as CatalogHostedProvider,
  wo as CatalogHostedResources,
  DIt as CatalogPromotions,
  cc as CatalogPropertyMap,
  Upe as DataAccessLayer,
  Rv as Environment,
  AIt as MailingListProvider,
  Kg as MailingListResources,
  xIt as ProfileProvider,
  PIt as PurchaseResources,
  C7 as RequestResources,
  jpe as allMap,
  jpe as allSettledMap,
  LIt as cyrb53,
  UIt as empty,
  FIt as isEmpty,
  YIt as isNumeric,
  WIt as resizeImage,
  oA as splitProduct,
  Q as templ8r
};
