<style lang="scss" scoped>
@import 'styles/common.scss';

.map-product {
    padding-top: $xsmall-size;

    header {
        @include icon-section;
    }

    footer {
        margin: $gap;
    }

    .more-info {
        white-space: pre-wrap;
        margin-bottom: $gap;
    }

    .layers {
        @include item-list;
    }

    .capability {
        

        img {
            width: 100%;
            margin: $gap 0;
        }
    }



}
</style>

<template>
    <div class="map-product">
        <header v-if="validSku">
            <div class="top">
                <div class="info show-smaller">
                    <div class="price">${{ price }}</div>
                    <div class="details">/year</div>
                </div>
                <subscription-icon class="icon" :region="product?.region" :subscription="product?.subscription"></subscription-icon>
            </div>

            <div class="content">
                <div class="secondary-title">add-on</div>
            <div class="info show-bigger">
                <div class="price">${{ price }}</div>
                    <div class="details">/year</div>
                </div>

                <div class="title">{{ name }}</div>
                <chip />
                <!-- <p><b>Trial it free for 3(0) days to see if it’s right for you.</b></p> -->

                <p class="details">{{ metadata?.description_header }}</p>

                <!-- <div>The iHunter {{ name }} add-on is an annual subscription to an additional set of
                    provincial boundary layers covering Crown Land and other special use areas in {{ regionName }}. Once
                    you’ve signed up with your iHunter Account it will renew once a year so that you’re always up-to-date
                    with the most recent boundary information.</div> -->

                <product-notice :sku="sku" :product-info="info"></product-notice>

                <!-- <div class="product-notice" v-if="purchase">
                    <img class="profile" :src="photo" referrerpolicy="no-referrer">
                    <div>
                        <div>You have an active {{ name }} from iHunter <platform-label :purchase="purchase"></platform-label>
                        </div>
                    </div>
                </div> -->

                <product-cart-button :sku="sku" :product="info" :full="true"></product-cart-button>
                

                <div class="secondary-title">works with</div>
                <div class="product-list">
                    <div class="product-logo">
                        <app-icon :region="region"></app-icon><word-logo :dark="true">{{ short }}</word-logo>
                    </div>
                    <div class="product-logo">
                        <web-app-icon></web-app-icon><word-logo :dark="true">Web</word-logo>
                    </div>
                </div>

                <divider />


                <div v-if="metadata?.capabilities?.length">
                    <div class="capability" v-for="(capability, index) in metadata?.capabilities" :key="index" v-if="capability">
                        <img :src="capability.image">
                        <h3>{{ capability.title }}</h3>
                        <p>{{ capability.description }}</p>
                        <p class="details" v-if="capability.secondaryDescription">{{capability.secondaryDescription}}</p>

                        <div v-if="capability.layers">
                            <div class="layers">
                                <div class="section-row">
                                    <p><span class="section-title">{{metadata.name_generic}} layers</span><span class="item-count">{{ layerCount
                                    }}</span></p>
                                </div>
            
                                <ul>
                                    <li class="item-row" v-for="(layer, id) in subscriptionLayers" :key="id">
                                        <layer-icon class="item-icon" :fill="layer?.color" type="subscription"></layer-icon>
                                        <div class="item-content">
                                            <div class="name" v-if="layer?.name">{{ layer?.name }} </div>
                                            <div class="details more-info" v-if="layerDescription(id)">{{ layerDescription(id) }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            <div v-if="bundledSubscription">
                <divider/>
                <h3>All the benefits of {{ bundledSubscription?.name }}</h3>
                <p v-html="emphasizeText(bundledSubscription?.header, bundledSubscription?.name)"></p>
                <div class="brand-bullet" v-if="bundledSubscription?.description?.length">
                    <ul>
                        <li v-for="(feature, index) in bundledSubscription?.description" :key="index">
                            <span>{{ feature }}</span>
                        </li>
                    </ul>
                </div>
                <p><a :href="`#/subscription/${bundledSubscription?.sku}`" class="arrow">More details ▸</a></p>
            </div>


            <divider v-if="metadata?.disclaimer"/>
            <div v-if="metadata?.disclaimer" class="fine-print" >{{ metadata?.disclaimer }}</div>
        </div>
    </header>

        <header v-else>
            <app-icon class="icon"></app-icon>
            <div class="content">
                <div class="secondary-title">Product details</div>
                <div class="title">Subscription not found</div>
                <chip />
                <p>Sorry, we can't find details for the subscription '<b>{{ sku }}</b>' in our catalog.</p>
                <p>Go <a href="javascript:history.back()">back</a> or navigate <a href="/">home</a>.</p>
            </div>
        </header>

        <!-- <footer v-if="validSku">
                <h3>Product information</h3>
                <json-viewer v-if="info" :value="info"></json-viewer>
                <h4>Subscription Metadata</h4>
                <json-viewer v-if="metadata" :value="metadata" :expand-depth="0"></json-viewer>
            </footer> -->
    </div>
</template>

<script>
import { Component, Watch, Vue } from 'vue-property-decorator';

import ProductPage from './ProductPage.vue';

@Component
export default class SubscriptionProductPage extends ProductPage {

    metadata = null;
    layerMetadata = {};

    mounted() {
        this.refreshMetadata();
    }

    @Watch('sku')
    onSkuChanged() {
        this.refreshMetadata();
    }

    refreshMetadata() {
        this.loadMetadata().then(metadata => {
            this.metadata = this.filterMetadata(metadata);
        }).catch(error => {
            console.error(error);
        });
    }

    filterMetadata(metadata) {
        if (metadata?.capabilities?.length) {
            for (let i in metadata.capabilities) {
                let capability = metadata.capabilities[i];
                // Filter platform capabilities
                if (capability.platform && capability.platform !== 'web' ) {
                    delete metadata?.capabilities[i];
                }

                // Update image urls
                if (capability.image) {
                    capability.image = this.replaceServerUrls(capability.image);
                }
            }
        }

        return metadata;
    }

    async loadMetadata() {
        if (this.product.region && (this.product.subscription || this.sku)) {
            return await this.state.catalogProvider.getSubscriptionMetadata(this.product.region, this.product.subscription || this.sku);
        }
        return null;
    }

    async loadLayerMetadata(layer) {
        if (this.product.region && this.product.subscription && this.sku && layer) {
            this.$set(this.layerMetadata, layer, {}); // Load empty data to prevent subsequent reloading 
            let promise = this.state.catalogProvider.getSubscriptionLayerMetadata(this.product.region, this.product.subscription, layer).then((metadata) => {
                this.$set(this.layerMetadata, layer, metadata);
            })
            return promise;
        }
        return null;
    }

    layerDescription(layer) {
        if (!this.layerMetadata[layer]) {
            this.loadLayerMetadata(layer);
        }
        return this.layerMetadata[layer]?.what_is_description;
    }

    get summary() {
        return this.catalog[this.sku];
    }

    get product() {
        return this.products[this.sku];
    }

    // get info() {
    //     if (!this.validSku) {
    //         return null;
    //     }
    //     return this.state.productInfo(this.sku);
    // }

    get purchase() {
        return this.info?.purchase;
    }

    get name() {
        return this.info?.name || this.metadata?.name;
    }

    get regionName() {
        if (this.catalog && this.product?.region) {
            return this.catalog[this.product?.region]?.name;
        }
        return null;
    }

    get subscriptions() {
        return this.state?.catalog[this.product?.region || this.info?.region]?.subscriptions;
    }

    get subscriptionLayers() {
        let layers = {};

        for (let id in this.subscriptions) {
            let subscription = this.subscriptions[id];
            for (let layer in subscription?.layers) {
                layers[layer] = subscription?.layers[layer];
            }

        }

        return layers;
    }

    get layerCount() {
        if (!this.subscriptionLayers) {
            return null;
        }
        return Object.keys(this.subscriptionLayers).length;
    }

    get bundledSubscription() {
        if (!this.product?.group) {
            return;
        }

        let group = this.state?.groups[this.product.group];
        if (!group || !group?.length) {
            return;
        }

        const lowestTier = group[0];
        if (!lowestTier.subscription || !lowestTier.region ||this.product.subscription === lowestTier.subscription) {
            return;
        }

        // console.log(group);
        return this.catalog[lowestTier.region]?.subscriptions[lowestTier.subscription];
    }
}

Vue.component('subscription-product-page', SubscriptionProductPage);

</script>

