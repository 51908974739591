<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import AppState from './AppState.vue';

    @Component
    export default class ProductItem extends Vue.extend({
        props: {
            sku: {
                type: String,
                required: true
            },
            productInfo: {
                type: Object,
                required: false
            }
        }
    }) {

        buy() {
            if(!this.eligablePurchase){
                return;
            }

            if(this.cart.contains(this.sku)){
                this.cart.remove(this.sku);
            }else {
                this.cart.buy(this.sku);

                // When buying, we need to remove peer skus
                for (let sku of this.peers) {
                    if (this.cart.contains(sku)) {
                        this.cart.remove(sku);
                    }
                }
            }
        }

        get peers() {

            let skus = [];

            const group = this.product?.group;
            if (group) {
                for (let product of this.state?.groups[group]) {
                    if (product.sku !== this.sku) {
                        skus.push(product.sku);
         
                    }
                }
            }
            return skus;
        }

        get group() {

            let skus = [];

            const group = this.product?.group;

            if (group) {
                for (let product of this.state?.groups[group]) {
                    skus.push(product.sku);
                }
            }
            return skus;
        }

        get state() {
            return AppState;
        }

        get cart() {
            return this.state?.cart;
        }

        get upgradeDiscount() {
            return this.state?.upgradeDiscount;
        }

        get incart() {
            return this.cart.contains(this.sku);
        }

        get info() {
            return this.productInfo ?? this.state.productInfo(this.sku);
        }

        get billingInfo() {
            return this.state.subscriptionBillingInfo(this.sku);
        }

        get owned() {
            return !!this.purchase;
        }

        get region() {
            return this.state?.catalog[this.info?.region]?.short;
        }

        get product() {
            return this.state?.products[this.sku];
        }

        get price() {
            return this.info?.price;
        }

        get name() {
            return this.info?.name;
        }

        get alternate() {
            return this.info?.alternate;
        }

        get outdated() {
            return this.info?.outdated;
        }

        get version() {
            return this.info?.version;
        }

        get type() {
            return this.info?.type;
        }

        get purchase() {
            return this.info?.purchase;
        }

        get map() {
            return this.type === 'map';
        }

        get subscription() {
            return this.type === 'subscription';
        }


        get purchases() {
            return this.state?.purchases;
        }

        productVersion(sku) {
            return this.state?.products[sku]?.version;
        }

        get activeSubscription() {
            return !!this.billingInfo;
        }
        
        get webSubscription() {
            return !!this.purchase?.web;
        }

        get inheritSubscription() {
            return this.subscription && this.ownUpgrade;
        }

        get managableSubscription() {
            if (this.subscription && (this.owned || this.ownGroup)) {
                for (let sku of this.group) {
                    if (this.state.subscriptionBillingInfo(sku)) {
                        return true;
                    }
                }
            }
            return false;
        }

        get eligablePurchase() {
            if (this.subscription) {
                // Don't allow purchasing a subscription we should be managing instead
                if (this.managableSubscription) {
                    return false;
                }

                // Don't allow purchasing something we already have as a legacy item
                if (this.isLegacy(this.purchase)) {
                    return false;
                }

                // Allow re-purchasing mobile subscriptions on web
                if (this.isMobile(this.purchase) && !this.webSubscription) {
                    return true;
                }
            }

            // Otherwise, we can buy it if its not owned yet
            return !this.owned
        }

        isLegacy(purchase) {
            return purchase?.web?.startsWith('legacy');
        }

        isMobile(purchase) {
            return purchase?.apple || purchase?.android;
        }

        get ownGroup() {
            return this.ownUpgrade || this.ownDowngrade;
        }

        get ownUpgrade() {
            if (!this.upgradeSku) {
                return false;
            }

            return !!this.upgradePurchase;
        }

        get ownDowngrade() {
            if (!this.downgradeSku) {
                return false;
            }

            return !!this.downgradePurchase;
        }

        get upgradePurchase() {
            return this.purchases[this.upgradeSku];
        }


        get downgradePurchase() {
            return this.purchases[this.downgradeSku];
        }

        get upgradeSku() {
            const groupId = this.product?.group;
            if (!groupId || !this.sku) {
                return null;
            }

            const group = this.state?.groups[groupId];
            if (!group || group.length <= 1) {
                return null;
            }

            if (group[0]?.sku !== this.sku || group[1]?.sku === this.sku) {
                return null;
            }

            return group[1].sku;
        }

        get downgradeSku() {
            const groupId = this.product?.group;
            if (!groupId || !this.sku) {
                return null;
            }

            const group = this.state?.groups[groupId];
            if (!group || group.length <= 1) {
                return null;
            }

            if (group[0]?.sku == this.sku) {
                return null;
            }

            return group[0].sku;
        }
    }

</script>

