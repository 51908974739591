export default class Analytics {

    constructor() {
        this.clientId = this.lookupClientId();
    }

    report(event, params) {
        gtag('event', event, params);
    }

    lookupClientId() {
        try {
            var cookie = {};
            document.cookie.split(';').forEach((el) => {
                var splitCookie = el.split('=');
                var key = splitCookie[0].trim();
                var value = splitCookie[1];
                cookie[key] = value;
            });
            return cookie["_ga"].substring(6);
        }catch(error) {
            console.warn('Analytics client ID not found.');
            return null;
        }
    }
}
