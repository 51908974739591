<style lang="scss" scoped>
    @import 'styles/common.scss';

    .cart {
        display: flex;
        flex-direction: column;
        height: 100vh;

        header {
            position: sticky;
            top:0;

            z-index: 8;

            display: flex;
            flex-direction: column;

            color: $mono-white;
            background-color: $mono-black;

            .header-content {
                display: flex;
                flex-direction: column;
                padding: $space $gap;// $gap 0 $gap;

                .item-count {
                    font-size: 16px;
                    // color: $mono-white
                }

                .purchase-details {
                    font-size: 11px;
                    color: $mono-light;

                    span {
                        color: $mono-white;
                    }
                }

                .promo {
                    width: 100%;
                    //max-width: $triple + (2 * $gap);
                    margin: $space 0;
                    border-radius: 28px;
                    border: solid 8px $mono-white;
                    background-color: $mono-white;

                    input {
                        width: 100%;
                        height: 28px;

                        padding-left: $space;
                        border-radius: 20px;
                        pointer-events: all;
                        text-transform: uppercase;
                        
                        background-color: $mono-white;

                        border: solid 2px $mono-white; 

                        outline: none;

                        &:focus {
                            border: solid 2px $general-orange;
                            
                        }
                    }
                }

                button {
                    width: 100%;
                }
            }
        }

        main {
            color: $mono-white;
            display: flex;
            flex-grow: 1;
            align-items: flex-start;

            ul {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            li {
                margin: $space 0 $space $space;
            }

            ul > li + li {
                margin-top: $notch;
                border-top: 1px solid $mono-misc;
                border-radius: 0.5px;
            }
        }
    }

</style>

<template>
    <div class="cart">
        <header>
            <error-bar></error-bar>
            <notification-bar></notification-bar>
            <div class="header-content">
                <div class="item-count" v-if="!empty">
                    <b>{{items.length}}</b> item<span v-if="items.length > 1">s</span> in my cart
                </div>
                <div class="item-count" v-else>
                    <b>Empty</b> cart
                </div>
                <!-- <div class="purchase-details">Promo codes and applicable taxes are all dealt with at checkout and payment.</div> -->
                <div class="purchase-details"><span>Add promo code here:</span></div>
                <form @submit.prevent="addPromo">
                    <div class="promo">
                        <input type="search" placeholder="Code" v-model="cart.promo" @blur="blur" @click="blur">
                    </div>
                

                    <div class="purchase-details">Applicable taxes are all dealt with at checkout and payment.</div>
                    <button v-if="authenticated" @click="checkoutCart" :disabled="empty || processing">Checkout</button>
                    <button v-else @click="signIn">Login to checkout</button>
                </form>
            </div>
        </header>

        <cart-promo-notice v-if="promoCode || promoTagline" :code="promoCode">{{promoTagline}} 
            <!-- Grand opening discount! Save 20% on your cart total at checkout with promo code: -->
        </cart-promo-notice>
        <!-- <div class="promo-banner">tests</div> -->

        <main class="content">
            <ul class="items">
                <li v-for="(item) of items" :key="item.sku">
                    <cart-item :sku="item.sku"></cart-item>
                </li>
            </ul>
        </main>

        <landscape></landscape>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import Page from './Page.vue';

    @Component
    export default class CartMenu extends Page {
        processing = false;

        get items() {
            return this.cart?.items;
        }

        get empty() {
            return !this.items?.length;
        }

        addPromo() {

        }

        blur() {
            this.cart.save(); // Preserve promo-code
        }

        async checkoutCart() {
            try {
                this.processing = true;
                await this.checkout();

            }catch(error) {
                console.error(error);

            }finally{
                this.processing = false;
            }
        }

        get promoCode() {
            return this.state.promoCode;
        }

        get promoTagline() {
            return this.state.promoTagline;
        }
    }

    Vue.component('cart-menu', CartMenu);

</script>

